import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  CardImg,
  CardText,
  ModalHeader,
  Nav,
  CardImgOverlay,
  NavItem,
  NavLink,
  Input,
  TabContent,
  TabPane,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Badge from "react-bootstrap/Badge";
import Breadcrumb from "../../../../components/Common/Breadcrumb";
import TrainingProductsSidebar from "./trainingproducts-sidebar";
import ProfileSettings from "../../../Authentication/profile-sidebar";
//Import Action to copy breadcrumb items from local state to redux state
import "react-toastify/dist/ReactToastify.css";
import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  bcrypt,
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../../useToken";
import { ApiUrl, ProductName, AttachementsUrl } from "../../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";

export default class TrainingSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ids: this.props.match.params.id,
      total_pages: 0,
      records: [],
      content: {},
      get_master: {},
      hasContent: 0,
      showMarkComplete: true,

    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    fetch(`${ApiUrl}` + "get/training/section", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ training_for: 'Client', ids: dcrypt(this.state.ids) }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            content: response.data.content,
            get_master: response.data.get_master_id
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  markAsComplete = () => {

    let data = {
      user_id: uInfo().user_id,
      content_id: this.state.content.content_id,
      cat_id: this.state.content.cat_id,
      section_id: this.state.content.section_id,
    }

    fetch(`${ApiUrl}` + "content/mark/complete", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState(prevState => {
            let content = Object.assign({}, prevState.content);
            content.id = 10;
            return { content };
          })
          this.getproductsections();

        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className="traininig-section">


          <div className="p-5 text-white bg-purple has-bg-img bg-blend-multiply" style={{ backgroundImage: `url("https://toruskit.com/assets/img/util/gray-w.svg")` }}>
            <h2>{this.state.content.title}</h2>
            <p>{this.state.content.short_description}</p>
            <img className="bg-img" src="https://toruskit.com/assets/img/util/gray-w.svg" alt="https://toruskit.com/assets/img/util/gray-w.svg" />
          </div>

          <div className="page-title-box m-3 d-flex space-between">
            <ol className="breadcrumb m-0">
              <li key={0} className="breadcrumb-item">Dashboard</li>
              <li key={1} className="breadcrumb-item">{this.state.content.title}</li>
            </ol>

            <ol className="breadcrumb m-0">
              <li onClick={() => this.props.history.goBack()} key={0} className="breadcrumb-item">Back</li>
            </ol>

          </div>

          <Row>
            <Col md={9}>
              <Card className="lesson-videos">
                <CardBody>
                  <div dangerouslySetInnerHTML={{ __html: this.state.content.content }} />
                </CardBody>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <CardBody className="text-center">
                  {
                    (!this.state.content.id && this.state.showMarkComplete) ? (
                        <button className="btn btn-info btn-md" onClick={() => this.markAsComplete()}>
                          Mark as Complete
                        </button>
                    ) : (
                      <div><i className="fas fa-check" /> Completed</div>
                    )
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment >
    );
  }
}
