import React, { useState, useEffect} from "react"
import { Link } from "react-router-dom";
import { ApiUrl, AttachementsUrl } from "../../../config";
import Context from './Context';
import swal from "sweetalert";
import { dcrypt,bcrypt, uInfo, uToken } from "../../../useToken";
const Box12 = (props) => {
    const params = React.useContext(Context);
    const [record, setRecord] =  useState({});
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        submitData.append('user_id', uInfo().user_id);
        submitData.append('id', params.form_data.id);
        fetch(`${ApiUrl}` + "update/system/form", {
            method: "POST",
            body: submitData,
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
        })
        .then((response) => response.json())
        .then((response) => {
            if (response.status === true) {
                swal({
                    title: "Action performed successfully",
                    icon: "success",
                });
            } else {
                swal({
                    title: response.message,
                    icon: "warning",
                });
            }
        })
        .catch((error) => {
            console.error("Error:", error);
        });
    }
    useEffect(() => {
        getData();
    }, []);
    const getData = () => {
        fetch(`${ApiUrl}` + "get/systemForm/resourceInfo", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
              },
            body: JSON.stringify({ id: params.form_data.id, user_id: uInfo().user_id}),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    //console.log(response.data.record, 'response.data.record')
                    setRecord(response.data.record);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    const changeHandlerImg = (e, id) => {
        if (e.target.files[0].type.match('video.*') || e.target.files[0].type.match('image.*')) {
            const preview = document.querySelector('#' + id);
            console.log(preview)
            var reader = new FileReader();
            reader.onload = () => {
                preview.src = reader.result;
            };
            reader.readAsDataURL(e.target.files[0]);
        } else {
            swal({
                title: 'Olny video allowed',
                icon: "warning",
            });
        }
    };
    return (
        <div>
            <div className="mt-1">
                <div className="d-flex align-items-center gap-2 StepHeading">
                        <div className="StepNumber">{params.form_data.id} <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">{params.form_data.name}</h4>
                    </div>
                    <form method="post" onSubmit={handleSubmit}
                        encType="multipart/form-data" className="form-horizontal" id="selection-myform">

                        <div className="border-right p-2 mt-1">
                            <div className="page-content-box">
                                <div className="TitleWizred d-flex align-items-center gap-2">
                                    Your website theme will create a foundation for the assets that you are uploading. Choose the layout and structure that you like and we will take care of the rest!
                                </div>

                                <div className="WizredForm">
                                    <div className="form-group">
                                        <div class="parent themeSelection">
                                            {record.theme_color && (
                                                <div class="row">
                                                    <div class='col-sm-2 '>
                                                        <div className="theme-img justify-content-center">
                                                            <input type="radio" name="theme_color" defaultChecked={record.theme_color == 'blue.jfif' ? true : false} id="theme_color_img1" class="d-none imgbgchk" value="blue.jfif" />
                                                            <label for="theme_color_img1">
                                                                <div className="hero-img-area">
                                                                    <img src={`${AttachementsUrl}/default/images/theme-color/blue.jfif`} alt="Image 1" />
                                                                </div>
                                                                <div class="tick_container">
                                                                    <div class="tick"><i class="fa fa-check"></i></div>
                                                                </div>
                                                                <div className="img-footer">
                                                                    <label className="img-box">A</label>
                                                                    <p>Blue</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class='col-sm-2 '>
                                                        <div className="theme-img justify-content-center">
                                                            <input type="radio" name="theme_color" defaultChecked={record.theme_color == 'yellow.jfif' ? true : false} id="theme_color_img2" class="d-none imgbgchk" value="yellow.jfif" />
                                                            <label for="theme_color_img2">
                                                                <div className="hero-img-area">
                                                                    <img src={`${AttachementsUrl}/default/images/theme-color/yellow.jfif`} alt="Image 1" />
                                                                </div>
                                                                <div class="tick_container">
                                                                    <div class="tick"><i class="fa fa-check"></i></div>
                                                                </div>
                                                                <div className="img-footer">
                                                                    <label className="img-box">B</label>
                                                                    <p>Yellow</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class='col-sm-2 '>
                                                        <div className="theme-img justify-content-center">
                                                            <input type="radio" name="theme_color" defaultChecked={record.theme_color == 'red.jfif' ? true : false} id="theme_color_img3" class="d-none imgbgchk" value="red.jfif" />
                                                            <label for="theme_color_img3">
                                                                <div className="hero-img-area">
                                                                    <img src={`${AttachementsUrl}/default/images/theme-color/red.jfif`} alt="Image 1" />
                                                                </div>
                                                                <div class="tick_container">
                                                                    <div class="tick"><i class="fa fa-check"></i></div>
                                                                </div>
                                                                <div className="img-footer">
                                                                    <label className="img-box">C</label>
                                                                    <p>Red</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div class='col-sm-2 '>
                                                        <div className="theme-img justify-content-center">
                                                            <input type="radio" name="theme_color" defaultChecked={record.theme_color == 'green.png' ? true : false} id="theme_color_img4" class="d-none imgbgchk" value="green.png" />
                                                            <label for="theme_color_img4">
                                                                <div className="hero-img-area">
                                                                    <img src={`${AttachementsUrl}/default/images/theme-color/green.png`} alt="Image 1" />
                                                                </div>
                                                                <div class="tick_container">
                                                                    <div class="tick"><i class="fa fa-check"></i></div>
                                                                </div>
                                                                <div className="img-footer">
                                                                    <label className="img-box">D</label>
                                                                    <p>Green</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div class='col-sm-2 '>
                                                        <div className="theme-img justify-content-center">
                                                            <input type="radio" name="theme_color" defaultChecked={record.theme_color == 'gray.png' ? true : false} id="theme_color_img5" class="d-none imgbgchk" value="gray.png" />
                                                            <label for="theme_color_img5">
                                                                <div className="hero-img-area">
                                                                    <img src={`${AttachementsUrl}/default/images/theme-color/gray.png`} alt="Image 1" />
                                                                </div>
                                                                <div class="tick_container">
                                                                    <div class="tick"><i class="fa fa-check"></i></div>
                                                                </div>
                                                                <div className="img-footer">
                                                                    <label className="img-box">E</label>
                                                                    <p>Gray</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div class='col-sm-2 '>
                                                        <div className="theme-img justify-content-center">
                                                            <input type="radio" name="theme_color" defaultChecked={record.theme_color == 'slate.png' ? true : false} id="theme_color_img6" class="d-none imgbgchk" value="slate.png" />
                                                            <label for="theme_color_img6">
                                                                <div className="hero-img-area">
                                                                    <img src={`${AttachementsUrl}/default/images/theme-color/slate.png`} alt="Image 1" />
                                                                </div>
                                                                <div class="tick_container">
                                                                    <div class="tick"><i class="fa fa-check"></i></div>
                                                                </div>
                                                                <div className="img-footer">
                                                                    <label className="img-box">F</label>
                                                                    <p>Slate</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div class='col-sm-2 '>
                                                        <div className="theme-img justify-content-center">
                                                            <input type="radio" name="theme_color" defaultChecked={record.theme_color == 'beige.png' ? true : false} id="theme_color_img7" class="d-none imgbgchk" value="beige.png" />
                                                            <label for="theme_color_img7">
                                                                <div className="hero-img-area">
                                                                    <img src={`${AttachementsUrl}/default/images/theme-color/beige.png`} alt="Image 1" />
                                                                </div>
                                                                <div class="tick_container">
                                                                    <div class="tick"><i class="fa fa-check"></i></div>
                                                                </div>
                                                                <div className="img-footer">
                                                                    <label className="img-box">G</label>
                                                                    <p>Beige</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div class='col-sm-2 '>
                                                        <div className="theme-img justify-content-center">
                                                            <input type="radio" name="theme_color" defaultChecked={record.theme_color == 'navy.png' ? true : false} id="theme_color_img8" class="d-none imgbgchk" value="navy.png" />
                                                            <label for="theme_color_img8">
                                                                <div className="hero-img-area">
                                                                    <img src={`${AttachementsUrl}/default/images/theme-color/navy.png`} alt="Image 1" />
                                                                </div>
                                                                <div class="tick_container">
                                                                    <div class="tick"><i class="fa fa-check"></i></div>
                                                                </div>
                                                                <div className="img-footer">
                                                                    <label className="img-box">H</label>
                                                                    <p>Navy</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div class='col-sm-2 '>
                                                        <div className="theme-img justify-content-center">
                                                            <input type="radio" name="theme_color" defaultChecked={record.theme_color == 'orange.png' ? true : false} id="theme_color_img9" class="d-none imgbgchk" value="orange.png" />
                                                            <label for="theme_color_img9">
                                                                <div className="hero-img-area">
                                                                    <img src={`${AttachementsUrl}/default/images/theme-color/orange.png`} alt="Image 1" />
                                                                </div>
                                                                <div class="tick_container">
                                                                    <div class="tick"><i class="fa fa-check"></i></div>
                                                                </div>
                                                                <div className="img-footer">
                                                                    <label className="img-box">I</label>
                                                                    <p>Orange</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                      
                        <button type="submit" className="btn btn-sm btn-primary mt-2"> Ok &nbsp;<i className="fas fa-check"></i></button>
                    </form>
            </div>
        </div >
    );
};

export default Box12;