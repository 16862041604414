import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import { Accordion, Dropdown, Tab, Tabs, Tooltip, Alert } from "react-bootstrap";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    bcrypt,
    getPages,
    getPermission,
    getRoles,
    hasRole,
    isAllowed,
    uInfo,
    uRole,
    uToken,
} from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import AvInput from "availity-reactstrap-validation/lib/AvInput";


export default class Contract extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                text: "Contract Name",
                key: "contract.name",
                cell: (record, index) => {
                    return record.contract ? record.contract.name : ''
                }
            },
            {
                text: "Assign Date",
                key: "assign_dated",
            },
            {
                text: "Last Updated	",
                key: "submitted_dated",
                cell: (record, index) => {
                    return record.submitted_dated == '0000-00-00 00:00:00' ? 'Not Completed' : record.submitted_dated
                }
            },
            {
                key: "action",
                text: "Action",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            {
                                record.submitted_dated == '0000-00-00 00:00:00' ? (
                                    <>
                                        <Link
                                            type="button"
                                            color="info"
                                            className="btn btn-link btn-sm mb-1 mr-5"
                                            // target={'_blank'}
                                            to={`/form/details?u=${bcrypt(uInfo().user_id)}&meta=${bcrypt(record.id)}&c=${bcrypt(record.contract_id)}`}
                                        >
                                            <i className="fas fa-edit"></i>
                                        </Link>
                                    </>

                                ) : (
                                    <>
                                        <Link
                                            title="Map this form"
                                            type="button"
                                            color="info"
                                            className="btn btn-link btn-sm mb-1 mr-5"
                                            // target={'_blank'}
                                            to={`/show/form?u=${bcrypt(uInfo().user_id)}&meta=${bcrypt(record.id)}&c=${bcrypt(record.contract_id)}`}
                                        >
                                            <i className="fas fa-eye"></i>
                                        </Link>
                                    </>

                                )
                            }


                            {/* )} */}
                        </Fragment >
                    );
                }
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            show_filter: false,
            show_pagination: false,
            button: {
                excel: false,
                print: false,
                csv: false,
            },
            key: Math.random()
        };
        this.state = {
            records: [],

        };
    }




    componentDidMount() {
        this.getData();
    }

    getData = (queryString = "", data) => {
        fetch(`${ApiUrl}` + "get/client/contract" + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: uInfo().client_id }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        total_pages: response.data.total,
                        records: response.data.records,
                        roles: response.data.roles,
                    });

                } else {
                     toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };





    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        Contracts
                    </title>
                </Helmet>



                <ToastContainer />

                <Row>
                    <Col className="col-12 mb-3">
                        <Alert color="danger" role="alert" className="mb-0">
                            <strong>Please complete the followings</strong>
                        </Alert>
                    </Col>
                </Row>

                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody className="pipeline-status">
                                {/* <CardTitle className="h4">Default Datatable </CardTitle> */}
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                    dynamic={false}
                                    total_record={this.state.total_pages}
                                    onChange={this.tableChangeHandler}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
