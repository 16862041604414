import React, { useState, useEffect } from 'react';
import Video from 'twilio-video';
import Participant from './Participant';

const Room = ({ roomName, token, handleLogout }) => {
    const [room, setRoom] = useState(null);
    const [participants, setParticipants] = useState([]);

    useEffect(() => {
        const participantConnected = participant => {
            console.log(participant, 'participantConnected')
            setParticipants(prevParticipants => [...prevParticipants, participant]);
        };

        const participantDisconnected = participant => {
            console.log(participant, 'participantDisconnected')
            setParticipants(prevParticipants =>
                prevParticipants.filter(p => p !== participant)
            );
        };

        Video.connect(token, {
            name: roomName,
        }).then(room => {
            setRoom(room);
            room.on('participantConnected', participantConnected);
            room.on('participantDisconnected', participantDisconnected);
            room.participants.forEach(participantConnected);
        });

        return () => {
            console.log('room state')
            setRoom(currentRoom => {
                console.log(currentRoom, 'room state')
                if (currentRoom && currentRoom.localParticipant.state === 'connected') {
                    currentRoom.localParticipant.tracks.forEach(function (trackPublication) {
                        trackPublication.track.stop();
                    });
                    currentRoom.disconnect();
                    return null;
                } else {
                    return currentRoom;
                }
            });
        };
    }, [roomName, token]);

    const remoteParticipants = participants.map(participant => (
        <Participant key={participant.sid} participant={participant} />
    ));

    const audio_mute_button = document.querySelector('#mute-unmute-audio-button');
    const video_mute_button = document.getElementById("mute_video");

    // audio_mute_button.addEventListener("click", audioButtonHandler);

    function audioButtonHandler(event) {

        event.preventDefault();
        room.localParticipant.audioTracks.forEach(publication => {
            console.log(publication, 'hello');
            if (publication.isTrackEnabled) {
                publication.track.disable()
                audio_mute_button.firstChild.className = "fas fa-microphone-slash";
            } else {
                publication.track.enable()
                audio_mute_button.firstChild.className = "fas fa-microphone";
            }
        });
    }

    function videoButtonHandler(event) {
        event.preventDefault();
        room.localParticipant.videoTracks.forEach(publication => {
            console.log(publication.isTrackEnabled, 'publication.isTrackEnabled')
            if (publication.isTrackEnabled) {
                publication.track.disable();
                video_mute_button.firstChild.className = "fas fa-video-slash";
            } else {
                publication.track.enable();
                video_mute_button.firstChild.className = "fas fa-video";
            }
        });

    }
    return (
        <div className="room">
            {/* <h5>Room: {roomName}</h5> */}

            <div className='customVideo'>
                {/* <div className='col-md-12'> */}
                    <div className="local-participant">
                        {room ? (
                            <Participant
                                key={room.localParticipant.sid}
                                participant={room.localParticipant}
                            />
                        ) : (
                            ''
                        )}

                    </div>
                {/* </div>
                <div className='col-md-12'> */}
                    <div className="remote-participants">{remoteParticipants}</div>
                {/* </div> */}
                <div className='video-control'>
                    <button className='call-drop' onClick={handleLogout}><i className="fas fa-phone-alt"> </i></button>
                    <button onClick={audioButtonHandler} id="mute-unmute-audio-button"><i
                        className="fas fa-microphone"></i></button>
                    <button onClick={videoButtonHandler} id="mute_video" title="Video Camera"><i
                        className="fas fa-video"></i></button>
                </div>

            </div>

        </div>
    );
};

export default Room;