import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Table,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  Alert
} from "reactstrap";
import Helmet from "react-helmet";
import axios from "axios";
import {
  AvForm,
  AvField,
  AvGroup,
  AvFeedback,
} from "availity-reactstrap-validation";
import { hasRole, uInfo, uToken } from "../../../useToken";
import { ApiUrl, ProductName } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import Select from "react-select";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import FileUpload from "../../../components/Common/UploadFile";
import { Stepper, Step } from "react-form-stepper";
import swal from 'sweetalert';
import Loader from "react-loader";
import countries from "../../../common/data/countries";

export default class UsersDatatableTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page_name: "Import Leads",
      groups: [],
      tags: [],
      //countries: [],
      file: {},
      disabledButton: false,
      currentStep: 1,
      records: [],
      csv_data_file_id: "",
      activeTab: "1",
      processQueue: [],
      failedQueue: [],
      columns_data: [],
      smstokens: [],
      field: "",
      fields: [],
      group: "",
      tag: "",
      loader: true
    };

    this.fileSet = this.fileSet.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  fileSet = (file) => {
    this.setState({
      file: file[0],
    });
  };

  getData = (queryString = "", data) => {
    fetch(`${ApiUrl}` + "get/group/tag" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            groups: response.data.groups,
            tags: response.data.tags,
            //countries: response.data.countries,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleGroup = (selectedGroup) => {
    // var filter = [];
    // if (selectedGroup) {
    //   selectedGroup.map((fil) => {
    //     filter.push(fil.group_id);
    //   });
    // }

    this.setState({
      // selectedGroup: selectedGroup,
      group: selectedGroup.group_id,
    });
  };

  handleTag = (selectedTag) => {
    // var filter = [];
    // if (selectedTag) {
    //   selectedTag.map((fil) => {
    //     filter.push(fil.group_id);
    //   });
    // }

    this.setState({
      tag: selectedTag.group_id,
    });
  };

  handleCountry = (selectedCountry) => {
    this.setState({
      country: selectedCountry.code,
    });
  };

  handleFilterSubmit = async (event, values) => {
    values.file = this.state.file;
    const data = new FormData();
    data.append("file", this.state.file);
    data.append("header", true);
    data.append("group_id", values.group_id.toString());
    data.append("country_code", values.country_code);
    data.append("tag_id", values.tag_id.toString());
    //this.setState({ disabledButton: true});

    this.setState({
      loader: false
    });

    fetch(`${ApiUrl}` + "import_parse", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: data,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          this.setState({
            currentStep: 2,
            disabledButton: false,
            records: data.data.records,
            columns: data.data.columns,
            csv_data_file_id: data.data.csv_data_file,
            custom_fields: this.parsecustomfields(data.data.fields.custom_fields),
            default_fields: this.parsecustomfields(
              data.data.fields.default_fields
            ),
          });
          //console.log(data.data.records,)

          let concat = this.state.default_fields.concat(this.state.custom_fields);
          let result = concat.map(({ value }) => value)

          this.setState({
            smstokens: result
          });

          this.setState({
            loader: true
          });

          //   toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          this.setState({ disabledButton: false });
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          this.setState({
            loader: true
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        this.setState({
          loader: true
        });
      });
  };

  parsecustomfields = (data) => {
    return data.map((customfield) => {
      return { label: customfield.label, value: customfield.key };
    });
  };

  onSelectValues = (data, index) => {
    console.log(data, 'dataa', index);
    let fields = [...this.state.fields];
    var index1 = fields[index];
    console.log(index1, 'index1', index);

    if (index1 !== -1) {
      fields[index] = data.value;
    } else {
      fields.push(data.value);
    }

    console.log(fields, 'fields')

    this.setState({ fields });
  };

  deleteprocess = async (csv_id) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "delete/processimport", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: csv_id }),
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
              if (response.status === true) {
                this.getImportData();
              } else {


                toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            //Then with the error genereted...
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
  };


  containsAny = (source, target) => {
    var result = source.filter(function (item) { return target.indexOf(item) > -1 });
    return (result.length > 0);
  }


  handleSubmit = async (event, values) => {
    const names = values.fields;
    const count = names =>
      names.reduce((result, value) => ({
        ...result,
        [value]: (result[value] || 0) + 1
      }), {}); // don't forget to initialize the accumulator
    const duplicates = dict =>
      Object.keys(dict).filter((a) => dict[a] > 1);

    let dup = duplicates(count(names));

    var filteredDup = dup.filter(function (el) {
      return el != '';
    });

    if (filteredDup.length > 0) {
      toast('Map Column has duplicate value', { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

      return;
    }

    if (!this.containsAny(names, ["email", "phone", "mobile"])) {
      toast('Map Column has required Email Or Phone Or Mobile field', { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
      return;
    }

    values.fields = JSON.stringify(values.fields);

    this.setState({
      loader: false
    });

    fetch(`${ApiUrl}` + "import_process", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          this.getData();
          this.setState({
            currentStep: 3,
            disabledButton: false,
          });
          this.setState({
            loader: true
          });
          //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          this.setState({
            loader: true
          });
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        this.setState({
          loader: true
        });
        console.error("Error:", error);
      });
  };

  import = () => {
    // this.setState({
    //     disabledButton: true
    // })
    this.setState({
      loader: false
    });
    fetch(`${ApiUrl}` + "import", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.csv_data_file_id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          this.getData();
          this.setState({
            currentStep: 1,
            disabledButton: false,
          });
          this.setState({
            loader: true
          });
          // this.props.history.push("/client/list");
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          this.setState({
            loader: true
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        this.setState({
          loader: true
        });
      });
  };

  getImportData = () => {
    let url = "";
    if (this.state.activeTab == "2") {
      url = "get/process/import";
    } else if (this.state.activeTab == "3") {
      url = "get/failed/import";
    }

    this.setState({
      loader: false
    });

    fetch(`${ApiUrl}` + url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          if (this.state.activeTab == "2") {
            this.setState({
              processQueue: response.data.getData,
            });

          } else if (this.state.activeTab == "3") {
            this.setState({
              failedQueue: response.data.getData,
            });
          }
          this.setState({
            loader: true
          });
        } else {
          this.setState({
            loader: true
          });


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        this.setState({
          loader: true
        });
        console.error("Error:", error);
      });
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState(
        {
          activeTab: tab,
        },
        () => this.getImportData()
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.page_name} </title>
        </Helmet>

        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4>{this.state.page_name}</h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#">{this.state.page_name}</Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <Link to={"/lead/list"} className="btn  btn-secondary pull-right mr-5">
              Back
            </Link>
          </Col>
        </Row>

        <ToastContainer />
        <Card className="mb-0">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={this.state.activeTab == 1 ? "active" : ""}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                Import
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={this.state.activeTab == 2 ? "active" : ""}
                onClick={() => {
                  this.toggle("2");
                }}
              >
                Process Queue
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={this.state.activeTab == 3 ? "active" : ""}
                onClick={() => {
                  this.toggle("3");
                }}
              >
                Failed Queue
              </NavLink>
            </NavItem>
          </Nav>
        </Card>

        <Loader loaded={this.state.loader}></Loader>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Stepper activeStep={this.state.currentStep}>
                      <Step label="PREPARE & UPLOAD" />
                      <Step label="MAP YOUR DATA" />
                      <Step label="CONFIRM" />
                      <Step label="IMPORT" />
                    </Stepper>
                    {this.state.currentStep == 1 && (
                      <AvForm
                        onValidSubmit={this.handleFilterSubmit}
                        ref={(c) => (this.form = c)}
                        className="needs-validation"
                      >
                        <Row>
                          <Col md="6">
                            <div className="mb-3">
                              <label className="control-label">Groups</label>
                              <div className="MainTZone LeadsImport One">
                                <Select
                                  getOptionLabel={(option) => option.title}
                                  getOptionValue={(option) => option.group_id}
                                  isMulti={false}
                                  options={this.state.groups}
                                  classNamePrefix="select2-selection"
                                  name="group_id"
                                  onChange={(e) => {
                                    this.handleGroup(e);
                                  }}
                                />
                              </div>
                              <AvField
                                required
                                type="hidden"
                                name="group_id"
                                value={this.state.group}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <label className="control-label">Country</label>
                              <div className="MainTZone LeadsImport Two">
                                <Select
                                  getOptionLabel={(option) => option.label +" ("+option.code+")"}
                                  getOptionValue={(option) => option.code}
                                  isMulti={false}
                                  options={countries}
                                  classNamePrefix="select2-selection"
                                  name="country_id"
                                  onChange={(e) => {
                                    this.handleCountry(e);
                                  }}
                                />
                              </div>
                              <AvField
                                required
                                type="hidden"
                                name="country_code"
                                value={this.state.country}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <label className="control-label">Tags</label>
                              <div className="MainTZone LeadsImport Three">
                                <Select
                                  getOptionLabel={(option) => option.title}
                                  getOptionValue={(option) => option.group_id}
                                  isMulti={false}
                                  options={this.state.tags}
                                  classNamePrefix="select2-selection"
                                  name="tag_id"
                                  onChange={(e) => {
                                    this.handleTag(e);
                                  }}
                                />
                              </div>
                              <AvField
                                type="hidden"
                                name="tag_id"
                                value={this.state.tag}
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3 upload-file">
                              <label className="control-label">
                                Select File (csv) First row of .csv file must
                                include header
                              </label>
                              <FileUpload fileSet={this.fileSet} />
                            </div>

                            {/* <AvGroup>
                                                                <label>Add photos or videos <p> Recommended dimensions: (500px500px)</p></label>
                                                                <AvInput
                                                                    placeholder=""
                                                                    className="form-control"
                                                                    name="photo"
                                                                    type="file"
                                                                    onChange={(e) => this.handleChange(e.target.files)}
                                                                />
                                                                <AvFeedback>This field is invalid</AvFeedback>
                                                            </AvGroup> */}

                            {/* handleCountry = (selectedCountry) => {
                                                                    this.setState({
                                                                        country: selectedCountry.id
                                                                    })
                                                                } */}
                          </Col>
                        </Row>

                        <div className="button-items">
                          <Button
                            disabled={this.state.disabledButton}
                            className="btn-ld"
                            color="primary"
                            type="submit"
                          >
                            Start Import
                          </Button>
                        </div>

                        <Alert className="mt-3" color="info"><b>Important:</b> Make sure the date format for any column is MM/DD/YYYY</Alert>
                      </AvForm>
                    )}

                    {this.state.currentStep == 2 && (
                      <>
                        <CardTitle className="h4">MAP YOUR DATA</CardTitle>
                        <div className="table-responsive">
                          <AvForm
                            onValidSubmit={this.handleSubmit}
                            className="needs-validation"
                          >
                            <Table className="table table-light mb-0">
                              <thead>
                                {this.state.records[0].length > 0 ? (
                                  this.state.records[0].map((row, index) => {
                                    let value = typeof row === 'string' ? row.replace(/ /g, "_").toLowerCase() : row;
                                    const found = this.state.smstokens.find(element => {
                                      return element.toLowerCase() === value;
                                    });
                                    return (
                                      <tr>
                                        <td>{row}</td>
                                        <td>{this.state.records[1][index]}</td>
                                        <td>
                                          {/* <AvGroup>
                                            <Select
                                              getOptionLabel={(option) =>
                                                option.label
                                              }
                                              getOptionValue={(option) =>
                                                option.value
                                              }
                                              isMulti={false}
                                              options={this.state.smstokens}
                                              classNamePrefix="select form-control"
                                              // value={this.state.fields[index] ? this.state.fields[index] : '' }
                                              // value={this.state.smstokens.filter(value => console.log(value, '-----', row.replace(/ /g, "_").toLowerCase()))}
                                              // value={row.replace(/ /g, "_").toLowerCase()}
                                              className={
                                                "is-touched is-dirty av-valid"
                                              }
                                              name={`fields[${index}]`}
                                              onChange={(value) =>
                                                this.onSelectValues(
                                                  value,
                                                  index
                                                )
                                              }
                                            />
                                          </AvGroup> */}
                                          {
                                            this.state.smstokens.length > 0 && (
                                              <AvField
                                                type="select"
                                                name={`fields[${index}]`}
                                                value={found}
                                                at={found}
                                                a={index}
                                              // required
                                              >
                                                <option value={''}>Map Column</option>
                                                <optgroup label="Default Fields">
                                                  {this.state.default_fields.map(
                                                    (column, c) => {
                                                      return (
                                                        <option
                                                          a={column.value}
                                                          value={column.value}
                                                        >
                                                          {column.value}
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                                </optgroup>
                                                <optgroup label="Custom Fields">
                                                  {this.state.custom_fields.map(
                                                    (column, c) => {
                                                      return (
                                                        <option
                                                          a={column.value}
                                                          value={column.value}
                                                        >
                                                          {column.value}
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                                </optgroup>
                                              </AvField>
                                            )
                                          }
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr key="1">
                                    <td colSpan={4}>
                                      <div className="col-md-12 text-center text-center">
                                        No record found
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </thead>
                              <AvField
                                type="hidden"
                                name="csv_data_file_id"
                                value={this.state.csv_data_file_id}
                                required
                              />

                              <div className="button-items">
                                <Button
                                  disabled={this.state.disabledButton}
                                  className="btn-ld"
                                  color="primary"
                                  type="submit"
                                >
                                  Map Data
                                </Button>
                              </div>
                            </Table>
                          </AvForm>
                        </div>
                      </>
                    )}

                    {this.state.currentStep == 3 && (
                      <>
                        <CardTitle className="h4">IMPORT YOUR DATA</CardTitle>
                        <div className="table-responsive">
                          <Button
                            onClick={() => this.import()}
                            disabled={this.state.disabledButton}
                            className="btn-ld"
                            color="primary"
                            type="submit"
                          >
                            Import
                          </Button>
                        </div>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <CardTitle>Queue Process</CardTitle>
                    <div className="table-responsive">
                      <Table className="table table-light mb-0">
                        <thead>
                          <tr>
                            <th>SR#</th>
                            <th>Submitted Group</th>
                            <th>Submitted Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.processQueue.length > 0 ? (
                            this.state.processQueue.map((row, i) => {
                              return (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>{row.group_id}</td>
                                  <td>{row.created_at}</td>
                                  <td>
                                    <Button
                                      color="danger"
                                      onClick={() => {
                                        this.deleteprocess(row.id);
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr key="1">
                              <td colSpan={4}>
                                <div className="col-md-12 text-center text-center">
                                  No record found
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <CardTitle>Failed Queue</CardTitle>
                    <div className="table-responsive">
                      <Table className="table table-light mb-0">
                        <thead>
                          <tr>
                            <th>SR#</th>
                            <th>Submitted Group</th>
                            <th>Submitted Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.failedQueue.length > 0 ? (
                            this.state.failedQueue.map((row, i) => {
                              return (
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>{row.group_id}</td>
                                  <td>{row.created_at}</td>
                                  <td>
                                    <Button
                                      color="danger"
                                      onClick={() => {
                                        this.deleteprocess(row.id);
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr key="1">
                              <td colSpan={4}>
                                <div className="col-md-12 text-center text-center">
                                  No record found
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </React.Fragment>
    );
  }
}
