import React, { useState } from "react";
import KeypadButton from "./KeypadButton";
import useLoudness from "./hooks/useLoudness";
import useMuteWarning from "./hooks/useMuteWarning";
import "./OnCall.css";

const OnCall = ({ handleHangup, connection }) => {
  const [muted, setMuted] = useState(false);
  const [running, setRunning, loudness] = useLoudness();
  const [showMuteWarning] = useMuteWarning(loudness, running);

  const handleMute = () => {
    connection.mute(!muted);
    setMuted(!muted);
    setRunning(!muted);
  };

  const muteWarning = (
    <p className="warning">Are you speaking? You are on mute!</p>
  );

  return (
    <>
      {showMuteWarning && muteWarning}
      <div className="call">
        <div className="call-options py-3">
          <KeypadButton handleClick={handleMute} color={muted ? "red" : "green"}>
           <p className="mute-unmute" dangerouslySetInnerHTML={{ __html : !muted ? '<i class="fas fa-microphone"></i>' : '<i class="fas fa-microphone-slash"></i>'}}></p>
          </KeypadButton>
        </div>
        
        <div className="hang-up">
          <KeypadButton handleClick={handleHangup} color="red btn btn-sm" >
          <i className="fas fa-phone"></i>
          </KeypadButton>
        </div>
      </div>
    </>
  );
};

export default OnCall;
