import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom";
import { ApiUrl } from "../../config";
import Context from './Context';
import swal from "sweetalert";
import { uInfo } from "../../useToken";
const Box9 = (props) => {
    const params = React.useContext(Context);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        if(uInfo().user_id == 2){
            submitData.append('edited_by', 0);
        }else{
            submitData.append('edited_by', 1);
        }

        submitData.append('last_step', 1);

        fetch(`${ApiUrl}` + "update/builder/setting/" + params.user_id, {
            method: "POST",
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    params.goToBox(params.currentBox + 1);
                    params.getBuilderSetting();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    return (
        <div>
            <div className="box-center-align mt-3">
                <div className="SmallContaner my-4">
                <div className="d-flex align-items-center gap-2 StepHeading">
                        <div className="StepNumber">5 <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">Section Information</h4>
                    </div>
                    <div className="page-content-box">

                        <form method="post" onSubmit={handleSubmit}
                            encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                            <div className="WizredForm mt-3">
                                

                                <div className="border-top border-bottom border-left border-right p-4">
                                    <div className="TitleWizred d-flex align-items-center gap-2">
                                        <span className="fs-5"> c.</span> <h5>Experience & Expertise.</h5>
                                    </div>

                                    <div className="form-group mt-3">
                                        <label>How many years of experience as a coach do you have?</label>

                                        <input name="coach_experience" defaultValue={params.setting.coach_experience ?? ''}
                                            className="form-control no-border px-0"
                                            type="text" placeholder="Type your answer here..."></input>
                                    </div>

                                    <div className="form-group mt-4">
                                        <label>What are your specific areas of expertise?
                                            <br />
                                            <small>Separate each with a comma and a space. For example, "increasing profit margins, pay-per-click advertising, and optimizing conversions."

                                            </small>
                                        </label>

                                        <textarea rows={5} name="expertise" defaultValue={params.setting.expertise ?? ''}
                                            className="form-control no-border px-0" type="text" placeholder="Type your answer here..." />
                                    </div>

                                    <div className="form-group mt-4">
                                        <label>What industries do you have experience working in?
                                            <br />
                                            <small>Separate each industry with a comma and a space. For example: finance, marketing, and information technology." </small>
                                        </label>

                                        <textarea rows={5} name="industries_experience" defaultValue={params.setting.industries_experience ?? ''}
                                            className="form-control no-border px-0" type="text" placeholder="Type your answer here..." />
                                    </div>

                                </div>

                            </div>
                            <button type="submit" className="btn btn-lg btn-primary mt-2"> Next &nbsp;<i className="fas fa-check"></i></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Box9;