import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody, Table } from "reactstrap";

// Pages Components
import Miniwidget from "./Miniwidget";
import MonthlyEarnings from "./montly-earnings";
import EmailSent from "./email-sent";
import MonthlyEarnings2 from "./montly-earnings2";
import Inbox from "./inbox";
import RecentActivity from "./recent-activity";
import WidgetUser from "./widget-user";
import YearlySales from "./yearly-sales";
import LoginHistory from "./login-history";
import LatestTransactions from "./latest-transactions";
import LatestOrders from "./latest-orders";

import Helmet from "react-helmet";
import { bcrypt, uRole, uToken } from "../../useToken";
import { ApiUrl } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Badge from "react-bootstrap/Badge";
import { Link } from "react-router-dom";

//Import Action to copy breadcrumb items from local state to redux state
// import { setBreadcrumbItems } from "../../store/actions";

const Admin = (props) => {
  const breadcrumbItems = [
    { title: "Lexa", link: "#" },
    { title: "Dashboard", link: "#" },
  ];

  const [transactionsHistory, setTransactionsHistory] = useState([]);
  const [subscriptionHistory, setSubscriptionHistory] = useState([]);
  const [recentactivity, setrecenthistory] = useState([]);

  useEffect(() => {
    // props.setBreadcrumbItems('Dashboard' , breadcrumbItems)
    getData();
  }, []);

  const getData = () => {
    fetch(`${ApiUrl}` + "dashboard", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setSubscriptionHistory(response.data.subscriptionHistory);
          setTransactionsHistory(response.data.transactionsHistory);
          setrecenthistory(response.data.recentactivity);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const reports = [
    {
      title: "Orders",
      iconClass: "cube-outline",
      total: "1,587",
      average: "+11%",
      badgecolor: "info",
    },
    {
      title: "Revenue",
      iconClass: "buffer",
      total: "$46,782",
      average: "-29%",
      badgecolor: "danger",
    },
    {
      title: "Average Price",
      iconClass: "tag-text-outline",
      total: "$15.9",
      average: "0%",
      badgecolor: "warning",
    },
    {
      title: "Product Sold",
      iconClass: "briefcase-check",
      total: "1890",
      average: "+89%",
      badgecolor: "info",
    },
  ];

  return (
    <React.Fragment>
      <Row>
        <LoginHistory />
      </Row>

      <Row>
        <Col xl="8">
          <EmailSent />
        </Col>
        {/* <Col xl="4">
          <YearlySales />
        </Col> */}
        <Col xl="4">
          <YearlySales />
        </Col>
      </Row>


      <Row>
        <Col xl="4" lg="6">
          <Card style={{ height: '39vh' }}>
            <CardBody className="CoachStatistics ps-0 pt-0 pe-0">
            <div class="DashboardHeadingSection mb-4"><h4 class="card-title">Latest Transactions</h4></div>

              <div className="table-responsive" style={{ height: '100%' }}>
                <Table className="align-middle table-centered table-vertical table-nowrap">
                  <thead>
                    <tr>
                      <th>Sr#</th>
                      <th>Name</th>
                      <th>Billing Status</th>
                      <th>Amount</th>
                      <th> Last Billing Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactionsHistory.map((transaction, key) => (
                      <tr key={key}>
                        <td>{transaction.sr}</td>
                        <td>
                          {transaction.package_name}{" "}
                          <Link
                            to={
                              "/wl/subscription/history/" +
                              bcrypt(transaction.subscription_id) + '/' + bcrypt(transaction.user_id)
                            }
                            className={'pl-3'}
                          >
                            <span className="fas fa-info"></span>
                          </Link>
                        </td>
                        <td>
                          <Badge
                            bg={
                              transaction.billing_status == "Active"
                                ? "success"
                                : "danger"
                            }
                          >
                            {transaction.billing_status}
                          </Badge>
                        </td>
                        <td>
                          ${transaction.price}
                        </td>
                        <td>
                          {transaction.last_billing_date}
                        </td>
                        {/* <td>
                          <Button color="secondary" size="sm" className="waves-effect waves-light">Edit</Button>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl="4" lg="6">
          <Card style={{ height: '39vh' }}>
            <CardBody className="CoachStatistics ps-0 pt-0 pe-0">
            <div class="DashboardHeadingSection mb-4"><h4 class="card-title">Latest Subscriptions</h4></div>

              <div className="table-responsive" style={{ height: '100%' }}>
                <Table className="align-middle table-centered table-vertical table-nowrap">
                  <thead>
                    <tr>
                      <th>Sr#</th>
                      <th>Name</th>
                      <th>Interval</th>
                      <th>Billing Status</th>
                      <th>Payment Method</th>
                      <th>Amount</th>
                      <th> Last Billing Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subscriptionHistory.map((subscription, key) => (
                      <tr key={key}>
                        <td>{subscription.sr}</td>
                        <td>
                          {subscription.name}
                          <Link
                            className={'pl-3'}
                            to={
                              "/wl/subscription/history/" +
                              bcrypt(subscription.subscription_id) + '/' + bcrypt(subscription.user_id)
                            }
                          >
                            <span className="fas fa-info"></span>
                          </Link>
                        </td>
                        <td>
                          {subscription.package_name}{" "}
                          <sub> / Per {subscription.package_interval}</sub>
                        </td>
                        <td>
                          <Badge
                            bg={
                              subscription.billing_status == "Active"
                                ? "success"
                                : "danger"
                            }
                          >
                            {subscription.billing_status}
                          </Badge>
                        </td>
                        <td>{subscription.payment_method}</td>
                        <td>
                          ${subscription.price}
                        </td>
                        <td>
                          {subscription.last_billing_date}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl="4" lg="6">
          {/* recent activity */}
          {/* <RecentActivity /> */}

          <Card style={{ height: '39vh' }}>
            <CardBody className="CoachStatistics ps-0 pt-0 pe-0">
            <div class="DashboardHeadingSection mb-4"><h4 class="card-title">Recent Activity Feed</h4></div>
              <div className="table-responsive" style={{ height: '100%' }}>
                <Table className="align-middle table-centered table-vertical table-nowrap">
                  <thead>
                    <tr>
                      <th>Sr#</th>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Operation</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {recentactivity.map((recentlog, key) => (
                      <tr key={key}>
                        <td>{recentlog.sr}</td>
                        <td>
                          {recentlog.name}
                          <Link to={"coach/activity/log"} className={'pl-3'}>
                            <span className="fas fa-info"></span>
                          </Link>
                        </td>
                        <td>{recentlog.type}</td>

                        <td>{recentlog.operation}</td>

                        <td>{recentlog.created_at}</td>
                      </tr>
                    ))}
                    {/* <div className="text-center">
                  <Link to="#" className="btn btn-sm btn-primary">
                    Load More
                  </Link>
                </div> */}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </React.Fragment>
  );
};

export default Admin;
