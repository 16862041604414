import React, { useState, useEffect, useRef } from "react";
import { ApiUrl, ProductName } from "../../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { bcrypt, dcrypt, uInfo, uToken } from "../../../../useToken";
import { Table, Card, CardBody, Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";

const SectionAccordion = ({ title, content, section_id }) => {
  const [isActive, setIsActive] = useState(false);
  const [section_data, setsectiondata] = useState([]);

  useEffect(() => {
    getsectioncontent(section_id);
  }, []);

  const getsectioncontent = (section_id) => {
    if (section_id != undefined && section_id != 'undefined') {
      fetch(`${ApiUrl}` + "get/sectioncontent/" + section_id, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify({ user_id: uInfo().user_id }),
      })
        .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((response) => {
          if (response.status === true) {
            setsectiondata(response.data.records);
          } else {
                      
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        //Then with the error genereted...
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  return (
    <div className="accordion" id="accordion">
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button
            className="accordion-button fw-medium"
            type="button"
            style={{ cursor: "pointer" }}
            onClick={() => setIsActive(!isActive)}
          >
            {title}
          </button>
        </h2>
        {isActive && <div className="accordion-body">{content}</div>}
      </div>
    </div>
  );
};

export default SectionAccordion;
