import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { ApiUrl } from "../../config";
import { ProductName, AttachementsUrl } from "../../config";
import { Link } from "react-router-dom";
import { uToken } from "../../useToken";
import ProfileSettings from "../Authentication/profile-sidebar";
import swal from "sweetalert";
import SweetAlert from 'react-bootstrap-sweetalert';


class ManageCmsSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page_name: 'Global Settings',
            row: {
                id: '',
                linkedin_link: "",
                tiktok_link: "",
                youtube_link: "",
                instagram_link: "",
                facebook_link: "",
                annual_conference_url: "",
                contact: "",
                email: "",
                address: "",
                contact_widget: "",
                user_id: "",
                logo: 0
            },
            showAlert: false,
            alertType: '',
            alertTitle: '',
            alertText: ''
        };
    }



    getSettingsData = () => {
        fetch(`${ApiUrl}get/cms/settings`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${uToken()}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true && response.data.records.length > 0) {
                    const settings = response.data.records[0];
                    this.setState({
                        row: {
                            id: settings.id,
                            linkedin_link: settings.linkedin_link || "",
                            tiktok_link: settings.tiktok_link || "",
                            youtube_link: settings.youtube_link || "",
                            instagram_link: settings.instagram_link || "",
                            facebook_link: settings.facebook_link || "",
                            annual_conference_url: settings.annual_conference_url || "",
                            contact: settings.contact || "",
                            email: settings.email || "",
                            address: settings.address || "",
                            contact_widget: settings.contact_widget || "",
                            logo: settings.logo || 0,
                            user_id: settings.user_id
                        }
                    });
                } else {
                    swal({
                        title: "No settings found.",
                        icon: "warning",
                    });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    updateUserSocialLinks = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append("id", this.state.row.id);
        fetch(`${ApiUrl}add/cms/settings`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${uToken()}`
            },
            body: formData
        })
        .then((response) => response.json())
        .then((data) => {
            if (data.status === true) {
                this.setState({
                    showAlert: true,
                    alertType: 'success',
                    alertTitle: 'Success',
                    alertText: 'Settings updated successfully.'
                });
                if (this._isMounted) {
                    this.getSettingsData();
                }
            } else {
                this.setState({
                    showAlert: true,
                    alertType: 'error',
                    alertTitle: 'Error',
                    alertText: data.message
                });
            }
        })
        .catch((error) => {
            console.error("Error:", error);
            this.setState({
                showAlert: true,
                alertType: 'error',
                alertTitle: 'Error',
                alertText: 'An error occurred while updating settings.'
            });
        });
    };
    

    componentDidMount() {
        this._isMounted = true;
        this.getSettingsData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    render() {
        const { row, showAlert, alertType, alertTitle, alertText } = this.state;
    
        return (
            <React.Fragment>
                {showAlert && (
                    <SweetAlert
                        type={alertType}
                        title={alertTitle}
                        onConfirm={() => this.setState({ showAlert: false })}
                    >
                        {alertText}
                    </SweetAlert>
                )}
                <Row>
                    <Col lg="2" className="pl-0">
                        <ProfileSettings menuOpen={false} />
                    </Col>
                    <Col lg="10">
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4>{this.state.page_name}</h4>
                                <ol className="breadcrumb m-0">
                                    <li key={0} className="breadcrumb-item active">{ProductName}</li>
                                    <li key={1} className="breadcrumb-item">
                                        <Link to="#">{this.state.page_name}</Link>
                                    </li>
                                </ol>
                            </div>
                        </Col>
                        <Row>
                            <AvForm
                                className="form-horizontal"
                                autoComplete="off"
                                onValidSubmit={this.updateUserSocialLinks}
                            >
                                <div className="row">
                                    <div className="col-md-6 form-group mb-3">
                                        <AvField
                                            name="linkedin_link"
                                            label="LinkedIn"
                                            className="form-control"
                                            type="text"
                                            placeholder="Enter LinkedIn profile link"
                                            value={row.linkedin_link}
                                        />
                                    </div>
                                    <div className="col-md-6 form-group mb-3">
                                        <AvField
                                            name="facebook_link"
                                            label="Facebook"
                                            className="form-control"
                                            type="text"
                                            placeholder="Enter Facebook Page Link"
                                            value={row.facebook_link}
                                        />
                                    </div>
                                    <div className="col-md-6 form-group mb-3">
                                        <AvField
                                            name="tiktok_link"
                                            label="Tiktok"
                                            className="form-control"
                                            type="text"
                                            placeholder="Enter Tiktok Profile Link"
                                            value={row.tiktok_link}
                                        />
                                    </div>
                                    <div className="col-md-6 form-group mb-3">
                                        <AvField
                                            name="instagram_link"
                                            label="Instagram"
                                            className="form-control"
                                            type="text"
                                            placeholder="Enter Instagram Profile Link"
                                            value={row.instagram_link}
                                        />
                                    </div>
                                    <div className="col-md-6 form-group mb-3">
                                        <AvField
                                            name="youtube_link"
                                            label="Youtube"
                                            className="form-control"
                                            type="text"
                                            placeholder="Enter Youtube Profile"
                                            value={row.youtube_link}
                                        />
                                    </div>
                                    <div className="col-md-6 form-group mb-3">
                                        <AvField
                                            name="annual_conference_url"
                                            label="Annual Conference Url"
                                            className="form-control"
                                            type="text"
                                            placeholder="Enter annual conference url"
                                            value={row.annual_conference_url}
                                        />
                                    </div>
                                    <div className="col-md-6 form-group mb-3">
                                        <AvField
                                            name="contact"
                                            label="Contact"
                                            className="form-control"
                                            type="text"
                                            placeholder="Enter Contact Link"
                                            value={row.contact}
                                        />
                                    </div>
                                    <div className="col-md-6 form-group mb-3">
                                        <AvField
                                            name="email"
                                            label="Email"
                                            className="form-control"
                                            type="text"
                                            placeholder="Enter Email Link"
                                            value={row.email}
                                        />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <AvField
                                            name="address"
                                            label="Address"
                                            className="form-control"
                                            type="text"
                                            placeholder="Enter address"
                                            value={row.address}
                                        />
                                    </div>
                                    <div className="col-md-6 form-group mb-1">
                                        <label>Logo</label>
                                        <input
                                            name="logo"
                                            className="form-control"
                                            type={'file'}
                                        />
                                        {row.logo != 0 && (
                                            <img width={"200px"} src={`${AttachementsUrl}/user_${row.user_id}/settings/${row.logo}`} className="img img-thumbnaikl" alt="Logo" />
                                        )}
                                    </div>
                                    <div className="col-md-6 form-group mb-3">
                                        <label>Contact Widget</label>
                                        <textarea
                                            name="contact_widget"
                                            className="form-control"
                                            placeholder="Enter contact widget"
                                            defaultValue={row.contact_widget}
                                            rows={5}
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <Button type="submit" className="Green">
                                        Update Setting
                                    </Button>
                                </div>
                            </AvForm>
                        </Row>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
    
}

export default ManageCmsSettings;
