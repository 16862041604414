import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import Loader from "react-loader";
import {
    Row,
    Col,
    Container,
    Card,
    CardBody,
    Alert,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button
} from "reactstrap";


import { ApiUrl, logoLightPng, ProductName } from "../../config.js";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Helmet } from "react-helmet";
import { AvField, AvForm } from "availity-reactstrap-validation";
import swal from 'sweetalert';
import { dcrypt } from "../../useToken.js";

//i18n

const RenewSubscription = (props) => {
    const [vData, setVData] = useState({});
    const [loaded, setLoaded] = useState(true);
    const [msg, setMsg] = useState('');
    const [showCode, setShowCode] = useState(false);
    const [currentPackage, setcurrentPackage] = useState({
        package_data: {}
    });
    const [packages, setpackages] = useState([]);
    const [modal, setModal] = useState(false);

    useEffect(() => {
        console.log(props.match.params.uid)
        userSubscription();
    }, []);


    const userSubscription = () => {
        let values = {};
        values.user_id = dcrypt(props.match.params.uid);
        values.coach_id = dcrypt(props.match.params.id);

        fetch(`${ApiUrl}` + 'get/user/previous/subscription', {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((res) => {
                if (res.status === true) {
                    setcurrentPackage(res.data.current_subscription);
                    setpackages(res.data.packages)
                } else {
                    swal({
                        title: "Sorry...",
                        text: res.message,
                        button: "Ok",
                        icon: "warning",
                    })
                    // setLoaded(true);
                }

            })
            //Then with the error genereted...
            .catch((error) => {
                console.error('Error:', error);
            });
    }


    const changeSubscription = (package_id, subscription_id) => {
        swal({
            title: "Are you sure?",
            text: "You want to perform this action",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    setLoaded(false);
                    fetch(`${ApiUrl}` + "user/change/subscription", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            package_id: package_id,
                            current_subscription: subscription_id,
                            user_id: dcrypt(props.match.params.uid),
                            coach_id: dcrypt(props.match.params.id)
                        }),
                    })
                        .then((response) => response.json())
                        .then((response) => {
                            setLoaded(true);
                            if (response.status === true) {
                                swal({
                                    title: "Success!",
                                    text: "Action performed successfully",
                                    type: "success"
                                }).then(function () {
                                    setTimeout(() => window.location.href = '/login', 500);
                                });

                            } else {
                                if (response.data.payment_customer_id == '') {
                                    swal({
                                        title: "Required Card!",
                                        text: "Please add your card details to upgrade to the selected package.",
                                        icon: "error",
                                    })
                                    setModal(true);
                                    return;
                                }
                                setLoaded(true);
                                toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                            }
                        })
                        .catch((error) => {
                            setLoaded(true);
                            console.error("Error:", error);
                        });
                }
            });
    }

    const addcarddetails = async (event, values) => {
        setLoaded(false);
        values.user_id = dcrypt(props.match.params.uid);
        values.coach_id = dcrypt(props.match.params.id);
        fetch(`${ApiUrl}` + "add/coach/Paymentmethod", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                setLoaded(true);
                if (response.status === true) {
                    swal({
                        title: "Success",
                        text: "Card added successfully. Now you can update/upgrade your package.",
                        icon: "success",
                    })
                    setModal(false)
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    let currentDescription = currentPackage.package_data.description
    ? JSON.parse(currentPackage.package_data.description)
    : "{}";
    return (

        <React.Fragment>

            <Helmet>
                <title>Renew Subscription</title>
            </Helmet>

            <div className="account-pages my-5 pt-sm-5" >

                <Container >
                    <ToastContainer />
                    <Loader loaded={loaded} className="spinner"></Loader>
                    <Row className="justify-content-center" >

                        <Col md={12}
                            lg={12}
                            xl={12} >

                            <Card className="overflow-hidden" >
                                <CardBody className="pt-0" >
                                    <h3 className="text-center mt-5 mb-4" >
                                        <Link to="#"
                                            className="d-block auth-logo" >

                                            <img src={logoLightPng}
                                                alt=""
                                                height="45"
                                                className="auth-logo-dark" />
                                        </Link>
                                    </h3>
                                    <Alert color="warning">{dcrypt(props.match.params.reason)}</Alert>
                                    {
                                        msg && msg != '' && (
                                            <Alert color="info">{msg}</Alert>

                                        )
                                    }
                                    {currentPackage.package_data.package_type == "custom" ?
                                        <Col md={12} key={1}>
                                            <div className="card mb-5 mb-lg-0 my-2 justify-content-center">
                                                <div className="card-body package">
                                                    <h5 className="card-title text-muted text-uppercase text-center">
                                                        {currentPackage.package_data.name}
                                                    </h5>
                                                    <h6 className="card-price text-center">
                                                        ${currentPackage.package_data.price}
                                                        <span className="period">
                                                            /{currentPackage.package_data.package_interval}
                                                        </span>
                                                    </h6>
                                                    <hr />
                                                    <ul className="fa-ul">
                                                        {currentDescription && currentDescription.map((desc, i) => {
                                                            return (
                                                                <li key={i} className="border-bottom py-2">
                                                                    <span className="fa-li">
                                                                        <i className="fas fa-check"></i>
                                                                    </span>
                                                                    {desc.description_meta}
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                    <div className="d-grid">
                                                        <button
                                                            className="btn btn-primary text-uppercase"
                                                            onClick={() =>
                                                                changeSubscription(
                                                                    currentPackage.package_id,
                                                                    currentPackage.subscription_id
                                                                )
                                                            }
                                                        >Renew</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        :
                                        <Row>
                                            {packages.map((row, i) => {
                                                if (row.package_interval == "Monthly") {
                                                    let description = JSON.parse(
                                                        row.description
                                                    );

                                                    return (
                                                        <Col md={3} key={i}>
                                                            <div className="card mb-5 mb-lg-0 my-2">
                                                                <div className="card-body package">
                                                                    <h5 className="card-title text-muted text-uppercase text-center">
                                                                        {row.name}
                                                                    </h5>
                                                                    <h6 className="card-price text-center">
                                                                        ${row.price}
                                                                        <span className="period">
                                                                            /{row.package_interval}
                                                                        </span>
                                                                    </h6>
                                                                    <hr />
                                                                    <ul className="fa-ul">
                                                                        {description.map((desc, i) => {
                                                                            return (
                                                                                <li key={i} className="border-bottom py-2">
                                                                                    <span className="fa-li">
                                                                                        <i className="fas fa-check"></i>
                                                                                    </span>
                                                                                    {desc.description_meta}
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                    <div className="d-grid">
                                                                        {Number(currentPackage
                                                                            .price) >= Number(row.price) &&
                                                                            currentPackage
                                                                                .payment_method ==
                                                                            "stripe" && (
                                                                                <button
                                                                                    onClick={() =>
                                                                                        changeSubscription(
                                                                                            row.package_id,
                                                                                            currentPackage.subscription_id
                                                                                        )
                                                                                    }
                                                                                    className="btn btn-primary text-uppercase"
                                                                                    disabled={currentPackage.package_data.package_id == row.package_id ? false : true}
                                                                                >
                                                                                    {
                                                                                        currentPackage.package_data.package_id == row.package_id ?
                                                                                            "Current Package" : " -- "
                                                                                    }

                                                                                </button>
                                                                            )}
                                                                        {Number(currentPackage
                                                                            .price) < Number(row.price) &&
                                                                            currentPackage
                                                                                .payment_method ==
                                                                            "stripe" && (
                                                                                <button
                                                                                    onClick={() =>
                                                                                        changeSubscription(
                                                                                            row.package_id,
                                                                                            currentPackage
                                                                                                .subscription_id
                                                                                        )
                                                                                    }
                                                                                    disabled={row.package_id <= 2 ? false : true}
                                                                                    className="btn btn-primary text-uppercase code-green border-0"
                                                                                >

                                                                                    {
                                                                                        currentPackage.package_data.package_id == row.package_id ?
                                                                                            "Current Package" : " --"
                                                                                    }
                                                                                    {" "} <i className="fas fa-level-up-alt"></i>
                                                                                </button>
                                                                            )}
                                                                        {currentPackage
                                                                            .payment_method !==
                                                                            "stripe" && (
                                                                                <button
                                                                                    onClick={() =>
                                                                                        changeSubscription(
                                                                                            row.package_id,
                                                                                            currentPackage
                                                                                                .subscription_id
                                                                                        )
                                                                                    }
                                                                                    disabled={(Number(currentPackage.price) > Number(row.price) ? true : false) || (row.package_id <= 2 ? false : true) || (currentPackage.package_data.package_id == row.package_id ? true : false)}
                                                                                    className="btn btn-primary text-uppercase"
                                                                                >
                                                                                    {
                                                                                        currentPackage.package_data.package_id == row.package_id &&
                                                                                        "Current Package"
                                                                                    }

                                                                                    {
                                                                                        Number(currentPackage.price) > Number(row.price) && currentPackage.package_data.package_id != row.package_id &&
                                                                                        "--"
                                                                                    }

                                                                                    {
                                                                                        Number(currentPackage.price) < Number(row.price) && currentPackage.package_data.package_id != row.package_id &&
                                                                                        "Upgrade"
                                                                                    }
                                                                                </button>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    );
                                                }
                                            })}
                                        </Row>
                                    }
                                </CardBody>
                            </Card>

                            <Modal
                                isOpen={modal}
                                role="dialog"
                                centered={true}
                                className="exampleModal"
                                tabIndex="-1"
                                toggle={() => {
                                    setModal(!modal);
                                }}
                            >
                                <div className="modal-content">
                                    <ModalHeader
                                        toggle={() => {
                                            setModal(!modal);
                                        }}
                                        className="text-transform-capitalize"
                                    >
                                        Add Payment Method
                                    </ModalHeader>
                                    <AvForm
                                        onValidSubmit={addcarddetails}
                                        className="needs-validation"
                                    >
                                        <ModalBody>
                                            <Row className="mt-3">
                                                <Col className="col-6">
                                                    <label className="control-label">Card Number</label>
                                                    <AvField
                                                        name="cardnumber"
                                                        className="form-control"
                                                        type="number"
                                                        Placeholder="Enter Card Number"
                                                        value=""
                                                        required
                                                    />
                                                </Col>
                                                <Col className="col-6">
                                                    <label className="control-label"> CVC</label>
                                                    <AvField
                                                        name="cvc"
                                                        className="form-control"
                                                        type="number"
                                                        Placeholder="CVC"
                                                        value=""
                                                        required
                                                    />
                                                </Col>
                                            </Row>

                                            <Row className="mt-3">
                                                <Col className="col-6">
                                                    <AvField
                                                        name="expirationdateMM"
                                                        label="MM"
                                                        className="form-control"
                                                        type="number"
                                                        Placeholder="MM"
                                                        value=""
                                                        required
                                                    />
                                                </Col>
                                                <Col className="col-6">
                                                    <AvField
                                                        name="expirationdateYYYY"
                                                        label="YYYY"
                                                        className="form-control"
                                                        type="number"
                                                        Placeholder="YYYY"
                                                        value=""
                                                        required
                                                    />
                                                </Col>
                                            </Row>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button
                                                type="button"
                                                color="secondary"
                                                onClick={() => {
                                                    setModal(false);
                                                }}
                                            >
                                                Close
                                            </Button>
                                            <button
                                                className="btn btn-primary w-md waves-effect waves-light"
                                                type="submit"
                                            >
                                                Save
                                            </button>
                                        </ModalFooter>
                                    </AvForm>
                                </div>
                            </Modal>
                        </Col>
                    </Row>

                </Container>
            </div>

        </React.Fragment >


    );
};

export default RenewSubscription;
