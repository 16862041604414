import React, { Component, Fragment } from "react";
import Select from "react-select";
import {
  Row,
  Col,
  Card,
  Button,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Helmet from "react-helmet";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  dcrypt,
  uToken,
} from "../../useToken";
import { ApiUrl, ProductName,ToastAutoClose} from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";

export default class EditFromEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      record: {
        from_name: "",
        from_email: "",
      },
      page_name: this.props.match.params.id ? "Edit" : "Create",
    };
  }

  componentDidMount() {

    this.state.id != null && this.getData();
  }
  
  getData = () => {
    fetch(`${ApiUrl}` + "get/fromEmail/record", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            record: response.data.record,
          });
        } else {
            toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };



  handleSubmit = async (event, values) => {
    values.body = this.state.editor;
    if (this.state.id) {
      values.id = this.state.record.id ? this.state.record.id : -1;
    }
    fetch(`${ApiUrl}` + "edit/from/email", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          setTimeout(() => this.props.history.push("/settings/profile"), 1000);
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name} </title>
        </Helmet>
        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.page_name} </h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#"> {this.state.page_name} </Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                type="button"
                color="secondary"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
        <ToastContainer />
        <Row>
          <Col className="col-12">
            <Card>
              <AvForm
                onValidSubmit={this.handleSubmit}
                model={this.state.record}
                ref={(c) => (this.form = c)}
                className="needs-validation"
              >
                <ModalBody>
                  <div className="mb-3">
                    <AvField
                      name="from_name"
                      value={this.state.record.from_name}
                      label="From Name"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <AvField
                      name="from_email"
                      value={this.state.record.from_email}
                      label="From Email"
                      readOnly
                      className="form-control"
                      required
                    />
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => this.props.history.goBack()}
                  >
                    Cancel
                  </Button>
                  <button
                    className="btn btn-primary waves-effect waves-light"
                    type="submit"
                  >
                    Submit
                  </button>
                </ModalFooter>
              </AvForm>
            </Card>
          </Col>
        </Row>
        {/* /////////////////////////////////////////////// */}
      </React.Fragment>
    );
  }
}
