import React, { useState, useEffect, useRef } from "react";
import Loader from "react-loader";
import { ApiUrl } from "../../config";
import { bcrypt, dcrypt, uToken } from "../../useToken";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import swal from "sweetalert";

import {
  Modal
} from "reactstrap";
require("highcharts/modules/exporting")(Highcharts);


const Implementation = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [toggleSwitch, settoggleSwitch] = useState(true);

  const [financial_records, setFinancialRecords] = useState([]);
  const [records, setRecords] = useState({
    date: '',
    noweeks: '',
    dates: [],
    records: []
  });
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  const [finicialModal, setFinicialModal] = useState(false);
  const [financial, setFinicial] = useState({
    id: 0,
    total_revenue: 0,
    gross_profit: 0,
    net_profit: 0,
  });


  useEffect(() => {
    getImplementation();
    getFinancialBreakdown();
  }, []);
  const getFinancialBreakdown = () => {
    fetch(`${ApiUrl}` + "get/profitCenter/financialBreakdown", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        id: props.assessment,
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          console.log(response.data.records.net_profit, 'daata')
          setFinancialRecords(response.data.records);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          setLoaded(true);
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        setLoaded(true);
      });
  };
  const getImplementation = () => {
    fetch(`${ApiUrl}` + "assessments/implementation/flow", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        id: props.assessment,
        slug: props.slug,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setRecords(response.data);
          setLoaded(true);
        } else {
          setLoaded(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoaded(true);
      });
  };


  const customClass = ['add_quarterly_reviews', 'initial_planning_meeting'];

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...records.records];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;

    setRecords((prevState) => ({
      ...prevState,
      records: copyListItems,
    }));


    // this.setState({
    //     records: copyListItems
    // });
    updateSequence(copyListItems);
  };

  const updateSequence = (items_arr) => {
    fetch(`${ApiUrl}` + "update/implementation/order", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ items_arr: items_arr }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {

          // toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const impWeekUpdation = (event) => {
    event.preventDefault();
    fetch(`${ApiUrl}` + "update/implementation/week", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ imp_id: event.target.name, imp_weeks: event.target.value }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          getImplementation();
          // toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const getFinancialBreakdownByMonth = (month) => {
    fetch(`${ApiUrl}` + "get/financial/breackdown/month", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ assessment_id: props.assessment, month: month }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setFinicial(response.data.record);
          // toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const closeFinanicalModal = () => {
    setFinicialModal(false);
    setFinicial({
      id: 0,
      total_revenue: 0,
      gross_profit: 0,
      net_profit: 0,
    });
  }
  const handleFinancialBreakdownSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    submitData.append('assessment_id', props.assessment);
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }

    fetch(`${ApiUrl}` + "submit/financial/breakdown", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          getFinancialBreakdown();
          closeFinanicalModal();
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const renderTd = () => {
    let count = -1;
    let continues = 0;
    for (const i of [1, 2, 3]) {

    }
    return records.records.map((row, r) => {
      count = records.records[r - 1] ? (count = count + records.records[r - 1].imp_weeks) : (count = 0);
      return (
        <tr key={'mair' + r}
          onDragStart={(e) => dragStart(e, r)}
          onDragEnter={(e) => dragEnter(e, r)}
          onDragEnd={drop}
          draggable>
          <td className="implementation-td"> <div style={{ textAlign: 'left', paddingLeft: '10px' }} className={`text-transform-capitalize ${customClass.includes(row.type_key) ? 'meeting' : ''}`}>
            {!customClass.includes(row.type_key) ? (
              <>
                <Link to={`/assessment/${bcrypt(row.assessment_id)}/${row.type_key}`}>{row.cat_name}</Link>
              </>
            ) : row.cat_name}

            {/* <Link to={`/assessment/${bcrypt(row.assessment_id)}/implementation/${bcrypt(10)}/${row.type_key}`}>{row.type_key.replaceAll('_', ' ')}</Link> */}

            <span className="pull-right mr-5">{row.percentage}%</span>
          </div>
          </td>
          <td>
            <select className="form-control" name={`${row.imp_id}`} value={row.imp_weeks} onChange={(e) => impWeekUpdation(e)}>
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>

            </select>

            {/* <input type={'number'} className="form_control" name={`${row.imp_id}`}
                value={row.imp_weeks} onChange={(e) => impWeekUpdation(e)}></input>  */}
          </td>
          {

            [...Array(Number(records.noweeks))].map((x, i) => {
              if (continues > 0) {
                continues = continues - 1;
                return;
              }

              if (count == i) {
                continues = row.imp_weeks - 1;
                return (
                  <td colSpan={row.imp_weeks} key={'child' + i}>
                    <div style={{ background: row.color_code }} className={`imp-time rounded-3 ${customClass.includes(row.type_key) ? 'meeting' : ''}`}>{row.imp_weeks}</div>
                  </td>
                );
              } else {
                return <td></td>;
              }
              // return (
              //     count == i ? (
              //         <td colSpan={row.imp_weeks} key={'child' + i}>
              //             <div className={`imp-time rounded-3 ${customClass.includes(row.type_key) ? 'meeting' : ''}`}>{row.imp_weeks}</div>
              //         </td>

              //     ) : (
              //         // i < count && (
              //         <td>{count}-- {i}</td>
              //         // )


              //     )
              // )

            })}

        </tr>
      )
    })
  }
  return (
    <React.Fragment>
      <Loader loaded={loaded}></Loader>
      <div className="table-responsive WordSection1 survey card card-body " style={{ minHeight: '350px' }}>
        <div className="form-check form-switch mb-3" >
          <input
            type="checkbox"
            style={{ float: "right" }}
            className="form-check-input"
            id="customSwitch2"
            onClick={e => {
              settoggleSwitch(!toggleSwitch)
            }}
          />
        </div>
        <h6 className="h-6 d-none">Implementation starts: {records.date}</h6>

        {
          loaded && (
            toggleSwitch ? (
              <table className="table" >
                <thead className="sub-nav-bg">
                  <tr >
                    <th style={{ textAlign: 'left' }}>Pathway to Profit</th>
                    <th style={{ textAlign: 'left' }}><small >WEEKS</small></th>
                    {

                      records.dates.map((row, i) => {
                        return <OverlayTrigger
                          key={`auto-${i}`}
                          placement={'auto'}
                          overlay={
                            <Tooltip>
                              {row.month_year}
                            </Tooltip>
                          }
                        >
                          <th colSpan={row.week}> {row.month} </th>
                        </OverlayTrigger>
                      })
                    }
                  </tr>
                </thead>
                <tbody id="MainHTML" >
                  {renderTd()}

                </tbody>
              </table>
            ) : (
              <div className="card card-body">
                <button onClick={() => setFinicialModal(true)} className="finincial-break-down--edit-btn btn btn-link btn-sm btn-block"><i className="fas fa-edit"></i></button>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    exporting: {
                      enabled: false
                    },
                    chart: {
                      type: 'column'
                    },

                    title: {
                      text: '<small>' + props.name + '</small>',
                      useHTML: true,
                      align: 'left'
                    },
                    subtitle: {
                      text: '<h3><b>Financial Breakdown</b></h3>',
                      useHTML: true,
                      align: 'left'
                    },
                    xAxis: {
                      categories: [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December'
                      ],
                      crosshair: true
                    },
                    yAxis: {
                      title: {
                        useHTML: true,
                        text: ''
                      }
                    },
                    tooltip: {
                      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
                      footerFormat: '</table>',
                      shared: true,
                      useHTML: true
                    },
                    plotOptions: {
                      column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                      }
                    },
                    legend: {
                      align: 'center',
                      x: -30,
                      verticalAlign: 'top',
                      y: 25,
                      floating: true,
                      backgroundColor:
                        Highcharts.defaultOptions.legend.backgroundColor || 'white',
                      borderColor: '#CCC',
                      borderWidth: 1,
                      shadow: false
                    },
                    series: [{
                      name: 'Net Profit',
                      color: "#008037",
                      data: financial_records.net_profit,
                    }, {
                      name: 'Gross Profit',
                      color: "#a0a0a0",
                      data: financial_records.gross_profit

                    }, {
                      name: 'Total Revenue',
                      color: "#00002A",
                      data: financial_records.total_revenue

                    }]
                  }}
                />
              </div>
            )
          )}


        <Modal
          isOpen={finicialModal}
          fade={true}
          size="lg"
          className="FinancialBreakdown"
          toggle={() => closeFinanicalModal()}
          centered={true}
        >
          <div className="card card-body">
            <div className="modalQuestion ">
              <button className="mb-3 btn btn-primary BtnCenter">Profit & Loss Statement</button>
              <button onClick={() => closeFinanicalModal()} className="mb-3 btn btn-link pull-right BtnCenterClose"><i className="fas fa-times"></i></button>

              <form method="post" onSubmit={handleFinancialBreakdownSubmit}
                encType="multipart/form-data" className="form-horizontal" id="selection-myform">

                <div className="mb-3 row">
                  <label for="example-text-input" className="col-md-2 col-form-label"></label>
                  <div className="col-md-10">
                    <select required name="calculation_month" className="form-control" onChange={(e) => getFinancialBreakdownByMonth(e.target.value)}>
                      <option value={''}>Select Month</option>
                      <option value={'01'}>January</option>
                      <option value={'02'}>February</option>
                      <option value={'03'}>March</option>
                      <option value={'04'}>April</option>
                      <option value={'05'}>May</option>
                      <option value={'06'}>June</option>
                      <option value={'07'}>July</option>
                      <option value={'08'}>August</option>
                      <option value={'09'}>September</option>
                      <option value={'10'}>October</option>
                      <option value={'11'}>November</option>
                      <option value={'12'}>December</option>
                    </select>
                  </div>
                </div>

                <input type={'hidden'} name="id" value={financial.id}></input>
                <div className="mb-3 row">
                  <label for="example-text-input" className="col-md-2 col-form-label">Total Revenue</label>
                  <div className="col-md-10">
                    <input required className="form-control" type={'number'}
                      onChange={(e) => setFinicial({ ...financial, ['total_revenue']: e.target.value })}
                      name="total_revenue" value={financial.total_revenue} />
                  </div>
                </div>

                <div className="mb-3 row">
                  <label for="example-text-input" className="col-md-2 col-form-label">Gross Profit</label>
                  <div className="col-md-10">
                    <input required type={'number'}
                      onChange={(e) => setFinicial({ ...financial, ['gross_profit']: e.target.value })}
                      className="form-control" name="gross_profit" value={financial.gross_profit} />
                  </div>
                </div>

                <div className="mb-3 row">
                  <label for="example-text-input" className="col-md-2 col-form-label">Net Profit</label>
                  <div className="col-md-10">
                    <input required type={'number'}
                      onChange={(e) => setFinicial({ ...financial, ['net_profit']: e.target.value })}
                      className="form-control" name="net_profit" value={financial.net_profit} />
                  </div>
                </div>


                <div className="row">
                  <div className="col-md-8">
                    <button type="submit" className="btn btn-primary pull-right w-50" >Save</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </div>

    </React.Fragment >
  );
};

export default Implementation;
