import React, { Component, Fragment } from "react"
import { Row, Col, Card, CardBody, CardTitle, Form, Label, Button, Modal, ModalHeader, Input, ModalBody, ModalFooter } from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import ReactDatatable from '@ashvin27/react-datatable';

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet"
import axios from "axios"
import { AvForm, AvField, AvInput, AvFeedback, AvGroup } from "availity-reactstrap-validation"
import { bcrypt, getPages, getPermission, getRoles, isAllowed, uInfo, uRole, uToken } from "../../useToken";
import { ApiUrl, ProductName, AttachementsUrl } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select"
import { Link } from "react-router-dom";
import ProfileSettings from "../Authentication/profile-sidebar";
import Loader from "react-loader";
import { Stepper, Step } from "react-form-stepper";

export default class BuildWebsite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            record: {},
            loader: true,
            currentStep: 0,
            records: [],
            domain: ''
        }
    }


    componentDidMount() {
        this.getData();
    }

    getData = () => {
        fetch(`${ApiUrl}` + 'get/branding', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + uToken()
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    if (response.data.record != null) {
                        this.setState({
                            record: response.data.record,
                        });
                    }
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }

            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }


    handleSubmit = async (event) => {
        this.setState({ loader: false });
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            if (key == 'domain') {
                submitData.append('domain', value + '.profitcoach.app');
            } else {
                submitData.append(key, value);
            }
        }
        fetch(`${ApiUrl}` + "update/branding", {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    this.getData();
                    this.setState({ currentStep: 1 });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
                this.setState({ loader: true });
            })
            .catch((error) => {
                this.setState({ loader: true });

                console.error("Error:", error);
            });
    };

    changeTheme = (num) => {
        fetch(`${ApiUrl}` + "update/builder/theme", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ select_theme: num }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    if (uInfo().domain_status != response.data.user_domain_status) {
                        let newtoken = {
                            domain_status: response.data.user_domain_status,
                        };
                        const authUser = {
                            ...JSON.parse(localStorage.getItem("authUser")),
                            ...newtoken,
                        };
                        localStorage.setItem("authUser", JSON.stringify(authUser));
                    }
                    this.setState({ currentStep: 2 });
                    this.getContentData();
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    getContentData = () => {
        fetch(`${ApiUrl}` + 'get/content/pages', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + uToken()
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {

                    this.setState({
                        records: response.data.records,
                        domain: response.data.domain
                    });

                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }

            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }

    changeHandler = (e) => {
        if (e.target.files[0].type.match('image.*')) {
            const preview = document.querySelector('#preview_img');
            var reader = new FileReader();
            reader.onload = () => {
                preview.src = reader.result;
            };
            reader.readAsDataURL(e.target.files[0]);
        } else {
            toast('Olny image allowed', { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

        }
    };

    changeHandlerFavicon = (e) => {
        if (e.target.files[0].type.match('image.*')) {
            const preview = document.querySelector('#preview_img_favicon');
            var reader = new FileReader();
            reader.onload = () => {
                preview.src = reader.result;
            };
            reader.readAsDataURL(e.target.files[0]);
        } else {
            toast('Olny image allowed', { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

        }
    };

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="2" className="pl-0">
                        <ProfileSettings menuOpen={false} props={this.props} />
                    </Col>
                    <Col lg="10">
                        <Loader loaded={this.state.loader}></Loader>

                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <Stepper activeStep={this.state.currentStep}>
                                            <Step label="Branding" />
                                            <Step label="Theme" />
                                            <Step label="Content" />
                                        </Stepper>

                                        {this.state.currentStep == 0 ? (

                                            <form onSubmit={this.handleSubmit} method="post"
                                                encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                                                <Row>
                                                    <Col md="12">
                                                        <div className="form-group mb-3">
                                                            <label>Brand/Business Name</label>
                                                            <input
                                                                name="brand_name"
                                                                label="Brand Nane"
                                                                defaultValue={this.state.record.brand_name}
                                                                className="form-control"
                                                                placeholder="Enter brand name"
                                                                required
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col md="12">
                                                        <div className="form-group mb-3">
                                                            <label>Domain Name</label>
                                                            <div className="input-group">
                                                                <input
                                                                    required
                                                                    defaultValue={this.state.record.domain}
                                                                    pattern="^[a-z](-?[a-z])*$"
                                                                    placeholder="example , ex-example-ex , e.g..."
                                                                    className="form-control" name="domain" />
                                                                <div className="input-group-text">.profitcoach.app</div>
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col md="6">
                                                        <div className="form-group mb-3">
                                                            <label>Logo</label>
                                                            <input
                                                                name="logo"
                                                                label="Select logo"
                                                                accept="image/*"
                                                                className="form-control"
                                                                type="file"
                                                                onChange={(e) => this.changeHandler(e)}
                                                            />
                                                        </div>
                                                            <img id="preview_img" height={50} src={this.state.record.logo} className="img img-thumbnail mb-1" />
                                                       
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="form-group mb-3">
                                                            <label>Favicon</label>
                                                            <input
                                                                name="favicon"
                                                                label="Select favicon"
                                                                accept="image/*"
                                                                className="form-control"
                                                                type="file"
                                                                onChange={(e) => this.changeHandlerFavicon(e)}
                                                            />
                                                        </div>
                                                             <img id="preview_img_favicon" height={50} src={this.state.record.favicon} className="img img-thumbnail mb-1" />
                                                        
                                                    </Col>




                                                    <Col md="2">
                                                        <div className="mb-3">
                                                            <div className="button-items">
                                                                <Button
                                                                    className="btn-md"
                                                                    color="primary"
                                                                    type="submit"
                                                                    disabled={!this.state.loader}
                                                                >
                                                                    Submit
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </form>
                                        ) : this.state.currentStep == 1 ? (
                                            <Row className="mt-1 wldomain_setting">
                                                <Col lg={4}>
                                                    <div className="card ThemeCard">

                                                        <div className="card-body">
                                                            <img src={`${AttachementsUrl}/default/website_builder_resources/themes/index1.png`} width={'100%'} />
                                                        </div>
                                                        <div className="ThemeTitleButton">
                                                            <div className="card-header h4 font-16 mt-0 card-header">
                                                                Theme 1
                                                            </div>
                                                            <button
                                                                onClick={() => this.changeTheme(1)}
                                                                className="waves-effect waves-light btn btn-secondary text-white bg-secondary"
                                                            >
                                                                Pick
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col lg={4}>
                                                    <div className="card ThemeCard">

                                                        <div className="card-body">
                                                            <img src={`${AttachementsUrl}/default/website_builder_resources/themes/index2.png`} width={'100%'} />
                                                        </div>
                                                        <div className="ThemeTitleButton">
                                                            <div className="card-header h4 font-16 mt-0 card-header">
                                                                Theme 2
                                                            </div>
                                                            <button
                                                                onClick={() => this.changeTheme(2)}
                                                                className="waves-effect waves-light btn btn-secondary text-white bg-secondary"
                                                            >
                                                                Pick
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col lg={4}>
                                                    <div className="card ThemeCard">

                                                        <div className="card-body">
                                                            <img src={`${AttachementsUrl}/default/website_builder_resources/themes/index3.png?p=1`} width={'100%'} />
                                                        </div>
                                                        <div className="ThemeTitleButton">
                                                            <div className="card-header h4 font-16 mt-0 card-header">
                                                                Theme 3
                                                            </div>
                                                            <button
                                                                onClick={() => this.changeTheme(3)}
                                                                className="waves-effect waves-light btn btn-secondary text-white bg-secondary"
                                                            >
                                                                Pick
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="card ThemeCard">

                                                        <div className="card-body">
                                                            <img src={`${AttachementsUrl}/default/website_builder_resources/themes/index4.png`} width={'100%'} />
                                                        </div>
                                                        <div className="ThemeTitleButton">
                                                            <div className="card-header h4 font-16 mt-0 card-header">
                                                                Theme 4
                                                            </div>
                                                            <button
                                                                onClick={() => this.changeTheme(4)}
                                                                className="waves-effect waves-light btn btn-secondary text-white bg-secondary"
                                                            >
                                                                Pick
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="card ThemeCard">

                                                        <div className="card-body">
                                                            <img src={`${AttachementsUrl}/default/website_builder_resources/themes/index5.png`} width={'100%'} />
                                                        </div>
                                                        <div className="ThemeTitleButton">
                                                            <div className="card-header h4 font-16 mt-0 card-header">
                                                                Theme 5
                                                            </div>
                                                            <button
                                                                onClick={() => this.changeTheme(5)}
                                                                className="waves-effect waves-light btn btn-secondary text-white bg-secondary"
                                                            >
                                                                Pick
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <table className="table table-border">
                                                <thead>
                                                    <tr>
                                                        <th colSpan={4} className="text-center">  <strong>Website Link:</strong> <a href={`${this.state.domain}`} className="" target={'_blank'}>{this.state.domain}</a></th>
                                                    </tr>
                                                    <tr>
                                                        <th>Id#</th>
                                                        <th>Name</th>
                                                        <th>Preview Link</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.records.map((row, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{row.id}</td>
                                                                <td>{row.title}</td>
                                                                <td>
                                                                    {
                                                                        (row.slug != 'header' && row.slug != 'footer') && (
                                                                            <a className="btn btn-link" href={`${this.state.domain}/${row.slug}`} target={'_blank'}>
                                                                                Preview
                                                                            </a>
                                                                        )
                                                                    }

                                                                </td>
                                                                <td>
                                                                    <Link to={`/content/${bcrypt(row.user_id)}/${row.slug}`} className="btn btn-secondary">
                                                                        Edit
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        )
                                        }

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </React.Fragment >
        )
    }
}