import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert"
import Helmet from "react-helmet";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { dcrypt, uToken, } from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import Select from "react-select"
import Loader from "react-loader";
import $ from "jquery";
window.jQuery = $;
window.$ = $;

require("formBuilder");
require("jquery-ui-sortable");
require('formBuilder/dist/form-render.min.js')

export default class ClientFormCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id ? dcrypt(this.props.match.params.id) : null,
      page_name: this.props.match.params.id ? "Edit Lead Form" : "Create Lead Form",
      from: this.props.from ? this.props.from : '',
      record: {
        name: '',
        permission: '',
        group_id: '',
        disclaimer: '',
        disclaimer_id: '',
      },
      groups: [],
      permissions: [],
      tag_id: [],
      tab: "1",
      tokens: [],
      warningAlert: false,
      loading: true,
      columns: [],
      mapping: [],
      formData: [],
      group_id: 0,
      disclaimer_id: 0,
      disclaimers: [],
     
    };

    this.updateFormBuilder = this.updateFormBuilder.bind(this)
  }

  componentDidMount() {
    this.getData();
    this.getFormData();
  }

  getData = () => {
    var self = this;
    fetch(`${ApiUrl}` + "get/edit/clientForms", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },

      body: JSON.stringify({ id: this.state.id },
      ),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          var tokens_group = [
            ...response.data.fields.default_fields,
            ...response.data.fields.custom_fields
          ];
          this.setState({
            record: response.data.record,
            selectedGroup: response.data.record.group_id,
            selectedDisclaimer: response.data.record.disclaimer_id,
            selectedDisclaimer: response.data.record.disclaimer,
            // group_id: response.data.record.group_id,
            selectedPermission: response.data.record.permissions,
            permission: response.data.record.permission,
            formData: response.data.record.metadata,
            mapping: response.data.record.mapped_data,
            columns: tokens_group,
          });

          var $fbEditor = $(document.getElementById('fb-editor')),
            $formContainer = $(document.getElementById('fb-rendered-form')),
            fbOptions = {
              formData: response.data.record.metadata,
              onSave: function () {
                //$fbEditor.toggle();
                $formContainer.toggle();
                self.updateFormBuilder(formBuilder.formData);
                $('form', $formContainer).formRender({
                  formData: formBuilder.formData
                });
              },
              disabledActionButtons: ['data'],
              disableFields: ['autocomplete', 'file', 'hidden']
            },
            formBuilder = $fbEditor.formBuilder(fbOptions);

          // $('.edit-form', $formContainer).on('click', function () {
          //     window.location.reload();
          // });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  getFormData = () => {
    fetch(`${ApiUrl}` + "get/meeting/formdata", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {

        if (response.status === true) {


          this.setState({
            groups: response.data.groups,
            permissions: response.data.coachPartners,
            tokens: response.data.tokens.default_fields,
            disclaimers: response.data.disclaimers,
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };



  handleSubmit = async (event, errors, values) => {
    values.id = this.state.id;
    if (this.state.tab == '1') {
      this.toggleTab("2");
      return;
    } else if (this.state.tab == "2") {
      this.toggleTab("3");
      return;
    } else if (this.state.tab == "3" && errors.length < 1) {
      this.setState({
        loading: false
      });
      fetch(`${ApiUrl}` + "add/clientForms", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify(values),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === true) {
            toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
            setTimeout(
              () => this.props.history.push('/lead/forms'),
              2000
            );
          } else {
            this.setState({
              loading: false
            });
            toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        //Then with the error genereted...
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      this.setState({
        warningAlert: true
      })

    }
  };


  handleGroup = (selectedGroup) => {

    this.setState(prevState => {
      let record = Object.assign({}, prevState.record);
      record.group_id = selectedGroup.group_id;
      return { record };
    });
    this.setState({
      group_id: selectedGroup.group_id
    })
  }
 handleDisclaimer = (selectedDisclaimer) => {
    this.setState(prevState => {
        let record = { ...prevState.record };
        record.disclaimer_id = selectedDisclaimer.id;
        record.disclaimer = selectedDisclaimer.body;

        return {
            record,
            disclaimer_id: selectedDisclaimer.id,
            disclaimer: selectedDisclaimer.body
        };
    });
}

  handlePermission = (selectedPermission) => {

    var filter = [];
    if (selectedPermission) {
      selectedPermission.map(fil => {
        filter.push(fil.user_id);
      })
    }

    this.setState(prevState => {
      let record = Object.assign({}, prevState.record);
      record.permission = filter.toString();
      return { record };
    })

    this.setState({
      selectedPermission: selectedPermission
    })
  }

  toggleTab = (index) => {
    if (this.state.tab !== index) {
      this.setState({
        tab: index,
      });
    }
  };

  updateFormBuilder = (data) => {
    fetch(`${ApiUrl}` + "update/clientForms/metaData", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id, metadata: data }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          $('.fb-editor').empty();
          $('.fb-rendered-form').empty();
          this.getData();
          this.toggleTab("3");
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  render() {
    let label = '';
    console.log(this.state.group_id, 'aaaaaaaaaaaaaaaaaaa')
    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name} </title>
        </Helmet>
        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.page_name} </h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#"> {this.state.page_name} </Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                type="button"
                color="secondary"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
        <ToastContainer />
        <Loader loaded={this.state.loading}></Loader>
        {this.state.warningAlert ? (
          <SweetAlert
            title="Please fill all fields"
            warning
            onConfirm={() => {
              this.setState({
                warningAlert: false
              })
            }}
          />
        ) : null}

        <Row>
          <Col className="col-12">
            <Card>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={this.state.tab == 1 ? "active" : ""}
                    onClick={() => {
                      this.toggleTab("1");
                    }}
                  >
                    Form Information
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={this.state.tab == 2 ? "active" : ""}
                    onClick={() => {
                      this.toggleTab("2");
                    }}
                  >
                    Build From
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={this.state.tab == 3 ? "active" : ""}
                    onClick={() => {
                      this.toggleTab("3");
                    }}
                  >
                    Map Form
                  </NavLink>
                </NavItem>
              </Nav>
              <AvForm
                onSubmit={this.handleSubmit}
                ref={(c) => (this.form = c)}
                className="needs-validation"
              >
                <TabContent activeTab={this.state.tab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col md={6}>
                        <ModalBody className="row">
                          <div className="col-md-12 mb-3">
                            <AvField
                              name="name"
                              value={this.state.record.name}
                              label="Name"
                              className="form-control"
                              required
                            />
                          </div>

                          <div className="col-md-12 mb-3">
                            <label className="control-label"> Group </label>
                            <div className="CommonSelectTwo Zindex">
                              <Select
                                getOptionLabel={option => option.title}
                                getOptionValue={option => option.group_id}
                                value={this.state.groups.filter(({ group_id }) => group_id === this.state.record.group_id)}
                                isMulti={false}
                                options={this.state.groups}
                                classNamePrefix="select2-selection"
                                name="groups_id"
                                onChange={(e) => {
                                  this.handleGroup(e)
                                }}
                              />
                              <AvField required type="hidden" name="group_id" value={this.state.group_id != 0 ? this.state.group_id : this.state.record.group_id} />
                            </div>
                          </div>

                          <div className="col-md-12 mb-3">
                            <label className="control-label"> Assign to </label>
                            <div className="CommonSelectTwo">
                              <Select
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.user_id}
                                isMulti={true}
                                value={this.state.selectedPermission}
                                options={this.state.permissions}
                                classNamePrefix="select2-selection"
                                onChange={(e) => {
                                  this.handlePermission(e);
                                }}
                              />
                              <AvField
                                type="hidden"
                                name="permission"
                                value={this.state.record.permission}
                              />
                            </div>
                          </div>
                          <div className="col-md-12 mb-3">
                          <label className="control-label"> Disclaimer Template </label>
                          <Select
                              getOptionLabel={option => option.title}
                              getOptionValue={option => option.id}
                              value={this.state.disclaimers.filter(({ id }) => id === this.state.record.disclaimer_id)}
                              isMulti={false}
                              options={this.state.disclaimers}
                              classNamePrefix="select2-selection"
                              name="disclaimer_id"
                              onChange={(e) => {
                                  this.handleDisclaimer(e)
                              }}
                          />
                          <AvField required type="hidden" name="disclaimer_id" value={this.state.disclaimer_id != 0 ? this.state.disclaimer_id : this.state.record.disclaimer_id} />

                          </div>
                          <div className="col-md-12 mb-3">
                            <AvField
                              name="disclaimer"
                              value={this.state.disclaimer != '' ? this.state.disclaimer : this.state.record.disclaimer}
                              label="Disclaimer Text"
                              type="textarea"
                              className="form-control d-none"
                              placeholder="Enter Disclaimer Text"
                            />
                            <div dangerouslySetInnerHTML={{
                            __html:  this.state.record.disclaimer,
                            }}
                            />
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          <button
                            className="btn btn-primary waves-effect waves-light"
                            type="submit"
                          >
                            Next
                          </button>
                        </ModalFooter>
                      </Col>
                      <Col md={6}></Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId={"2"}>
                    <Row>
                      <Col md={12}>
                        <ModalBody >
                          <div className="row">
                            <div className="fb-editor" id="fb-editor"></div>
                            <div className="fb-rendered-form" id="fb-rendered-form">
                              <form action="#"></form>
                              <button className="btn btn-primary">Edit Form</button>
                            </div>
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          {/* <button
                            className="btn btn-primary waves-effect waves-light"
                            type="button"
                            onClick={() => this.toggleTab("1")}
                          >
                            Back
                          </button>
                          <button
                            className="btn btn-primary waves-effect waves-light"
                            type="submit"
                          >
                            Next
                          </button> */}
                        </ModalFooter>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId={"3"} className="mt-3">
                    <Row>
                      <Col md={12}>
                        <ModalBody>
                          <div className="border py-3 px-3">
                            <div className="d-flex space-between">
                              <Table className="table table-light mb-0">
                                <thead>
                                  <tr>
                                    <th>Label</th>
                                    <th>Index</th>
                                    <th>System Map Columns</th>
                                  </tr>
                                  {
                                    this.state.formData && this.state.formData.map((row, index) => {
                                      label = row.label.replace(/(<([^>]+)>)/gi, "");
                                      return (
                                        <tr>
                                          <td>{label.length > 30 ? label.substring(0, 30) + '...' : label}</td>
                                          <td>{row.name}</td>
                                          <td>
                                            <AvField
                                              type="select"
                                              name={`fields[${row.name}]`}
                                              value={this.state.mapping[row.name]}
                                              at={row.label}
                                              a={index}
                                            >
                                              <option value="">Map Column</option>
                                              {this.state.columns.map((column, c) => {
                                                return (
                                                  <option a={column.key}
                                                    value={`${column.key}`}>{column.label}</option>
                                                )
                                              })}

                                            </AvField>
                                          </td>
                                        </tr>
                                      )
                                    })
                                  }
                                </thead>
                              </Table>
                            </div>
                          </div>

                        </ModalBody>
                        <ModalFooter>
                          <button
                            className="btn btn-primary waves-effect waves-light"
                            type="button"
                            onClick={() => this.toggleTab("2")}
                          >
                            Back
                          </button>
                          <button
                            className="btn btn-primary waves-effect waves-light"
                            type="submit"
                          >
                            Submit
                          </button>
                        </ModalFooter>
                      </Col>
                      <Col md={6}></Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </AvForm>
            </Card>
          </Col>
        </Row>

      </React.Fragment >
    );
  }
}
