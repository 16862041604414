import React, { useState, useEffect } from "react";
import { Device } from "twilio-client";
import Dialler from "./Dialler";
import KeypadButton from "./KeypadButton";
import Incoming from "./Incoming";
import OnCall from "./OnCall";
import "./Phone.css";
import states from "./states";
import FakeState from "./FakeState";
import swal from "sweetalert";
import { ApiUrl } from "../../config";
import { uToken } from "../../useToken";

const Phone = ({ paramsters, getCallSid }) => {
  const [state, setState] = useState(states.CONNECTING);
  const [to_number, setNumber] = useState(paramsters.phoneno);
  const [from_number, setFromNumber] = useState(paramsters.from_number);
  const [client_id, setClientId] = useState(paramsters.client_id);
  const [coach_id, setCoachId] = useState(paramsters.coach_id);
  const [campaign_id] = useState(paramsters.campaign_id ? paramsters.campaign_id : 0);
  const [phone] = useState(paramsters.phone);
  const [mobile] = useState(paramsters.mobile);

  const [conn, setConn] = useState(false);
  const [device, setDevice] = useState(true);
  const [lists, setLists] = useState([]);


  useEffect(() => {
    // console.log(paramsters ,'paramsters')
    getNumberLists();
  }, []);

  const getNumberLists = () => {
    fetch(`${ApiUrl}` + "get/phone/numbers", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setLists(response.data.records);
        } else {
          console.error("response.message:", response.message);
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  useEffect(() => {
    const device = new Device();

    device.setup(paramsters.token, { debug: true });

    device.on("ready", () => {
      setDevice(device);
      setState(states.READY);
    });
    device.on("connect", (connection) => {
      console.log("Connect event");
      setConn(connection);
      getCallSid(connection.parameters.CallSid);
      setState(states.ON_CALL);
    });
    device.on("disconnect", () => {
      getCallSid('');
      setState(states.READY);
      setConn(null);
    });
    // device.on("incoming", (connection) => {
    //   setState(states.INCOMING);
    //   setConn(connection);
    //   connection.on("reject", () => {
    //     setState(states.READY);
    //     setConn(null);
    //   });
    // });
    device.on("cancel", () => {
      setState(states.READY);
      setConn(null);
    });
    device.on("reject", () => {
      getCallSid('');
      setState(states.READY);
      setConn(null);
    });

    return () => {
      device.destroy();
      setDevice(null);
      setState(states.OFFLINE);
    };
  }, [paramsters.token]);

  const handleCall = (e) => {
    if (from_number == '') {
      swal({
        title: "Please set outbound number as from number in your profile setting",
        icon: "warning",
      });
      return;
    }

    if (to_number == '') {
      swal({
        title: "Sorry , To number is empty",
        icon: "warning",
      });
      return;
    }

    e.preventDefault();
    device.connect({
      to_number: to_number,
      from_number: from_number,
      coach_id: coach_id,
      client_id: client_id,
      campaign_id: campaign_id
    });
  };

  const handleHangup = () => {
    device.disconnectAll();
  };

  let render;
  if (conn) {
    if (
      state === states.Ready ||
      state === states.CONNECTING
    ) {
      render = <Incoming device={device} connection={conn}></Incoming>;
    } else if (state === states.ON_CALL) {

      render = (
        <>
          <Dialler phone={phone} mobile={mobile} number={to_number} setNumber={""} connection={conn}></Dialler>
          <OnCall handleHangup={handleHangup} connection={conn}></OnCall>
        </>
      )

    }
  } else {
    render = (
      <>
        <Dialler phone={phone} mobile={mobile} number={to_number} setNumber={setNumber}></Dialler>
        <div className="call">
          <KeypadButton handleClick={(e) => handleCall(e)} color="green btn btn-sm">
            <i className="fas fa-phone"></i>
          </KeypadButton>
        </div>
      </>
    );
  }
  return (
    <>
      {/* <FakeState
        currentState={state}
        setState={setState}
        setConn={setConn}
      ></FakeState> */}
      {/* <p>hello this {from_number} - {to_number}</p> */}

      {/* {console.log(paramsters ,'paramsters')} */}
      <div className="form-group text-left">
        <label>From number</label>
        <select onChange={(e) => { setFromNumber(e.target.value) }} value={from_number} className="form-control text-center" name="from_number">
          <option value={''}>Select Number</option>
          {
            lists.map((row, i) => {
              return <option value={row.phone_number} key={i}>{row.phone_number}</option>
            })
          }
        </select>
      </div>
      <div className="form-group text-left mt-2">
        <label>To number</label>
      </div>
      {render}
      {/* <p className="status">{state}</p> */}
    </>
  );
};

export default Phone;
