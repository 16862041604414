import React, { Component, Fragment } from "react";
import Loader from "react-loader";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  Table,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import Helmet from "react-helmet";
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
} from "availity-reactstrap-validation";
import {
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../useToken";
import {
  ApiUrl,
  ProductName,
  ToastAutoClose,
  AttachementsUrl,
} from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import ProfileSettings from "../Authentication/profile-sidebar";
import Dropdownaction from "./Dropdown";
import $ from "jquery";
import swal from 'sweetalert';
export default class PhoneNumbers extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          return (
            <Dropdownaction phone_id={record.id} phone_number={record.phone_number} is_locked={record.is_locked} />
          );
        },
      },
      {
        text: "Phone Number",
        key: "phone_number",
        sortable: false,
        cell: (record, index) => {
          return (
            <Fragment>
              {record.phone_number}
            </Fragment>
          );
        },
      },
      {
        text: "Phone Name",
        key: "phone_name",
        sortable: false,
        cell: (record, index) => {
          return (
            <Fragment>
              {record.phone_name}
            </Fragment>
          );
        },
      },
      {
        text: "In Use",
        key: "in_use",
        sortable: false,
        cell: (record, index) => {
          return (
            <Fragment>
              {record.phone_used_in}
            </Fragment>
          );
        },
      },
      {
        text: "Phone Type",
        key: "type",
        sortable: false,
        cell: (record, index) => {
          return (
            <Fragment>
              {record.type == "local" && (
                <Badge bg="success"> Local </Badge>
              )}
              {record.type == "toll_free" && (
                <Badge bg="info"> Toll Free </Badge>
              )}
            </Fragment>
          );
        },
      },
      {
        text: "Is Registered",
        key: "is_registered",
        sortable: false,
        cell: (record, index) => {
          return (
            
            <Fragment>
              {record.is_registered_a2p == "1" && (
                <>
                 <Link to="/trusthub" target="_blank">
                  <Badge bg="success">
                    A2P Messaging
                  </Badge>
                  <i className="ion ion-md-checkmark-circle-outline ml-3"></i>
                </Link>
                <br></br>
                </>
              )}
              {record.is_registered_a2p == "0" && (
                 <>
                <Link to="/trusthub" target="_blank">
                  <Badge bg="danger">
                    A2P Messaging
                  </Badge>
                  <i className="ion ion-md-close-circle-outline ml-3"></i>
                </Link>
                <br></br>
                </>
              )}
            {record.is_registered_shaken == "0" && (
               <>
              <Link to="/trusthub/overview" target="_blank">
                <Badge bg="danger">
                  SHAKEN/STIR
                </Badge>
                <i className="ion ion-md-close-circle-outline ml-3"></i>
              </Link>
              <br></br>
              </>
              )}
              {record.is_registered_shaken == "1" && (
               <>
              <Link to="/trusthub/overview" target="_blank">
                <Badge bg="success">
                  SHAKEN/STIR
                </Badge>
                <i className="ion ion-md-checkmark-circle-outline ml-3"></i>
              </Link>
              </>
              )}
            </Fragment>
          );
        },
      },
      {
        text: "Is Locked",
        key: "type_locked",
        sortable: false,
        cell: (record, index) => {
          return (
            <Fragment>
              {record.is_locked == "Yes" && (
                <Badge bg="success"> Yes </Badge>
              )}
              {record.is_locked == "No" && (
                <Badge bg="danger"> No </Badge>
              )}
            </Fragment>
          );
        },
      },
      {
        text: "Billing",
        key: "last_billing_date",
        sortable: false,
        cell: (record, index) => {
          return (
            <Fragment>
              <strong>Purchase Date:</strong> {record.purchase_date} <br></br>
              <strong>Next Billing Date:</strong> {record.last_billing_date}
            </Fragment>
          );
        },
      },
    ];
    this.columns_del = [
      {
        text: "Phone Number",
        key: "phone_number",
        sortable: false,
        cell: (record, index) => {
          return (
            <Fragment>
              {record.phone_number}
            </Fragment>
          );
        },
      },
      {
        text: "Purchase Date",
        key: "purchase_date",
        sortable: false,
      },
      {
        text: "Last Billing Date",
        key: "last_billing_date",
        sortable: false,
      },
      {
        text: "Deleted At",
        key: "deleted_date",
        sortable: false,
      },
    ];
    this.config = {
      key_column: "sr",
      page_size: 100,
      length_menu: false,
      show_filter: true,
      show_pagination: true,

      button: {
        excel: false,
        print: true,
        csv: true,
      },
    };


    this.state = {
      records: [],
      selected_phone_arr: [],
      total_pages: 0,
      del_records: [],
      total_pages_del: 0,
      modal: false,
      updatemodal: false,
      record: {},
      filter: {},
      page_name: "Phone Numbers",
      customDate: true,
      activeTab: "1",
      loaded: true,
      phone_records: {},
      seach_by_type: "areaCode",
      country_code: "US",
      phone_type: "local",
      seach_by_value: "",
      unlockFlow: {
        groups: []
      },
      loader: false,
    };
  }

  componentDidMount() {
    this.geActivePhones("", "");
  }

  getUnlockFlow = (data) => {
    fetch(`${ApiUrl}` + "user/unlock/flow", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            unlockFlow: response.data
          })
          if (data.show_notify) {
            toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          }

          //console.log(this.state.records,'here is records');
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  handleSubmitUnlockFlow = async (event, values) => {
    values.type = 'submit';
    values.show_notify = true;
    this.getUnlockFlow(values);
  }
  geADeletedPhones = (queryString = "", data) => {
    fetch(`${ApiUrl}` + "get/user/phone/numbers" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ data, 'phone_status': 'Deleted' }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            total_pages: response.data.total,
            del_records: response.data.records,
          });
          //console.log(this.state.records,'here is records');
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  geActivePhones = (queryString = "", data) => {
    fetch(`${ApiUrl}` + "get/user/phone/numbers" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ data, 'phone_status': "Active" }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            total_pages: response.data.total,
            records: response.data.records,
          });
          //console.log(this.state.records,'here is records');
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  getTwilioNumber = (queryString = "", data) => {
    this.setState({ loaded: false });
    fetch(`${ApiUrl}` + "get/user/twilio/available_numbers" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ data }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            phone_records: response.data.records,
          });
          this.setState({ loaded: true });
        } else {
          this.setState({ loaded: true });


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  tableChangeHandlerActive = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.geActivePhones("?" + queryString, "");
  };
  tableChangeHandlerDeleted = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.geADeletedPhones("?" + queryString, this.state.filter);
  };
  resetPhoneField = async (data) => {
    this.setState({
      seach_by_type: "areaCode",
      country_code: "US",
      phone_type: "local",
      area_code: "",
    });
    this.getTwilioNumber("", "");
  }


  handleChangeType = async (value) => {
    $('input[name=selected_numbers]').prop('checked', false);
    let queryString = encodeURIComponent('phone_type') + "=" + encodeURIComponent(value);
    this.getTwilioNumber("?" + queryString, "");
  };
  handleFilterSubmit = async (event, data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
      })
      .join("&");
    this.getTwilioNumber("?" + queryString, "");
  };
  handleSelectPhone = (e) => {
    let isChecked = e.target.checked;
    let phone = e.target.value;
    var selected_phone_arr = this.state.selected_phone_arr;
    if (isChecked) {
      selected_phone_arr.push(phone);
      this.setState({ selected_phone_arr: selected_phone_arr });
    } else {
      var index = selected_phone_arr.indexOf(phone);
      if (index !== -1) {
        selected_phone_arr.splice(index, 1);
        this.setState({ selected_phone_arr: selected_phone_arr });
      }
    }
    console.log(this.state.selected_phone_arr, 'selected_phone_arr_yes');
  };
  handleSubmit = async (event, values) => {
    this.setState({
      loader: true,
    })
    values.phone_type = this.state.phone_type;
    values.selected_phone_arr = this.state.selected_phone_arr;
    fetch(`${ApiUrl}` + "purchase/phone/numbers", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          this.geActivePhones("", "");
          $('input[name=selected_numbers]').prop('checked', false);
          //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' }); 
          
          this.setState({
            loader: false,
            selected_phone_arr: []
          });
          swal({
            text: data.message,
            icon: "success",
          });
        } else {
          this.setState({
            loader: false,
          })
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        this.setState({
          loader: false,
        })
        console.error("Error:", error);
      });
  };


  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name} </title>
        </Helmet>
        <Row>
          <Col lg="2" className="pl-0">
            <ProfileSettings menuOpen={false} props={this.props} />
          </Col>
          <Col lg="10">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4> {this.state.page_name} </h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> {this.state.page_name} </Link>
                    </li>
                  </ol>
                </div>
              </Col>
            </Row>

            <ToastContainer />

            <Card className="mt-0 mb-0">
              <CardBody className="py-0">

                <Nav tabs className="pt-0">
                  <NavItem>
                    <NavLink
                      className={this.state.activeTab == 1 ? "active" : ""}
                      onClick={() => { this.setState({ activeTab: "1" }); }}
                    >
                      My Numbers
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={this.state.activeTab == 2 ? "active" : ""}
                      onClick={() => { this.setState({ activeTab: "2" }); this.getTwilioNumber("", ""); }}
                    >
                      Get Phone Number(s)
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={this.state.activeTab == 3 ? "active" : ""}
                      onClick={() => { this.setState({ activeTab: "3" }); this.geADeletedPhones("", ""); }}
                    >
                      Deleted PhoneNumber(s)
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={this.state.activeTab == 4 ? "active" : ""}
                      onClick={() => { this.setState({ activeTab: "4" }); this.getUnlockFlow(""); }}
                    >
                      Unlock Phone Flow
                    </NavLink>
                  </NavItem>

                </Nav>
              </CardBody>
            </Card>
            <TabContent className="p-0" activeTab={this.state.activeTab}>
              <TabPane tabId={"1"}>
                <Row>
                  <Col className="col-12">
                    <Card>
                      <CardBody>
                        <ReactDatatable
                          key={'s'}
                          config={this.config}
                          records={this.state.records}
                          columns={this.columns}
                          dynamic={true}
                          total_record={this.state.total_pages}
                          onChange={this.tableChangeHandlerActive}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={"3"}>
                <Row>
                  <Col className="col-12">
                    <Card>
                      <CardBody>
                        <ReactDatatable
                          key={'d'}
                          config={this.config}
                          records={this.state.del_records}
                          columns={this.columns_del}
                          dynamic={true}
                          total_record={this.state.total_pages_del}
                          onChange={this.tableChangeHandlerDeleted}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">

                <Card>
                  <Loader loaded={this.state.loaded}>
                    <AvForm
                      onValidSubmit={this.handleFilterSubmit}
                      ref={(c) => (this.form = c)}
                      className="needs-validation"
                    >
                      <ModalBody className="row">
                        <div className="col-md-2 mb-3">
                          <AvField
                            className="select form-control"
                            type="select"
                            name="phone_type"
                            label="Phone Type"
                            onChange={(e) => {
                              this.setState({ "phone_type": e.target.value });
                              this.handleChangeType(e.target.value);

                            }}
                            value={this.state.phone_type}
                          >
                            <option value="local">Local Numbers</option>
                            <option value="toll_free">Toll Free Numbers</option>
                          </AvField>
                        </div>
                        <div className="col-md-2 mb-3">
                          <AvField
                            className="select form-control"
                            type="select"
                            name="country_code"
                            label="Country"
                            onChange={(e) => {
                              this.setState({ "country_code": e.target.value });
                            }}
                            value={this.state.country_code}
                          >
                            <option value="US">USA</option>
                            <option value="CA">Canada</option>

                            <option value="AU">Australia</option>
                            <option value="MX">Mexico</option>
                            <option value="GB">United Kingdom</option>

                          </AvField>
                        </div>
                        <div className="col-md-2 mb-3">
                          <AvField
                            className="select form-control"
                            type="select"
                            name="seach_by_type"
                            label="Search By"
                            value={this.state.seach_by_type}
                            onChange={(e) => { this.setState({ "seach_by_type": e.target.value }) }}
                          >
                            <option value="areaCode">Area Code</option>
                            <option value="contains">Phone</option>
                          </AvField>
                        </div>

                        <div className="col-md-3 mb-3">
                          <AvField
                            name="area_code"
                            value={this.state.area_code}
                            placeholder="Enter search value"
                            label="-"
                            className="form-control"
                            onKeyUp={(e) => { this.setState({ "area_code": e.target.value }) }}
                          />
                        </div>
                        <div className="col-md-3 mt-4 button-items">
                          <div className="form-group">
                            <button
                              className="btn btn-primary waves-effect waves-light"
                              type="submit"
                            >
                              Search
                            </button>
                            <button
                              className="btn btn-info waves-effect waves-light"
                              type="button"
                              onClick={this.resetPhoneField}
                            >
                              Reset
                            </button>
                          </div>
                        </div>

                      </ModalBody>
                    </AvForm>
                  </Loader>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle>
                      {this.state.phone_type == 'local' ? "Local Phone Numbers" : "Toll Free Numbers"}
                    </CardTitle>
                    <Row>
                      <AvForm
                        onValidSubmit={this.handleSubmit}
                        ref={(d) => (this.form = d)}
                        className="needs-validation"
                      >
                        <div className="table-responsive mb-0 col-md-12" data-pattern="priority-columns">
                          <table className="table table-striped table-bordered responsiveTable">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Number</th>
                                <th>Region</th>
                                <th>Postal Code</th>
                                <th>Locality</th>
                                <th>Address Requirements</th>
                                <th>Voice Enabled</th>
                                <th>SMS Enabled</th>
                                <th>MMS Enabled</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.phone_records.length > 0 ? (
                                this.state.phone_records.map((row, i) => {
                                  return (
                                    <tr
                                      key={i + '--' + row.id}
                                    >
                                      <td align="center">

                                        <input className="form-check-input" type="checkbox" name="selected_numbers" value={row.phone_number} onChange={(e) => {
                                          this.handleSelectPhone(e);
                                        }} />
                                      </td>
                                      <td align="center">{row.phone_number} </td>
                                      <td align="center"> {row.region} </td>
                                      <td align="center"> {row.postal_code == '' ? '--' : row.postal_code} </td>
                                      <td align="center">{row.locality}  </td>
                                      <td align="center"> {row.address_requirements} </td>
                                      <td align="center"> {row.capabilities.voice == '1' ? 'True' : 'False'} </td>
                                      <td align="center"> {row.capabilities.SMS == '1' ? 'True' : 'False'}  </td>
                                      <td align="center"> {row.capabilities.MMS == '1' ? 'True' : 'False'}  </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr key="-1">
                                  <td colSpan={9}>
                                    <div className="col-md-12 text-center text-center">
                                      No record found
                                    </div>
                                  </td>
                                </tr>
                              )
                              }
                            </tbody>
                          </table>
                        </div>
                        {this.state.phone_records.length > 0 && (
                          <>
                            {this.state.selected_phone_arr.length > 0 && (
                              <div className="col-md-3 mb-3">
                                <AvField
                                  name="phone_name"
                                  value=""
                                  placeholder="Enter Phone Name"
                                  label="Phone Name(optional)"
                                  className="form-control"
                                />
                              </div>
                            )}
                            <div className="col-md-12">
                              <div className="col-md-3 mt-4 button-items">
                                <div className="form-group">
                                  <button
                                    className="btn btn-primary waves-effect waves-light"
                                    type="submit"
                                    disabled={this.state.loader}
                                  >
                                    Purchase Number
                                  </button>
                                </div>
                              </div>
                              <div className="col-md-9 mt-4">
                                Note : Your phone numbers will be billed on a monthly bases.
                              </div>
                            </div>
                          </>
                        )
                        }
                      </AvForm>

                    </Row>
                  </CardBody>
                </Card>

              </TabPane>

              <TabPane tabId="4">

                <Card>
                  <Loader loaded={this.state.loaded}>
                    <AvForm
                      onValidSubmit={this.handleSubmitUnlockFlow}
                      ref={(c) => (this.form = c)}
                      className="needs-validation"
                    >
                      <ModalBody className="row">
                        <div className="col-md-12 mb-4">
                          <AvField
                            className="select form-control"
                            type="select"
                            name="group_id"
                            label="Select Group"
                            required
                            value={this.state.unlockFlow.group_id}
                          >
                            <option value=""> Select Group </option>
                            {
                              this.state.unlockFlow.groups.map((row, i) => {
                                return <option key={i} value={row.group_id}> {row.title} </option>
                              })
                            }
                          </AvField>
                        </div>

                        <div className="col-md-12 mb-3">
                          <AvField
                            className="form-control"
                            type="textarea"
                            name="voice_text"
                            label="Change Voice Message"
                            required
                            value={this.state.unlockFlow.voice_text}
                          />
                        </div>

                      </ModalBody>
                      <ModalFooter>
                        <button
                          className="btn btn-primary waves-effect waves-light"
                          type="submit"
                        >
                          Submit
                        </button>
                      </ModalFooter>
                    </AvForm>
                  </Loader>
                </Card>


              </TabPane>

            </TabContent>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
