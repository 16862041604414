import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Modal,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import Helmet from "react-helmet";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    bcrypt,
    isAllowed,
    uInfo,
    uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import swal from 'sweetalert';
import Loader from "react-loader";
import { currencies } from "../../common/Common-Currency.js";

export default class Invoice extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                text: "Invoice#	",
                key: "invoice",
                cell: (record, index) => {
                    return `IN-${String(record.invoice_id).padStart(5, '0')}`
                }
            },
            {
                text: "Invoice For",
                key: "invoice_for",

            },
            {
                text: "REVENUE TYPE",
                key: "invoice_for_type",

            },

            {
                text: "Name",
                key: "created_for_name",
            },
            {
                text: "Start Date",
                key: "start_date",
                className:"StartDateProduct",
            },
            {
                text: "Expire Date",
                key: "expire_date",
                className:"StartDateProduct",
            },
            {
                text: "Type(Recurring#)	",
                key: "type",
                cell: (record, index) => {
                    if (record.type == 'one_time') {
                        return 'One Time';
                    } else if (record.type == 'recurring') {
                        return 'Recurring';
                    } else {
                        return record.type;
                    }
                }
            },
            {
                text: "Interval",
                key: "renewal_interval",
            },
            {
                text: "Total Price",
                key: "total_price",
                cell: (record, index) => {
                    return `${this.getInvoiceCurrency(record.currency_code)}${record.total_price}`
                }
            },
            {
                text: "Amount in usd",
                key: "amount_in_usd",
                cell: (record, index) => {
                    return `$${record.amount_in_usd}`
                }
            },
            // {
            //     text: "Viewed",
            //     key: 'viewed'
            // },
            {
                text: "Payment Method",
                key: "payment_method",
            },
            {
                text: "Status",
                key: 'payment_status',
                cell: (record, index) => {
                    if (record.payment_status == 'Paid') {
                        return <p key={index} className="badge bg-success">{record.payment_status}</p>
                    } else if (record.payment_status == 'Unpaid') {
                        return <p key={index} className="badge bg-danger">{record.payment_status}</p>
                    } else {
                        return <p key={index}>{record.status}</p>
                    }
                }
            },

            {
                text: "Created At",
                key: "created_at",
                className:"StartDateProduct",
            },
            {
                key: "action",
                text: "Action",
                className: "RevenueType",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            {(record.invoice_for != 'ConvertCoach' && record.payment_status == 'Unpaid') && (
                                <button
                                    type="button"
                                    color="info"
                                    className="btn btn-link btn-sm mb-1 mr-5"
                                    onClick={() => this.props.history.push("/invoice/edit/" + bcrypt(record.invoice_id))}

                                >
                                    <i className="fas fa-edit" />
                                </button>
                            )}

                            {
                                (record.payment_status == 'Unpaid') && (
                                    <button
                                        type="button"
                                        color="info"
                                        className="btn btn-link btn-sm"
                                        onClick={this.changeApprovalUser.bind(this, record, index)}
                                        title="Change manually invoice status"

                                    >
                                        <i class="fas fa-sync-alt"></i>
                                    </button>
                                )
                            }
                            <Link
                                to={
                                    "/invoice/preview/" + bcrypt(record.invoice_id)
                                }
                                className="btn btn-link btn-sm"
                            >
                                <i className="fas fa-eye"> </i>
                            </Link>
                            {record.payment_status == "Unpaid" && (
                                <Link
                                    to={
                                        "/invoice/pay/" + bcrypt(record.invoice_id)
                                    }
                                    target="_blank"
                                    className="btn btn-stripe d-none"
                                >
                                    <i className="fas fa-credit-card"> </i>
                                </Link>
                            )}

                            {record.payment_status == "Paid" && record.download_link != '' && (
                                <>
                                    {/* {"|"} */}
                                    <a
                                        href={
                                            record.download_link
                                        }
                                        target={"_blank"}
                                        className="btn btn-link btn-sm"
                                    >
                                        <i className="fas fa-download"> </i>
                                    </a>
                                </>
                            )}
                            <button
                                className="btn btn-link btn-sm"
                                onClick={this.deleteRecord.bind(this, record, index)}
                            >
                                <i className="fas fa-trash" />
                            </button>
                        </Fragment>
                    );
                }
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: false,
                print: true,
                csv: true,
            },
        };
        this.state = {
            records: [],
            roles: [],
            Loader: true,
            total_pages: 0,
            modal: false,
            record: {},
            filter: {},
            page_name: "Product Requests",
            customDate: true,
            ClientSubcribeModal: false,
            currentClient: {
                id: "",
                email_status: "",
                call_status: "",
                groups: [],
                tags: [],
            },
            approvalModal: false,
            approvalUser: {},
            approvalStatus: '',
        };
    }

    changeApprovalUser = (record, index) => {
        //console.log(record, 'recordrecordrecord')
        this.setState({
            approvalModal: true,
            approvalUser: record,
            approvalStatus: ''
        })
    }

    changeApprovalStatus = (status) => {
        let record = this.state.approvalUser;

        swal({
            title: "Are you sure?",
            text: 'You want to perform this action',
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "invoice/status/" + record.invoice_id + '/' + status, {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((data) => {
                            if (data.status === true) {
                                this.getData();
                                this.setState({
                                    approvalUser: {},
                                    approvalModal: false
                                })
                            } else {
                                toast.error(data.message, { theme: "colored" });
                            }
                        })
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                } else {
                    this.setState({
                        approvalUser: {},
                        approvalModal: false
                    })
                }
            });
    };

    deleteRecord = (record, index) => {
        swal({
            title: "Are you sure?",
            text: "You want to delete this record.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    this.setState({ loader: false });
                    fetch(`${ApiUrl}` + "delete/invoice", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                        body: JSON.stringify({ id: record.invoice_id }),
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((data) => {
                            this.setState({ loader: true });
                            if (data.status === true) {
                                this.getData();

                                swal({
                                    text: "Action performed successfully",
                                    icon: "success",
                                });
                            } else {
                                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                            }
                        })
                        //Then with the error genereted...
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    };

    componentDidMount() {
        this.getData();
    }

    getData = (queryString = "", data) => {
        fetch(`${ApiUrl}` + "invoices" + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((response) => {
                this.setState({ loader: true });
                if (response.status === true) {
                    console.log('invoices obj ', response.data.records)
                    // console.log(response, 'debugging');
                    this.setState({
                        total_pages: response.data.total,
                        records: response.data.records,
                        roles: response.data.roles,
                    });

                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    handleFilterSubmit = async (event, values) => {
        this.setState({
            filter: values,
        });
        this.getData("", values);
    };

    cancelFilter = async () => {
        this.form && this.form.reset();
        this.setState({ filter: {}, customDate: true });
        this.getData("", "");
    };

    tableChangeHandler = (data) => {
        let queryString = Object.keys(data)
            .map((key) => {
                if (key === "sort_order" && data[key]) {
                    return (
                        encodeURIComponent("sort_order") +
                        "=" +
                        encodeURIComponent(data[key].order) +
                        "&" +
                        encodeURIComponent("sort_column") +
                        "=" +
                        encodeURIComponent(data[key].column)
                    );
                } else {
                    return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
                }
            })
            .join("&");
        this.getData("?" + queryString, this.state.filter);
    };
    getInvoiceCurrency = (code) => {
        let currencysymbol = currencies[code];
        if (currencysymbol) {
            return currencysymbol.symbol;
        }
        return '$';
    }
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.state.page_name}

                    </title>
                </Helmet>
                <Loader loaded={this.state.loader}></Loader>
                <Row>
                    <Col sm={5}>
                        <div className="page-title-box">
                            <h4>{this.state.page_name}</h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#">{this.state.page_name}</Link>
                                </li>
                            </ol>
                        </div>
                    </Col>
                    <Col sm={6}>
                        {/* <div className="page-title-box text-align-right">
                            {hasRole(uInfo(), ["Import Leads"]) && (
                                <Link to={"import/lead"} className="btn  btn-success">
                                    Import
                                </Link>
                            )}
                        </div> */}
                    </Col>
                    <Col sm={1}>
                        <div className="page-title-box text-align-right">
                            {isAllowed(uInfo(), ["can_create"]) && (
                                <Link
                                    type="button"
                                    color="info"
                                    className="btn btn-info"
                                    to={"/invoice/add"}
                                >
                                    <i className="mdi mdi-plus"> </i>
                                    Create
                                </Link>
                            )}
                        </div>
                    </Col>
                </Row>

                <ToastContainer />

                <Row>
                    <Col xl="12">
                        <Card>
                            <CardBody className="pb-0">
                                <AvForm
                                    onValidSubmit={this.handleFilterSubmit}
                                    ref={(c) => (this.form = c)}
                                    className="needs-validation"
                                >
                                    <Row>
                                        <Col md="2">
                                            <div className="mb-0">
                                                <AvField
                                                    className="select form-control"
                                                    type="select"
                                                    name="date_range"
                                                    label="Date Filter"
                                                    onChange={(e) =>
                                                        e.target.value == "Custom Date"
                                                            ? this.setState({
                                                                customDate: false,
                                                            })
                                                            : this.setState({
                                                                customDate: true,
                                                            })
                                                    }
                                                >
                                                    <option value=""> All Time </option>
                                                    <option value="Current Week"> Current Week </option>
                                                    <option value="Last Week"> Last Week </option>
                                                    <option value="Current Month"> Current Month </option>
                                                    <option value="Last Month"> Last Month </option>
                                                    <option value="Last 3 Months"> Last 3 Months </option>
                                                    <option value="Last 6 Months"> Last 6 Months </option>
                                                    <option value="Current Year"> Current Year </option>
                                                    <option value="Last Year"> Last Year </option>
                                                    <option value="Custom Date"> Custom Date </option>
                                                </AvField>
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-0">
                                                <AvField
                                                    name="start_date"
                                                    label="Start date"
                                                    className="form-control"
                                                    placeholder="Enter Email Address"
                                                    type="date"
                                                    disabled={this.state.customDate}
                                                    required={!this.state.customDate}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-0">
                                                <AvField
                                                    name="end_date"
                                                    label="End date"
                                                    className="form-control"
                                                    placeholder="Enter Email Address"
                                                    type="date"
                                                    disabled={this.state.customDate}
                                                    required={!this.state.customDate}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-0">
                                                <AvField
                                                    name="master_type"
                                                    label="Revenue Type"
                                                    className="form-control"
                                                    type="select"

                                                >
                                                    <option value={''} >All</option>
                                                    <option value={'coaching'} >Coaching</option>
                                                    <option value={'e-learning'} >E-Learning</option>
                                                    <option value={'package'} >Manual</option>
                                                    <option value={'stripe'} >Package</option>

                                                </AvField>
                                            </div>
                                        </Col>

                                        <Col md="2">
                                            <div className="mb-0">
                                                <label className=""> {"Action"}</label>
                                                <div className="button-items">

                                                    <Button className="btn-sm" color="primary" type="submit">
                                                        Filter
                                                    </Button>
                                                    <Button
                                                        onClick={() => this.cancelFilter()}
                                                        outline
                                                        color="secondary"
                                                        className="waves-effect btn-sm"
                                                        type="button"
                                                    >
                                                        Reset
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody className="pt-0">
                                {/* <CardTitle className="h4">Default Datatable </CardTitle> */}
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                    dynamic={true}
                                    total_record={this.state.total_pages}
                                    onChange={this.tableChangeHandler}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Modal
                    isOpen={this.state.approvalModal}
                    fade={false}
                    toggle={() => this.setState({ approvalModal: false })}
                >
                    <ModalBody>
                        <div className="form-group mb-3">
                            <label>Select Status</label>
                            <select onChange={(e) => this.setState({ approvalStatus: e.target.value })} name="from_number" className="form-control" >
                                <option value={''}>Select option</option>
                                <option value={'Paid'}>Paid</option>
                                <option value={'Unpaid'}>Unpaid</option>
                            </select>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.state.approvalStatus != '' ?
                                <Button color="info" onClick={() => this.changeApprovalStatus(this.state.approvalStatus)}>
                                    Yes, Change it
                                </Button>
                                :
                                <Button disabled color="info" >
                                    Yes, Change it
                                </Button>
                        }
                        <Button color="danger" onClick={() => this.setState({ approvalModal: false })}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}
