import React, { useState, useEffect} from "react"
import { Link } from "react-router-dom";
import { ApiUrl } from "../../../config";
import { dcrypt,bcrypt, uInfo, uToken } from "../../../useToken";
import Context from './Context';
import swal from "sweetalert";
const Box1 = (props) => {
    const params = React.useContext(Context);
    const [record, setRecord] =  useState({});

    useEffect(() => {
        getData();
    }, []);
    const getData = () => {
        fetch(`${ApiUrl}` + "get/systemForm/resourceInfo", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
              },
            body: JSON.stringify({ id: params.form_data.id, user_id: uInfo().user_id}),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setRecord(response.data.record);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        submitData.append('user_id', uInfo().user_id);
        submitData.append('id', params.form_data.id);
        fetch(`${ApiUrl}` + "update/system/form", {
            method: "POST",
            body: submitData,
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    swal({
                        title: "Action performed successfully",
                        icon: "success",
                    });
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }


    return (
        <div>
            <div className="mt-1">
                <div className="d-flex align-items-center gap-2 StepHeading">
                        <div className="StepNumber">{params.form_data.id} <i className="fas fa-arrow-right"></i></div> <h5 className="m-0">Provide answers to the following questions so your website can have unique and individual content.</h5>
                    </div>
                    <form method="post" onSubmit={handleSubmit}
                        encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                        <div className="p-3 mt-1">
                            <div className="page-content-box">
                               
                                <div className="WizredForm">
                                    
                                    <div className="form-group mt-2">
                                        <label className="row">What is Your Position of Market Dominance or Statement of Fact About Your Business? 
                                           <small className="row">This will be on your main here image and the first piece of text that your leads will see when they visit your new website.</small>
                                            <small className="row">Example: "I'll find you $50,000 in your business today without spending an additional dollar on marketing or advertising."</small>
                                            <small className="row">Remember, be unique! Think about it, own it, and take massive action!</small>
                                        </label>
                                        <input required name="headline" defaultValue={record.headline ?? ''} className="form-control no-border px-0" type="text" placeholder="Type your answer here..."></input>
                                    </div>
                                    <div className="form-group mt-2">
                                        <label className="row">What is your sub-headline?  
                                        <small className="row">Ex. Learn how I will triple your current number of leads, double your sales, and increase your annual revenue by at least $50,000 or more...in just a single 45 minute presentation, guaranteed!</small>
                                        <small className="row">The description will immediately follow your Position of  Market Dominance and used to catch the attention of your new lead as soon as they enter your site.</small>
                                        </label>
                                        <textarea required rows={10} name="sub_headline" defaultValue={record.sub_headline ?? ''} className="form-control no-border px-0" type="text" placeholder="Type your answer here..."></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-sm btn-primary"> Ok &nbsp;<i className="fas fa-check"></i></button>
                    </form>
            </div>
        </div>
    );
};

export default Box1;