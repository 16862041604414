import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { ApiUrl } from "../../config.js";
import { uToken } from "../../useToken.js";
import {
    Row,
    Col,
    Container,
    Card,
    CardBody,
} from "reactstrap";

import ThrusthubHeader from "./header.js";
const Overview = (props) => {
    const [profile, setProfile] = useState({});
    const [enableShakenStir, setEnableShakenStir] = useState(false);


    useEffect(() => {
        getProfileData();
    }, []);

    const getProfileData = () => {
        fetch(`${ApiUrl}` + "check/trusthub/profile", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    console.log(response.data.records, 'getProfileData')
                    setProfile(response.data.records);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const getEnableShakenStir = () => {
        fetch(`${ApiUrl}` + "trusthub/load/active/phones", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    console.log(response.data.records, 'getProfileData')
                    setEnableShakenStir(true)
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });

       
    }
    return (
        <React.Fragment>
            <div className="overview" >
                <Container fluid>
                    <Row>
                        <Col md={12}
                            lg={12}
                            xl={12} >
                            <ThrusthubHeader />
                            <Card>
                                <CardBody>
                                    <div className="lower">

                                        <div className="heading mb-5">
                                            <h3 className="btn-link">Register Business Profile </h3>
                                        </div>

                                        <h5>Available products</h5>
                                        <p>Trust Hub provides products that can improve customer engagement by increasing throughput and brand recognition. To access the available products, please follow these steps</p>
                                        <p>
                                        </p><ol>
                                            <li>Once your required Business Profiles have been setup, you can get started with the Trust Products listed below.</li>
                                        </ol>


                                        <div className="row">
                                            <div className="col-md-6" >
                                                <div className="border py-5 px-5">
                                                    <h3>A2P Messaging (SMS)</h3>
                                                    <p>Avoid additional carrier filtering by registering your Business Profile for A2P messaging capabilities. This applies to SMS/MMS sent to the US via 10-digit long code numbers.</p>

                                                    <Link to={`/trusthub`} className="waves-effect btn btn-outline-dark btn-block mt-2"><span>Go to A2P Messaging</span> </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-6" >
                                                <div className="border py-5 px-5">
                                                    <h3 >SHAKEN/STIR Trusted Calling (Voice)</h3>
                                                    <p >Increase the answer rates of your calls by getting the highest SHAKEN/STIR attestation rating.</p>
                                                    {
                                                        profile.shst_status != 'twilio-approved' && (
                                                            <Link to={profile.shst_status == 'draft' || profile.shst_status == 'twilio-rejected' || profile.shst_status == 'pending-review' ? `/trusthub/submit_request?type=business_profile` : `#`}

                                                                className="waves-effect btn btn-outline-dark btn-block mt-2"><span>Enable SHAKEN/STIR</span> </Link>
                                                        )
                                                    }

                                                    <div className="alert alert-info mt-2">
                                                        <span><i className="fa fa-info-circle"></i> Your SHAKEN/STIR Trusted Calling Status:.
                                                            {
                                                                profile.shst_status == 'twilio-approved' ?
                                                                    <> <i className="fa fa-check-circle text-success" aria-hidden="true"></i> <span className="text-success">Approved</span></>
                                                                    :
                                                                    profile.shst_status == 'in-review' || profile.shst_status == 'draft' ?
                                                                        <span className="text-warning text-capitalize"> {profile.shst_status}</span>
                                                                        :
                                                                        <span className="text-danger text-capitalize"> {profile.shst_status}</span>
                                                            }
                                                        </span>
                                                    </div>

                                                    {
                                                        profile.shst_status == 'twilio-approved-1' && (
                                                            <button
                                                                onClick={() => getEnableShakenStir()}

                                                                className="waves-effect btn btn-outline-dark btn-block mt-2"><span>Assigned Phone Numbers</span> </button>
                                                        )
                                                    }

                                                    {
                                                        enableShakenStir && (
                                                            <div className="mt-3">
                                                                <h4>Assign Twilio phone numbers to Business Profile</h4>
                                                            </div>
                                                        )
                                                    }

                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>



            </div>

        </React.Fragment>
    );
};

export default Overview;
