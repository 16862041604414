import React from "react"
import { Row, Col } from "reactstrap"
import { Link } from "react-router-dom"
import {
  Sparklines,
  SparklinesBars,
} from "react-sparklines"
import { ProductName } from "../../config"

const Breadcrumb = props => {

  const itemLength = (props.breadcrumbItems || []).length || 1;
  return (
    <Row>
      <Col sm={6}>
        <div className="page-title-box">
          <h4>{props.title}</h4>
          <ol className="breadcrumb m-0">
            <li key={0} className="breadcrumb-item active">{ProductName}</li>
            {
              (props.breadcrumbItems || []).map((item, key) =>
                (key + 1) === itemLength ?
                  <li key={key} className="breadcrumb-item">{item.title}</li>
                  : <li key={key} className="breadcrumb-item"><Link to="#">{item.title}</Link></li>
              )
            }
          </ol>
        </div>
      </Col>
    </Row>
  )
}



export default Breadcrumb
