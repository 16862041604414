import React, { useEffect, useState, useRef } from "react";
import ReactDOM from 'react-dom';
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import Helmet from "react-helmet"
import { Link } from "react-router-dom"
import { toast, ToastContainer, Flip } from "react-toastify";
import { uToken, bcrypt, dcrypt } from "../../useToken";
import SimpleBar from "simplebar-react";
import { logoLightPng, ApiUrl, AttachementsUrl } from "../../config";
import ProposalSelectPackage from "./ProposalSelectPackage";
import ProposalSignature from "./ProposalSignature";
import ProposalSignatureAccepted from "./ProposalSignatureAccepted";
import ProposalSignupPackage from "./ProposalSignupPackage";
import ProposalSignupPayment from "./ProposalSignupPayment";
import { currencies } from "../../common/Common-Currency.js";
const Proposal = props => {
    const query = new URLSearchParams(props.location.search);
    //console.log(query)
    const ref = useRef();
    const [page_id, setPageId] = useState(query.get('page') ? query.get('page') : 0);
    const [proposal, setProposal] = useState([]);
    const [user_id, setUserId] = useState(0);
    const [accept_proposal_id, setAcceptedProposalId] = useState(0);
    const [proposal_pages, setProposalPages] = useState([]);
    const [page_body, setPageBody] = useState("");
    const [currencyword, setCurrency] = useState("USD");
    const [page_index, setPageIndex] = useState("");

    const [sign_up_step, setsignUpStep] = useState("one");
    const [showLoginLink, setShowLoginLink] = useState("No");
    const [isProposalAccepted, setisProposalAccepted] = useState("No");

    const [hasSignupToken, sethasSignupToken] = useState(false);
    const [proposlViewType] = useState(query.has('t') ? query.get('t') == 'p' ? 1 : 0 : 0);


    const [proposal_id, setproposalId] = useState(props.match.params.id ? dcrypt(props.match.params.id) : null);

    useEffect(() => {
        getAcceptedProposal();
        // console.log(dcrypt(props.match.params.id), ' props.match.params.id')
        getProposal();
        // getPageBody(page_id);
        signUpStep("one");
        setShowLoginLink('No');
    }, []);

    const getAcceptedProposal = () => {
        fetch(`${ApiUrl}` + "proposal/accepted/data", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ proposal_id: proposal_id }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    if (response.data.proposal_accepted) {
                        if (response.data.proposal_accepted.id > 0) {
                            if (response.data.proposal_accepted.status == 'Inactive') {
                                signUpStep("two");
                            } else {
                                setisProposalAccepted("Yes");
                            }
                            if (response.data.proposal_accepted.user_id > 0) {
                                setShowLoginLink("Yes");

                            }
                        }
                    }
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    const getProposal = (data = {}) => {
        console.log('inside getproposal', query.has('c'))
        // if (query.size > 0) {
        if (query.has('c')) {
            data.client_id = dcrypt(query.get('c'));
        }

        if (query.has('a')) {
            data.assessment_id = dcrypt(query.get('a'));
        }

        if (query.has('u')) {
            data.user_id = dcrypt(query.get('u'));
        }

        if (query.has('t')) {
            data.preview_type = query.get('t') == 'p' ? 1 : 0;
        } else {
            data.preview_type = 0;
        }
        if (query.has('curr')) {
            data.currency_code_preview = dcrypt(query.get('curr'));
        } else {
            data.currency_code_preview_er = 'USD';
        }

        // }
        console.log('2 proposal ')
        let id = props.match.params.id ? dcrypt(props.match.params.id) : null;
        fetch(`${ApiUrl}` + "proposal/pages/list/" + proposal_id, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })

            .then((response) => response.json())
            .then((response) => {
                // console.log(response)
                if (response.status === true) {
                    setProposal(response.data.propoal_record);
                    setProposalPages(response.data.records);
                    findSymbol(response.data.currency_);
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const findSymbol = (code) => {
        let currencyw = currencies[code];
        if (currencyw) {
            setCurrency(currencyw.symbol);
        }
    };
    const getPageBody = (new_page_id) => {
        fetch(`${ApiUrl}` + "proposal/page/content", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ proposal_id: proposal_id, page_id: new_page_id }),
        })

            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setPageBody(response.data.record.body);

                    setPageIndex(response.data.record.sub_type);

                    const htmlBody = response.data.record.body;
                    const token = "{signup_pkg_form}";
                    const index = htmlBody.indexOf(token);

                    if (index !== -1) {
                        sethasSignupToken(true);
                    } else {
                        sethasSignupToken(false);
                    }

                    if (htmlBody.includes(token)) {
                        const updatedHtmlBody = htmlBody.replace(token, '');
                        setPageBody(updatedHtmlBody);
                    }

                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    const getUserDetails = (user_id, accept_proposal_id) => {
        setUserId(user_id);
        setAcceptedProposalId(accept_proposal_id);
    }
    const signUpStep = (step) => {
        setsignUpStep(step);
        if (step == "two") {


            setisProposalAccepted("Yes");
            document.getElementById('FormID1').scrollIntoView();
        }
        if (step == "five") {
            getAcceptedProposal();
        }

        if (step == 'final') {
            setShowLoginLink('Yes');
        }
    }

    useEffect(() => {
        const placeholderElement = document.getElementById('signup_pkg_form_placeholder');
        console.log(showLoginLink, isProposalAccepted, 'isProposalAccepted');

        if (placeholderElement && showLoginLink === 'No' && proposlViewType == 0) {
            if (isProposalAccepted !== 'Yes') {
                ReactDOM.render(<ProposalSignature
                    package_id={proposal.package_id}
                    proposal_id={proposal.proposal_id}
                    proposal_type={proposal.type}
                    signUpStep={signUpStep} />, placeholderElement);
            } else {
                ReactDOM.render(
                    <div id="FormID2">
                        <ProposalSignatureAccepted
                            package_id={proposal.package_id}
                            proposal_id={proposal.proposal_id}
                            signUpStep={signUpStep}
                        />
                    </div>,
                    placeholderElement
                );
            }
        }

        return () => {
            // Cleanup function to remove the rendered component if necessary
            if (placeholderElement && placeholderElement.parentNode) {
                ReactDOM.unmountComponentAtNode(placeholderElement);
            }
        };
    }, [showLoginLink, isProposalAccepted, proposal.package_id, proposal.proposal_id, signUpStep]);

    const replaceTokenWithComponent = (content) => {
        const updatedContent = content.replace(
            '{signup_pkg_form}',
            '<div id="signup_pkg_form_placeholder"></div>'
        );

        // const updatedContent2 = updatedContent.replace(
        //     '$',
        //     currencyword
        // );
        // const updatedContent3 = updatedContent2.replace(
        //     '{currency_symbol}',
        //     currencyword
        // );
        console.log(updatedContent)

        return <div dangerouslySetInnerHTML={{ __html: updatedContent }} />;
    };


    return (
        <React.Fragment>
            {proposal && proposal.type != 'Coaching' && (
                <Helmet>
                    <title> {proposal.name ? proposal.name : " Propoal"} </title>
                    {/* <link rel="stylesheet" href={`${AttachementsUrl}default/proposals-resources/all.css`} />
                    <link rel="stylesheet" href={`${AttachementsUrl}default/proposals-resources/normalize.css`} />
                    <link rel="stylesheet" href={`${AttachementsUrl}default/proposals-resources/uikit.css`} />
                    <link rel="stylesheet" href={`${AttachementsUrl}default/proposals-resources/main.css`} />
                    <link rel="stylesheet" href={`${AttachementsUrl}default/proposals-resources/viewer.css`} />
                    <link rel="stylesheet" href={`${AttachementsUrl}default/proposals-resources/responsive.css`} /> */}
                </Helmet>
            )}
            <Container fluid className="px-0 ediditor-proposl-view">
                <Row className="mx-0">

                    <Col lg="1" className="px-0"></Col>
                    <Col lg="10">
                        <CardBody className="pt-0 px-0" style={{ background: "white" }}>

                            {
                                proposal_pages.map((row, i) => {
                                    let replace = replaceTokenWithComponent(row.body);
                                    return <div key={i} >{replace}</div>
                                })
                            }


                            {showLoginLink == 'Yes' && (
                                <div className="p-3" >
                                    <h5 className="text-center mb-2" >
                                        <Link to="/" style={{ color: "#1e87f0" }}>
                                            Cick here to login now
                                        </Link>
                                    </h5>
                                </div>
                            )}
                            {page_index == 'sign-sealed-delivered' && (
                                <div class="ProposalFooter" style={{ margin: "0" }}>
                                    <div class="container">
                                        <div class="logo">
                                            <img src="https://ws.profitcoach.app/crmdoc/default/images/assessment_proposal/powered.png" alt="Logo" />
                                        </div>
                                        <div class="PageNumber">
                                            <h3>07
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </CardBody>
                    </Col>
                    <Col lg="1" className="px-0"></Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default Proposal