import React, { Component, Fragment } from "react"
import { Row, Col, Card, CardBody, Button, Modal, ModalBody, ModalFooter } from "reactstrap"
import { uToken } from "../../useToken";
import { ApiUrl, ProductName, AttachementsUrl } from "../../config";
import ProfileSettings from "../Authentication/profile-sidebar";
import Loader from "react-loader";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Froala from "../Froala";
import ReactDatatable from "@ashvin27/react-datatable";
import { AvForm, AvField } from "availity-reactstrap-validation";
import 'suneditor/dist/css/suneditor.min.css';
export default class ManageCmsAdvisorPartners extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                text: "Id#",
                key: "id",
                cell: (record, index) => {
                    return index + 1;
                }
            },
            {
                text: "Image",
                key: "image",
                cell: (record, index) => {
                    return (
                        <a href={`${AttachementsUrl}/user_${record.user_id}/partners/${record.image}`} target={'_blank'}>
                            <img width={50} height={50} src={`${AttachementsUrl}/user_${record.user_id}/partners/${record.image}`} className="img img-thumbnail" />
                        </a>
                    )
                }
            },
            {
                text: "Name",
                key: "name",
            },

            {
                text: "Type",
                key: "type",
                cell: (record) => {
                    let badgeClass = "badge ";
                    if (record.type === 'our_advisor') {
                        badgeClass += "bg-primary";
                    } else if (record.type === 'diamond_partner') {
                        badgeClass += "bg-info";
                    }
                    return <p className={badgeClass}>{record.type}</p>;
                }
            },
            {
                text: "Status",
                key: "status",
                cell: (record) => {
                    let badgeClass = "badge ";
                    if (record.status === 'Active') {
                        badgeClass += "bg-success";
                    } else if (record.status === 'InActive') {
                        badgeClass += "bg-danger";
                    }
                    return <p className={badgeClass}>{record.status}</p>;
                }
            },
            {
                text: "Created Date",
                key: "created_at",
            },
            {
                key: "action",
                text: "Action",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <button onClick={() => { this.setState({ row: record, modal: true }) }} className="btn btn-sm btn-secondary mr-5">
                                <i className="mdi mdi-pencil"></i>
                            </button>
                            <button onClick={() => { this.removeProduct(record.id) }} className="btn btn-sm btn-danger">
                                <i className="mdi mdi-trash-can-outline"></i>
                            </button>
                            {/* )} */}
                        </Fragment>
                    );
                }
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: false,
                print: false,
                csv: false,
            },
        };
        this.state = {
            records: [],
            loader: true,
            page_name: 'Advisor & Diamond Partners listings',
            modal: false,
            modal2: false,
            row: {
                id: '',
                name: '',
                image: '',
                position: '',
                user_id: '',
                type: '',
            },
            filter: {},

        }
    }


    componentDidMount() {
        this.getData();
    }

    getData = () => {
        fetch(`${ApiUrl}` + 'get/diamond/partners', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + uToken()
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    let filteredRecords = response.data.records;
                    if (this.state.filter.type && this.state.filter.type !== 'All') {
                        filteredRecords = filteredRecords.filter(record => record.type === this.state.filter.type);
                    }
                    if (this.state.filter.status) {
                        filteredRecords = filteredRecords.filter(record => record.status === this.state.filter.status);
                    }

                    this.setState({
                        records: filteredRecords,
                    });

                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }

            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    handleFilterSubmit = async (event, values) => {
        values.type = values.type === 'All' ? '' : values.type;

        this.setState({
            filter: values,
        });

        this.getData();
    };

    cancelFilter = async () => {
        this.form && this.form.reset();
        this.setState({ filter: {}, customDate: true });
        this.getData("", "");
    };

    submitPage = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        fetch(`${ApiUrl}` + "add/diamond/partners", {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    this.setState({
                        row: {
                            id: '',
                            name: '',
                            image: '',
                            position: '',
                            user_id: '',
                            type: '',

                        },
                        modal: false
                    })
                    this.getData();
                } else {
                    swal({
                        title: data.message,
                        icon: "warning",
                    });
                    // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
                this.setState({ loader: true });
            })
            .catch((error) => {
                this.setState({ loader: true });

                console.error("Error:", error);
            });
    }


    removeProduct = (id) => {
        swal({
            title: "Are you sure?",
            text: "You want to remove this record.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "delete/diamond/partners", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                        body: JSON.stringify({ id: id }),
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.status === true) {
                                this.getData();
                            } else {
                                swal({
                                    title: data.message,
                                    icon: "warning",
                                });
                            }
                        })
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    }
    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="2" className="pl-0">
                        <ProfileSettings menuOpen={false} props={this.props} />
                    </Col>
                    <Col lg="10">
                        <Loader loaded={this.state.loader}></Loader>
                        <Row>
                            <Col sm={6} >
                                <div className="page-title-box">
                                    <h4> {this.state.page_name} </h4>
                                    <ol className="breadcrumb m-0" >
                                        <li key={0} className="breadcrumb-item active"> {ProductName} </li>
                                        <li key={1} className="breadcrumb-item"> < Link to="#"> {this.state.page_name} </Link></li>
                                    </ol>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="page-title-box text-align-right ">
                                    <Button type="button"
                                        color="info"
                                        onClick={
                                            () => this.setState({
                                                modal: true, row: {
                                                    id: '',
                                                    name: '',
                                                    image: '',
                                                    position: '',
                                                    user_id: '',
                                                    type: '',

                                                }
                                            })}>
                                        <i className="mdi mdi-plus"></i> Create
                                    </Button>
                                </div>

                            </Col>
                        </Row>


                        <Row>
                            <Col xl="12">
                                <Card className="mb-0">
                                    <CardBody className="py-0">
                                        <AvForm
                                            onValidSubmit={this.handleFilterSubmit}
                                            ref={(c) => (this.form = c)}
                                            className="needs-validation"
                                        >
                                            <Row>

                                                <Col md="4">
                                                    <div className="mb-3">
                                                        <AvField
                                                            className="select form-control"
                                                            type="select"
                                                            name="type"
                                                            label="Type Status"
                                                        >
                                                            <option value={""}> Select Type </option>
                                                            <option value={"All"}>All</option>
                                                            <option value={"diamond_partner"}> Diamond Partner </option>
                                                            <option value={"our_advisor"}> Our Advisor </option>
                                                        </AvField>
                                                    </div>
                                                </Col>

                                                <Col md="4" >
                                                    <div className="mb-3">
                                                        <AvField
                                                            className="select form-control"
                                                            type="select"
                                                            id="s"
                                                            name="status"
                                                            label="Status"
                                                        >
                                                            <option value={""}> Select Status </option>
                                                            <option value={"Active"}> Active </option>
                                                            <option value={"InActive"}> InActive </option>

                                                        </AvField>
                                                    </div>
                                                </Col>


                                                <Col md="2" className="px-0">
                                                    <label className="">Action</label>
                                                    <div className="mb-3">
                                                        <div className="button-items">
                                                            <Button
                                                                className="btn-sm"
                                                                color="primary"
                                                                type="submit"
                                                            >
                                                                Filter
                                                            </Button>
                                                            <Button
                                                                onClick={() => this.cancelFilter()}
                                                                outline
                                                                color="secondary"
                                                                className="waves-effect btn-sm"
                                                                type="button"
                                                            >
                                                                Reset
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>


                        <Modal
                            isOpen={this.state.modal}
                            fade={false}
                            toggle={() => this.setState({ modal: false })}
                        >
                            <form onSubmit={this.submitPage} method="post"
                                encType="multipart/form-data" className="form-horizontal" id="selection-myform-testimonial">
                                <ModalBody>
                                    <Row>
                                        <Col md="12">
                                            <div className="form-group mb-3">
                                                <label>Name</label>
                                                <input
                                                    name="name"
                                                    defaultValue={this.state.row.name}
                                                    className="form-control"
                                                    placeholder="Enter name"
                                                    required
                                                />
                                                <input
                                                    name="id"
                                                    defaultValue={this.state.row.id}
                                                    type='hidden'
                                                />
                                            </div>
                                        </Col>

                                        {
                                            <Col md="12">
                                                <div className="form-group mb-3">
                                                    <label>Type</label>
                                                    <select name="type" className="form-control" defaultValue={this.state.row.type}>
                                                        <option value={'our_advisor'}>Our Advisor</option>
                                                        <option value={'diamond_partner'}>Diamond Partner</option>
                                                    </select>
                                                </div>
                                            </Col>
                                        }

                                        <Col md="12">
                                            <div className="form-group mb-3">
                                                <label>Position</label>
                                                <input
                                                    name="position"
                                                    defaultValue={this.state.row.position}
                                                    className="form-control"
                                                    placeholder="Enter position"
                                                />

                                            </div>
                                        </Col>


                                        <Col md="12">
                                        
                                            <Col md="12">
                                            
                                                <div className="form-group mb-3">
                                                    <label>Message</label>

                                                    <Froala content={this.state.row.message ?? ''} nameKey={'message'} insideForm={true} btnId={'submit-blog'} handleSubmit={() => console.log('hi')} use_token={'users'} />

                            
                                                </div>
                                            </Col>

                                        </Col>
                                        <Col md="12">
                                            <div className="form-group mb-3">
                                                <label>Image</label>
                                                <input
                                                    name="image"
                                                    className="form-control"
                                                    placeholder="Enter title"
                                                    type={'file'}
                                                />
                                                {
                                                    this.state.row.image != 0 && (
                                                        <img width={"200px"} src={`${AttachementsUrl}/user_${this.state.row.user_id}/partners/${this.state.row.image}`} className="img img-thumbnaikl" />
                                                    )
                                                }
                                            </div>
                                        </Col>


                                        {
                                            <Col md="12">
                                                <div className="form-group mb-3">
                                                    <label>Status</label>
                                                    <select name="status" className="form-control" defaultValue={this.state.row.status}>
                                                        <option value={'Active'}>Active</option>
                                                        <option value={'InActive'}>InActive</option>
                                                    </select>
                                                </div>
                                            </Col>
                                        }
                                    </Row>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" type="button" onClick={() => this.setState({ modal: false })}>Cancel</Button>
                                    <Button className="Green" type="submit">Submit</Button>
                                </ModalFooter>
                            </form>
                        </Modal>

                        <Card>
                            <CardBody>
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment >
        )
    }
}