import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  CardBody,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  CardTitle,
} from "reactstrap";
import {
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../useToken";
//Import Action to copy breadcrumb items from local state to redux state

const UiDropdown = (props) => {
  useEffect(() => {



  });
  const [subscriptionid, setsubscriptionid] = useState(props.props);
  const [userid, setuserid] = useState(props.userid);
  const [trans_id, settrans_id] = useState(props.trans_id);
  const [btnInfo1, setBtnInfo1] = useState(false);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={btnInfo1}
        toggle={() => setBtnInfo1(!btnInfo1)}
        className="btn-group me-1 mt-2"
      >
        <DropdownToggle tag="button" className="btn btn-info dropdown-toggle ti-settings">

        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem>
            <li>
              <Link
                to={"/wl/subscription/history/" + bcrypt(subscriptionid) + '/' + bcrypt(userid)}
              >
                <i className="mdi mdi-information"> </i> Subscription History
              </Link>
            </li>
          </DropdownItem>
          <DropdownItem>
            <li>
              <Link
                to={"/wl/subscription/history/invoice/" + bcrypt(subscriptionid) + '/' + bcrypt(trans_id) + '/' + bcrypt(userid)}
              >
                <i className="mdi mdi-file-multiple"></i> Current Receipt
              </Link>
            </li>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default UiDropdown;
