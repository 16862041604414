import React, { Component, Fragment } from "react"
import { Row, Col, Card, CardBody, CardTitle, Form, Label, Button, Modal, ModalHeader, Input, ModalBody, ModalFooter, Table } from "reactstrap"
import { bcrypt, uInfo, uToken } from "../../useToken";
import { ApiUrl, ProductName, AttachementsUrl } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import ProfileSettings from "../Authentication/profile-sidebar";
import Loader from "react-loader";
import { Link } from "react-router-dom";

export default class Branding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            records: [],
            loader: true,
            page_name: 'Mangage Product',
            modal: false,
            modal2: false,
            row: {
                id: '',
                title: '',
                description: '',
                image: 0
            }
        }
    }


    componentDidMount() {
        this.getData();
    }

    getData = () => {
        fetch(`${ApiUrl}` + 'get/products', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + uToken()
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {

                    this.setState({
                        records: response.data.records,
                    });

                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }

            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }

    submitPage = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        fetch(`${ApiUrl}` + "add/product", {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                if (data.status === true) {
                    this.setState({
                        row: {
                            id: '',
                            title: '',
                            description: '',
                            image: 0
                        },
                        modal: false
                    })
                    this.getData();
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
                this.setState({ loader: true });
            })
            //Then with the error genereted...
            .catch((error) => {
                this.setState({ loader: true });

                console.error("Error:", error);
            });
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="2" className="pl-0">
                        <ProfileSettings menuOpen={false} props={this.props} />
                    </Col>
                    <Col lg="10">
                        <Loader loaded={this.state.loader}></Loader>
                        <Row>
                            <Col sm={6} >
                                <div className="page-title-box">
                                    <h4> {this.state.page_name} </h4>
                                    <ol className="breadcrumb m-0" >
                                        <li key={0} className="breadcrumb-item active"> {ProductName} </li>
                                        <li key={1} className="breadcrumb-item"> < Link to="#"> {this.state.page_name} </Link></li>
                                    </ol>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="page-title-box text-align-right ">
                                    <Button type="button"
                                        color="secondary"
                                        onClick={
                                            () => this.setState({
                                                modal: true, row: {
                                                    id: '',
                                                    title: '',
                                                    description: '',
                                                    image: 0
                                                }
                                            })}>
                                        Create
                                    </Button>
                                </div>


                            </Col>
                        </Row>

                        <Modal
                            isOpen={this.state.modal}
                            fade={false}
                            toggle={() => this.setState({ modal: false })}
                        >
                            <form onSubmit={this.submitPage} method="post"
                                encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                                <ModalBody>
                                    <Row>
                                        <Col md="12">
                                            <div className="form-group mb-3">
                                                <label>Title</label>
                                                <input
                                                    name="title"
                                                    defaultValue={this.state.row.title}
                                                    className="form-control"
                                                    placeholder="Enter title"
                                                    required
                                                />
                                                <input
                                                    name="id"
                                                    defaultValue={this.state.row.id}
                                                    type='hidden'
                                                />
                                            </div>
                                        </Col>

                                        <Col md="12">
                                            <div className="form-group mb-3">
                                                <label>Enter Description</label>
                                                <textarea
                                                    defaultValue={this.state.row.description}
                                                    name="description"
                                                    className="form-control"
                                                    placeholder="Enter description"
                                                    required
                                                />
                                            </div>
                                        </Col>

                                        <Col md="12">
                                            <div className="form-group mb-3">
                                                <label>Image</label>
                                                <input
                                                    name="image"
                                                    className="form-control"
                                                    placeholder="Enter title"
                                                    type={'file'}
                                                />

                                                <img width={"200px"} src={`${AttachementsUrl}/user_${this.state.row.user_id}/products/${this.state.row.image}`} className="img img-thumbnaikl" />
                                            </div>
                                        </Col>
                                    </Row>
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        color="info"
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        color="danger"
                                        type="button"
                                        onClick={() => this.setState({ modal: false })}
                                    >
                                        Cancel
                                    </Button>
                                </ModalFooter>
                            </form>
                        </Modal>

                        <Card>
                            <CardBody>
                                <table className="table table-border">
                                    <thead>
                                        <tr>
                                            <th>Id#</th>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th>Image</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.records.map((row, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{row.id}</td>
                                                    <td>{row.title}</td>
                                                    <td>{row.description}</td>
                                                    <td>
                                                        <a href={`${AttachementsUrl}/user_${row.user_id}/products/${row.image}`} target={'_blank'}>
                                                            <img width={100} height={100} src={`${AttachementsUrl}/user_${row.user_id}/products/${row.image}`} className="img img-thumbnail" />
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <button onClick={() => { this.setState({ row: row, modal: true }) }} className="btn btn-secondary mr-5">
                                                            Edit
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment >
        )
    }
}