import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import { AvForm, AvField, AvInput, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox, AvGroup } from "availity-reactstrap-validation";
import {
    currentDateTime,
    dcrypt,
    getPages,
    getPermission,
    getRoles,
    isAllowed,
    uInfo,
    uRole,
    uToken,
} from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
// import '../../../node_modules/bootstrap/js/dist/tooltip';
import Select from "react-select"
import countries from "../../common/data/countries";
import AsyncSelect from 'react-select/async';

export default class FinalDealStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id != '' ? this.props.id : '',
            record: {},
            pipelineStatus: [],
            tokens: [],
            notificationEmail: {
                cc_email: '',
                token: '',
                subject: '',
                body: ''
            },
            selectedToken: ''
        };
    }

    componentDidMount() {
        if (this.state.id != '') {
            this.getData();
        }
        this.getFormData();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.id != this.props.id) {
            this.setState({
                id: this.props.id != '' ? this.props.id : '',
                selectedToken: '',
                record: {}, notificationEmail: {
                    cc_email: '',
                    token: '',
                    subject: '',
                    body: ''
                }
            }, () => this.getData())
        }
    }

   

    getFormData = () => {
        fetch(`${ApiUrl}` + "get/contract/formdata", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {

                if (response.status === true) {
                    var tokens_group = [
                        { label: 'Default Field', options: response.data.tokens.default_fields },
                        { label: 'Custom Field', options: response.data.tokens.custom_fields }
                    ];

                    this.setState({
                        pipelineStatus: response.data.pipelineStatus,
                        tokens: tokens_group,
                        selectedToken:''
                    });
                } else {
                              
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    getData = () => {

        fetch(`${ApiUrl}` + "get/edit/contract", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },

            body: JSON.stringify({ id: this.state.id },
            ),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        record: response.data.record,
                    });

                    if (response.data.record.dealFinalEmail != "{}") {
                        this.setState({
                            notificationEmail: response.data.record.dealFinalEmail,
                        });
                    }
                } else {
                              
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    handleSubmit = async (event, values) => {
        if (this.state.id) {
            values.id = this.state.record.contract_id ? this.state.record.contract_id : -1;
        }
        values.deal_final_sub = JSON.stringify(this.state.notificationEmail);
        fetch(`${ApiUrl}` + "update/final/contract", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data, 'Editing');
                if (data.status === true) {
                    this.form && this.form.reset();
                    this.props.cancelSlider();
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    getAlert = () => {
        this.form && this.form.reset();
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <AvForm
                                onValidSubmit={this.handleSubmit}
                                // model={this.state.record}
                                ref={(c) => (this.form = c)}
                                className="needs-validation"
                            >
                                <ModalBody className="row">

                                    <div className="col-md-12 mb-3">
                                        <AvField
                                            name="deal_status_final"
                                            value={this.state.record.deal_status_final}
                                            label="Deal Status ( When Contract assigned to Client )"
                                            className="form-control"
                                            type="select"
                                            required
                                        >
                                            <option value={''}>Select Status</option>
                                            {
                                                this.state.pipelineStatus.map((row, i) => {
                                                    return <option value={row.status_id} key={i}>{row.title}</option>
                                                })
                                            }
                                        </AvField>
                                    </div>
                                    <h6 className="text-link">Email Notification</h6>
                                    <div className="mb-3">
                                        <AvField
                                            label="CC Email"
                                            className="form-control"
                                            type="text"
                                            name="cc_email"
                                            required
                                            value={this.state.notificationEmail.cc_email}
                                            onChange={(e) => this.setState(prevState => {
                                                let notificationEmail = Object.assign({}, prevState.notificationEmail);
                                                notificationEmail.cc_email = e.target.value;
                                                return { notificationEmail };
                                            })}
                                        />
                                    </div>



                                    <div className="col-md-12 mb-1">
                                        <Select
                                            getOptionLabel={option => option.label}
                                            getOptionValue={option => option.key}
                                            // value={options.filter(({ id }) => id === this.state.country_code)}
                                            isMulti={false}
                                            options={this.state.tokens}
                                            classNamePrefix="select2-selection"
                                            name="country_id"
                                            placeholder="Select Token"
                                            onChange={(e) => {
                                                this.setState({
                                                    selectedToken: `{${e.key}}`
                                                })
                                                // this.setState(prevState => {
                                                //     let notificationEmail = Object.assign({}, prevState.notificationEmail);
                                                //     notificationEmail.token = `{${e.key}}`;
                                                //     return { notificationEmail };
                                                // })
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <button onClick={() =>
                                            this.setState(prevState => {
                                                let notificationEmail = Object.assign({}, prevState.notificationEmail);
                                                notificationEmail.body = this.state.selectedToken + '' + this.state.notificationEmail.body;
                                                return { notificationEmail };
                                            })}
                                            type="button" className="btn btn-primary">Insert Body</button>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <button onClick={() =>
                                            this.setState(prevState => {
                                                let notificationEmail = Object.assign({}, prevState.notificationEmail);
                                                notificationEmail.subject = this.state.selectedToken + '' + this.state.notificationEmail.subject;
                                                return { notificationEmail };
                                            })}
                                            type="button" className="btn btn-primary">Insert Subject</button>
                                    </div>

                                    <div className="mb-3">
                                        <AvField
                                            className="form-control"
                                            type="text"
                                            label="Subject"
                                            name="subject"
                                            placeholder="Subject"
                                            required
                                            value={this.state.notificationEmail.subject}
                                            onChange={(e) => this.setState(prevState => {
                                                let notificationEmail = Object.assign({}, prevState.notificationEmail);
                                                notificationEmail.subject = e.target.value;
                                                return { notificationEmail };
                                            })}
                                        />
                                    </div>


                                    <div className="mb-3">
                                        <AvField
                                            className="form-control"
                                            type="textarea"
                                            label="Email Body"
                                            name="body"
                                            placeholder="Enter body"
                                            required
                                            value={this.state.notificationEmail.body}
                                            rows={5}
                                            onChange={(e) => this.setState(prevState => {
                                                let notificationEmail = Object.assign({}, prevState.notificationEmail);
                                                notificationEmail.body = e.target.value;
                                                return { notificationEmail };
                                            })}
                                        />

                                    </div>


                                </ModalBody>
                                <ModalFooter>
                                    <button
                                        className="btn btn-primary w-md waves-effect waves-light"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </ModalFooter>
                            </AvForm>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment >
        );
    }
}
