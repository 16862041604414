import React, { Component, Fragment } from "react"
import { Row, Col, Card, CardBody, CardTitle, Form, Label, Button, ModalHeader, Input, ModalBody, ModalFooter, Table } from "reactstrap"

import Helmet from "react-helmet"
import axios from "axios"
import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation"
import { bcrypt, isAllowed, uInfo, uToken } from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import { data } from "jquery"
import swal from 'sweetalert';
import { Modal } from "react-bootstrap";
import { Draggable } from "react-drag-reorder";

export default class CustomField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page_name: "Custom Field",
            defaultFields: [{ "label": "Title", "type": "textbox", "options": [], "key": "title", "alternate": "" }, { "label": "First Name", "type": "textbox", "options": [], "key": "first_name", "alternate": "" }, { "label": "Last Name", "type": "textbox", "options": [], "key": "last_name", "alternate": "" }, { "label": "Group", "type": "textbox", "options": [], "key": "group", "alternate": "" }, { "label": "Email", "type": "emailtextbox", "options": [], "key": "email", "alternate": "" }, { "label": "Phone Extension", "type": "textbox", "options": [], "key": "phone_extension", "alternate": "" }, { "label": "Phone Number", "type": "phonetextbox", "options": [], "key": "phone_number", "alternate": "" }, { "label": "City", "type": "textbox", "options": [], "key": "city", "alternate": "" }, { "label": "State", "type": "textbox", "options": [], "key": "state", "alternate": "" }, { "label": "Zip", "type": "textbox", "options": [], "key": "zip", "alternate": "" }, { "label": "Address", "type": "textbox", "options": [], "key": "address", "alternate": "" }, { "label": "Company name", "type": "textbox", "options": [], "key": "company_name", "alternate": "" }, { "label": "LinkedIn", "type": "linkfield", "options": [], "key": "linkedin", "alternate": "" }, { "label": "Website", "type": "linkfield", "options": [], "key": "website", "alternate": "" }],
            customFields: [],
            showModal: false
            // customFields: [{ "label": "aa", "type": "textbox", "options": [], "key": "aa", "alternate": "", "key_0": "a" }, { "label": "bs", "type": "textbox", "options": [], "key": "bs", "alternate": "", "key_1": "b" }, { "label": "ds", "type": "textbox", "options": [], "key": "ds", "alternate": "", "key_3": "d" }, { "label": "City", "type": "select", "options": [{ "option": "Islamabad" }, { "option": "Lahore" }], "key": "city", "alternate": "" }, { "label": "Country", "type": "select", "options": [{ "option": "Pk" }, { "option": "us" }], "key": "country", "alternate": "" }],
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    componentDidMount() {
        this.getData();
    }

    addClick() {
        this.setState(prevState => ({
            customFields: [{ [`label`]: "", type: "textbox", options: [], [`key`]: "", [`alternate`]: "" }, ...prevState.customFields]
        }))
    }


    addOptionClick(i, e) {
        let customFields = [...this.state.customFields];
        customFields[i].options = [...customFields[i].options, { [`option`]: "" }];
        this.setState({ customFields });
    }


    createUIReorder() {
        return this.state.customFields.map((el, i) => (
            <div className="border py-3 px-2" key={i}>
                <div className="row">
                    <div className="col-md-4 mb-3">
                        <div className="form-group">
                            <input placeholder="Enter label" name="key" required="" id="label" type="text"
                                className="form-control" value={`${el[`key`]}` || ''} readOnly onChange={this.handleChange.bind(this, i)} />
                        </div>

                    </div>
                    <div className="col-md-4 mb-3">
                        <div className="form-group">
                            <input placeholder="Enter label" name="label" required="required" id="label" type="text"
                                className="form-control" value={`${el[`label`]}` || ''} readOnly onChange={this.handleChange.bind(this, i)} />

                        </div>



                    </div>

                    <div className="col-md-4">

                        <div className="form-group">
                            <select className="select form-control"
                                // selectedValue={el.type || 'textbox'}
                                type="select"
                                disabled
                                value={el.type || 'textbox'}
                                name={`type`}
                                onChange={this.handleChange.bind(this, i)}
                            >
                                <option value="textbox">Text Box</option>
                                <option value="textarea">Text Area</option>
                                <option value="nametextbox">Name</option>
                                <option value="phonetextbox">Phone</option>
                                <option value="mobiletextbox">Mobile</option>
                                <option value="emailtextbox">Email</option>
                                <option value="date">Date</option>
                                <option value="radio">Radio</option>
                                <option value="checkbox">Checkbox</option>
                                <option value="select">Select</option>
                                <option value="linkfield">Link</option>
                            </select>
                        </div>



                    </div>

                </div>

            </div>
        ))
    }

    createUI() {
        return this.state.customFields.map((el, i) => (
            <div className="border py-3 px-2" key={i}>
                <div className="row">
                    <div className="col-md-3 mb-3">
                        <div className="form-group">
                            <input placeholder="Enter label" name="key" required="" id="label" type="text"
                                className="form-control" value={`${el[`key`]}` || ''} readOnly onChange={this.handleChange.bind(this, i)} />
                        </div>

                        {/* <AvField
                            key={i}
                            placeholder="Enter label"
                            name={`key`}
                            value={`${el[`key`]}` || ''}
                            className="form-control"
                            onChange={this.handleChange.bind(this, i)}
                            readOnly
                        /> */}
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="form-group">
                            <input placeholder="Enter label" name="label" required="required" id="label" type="text"
                                className="form-control" value={`${el[`label`]}` || ''} onChange={this.handleChange.bind(this, i)} />

                        </div>
                        {/* <AvField
                            key={i}
                            placeholder="Enter label"
                            name={`label`}
                            value={`${el[`label`]}` || ''}
                            className="form-control"
                            onChange={this.handleChange.bind(this, i)}
                            required
                        /> */}


                    </div>
                    <div className="col-md-3 mb-3">

                        <div className="form-group">
                            <input placeholder="Enter Alternate value" name="alternate" required="" type="text"
                                className="form-control" value={`${el[`alternate`]}` || ''} onChange={this.handleChange.bind(this, i)} />
                        </div>
                        {/* 
                        <AvField
                            key={i}
                            placeholder="Enter Alternate value"
                            name={`alternate`}
                            value={`${el[`alternate`]}` || ''}
                            className="form-control"
                            onChange={this.handleChange.bind(this, i)}

                        /> */}

                    </div>
                    <div className="col-md-3">
                        <div className="grid-style">

                            <div className="form-group">
                                <select className="select form-control"
                                    // selectedValue={el.type || 'textbox'}
                                    type="select"
                                    value={el.type || 'textbox'}
                                    name={`type`}
                                    onChange={this.handleChange.bind(this, i)}
                                >
                                    <option value="textbox">Text Box</option>
                                    <option value="textarea">Text Area</option>
                                    <option value="nametextbox">Name</option>
                                    <option value="phonetextbox">Phone</option>
                                    <option value="mobiletextbox">Mobile</option>
                                    <option value="emailtextbox">Email</option>
                                    <option value="date">Date</option>
                                    <option value="radio">Radio</option>
                                    <option value="checkbox">Checkbox</option>
                                    <option value="select">Select</option>
                                    <option value="linkfield">Link</option>
                                </select>
                            </div>

                            {/* <AvField
                                className="select"
                                selectedValue={el.type || 'textbox'}
                                type="select"
                                value={el.type || 'textbox'}
                                name={`type`}
                                onChange={this.handleChange.bind(this, i)}
                            >
                                <option value="textbox">Text Box</option>
                                <option value="textarea">Text Area</option>
                                <option value="nametextbox">Name</option>
                                <option value="phonetextbox">Phone</option>
                                <option value="mobiletextbox">Mobile</option>
                                <option value="emailtextbox">Email</option>
                                <option value="date">Date</option>
                                <option value="radio">Radio</option>
                                <option value="checkbox">Checkbox</option>
                                <option value="select">Select</option>
                                <option value="linkfield">Link</option>
                            </AvField> */}
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={this.removeClick.bind(this, i)}>
                                <i className="ion ion-md-trash"> </i>
                            </button>
                        </div>
                    </div>

                </div>
                <div className={`${el.options.length > 0 ? 'border py-2 px-2' : ''}`}>
                    <div className="row">
                        {
                            el.options.map((a, k) => (
                                <div className="col-md-4 mb-3" key={k}>

                                    <div className="input-group mb-3 form-group">
                                        <input placeholder="Enter label" name={`option`} required="required" type="text"
                                            className="form-control "
                                            value={`${a[`option`]}` || ''}
                                            onChange={this.handleOptionChange.bind(this, i, k)} />
                                        <div className="input-group-text">
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={this.removeOptionClick.bind(this, i, k)}>
                                                <i className="ion ion-md-trash"> </i>
                                            </button>
                                        </div>
                                    </div>
                                    {/* <AvGroup className="input-group mb-3">
                                        <AvInput
                                            placeholder="Enter label"
                                            name={`option_${k}`}
                                            // value={a.option || ''}
                                            value={`${a[`option_${k}`]}` || ''}
                                            className="form-control"
                                            onChange={this.handleOptionChange.bind(this, i, k)}
                                            required
                                        />
                                        <div className="input-group-text">
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={this.removeOptionClick.bind(this, i, k)}>
                                                <i className="ion ion-md-trash"> </i>
                                            </button>
                                        </div>
                                    </AvGroup> */}
                                </div>

                            ))
                        }
                        {
                            el.options.length > 0 && (
                                <div className="col-md-12 text-center">
                                    <button
                                        type="button"
                                        className="btn btn-info btn-sm width-350"
                                        onClick={this.addOptionClick.bind(this, i)}>
                                        Add Optios
                                    </button>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        ))
    }

    defaultUI() {
        return this.state.defaultFields.map((el, i) => (
            <div className="border py-3 px-2" key={i}>
                <div className="row">
                    <div className="col-md-3 mb-3">
                        <div className="form-group">
                            <input placeholder="Enter label" name="key" required="" id="label" type="text"
                                className="form-control"
                                value={`${el[`key`]}` || ''}
                                readOnly
                            />
                        </div>
                        {/* <AvField
                            key={i}
                            placeholder="Enter label"
                            name={`key_${i}`}
                            value={`${el[`key_${i}`]}` || ''}
                            className="form-control"
                            // onChange={this.handleChange.bind(this, i)}
                            readOnly
                        /> */}
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="form-group">
                            <input placeholder="Enter label" name="label" required="" id="label" type="text"
                                className="form-control"
                                value={`${el[`label`]}` || ''}
                                readOnly
                            />
                        </div>
                        {/* <AvField
                            key={i}
                            placeholder="Enter label"
                            name={`label_${i}`}
                            value={`${el[`label_${i}`]}` || ''}
                            className="form-control"
                            // onChange={this.handleChange.bind(this, i)}
                            required
                            readOnly
                        /> */}


                    </div>
                    <div className="col-md-3 mb-3">
                        <input placeholder="Enter Alternate value" name="alternate" required="" id="alternate" type="text"
                            className="form-control"
                            value={`${el[`alternate`]}` || ''}
                            onChange={this.handleDefaultChange.bind(this, i)}

                        />
                        {/* <AvField
                            key={i}
                            placeholder="Enter Alternate value"
                            name={`alternate_${i}`}
                            value={`${el[`alternate_${i}`]}` || ''}
                            className="form-control"
                            onChange={this.handleDefaultChange.bind(this, i)}

                        /> */}

                    </div>
                    <div className="col-md-3">

                        <div className="form-group">
                            <select
                                className="select form-control"
                                // selectedValue={el.type || 'textbox'}
                                type="select"
                                value={el.type || 'textbox'}
                                name={`type`}
                                disabled={true}
                            >
                                <option value="textbox">Text Box</option>
                                <option value="textarea">Text Area</option>
                                <option value="nametextbox">Name</option>
                                <option value="phonetextbox">Phone</option>
                                <option value="mobiletextbox">Mobile</option>
                                <option value="emailtextbox">Email</option>
                                <option value="date">Date</option>
                                <option value="radio">Radio</option>
                                <option value="checkbox">Checkbox</option>
                                <option value="select">Select</option>
                                <option value="linkfield">Link</option>
                            </select>
                        </div>

                        {/* <AvField
                            className="select"
                            selectedValue={el.type || 'textbox'}
                            type="select"
                            value={el.type || 'textbox'}
                            name={`type`}
                            // onChange={this.handleChange.bind(this, i)}
                            disabled={true}
                        >
                            <option value="textbox">Text Box</option>
                            <option value="textarea">Text Area</option>
                            <option value="nametextbox">Name</option>
                            <option value="phonetextbox">Phone</option>
                            <option value="mobiletextbox">Mobile</option>
                            <option value="emailtextbox">Email</option>
                            <option value="date">Date</option>
                            <option value="radio">Radio</option>
                            <option value="checkbox">Checkbox</option>
                            <option value="select">Select</option>
                            <option value="linkfield">Link</option>
                        </AvField> */}
                    </div>

                </div>
            </div>
        ))
    }

    removeOptionClick(i, k, e) {
        let customFields = [...this.state.customFields];
        customFields[i].options.splice(k, 1);
        this.setState({ customFields });
    }

    handleOptionChange(i, k, e) {
        const { name, value } = e.target;
        let customFields = [...this.state.customFields];
        customFields[i].options[k] = { ...customFields[i].options[k], [name]: value };
        this.setState({ customFields });
    }


    handleDefaultChange(i, e) {
        const { name, value } = e.target;
        let defaultFields = [...this.state.defaultFields];
        defaultFields[i] = { ...defaultFields[i], [name]: value };
        this.setState({ defaultFields });
    }

    handleChange(i, e) {
        const { name, value } = e.target;

        let customFields = [...this.state.customFields];
        if (name.includes("label")) {
            customFields[i] = { ...customFields[i], [name]: value, [`key`]: value.replace(/[^A-Z0-9]+/ig, "_").toLowerCase() };
        } else {
            customFields[i] = { ...customFields[i], [name]: value };
        }

        const displayOption = ['radio', 'checkbox', 'select'];
        if (name === 'type' && displayOption.includes(value)) {
            customFields[i].options = [...customFields[i].options, { option: "" }];
        } else {
            customFields[i].options = [];
        }
        this.setState({ customFields });
    }

    removeClick(i) {
        let customFields = [...this.state.customFields];
        console.log(customFields)
        customFields.splice(i, 1);
        console.log(customFields[i])

        console.log(customFields)
        this.setState({ customFields });
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        // if (errors.length < 1) {
        fetch(`${ApiUrl}` + 'add/custom/field', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + uToken()
            },
            body: JSON.stringify({
                custom_fields: JSON.stringify(this.state.customFields),
                default_fields: JSON.stringify(this.state.defaultFields),
            }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    //toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                    swal({
                        text: 'Action performed successfully',
                        icon: "success",
                    });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error('Error:', error);
            });
        // }

    }

    getData = () => {
        fetch(`${ApiUrl}` + 'get/custom/field', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + uToken()
            },
            body: JSON.stringify({}),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {

                    if (JSON.parse(response.data.record.default_fields).length > 0) {
                        this.setState({
                            defaultFields: JSON.parse(response.data.record.default_fields)
                        });
                    }

                    this.setState({
                        customFields: JSON.parse(response.data.record.custom_fields),
                    })
                }

            })
            //Then with the error genereted...
            .catch((error) => {
                console.error('Error:', error);
            });

    }
    getChangedPos = (currentPos, newPos) => {
        console.log(currentPos, newPos);

        const { customFields } = this.state;
        const updatedItems = [...customFields];

        // Swap the elements
        const temp = updatedItems[currentPos];
        updatedItems[currentPos] = updatedItems[newPos];
        updatedItems[newPos] = temp;

        this.setState({ customFields: updatedItems });

    };

    toggleModal = () => {
        this.setState((prevState) => ({
            showModal: !prevState.showModal,
        }));
        this.getData();
    };
    updateSequence = () => {
        fetch(`${ApiUrl}` + 'add/custom/field', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + uToken()
            },
            body: JSON.stringify({
                custom_fields: JSON.stringify(this.state.customFields),
                default_fields: JSON.stringify(this.state.defaultFields),
            }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    this.toggleModal();
                    //toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });

                } else {
                    swal({
                        text: response.message,
                        icon: "warning",
                    });
                    // toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{this.state.page_name} Tables </title>
                </Helmet>

                <ToastContainer />

                <form method="POST" onSubmit={this.handleSubmit} className="custom-field-page">
                    <Row>
                        <Col sm={6}>
                            <div className="page-title-box">
                                <h4>{this.state.page_name} Lists</h4>
                                <ol className="breadcrumb m-0">
                                    <li key={0} className="breadcrumb-item active">{ProductName}</li>
                                    <li key={1} className="breadcrumb-item"><Link to="#">{this.state.page_name} Lists</Link></li>
                                </ol>
                            </div>
                        </Col>
                        <Col sm={6}>


                            <div className="page-title-box text-align-right">
                                <input className="btn btn-info mr-5" type="submit" value="Save" />
                                <button
                                    type="button"
                                    className="btn btn-info"
                                    onClick={this.addClick.bind(this)}>
                                    Add Field
                                </button>

                            </div>
                        </Col>
                    </Row>

                    <Card>
                        <CardBody>
                            <div className="row mx-0">
                                <div className="col-md-3 mb-2 CustonField">Default Token Index</div>
                                <div className="col-md-3 mb-2 CustonField">Default Token Values</div>
                                <div className="col-md-3 mb-2 CustonField">Alternate Token Values</div>
                                <div className="col-md-3 mb-2 CustonField">Type</div>
                            </div>
                            <div className="height-350">
                                {this.defaultUI()}
                            </div>


                            <div className="text-align-right  mb-3 mt-3">
                                <button onClick={this.toggleModal} type="button" className="btn btn-primary">Reorder Custom Fields</button>
                            </div>



                            <div className="row mx-0">
                                <div className="col-md-3 mb-2 CustonField">Custom Token Index</div>
                                <div className="col-md-3 mb-2 CustonField">Custom Token Values</div>
                                <div className="col-md-3 mb-2 CustonField">Alternate Token Values</div>
                                <div className="col-md-3 mb-2 CustonField">Type</div>
                            </div>
                            {this.createUI()}

                        </CardBody>
                    </Card>
                </form>

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    show={this.state.showModal}
                    scrollable={true}
                    onHide={this.toggleModal}>
                    {/* <Modal.Header closeButton>
                        <Modal.Title>Custom field ordering </Modal.Title>
                    </Modal.Header> */}

                    <div className="row mx-3 mt-3">
                        <div className="col-md-4 mb-2 CustonField">Custom Token Index</div>
                        <div className="col-md-4 mb-2 CustonField">Custom Token Values</div>
                        <div className="col-md-4 mb-2 CustonField">Type</div>
                    </div>
                    <Modal.Body>
                        {
                            this.state.customFields.length > 0 && (
                                <Draggable onPosChange={this.getChangedPos}>
                                    {this.createUIReorder()}
                                </Draggable>
                            )
                        }
                    </Modal.Body>

                    <Modal.Footer>
                        <button className="btn btn-primary btn-sm" onClick={this.updateSequence}>
                            Update Order
                        </button>
                        <button className="btn btn-danger btn-sm" onClick={this.toggleModal}>
                            Close
                        </button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment >
        )
    }
}

