import React, { Component, Fragment, useState,useEffect } from "react"
import { Link } from "react-router-dom";
import { ApiUrl } from "../../../config";
import Context from './Context';
import swal from "sweetalert";
import $ from "jquery";
import { dcrypt,bcrypt, uInfo, uToken } from "../../../useToken";
const Box1 = (props) => {
    const params = React.useContext(Context);
    const [record, setRecord] =  useState({});

    useEffect(() => {
        getData();
      
    }, []);
    const getData = () => {
        fetch(`${ApiUrl}` + "get/systemForm/resourceInfo", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
              },
            body: JSON.stringify({ id: params.form_data.id, user_id: uInfo().user_id}),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setRecord(response.data.record);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        submitData.append('user_id', uInfo().user_id);
        submitData.append('id', params.form_data.id);

        fetch(`${ApiUrl}` + "update/system/form", {
            method: "POST",
            body: submitData,
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    swal({
                        title: "Action performed successfully",
                        icon: "success",
                    });
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    return (
        <div>
            <div className="mt-1">
                    <div className="d-flex align-items-center gap-2 StepHeading">
                        <div className="StepNumber">{params.form_data.id} <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">{params.form_data.name}</h4>
                    </div>
                    <form method="post" onSubmit={handleSubmit}
                        encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                        
                        <div className="p-3">
                            <div className="page-content-box">
                               
                                <div className="WizredForm">
                                    <div className="form-group">
                                        <label className="row mb-3">This is for your About page on your website. It is an opportunity for you to let your leads and future customers know a little more about you. Before you skip, try answering the following questions. See what you come up with.</label>
                                        <label className="row">1). How would you describe your coaching style?</label>
                                        <label className="row">2). What problems do you feel most equipped to solve for your customers?</label>
                                        <label className="row">3). What are your biggest accomplishments as a professional?</label>
                                        <label className="row">4).  What makes you valuable?</label>
                                        <label className="row">5). What do you want your customers to know about you?</label>
                                        <label className="row mt-3 mb-3">When you're finished, just copy/paste in this section, and voila! You have an About page.</label>
                                        <textarea rows={10} name="question_answer" defaultValue={record.question_answer ?? ''}
                                            className="form-control no-border px-0" type="text" placeholder="Type your answer here..." />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-sm btn-primary mt-1"> Ok &nbsp;<i className="fas fa-check"></i></button>
                    </form>
            </div>
        </div>
    );
};

export default Box1;