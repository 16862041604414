import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Table,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  CardHeader,
  Container,
} from "reactstrap";

import Helmet from "react-helmet";

import { toast, ToastContainer, Flip } from "react-toastify";

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import moment from 'moment';
import { ApiUrl } from "../../config";
import { Dropdown, DropdownButton, FormControl, ListGroup, Modal } from "react-bootstrap";
import { Asia, TimezoneMain, America, Australia, Europe, Indian } from "../../common/data/timezones";
import $ from "jquery";
import { AvField, AvForm } from "availity-reactstrap-validation";
import Loader from "react-loader";
import swal from 'sweetalert';

const MeetingCalander = (props) => {
  const [page_name] = useState("Meet");
  const [info, setInfo] = useState({});
  const [hiddenDays, setHiddenDays] = useState([]);
  const [pickDate, setPickDate] = useState(moment().format('YYYY-MM-DD'));
  const [slots, setSlots] = useState([]);
  const [modal, setModal] = useState(false);
  const [main, setMain] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone ? Intl.DateTimeFormat().resolvedOptions().timeZone.split('/').shift() : 'America');
  const [zone, setZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'America/Los_Angeles');



  const [Timezone, setMainTimeZone] = useState(TimezoneMain);
  const [zones, setZones] = useState(America);

  const calendarRef = React.createRef()

  const [step, setStep] = useState('1');
  const [slot, setSlot] = useState('');

  const [form, setForm] = useState('');
  const [loaded, setLoaded] = useState(true);
  const handleDateClick = (arg) => { // bind with an arrow function
    // alert(arg.dateStr)
  }

  useEffect(() => {
    getData();
  }, [zone]);

  useEffect(() => {
    getData();
  }, [pickDate]);

  useEffect(() => {


    $('[data-date=' + moment().format('YYYY-MM-DD') + ']').addClass('selectedDate');
  }, [])

  const getData = () => {
    fetch(`${ApiUrl}` + "get/calander/link", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ link: window.location.href, date: pickDate, timezone: zone }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setInfo(response.data.record);
          setHiddenDays(response.data.off_days);
          setSlots(response.data.slots);
          setForm(response.data.schedule_form);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {

      const [value, setValue] = useState('');
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled" style={{ maxHeight: "250px", overflow: "auto" }}>
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value),
            )}
          </ul>
        </div>
      );
    },
  );


  const changeMainZone = (zone) => {

    if (zone == 'Asia') {
      setZones(Asia);
      setZone(Asia[0].id)
    } else if (zone == 'America') {
      setZones(America);
      setZone(America[0].id)
    } else if (zone == 'Australia') {
      setZones(Australia);
      setZone(Australia[0].id)
    } else if (zone == 'Europe') {
      setZone(Europe[0].id)
      setZones(Europe);
    } else if (zone == 'Indian') {
      setZone(Indian[0].id)
      setZones(Indian);
    }
    setMain(zone);
  }

  const handleSubmit = async (event, values) => {
    setLoaded(false);
    values.coach_id = info.coach_id;
    values.coach_partner_id = info.coach_partner_id;
    values.group_id = [info.group_id];
    values.calendar_id = info.calendar_id;
    values.event_title = info.event_title;
    values.location = info.location;
    values.client_time_zone = zone;
    values.pick_date = pickDate;
    values.from_time = slot;
    values.duration = info.duration;
    values.schedule_confirmation_email = info.automation_settings.confirmation_email ? 'Y' : 'N';
    values.pre_meeting_reminder = info.automation_settings.reminder ? 'Y' : 'N';


    fetch(`${ApiUrl}` + "submit/metting", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {

        if (data.status === true) {
          // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          swal({
            title: data.message,
            icon: "success",
          });
          setTimeout(
            () => setStep('1'),
            500
          );
          getData();
          setLoaded(true);
        } else {
          setLoaded(true);
          swal({
            title: data.message,
            icon: "warning",
          });
          // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (

    <React.Fragment>
      <Helmet>
        <title>{page_name}</title>
      </Helmet>
      <Container className="cal-custom custom-calander mb-4">

        <ToastContainer />
        <Loader loaded={loaded}></Loader>
        {
          step == '1' ?
            <Row className="calander-meetings">
              <h5>Pick a date and time</h5>
              <p className="mb-0"><b>Scheduled with </b> {info.coach_name}</p>
              <p className="mb-0"><b>Duration</b> {info.duration} minutes</p>
              <p><b>Your time zone</b>  {main} ({zone}) <button onClick={() => setModal(true)} className="btn btn-info">Change Zone</button></p>

              <Col md={6}>
                <FullCalendar
                  plugins={[dayGridPlugin, interactionPlugin]}
                  initialView="dayGridMonth"
                  ref={calendarRef}
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: ""
                  }}
                  selectable={true}
                  selectHelper={true}
                  validRange={{
                    // start: moment().format("YYYY-MM-DD"),
                  }}
                  // initialDate={new Date('2022-06-23')}
                  dateClick={function (info) {
                    // // calendarRef.current.getApi().today();
                    // console.log(moment(info.dateStr).format('d'), 'a');
                    var now = moment(new Date()); //todays date
                    var end = moment(info.dateStr); // another date
                    var duration = moment.duration(now.diff(end));
                    var days = duration.asDays();
                    if (days <= 0) {

                      if (!hiddenDays.includes(moment(info.dateStr).format('d'))) {
                        $('.fc-daygrid-day').removeClass('selectedDate');
                        $('[data-date=' + info.dateStr + ']').addClass('selectedDate');
                        $('[data-date=' + info.dateStr + ']').children().children().addClass('childselectedDate');
                        setPickDate(info.dateStr);
                        return true;
                      } else {
                        return false;
                      }

                    } else if (days > 0 && days < 1) {
                      $('.fc-daygrid-day').removeClass('selectedDate');
                      $('[data-date=' + moment().format('YYYY-MM-DD') + ']').addClass('selectedDate');
                      setPickDate(info.dateStr);
                    } else {

                      info.dayEl.click.disabled = true;
                      return false
                    }
                  }}

                  selectAllow={function (select) {
                    if (hiddenDays.includes(moment(select.start).format('d'))) {
                      return false
                    }
                    else {
                      return true
                    }
                    // return moment(info.startStr).add(1, 'days').format('YYYY-MM-DD') == moment(info.endStr).format('YYYY-MM-DD');
                  }}


                  // hiddenDays={hiddenDays}

                  select={
                    function (info) {

                    }
                  }

                // unselect={function () {
                //   $('#submitButton').unbind();
                // }}
                />
              </Col>
              <Col md={6}>
                <h5>Available times for {moment(pickDate).format('ll')}</h5>
                {
                  slots.length < 1 && (
                    <h6>No available times</h6>
                  )
                }

                <ListGroup horizontal className="slot-list">
                  {
                    slots.map((row, i) => {
                      return <ListGroup.Item onClick={() => { setStep('2'); setSlot(row); }} className="list-group-item" key={i}>{row}</ListGroup.Item>
                    })
                  }
                </ListGroup>


              </Col>

            </Row>
            :
            step == '2' ?

              <Row>
                <Col md={'8'}>
                  <Card className="card-body">
                    <CardTitle>Your Information</CardTitle>
                    <AvForm
                      onValidSubmit={handleSubmit}
                      className="needs-validation"
                    >
                      {
                        form.map((row, r) => {
                          return (
                            <div className="row mb-3" key={r}>

                              <div className="col-md-12">
                                <AvField
                                  name={`${row.key}`}
                                  placeholder={`${row.alternative_text ? row.alternative_text : row.label}`}
                                  className="form-control"
                                  required={row.required}
                                />
                              </div>
                            </div>
                          )
                        })
                      }

                      <button
                        className="btn btn-info w-md waves-effect waves-light mr-5"
                        type="button"
                        onClick={() => setStep('1')}
                      >
                        Back
                      </button>
                      <button
                        className="btn btn-primary w-md waves-effect waves-light"
                        type="submit"
                      >
                        Submit
                      </button>
                    </AvForm>

                  </Card>
                </Col>
              </Row>
              :
              <>
              </>
        }



        <Modal show={modal} backdrop={'static'}>
          <Modal.Header>
            <Modal.Title>Main Timezone</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="d-flex space-between">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-autoclose-true" className="btn btn-primary">
                  {main == '' ? 'Your Timezone' : main}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {
                    Timezone.map((zone, i) => {
                      return <Dropdown.Item onClick={() => changeMainZone(zone)} eventKey={i}>{zone}</Dropdown.Item>
                    })
                  }


                </Dropdown.Menu>
              </Dropdown>

              <Dropdown>
                <Dropdown.Toggle id="dropdown-autoclose-true" className="btn btn-primary">
                  {zone == '' ? 'Your Timezone' : zone}
                </Dropdown.Toggle>

                <Dropdown.Menu as={CustomMenu}>
                  {
                    zones.map((zone, i) => {
                      return <Dropdown.Item onClick={() => { setZone(zone.id) }} eventKey={i}>{zone.id}</Dropdown.Item>
                    })
                  }


                </Dropdown.Menu>
              </Dropdown>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button
              color="info"
              onClick={() => setModal(false)}
            >
              Continue
            </Button>
          </Modal.Footer>
        </Modal>

      </Container>
    </React.Fragment>
  );
};

export default MeetingCalander;
