import React from 'react';
import Folder from './folder.js';

const App = () => {
  const folderStructure = {
    name: 'Folder 1',
    folders: [
      {
        name: ' -- Folder 2',
        folders: [
          {
            name: '--Folder 3'
          }
        ]
      },
      {
        name: 'Folder 4'
      }
    ]
  };
  
  return (
    <Folder folder={folderStructure} />
  );
};

export default App;