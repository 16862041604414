import React, { Component, Fragment, useState,useEffect } from "react"
import { Link } from "react-router-dom";
import { ApiUrl } from "../../../config";
import Context from './Context';
import swal from "sweetalert";
import { uInfo, uToken } from "../../../useToken";
const Box9 = (props) => {
    const params = React.useContext(Context);
    const [record, setRecord] =  useState({});
    useEffect(() => {
        getData();
      
    }, []);
    const getData = () => {
        fetch(`${ApiUrl}` + "get/systemForm/resourceInfo", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
              },
            body: JSON.stringify({ id: params.form_data.id, user_id: uInfo().user_id}),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setRecord(response.data.record);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        if(uInfo().user_id == 2){
            submitData.append('edited_by', 0);
        }else{
            submitData.append('edited_by', 1);
        }
        submitData.append('user_id', uInfo().user_id);
        submitData.append('id', params.form_data.id);

        fetch(`${ApiUrl}` + "update/system/form", {
            method: "POST",
            body: submitData,
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    swal({
                        title: "Action performed successfully",
                        icon: "success",
                    });
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    return (
        <div>
            <div className="mt-1">
                    <div className="d-flex align-items-center gap-2 StepHeading">
                        <div className="StepNumber">{params.form_data.id} <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">{params.form_data.name}</h4>
                    </div>
                    <div className="page-content-box">
                        <form method="post" onSubmit={handleSubmit}
                            encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                            <div className="WizredForm mt-1 p-2">
                                <div className="p-2">
                                    <div className="form-group mt-2">
                                        <label>Linkdein Business (OR Personal)
                                        <br />
                                            <small>Provide the link to your page or skip. </small>
                                        </label>

                                        <input name="linkedin_link" defaultValue={record.linkedin_link ?? ''}
                                            className="form-control no-border px-0"
                                            type="text" placeholder="https://"></input>
                                    </div>

                                    <div className="form-group mt-2">
                                        <label>Facebook Business Page (OR Personal )
                                        <br />
                                            <small>Provide the link to your page or skip. </small>
                                        </label>

                                        <input name="facebook_link" defaultValue={record.facebook_link ?? ''}
                                            className="form-control no-border px-0"
                                            type="text" placeholder="https://"></input>
                                    </div>

                                    <div className="form-group mt-2">
                                        <label>Twitter Page
                                        <br />
                                            <small>Provide the link to your page or skip. </small>
                                        </label>

                                        <input name="twitter_link" defaultValue={record.twitter_link ?? ''}
                                            className="form-control no-border px-0"
                                            type="text" placeholder="https://"></input>
                                    </div>

                                    <div className="form-group mt-2">
                                        <label>Google Business Page
                                        <br />
                                            <small>Provide the link to your page or skip. </small>
                                        </label>

                                        <input name="google_link" defaultValue={record.google_link ?? ''}
                                            className="form-control no-border px-0"
                                            type="text" placeholder="https://"></input>
                                    </div>

                                    
                                    <div className="form-group mt-2">
                                        <label>Instagram Page
                                        <br />
                                            <small>Provide the link to your page or skip. </small>
                                        </label>

                                        <input name="instagram_link" defaultValue={record.instagram_link ?? ''}
                                            className="form-control no-border px-0"
                                            type="text" placeholder="https://"></input>
                                    </div>
                                                                        
                                    <div className="form-group mt-2">
                                        <label>If you have an additional page you want linked, provide it below
                                        <br />
                                            <small>Provide the link to your page or skip. </small>
                                        </label>

                                        <input name="other_link" defaultValue={record.instagram_link ?? ''}
                                            className="form-control no-border px-0"
                                            type="text" placeholder="https://"></input>
                                    </div>

                                </div>

                            </div>
                            <button type="submit" className="btn btn-sm btn-primary m-2"> Ok &nbsp;<i className="fas fa-check"></i></button>
                        </form>
                    </div>
            </div>
        </div>
    );
};

export default Box9;