import React, { useEffect } from "react"
import Helmet from "react-helmet"
import Breadcrumb from "../../components/Common/Breadcrumb"

//Import Action to copy breadcrumb items from local state to redux state

const PagesBlank = (props) => {
    const breadcrumbItems = [
        { title: "Crm", link: "#" },
        { title: "Pages", link: "#" },
        { title: "Blank page", link: "#" },
    ]

    useEffect(() => {
        // props.setBreadcrumbItems('Blank page', breadcrumbItems)
    })

    return (
        <React.Fragment>
            <Helmet>
                <title>Starter Page </title>
            </Helmet>
            <Breadcrumb title={'Blank page'} breadcrumbItems={breadcrumbItems} />
        </React.Fragment>
    )
}

export default PagesBlank;