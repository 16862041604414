import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"


const Logout = props => {
    useEffect(() => {
        console.log(props)
    })

    return < > < />
}

export default Logout