import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Nav, NavItem } from "reactstrap";
export default class CampaignReportingNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: this.props.activeTab,
            campaignId: this.props.campaignId
        };
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <Nav tabs>
                                <NavItem>
                                    <Link
                                        className={`nav-link  ${this.state.tab == 1 ? "active" : ""} `}
                                        to={`/campaign/report/summary/${this.state.campaignId}`}
                                    >
                                        Summary
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link
                                        className={`nav-link  ${this.state.tab == 2 ? "active" : ""} `}
                                        to={`/campaign/report/call/${this.state.campaignId}`}
                                    >
                                        Call Report
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link
                                        className={`nav-link  ${this.state.tab == 9 ? "active" : ""} `}
                                        to={`/campaign/report/voice/${this.state.campaignId}`}

                                    >
                                        Voice Report
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link
                                        className={`nav-link  ${this.state.tab == 3 ? "active" : ""} `}
                                        to={`/campaign/report/rvm/${this.state.campaignId}`}

                                    >
                                        Rvm Logs
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link
                                        className={`nav-link  ${this.state.tab == 4 ? "active" : ""} `}
                                        to={`/campaign/report/sms/${this.state.campaignId}`}
                                    >
                                        Sms Logs
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link
                                        className={`nav-link  ${this.state.tab == 5 ? "active" : ""} `}
                                        to={`/campaign/report/email/${this.state.campaignId}`}

                                    >
                                        Email Report
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link
                                        className={`nav-link  ${this.state.tab == 6 ? "active" : ""} `}
                                        to={`/campaign/report/insights/${this.state.campaignId}`}
                                    >
                                        Insights
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link
                                        className={`nav-link  ${this.state.tab == 7 ? "active" : ""} `}
                                        to={`/campaign/report/manual/${this.state.campaignId}`}
                                    >
                                        Manual Report
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link
                                        className={`nav-link  ${this.state.tab == 8 ? "active" : ""} `}
                                        to={`/campaign/report/activity/${this.state.campaignId}`}

                                    >
                                        Activity Log
                                    </Link>
                                </NavItem>

                            </Nav>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
