import React, { useState, useEffect, useRef } from "react";

const Participant = ({ participant, hostId }) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);

  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      console.log(track, 'trackSubscribed')
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      console.log(track, 'trackUnsubscribed')
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      console.log(participant, 'removeAllListeners')
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      console.log('videoTrack attach')
      videoTrack.attach(videoRef.current);
      return () => {
        console.log('videoTrack detach')
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      console.log('audioTrack attach')
      audioTrack.attach(audioRef.current);
      return () => {
        console.log('audioTrack detach')
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  return (
    <div className={`participant ${participant.identity == 'host-' + hostId ? 'host-video' : 'remote-video'} participant_${participant.sid}`}>

      {
        participant.identity == 'host-' + hostId ?
          <div className="host">
            {/* <h3>Host</h3> */}
            <video ref={videoRef} autoPlay={true} />
            <audio ref={audioRef} autoPlay={true} />
          </div>
          :
          <div className="remote">
            <div className="RemoteUser"><h3>{participant.identity}</h3></div>
            <video ref={videoRef} autoPlay={true} />
            <audio ref={audioRef} autoPlay={true} />
          </div>
      }

    </div>
  );
};

export default Participant;