import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardTitle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import Loader from "react-loader";

import { AvField, AvForm } from "availity-reactstrap-validation";
import { ApiUrl } from "../../config";
import { bcrypt, uToken } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import Tooltip from "react-bootstrap/Tooltip";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import $ from "jquery";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ItemShape from './../../assets/images/shape.png';
import { Link } from "react-router-dom";
require("highcharts/modules/exporting")(Highcharts);

const Summary = (props) => {
  const [modal, setModal] = useState(false);
  const [id, setId] = useState("");
  const [question, setQuestion] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [toggleSwitch, settoggleSwitch] = useState(true)

  useEffect(() => {
    setLoaded(true);
    setQuestion(props.questions);
  }, [props]);

  function NumberWithCommas(x) {
    let y = Math.trunc(x);
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // const twoGreaterThanZero = question => question.filter(x => x.profit_increase > 0);
  // let completedCount = twoGreaterThanZero(question);

  // const TotalActive = question => question.filter(x => x.status = 1 && x.percentage != 0);
  // let TotalActiveCount = TotalActive(question);

  return (
    <React.Fragment>
      {/* <h2 className="mb-3">

        {props.module && props.module.replaceAll("_", " ")}
      </h2> */}
      <Loader loaded={loaded}> </Loader>

      {/* <li key={i} className={row.profit_increase > 0 ? 'd-inline-block' : 'd-none'}> */}


      <ul className={`summary-report h-100px`} >
        {question.map((row, i) => {
          return (
            <Link key={i} to={`/assessment/${bcrypt(row.assessment_id)}/${row.type_key}`}>
              <li key={i} className={row.percentage != 0 && row.status == 1 ? 'd-inline-block' : 'd-none'} style={{ background: '#008037' }}>
                <OverlayTrigger
                  key={i}
                  placement={'auto'}
                  overlay={
                    <Tooltip>
                      <p
                        className={`${row.percentage > 0
                          ? "text-default"
                          : row.percentage < 0
                            ? "text-danger"
                            : "text-default"
                          }`}
                      >
                        Title: {row.cat_name}
                      </p>

                      <p
                        className={`${row.percentage > 0
                          ? "text-default"
                          : row.percentage < 0
                            ? "text-danger"
                            : "text-default"
                          }`}
                      >
                        Amount: {props.currency} {NumberWithCommas(row.rev_impact)}
                      </p>
                      <p
                        className={`${row.input_percentage > 0
                          ? "text-default"
                          : row.percentage < 0
                            ? "text-danger"
                            : "text-default"
                          }`}
                      >
                        Percentage:  {row.input_percentage} %
                      </p>
                    </Tooltip>
                  }
                >


                  <img className={`assessment-icon ${row.type_key}`} src={require('./../../assets/images/assessments-icons/' + row.type_key + '.png')} />
                  {/* <p>{row.type_key && row.type_key.replaceAll("_", " ")}</p> */}
                </OverlayTrigger>
                <div className="BottomShade"><img src={ItemShape}></img></div>
              </li>
            </Link>
          );
        })}
      </ul>

    </React.Fragment>
  );
};

export default Summary;
