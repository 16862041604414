import React, { forwardRef, useImperativeHandle } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const PaymentComponent = forwardRef((props, ref) => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        console.log('child trigger');
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        // Use the CardElement component to get payment details from the user
        const cardElement = elements.getElement(CardElement);

        // Create a card token using the card information
        const { error, token } = await stripe.createToken(cardElement);
        

        if (error) {
            // console.error('Error creating card token:', error);
            return { error: 1, message: error.message };

        } else {
            // console.log('Card Token:', token);
            // Send the token to your server to handle the payment
            // Your server will use the Stripe API to process the payment.
            return { error: 0, token: token.id ,message: 'Success' };
        }
    };

    // Expose the function to the parent using useImperativeHandle
    useImperativeHandle(ref, () => ({
        handlePayment: handleSubmit,
    }));

    return (
        <div className='StripeCardElement'>
            <label>Card details</label>
            <div className="form-group">
                <CardElement
                    className="custom-card-element-wrapper form-control"

                    options={{
                        className: "form-control customCardElement",
                        // Customize the appearance of the CardElement component
                        hidePostalCode: true,
                        style: {
                            base: {
                                fontSize: '16px',
                                border: '5px solid',
                                color: '#424770',
                                '::placeholder': {
                                    color: '#aab7c4',
                                },
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                        },
                    }}
                />
            </div>
        </div>
    );
});

export default PaymentComponent;
