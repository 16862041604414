import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardTitle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import Loader from "react-loader";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ApiUrl } from "../../config";
import { uToken } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import ShakeHand from "./../../assets/images/small/ShakeHand.png";
import Cash from "./../../assets/images/small/cash-icon.png";
import Select from "react-select";
import OperationBreakdown from "./operationBreakdown";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { render } from "react-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from "react-bootstrap/Popover";

require("highcharts/modules/exporting")(Highcharts);
const Roi = (props) => {
  // console.log('props--- ', props)
  const [loaded, setLoaded] = useState(false);
  const [toggleSwitch, settoggleSwitch] = useState(true)
  const [customer_conversion_calculation] = useState(props.investment.customer_conversion_calculation);
  const [loading, setLoading] = useState(false);
  const [group, setGroup] = useState(1);
  const [modal, setModal] = useState(false);
  const [all_reports, setAllReports] = useState([]);
  const [selected_reports, setSelectedReports] = useState([]);
  const [modal2, setModal2] = useState(false);
  const [marginBreakDownType, setMarginBreakDownType] = useState(0);

  useEffect(() => {
    // console.log(props.slug)
    setLoaded(true);
    fetchAllActiveReport();

  }, []);
  function NumberWithCommas(x) {
    let y = Math.trunc(x);
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const handleSubmit = async (event) => {

    event.preventDefault();
    setLoaded(false);
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    submitData.append('assessment_id', props.assessment);
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }

    fetch(`${ApiUrl}` + "submit/assessment/roi", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          props.getAssessment();
          // toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        setLoaded(true);
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        setLoaded(true);
      });
  }

  function isWhatPercentOfBetWeen(numA, numB) {
    if (numB == 0) {
      return 0;
    }

    let a = (numA / numB) * 100;
    if (a >= 100) {
      return 100;
    }
    return (numA / numB) * 100;
  }


  function isWhatPercentOf(numA, numB) {
    // numB = Number(props.AnnualNetProfit) + Number(props.AnnualProfit) + Number(props.AnnualGrossRevenue);
    // numB = 166250;

    if (numB == 0) {
      return 0;
    }

    let a = (numA / numB) * 100;
    if (a >= 100) {
      return 100;
    }
    return a;
  }

  useEffect(() => {
    fetchAllActiveReport();
    setSelectedReports([]);
  }, [group])


  const fetchAllActiveReport = async () => {
    await fetch(`${ApiUrl}` + "fetch/assessment/report/summary", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        id: props.assessment,
        group_by: group
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        console.log(response.data.selected_reports)
        if (response.status === true) {
          setAllReports(response.data.all_reports);
          setSelectedReports(response.data.selected_reports);
          setLoading(true);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          setLoading(true);
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        setLoading(true);
      });
  };


  const handleReportSummarySubmit = async (event) => {
    event.preventDefault();
    setLoaded(false);
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    submitData.append('assessment_id', props.assessment);
    submitData.append('group_by', group);

    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }

    fetch(`${ApiUrl}` + "submit/report/summary", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setModal(false);
          fetchAllActiveReport();
          // toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        setLoaded(true);
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        setLoaded(true);
      });
  }


  const revenue = () => {
    let revenueShare = Number(props.ExpectedIncrease) * (Number(props.investment.revenue_share)) / 100;
    let coachingFee = Number(props.investment.initial_investment) + (Number(props.investment.monthly_investment) * 12);
    let totalInvestment = Number(revenueShare) + Number(coachingFee);
    let revenueRoi = Number(props.AnnualGrossRevenue) - Number(totalInvestment);
    let revenueRoiPercentage = totalInvestment > 0 ? ((revenueRoi - props.currentRevenue) / totalInvestment) * 100 : 0;
    let percentageRoi = totalInvestment > 0 ? isWhatPercentOfBetWeen(revenueRoi, totalInvestment + revenueRoi) : 0;
    let percentageInvestment = totalInvestment > 0 ? isWhatPercentOfBetWeen(totalInvestment, totalInvestment + revenueRoi) : 0;
    let coachingROI = Number(props.ExpectedIncrease) - Number(totalInvestment);
    let coachingROIRoiPercentage = totalInvestment > 0 ? (coachingROI / totalInvestment) * 100 : 0;

    return <OverlayTrigger
      container={this}
      trigger={['hover', 'focus']}
      placement={'left'}
      overlay={
        <Popover id="popover-basic" className="roi-popover">
          <Popover.Body>
            <ul className="fa-ul">
              <li className="py-2"><span className="fa-li"></span>Current Revenue: {props.currency}{NumberWithCommas(props.currentRevenue)}</li>
              <li className="border-bottom py-2"><span className="fa-li"></span>Revenue Increase: {props.currency}{NumberWithCommas(props.ExpectedIncrease)}</li>
              <li className="py-2"><span className="fa-li"></span><b>New Revenue: {props.currency}{NumberWithCommas(props.AnnualGrossRevenue)}</b></li>
              <li className="py-2"><span className="fa-li"></span>Initial investment: {props.currency}{NumberWithCommas(props.investment.initial_investment)}</li>
              <li className="py-2"><span className="fa-li"></span>Monthly Retainer: {props.currency}{NumberWithCommas(props.investment.monthly_investment)} (x12)</li>
              <li className="border-bottom py-2"><span className="fa-li"></span>Revenue Share: {props.currency}{NumberWithCommas(revenueShare)} ({props.investment.revenue_share}%)</li>
              <li className="py-2"><span className="fa-li"></span><b>Total Investment: {props.currency}{NumberWithCommas(totalInvestment)}</b></li>
              <li className="py-2"><span className="fa-li"></span><b>ROI: {props.currency}{NumberWithCommas(revenueRoi)}</b></li>
              <li className="py-2"><span className="fa-li"></span><b>Coaching Investment: {props.currency}{NumberWithCommas(totalInvestment)}</b></li>
              <li className="py-2"><span className="fa-li"></span><b>Coaching ROI: {props.currency}{NumberWithCommas(coachingROI)} ({revenueRoiPercentage > 0 ? Math.round(revenueRoiPercentage) : 0}%)</b></li>

            </ul>
          </Popover.Body>
        </Popover>
      }

    >
      <div className="d-chart">
        <div className="donut" style={{ background: `conic-gradient(#008037 ${percentageRoi}%, #041934 ${percentageInvestment}%)` }}>
          <div className="hole">
            <p>{revenueRoiPercentage > 0 ? Math.round(revenueRoiPercentage) : 0}%</p>
            {
              revenueRoiPercentage > 0 && (
                <div className="hole-arrow"><img src={require('./../../assets/images/up-arrow.png')}></img></div>
              )
            }
          </div>
        </div>
      </div>
    </OverlayTrigger>
  }

  const gross = () => {
    let revenueSharePrev = Number(props.ExpectedIncrease) * (Number(props.investment.revenue_share)) / 100;
    let revenueShare = (revenueSharePrev * props.grossPercentage) / 100;
    let coachingFee = Number(props.investment.initial_investment) + (Number(props.investment.monthly_investment) * 12);
    let totalInvestment = Number(revenueShare) + Number(coachingFee);
    let revenueRoi = Number(props.AnnualProfit) - Number(totalInvestment);
    let revenueRoiPercentage = totalInvestment > 0 ? ((revenueRoi - props.currentProfit) / totalInvestment) * 100 : 0;
    let percentageRoi = totalInvestment > 0 ? isWhatPercentOfBetWeen(revenueRoi, totalInvestment + revenueRoi) : 0;
    let percentageInvestment = totalInvestment > 0 ? isWhatPercentOfBetWeen(totalInvestment, totalInvestment + revenueRoi) : 0;
    let coachingROI = Number(props.ExpectedIncreaseProfit) - Number(totalInvestment);
    let coachingROIRoiPercentage = totalInvestment > 0 ? (coachingROI / totalInvestment) * 100 : 0;

    return <OverlayTrigger
      container={this}
      trigger={['hover', 'focus']}
      placement={'left'}
      overlay={
        <Popover id="popover-basic" className="roi-popover">
          <Popover.Body>
            <ul className="fa-ul"> &#129121;
              <li className="py-2"><span className="fa-li"></span>Current Gross Profit: {props.currency}{NumberWithCommas(props.currentProfit)}</li>
              <li className="border-bottom py-2"><span className="fa-li"></span>Increase: {props.currency}{NumberWithCommas(props.ExpectedIncreaseProfit)}</li>
              <li className="py-2"><span className="fa-li"></span><b>New Annual: {props.currency}{NumberWithCommas(props.AnnualProfit)}</b></li>
              <li className="py-2"><span className="fa-li"></span>Initial investment: {props.currency}{NumberWithCommas(props.investment.initial_investment)}</li>
              <li className="py-2"><span className="fa-li"></span>Monthly Retainer: {props.currency}{NumberWithCommas(props.investment.monthly_investment)} (x12)</li>
              <li className="border-bottom py-2"><span className="fa-li"></span>Revenue Share: {props.currency}{NumberWithCommas(revenueShare)} ({props.investment.revenue_share}%)</li>
              <li className="py-2"><span className="fa-li"></span><b>Total Investment: {props.currency}{NumberWithCommas(totalInvestment)}</b></li>
              <li className="py-2"><span className="fa-li"></span><b>ROI: {props.currency}{NumberWithCommas(revenueRoi)}</b></li>
              <li className="py-2"><span className="fa-li"></span><b>Coaching Investment: {props.currency}{NumberWithCommas(totalInvestment)}</b></li>
              <li className="py-2"><span className="fa-li"></span><b>Coaching ROI: {props.currency}{NumberWithCommas(coachingROI)} ({coachingROIRoiPercentage > 0 ? Math.round(coachingROIRoiPercentage) : 0}%)</b></li>

            </ul>
          </Popover.Body>
        </Popover>
      }

    >
      <div className="d-chart">
        <div className="donut" style={{ background: `conic-gradient(#008037 ${percentageRoi}%, #041934 ${percentageInvestment}%)` }}>
          <div className="hole">
            <p>{revenueRoiPercentage > 0 ? Math.round(revenueRoiPercentage) : 0}%</p>
            {
              revenueRoiPercentage > 0 && (
                <div className="hole-arrow"><img src={require('./../../assets/images/up-arrow.png')}></img></div>
              )
            }
          </div>
        </div>
      </div>
    </OverlayTrigger>
  }


  const net = () => {
    let revenueSharePrev = Number(props.ExpectedIncrease) * (Number(props.investment.revenue_share)) / 100;
    let revenueShare = (revenueSharePrev * props.netPercentage) / 100;
    let coachingFee = Number(props.investment.initial_investment) + (Number(props.investment.monthly_investment) * 12);
    let totalInvestment = Number(revenueShare) + Number(coachingFee);
    let revenueRoi = Number(props.AnnualNetProfit) - Number(totalInvestment);
    let revenueRoiPercentage = totalInvestment > 0 ? ((revenueRoi - props.currentNetProfit) / totalInvestment) * 100 : 0;
    let percentageRoi = totalInvestment > 0 ? isWhatPercentOfBetWeen(revenueRoi, totalInvestment + revenueRoi) : 0;
    let percentageInvestment = totalInvestment > 0 ? isWhatPercentOfBetWeen(totalInvestment, totalInvestment + revenueRoi) : 0;
    let coachingROI = Number(props.ExpectedIncreaseProfit) - Number(totalInvestment);
    let coachingROIRoiPercentage = totalInvestment > 0 ? (coachingROI / totalInvestment) * 100 : 0;
    return <OverlayTrigger
      container={this}
      trigger={['hover', 'focus']}
      placement={'left'}
      overlay={
        <Popover id="popover-basic" className="roi-popover">
          <Popover.Body>
            <ul className="fa-ul">
              <li className="py-2"><span className="fa-li"></span>Current Net Profit: {props.currency}{NumberWithCommas(props.currentNetProfit)}</li>
              <li className="border-bottom py-2"><span className="fa-li"></span>Increase: {props.currency}{NumberWithCommas(props.ExpectedIncreaseNetProfit)}</li>
              <li className="py-2"><span className="fa-li"></span><b>New Annual: {props.currency}{NumberWithCommas(props.AnnualNetProfit)}</b></li>
              <li className="py-2"><span className="fa-li"></span>Initial investment: {props.currency}{NumberWithCommas(props.investment.initial_investment)}</li>
              <li className="py-2"><span className="fa-li"></span>Monthly Retainer: {props.currency}{NumberWithCommas(props.investment.monthly_investment)} (x12)</li>
              <li className="border-bottom py-2"><span className="fa-li"></span>Revenue Share: {props.currency}{NumberWithCommas(revenueShare)} ({props.investment.revenue_share}%)</li>
              <li className="py-2"><span className="fa-li"></span><b>Total Investment: {props.currency}{NumberWithCommas(totalInvestment)}</b></li>
              <li className="py-2"><span className="fa-li"></span><b>ROI: {props.currency}{NumberWithCommas(revenueRoi)}</b></li>
              <li className="py-2"><span className="fa-li"></span><b>Coaching Investment: {props.currency}{NumberWithCommas(totalInvestment)}</b></li>
              <li className="py-2"><span className="fa-li"></span><b>Coaching ROI: {props.currency}{NumberWithCommas(coachingROI)} ({coachingROIRoiPercentage > 0 ? Math.round(coachingROIRoiPercentage) : 0}%)</b></li>
            </ul>
          </Popover.Body>
        </Popover>
      }

    >
      <div className="d-chart">
        {/* <!-- (A) DONUT CHART --> */}
        <div className="donut" style={{ background: `conic-gradient(#008037 ${percentageRoi}%, #041934 ${percentageInvestment}%)` }}>
          <div className="hole">
            <p>{revenueRoiPercentage > 0 ? Math.round(revenueRoiPercentage) : 0}%</p>
            {
              revenueRoiPercentage > 0 && (
                <div className="hole-arrow"><img src={require('./../../assets/images/up-arrow.png')}></img></div>
              )
            }
          </div>
        </div>

      </div>
    </OverlayTrigger>
  }

  // console.log(customer_conversion_calculation, 'customer_conversion_calculation')

  return (
    <React.Fragment>
      <div style={{ background: 'transparent' }}>

        <Loader loaded={loaded}></Loader>
        <Row>

          <Col md={6}>

            <section className="breakthrough sub-nav-bg mb-0">
              <h5 className="mb-0">{group == '1' ? 'Operations' : group == 2 ? 'Sales' : group == 3 ? 'Marketing' : ''} Breakdown </h5>
              <div style={{ position: 'absolute', right: 0 }}>
                <Dropdown onSelect={(e) => { setGroup(e); }}>
                  <Dropdown.Toggle type="button" variant="link" ><i className="fas fa-bars bars-icon"></i></Dropdown.Toggle>
                  <Dropdown.Menu align="start">
                    <Dropdown.Item eventKey="1">Operations Breakdown <i onClick={() => { setModal(true); setGroup("1") }} className={`fa fa-edit ${!props.canEdit && ('d-none')}`}></i></Dropdown.Item>
                    <Dropdown.Item eventKey="2">Sales Breakdown <i onClick={() => { setModal(true); setGroup("2") }} className={`fa fa-edit ${!props.canEdit && ('d-none')}`}></i></Dropdown.Item>
                    <Dropdown.Item eventKey="3">Marketing Breakdown <i onClick={() => { setModal(true); setGroup("3") }} className={`fa fa-edit ${!props.canEdit && ('d-none')}`}></i></Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>


            </section>

            <div className="BreakDownShadow card card-body Breakdown">
              <div className="CalculatorArea">
                <div className="ProfileImpact">
                  <div className="ProfitImpactChart">
                    <h3>Total Impact</h3>
                    <h3>{selected_reports.length > 0 ? props.currency + NumberWithCommas(selected_reports[0].profit_impac_net) : 0}</h3>
                  </div>
                  <div className="half-circle"></div>
                  {
                    selected_reports.length > 0 && (
                      <>
                        {
                          selected_reports.length > 0 && (
                            <>
                              {
                                selected_reports.map((op, i) => {
                                  return (
                                    <div key={i} className={`Impacts _${i}`}>
                                      <div className="PointIcon"></div>
                                      <div className={`OperationsImpact _${i}`}>
                                        <div className="PercentCounter">
                                          <div className="PercentNumber">
                                            {op.input_amount}%
                                          </div>
                                        </div>
                                        <div className="ShowTotalRev">
                                          <div className="PackegeName">
                                            <i className="fa fa-arrow-circle-up"></i> <h6>{op.cat_name}</h6>
                                          </div>
                                          <h3>{props.currency}{NumberWithCommas(op.net_amount)}</h3>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </>
                          )}
                      </>
                    )}
                </div>
              </div>
            </div>
            {/* {selected_reports.length > 0 && (
              <OperationBreakdown key={0} items={selected_reports} />
            )} */}




          </Col>
          <Col md={6}>
            <section className="breakthrough sub-nav-bg mb-0 text-center margin-breakdown-dropdown">
              <h5 className="mb-0">Margin Breakdown</h5>
              <div style={{ position: 'absolute', right: 0 }}>
                <Dropdown onSelect={(e) => { setMarginBreakDownType(e); }}>
                  <Dropdown.Toggle type="button" variant="link" ><i className="fas fa-bars bars-icon"></i></Dropdown.Toggle>
                  <Dropdown.Menu align="start">
                    <Dropdown.Item className={marginBreakDownType == 0 && `active`} eventKey="0">Revenue Impact</Dropdown.Item>
                    <Dropdown.Item className={marginBreakDownType == 1 && `active`} eventKey="1">Gross Profit Impact </Dropdown.Item>
                    <Dropdown.Item className={marginBreakDownType == 2 && `active`} eventKey="2">Net Profit Impact </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </section>
            <div className="BreakDownShadow card card-body Breakdown">

              {
                marginBreakDownType == 0 ?

                  <div className="ROiPercents w-100 position-relative BarChartBreakDown">
                    <div className="TotalImapoctValue">
                      <div className="TotalPercent">
                        <span className="ImpactLabel">Revenue Impact</span>
                        <h4 className="text-start m-0 w-0">
                          <span className="percentageSign pull-right"> <i className="fa fa-arrow-circle-up"></i><span className="PercentValue"> {props.currentRevenuePercentage}% </span> </span>
                        </h4>
                      </div>
                      <div className="CalculationResult BarChartBox">
                        <div className="Current mb-0">
                          <div className="ShowCalc"></div><p>Current Financials</p>
                        </div>
                        <div className="Current Projected">
                          <div className="ShowCalc"></div><p>Projected Impacts</p>
                        </div>
                      </div>

                    </div>
                    <ul className="BarChart">
                      <li>
                        <div className="BarChartBars mt-5">
                          <div className="GreenBar">
                            <div className="bar DarkBlue" style={{ height: `${(isWhatPercentOf(props.currentRevenue, Number(props.currentRevenue) + Number(props.AnnualGrossRevenue))) * 2}px` }}>
                              <span style={{ color: `${(isWhatPercentOf(props.currentRevenue, Number(props.currentRevenue) + Number(props.AnnualGrossRevenue))) * 2 < 25 && ('#90ee90')}` }} className="BottomValue">{props.currency}{NumberWithCommas(props.currentRevenue)}</span>
                            </div>
                            <p className="text-footer">Current Revenue</p>
                          </div>
                          <div className="GreenBar">
                            <div className="bar DarkGreen" style={{ height: `${(isWhatPercentOf(props.AnnualGrossRevenue, Number(props.currentRevenue) + Number(props.AnnualGrossRevenue))) * 2}px` }}>

                              <span style={{ color: `${(isWhatPercentOf(props.AnnualGrossRevenue, Number(props.currentRevenue) + Number(props.AnnualGrossRevenue))) * 2 < 25 && ('#90ee90')}` }} className="BottomValue">{props.currency}{NumberWithCommas(props.AnnualGrossRevenue)}</span>
                            </div>
                            <p className="text-footer">New Annual Revenue</p>
                          </div>
                          <div className="border-1 border-bottom w-100 ChartBottomBorder"></div>
                        </div>
                        <div className="revenue"><span className="fs-2">{props.currency}{NumberWithCommas(props.ExpectedIncrease)}</span> <br></br> <p>Total 1 yr Projected Revenue Increase </p></div>

                      </li>
                    </ul>
                  </div>
                  : marginBreakDownType == 1 ?

                    <div className="ROiPercents w-100 position-relative BarChartBreakDown">
                      <div className="TotalImapoctValue">
                        <div className="TotalPercent">
                          <span className="ImpactLabel">Gross Profit Impact</span>
                          <h4 className="text-start m-0 w-0">
                            <span className="percentageSign pull-right"> <i className="fa fa-arrow-circle-up"></i> <span className="PercentValue">{props.currentProfitPercentage}%</span>
                            </span>
                          </h4>
                        </div>
                        <div className="CalculationResult BarChartBox">
                          <div className="Current mb-0">
                            <div className="ShowCalc"></div><p>Current Financials</p>
                          </div>
                          <div className="Current Projected">
                            <div className="ShowCalc"></div><p>Projected Impacts</p>
                          </div>
                        </div>

                      </div>
                      <ul className="BarChart">
                        <li>
                          <div className="BarChartBars mt-5">
                            <div className="GreenBar">
                              <div className="bar DarkBlue" style={{ height: `${(isWhatPercentOf(props.currentProfit, Number(props.currentProfit) + Number(props.AnnualProfit))) * 2}px` }}>
                                <span style={{ color: `${(isWhatPercentOf(props.currentProfit, Number(props.currentProfit) + Number(props.AnnualProfit))) * 2 < 25 && ('#90ee90')}` }} className="BottomValue"> {props.currency}{NumberWithCommas(props.currentProfit)}</span>
                              </div>
                              <p className="text-footer">Current Gross Profit</p>
                            </div>
                            <div className="GreenBar">
                              <div className="bar DarkGreen" style={{ height: `${(isWhatPercentOf(props.AnnualProfit, Number(props.currentProfit) + Number(props.AnnualProfit))) * 2}px` }}>

                                <span style={{ color: `${(isWhatPercentOf(props.AnnualProfit, Number(props.currentProfit) + Number(props.AnnualProfit))) * 2 < 25 && ('#90ee90')}` }} className="BottomValue">{props.currency}{NumberWithCommas(props.AnnualProfit)}</span>
                              </div>
                              <p className="text-footer">New Annual Gross Profit</p>
                            </div>
                            <div className="border-1 border-bottom w-100 ChartBottomBorder"></div>
                          </div>
                          <div className="revenue"><span className="fs-2">{props.currency}{NumberWithCommas(props.ExpectedIncreaseProfit)}</span> <br></br> <p>Total 1 yr Projected  Gross Profit Increase </p></div>

                        </li>
                      </ul>
                    </div>

                    : marginBreakDownType == 2 ?

                      <div className="ROiPercents w-100 position-relative BarChartBreakDown">
                        <div className="TotalImapoctValue">
                          <div className="TotalPercent">
                            <span className="ImpactLabel">Net Profit Impact</span>
                            <h4 className="text-start m-0 w-0">
                              <span className="percentageSign pull-right"> <i className="fa fa-arrow-circle-up"></i> <span className="PercentValue"> {props.currentNetProfitPercentage}%</span>
                              </span>
                            </h4>
                          </div>
                          <div className="CalculationResult BarChartBox">
                            <div className="Current mb-0">
                              <div className="ShowCalc"></div><p>Current Financials</p>
                            </div>
                            <div className="Current Projected">
                              <div className="ShowCalc"></div><p>Projected Impacts</p>
                            </div>
                          </div>

                        </div>
                        <ul className="BarChart">
                          <li>
                            <div className="BarChartBars mt-5">
                              <div className="GreenBar">
                                <div className="bar DarkBlue" style={{ height: `${(isWhatPercentOf(props.currentNetProfit, Number(props.currentNetProfit) + Number(props.AnnualNetProfit))) * 2}px` }}>
                                  <span style={{ color: `${(isWhatPercentOf(props.currentNetProfit, Number(props.currentNetProfit) + Number(props.AnnualNetProfit))) * 2 < 25 && ('#90ee90')}` }} className="BottomValue"> {props.currency}{NumberWithCommas(props.currentNetProfit)}</span>
                                </div>
                                <p className="text-footer">Current Net Profit</p>
                              </div>
                              <div className="GreenBar">
                                <div className="bar DarkGreen" style={{ height: `${(isWhatPercentOf(props.AnnualNetProfit, Number(props.currentNetProfit) + Number(props.AnnualNetProfit))) * 2}px` }}>

                                  <span style={{ color: `${(isWhatPercentOf(props.AnnualNetProfit, Number(props.currentNetProfit) + Number(props.AnnualNetProfit))) * 2 < 25 && ('#90ee90')}` }} className="BottomValue">{props.currency}{NumberWithCommas(props.AnnualNetProfit)}</span>
                                </div>
                                <p className="text-footer">New Annual Net Profit</p>
                              </div>
                              <div className="border-1 border-bottom w-100 ChartBottomBorder"></div>
                            </div>
                            <div className="revenue">
                              <span className="fs-2">{props.currency}{NumberWithCommas(props.ExpectedIncreaseNetProfit)}</span>
                              <br></br> <p>Total 1 yr Projected Net Profit Increase </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                      :
                      <>
                      </>
              }

            </div>
          </Col>
        </Row>

        <Modal
          isOpen={modal}
          fade={true}
          size="lg"
          className="breakdown-content-question"
          backdrop={'static'}
          centered={true}
        >
          <div className="card card-body">
            <div className="">
              <button className="mb-3 btn btn-primary">{group == '1' ? 'Operations' : group == 2 ? 'Sales' : group == 3 ? 'Marketing' : ''} Breakdown</button>
              <form onSubmit={handleReportSummarySubmit} method="post"
                encType="multipart/form-data" className="form-horizontal" id="selection-myform">

                <label>All Summary Report(s)</label>
                <div className="MainTZone EventReminder One">
                  <Select
                    getOptionLabel={option => option.cat_name}
                    getOptionValue={option => option.report_id}
                    isMulti={true}
                    value={selected_reports}
                    options={all_reports}
                    classNamePrefix="select2-selection"
                    name="report_id[]"
                    onChange={(selectedGroup) => setSelectedReports(selectedGroup)}
                    isOptionDisabled={() => selected_reports.length >= 3}
                  />
                </div>
                <p className="text-danger">You can select max 3 module category</p>
                <ModalFooter>
                  <Button color="info" type="submit">
                    Update
                  </Button>
                  <Button color="danger" onClick={() => { setModal(false); fetchAllActiveReport(); }}>
                    Cancel
                  </Button>
                </ModalFooter>
              </form>
            </div>
          </div>
        </Modal>

        {
          props.investment && (
            <>
              <Row>
                <Col md={6}>
                  <section className="breakthrough sub-nav-bg mb-0 text-center">
                    <h5 className="mb-0">Customer Conversion Calculation</h5>
                  </section>
                  <div className="BreakDownShadow card card-body Breakdown">
                    <Row>
                      <Col lg={6}>
                        <div className="SalesCounter">
                          <div className="CountBox">
                            <div className="Counters">
                              <div className="oIcons">
                                <i className="fas fa-user"></i>
                              </div>
                              <div className="Count-LastYear">
                                <span className="CountNumber">{NumberWithCommas(customer_conversion_calculation.sales_last_year)}</span>
                                <span className="CountDesc">Sales Last Year</span>
                              </div>
                            </div>
                            <div className="Counters">
                              <div className="oIcons Dollar">
                                <span className="currencyIcon"><img src={Cash}></img></span>
                                {/* <span className="currencyIcon">{props.currency}</span> */}
                              </div>
                              <div className="Count-LastYear">
                                <span className="CountNumber">{props.currency}{NumberWithCommas(customer_conversion_calculation.avg_ticket_price)}</span>
                                <span className="CountDesc">Avg. Ticket Price</span>
                              </div>
                            </div>
                            <div className="Counters">
                              <div className="oIcons Percent">
                                <i className="fas fa-percent"></i>
                              </div>
                              <div className="Count-LastYear">
                                <span className="CountNumber">{NumberWithCommas(customer_conversion_calculation.conversation_rate)}%</span>
                                <span className="CountDesc">Conversion Rate</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} className="LeadsCounter">
                        <div className="form-check form-switch mb-3 toogle-sales" >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitch2"
                            onClick={e => {
                              settoggleSwitch(!toggleSwitch)
                            }}
                          />
                        </div>
                        <div className="LeadsSales pt-5">
                          {
                            toggleSwitch ?
                              <div className="CountBox">
                                <div className="Counters">
                                  <div className="oIcons">
                                    <i className="fas fa-user"></i>
                                    <span className="PlusUser"><i className="fas fa-plus"></i></span>
                                  </div>
                                  <div className="Count-LastYear">
                                    <span className="CountNumber">{NumberWithCommas(customer_conversion_calculation.toggle_1.new_leads)}</span>
                                    <span className="CountDesc">New Leads</span>
                                  </div>
                                </div>
                                <div className="Counters">
                                  <div className="oIcons Dollar">
                                    <i className="fas fa-plus"></i>
                                  </div>
                                  <div className="Count-LastYear">
                                    <span className="CountNumber">{NumberWithCommas(customer_conversion_calculation.toggle_1.leads_per_month)}</span>
                                    <span className="CountDesc">Leads / Month</span>
                                  </div>
                                </div>
                                <div className="Counters">
                                  <div className="oIcons">
                                    <img src={ShakeHand}></img>
                                  </div>
                                  <div className="Count-LastYear">
                                    <span className="CountNumber">{NumberWithCommas(customer_conversion_calculation.toggle_1.sales_12_month)}</span>
                                    <span className="CountDesc">Sales</span>
                                  </div>
                                </div>
                                <div className="Counters">
                                  <div className="oIcons Dollar">
                                    {/* <i className="fas fa-dollar-sign"></i> */}
                                    <img src={Cash}></img>
                                  </div>
                                  <div className="Count-LastYear">
                                    <span className="CountNumber">{NumberWithCommas(customer_conversion_calculation.toggle_1.sales_per_month)}</span>
                                    <span className="CountDesc">Sales / Month</span>
                                  </div>
                                </div>
                              </div>
                              :
                              <div className="CountBox">
                                <div className="Counters">
                                  <div className="oIcons">
                                    <i className="fas fa-user"></i>
                                    <span className="PlusUser"><i className="fas fa-plus"></i></span>
                                  </div>
                                  <div className="Count-LastYear">
                                    <span className="CountNumber">{NumberWithCommas(customer_conversion_calculation.toggle_2.new_leads)}</span>
                                    <span className="CountDesc">New Leads</span>
                                  </div>
                                </div>
                                <div className="Counters">
                                  <div className="oIcons Dollar">
                                    <i className="fas fa-plus"></i>
                                  </div>
                                  <div className="Count-LastYear">
                                    <span className="CountNumber">{NumberWithCommas(customer_conversion_calculation.toggle_2.leads_per_month)}</span>
                                    <span className="CountDesc">Leads / Month</span>
                                  </div>
                                </div>
                                <div className="Counters">
                                  <div className="oIcons">
                                    <img src={ShakeHand}></img>
                                  </div>
                                  <div className="Count-LastYear">
                                    <span className="CountNumber">{NumberWithCommas(customer_conversion_calculation.toggle_2.sales_12_month)}</span>
                                    <span className="CountDesc">Sales</span>
                                  </div>
                                </div>
                                <div className="Counters">
                                  <div className="oIcons Dollar">
                                    {/* <i className="fas fa-dollar-sign"></i> */}
                                    <img src={Cash}></img>
                                  </div>
                                  <div className="Count-LastYear">
                                    <span className="CountNumber">{NumberWithCommas(customer_conversion_calculation.toggle_2.sales_per_month)}</span>
                                    <span className="CountDesc">Sales / Month</span>
                                  </div>
                                </div>
                              </div>
                          }
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={6}>
                  <section className="breakthrough sub-nav-bg mb-0 text-center">
                    <h5 className="mb-0">Coaching ROI Calculation</h5>
                    <button disabled={!props.canEdit ? true : false} className="btn btn-link bars-icon" type="button" onClick={() => setModal2(true)}><i className="fas fa-bars bars-icon"></i></button>
                  </section>
                  <div className="BreakDownShadow card card-body Breakdown">


                    <Row>
                      <Col lg={4} >
                        <div className="dount-heading text-center">
                          <h5>Revenue</h5>
                        </div>
                        {revenue()}
                        <div className="legend">

                          <div className="segment2"></div>
                          <div className="legend-roi total-inst">Total Investment</div>

                          <div className="segment1"></div>
                          <div className="legend-roi">ROI</div>

                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="dount-heading text-center">
                          <h5>Gross Profit</h5>
                        </div>
                        {gross()}
                        <div className="legend">

                          <div className="segment2"></div>
                          <div className="legend-roi total-inst">Total Investment</div>

                          <div className="segment1"></div>
                          <div className="legend-roi">ROI</div>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="dount-heading text-center">
                          <h5>Net Profit</h5>
                        </div>
                        {net()}
                        <div className="legend">
                          <div className="segment2"></div>
                          <div className="legend-roi total-inst">Total Investment</div>

                          <div className="segment1"></div>
                          <div className="legend-roi">ROI</div>

                        </div>
                      </Col>

                    </Row>
                    {/* <Row>
                      <Col lg={10} className="space-between d-flex offset-lg-1 mt-4">
                        <h5 className="m-0">1 Yr Projected Revenue</h5>
                        <h4 className="m-0">{props.currency}{NumberWithCommas(props.AnnualGrossRevenue)}</h4>

                        <h4 className="m-0">{props.investment.annual_investment > 0 ? Math.round(((props.AnnualGrossRevenue - props.currentRevenue) / props.investment.annual_investment) * 100) : 0}%</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={10} className="space-between d-flex offset-lg-1 mt-1">
                        <h5 className="m-0">1 Yr Projected Gross Profit</h5>
                        <h4 className="m-0">{props.currency}{NumberWithCommas(props.AnnualProfit)}</h4>
                        <h4 className="m-0">{props.investment.annual_investment > 0 ? Math.round(((props.AnnualProfit - props.currentProfit) / props.investment.annual_investment) * 100) : 0}%</h4>

                      </Col>
                    </Row>
                    <Row>
                      <Col lg={10} className="space-between d-flex offset-lg-1 mt-1">
                        <h5 className="m-0">1 Yr Projected Net Profit</h5>
                        <h4 className="m-0">{props.currency}{NumberWithCommas(props.AnnualNetProfit)}</h4>
                        <h4 className="m-0">{props.investment.annual_investment > 0 ? Math.round(((props.AnnualNetProfit - props.currentNetProfit) / props.investment.annual_investment) * 100) : 0}%</h4>

                      </Col>
                    </Row>
                    <Row>
                      <Col lg={10} className="space-between d-flex offset-lg-1 mt-1 BGYellow">
                        <h5 className="m-0">Coaching Fee</h5>
                        <h4 className="m-0">{props.currency}{NumberWithCommas(props.investment.annual_investment)}</h4>
                      </Col>
                    </Row> */}
                    {/* <Row>
                      <Col lg={10} className="space-between d-flex offset-lg-1 mt-1">
                        <h4 className="m-0">&nbsp;</h4>
                        <h3 className="m-0">+0% ROI</h3>
                      </Col>
                    </Row> */}
                  </div>
                </Col>
              </Row>

            </>
          )
        }

        <Modal
          isOpen={modal2}
          fade={true}
          size="lg"
          className="breakdown-content-question"
          centered={true}
          toggle={() => setModal2(false)}
        >
          <div className="card card-body">
            <div className="">
              <form onSubmit={handleSubmit} method="post"
                encType="multipart/form-data" className="form-horizontal" id="selection-myform-roi">
                <div className="row mt-0 mb-3">
                  <Col lg={5} className="offset-lg-1">
                    <Row>
                      <label htmlFor="initial_investment" className="col-sm-7 col-form-label" style={{ textAlign: 'right' }}>Initial Investment:</label>
                      <div className="col-sm-5">
                        <input defaultValue={props.investment.initial_investment} type="text" className="form-control" name="initial_investment" />
                      </div>
                    </Row>
                  </Col>
                  <Col lg={5}>
                    <Row>
                      <label htmlFor="revenue_share" className="col-sm-6 col-form-label" style={{ textAlign: 'right' }}>Revenue Share %:</label>
                      <div className="col-sm-6">
                        <input defaultValue={props.investment.revenue_share} type="text" className="form-control" name="revenue_share" />
                      </div>
                    </Row>
                  </Col>
                </div>
                <div className="row mt-3 mb-3">
                  <Col lg={5} className="offset-lg-1">
                    <Row>
                      <label htmlFor="monthly_investment" className="col-sm-7 col-form-label" style={{ textAlign: 'right' }}>Monthly Retainer:</label>
                      <div className="col-sm-5">
                        <input defaultValue={props.investment.monthly_investment} type="text" className="form-control" name="monthly_investment" />
                      </div>
                    </Row>
                  </Col>
                  <Col lg={5} >
                    <Row>
                      <div className="col-sm-12">
                        <button className="btn btn-primary saveBtn">Save</button>
                      </div>
                    </Row>
                  </Col>
                </div>
              </form>



            </div>
          </div>
        </Modal>
      </div >

    </React.Fragment >
  );
};

export default Roi;
