import React, { useState, Fragment } from "react"
import { Link } from "react-router-dom";
import { ApiUrl, AttachementsUrl } from "../../config";
import Context from './Context';
import swal from "sweetalert";
import {
    Modal,
    Card,
    CardBody,
    Button,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ImageCropper from "./ImageCropper";

const Box7 = (props) => {
    const params = React.useContext(Context);
    const [modal, setModal] = useState(false);
    const [imageData, setImageData] = useState({
        id: '',
        url: '',
    });

    const [inputFields, setInputFields] = useState(
        { preview_professional_photo: '', preview_professional_photo_other: '', preview_personal_photo: '' }
    );

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        if (inputFields.preview_professional_photo != '') {
            submitData.append('preview_professional_photo_base_64', inputFields.preview_professional_photo);
        }

        if (inputFields.preview_professional_photo_other != '') {
            submitData.append(' preview_professional_photo_other_base_64', inputFields.preview_professional_photo_other);
        }

        if (inputFields.preview_personal_photo != '') {
            submitData.append('preview_personal_photo_base_64', inputFields.preview_personal_photo);
        }



        fetch(`${ApiUrl}` + "update/builder/setting/" + params.user_id, {
            method: "POST",
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    params.goToBox(params.currentBox + 1);
                    params.getBuilderSetting();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const changeHandlerImg = (e, id) => {
        if (e.target.files[0].type.match('image.*')) {
            const preview = document.querySelector('#' + id);
            console.log(preview)
            var reader = new FileReader();
            reader.onload = () => {
                preview.src = reader.result;
                imageCropper(id, reader.result)
            };
            reader.readAsDataURL(e.target.files[0]);
        } else {
            swal({
                title: 'Olny images allowed',
                icon: "warning",
            });
        }
    };

    const imageCropper = (id, url) => {
        // var url = document.getElementById(id).src;
        setImageData({
            id: id,
            url: url
        });
        setTimeout(() => {
            setModal(true);
        }, 200);
    }

    const imageCropperSet = (id, url) => {
        const preview = document.querySelector('#' + id);
        preview.src = url;

        setInputFields({ ...inputFields, [id]: url })

        setModal(false);
    }

    const closeModal = () => {
        setInputFields({ ...inputFields, [imageData.id]: imageData.url })
        setModal(false);
    }

    return (
        <div>
            <div className="box-center-align mt-3">
                <div className="SmallContaner my-4">
                    <div className="d-flex align-items-center gap-2 StepHeading">
                        <div className="StepNumber">3 <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">Graphic Uploads / Selections</h4>
                    </div>
                    <form method="post" onSubmit={handleSubmit}
                        encType="multipart/form-data" className="form-horizontal" id="selection-myform">


                        <div className="border-top border-bottom border-left border-right p-4 mt-3">

                            <div className="page-content-box">
                                <div className="TitleWizred d-flex align-items-center gap-2">
                                    <span className="fs-5"> e.</span> <h5>Professional Photo
                                    </h5>
                                </div>

                                <div className="WizredForm">
                                    <div className="form-group mt-4">
                                        <label>Upload a professional photo of yourself without a background
                                            <br />
                                            <small>If you're unable to remove the white background, a photo of yourself will do just fine. The shot should be from the waist up.</small>
                                        </label>
                                        <input name="professional_photo" className="form-control no-border px-0 mt-2"
                                            accept="image/*"
                                            type="file" placeholder="https://"
                                            onChange={(e) => changeHandlerImg(e, 'preview_professional_photo')}
                                        />
                                        <img width={'50'} id="preview_professional_photo" src={params.setting.professional_photo} className="img img-thumbnail mt-1" alt="Not Found" />
                                    </div>

                                    <div className="form-group mt-4">
                                        <label>Upload any other professional photo of yourself you would like on your website</label>
                                        <input name="professional_photo_other" className="form-control no-border px-0 mt-2"
                                            accept="image/*"
                                            type="file" placeholder="https://"
                                            onChange={(e) => changeHandlerImg(e, 'preview_professional_photo_other')}
                                        />
                                        <img width={'50'} id="preview_professional_photo_other" src={params.setting.professional_photo_other} className="img img-thumbnail mt-1" alt="Not Found" />
                                    </div>

                                    <div className="form-group mt-4">
                                        <label>Upload a personal photo of yourself, family, pets, etc. that can be used for your "about page"</label>
                                        <input name="personal_photo" className="form-control no-border px-0 mt-2"
                                            accept="image/*"
                                            type="file" placeholder="https://"
                                            onChange={(e) => changeHandlerImg(e, 'preview_personal_photo')}
                                        />
                                        <img width={'50'} id="preview_personal_photo" src={params.setting.personal_photo} className="img img-thumbnail mt-1" alt="Not Found" />
                                    </div>
                                </div>

                            </div>
                        </div>


                        <button type="submit" className="btn btn-lg btn-primary mt-2"> Next &nbsp;<i className="fas fa-check"></i></button>
                    </form>
                </div>
            </div>
            <Modal
                isOpen={modal}
                fade={true}
                size="lg"
                className="subActionTraining websitebuilder"
                toggle={() => closeModal()}
                centered={true}
                backdrop={'static'}

            >
                <div className="">
                    <div className='modal-header'>
                        <div className='modal-title'>
                            Crop Image
                        </div>
                        {/* <button onClick={() => closeModal()} className="mb-3 btn btn-link pull-right BtnCenterClose"><i className="fas fa-times"></i></button> */}
                    </div>

                    <ImageCropper closeModal={closeModal} imageCropperSet={imageCropperSet} data={imageData} />



                </div>
            </Modal>
        </div>
    );
};

export default Box7;