import React, { useEffect, useState } from "react"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import Helmet from "react-helmet"
import { Link } from "react-router-dom"
import { logoLightPng, ApiUrl } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { uToken, bcrypt, NumberWithCommas } from "../../useToken";
import { Tabs, Tab } from "react-bootstrap";

const ProposalSelectPackage = props => {

    const [packages, setPackages] = useState([]);

    useEffect(() => {
        getPackages();
    }, []);

    const getPackages = () => {

        fetch(`${ApiUrl}` + "packages/proposal/package", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ package_id: props.package_id, proposal_id: props.proposal_id }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setPackages(response.data.records);
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    return (
        <React.Fragment>
            <Helmet>
                <title> Register </title>
            </Helmet>
            <div className="account-pages" >
                <Card className="overflow-hidden" >
                    <CardBody className="pt-0" >
                        <section className="pricing">
                            <div className="row">
                                {
                                    packages.map((row, i) => {
                                        if (row.package_interval == 'Monthly' && row.status == 'Active') {

                                            return (
                                                <div key={i} className="card-body p-0">
                                                    <h4 className="card-title text-uppercase mb-3">{row.name}</h4>
                                                    <h6 className="card-price d-flex justify-content-between"><span className="period">Month</span>{row.price}</h6>
                                                    <hr className="my-1" />

                                                    <ul className="fa-ul PackegesDetails">
                                                        {row.description_decode.map((desc, i) => {
                                                            return (
                                                                <li key={i} >
                                                                    <span className="fa-li">
                                                                        <i className="fas fa-check"></i>
                                                                    </span>
                                                                    {desc.description_meta}
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>

                                                    {
                                                        row.setup_fee_installment > 0 && (
                                                            <div class="alert alert-info" role="alert">

                                                                <h4 class="alert-heading">This package's setup price has been divided into {row.setup_fee_installment} installments, each costing ${NumberWithCommas(Math.round(row.setup_without_convert / row.setup_fee_installment))} per month</h4>
                                                                <hr />
                                                                <p>After {row.setup_fee_installment} months, payment will be deducted based on the terms of your package, either on a monthly or yearly basis.</p>
                                                            </div>
                                                        )
                                                    }

                                                    {/* <ul className="fa-ul PackegesDetails">
                                                        <li><span className="fa-li"><i className="fas fa-check"></i></span>{row.setup_price} One Time Setup Fee</li>
                                                        <li><span className="fa-li"><i className="fas fa-check"></i></span>{row.monthly_credits} Monthly Credits</li>

                                                    </ul> */}
                                                    {/* <div className="d-grid">
                                                        <Link  onClick={() => { props.signUpStep("three"); }}  className="btn btn-primary text-uppercase">
                                                            Sign Up
                                                        </Link>
                                                    </div> */}
                                                </div>
                                            )
                                        }
                                    })
                                }

                            </div>
                        </section>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

export default ProposalSelectPackage