import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
    Row,
    Col,
    Container,
    Card,
    CardBody,
} from "reactstrap";


import { ApiUrl, logoLightPng, ProductName } from "../../config.js";
import { logout, uToken } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";

//i18n

const ThrusthubHeader = (props) => {
    const [vData, setVData] = useState({});
    const [isApproval, setIsApproval] = useState(false);
    let history = useHistory();

    const Logout = () => {
        fetch(`${ApiUrl}` + "logout", {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${uToken()}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({}),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                logout();
                history.push("/login");
                // if (data.status === true) {
                //     toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                // } else {
                //     toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                // }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    return (
        <React.Fragment>
            <div className="account-pages" >

                <Card className="overflow-hidden" >
                    <CardBody className="" >
                        <h3 className="mt-2 mb-2" >
                            <div className="logout-wizard pull-right">
                                <button type="button" className="btn btn-md btn-link" onClick={() =>    window.location.href = "/dashboard"}>
                                    <i className="fas fa-home" /> Home
                                </button>
                                <button type="button" className="btn btn-md btn-link" onClick={() =>    window.location.href = "/trusthub/overview"}>
                                    <i className="fas fa-home" /> Product Overview
                                </button>
                                <button type="button" className="btn btn-md btn-link" onClick={() => Logout()}>
                                    <i className="fas fa-power-off" /> Logout
                                </button>
                            </div>
                            <Link to="#"
                                className="d-block auth-logo" >

                                <img src={logoLightPng}
                                    alt=""
                                    height="45"
                                />
                            </Link>
                        </h3>

                    </CardBody>
                </Card>

            </div>

        </React.Fragment>
    );
};

export default ThrusthubHeader;
