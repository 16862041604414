import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  CardImg,
  CardText,
  ModalHeader,
  Nav,
  CardImgOverlay,
  NavItem,
  NavLink,
  Input,
  TabContent,
  TabPane,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Badge from "react-bootstrap/Badge";
import Breadcrumb from "../../../../components/Common/Breadcrumb";
import TrainingProductsSidebar from "./trainingproducts-sidebar";
import ProfileSettings from "../../../Authentication/profile-sidebar";
//Import Action to copy breadcrumb items from local state to redux state
import "react-toastify/dist/ReactToastify.css";
import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  bcrypt,
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../../useToken";
import { ApiUrl, ProductName, AttachementsUrl } from "../../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";

export default class CoachTrainingView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ids: this.props.match.params.id,
      total_pages: 0,
      records: [],
      content: {},
      get_master: {},
      hasContent: 0,
      showMarkComplete: true,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    fetch(`${ApiUrl}` + "get/client/training/subcategories", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ training_for: 'Client', ids: this.state.ids, user_id: uInfo().user_id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            records: response.data.records,
            content: response.data.content,
            hasContent: response.data.has_content,
            get_master: response.data.get_master_id
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  markAsComplete = () => {

    let data = {
      user_id: uInfo().user_id,
      content_id: this.state.content[0].content_id,
      cat_id: this.state.content[0].cat_id,
      section_id: this.state.content[0].section_id,
    }

    fetch(`${ApiUrl}` + "content/mark/complete", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState(prevState => {
            let content = Object.assign({}, prevState.content);
            content.id = 10;
            return { content };
          })
          this.getData();

        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className="training-view">

          <div className="p-5 text-white bg-purple has-bg-img bg-blend-multiply" style={{ backgroundImage: `url("https://toruskit.com/assets/img/util/gray-w.svg")` }}>
            <h1>{this.state.get_master.title}</h1>
            <h5 className="w-50 mt-2">{this.state.get_master.short_description}</h5>
            <img className="bg-img" src="https://toruskit.com/assets/img/util/gray-w.svg" alt="https://toruskit.com/assets/img/util/gray-w.svg" />
          </div>

          <div className="page-title-box">
            <Row>
              <Col md={10}>
                <ol className="breadcrumb mt-3 mb-3">
                  <li key={-1} className="breadcrumb-item">Dashboard</li>
                  {
                    this.state.ids.split('/').map((key, i) => {
                      return <li key={i} className="breadcrumb-item active text-transform-capitalize">
                        {key.replaceAll('-', ' ')}
                      </li>
                    })
                  }
                </ol>
              </Col>
              <Col md={2}>
                <button onClick={() => this.props.history.goBack()} className="btn btn-primary pull-right mt-2">Back</button>
              </Col>
            </Row>
          </div>

          <div className="card mb-0">
            <div className="card-body">
              <p>{this.state.get_master.description}</p>
            </div>
          </div>


          {
            this.state.hasContent == 0 ? (
              <Row className="mt-2">
                {this.state.records.map((row, k) => {
                  return (
                    <div className="pulse col-lg-3 col-md-4 col-sm-6" key={k}>
                      <Link
                        className="btn btn-link text-left"
                        to={"/client/trainings/view/" + this.state.ids + '/' + row.slug}
                      >
                        <div className="card">
                          <img src={
                            AttachementsUrl +
                            "user_2/training/" +
                            row.image
                          } alt={row.title} className="img-fluid card-img-top" />
                          <div className="card-body">
                            <div className="h4 card-title">{row.title}</div>
                            <div className="h6 card-subtitle text-muted mt-1 mb-1">{this.state.get_master.title}</div>
                            <p className="card-text">{row.short_description}</p>
                          </div>
                        </div>
                      </Link>

                      {/* <div className="marketplace_card">
                          <div className="containerw">
                            <img className="image" src={
                              AttachementsUrl +
                              "user_2/training/" +
                              row.image
                            } title={row.title} />
                            <div className="middle">
                              <Link to={"/coach/trainings/view/" + this.state.ids + '/' + row.slug} title="Embed Code" data-toggle="modal" className="text">View</Link>
                            </div>
                          </div>
                          <div className="market_content">
                            <div className="row" >
                              <div className="col-md-12">
                                <label className="tooltip-trigger" data-placement="bottom"
                                  data-toggle="tooltip" title="" data-original-title="Test">
                                  {row.title}                                          </label>
                                <div className="market_price">
                                  <label></label>
                                  <Link
                                    className="btn btn-primary"
                                    to={"/coach/trainings/view/" + this.state.ids + '/' + row.slug}
                                  >View</Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                    </div>
                  )
                })}
              </Row>
            ) : (
              <>
                {
                  this.state.get_master.total_content > 0 && this.state.get_master.total_content == this.state.get_master.total_user && (
                    <>
                      {
                        this.state.get_master.result_id == null && (
                          <button className="btn btn-info mt-2 mb-2 text-center d-none" onClick={() => this.props.history.push('/take/quiz/' + bcrypt(this.state.get_master.cat_id))}>Take Quiz</button>
                        )
                      }
                    </>
                  )
                }

                {
                  this.state.content.length > 0 && this.state.content.length <= 1 ? (

                    <Row className=" mt-2 ">
                      <Col md={9}>
                        <Card className="lesson-videos mb-4">
                          <CardBody>
                            <div dangerouslySetInnerHTML={{ __html: this.state.content[0].content }} />
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md={3}>
                        <Card>
                          <CardBody className="text-center">
                            {
                              (!this.state.content[0].id && this.state.showMarkComplete) ? (
                                <button className="btn btn-info btn-md" onClick={() => this.markAsComplete()}>
                                  Mark as Complete
                                </button>
                              ) : (
                                <div><i className="fas fa-check" /> Completed</div>
                              )
                            }
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>

                  ) : this.state.content.length > 1 ? (
                    <Card className=" mt-2 ">
                      <CardBody>
                        <Row>
                          <div className="contents">
                            {
                              this.state.content.map((row, i) => {
                                let title = row.title.split(':');
                                return (
                                  <div className="section-lesson">
                                    <div className="section-title p-2">
                                      <h5>{title[0]}</h5>
                                    </div>
                                    {
                                      i == 0 ? (
                                        <p className="unlocked">
                                          <Link to={`/client/training/section/${bcrypt(row.content_id)}`}>
                                            <i className="fas fa-circle text-success"></i>
                                            {title[1]}
                                          </Link>
                                        </p>
                                      ) : (
                                        this.state.content[i - 1].id ? (
                                          <p className="unlocked">

                                            <Link to={`/client/training/section/${bcrypt(row.content_id)}`}>
                                              <i className="fas fa-circle text-success"></i>
                                              {title[1]}
                                            </Link>
                                          </p>
                                        ) : (
                                          <p className="locked">
                                            <Link to="#">
                                              <i className="fas fa-circle text-muted"></i>

                                              {title[1]}
                                            </Link>
                                          </p>
                                        )
                                      )
                                    }
                                  </div>
                                )
                              })
                            }
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  ) :
                    <></>
                }
              </>

            )
          }
        </div>
      </React.Fragment >
    );
  }
}
