import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state
import "react-toastify/dist/ReactToastify.css";
import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";

export default class TwillioSettings extends Component {
  constructor(props) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    super(props);
    this.state = {
      id: obj.id,
      record: {},
      header_code: '',
      footer_code: '',
      page_name: this.props.match.params.id
        ? "Tracking Code"
        : "Tracking Code",
    };
    this.handleChangeheader= this.handleChangeheader.bind(this);
    this.handleChangefooter= this.handleChangefooter.bind(this);
  }

  
  componentDidMount() {
    this.getData();
  }
  handleChangeheader(event) {
    this.setState({
      header_code: event.target.value,
    });
  }
  handleChangefooter(event) {
    this.setState({
      footer_code: event.target.value,
    });
  }

  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/trackingcode", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
            console.log(response,'Debugging');
          this.setState({
            header_code: response.data.record.header_code,
            footer_code: response.data.record.footer_code,
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = async (event, values) => {

    values.header_code = this.state.header_code;
    values.footer_code = this.state.footer_code;
    fetch(`${ApiUrl}` + "addedit/trackingcode", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
            console.log(response,'After Submit');
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          // this.getData();
          toast.configure();
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.page_name} </title>
        </Helmet>
        <Helmet>
          <title>
            {this.state.page_name}
            
          </title>
        </Helmet>

        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4>{this.state.page_name}</h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#">{this.state.page_name}</Link>
                </li>
              </ol>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <Card>
              <AvForm
                onValidSubmit={this.handleSubmit}
                ref={(c) => (this.form = c)}
                className="needs-validation"
              >
                <ModalBody>
                  <div className="mb-3">
                    <h4>Header Code</h4>
                    <textarea
                      className="form-control"
                      name="header_code"
                      value={this.state.header_code}
                      placeholder="Header Code"
                      onChange={this.handleChangeheader}
                      rows={5}
                      cols={5}
                    />
                  </div>
                  <div className="mb-3">
                    <h4>Footer Code</h4>
                    <textarea
                      className="form-control"
                      name="footer_code"
                      value={this.state.footer_code}
                      onChange={this.handleChangefooter}
                      placeholder="Footer Code"
                      rows={5}
                      cols={5}
                    />
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => this.props.history.goBack()}
                  >
                    Cancel
                  </Button>
                  <button
                    className="btn btn-primary w-md waves-effect waves-light"
                    type="submit"
                  >
                    Submit
                  </button>
                </ModalFooter>
              </AvForm>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
