import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumb from "../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";


export default class TwillioUsageLog extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        text: "SR#",
        key: "sr",
        sortable: false,
      },
      {
        text: "Name",
        key: "coach_name",
        sortable: false,
        cell: (record, index) => {
          return (
            <Fragment>
              {isAllowed(uInfo(), ["can_view"]) ? (
                <button
                  onClick={() => this.props.history.push("/user-profile/" + bcrypt(record.user_id))}
                  className="waves-effect btn btn-link"
                >
                  {record.coach_name}
                </button>
              ) : (
                record.coach_name
              )}
            </Fragment>
          );
        },
      },

      {
        text: "Email",
        key: "email",
        sortable: false,
      },
      {
        text: "Calls/Price",
        key: "call_price",
        sortable: false,
      },
      {
        text: "SMS/Price",
        key: "sms_price",
        sortable: false,
      },
      {
        text: "Phone/Price",
        key: "phone_price",
        sortable: false,
      },
      {
        text: "Recordings/Price",
        key: "recording_price",
        sortable: false,
      },
      {
        text: "Total Price",
        key: "total_price",
        sortable: false,
      },
      {
        text: "Created At",
        key: "start_date",
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
      button: {
        excel: false,
        print: true,
        csv: true,
      },
    };
    this.state = {
      type: this.props.match.params.type,
      records: [],
      roles: [],
      users: [],
      total_pages: 0,
      modal: false,
      record: {},
      filter: {},
      page_name: "Twilio Usage Log",
      customDate: true,
      user_id: this.props.user_id ? this.props.user_id : "",
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.type !== prevProps.match.params.type) {
      this.setState({
        type: this.props.match.params.type,
      });
      this.getData(this.props.match.params.type, "");
    }
  }

  getData = (queryString = "", data) => {

    let url = `${ApiUrl}` + "twillio/activitylog";
    fetch(`${url}` + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        console.log(response, "here data");
        if (response.status === true) {
          this.setState({
            total_pages: response.data.total,
            records: response.data.records,
            roles: response.data.roles,
            users: response.data.users,
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = async (event, values) => {
    if (this.state.record) {
      values.id = this.state.record.id;
    }
    fetch(`${ApiUrl}` + "edit/user", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          this.setState({
            modal: false,
            record: {},
            selectedMulti: null,
            selectedPermissionMulti: null,
            pages: "",
            permission: "",
          });
          this.getData();
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleFilterSubmit = async (event, values) => {
    this.setState({
      filter: values,
    });
    this.getData('', values);
  };

  cancelFilter = async () => {
    this.form && this.form.reset();
    this.setState({ filter: {}, customDate: true });
    this.getData("", "");
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData("?" + queryString, this.state.filter);
  };

  render() {
    return (
      <React.Fragment>
        <Row>

          <Col lg="12">
            {this.state.user_id == "" && (
              <Helmet>
                <title>{this.state.page_name} Tables </title>
              </Helmet>
            )}
            {this.state.user_id == "" && (
              <Row>
                <Col sm={6}>
                  <div className="page-title-box">
                    <h4>{this.state.page_name} Lists</h4>
                    {this.state.user_id == "" && (
                      <ol className="breadcrumb m-0">
                        <li key={0} className="breadcrumb-item active">
                          {ProductName}
                        </li>
                        <li key={1} className="breadcrumb-item">
                          <Link to="#">{this.state.page_name} Lists</Link>
                        </li>
                      </ol>
                    )}
                  </div>
                </Col>
              </Row>
            )}

            <ToastContainer />
            {this.state.user_id == "" && (
              <Row>
                <Col xl="12">
                  <Card className="mb-0">
                    <CardBody className="py-0">
                      <AvForm
                        onValidSubmit={this.handleFilterSubmit}
                        ref={(c) => (this.form = c)}
                        className="needs-validation"
                      >
                        <Row>
                          <Col md="2">
                            <div className="mb-3">
                              <AvField
                                className="select form-control"
                                type="select"
                                name="date_range"
                                label="Date Filter"
                                onChange={(e) =>
                                  e.target.value == "Custom Date"
                                    ? this.setState({
                                      customDate: false,
                                    })
                                    : this.setState({
                                      customDate: true,
                                    })
                                }
                              >
                                <option value=""> All Time </option>
                                <option value="Current Week">Current Week</option>
                                <option value="Last Week"> Last Week </option>
                                <option value="Current Month">
                                  Current Month
                                </option>
                                <option value="Last Month"> Last Month </option>
                                <option value="Last 3 Months">
                                  Last 3 Months
                                </option>
                                <option value="Last 6 Months">
                                  Last 6 Months
                                </option>
                                <option value="Current Year">Current Year</option>
                                <option value="Last Year"> Last Year </option>
                                <option value="Custom Date"> Custom Date </option>
                              </AvField>
                            </div>
                          </Col>
                          <Col md="2">
                            <div className="mb-3">
                              <AvField
                                name="start_date"
                                label="Start date"
                                className="form-control"
                                placeholder="Enter Email Address"
                                type="date"
                                disabled={this.state.customDate}
                                required={!this.state.customDate}
                              />
                            </div>
                          </Col>
                          <Col md="2">
                            <div className="mb-3">
                              <AvField
                                name="end_date"
                                label="End date"
                                className="form-control"
                                placeholder="Enter Email Address"
                                type="date"
                                disabled={this.state.customDate}
                                required={!this.state.customDate}
                              />
                            </div>
                          </Col>
                          {/* <Col md="2">
                         <div className="mb-3">
                           <AvField
                             className="select form-control"
                             type="select"
                             id="s"
                             name="status"
                             label="Status"
                           >
                             <option value={""}> Select Status </option>
                             <option value={"Active"}> Active </option>
                             <option value={"Inactive"}> Inactive </option>
                           </AvField>
                         </div>
                       </Col> */}
                          <Col md="2">
                            <label className="">Action</label>
                            <div className="mb-3">
                              <div className="button-items">
                                <Button
                                  className="btn-sm"
                                  color="primary"
                                  type="submit"
                                >
                                  Filter
                                </Button>
                                <Button
                                  onClick={() => this.cancelFilter()}
                                  outline
                                  color="secondary"
                                  className="waves-effect btn-sm"
                                  type="button"
                                >
                                  Reset
                                </Button>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody className="pt-0">
                    {this.state.user_id != "" && (
                      <CardTitle className="h4">Activity Log Lists</CardTitle>
                    )}
                    <ReactDatatable
                      config={this.config}
                      records={this.state.records}
                      columns={this.columns}
                      dynamic={true}
                      total_record={this.state.total_pages}
                      onChange={this.tableChangeHandler}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
