import React, { useState, useEffect } from 'react';

import ResourcesContext from './ResourcesContext';

import Navbar from "./Navbar";
import SideBar from "./SideBar";
import DisplayUsers from "./DisplayUsers";
import DisplayDrive from "./DisplayDrive";
import UploadFiles from './files_upload';

import "../../assets/css/resource-drive/SideBar.css";
import Loader from "react-loader";
import { ApiUrl } from '../../config';
import swal from 'sweetalert';
import { dcrypt, isObjectEmpty, uInfo, uToken } from '../../useToken';
import {
    Modal,
    Card,
    CardBody,
    Button,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import Avatar from "react-avatar";
import Select from "react-select";

function ResourcesDrive(props) {
    const [loaded, setLoaded] = useState(false);
    const [lists, setLists] = useState([]);
    const [type] = useState(props.match.params.type);
    const [user_id] = useState(props.match.params.user_id ? dcrypt(props.match.params.user_id) : '');
    const [idx] = useState(props.match.params.ids ?? '');
    const [viewType, setViewType] = useState(localStorage.getItem("view_type") ?? 'grid');
    const [driveType, setDriveType] = useState("");
    const [action, setAction] = useState(0);
    const [actionModal, setActionModal] = useState(false);
    const [folderId, setFolderId] = useState(0);
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    const [subAction, setSubAction] = useState({});
    const [subactionModal, setSubActionModal] = useState(false);
    const [share, setShare] = useState(false);
    const [users, setUsers] = useState([]);
    const [selected_users, setSelectedUsers] = useState([]);

    const [subFileAction, setSubFileAction] = useState({});
    const [subFileactionModal, setSubFileActionModal] = useState(false);
    const [page2Class, setpage2Class] = useState("");

    const [roles, setRoles] = useState({
        'coachPackages': [],
        'staff': []
    });


    const [movelists, setMoveLists] = useState({
        records: {
            folders: [],
            files: [],
        },
        record: {}
    });

    const [info, setInfo] = useState({
        record: {},
        shareUser: [],
    });

    const [moveFolderId, setMoveFolderId] = useState(0);


    useEffect(() => {
        if (user_id == '' && type != 'my-drive') {
            getResourcesUsersLists();
        } else {
            getResourcesLists();
        }
    }, []);

    const getResourcesUsersLists = (search = '') => {
        setLoaded(false);
        fetch(`${ApiUrl}` + "get/drive/user/lists", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ type: type, search: search }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    setLists(response.data.records);
                    setDriveType('users');
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
                setLoaded(true);

            })
            //Then with the error genereted...
            .catch((error) => {
                setLoaded(true);

                console.error("Error:", error);
            });
    };
    const getResourcesLists = (search = '') => {
        setLoaded(false);
        fetch(`${ApiUrl}` + "get/drive/lists", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ type: type, user_id: user_id, idx: idx, search: search, staff_user_id: uInfo().staff_user_id }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    setLists(response.data.records);
                    setFolderId(response.data.folder_id);
                    setBreadcrumbs(response.data.breadcrumbs);
                    setDriveType('drive');
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
                setLoaded(true);

            })
            //Then with the error genereted...
            .catch((error) => {
                setLoaded(true);

                console.error("Error:", error);
            });
    };


    const Action = (from, event) => {
        setAction(0);
        setTimeout(() => {
            if (event == 1) {
                setAction(event);
                setActionModal(true);
            } else if (event == 2) {
                setAction(event);
                setActionModal(true);
            }

        }, 100)
    };

    const closeActionModal = () => {
        setAction(0);
        setActionModal(false);
        setSubFileAction({});

        setSubAction({});
        setInfo({
            record: {},
            shareUser: [],
        });
        setUsers([]);
        setSubActionModal(false);
        setSubFileActionModal(false);

        setShare(false);
        setpage2Class("slide-out2");

        if (action == 2 || action == 4) {
            getResourcesLists();
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        let url = '';
        if (action == 1 || action == 5) {
            url = 'submit/drive/folder';
        } else if (action == 2 || action == 6) {
            url = 'submit/drive/file';
        }

        fetch(`${ApiUrl}` + url, {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    getResourcesLists();
                    closeActionModal();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const DriveAction = (from, event, object) => {
        setSubAction({});
        setTimeout(() => {
            if (event == 1) {
                deleteFolder(object.id);
            } else if (event == 2) {
                setAction(3);
                setSubAction(object);
                getMoveLists(object.parent_id);
            } else if (event == 3) {
                setSubAction(object);
                getFolderInfo(object.id);
                getUsersLists();
                setAction(4);
            } else if (event == 4) {
                setSubAction(object);
                setAction(5);
                setSubActionModal(true);
            }

        }, 100)
    };
    const getFolderInfo = (id) => {
        setMoveFolderId(0);
        fetch(`${ApiUrl}` + "get/folder/info/" + id, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    setInfo(response.data);
                    setSubActionModal(true);
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    const getUsersLists = () => {
        fetch(`${ApiUrl}` + "get/drive/user/lists", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ type: 'all' }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    setUsers(response.data.records);
                    setRoles(response.data.roles);
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }

            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    const getMoveLists = (id, search = "") => {
        setMoveLists({});
        setMoveFolderId(0);
        fetch(`${ApiUrl}` + "get/drive/lists", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ type: type, user_id: user_id, idx: id, search: search }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    setMoveLists(response.data);
                    setSubActionModal(true);
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const moveFolder = (from, to) => {
        fetch(`${ApiUrl}` + "move/drive/folder", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ from: from, to: to }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    getResourcesLists();
                    closeActionModal();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const deleteFolder = (folder_id) => {
        swal({
            title: "Are you sure?",
            text: "You want to remove this record.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "delete/drive/folder", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                        body: JSON.stringify({ folder_id: folder_id }),
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((response) => {
                            if (response.status === true) {
                                getResourcesLists();
                            } else {
                                swal({
                                    title: response.message,
                                    icon: "warning",
                                });
                            }
                        })
                        //Then with the error genereted...
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    }

    const shareFolder = (id) => {
        var filter = [];

        if (subAction.share_type == 0) {
            if (selected_users.length < 1) {
                swal({
                    title: "You need to select atleast one user",
                    icon: "warning",
                });
                return;
            }


            if (selected_users) {
                selected_users.map((fil) => {
                    filter.push(fil.user_id);
                });
            }
        }

        if (subFileAction.share_type == 1) {
            if (subFileAction.share_group_id == '0' || subFileAction.share_group_id == '') {
                swal({
                    title: "You need to select one group",
                    icon: "warning",
                });
                return;
            }
        }

        fetch(`${ApiUrl}` + "share/drive/folder", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ folder_id: id, user_id: filter, share_type: subAction.share_type, share_group_id: subAction.share_group_id }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    closeActionModal();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }

            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });

    }

    const FileAction = (from, event, object) => {
        setSubFileAction({});
        setTimeout(() => {
            if (event == 1) {
                deleteFile(object.id);
            } else if (event == 2) {
                setAction(3);
                setSubFileAction(object);
                getMoveFileLists(object.folder_id);
            } else if (event == 3) {
                setSubFileAction(object);
                getFileInfo(object.id);
                getUsersLists();
                setAction(4);
            } else if (event == 4) {
                setSubAction(object);
                setAction(6);
                setSubActionModal(true);
            } else if (event == 5) {
                object.ext = object.url.split('.').pop();
                setSubAction(object);
                setpage2Class("slide-in");
            }

        }, 100)
    };

    const getFileInfo = (id) => {
        setMoveFolderId(0);
        fetch(`${ApiUrl}` + "get/file/info/" + id, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    setInfo(response.data);
                    setSubFileActionModal(true);
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const deleteFile = (file_id) => {
        swal({
            title: "Are you sure?",
            text: "You want to remove this record.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "delete/drive/file", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                        body: JSON.stringify({ file_id: file_id }),
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((response) => {
                            if (response.status === true) {
                                getResourcesLists();
                            } else {
                                swal({
                                    title: response.message,
                                    icon: "warning",
                                });
                            }
                        })
                        //Then with the error genereted...
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    }

    const moveFile = (from, to) => {
        fetch(`${ApiUrl}` + "move/drive/file", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ from: from, to: to }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    getResourcesLists();
                    closeActionModal();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const getMoveFileLists = (id, search = "") => {
        setMoveLists({});
        setMoveFolderId(0);
        fetch(`${ApiUrl}` + "get/drive/lists", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ type: type, user_id: user_id, idx: id, search: search }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    setMoveLists(response.data);
                    setSubFileActionModal(true);
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const shareFile = (id) => {

        var filter = [];

        if (subFileAction.share_type == 0) {

            if (selected_users.length < 1) {
                swal({
                    title: "You need to select atleast one user",
                    icon: "warning",
                });
                return;

            }


            if (selected_users) {
                selected_users.map((fil) => {
                    filter.push(fil.user_id);
                });
            }
        }

        if (subFileAction.share_type == 1) {
            if (subFileAction.share_group_id == '0' || subFileAction.share_group_id == '') {
                swal({
                    title: "You need to select one group",
                    icon: "warning",
                });
                return;
            }
        }

        fetch(`${ApiUrl}` + "share/drive/file", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ file_id: id, user_id: filter, share_type: subFileAction.share_type, share_group_id: subFileAction.share_group_id }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    closeActionModal();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }

            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });

    }

    const changeViewType = (type) => {
        if (type == 'grid') {
            localStorage.setItem("view_type", 'list');
            setViewType('list');
        } else if (type == 'list') {
            localStorage.setItem("view_type", 'grid');
            setViewType('grid');
        }
    }

    const user_types = [
        { value: '1', label: 'Coach' },
        { value: '2', label: 'Diamond Partner' },
        { value: '3', label: 'Client' },
        { value: '4', label: 'Staff' }
    ];

    const data = {
        lists: lists,
        type: type,
        driveType: driveType,
        user_id: user_id,
        idx: idx,
        Action: Action,
        breadcrumbs: breadcrumbs,
        getResourcesUsersLists: getResourcesUsersLists,
        getResourcesLists: getResourcesLists,
        DriveAction: DriveAction,
        FileAction: FileAction,
        changeViewType: changeViewType,
        viewType: viewType,
    };

    return (
        <ResourcesContext.Provider value={data}>
            <Loader loaded={loaded}></Loader>
            <div className='drive-main'>
                <Navbar />
                <div className='row'>
                    <div className='col-lg-2 ps-0'>
                        <SideBar />
                    </div>
                    <div className='col-lg-10'>
                        {
                            driveType == 'users' && (
                                <DisplayUsers />
                            )
                        }

                        {
                            driveType == 'drive' && (
                                <DisplayDrive />
                            )
                        }
                    </div>
                </div>
                {/* <button
                    className="btn btn-link btn-md pull-right"
                    onClick={() => {
                        setpage2Class("slide-in");
                    }}
                >
                    <i className="fas fa-pencil-alt"> </i>
                </button> */}

                <div className={`page3 ${page2Class} custom-scroll drive-detail`}>
                    <div>
                        <div className="modal-head top-heading">
                            <h2> {subAction.name} </h2>
                            <button
                                onClick={() => {
                                    closeActionModal();
                                }}
                                className="btn btn-link text-white"
                            >
                                <i className="fas fa-times"> </i>
                            </button>
                        </div>
                        <div className='modal-body'>
                            <div className="h_iframe">

                                {
                                    subAction?.file_type?.match('image.*') ? (
                                        <img src={subAction.url} className="img img-thumbnail" />
                                    ) :
                                        subAction?.file_type?.match('video.*') ? (
                                            <video width={'100%'} height={'100%'} controls="controls">
                                                <source src={subAction.url} />
                                                Your browser does not support the HTML5 Video element.
                                            </video>
                                        ) :
                                            subAction?.file_type?.match('audio.*') ? (
                                                <audio width={'100%'} height={'100%'} controls="controls">
                                                    <source src={subAction.url} />
                                                    Your browser does not support the HTML5 Audio element.
                                                </audio>
                                            ) :

                                                // <embed src={subAction.url} type={subAction?.file_type} width="100%" height="600px" />



                                                <iframe src={`https://docs.google.com/viewer?url=${subAction.url}&embedded=true`}></iframe>
                                }

                                {/* {
                                    subAction?.file_type?.match('image.*') || subAction?.file_type?.match('video.*') ? (
                                        <div className="responsive-iframe">
                                            <iframe src={`${subAction.url}`} width="640" height="100%" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                                        </div>

                                    ) : (
                                        <iframe src={`https://docs.google.com/viewer?url=${subAction.url}&embedded=true`}></iframe>
                                    )
                                } */}



                                {/* <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${subAction.url}`}></iframe> */}

                                {/* <iframe src={subAction.url} frameborder="0" allowfullscreen></iframe> */}
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={actionModal}
                    fade={true}
                    size="md"
                    className="DriveModal"
                    toggle={() => closeActionModal()}
                    centered={true}
                    backdrop={'static'}
                >
                    <div className="p-2">
                        <div className="">
                            <div className='modal-header p-0'>
                                <div className='modal-title pl-3'>
                                    {
                                        action == 1 && (
                                            <h5>New Folder</h5>
                                        )
                                    }

                                    {
                                        action == 2 && (
                                            <h5>Upload File</h5>
                                        )
                                    }

                                </div>
                                <button onClick={() => closeActionModal()} className="mb-3 btn btn-link pull-right BtnCenterClose"><i className="fas fa-times"></i></button>
                            </div>
                            {
                                action == 1 && (
                                    <form method="post" onSubmit={handleSubmit}
                                        encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                                        <div className='modal-body'>

                                            {
                                                action == 1 && (
                                                    <div className='form-group'>
                                                        <input name='name' type={'text'} className="form-control" required defaultValue={'Untitled folder'} />
                                                    </div>
                                                )
                                            }


                                            <input name='user_id' value={user_id} required type={'hidden'} />
                                            <input name='folder_id' value={folderId} required type={'hidden'} />
                                        </div>

                                        <div className='modal-footer p-0'>
                                            <div className="row mt-3">
                                                <div className="col-md-12">
                                                    <div className="pull-right">
                                                        <button type="button" onClick={() => closeActionModal()} className="btn btn-link mr-3" >Cancel</button>
                                                        <button type="submit" className="btn btn-primary" >Create</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                )
                            }

                            {
                                action == 2 && (
                                    <>
                                        <UploadFiles userId={user_id} folderId={folderId} />
                                        {/* <div className='form-group mb-3'>
                                                    <input placeholder='Add File Title' name='name' type={'text'} className="form-control" required />
                                                </div> */}

                                        {/* <div className='form-group'>
                                            <input name='file' type={'file'} className="form-control" required />
                                        </div> */}
                                    </>
                                )
                            }
                        </div>
                    </div>
                </Modal>

                <Modal
                    isOpen={subactionModal}
                    fade={true}
                    size="md"
                    className="subActionTraining"
                    toggle={() => closeActionModal()}
                    centered={true}
                >

                    {
                        action == 3 && (
                            <div className='drive-move-modal'>
                                <div className='modal-header p-0'>

                                    <div className='modal-title p-2'>
                                        {
                                            isObjectEmpty(movelists.record) ?
                                                <div className='d-flex'>
                                                    <h3>Drive</h3>
                                                </div>
                                                :
                                                <div className='d-flex'>
                                                    <button title='Back' className='btn btn-link' onClick={() => getMoveLists(movelists.record.parent_id)}>
                                                        <i className='fas fa-long-arrow-alt-left'></i>
                                                    </button>

                                                    <h3>{movelists?.record?.name}</h3>
                                                </div>
                                        }


                                    </div>
                                    <button onClick={() => closeActionModal()} className="btn btn-link"><i className="fas fa-times"></i></button>
                                </div>
                                <div className="modal-body">
                                    <ul className='list-group'>
                                        {
                                            movelists?.records?.folders.map((row, i) => {
                                                return (
                                                    <li className={`list-group-item d-flex justify-content-between ${moveFolderId == row.id ? 'active' : ''} ${subAction.id == row.id ? 'disabled' : ''} ${subAction.parent_id == row.id ? 'disabled' : ''}`} onClick={() => setMoveFolderId(moveFolderId != row.id ? row.id : 0)} onDoubleClick={() => getMoveLists(row.id)}>
                                                        {row.name}
                                                        <div className={`hover-show ${subAction.parent_id == row.id ? 'd-none' : ''} ${subAction.id == row.id ? 'd-none' : ''}`}>
                                                            <button title={`go to ${row.name} folder`} className='btn btn-primary' onClick={() => getMoveLists(row.id)}>
                                                                <i className='fas fa-angle-right'></i>
                                                            </button>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className="modal-footer p-0">
                                    <div className='action-move-button p-2'>
                                        {
                                            moveFolderId == 0 ?
                                                <button className='btn btn-primary' onClick={() => moveFolder(subAction.id, movelists.record.id > 0 ? movelists.record.id : 0)}>
                                                    Move Here
                                                </button>
                                                :
                                                <button className='btn btn-primary' onClick={() => moveFolder(subAction.id, moveFolderId)}>
                                                    Move
                                                </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    {
                        action == 4 && (
                            <div className='folder-share'>
                                <div className='modal-header p-0'>
                                    <div className='modal-title p-2'>
                                        <h4 className='m-0'>Share "{subAction.name}"</h4>
                                    </div>
                                    <button onClick={() => closeActionModal()} className="btn btn-link"><i className="fas fa-times"></i></button>
                                </div>
                                <div className="modal-body py-1">

                                    <div className="form-group">
                                        <div className="radio-box">
                                            <label>Share With</label>
                                            <br />
                                            <div className="form-check form-check-inline">
                                                <input name="own_hero_image" defaultChecked={subAction.share_type == 0 ? true : false}
                                                    onChange={() => setSubAction((prevState) => ({
                                                        ...prevState,
                                                        share_type: 0,
                                                    }))}
                                                    className="form-check-input" type="radio" id="inlineRadio1" value="0" />
                                                <label className="form-check-label" for="inlineRadio1">Individual Users</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input name="own_hero_image" defaultChecked={subAction.share_type == 1 ? true : false}
                                                    onChange={() => setSubAction((prevState) => ({
                                                        ...prevState,
                                                        share_type: 1,
                                                    }))}
                                                    className="form-check-input" type="radio" id="inlineRadio2" value="1" />
                                                <label className="form-check-label" for="inlineRadio2">Group</label>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        subAction.share_type == 0 && (
                                            <>

                                                <div className="form-group mt-3">
                                                    <label htmlFor="example-text-input" className="form-label">Users Lists</label>
                                                    <Select
                                                        isClearable
                                                        getOptionLabel={option => `${option.name} | ${option.email} | ${option.package_id == 4 ? 'Diamond Coach' : option.type}`}
                                                        getOptionValue={option => option.user_id}
                                                        isMulti={true}
                                                        menuIsOpen={true}
                                                        placeholder={'Select User which you want to share this folder'}
                                                        options={users}
                                                        classNamePrefix="select2-selection"
                                                        name="user_ids"
                                                        onChange={(selectedGroup) => { setSelectedUsers(selectedGroup); setShare(true) }}
                                                    />
                                                </div>

                                                {
                                                    !share && (
                                                        <div className='share-users'>
                                                            <h5>People with access</h5>
                                                            <div className="inbox-wid">
                                                                {
                                                                    info?.shareUser.map((row, i) => {
                                                                        return (
                                                                            <a key={i} className="text-dark" href="#">
                                                                                <div className="inbox-item">
                                                                                    <div className="inbox-item-img float-start me-3">
                                                                                        <Avatar name={row.name} size="48" round={true} />
                                                                                    </div>
                                                                                    <h6 className="inbox-item-author mt-0 mb-1 font-size-16">{row.name}</h6>
                                                                                    <p className="inbox-item-text text-muted mb-0">{row.email}</p>
                                                                                    <p className="inbox-item-date text-muted"></p>
                                                                                </div>
                                                                            </a>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    }

                                    {
                                        subAction.share_type == 1 && (
                                            <>
                                                <div className="form-group mt-3">
                                                    <label htmlFor="example-text-input" className="form-label">Group</label>
                                                    <select className='form-control' defaultValue={subAction.type + '-' + subAction.share_group_id}
                                                        onChange={(e) => setSubAction((prevState) => ({
                                                            ...prevState,
                                                            share_group_id: e.target.value,
                                                        }))}
                                                    >
                                                        <option value={'0'}>Select Group</option>
                                                        <option value={`Client-3`}>Client</option>
                                                        <option value={`Team-6`}>Coach Team</option>
                                                        {
                                                            (uInfo().type == 'WlAdmin' || (uInfo().type == 'Coach' && (uInfo().package_id == 3 || uInfo().package_id == 4 || uInfo().has_coach_license == 'Yes'))) && (
                                                                <optgroup label="Coach">
                                                                    {
                                                                        roles.coachPackages.map((row, i) => {
                                                                            return <option key={'Coach' + i} value={`Coach-${row.package_id}`}>{row.name}</option>
                                                                        })
                                                                    }
                                                                </optgroup>
                                                            )
                                                        }
                                                        {
                                                            uInfo().type == 'WlAdmin' && (
                                                                <optgroup label="Team Admin">
                                                                    {
                                                                        roles.staff.map((row, i) => {
                                                                            return <option key={'Staff' + i} value={`Staff-${row.id}`}>{row.name}</option>
                                                                        })
                                                                    }
                                                                </optgroup>

                                                            )
                                                        }

                                                        {/* {
                                                            user_types.map((row, i) => {
                                                                return <option key={i} value={`${row.value}`}>{row.label}</option>
                                                            })
                                                        } */}
                                                    </select>
                                                </div>
                                            </>
                                        )
                                    }



                                </div>
                                <div className="modal-footer p-0">
                                    <div className='action-move-button p-2'>

                                        {/* <button type='button' className='btn btn-link' onClick={() => closeActionModal()}>
                                            Cancel
                                        </button> */}
                                        {subAction.share_type == 0 && (
                                            <button className={`btn btn-primary ${!share ? 'd-none' : ''}`} onClick={() => shareFolder(subAction.id)}>
                                                Share
                                            </button>
                                        )}

                                        {subAction.share_type == 1 && (
                                            <button className={`btn btn-primary`} onClick={() => shareFolder(subAction.id)}>
                                                Share
                                            </button>
                                        )}

                                    </div>
                                </div>
                            </div>
                        )
                    }

                    {
                        action == 5 && (
                            <div className='folder-rename'>
                                <div className='modal-header p-0'>
                                    <div className='modal-title p-2'>
                                        <h3>Rename </h3>
                                    </div>
                                    <button onClick={() => closeActionModal()} className="btn btn-link"><i className="fas fa-times"></i></button>
                                </div>
                                <form method="post" onSubmit={handleSubmit}
                                    encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                                    <div className="modal-body">
                                        <div className='form-group'>
                                            <input name='name' type={'text'} className="form-control" required defaultValue={subAction.name} />
                                        </div>
                                    </div>
                                    <input name='id' value={subAction.id} required type={'hidden'} />
                                    <div className='modal-footer p-0'>
                                        <div className="row mt-3">
                                            <div className="col-md-12">
                                                <div className="pull-right">
                                                    {/* <button type="button" onClick={() => closeActionModal()} className="btn btn-link mr-3" >Cancel</button> */}
                                                    <button type="submit" className="btn btn-primary" >Ok</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )
                    }

                    {
                        action == 6 && (
                            <div className='folder-rename'>
                                <div className='modal-header p-0'>
                                    <div className='modal-title p-2'>
                                        <h3>Rename </h3>
                                    </div>
                                    <button onClick={() => closeActionModal()} className="btn btn-link"><i className="fas fa-times"></i></button>
                                </div>
                                <form method="post" onSubmit={handleSubmit}
                                    encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                                    <div className="modal-body">
                                        <div className='form-group'>
                                            <input name='name' type={'text'} className="form-control" required defaultValue={subAction.name} />
                                        </div>
                                    </div>
                                    <input name='id' value={subAction.id} required type={'hidden'} />
                                    <div className='modal-footer p-0'>
                                        <div className="row mt-3">
                                            <div className="col-md-12">
                                                <div className="pull-right">
                                                    <button type="button" onClick={() => closeActionModal()} className="btn btn-link mr-3" >Cancel</button>
                                                    <button type="submit" className="btn btn-primary" >Ok</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )
                    }


                </Modal>

                <Modal
                    isOpen={subFileactionModal}
                    fade={true}
                    size="md"
                    className="subActionTraining"
                    toggle={() => closeActionModal()}
                    centered={true}
                >

                    {
                        action == 3 && (
                            <div className='drive-move-modal'>
                                <div className='modal-header p-0'>

                                    <div className='modal-title p-2'>
                                        {
                                            isObjectEmpty(movelists.record) ?
                                                <div className='d-flex'>
                                                    <h3>Drive</h3>
                                                </div>
                                                :
                                                <div className='d-flex'>
                                                    <button title='Back' className='btn btn-link' onClick={() => getMoveFileLists(movelists.record.parent_id)}>
                                                        <i className='fas fa-long-arrow-alt-left'></i>
                                                    </button>

                                                    <h3>{movelists?.record?.name}</h3>
                                                </div>
                                        }


                                    </div>
                                    <button onClick={() => closeActionModal()} className="btn btn-link"><i className="fas fa-times"></i></button>
                                </div>
                                <div className="modal-body">
                                    <ul className='list-group'>
                                        {
                                            movelists?.records?.folders.map((row, i) => {
                                                return (
                                                    <li className={`list-group-item d-flex justify-content-between ${moveFolderId == row.id ? 'active' : ''} `} onClick={() => setMoveFolderId(moveFolderId != row.id ? row.id : 0)} onDoubleClick={() => getMoveFileLists(row.id)}>
                                                        {row.name}
                                                        <div className={`hover-show`}>
                                                            <button title={`go to ${row.name} folder`} className='btn btn-primary' onClick={() => getMoveFileLists(row.id)}>
                                                                <i className='fas fa-angle-right'></i>
                                                            </button>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className="modal-footer p-0">
                                    <div className='action-move-button p-0'>
                                        {
                                            moveFolderId == 0 ?
                                                <button className={`btn btn-primary ${movelists.record?.id == subFileAction.folder_id ? 'disabled' : ''}`} onClick={() => moveFile(subFileAction.id, movelists.record.id > 0 ? movelists.record.id : 0)}>
                                                    Move Here
                                                </button>
                                                :
                                                <button className='btn btn-primary' onClick={() => moveFile(subFileAction.id, moveFolderId)}>
                                                    Move
                                                </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    {
                        action == 4 && (
                            <div className='folder-share'>
                                <div className='modal-header p-0'>
                                    <div className='modal-title p-2'>
                                        <h4 className='m-0'>Share "{subFileAction.name}"</h4>
                                    </div>
                                    <button onClick={() => closeActionModal()} className="btn btn-link"><i className="fas fa-times"></i></button>
                                </div>
                                <div className="modal-body py-0">

                                    <div className="form-group">
                                        <div className="radio-box">
                                            <label>Share With</label>
                                            <br />
                                            <div className="form-check form-check-inline">
                                                <input name="own_hero_image" defaultChecked={subFileAction.share_type == 0 ? true : false}
                                                    onChange={() => setSubFileAction((prevState) => ({
                                                        ...prevState,
                                                        share_type: 0,
                                                    }))}
                                                    className="form-check-input" type="radio" id="inlineRadio1" value="0" />
                                                <label className="form-check-label" for="inlineRadio1">Individual Users</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input name="own_hero_image" defaultChecked={subFileAction.share_type == 1 ? true : false}
                                                    onChange={() => setSubFileAction((prevState) => ({
                                                        ...prevState,
                                                        share_type: 1,
                                                    }))}
                                                    className="form-check-input" type="radio" id="inlineRadio2" value="1" />
                                                <label className="form-check-label" for="inlineRadio2">Group</label>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        subFileAction.share_type == 0 && (
                                            <>


                                                <div className="form-group mt-3">
                                                    <label htmlFor="example-text-input" className="form-label">Users Lists</label>
                                                    <Select
                                                        isClearable
                                                        getOptionLabel={option => `${option.name} | ${option.email} | ${option.package_id == 4 ? 'Diamond Coach' : option.type}`}
                                                        getOptionValue={option => option.user_id}
                                                        isMulti={true}
                                                        menuIsOpen={true}
                                                        placeholder={'Select User which you want to share this folder'}
                                                        options={users}
                                                        classNamePrefix="select2-selection"
                                                        name="user_ids"
                                                        onChange={(selectedGroup) => { setSelectedUsers(selectedGroup); setShare(true) }}
                                                    />
                                                </div>

                                                {
                                                    !share && (
                                                        <div className='share-users'>
                                                            <h5>People with access</h5>
                                                            <div className="inbox-wid">
                                                                {
                                                                    info?.shareUser.map((row, i) => {
                                                                        return (
                                                                            <a key={i} className="text-dark" href="#">
                                                                                <div className="inbox-item">
                                                                                    <div className="inbox-item-img float-start me-3">
                                                                                        <Avatar name={row.name} size="48" round={true} />
                                                                                    </div>
                                                                                    <h6 className="inbox-item-author mt-0 mb-1 font-size-16">{row.name}</h6>
                                                                                    <p className="inbox-item-text text-muted mb-0">{row.email}</p>
                                                                                    <p className="inbox-item-date text-muted"></p>
                                                                                </div>
                                                                            </a>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    }


                                    {
                                        subFileAction.share_type == 1 && (
                                            <>
                                                <div className="form-group mt-3">
                                                    <label htmlFor="example-text-input" className="form-label">Group</label>
                                                    <select className='form-control' defaultValue={subFileAction.share_group_id}
                                                        onChange={(e) => setSubFileAction((prevState) => ({
                                                            ...prevState,
                                                            share_group_id: e.target.value,
                                                        }))}
                                                    >
                                                        <option value={'0'}>Select Group</option>
                                                        <option value={`Client-3`}>Client</option>
                                                        <option value={`Team-6`}>Coach Team</option>
                                                        {
                                                            (uInfo().type == 'WlAdmin' || (uInfo().type == 'Coach' && (uInfo().package_id == 3 || uInfo().package_id == 4 || uInfo().has_coach_license == 'Yes'))) && (
                                                                <optgroup label="Coach">
                                                                    {
                                                                        roles.coachPackages.map((row, i) => {
                                                                            return <option key={'Coach' + i} value={`Coach-${row.package_id}`}>{row.name}</option>
                                                                        })
                                                                    }
                                                                </optgroup>
                                                            )
                                                        }
                                                        {
                                                            uInfo().type == 'WlAdmin' && (
                                                                <optgroup label="Team Admin">
                                                                    {
                                                                        roles.staff.map((row, i) => {
                                                                            return <option key={'Staff' + i} value={`Staff-${row.id}`}>{row.name}</option>
                                                                        })
                                                                    }
                                                                </optgroup>

                                                            )
                                                        }


                                                    </select>
                                                </div>
                                            </>
                                        )
                                    }

                                </div>
                                <div className="modal-footer p-0">
                                    <div className='action-move-button p-2'>

                                        {/* <button type='button' className='btn btn-link' onClick={() => closeActionModal()}>
                                            Cancel
                                        </button> */}
                                        {subFileAction.share_type == 0 && (
                                            <button className={`btn btn-primary ${!share ? 'd-none' : ''}`} onClick={() => shareFile(subFileAction.id)}>
                                                Share
                                            </button>
                                        )}
                                        {subFileAction.share_type == 1 && (
                                            <button className={`btn btn-primary`} onClick={() => shareFile(subFileAction.id)}>
                                                Share
                                            </button>
                                        )}

                                    </div>
                                </div>
                            </div>
                        )
                    }
                </Modal>
            </div>
        </ResourcesContext.Provider>
    );
}

export default ResourcesDrive;