import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import {
    Row,
    Col,
    Container,
    Card,
    CardBody,
} from "reactstrap";
import { AvForm, AvField, AvInput, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox, AvGroup, AvFeedback } from "availity-reactstrap-validation";
import ThrusthubHeader from "./header.js";
import { Stepper, Step } from "react-form-stepper";
import { ApiUrl } from "../../config.js";
import { uToken } from "../../useToken.js";
import { toast, ToastContainer, Flip } from "react-toastify";
import Loader from "react-loader";
import swal from 'sweetalert';

const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const TrustHub = (props) => {
    const [step, SetStep] = useState(0);
    const [starterProfile, SetStarterProfile] = useState('No');
    const [haveSecondryProfile, sethaveSecondryProfile] = useState(false);
    const [countryCode, setCountryCode] = useState('+1');
    const [countryCode2, setCountryCode2] = useState('+1');
    const [loaded, setLoaded] = useState(true);
    const [hasAuthorized, setHasAuthorized] = useState(false);

    const [profile, setProfile] = useState([]);
    const queryParms = useQuery();

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        fetch(`${ApiUrl}` + "check/trusthub/profile", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setProfile(response.data.records);
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    const handleSubmit = async (event, values) => {
        if(queryParms.get('type')){
            values.request_type = queryParms.get('type');
        }
        setLoaded(false);
        fetch(`${ApiUrl}` + "trusthub/handleProduct/request", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
        .then((response) => response.json())
        .then((response) => { 
            setLoaded(true);
            if (response.status === true) { 
                swal({
                    title: 'Submitted successfully',
                    icon: "success",
                  });
                setTimeout(
                    () => props.history.push('/trusthub/overview'),
                    4000
                );
            }
        })
        .catch((error) => {
            console.error("Error:", error);
        });
    }

    return (
        <React.Fragment>
            <div className="trusthub" >
                <Container fluid>
                <ToastContainer/>
                    <Loader loaded={loaded}></Loader>
                    <Row>
                        <Col md={12}
                            lg={12}
                            xl={12} >
                            <ThrusthubHeader />

                            <Card>
                                <CardBody>
                                    {/* <div className="alert alert-info fade show" role="alert"><strong>Submit Valid Information for Secondary Business Profile</strong> </div> */}
                                    { profile && profile.account_status == 'twilio-approved' ? (

                                    <AvForm
                                        onValidSubmit={handleSubmit}
                                        className="needs-validation"
                                    >
                                         <Row>
                                        <Col md={12}>
                                            <div className="alert alert-info mt-2">
                                                <span><i className="fa fa-info-circle m-1"></i>
                                                 SHAKEN/STIR is a cost-free way to increase the answers when you call your customers. All outbound calls from eligible United States numbers assigned to your Approved Business Profile will have SHAKEN/STIR A-level attestation. Recipients will see “Caller Verified” on incoming calls on selected smartphones.
                                                </span>
                                            </div>
                                        </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>

                                                <button
                                                    className="btn btn-primary w-md waves-effect waves-light btn-block mt-4"
                                                    type="submit"
                                                >
                                                    Enable SHAKEN/STIR Trusted Calling
                                                </button>
                                                <button 
                                                    className="btn btn-danger w-md waves-effect waves-light btn-block mt-4 ml-5"
                                                    type="button"
                                                    onClick={() => props.history.push('/trusthub/overview')}
                                                >
                                                    Cancel
                                                </button>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                    
                                    ):(
                                        <Row>
                                        <Col md={12}>
                                            <div className="alert alert-warning mt-2">
                                                <span><i className="fa fa-warning-circle"></i> Apologies! Your business profile has not yet been created/approved to enable SHAKEN/STIR Trusted Calling. <a href={"/trusthub"}>Click here</a> to create a business profile..</span>
                                            </div>
                                        </Col>
                                        </Row>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>



            </div>

        </React.Fragment >
    );
};

export default TrustHub;
