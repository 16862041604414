import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import {
  AvForm,
  AvField,
  AvCheckbox,
  AvCheckboxGroup,
} from "availity-reactstrap-validation";
import {
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import AvRadioGroup from "availity-reactstrap-validation/lib/AvRadioGroup";
import AvRadio from "availity-reactstrap-validation/lib/AvRadio";
import AvGroup from "availity-reactstrap-validation/lib/AvGroup";
import swal from 'sweetalert';

export default class PackagesCreateUpdate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      is_calculator: false,
      is_sale: false,
      is_marketing: false,
      record: {
        descriptions: [],
        package_for: "",
        package_interval: "Monthly",
        package_type: "default",
        has_coach_license: "No",
        name: "",
        price: 0,
        trial_package: "No",
        showTrialAction: "No",
        voip_credits: 0,
        clients: 0,
        s_member_limit: 0,
        t_member_limit: 0,
        status: "Active",
        licence_limit: 0,
        monthly_credits: 0,
        clients_limit: 0,
        additional_licence_fee: 0,
        setup_fee_installment: 1,
      },
      page_name: "Edit Package",
      roles: [],
      inputList: [],
    };
    this.role = { id: this.props.role.id };
  }

  componentDidMount() {
    this.state.id != null && this.getData();
  }

  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/package", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          var features_selected = response.data.record.features_available;
          if (features_selected !== null) {
            features_selected.forEach((product) => {
              if (product == "Sales") {
                this.setState({
                  is_sale: true,
                });
              }
              if (product == "Marketing") {
                this.setState({
                  is_marketing: true,
                });
              }
              if (product == "Calculator") {
                this.setState({
                  is_calculator: true,
                });
              }
            });
          }
          //console.log(response.data.record.description, 'inputList')
          this.setState({
            record: response.data.record,

            inputList: response.data.record.description,
          });
          this.onChangeTrialAction(this.state.record.trial_package);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = async (event, values) => {

    let check = false;
    var filteredArray = this.state.inputList.filter(function (itm) {
      if (itm.description_meta == '') {
        swal({
          title: "Error",
          text: "Description: cannot be blank",
          icon: "warning",
        });

        check = true;
      }

    });

    values.description = JSON.stringify(this.state.inputList);
    if (this.state.id) {
      values.id = this.state.id ? this.state.id : -1;
    }

    if (!check) {
      fetch(`${ApiUrl}` + "edit/package", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify(values),
      })
        .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((data) => {
          if (data.status === true) {
            if (this.state.id == null) {
              this.form && this.form.reset();
              this.setState({
                inputList: [],
              });
            }
            this.props.history.goBack();

            // this.getData();
            //              toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });    

          } else {
            toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        //Then with the error genereted...
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.inputList];
    list[index][name] = value;
    this.setState({ inputList: list });
  };

  // handle click event of the Remove button
  handleRemoveClick = (index) => {
    const list = [...this.state.inputList];
    list.splice(index, 1);
    this.setState({ inputList: list });
  };

  // handle click event of the Add button
  onChangeCalculator = () => {
    this.setState((initialState) => ({
      is_calculator: !initialState.is_calculator,
    }));
  };
  onChangeSales = () => {
    this.setState((initialState) => ({
      is_sale: !initialState.is_sale,
    }));
  };
  onChangeMarketing = () => {
    this.setState((initialState) => ({
      is_marketing: !initialState.is_marketing,
    }));
  };
  onChangeTrialAction = (value) => {
    this.setState({
      showTrialAction: value
    })
    // var x = document.getElementById("trial_fields");
    // if (value == "Yes") {
    //   x.style.display = "block";
    // } else {
    //   x.style.display = "none";
    // }
  };
  handleAddClick = () => {
    this.setState({
      inputList: [
        ...this.state.inputList,
        { key: "description_meta", description_meta: "" },
      ],
    });
  };

  addLimit = (key, name, value) => {
    if (value > 0) {
      const target = this.state.inputList.find(
        (element) => element.key === key
      );

      if (target) {
        target.description_meta = value + "  " + name;
      } else {
        let itemObj = {
          key: key,
          description_meta: value + "  " + name,
        };
        this.state.inputList.push(itemObj);
      }
    } else {
      this.setState({
        inputList: this.state.inputList.filter((item) => item.key != key),
      });
    }

    this.forceUpdate();

    //   const retail = this.state.inputList.filter(iterator => iterator.key == key);
    //   console.log(retail, 's')
    //   if (retail.length < 1) {
    //     this.setState({ inputList: [...this.state.inputList, { key: key, name: value + ' - ' + name }] });
    //   } else {
    //     this.setState({ inputList: [...retail, { key: key, name: value + ' - ' + name }] });
    //   }
    // }
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {this.state.page_name}
            Tables
          </title>
        </Helmet>

        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.page_name} </h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#"> {this.state.page_name} </Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                type="button"
                color="secondary"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>

        <ToastContainer />
        <Row>
          <Col className="col-12">
            <Card className="package-add">
              <AvForm
                onValidSubmit={this.handleSubmit}
                model={this.state.record}
                ref={(c) => (this.form = c)}
                className="needs-validation"
              >
                <ModalBody>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <AvField
                          name="name"
                          value={this.state.record.name}
                          label="Title"
                          className="form-control"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <AvField
                          name="sub_title"
                          value={this.state.record.sub_title}
                          label="Sub Title"
                          className="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  {this.state.record.package_for != "AccountCredit" && (
                    <div className="mb-3">
                      <AvField
                        className="select form-control"
                        type="select"
                        value={this.state.record.package_interval}
                        name="package_interval"
                        label="Interval"
                        required
                      >
                        <option value={"Monthly"}> Monthly </option>
                        <option value={"Yearly"}> Yearly </option>
                      </AvField>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label> Price </label>
                        <div className="group-addnon">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="validationTooltipUsernamePrepend"
                              >
                                $
                              </span>

                            </div>
                            <AvField
                              name="price"
                              id="price"
                              type="number"
                              value={this.state.record.price}
                              validate={{
                                required: { value: true, errorMessage: 'This field is invalid' },
                                pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                              }} />

                          </div>
                        </div>
                      </div>
                    </div>

                    {this.state.record.package_for == "Coach" && (
                      <>
                        <div className="col-md-2 mb-3">
                          <AvField
                            className="select form-control"
                            type="select"
                            value={this.state.record.setup_fee_installment}
                            name="setup_fee_installment"
                            label="Setup Fee Monthly Installment"
                          >
                            <option value={"0"}> 0 </option>
                            <option value={"1"}> 1 </option>
                            <option value={"2"}> 2 </option>
                            <option value={"3"}> 3 </option>
                            <option value={"4"}> 4 </option>
                            <option value={"5"}> 5 </option>
                            <option value={"6"}> 6 </option>
                            <option value={"7"}> 7 </option>
                            <option value={"8"}> 8 </option>
                            <option value={"9"}> 9 </option>
                            <option value={"10"}> 10 </option>
                          </AvField>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label> Setup Fee </label>
                          <div className="group-addnon">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="validationTooltipUsernamePrepend"
                                >
                                  $
                                </span>

                              </div>
                              <AvField
                                name="setup_price"
                                id="setup_price"
                                type="number"
                                value={this.state.record.setup_price}
                                validate={{
                                  required: { value: true, errorMessage: 'This field is invalid' },
                                  pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                                }} />

                            </div>
                          </div>
                        </div>
                      </>
                    )
                    }

                  </div>
                  {this.role.id !== 1 &&
                    this.state.record.package_for != "CoachPartner" &&
                    this.state.record.package_for != "AccountCredit" && (
                      <div className="mb-3 d-none">
                        <label> Trial </label>
                        <AvRadioGroup
                          value={this.state.record.trial_package}
                          inline
                          name="trial_package"
                          onChange={(e) =>
                            this.onChangeTrialAction(e.target.value)
                          }
                        >
                          <AvRadio label="Yes" value="Yes" />
                          <AvRadio label="No" value="No" />
                        </AvRadioGroup>
                      </div>
                    )}

                  {this.state.record.package_for == "Coach" && (
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label> Coach License</label>
                          <AvRadioGroup
                            value={this.state.record.has_coach_license}
                            inline
                            name="has_coach_license"
                            disabled
                            onChange={(e) => {
                              this.setState({
                                record: {
                                  ...this.state.record,
                                  has_coach_license: e.target.value
                                }
                              })
                            }}
                          >
                            <AvRadio label="Yes" value="Yes" />
                            <AvRadio label="No" value="No" />
                          </AvRadioGroup>
                        </div>
                      </div>
                      {
                        this.state.record.has_coach_license == 'Yes' && (
                          <>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <AvField
                                  name="licence_limit"
                                  label="Coach License Limit"
                                  className="form-control"
                                  type="number"
                                  value={this.state.record.licence_limit}
                                  onChange={(e) =>
                                    this.addLimit(
                                      "number_of_coaches",
                                      "Coach License Limit",
                                      e.target.value
                                    )
                                  }
                                  validate={{
                                    required: { value: true, errorMessage: 'This field is invalid' },
                                    pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <AvField
                                  name="additional_licence_fee"
                                  label="Per Additional coach License fee monthly"
                                  className="form-control"
                                  type="number"
                                  value={this.state.record.additional_licence_fee}
                                  validate={{
                                    required: { value: true, errorMessage: 'This field is invalid' },
                                    pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                                  }}
                                />
                              </div>
                            </div>
                          </>

                        )
                      }
                    </div>
                  )}
                  {this.state.record.package_for != "CoachPartner" &&
                    this.state.record.package_for != "AccountCredit" &&
                    this.state.showTrialAction == 'Yes' && (
                      <div id="trial_fields d-none">
                        <div className="mb-3">
                          <AvField
                            name="trial_duration"
                            label="Trial Duration"
                            className="form-control"
                            type="number"
                            value={this.state.record.trial_duration}
                          // validate={{
                          //   pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                          // }}
                          />
                        </div>

                        <div className="mb-3">
                          <label> Trial Fee </label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="validationTooltipUsernamePrepend"
                              >
                                $
                              </span>
                            </div>
                            <AvInput
                              name="trial_fee"
                              id="trial_fee"
                              value={this.state.record.trial_fee}
                            // validate={{
                            //   pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                            // }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  {this.role.id !== 1 &&
                    this.state.record.package_for != "CoachPartner" &&
                    this.state.record.package_for != "AccountCredit" && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                          <label>Monthly Credits</label>
                            <div className="group-addnon">
                              <div className="input-group">
                                <div class="input-group-prepend"><span class="input-group-text">$</span></div>
                                <div class="form-group">
                                  <AvField
                                    name="monthly_credits"
                                    className="form-control"
                                    type="number"
                                    value={(this.state.record.monthly_credits / 100)}
                                    onChange={(e) =>
                                      this.addLimit(
                                        "monthly_credits",
                                        "($) Monthly Credits",
                                        e.target.value
                                      )
                                    }
                                    validate={{
                                      required: { value: true, errorMessage: 'This field is invalid' },
                                      pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <AvField
                              name="clients_limit"
                              label="Leads Limit"
                              className="form-control"
                              type="number"
                              value={this.state.record.clients_limit}
                              onChange={(e) =>
                                this.addLimit("clients", "Leads", e.target.value)
                              }
                              validate={{
                                required: { value: true, errorMessage: 'This field is invalid' },
                                pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                  {this.role.id !== 1 &&
                    this.state.record.package_for != "AccountCredit" && (
                      <div className="mb-3 d-none">
                        <AvField
                          name="coach_partner_limit"
                          label="Coach Partner's Limit"
                          className="form-control"
                          type="number"
                          value={this.state.record.coach_partner_limit}
                          onChange={(e) =>
                            this.addLimit(
                              "coach_partner_limit",
                              "Coach Partner Limit",
                              e.target.value
                            )
                          }
                        // validate={{
                        //   required: { value: true, errorMessage: 'This field is invalid' },
                        //   pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                        // }}
                        />
                      </div>
                    )}

                  {this.state.record.package_for != "CoachPartner" &&
                    this.state.record.package_for != "AccountCredit" && (
                      <div className="mb-3 d-none">
                        <label> Type </label>
                        <AvRadioGroup
                          value={this.state.record.package_type}
                          defaultValue={this.state.record.package_type}
                          inline
                          name="package_type"
                        >
                          <AvRadio label="Default" value="default" />
                          <AvRadio label="Custom" value="custom" />
                        </AvRadioGroup>
                      </div>
                    )}

                  {this.role.id == 1 &&
                    this.state.record.package_for != "Coach" &&
                    this.state.record.package_for != "AccountCredit" && (
                      <div className="mb-3">
                        <AvField
                          name="number_of_coaches"
                          label="Number of Coaches"
                          className="form-control"
                          type="number"
                          value={this.state.record.number_of_coaches}
                          onChange={(e) =>
                            this.addLimit(
                              "number_of_coaches",
                              "Coach License",
                              e.target.value
                            )
                          }
                          validate={{
                            pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                          }}
                        />
                      </div>
                    )}

                  {this.state.record.package_for == "AccountCredit" && (
                    <div className="row d-none">
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label> Amount</label>
                          <div className="group-addnon">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="validationTooltipUsernamePrepend"
                                >
                                  ¢
                                </span>
                              </div>
                              <AvField
                                name="credit_amount"
                                className="form-control"
                                type="number"
                                validate={{
                                  // required: { value: true, errorMessage: 'This field is invalid' },
                                  pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                                }}
                                value={this.state.record.credit_amount}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  )}

                  {this.role.id !== 1 && (
                    <div className="mb-3">
                      <label> Description </label>
                      <Button
                        type="button"
                        className="btn btn-sm info ml-5"
                        onClick={this.handleAddClick}
                      >
                        <i class="mdi mdi-plus"></i>

                      </Button>
                      {this.state.inputList.map((x, i) => {
                        return (
                          <div key={i} className="mb-3">
                            {x.key == "voip_credits" ? (
                              <AvInput
                                name="description_meta"
                                value={x.description_meta}
                                className="form-control"
                                readOnly
                              />
                            ) : x.key == "clients" ? (
                              <AvInput
                                name="description_meta"
                                value={x.description_meta}
                                className="form-control"
                                readOnly
                              />
                            ) : x.key == "number_of_coaches" ? (
                              <AvInput
                                name="description_meta"
                                value={x.description_meta}
                                className="form-control"
                                readOnly
                              />
                            ) : x.key == "monthly_credits" ? (
                              <AvInput
                                name="description_meta"
                                value={x.description_meta}
                                className="form-control"
                                readOnly
                              />
                            ) : x.key == "clients_limit" ? (
                              <AvInput
                                name="description_meta"
                                value={x.description_meta}
                                className="form-control"
                                readOnly
                              />
                            ) : x.key == "coach_partner_limit" ? (
                              <AvInput
                                name="description_meta"
                                value={x.description_meta}
                                className="form-control"
                                readOnly
                              />
                            ) : x.key == "s_member_limit" ? (
                              <AvInput
                                name="description_meta"
                                value={x.description_meta}
                                readOnly
                                className="form-control"
                              />
                            ) : x.key == "t_member_limit" ? (
                              <AvInput
                                name="description_meta"
                                value={x.description_meta}
                                readOnly
                                className="form-control"
                              />
                            ) : (
                              <div className="input-group">
                                <AvInput
                                  name="description_meta"
                                  value={x.description_meta}
                                  label="Add Description"
                                  onChange={(e) => this.handleInputChange(e, i)}
                                  className="form-control"

                                />
                                <div className="input-group-prepend">
                                  {this.state.inputList.length && (
                                    <button
                                      className="btn btn-md btn-danger ml-5"
                                      type="button"
                                      onClick={() => this.handleRemoveClick(i)}
                                    >
                                      Remove
                                    </button>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {this.role.id !== 1 &&
                    this.state.record.package_for != "CoachPartner" &&
                    this.state.record.package_for != "AccountCredit" && (
                      <div className="mb-3 d-none">
                        <label>Features Available </label>
                        <AvCheckboxGroup
                          inline
                          name="features_available"
                          label=""
                          value={this.state.record.features_available}
                        >
                          <AvCheckbox
                            checked={this.state.is_calculator}
                            customInput
                            label="Calculator"
                            value="Calculator"
                            onChange={this.onChangeCalculator}
                          />
                          <AvCheckbox
                            checked={this.state.is_sale}
                            customInput
                            label="Sales"
                            value="Sales"
                            onChange={this.onChangeSales}
                          />
                          <AvCheckbox
                            checked={this.state.is_marketing}
                            onChange={this.onChangeMarketing}
                            customInput
                            label="Marketing"
                            value="Marketing"
                          />
                        </AvCheckboxGroup>
                      </div>
                    )}
                  <div className="mb-3">
                    <AvField
                      className="select form-control"
                      type="select"
                      value={this.state.record.status}
                      id="s"
                      name="status"
                      label="Status"
                    >
                      <option value={"Active"}> Active </option>
                      <option value={"Inactive"}> Inactive </option>
                    </AvField>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => this.props.history.goBack()}
                  >
                    Cancel
                  </Button>
                  <button
                    className="btn btn-primary waves-effect waves-light"
                    type="submit"
                  >
                    Submit
                  </button>
                </ModalFooter>
              </AvForm>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
