import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

export default function Accept(props) {

    
    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        accept: '.csv',
        maxFiles: 1
    });


    useEffect(() => {
        if(acceptedFiles.length > 0){
            props.fileSet(acceptedFiles)
        }
    },[acceptedFiles.length > 0])

    const acceptedFileItems = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    return (
        <section className="">
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()}  multiple={false}/>
                <p>Drag 'n' drop  file here, or click to select file</p>
            </div>
            <aside>
                <ul>{acceptedFileItems}</ul>
            </aside>
        </section>
    );
}