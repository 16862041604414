import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import { ApiUrl, AttachementsUrl } from "../../../config";
import Context from './Context';
import swal from "sweetalert";
import { dcrypt, bcrypt, uInfo, uToken } from "../../../useToken";
import {
    Modal,
    Card,
    CardBody,
    Button,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ImageCropper from "./ImageCropper";

const Box10 = (props) => {
    const params = React.useContext(Context);
    const [modal, setModal] = useState(false);
    const [record, setRecord] = useState({});
    const [imageData, setImageData] = useState({
        id: '',
        url: '',
    });

    const [inputFields, setInputFields] = useState(
        { upload_book_cover: '' }
    );
    useEffect(() => {
        getData();
    }, []);
    const getData = () => {
        fetch(`${ApiUrl}` + "get/systemForm/resourceInfo", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: params.form_data.id, user_id: uInfo().user_id }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setRecord(response.data.record);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        submitData.append('user_id', uInfo().user_id);
        submitData.append('id', params.form_data.id);
        if (inputFields.upload_book_cover != '') {
            submitData.append('upload_book_cover_base_64', inputFields.upload_book_cover);
        }



        fetch(`${ApiUrl}` + "update/system/form", {
            method: "POST",
            body: submitData,
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    swal({
                        title: "Action performed successfully",
                        icon: "success",
                    });
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const changeHandlerImg = (e, id) => {
        if (e.target.files[0].type.match('image.*')) {
            const preview = document.querySelector('#' + id);
            var reader = new FileReader();
            reader.onload = () => {
                preview.src = reader.result;
                imageCropper(id, reader.result)
            };
            reader.readAsDataURL(e.target.files[0]);
        } else {
            swal({
                title: 'Olny images allowed',
                icon: "warning",
            });
        }
    };

    const imageCropper = (id, url) => {
        // var url = document.getElementById(id).src;
        setImageData({
            id: id,
            url: url
        });
        setTimeout(() => {
            setModal(true);
        }, 200);
    }


    const imageCropperSet = (id, url) => {
        const preview = document.querySelector('#' + id);
        preview.src = url;

        setInputFields({ ...inputFields, [id]: url })

        setModal(false);
    }

    const closeModal = () => {
        setInputFields({ ...inputFields, [imageData.id]: imageData.url })
        setModal(false);
    }
    return (
        <div>
            <div className="mt-1">
                <div className="d-flex align-items-center gap-2 StepHeading">
                    <div className="StepNumber">{params.form_data.id} <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">{params.form_data.name}</h4>
                </div>
                <form method="post" onSubmit={handleSubmit}
                    encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                    <div className="p-1 mt-1">
                        <div className="page-content-box">
                            <div className="WizredForm">
                                <div className="form-group mt-3">
                                    <label className="row">What is the title of your new book?</label>
                                    <small>Provide a title for your book in 50 characters or less. You can do it!</small>
                                    <input required name="book_title" defaultValue={record.book_title ?? ''} className="form-control no-border px-0" type="text" placeholder="Type your answer here..."></input>
                                </div>
                                <div className="form-group mt-4">
                                    <label className="row">What is the subtitle of your new book?</label>
                                    <small>Provide a sub-title for your book in 100 characters or less. Less is more!</small>
                                    <input required name="book_subtitle" defaultValue={record.book_subtitle ?? ''} className="form-control no-border px-0" type="text" placeholder="Type your answer here..."></input>
                                </div>
                                {/* <div className="form-group mt-4">
                                    <div className="radio-box mt-3">
                                        <label>Do you have an own book cover?</label>
                                        <br />
                                        {record.own_book_cover && (
                                        <div class="form-check form-check-inline">
                                            <input name="own_book_cover" defaultChecked={record.own_book_cover == 'Yes' ? true : false}
                                                onChange={() => 
                                                    setRecord((prevState) => ({
                                                        ...prevState,
                                                        ['own_book_cover']: 'Yes',
                                                    }))
                                                }
                                                class="form-check-input" type="radio" id="inlineRadio1" value="Yes" />
                                            <label class="form-check-label" for="inlineRadio1">Yes</label>
                                        </div>
                                        )}
                                        {record.own_book_cover && (
                                            <div class="form-check form-check-inline">
                                                <input name="own_book_cover" defaultChecked={record.own_book_cover == 'No' ? true : false}
                                                    onChange={() => 
                                                        setRecord((prevState) => ({
                                                            ...prevState,
                                                            ['own_book_cover']: 'No',
                                                        }))
                                                    }
                                                    class="form-check-input" type="radio" id="inlineRadio2" value="No" />
                                                <label class="form-check-label" for="inlineRadio2">No</label>
                                            </div>
                                        )}
                                    </div>
                                </div> */}


                                <div className="form-group mt-4">
                                    <label>Upload your 3D book Cover <note className="text-muted">(Preferably size 450 x 638)</note></label>
                                    <input name="upload_book_cover" className="form-control no-border px-0 mt-1"
                                        accept="image/*"
                                        type="file" placeholder="https://"
                                        onChange={(e) => changeHandlerImg(e, 'upload_book_cover')}
                                    />
                                    <img width={'50'} id="upload_book_cover" src={record.book_cover_image_3d && record.book_cover_image_3d} className="img img-thumbnail mt-1" alt="Not Found" />
                                </div>

                                <div className="form-group mt-4">
                                    <label>Select Image For Website Book Cover</label>
                                    <div class="row parent themeSelection" style={{ overflow: "auto", height: "200px" }}>
                                        {record.book_cover && (
                                            <div class="row">
                                                <div class='col-sm-4'>
                                                    <div className="theme-img justify-content-center">
                                                        <input type="radio" name="book_cover" defaultChecked={record.book_cover == '1.png' ? true : false} id="book_cover_image1" class="d-none bgimgbgchk" value="1.png" />
                                                        <label for="book_cover_image1">
                                                            <div className="hero-img-area">
                                                                <img src={`${AttachementsUrl}/default/images/book-cover/1.png`} alt="Image 1" />
                                                            </div>
                                                            <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                            </div>
                                                            <div className="img-footer">
                                                                <label className="img-box">A</label>
                                                                <p>Choice 1</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <a className="d-block text-center mt-2" href={`${AttachementsUrl}/default/images/book-cover/1.png`} target="_blank">Preview</a>

                                                </div>
                                                <div class='col-sm-4'>
                                                    <div className="theme-img justify-content-center">
                                                        <input type="radio" name="book_cover" defaultChecked={record.book_cover == '2.png' ? true : false} id="book_cover_image2" class="d-none bgimgbgchk" value="2.png" />
                                                        <label for="book_cover_image2">
                                                            <div className="hero-img-area">
                                                                <img src={`${AttachementsUrl}/default/images/book-cover/2.png`} alt="Image 1" />
                                                            </div>
                                                            <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                            </div>
                                                            <div className="img-footer">
                                                                <label className="img-box">B</label>
                                                                <p>Choice 2</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <a className="d-block text-center mt-2" href={`${AttachementsUrl}/default/images/book-cover/2.png`} target="_blank">Preview</a>

                                                </div>

                                                <div class='col-sm-4'>
                                                    <div className="theme-img justify-content-center">
                                                        <input type="radio" name="book_cover" defaultChecked={record.book_cover == '3.png' ? true : false} id="book_cover_image3" class="d-none bgimgbgchk" value="3.png" />
                                                        <label for="book_cover_image3">
                                                            <div className="hero-img-area">
                                                                <img src={`${AttachementsUrl}/default/images/book-cover/3.png`} alt="Image 1" />
                                                            </div>
                                                            <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                            </div>
                                                            <div className="img-footer">
                                                                <label className="img-box">C</label>
                                                                <p>Choice 3</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <a className="d-block text-center mt-2" href={`${AttachementsUrl}/default/images/book-cover/3.png`} target="_blank">Preview</a>
                                                </div>

                                                <div class='col-sm-4'>
                                                    <div className="theme-img justify-content-center">
                                                        <input type="radio" name="book_cover" defaultChecked={record.book_cover == '4.png' ? true : false} id="book_cover_image4" class="d-none bgimgbgchk" value="4.png" />
                                                        <label for="book_cover_image4">
                                                            <div className="hero-img-area">
                                                                <img src={`${AttachementsUrl}/default/images/book-cover/4.png`} alt="Image 1" />
                                                            </div>
                                                            <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                            </div>
                                                            <div className="img-footer">
                                                                <label className="img-box">D</label>
                                                                <p>Choice 4</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <a className="d-block text-center mt-2" href={`${AttachementsUrl}/default/images/book-cover/4.png`} target="_blank">Preview</a>
                                                </div>

                                                <div class='col-sm-4'>
                                                    <div className="theme-img justify-content-center">
                                                        <input type="radio" name="book_cover" defaultChecked={record.book_cover == '5.png' ? true : false} id="book_cover_image5" class="d-none bgimgbgchk" value="5.png" />
                                                        <label for="book_cover_image5">
                                                            <div className="hero-img-area">
                                                                <img src={`${AttachementsUrl}/default/images/book-cover/5.png`} alt="Image 1" />
                                                            </div>
                                                            <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                            </div>
                                                            <div className="img-footer">
                                                                <label className="img-box">E</label>
                                                                <p>Choice 5</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <a className="d-block text-center mt-2" href={`${AttachementsUrl}/default/images/book-cover/5.png`} target="_blank">Preview</a>
                                                </div>

                                                <div class='col-sm-4'>
                                                    <div className="theme-img justify-content-center">
                                                        <input type="radio" name="book_cover" defaultChecked={record.book_cover == '6.png' ? true : false} id="book_cover_image6" class="d-none bgimgbgchk" value="6.png" />
                                                        <label for="book_cover_image6">
                                                            <div className="hero-img-area">
                                                                <img src={`${AttachementsUrl}/default/images/book-cover/6.png`} alt="Image 1" />
                                                            </div>
                                                            <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                            </div>
                                                            <div className="img-footer">
                                                                <label className="img-box">F</label>
                                                                <p>Choice 6</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <a className="d-block text-center mt-2" href={`${AttachementsUrl}/default/images/book-cover/6.png`} target="_blank">Preview</a>
                                                </div>

                                                <div class='col-sm-4'>
                                                    <div className="theme-img justify-content-center">
                                                        <input type="radio" name="book_cover" defaultChecked={record.book_cover == '7.png' ? true : false} id="book_cover_image7" class="d-none bgimgbgchk" value="7.png" />
                                                        <label for="book_cover_image7">
                                                            <div className="hero-img-area">
                                                                <img src={`${AttachementsUrl}/default/images/book-cover/7.png`} alt="Image 1" />
                                                            </div>
                                                            <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                            </div>
                                                            <div className="img-footer">
                                                                <label className="img-box">F</label>
                                                                <p>Choice 7</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <a className="d-block text-center mt-2" href={`${AttachementsUrl}/default/images/book-cover/7.png`} target="_blank">Preview</a>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-sm btn-primary mt-2"> Ok &nbsp;<i className="fas fa-check"></i></button>
                </form>
            </div>
            <Modal
                isOpen={modal}
                fade={true}
                size="lg"
                toggle={() => closeModal()}
                centered={true}
                backdrop={'static'}
                style={{ height: "calc(156vh - 186px) !important" }}
            >
                <div className="">
                    <div className='modal-header'>
                        <div className='modal-title'>
                            Crop Image
                        </div>
                        {/* <button onClick={() => closeModal()} className="mb-3 btn btn-link pull-right BtnCenterClose"><i className="fas fa-times"></i></button> */}
                    </div>

                    <ImageCropper closeModal={closeModal} imageCropperSet={imageCropperSet} data={imageData} />



                </div>
            </Modal>
        </div>
    );
};

export default Box10;