import React from "react";
import drive from "../../assets/images/resource-drive/myDrive.png";
import shared from "../../assets/images/resource-drive/shared.png";
import starred from "../../assets/images/resource-drive/starred.png";
import cloud from "../../assets/images/resource-drive/cloud.png";
import "../../assets/css/resource-drive/SideBar.css";
import { Link } from "react-router-dom";
import { bcrypt, uInfo } from "../../useToken";
import Dropdown from 'react-bootstrap/Dropdown';
import ResourcesContext from "./ResourcesContext";

export default function SideBar() {
  const params = React.useContext(ResourcesContext);

  return (
    <>
      <div id="sideBar">

        <div className="drive-action-button">
          <button title="Add new folder"
            className="btn text-dark"
            onClick={() => params.Action('SideBar', 1)}
          >
            <i className="fas fa-folder-plus"></i>
          </button>
          <button
            title="Add new files"
            className="btn text-dark"
            onClick={() => params.Action('SideBar', 2)}>
            <i className="fas fa-file-upload"></i>
          </button>
        </div>

        {/* <Dropdown onSelect={(e) => params.Action('SideBar', e)}>
          <Dropdown.Toggle id="linkBtn" variant="secondary">
            <i className="fas fa-hdd text-dark"></i>
            <p>New</p>
          </Dropdown.Toggle>

          <Dropdown.Menu variant="">
            <Dropdown.Item eventKey="1">New Folder</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="2">Upload File</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}

        {
          (uInfo().type == 'WlAdmin' || uInfo().type == 'Staff') ?
            <div id="sideBarOpt">
              <Link to={`/resourcesDrive/my-resources/${bcrypt(uInfo().user_id)}`}>
                <div className={`sideBarOptions ${params.type == 'my-resources' && ('activeSideOpt')}`}>
                  <i className="fas fa-hdd text-dark"></i>
                  <h3 className="m-0">My Resources</h3>
                </div>
              </Link>

              <Link to={`/resourcesDrive/shared-with-me/${bcrypt(uInfo().user_id)}`}>
                <div className={`sideBarOptions ${params.type == 'shared-with-me' && ('activeSideOpt')}`}>
                  <i className="fas fa-share-alt text-dark"></i>
                  <h3 className="m-0">Shared with me</h3>
                </div>
              </Link>

              <Link to={'/resourcesDrive/team-admin'}>
                <div className={`sideBarOptions ${params.type == 'team-admin' && ('activeSideOpt')}`}>
                  {/* <img src={shared} alt="Reload page" className="opacity" /> */}
                  <i className="fas fa-users text-dark"></i>
                  <h3 className="m-0">Team Admin</h3>
                </div>
              </Link>

              <Link to={'/resourcesDrive/diamond-coach'}>
                <div className={`sideBarOptions ${params.type == 'diamond-coach' && ('activeSideOpt')}`}>
                  {/* <img src={shared} alt="Reload page" className="opacity" /> */}
                  <i className="fas fa-gem text-dark"></i>
                  <h3 className="m-0">Diamond Coach </h3>
                </div>
              </Link>

              <Link to={'/resourcesDrive/coach'}>
                <div className={`sideBarOptions ${params.type == 'coach' && ('activeSideOpt')}`}>
                  {/* <img src={shared} alt="Reload page" className="opacity" /> */}
                  <i className="fas fa-user-tie text-dark"></i>
                  <h3 className="m-0">Coach</h3>

                </div>
              </Link>

              <Link to={'/resourcesDrive/coach-team'}>
                <div className={`sideBarOptions ${params.type == 'coach-team' && ('activeSideOpt')}`}>
                  {/* <img src={shared} alt="Reload page" className="opacity" /> */}
                  <i className="fas fa-user-friends text-dark"></i>
                  <h3 className="m-0">Coach Team</h3>
                </div>
              </Link>

              <Link to={'/resourcesDrive/client'}>
                <div className={`sideBarOptions ${params.type == 'client' && ('activeSideOpt')}`}>
                  {/* <img src={shared} alt="Reload page" className="opacity" /> */}
                  <i className="fas fa-user-alt text-dark"></i>

                  <h3 className="m-0">Client</h3>
                </div>
              </Link>
            </div>
            :
            uInfo().type == 'Coach' ?
              <div id="sideBarOpt">
                <Link to={`/resourcesDrive/my-resources/${bcrypt(uInfo().user_id)}`}>
                  <div className={`sideBarOptions ${params.type == 'my-resources' && ('activeSideOpt')}`}>
                    <i className="fas fa-hdd text-dark"></i>
                    <h3 className="m-0">My Resources</h3>
                  </div>
                </Link>

                <Link to={`/resourcesDrive/shared-with-me/${bcrypt(uInfo().user_id)}`}>
                  <div className={`sideBarOptions ${params.type == 'shared-with-me' && ('activeSideOpt')}`}>
                    <i className="fas fa-share-alt text-dark"></i>
                    <h3 className="m-0">Shared with me</h3>
                  </div>
                </Link>


                {
                  uInfo().package_id == 4 && (
                    <Link to={'/resourcesDrive/coach'}>
                      <div className={`sideBarOptions ${params.type == 'coach' && ('activeSideOpt')}`}>
                        {/* <img src={shared} alt="Reload page" className="opacity" /> */}
                        <i className="fas fa-user-tie text-dark"></i>
                        <h3 className="m-0">Coach</h3>

                      </div>
                    </Link>
                  )
                }



                <Link to={'/resourcesDrive/coach-team'}>
                  <div className={`sideBarOptions ${params.type == 'coach-team' && ('activeSideOpt')}`}>
                    {/* <img src={shared} alt="Reload page" className="opacity" /> */}
                    <i className="fas fa-user-friends text-dark"></i>
                    <h3 className="m-0">Coach Team</h3>
                  </div>
                </Link>

                <Link to={'/resourcesDrive/client'}>
                  <div className={`sideBarOptions ${params.type == 'client' && ('activeSideOpt')}`}>
                    {/* <img src={shared} alt="Reload page" className="opacity" /> */}
                    <i className="fas fa-user-alt text-dark"></i>

                    <h3 className="m-0">Client</h3>
                  </div>
                </Link>
              </div>
              :
              <div id="sideBarOpt">
              </div>

        }


        <div id="sideBarOpt" className="d-none">
          <Link to={`/resourcesDrive/my-resources/${bcrypt(uInfo().user_id)}`}>
            <div className={`sideBarOptions ${params.type == 'my-resources' && ('activeSideOpt')}`}>
              <i className="fas fa-hdd text-dark"></i>
              <h3 className="m-0">My Resources</h3>
            </div>
          </Link>

          <Link to={`/resourcesDrive/shared-with-me/${bcrypt(uInfo().user_id)}`}>
            <div className={`sideBarOptions ${params.type == 'shared-with-me' && ('activeSideOpt')}`}>
              <i className="fas fa-share-alt text-dark"></i>
              <h3 className="m-0">Shared with me</h3>
            </div>
          </Link>

          <Link to={'/resourcesDrive/team-admin'}>
            <div className={`sideBarOptions ${params.type == 'team-admin' && ('activeSideOpt')}`}>
              {/* <img src={shared} alt="Reload page" className="opacity" /> */}
              <i className="fas fa-users text-dark"></i>
              <h3 className="m-0">Team Admin</h3>
            </div>
          </Link>

          <Link to={'/resourcesDrive/diamond-coach'}>
            <div className={`sideBarOptions ${params.type == 'diamond-coach' && ('activeSideOpt')}`}>
              {/* <img src={shared} alt="Reload page" className="opacity" /> */}
              <i className="fas fa-gem text-dark"></i>
              <h3 className="m-0">Diamond Coach </h3>
            </div>
          </Link>

          <Link to={'/resourcesDrive/coach'}>
            <div className={`sideBarOptions ${params.type == 'coach' && ('activeSideOpt')}`}>
              {/* <img src={shared} alt="Reload page" className="opacity" /> */}
              <i className="fas fa-user-tie text-dark"></i>
              <h3 className="m-0">Coach</h3>

            </div>
          </Link>

          <Link to={'/resourcesDrive/coach-team'}>
            <div className={`sideBarOptions ${params.type == 'coach-team' && ('activeSideOpt')}`}>
              {/* <img src={shared} alt="Reload page" className="opacity" /> */}
              <i className="fas fa-user-friends text-dark"></i>
              <h3 className="m-0">Coach Team</h3>
            </div>
          </Link>

          <Link to={'/resourcesDrive/client'}>
            <div className={`sideBarOptions ${params.type == 'client' && ('activeSideOpt')}`}>
              {/* <img src={shared} alt="Reload page" className="opacity" /> */}
              <i className="fas fa-user-alt text-dark"></i>

              <h3 className="m-0">Client</h3>
            </div>
          </Link>
        </div>

        {/* <div id="storageInfo">
          <div className="sideBarOptions">
            <img src={cloud} alt="Reload page" className="opacity" />
            <h3>Storage</h3>
          </div>

          <div className="sideBarOptions">
            <div id="storageLoader">
              <div id="preLoader"></div>
            </div>
          </div>

          <div id="storageNumericalInfo">
            <p>190 GB of 300 GB Used</p>
          </div>

          <button id="buyStorage">Buy storage</button>
        </div> */}

      </div>
    </>
  );
}
