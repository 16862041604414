import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  CardImg,
  CardText,
  ModalHeader,
  Nav,
  CardImgOverlay,
  NavItem,
  NavLink,
  Input,
  TabContent,
  TabPane,
  ModalBody,
  ModalFooter,
  Collapse,
} from "reactstrap";
import Badge from "react-bootstrap/Badge";
import Breadcrumb from "../../../../components/Common/Breadcrumb";
import SectionAccordion from "./SectionAccordion";
import ProductFaqs from "./ProductFaqs";
import ProfileSettings from "../../../Authentication/profile-sidebar";
//Import Action to copy breadcrumb items from local state to redux state
import "react-toastify/dist/ReactToastify.css";
import TrainingProductsSidebar from "./trainingproducts-sidebar";
import ReactPlayer from "react-player";
import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  dcrypt,
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../../useToken";
import { ApiUrl, ProductName, AttachementsUrl } from "../../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import ProgressBar from 'react-bootstrap/ProgressBar';

export default class smstemplate extends Component {
  constructor(props) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    super(props);

    this.state = {
      total_pages: 0,
      records: [],
      id: obj.user_id,
      record: {},
      recordrvm: {},
      page_name: this.props.match.params.id ? "" : "",
      product_data: [],
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      content_url: "",
      playing: true,
      volumne: "1",
      content_id: this.props.match.params.cont_id
        ? dcrypt(this.props.match.params.cont_id)
        : "1",
      index: this.props.match.params.index
        ? dcrypt(this.props.match.params.index)
        : "0",
      product_sections: [],
      params: props,
      controls: true,
      pip: false,

      light: false,
      volume: 0.8,
      muted: false,
      played: 0,
      loaded: 0,
      duration: 0,
      playbackRate: 1.0,
      loop: false,
      recordall: [],
      product_faqs: [],
      showMarkComplete: true,
      total_ratio: {
        total_content: 0,
        total_user: 0,
        avg: 0
      },
      training_cat: {}
    };
  }
  handleToggleControls = () => {
    this.setState({
      controls: !this.state.controls,
    });
  };
  componentDidMount() {
    this.getproductsections();
    this.getpoductdata();
    this.getcontentvideo(this.state.content_id);
    this.getproductfaqs();
  }
  getproductfaqs = (queryString = this.state.id, data) => {
    fetch(`${ApiUrl}` + "get/productfaqs/" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            product_faqs: response.data.records,
          });

          console.log(this.state.product_faqs, "Product FAQS");
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  componentDidUpdate(prevProps) {
    if (this.props.match.params.cont_id !== prevProps.match.params.cont_id) {
      this.setState({
        content_id: dcrypt(this.props.match.params.cont_id),
        showMarkComplete: false
      });
      this.getcontentvideo(dcrypt(this.props.match.params.cont_id));
    }
    // console.log(this.props.match.params.cont_id,'Content id debug');
  }
  getpoductdata = (queryString = this.state.id, data) => {
    fetch(`${ApiUrl}` + "getcatgeoryproduct/details/" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            product_data: response.data.record,
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing });
  };
  handleToggleLight = () => {
    this.setState({ light: !this.state.light });
  };

  handleToggleLoop = () => {
    this.setState({ loop: !this.state.loop });
  };

  handleVolumeChange = (e) => {
    this.setState({ volume: parseFloat(e.target.value) });
  };

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  };

  handleSetPlaybackRate = (e) => {
    this.setState({ playbackRate: parseFloat(e.target.value) });
  };

  handleOnPlaybackRateChange = (speed) => {
    this.setState({ playbackRate: parseFloat(speed) });
  };

  handleTogglePIP = () => {
    this.setState({ pip: !this.state.pip });
  };

  handlePlay = () => {
    this.setState({ playing: true });
  };

  handleEnablePIP = () => {
    this.setState({ pip: true });
  };

  handleDisablePIP = () => {
    this.setState({ pip: false });
  };

  handlePause = () => {
    this.setState({ playing: false });
  };

  handleSeekMouseDown = (e) => {
    this.setState({ seeking: true });
  };

  handleSeekChange = (e) => {
    this.setState({ played: parseFloat(e.target.value) });
  };

  handleSeekMouseUp = (e) => {
    this.setState({ seeking: false });
    this.player.seekTo(parseFloat(e.target.value));
  };

  handleProgress = (state) => {
    console.log("onProgress", state);
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state);
    }
  };

  handleEnded = () => {
    console.log("onEnded");
    this.setState({ playing: this.state.loop });
  };

  handleDuration = (duration) => {
    console.log("onDuration", duration);
    this.setState({ duration });
  };

  getcontentvideo = (queryString) => {
    fetch(`${ApiUrl}` + "view/training/content/" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ user_id: uInfo().user_id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            content_url: response.data.record,
            recordall: response.data.recordall,
          });

          let self = this;

          setTimeout(function () {
            self.setState({
              showMarkComplete: true
            })
            console.log('--------------------------------');
          }, 30000);


        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getproductsections = (queryString = this.state.id, data) => {
    fetch(`${ApiUrl}` + "get/productcontent/" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ user_id: uInfo().user_id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {

          let total_ratio = response.data.total_ratio;
          let avg = (response.data.total_ratio.total_user / response.data.total_ratio.total_content) * 100;
          total_ratio.avg = avg;

          this.setState({
            product_sections: response.data.records,
            training_cat: response.data.first,
            total_ratio: total_ratio
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  markAsComplete = () => {

    let data = {
      user_id: uInfo().user_id,
      content_id: this.state.recordall.content_id,
      cat_id: this.state.recordall.cat_id,
      section_id: this.state.recordall.section_id,
    }

    fetch(`${ApiUrl}` + "content/mark/complete", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            product_sections: []
          });

          this.setState(prevState => {
            let recordall = Object.assign({}, prevState.recordall);
            recordall.id = 1;
            return { recordall };
          })
          this.getproductsections();

        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  render() {
    return (
      <React.Fragment>
        {/* <Row>
          <Col lg="2" className="pl-0">
            <TrainingProductsSidebar menuOpen={false} props={this.props} />
          </Col>
          <Col lg="10"> */}
        <Row>
          <Col className="col-12">
            <Card className="NoShadow HeightAuto">
              <CardBody className="NoPadding">
                <h3>
                  {this.state.product_data.title} {this.state.page_name}
                </h3>

              </CardBody>
              <Row className="mx-0">
                <Col xl={3}>
                  <Card className="NoShadow traning HeightAuto Playlist custom-scroll">
                    <CardBody>
                      <CardTitle className="h4">
                        {this.state.product_data.title}
                      </CardTitle>

                      {
                        uInfo().type == 'Client' && (
                          <ProgressBar now={this.state.total_ratio.avg} />
                        )
                      }

                      <div className="accordion">
                        {this.state.product_sections.map((row, i) => {
                          return (
                            <SectionAccordion
                              title={row.title}
                              content={row.title}
                              section_id={row.cat_id}
                              index={i}
                              defaultIndex={this.state.index}
                              defaultSelection={dcrypt(this.props.match.params.cont_id)}
                              shown={i == 0 ? true : row.result_id != null ? true : false}
                            // props={row}
                            />
                          );
                        })}
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col xl={9}>
                  {
                    this.state.training_cat.total_content == this.state.training_cat.total_user && (
                      <>
                        {
                          this.state.training_cat.result_id == null && (
                            <button className="btn btn-info" onClick={() => this.props.history.push('/take/quiz/' + bcrypt(this.state.training_cat.cat_id))}>Take Quiz</button>
                          )
                        }
                      </>
                    )
                  }
                  <Card className="NoShadow traning HeightAuto VideoHeight video p-0">
                    <CardBody className="p-0">
                      <ReactPlayer
                        width="100%"
                        url={this.state.content_url}
                        playing={this.state.playing}
                        onError={(e) => console.log("onError", e)}
                        volume={this.state.volume}
                        onStart={() => console.log("onStart")}
                        controls={this.state.controls}
                      />

                      <div className="Video-content mt-3 text-center">
                        <h4>{this.state.recordall.title}</h4>

                        {
                          !this.state.recordall.id && this.state.showMarkComplete && uInfo().type == 'Client' && (
                            <button className="btn btn-info btn-md" onClick={() => this.markAsComplete()}>
                              Mark as Complete
                            </button>
                          )
                        }
                      </div>
                    </CardBody>
                  </Card>
                </Col>

              </Row>
              <Row className="mx-0">
                <Col xl={8} className="offset-xl-2">
                  <Card className="NoShadow HeightAuto Faqs">
                    <CardBody>
                      <h4>Frequently Asked Questions</h4>
                      <div className="accordion">
                        {this.state.product_faqs.map((row, i) => {
                          return (
                            <ProductFaqs
                              title={row.title}
                              content={row.description}
                            />
                          );
                        })}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {/* </Col>
        </Row> */}
      </React.Fragment>
    );
  }
}
