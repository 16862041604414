var countries = [
    { id: 'usa', label: 'USA / Canada' , code: '+1' },
    { id: 'australia', label: 'Australia'  , code: '+61'},
    { id: 'mexico', label: 'Mexico' , code: '+52' },
    { id: 'uk', label: 'United Kingdom', code: '+44'  },
    { id: 'venezuela', label: 'Venezuela', code: '+58'  },
    { id: 'belgium', label: 'Belgium', code: '+32'  },
    { id: 'spain', label: 'Spain', code: '+34'  },
    // { id: 'newzealand', label: 'New Zealand' },
    // { id: 'netherlands', label: 'Netherlands' },
    // { id: 'southafrica', label: 'South Africa' },
    // { id: 'ireland', label: 'Ireland' },
    // { id: 'vanuatu', label: 'Vanuatu' },
    // { id: 'pakistan', label: 'Pakistan' },
    // { id: 'uae', label: 'UAE' },
];

export default countries;