import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  CardText,
  CardImg,
  CardTitle,
  Table,
  Button,
  Badge,
} from "reactstrap";

import Helmet from "react-helmet";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { logoLightPng } from "../../../config.js"
import { Link, withRouter } from "react-router-dom";

import { ApiUrl, ProductName } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { dcrypt, uToken } from "../../../useToken";
import LoginHistory from "../../../components/tables/LoginHistory";
import ProfileSettings from "../../Authentication/profile-sidebar";
// actions

//Import Action to copy breadcrumb items from local state to redux state

const SubscriptioHistory = (props) => {
  const [page_name] = useState("Subscription Invoice");
  const [sub_id] = useState(dcrypt(props.match.params.sub_id));
  const [user_id] = useState(dcrypt(props.match.params.user_id));
  const [trans_id] = useState(dcrypt(props.match.params.trans_id));
  const [info, setInfo] = useState({});


  useEffect(() => {
    getData();
  }, []);
  //Print the Invoice
  const printInvoice = () => {
    window.print();
  };
  const getData = () => {
    fetch(`${ApiUrl}` + "get/wl/subscription/historydata/invoice/" + sub_id + '/' + trans_id + '/' + user_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        console.log(response, 'Debugging');
        if (response.status === true) {
          setInfo(response.data.record);

        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      <Helmet>
        <title>Profile </title>
      </Helmet>

      <Row>
        <Col lg="2" className="pl-0">
          <ProfileSettings menuOpen={false} props={props} />
        </Col>
        <Col lg="10">
          <Row>
            <Col sm={6}>
              <div className="page-title-box">
                <h4>{page_name} </h4>
                <ol className="breadcrumb m-0">
                  <li key={0} className="breadcrumb-item active">
                    {ProductName}
                  </li>
                  <li key={1} className="breadcrumb-item">
                    <Link to="#">{page_name} </Link>
                  </li>
                </ol>
              </div>
            </Col>
            <Col sm={6}>
              <div className="page-title-box text-align-right">
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => props.history.goBack()}
                >
                  Back{" "}
                </Button>
              </div>{" "}
            </Col>{" "}
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>

                  <div className="invoice-title">
                    <Row>
                      <Col lg="12 mb-3">
                        <h2 className="float-end title-border-bottom">Invoice</h2>
                      </Col>
                      <Col lg="6">
                        <div className="invoice-logo">
                          <h3 className="mt-0 float-start">
                            <img src={logoLightPng} alt="logo" height="40" />
                          </h3>
                        </div>
                      </Col>

                      <Col lg="6">
                        <address className="mb-0 float-end">
                          {info.created_at}
                          <br />
                          <h5>Invoice #  {info.transaction_id}</h5>
                        </address>
                      </Col>
                    </Row>
                  </div>


                  <Row className="pt-2 justify-content-between mb-3">
                    <address className="mb-0 user-address-info p-3">
                      <strong>Billed To:</strong>
                      <br />
                      {info.name}
                      <br />
                    </address>
                    <address className="mb-0 user-address-info p-3">
                      <strong>Payment Method:</strong>&nbsp;&nbsp;

                      <div className="payment-method" style={{ textTransform: 'capitalize' }}>{info.payment_method}</div>
                      <br />
                      {info.email}
                    </address>
                    {/* <Col xs="6" className="text-end mt-3">
                        <address>
                          <strong>Order Date:</strong>
                          <br />
                          {info.created_at}
                        </address>
                      </Col> */}
                    {/* <Col xs="6" className="text-end">
                          <address>
                            <strong>Shipped To:</strong>
                            <br />
                            Kenny Rigdon
                            <br />
                            1234 Main
                            <br />
                            Apt. 4B
                            <br />
                            Springfield, ST 54321
                          </address>
                        </Col> */}
                  </Row>



                  <Row>
                    <Col xs="12">
                      <div>
                        {/* <div className="p-2">
                          <h3 className="font-size-16">
                            <strong>Order summary</strong>
                          </h3>
                        </div> */}
                        <div className="">
                          <div className="table-responsive">
                            <table className="table coach-invoice table-bordered table-striped">
                              <thead>
                                <tr>
                                  <th>Package Name</th>
                                  <th className="text-left">Price</th>
                                  <th className="text-left">Package Interval</th>
                                  <th className="text-end">Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>  {info.package_name}</td>
                                  <td className="text-left">{info.price}</td>
                                  <td className="text-left">{info.package_interval}</td>
                                  <td className="text-end">{info.price}</td>
                                </tr>

                                <tr className="InvoiceTotals">
                                  <td colSpan={2} className="thick-line no-border"></td>
                                  <th className="thick-line text-left no-border">Subtotal</th>
                                  <td className="thick-line text-end no-border">
                                    {info.price}
                                  </td>
                                </tr>
                                {/* <tr>
                                  <td className="no-line"></td>
                                  <td className="no-line"></td>
                                  <td className="no-line text-center">
                                    <strong>Shipping</strong>
                                  </td>
                                  <td className="no-line text-end">$15</td>
                                </tr> */}
                                <tr>
                                  <td colSpan={2} className="no-line no-border"></td>
                                  <th className="no-line text-left no-border">Total</th>
                                  <td className="no-line text-end no-border">
                                    <h4 className="m-0">{info.price}</h4>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div className="d-print-none">
                            <div className="float-end">
                              <Link
                                to="#"
                                onClick={printInvoice}
                                className="btn btn-success waves-effect waves-light"
                              >
                                <i className="fa fa-print"></i>
                              </Link>{" "}
                              {/* <Link
                                to="#"
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Send
                              </Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row >
    </>
  );
};

export default SubscriptioHistory;
