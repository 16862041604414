import React, { useState, useEffect, useRef } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import { Accordion, Dropdown, Tab, Tabs, Tooltip } from "react-bootstrap";

import { Link } from "react-router-dom";

import Helmet from "react-helmet";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    bcrypt,
    dcrypt,
    uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';



const PipelineView = (props) => {

    const [id] = useState(props.match.params.pipeline_id
        ? dcrypt(props.match.params.pipeline_id)
        : null);
    const [records, setRecords] = useState([]);

    useEffect(() => {
        getData();
    }, []);


    const getData = () => {
        fetch(`${ApiUrl}` + "pipeline/status/deal?filter_value=&page_number=1&page_size=300&sort_order=false", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ pipeline_id: id }),
        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {
                    console.log(response, 'debugging');
                    setRecords(response.data.records);

                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    const onDragEnd = e => {
        if (!e.destination) {
            return;
        }
        const sorted = reorder(records, e.source.index, e.destination.index);
        setRecords(sorted);
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };


    function handleSubmit(event) {
        fetch(`${ApiUrl}` + "update/pipeline/order", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ pipeline_id: id, items_arr: records }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {

                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }



    return (
        <React.Fragment>
            <Helmet>
                <title>Order Sequence Pipeline Status</title>
            </Helmet>
            <Row>
                <Col sm={8}>
                    <div className="page-title-box">
                        <h4>Pipeline Status</h4>
                        <ol className="breadcrumb m-0">
                            <li key={0} className="breadcrumb-item active">
                                {ProductName}
                            </li>
                            <li key={1} className="breadcrumb-item">
                                <Link to="#">Order Sequence Pipeline Status</Link>
                            </li>
                        </ol>
                    </div>
                </Col>

                <Col sm={4}>
                    <div className="page-title-box text-align-right">
                        <button
                            type="button"
                            color="secondary"
                            className="btn btn-info"
                            onClick={() => props.history.push('/pipeline/list')}
                        >
                            <i className="mdi mdi-keyboard-backspace "> </i>
                            Back
                        </button>
                    </div>
                </Col>
            </Row>

            <Card>
                <CardBody>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="Table">
                            {provided => (
                                <div className="table-responsive mb-0 col-md-12" data-pattern="priority-columns">
                                    <table className="table table-striped table-bordered responsiveTable" {...provided.droppableProps} ref={provided.innerRef}>
                                        <thead>
                                            <tr>
                                                <th>Order Sequence</th>
                                                <th>Title</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                records.map((row, i) => {

                                                    return (
                                                        <Draggable
                                                            key={row.status_id.toString()}
                                                            draggableId={row.status_id.toString()}
                                                            index={i}
                                                        >
                                                            {provided => (
                                                                <tr
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <td>{i + 1}</td>
                                                                    <td>{row.title}</td>
                                                                    <td>{row.status}</td>
                                                                </tr>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })
                                            }


                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    {
                        records.length < 1 && (
                            <div className="text-center">No row found</div>
                        )
                    }
                    {
                        records.length > 0 && (
                            <button className="btn btn-primary btn-md" onClick={() => handleSubmit()}>Update Order</button>
                        )
                    }
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default PipelineView;
