import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Container,
} from "reactstrap";
import { Dropdown } from "react-bootstrap";

import Helmet from "react-helmet";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    bcrypt,
    isAllowed,
    uInfo,
    uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import StatusCreate from "./statusCreate.js";
import StatusUpdate from "./statusUpdate.js";
import Create from "./create.js";
import Update from "./update.js";
import swal from 'sweetalert';
const arrowsPlus = require('../../assets/images/arrows_plus.png');


export default class OnBoardTraining extends Component {
    constructor(props) {
        super(props);
        this.state = {
            records: [],
            roles: [],
            total_pages: 0,
            modal: false,
            record: {},
            filter: {},
            page_name: "OnBoard Training",
            customDate: true,
            statusModal: false,
            page2Class: '',
            editId: '',
            sliderShown: '',
            currentClient: {
                id: "",
                email_status: "",
                call_status: "",
                groups: [],
                tags: [],
            },
            status_id: 0,
            filter: 'All',
            pipeline_id: 0,
            PrevStatus: null,
        };
    }



    updateSequence = (items_arr) => {
        fetch(`${ApiUrl}` + "update/pipeline/order", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ items_arr: items_arr }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {

                    // toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    statusPipline = (pipeline_id, status_id) => {
        var filter = [];
        if (pipeline_id) {
            filter.push(pipeline_id.pipeline_id)
            // pipeline_id.map(fil => {
            //     filter.push(fil.pipeline_id);
            // })
        }
        fetch(`${ApiUrl}` + "add/status/pipeline", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ pipeline_id: filter.toString(), status_id: status_id }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    this.setState({
                        status_id: 0
                    })

                    this.getData();

                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    deleteRecord = (record, index) => {
        swal({
            title: "Are you sure?",
            text: "You want to remove this record.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "delete/pipeline/status", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                        body: JSON.stringify({ id: record.status_id }),
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((data) => {
                            if (data.status === true) {
                                this.getData("", { 'pipeline_id': this.state.pipeline_id });
                                //    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                            } else {
                                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                            }
                        })
                        //Then with the error genereted...
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    };

    addStatus = (record, index) => {
        this.setState({
            statusModal: true,
            statusModalId: record.pipeline_id
        })
    };

    componentDidMount() {
        this.getData();
    }


    deletePipelineRecord = (id) => {
        swal({
            title: "Are you sure?",
            text: "You want to remove this record.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "delete/pipeline", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                        body: JSON.stringify({ id: id }),
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((data) => {
                            if (data.status === true) {
                                this.getData();
                                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                            } else {
                                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                            }
                        })
                        //Then with the error genereted...
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    };

    getData = () => {
        fetch(`${ApiUrl}` + "pipeline/training/status", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {
                    console.log(response, 'debugging');
                    this.setState({
                        total_pages: response.data.total,
                        records: response.data.records,
                        roles: response.data.roles,
                    });


                    // this.forceUpdate();



                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };



    cancelSlider = async () => {
        this.getData("", "");
        this.setState({
            page2Class: 'slide-out2',
            editId: '',
            sliderShown: ''
            // showSlider: false,
        })
    };

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{this.state.page_name}</title>
                </Helmet>

                <Row>
                    <Col sm={5}>
                        <div className="page-title-box">
                            <h4>{this.state.page_name} Lists</h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#">{this.state.page_name} Lists</Link>
                                </li>
                            </ol>
                        </div>
                    </Col>
                    <Col sm={3}>
                        {/* <div className="page-title-box text-align-right">
                            {hasRole(uInfo(), ["Import Leads"]) && (
                                <Link to={"import/lead"} className="btn  btn-success">
                                    Import
                                </Link>
                            )}
                        </div> */}
                    </Col>
                    <Col sm={4}>
                        <div className="page-title-box text-align-right">
                            {isAllowed(uInfo(), ["can_create"]) && uInfo().user_id == 3 && (
                                <>
                                    <button
                                        type="button"
                                        color="info"
                                        className="btn btn-info mr-5"
                                        onClick={() => { this.setState({ showSlider: true, page2Class: 'slide-in', sliderShown: 'showSlider' }) }}
                                    >
                                        <i className="mdi mdi-plus"> </i>
                                        Create
                                    </button>

                                    {/* <button
                                        type="button"
                                        color="info"
                                        className="btn btn-info"
                                        onClick={() => { this.setState({ show3Slider: true, page2Class: 'slide-in', sliderShown: 'show3Slider' }) }}
                                    >
                                        <i className="mdi mdi-plus"> </i>
                                        Create Status
                                    </button> */}
                                </>
                            )}
                        </div>
                    </Col>
                </Row>

                <ToastContainer />

                {
                    this.state.showSlider && this.state.sliderShown == 'showSlider' && (
                        <div className={`page3 ${this.state.page2Class} custom-scroll`} >
                            <div>
                                <div className="modal-head top-heading">
                                    <h2>Add Category</h2>
                                    <button onClick={() => { this.setState({ page2Class: 'slide-out2' }); }} className="btn btn-link text-white">
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                                <Create cancelSlider={this.cancelSlider} />
                            </div>
                        </div>
                    )
                }

                {
                    this.state.show2Slider && this.state.sliderShown == 'show2Slider' && (
                        <div className={`page3 ${this.state.page2Class} custom-scroll`} >
                            <div>
                                <div className="modal-head top-heading">
                                    <h2>Edit Pipeline</h2>
                                    <button onClick={() => { this.setState({ page2Class: 'slide-out2' }); }} className="btn btn-link text-white">
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                                <Update cancelSlider={this.cancelSlider} id={this.state.editId} />
                            </div>
                        </div>
                    )
                }

                {
                    this.state.show3Slider && this.state.sliderShown == 'show3Slider' && (
                        <div className={`page3 ${this.state.page2Class} custom-scroll`} >
                            <div>
                                <div className="modal-head top-heading">
                                    <h2>Add Status</h2>
                                    <button onClick={() => { this.setState({ page2Class: 'slide-out2' }); }} className="btn btn-link text-white">
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                                <StatusCreate cancelSlider={this.cancelSlider} prev_status_id={this.state.PrevStatus} />
                            </div>
                        </div>
                    )
                }

                {
                    this.state.show4Slider && this.state.sliderShown == 'show4Slider' && (
                        <div className={`page3 ${this.state.page2Class} custom-scroll`} >
                            <div>
                                <div className="modal-head top-heading">
                                    <h2>Edit Status</h2>
                                    <button onClick={() => { this.setState({ page2Class: 'slide-out2' }); }} className="btn btn-link text-white">
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                                <StatusUpdate cancelSlider={this.cancelSlider} id={this.state.editId} />
                            </div>
                        </div>
                    )
                }








                <Modal
                    isOpen={this.state.statusModal}
                    role="dialog"
                    centered={true}
                    className="exampleModal"
                    tabIndex="-1"
                    toggle={() => {
                        this.setState({
                            statusModal: !this.state.statusModal,
                        });
                    }}
                >
                    <div className="modal-content">
                        <ModalHeader
                            toggle={() => {
                                this.setState({
                                    statusModal: !this.state.statusModal,
                                });
                            }}
                        >
                            Add Status
                        </ModalHeader>
                        <AvForm
                            onValidSubmit={this.ClientSubcribeSubmit}
                            className="needs-validation"
                        >
                            <ModalBody>
                                <div className="mb-3">
                                    <AvField
                                        className="select form-control"
                                        type="select"
                                        name="call_status"
                                        label="Landline & Mobile"
                                        required
                                        value={this.state.currentClient.call_status}
                                    >
                                        <option value={1}> Subscribe </option>
                                        <option value={0}> Unsubscribe </option>
                                    </AvField>
                                </div>
                                <div className="mb-3">
                                    <AvField
                                        className="select form-control"
                                        type="select"
                                        name="email_status"
                                        label="Email"
                                        required
                                        value={this.state.currentClient.email_status}
                                    >
                                        <option value={1}> Subscribe </option>
                                        <option value={0}> Unsubscribe </option>
                                    </AvField>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    type="button"
                                    color="secondary"
                                    onClick={() => {
                                        this.setState({
                                            statusModal: !this.state.statusModal,
                                        });
                                    }}
                                >
                                    Close
                                </Button>
                                <button
                                    className="btn btn-primary waves-effect waves-light"
                                    type="submit"
                                >
                                    Save
                                </button>
                            </ModalFooter>
                        </AvForm>
                    </div>
                </Modal>
                <Container fluid>
                    <Row>
                        {this.state.records.length > 0 ? (
                            this.state.records.map((pipeline, i) => {
                                return <Col lg={6} className="mb-3">
                                    <div className="table-responsive PipeLineStatus" key={i}>
                                        <div className="TableName d-flex justify-content-between" style={{ color: "#000" }}>
                                            <h4><i className="fas fa-circle"></i> {pipeline.name}</h4>
                                            {isAllowed(uInfo(), ["can_create"]) && uInfo().user_id == 3 && (
                                                <div className="">
                                                    <i
                                                        title="Edit status"
                                                        color="info"
                                                        type="button"
                                                        className="fas fa-edit"

                                                        onClick={() => {
                                                            this.setState({
                                                                show2Slider: true,
                                                                page2Class: 'slide-in',
                                                                sliderShown: 'show2Slider',
                                                                editId: pipeline.pipeline_id
                                                            })
                                                        }}
                                                    >
                                                    </i>
                                                    {
                                                        pipeline.final_pipeline != 1 && (
                                                            <i title="delete"
                                                                onClick={() => this.deletePipelineRecord(pipeline.pipeline_id)}
                                                                className="fas fa-trash-alt px-2 mr-2"></i>
                                                        )
                                                    }
                                                </div>
                                            )}

                                        </div>
                                        <table className="table table-borderless mb-0">
                                            <tbody>
                                                {
                                                    pipeline.pipelines.map((row, k) => {
                                                        return <tr key={k}>
                                                            <td width={50} style={{ background: row.color_code, color: "#fff" }} scope="row">
                                                                {/* <i className="fas fa-arrow-down"></i> */}
                                                            </td>
                                                            <td width={250}>{row.title}</td>

                                                            <td width={30}>
                                                                <i
                                                                    title="Edit status"
                                                                    color="info"
                                                                    type="button"
                                                                    className="fas fa-edit"

                                                                    onClick={() => { this.setState({ show4Slider: true, page2Class: 'slide-in', sliderShown: 'show4Slider', editId: row.status_id }) }}
                                                                >

                                                                </i>
                                                                {/* <i
                                                                    title="Delete status"
                                                                    type="button"
                                                                    className="fas fa-trash-alt px-1"
                                                                    disabled={row.is_complete == 1 ? true : false}
                                                                    onClick={this.deleteRecord.bind(this, row, i)}
                                                                >

                                                                </i> */}
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            }))
                            : (
                                <Card>
                                    <CardBody>
                                        <div className="col-12 col-lg-12 col-xl-12 text-center">No record found.</div>
                                    </CardBody>
                                </Card>
                            )
                        }
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}
