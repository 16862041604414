import React, { Component, Fragment, useCallback } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast, ToastContainer, Flip } from "react-toastify";
import { ApiUrl, MeetingUrl, PusherKey } from "../../config";
import { bcrypt, uInfo, uToken } from "../../useToken";
import Avatar from "react-avatar";
import Pusher from "pusher-js";
import Meeting from "../../assets/images/meeting.png"
import VideoChat from "../TwilioVideo/VideoChat";
import Room from "../TwilioVideo/Room";

let pusher = new Pusher(PusherKey, {
  cluster: "mt1",
});
var channel = pusher.subscribe("my-channel");
export default class ChatBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id ? this.props.match.params.id : "",
      page_name: "Chat",
      type: this.props.match.params.type
        ? this.props.match.params.type
        : "user",
      users: [],
      groups: [],
      activeTab: this.props.match.params.type == "group"
        ? "2"
        : "1",
      clients: [],
      chats: [],
      message: "",
      chatUser: {},
    };
  }

  componentDidMount() {
    this.getChatUser("", this.props.match.params.type);
    if (this.state.id != "") {
      this.getData();
    }
    var self = this;
    channel.bind("chat-event", function (data) {

      if (data.data.type == "group") {


        if (data.data.sent_to == self.state.id) {
          self.getData(data.data.sent_to);
          console.log(data.data, 'getData=-')
        } else {
          self.getChatUser("", "group");
          console.log(data.data, 'getChatUser')
        }

      } else {
        if (
          data.data.sent_to == uInfo().user_id &&
          data.data.user_id == self.state.id
        ) {
          self.getData();
        } else {
          self.getChatUser("", "user");
        }
      }

    });

    if (this.state.type == "group") {
      this.toggle("2", "group");
    } else {
      this.toggle("1", "user");
    }
  }

  getChatUser = (search = "", chat_type = "") => {
    fetch(`${ApiUrl}` + "get/chat/user", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ search: search, chat_type: chat_type }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          this.setState({
            users: data.data.lists,
            clients: data.data.clients,
            groups: data.data.groups,
          });

        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id != this.props.match.params.id) {
      this.setState(
        {
          id: this.props.match.params.id,
          type: this.props.match.params.type,
        },
        () => this.getData()
      );
    }
  }

  getData = (group_id = null) => {
    fetch(`${ApiUrl}` + "get/chats", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: group_id ? group_id : this.state.id, type: this.state.type }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          this.setState({
            chatUser: data.data.user,
            chats: data.data.chats,
          });
          if (document.getElementById(`total_read_count`)) {
            let a = document.getElementById(`total_read_count`).textContent;
            let b = document.getElementById(
              `count_removed${this.state.id}`
            ).textContent;
            document.getElementById(
              `count_removed${this.state.id}`
            ).textContent = "";
            document.getElementById(`total_read_count`).textContent = a - b;
          }

          this.forceUpdate();
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  sendMessage = (chatType, roomName = '') => {
    let message = this.state.message;
    let sub_type = 'text';

    if (chatType == 'video') {
      message = roomName;
      sub_type = 'video';
    }
    fetch(`${ApiUrl}` + "send/message", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        send_to: this.state.id,
        message: message,
        sub_type: sub_type,
        chat_init: this.state.chatUser.chat_init,
        chat_type: chatType,
        type: this.props.match.params.type
          ? this.props.match.params.type
          : "user",
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          this.setState({
            message: "",
          });
          if (data.data.meetingId != 0 && chatType == 'meeting') {
            window.open(`${data.data.meetingId}`, '_blank');
          }
          this.getData();
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  getVideoTokenTwillio = (roomName) => {
    fetch(`${ApiUrl}` + "video/token", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ room: roomName, identity: uInfo().name }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          console.log(data.data.token, 'data.data.token')
          this.setState({
            roomName: roomName,
            token: data.data.token,
            page11Class: 'slide-in'
          });

        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  handleLogout = event => {
    this.setState({
      token: null,
      page11Class: 'slide-out2'
    });
    // props.history.push('/client/profile');
  };
  toggle(tab, chat_type) {
    if (this.state.activeTab !== tab) {
      this.setState(
        {
          activeTab: tab,
        },
        () => this.getChatUser("", chat_type)
      );
    }
  }
  render() {
    return (
      <React.Fragment>
        <Nav tabs style={{ padding: "0" }}>
          <NavItem>
            <NavLink
              className={this.state.activeTab == 1 ? "active" : ""}
              onClick={() => {
                this.toggle("1", "user");
              }}
            >
              One on One
            </NavLink>
          </NavItem>
          {
            uInfo().type == 'Coach' && (
              <NavItem>
                <NavLink
                  className={this.state.activeTab == 2 ? "active" : ""}
                  onClick={() => {
                    this.toggle("2", "group");
                  }}
                >
                  Group Chat
                </NavLink>
              </NavItem>
            )
          }
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <main className="chat-box">
              <div className="card border">
                <div className="row g-0">
                  <div className="col-12 col-lg-5 col-xl-3 border-right">
                    <div className="px-4 d-none d-md-block border-bottom">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <input
                            onChange={(e) => this.getChatUser(e.target.value, "user")}
                            type="text"
                            className="form-control my-3"
                            placeholder="Search..."
                          />
                        </div>
                      </div>
                    </div>

                    <div className="chat-user-list custom-scroll">
                      {this.state.users.map((user, i) => {
                        return (
                          <div className="parent" key={i}>
                            <div id={user.user_id}>
                              <Link
                                to={`/chat/${user.user_id}/user`}
                                key={`${user.user_id}-${i}`}
                                className="list-group-item list-group-item-action border-0"
                              >
                                <div
                                  className="badge bg-success float-right"
                                  style={{ margin: "0 5px" }}
                                  id={`count_removed${user.user_id}`}
                                >
                                  {user.unread > 0 ? user.unread : ""}
                                </div>
                                <div className="d-flex align-items-start">
                                  <div className="small">



                                    {user.login_status == "yes"
                                      ? <span className="fas fa-circle chat-online"></span>
                                      : <span className="fas fa-circle chat-offine"></span>}

                                  </div>
                                  <Avatar name={user.name} size="30" round={true} />
                                  <div className="flex-grow-1 ml-3">
                                    {user.name}
                                    <div className="small float-right">
                                      {uInfo().type == "Coach"
                                        ? "ADMIN "
                                        : "COACH "}
                                    </div>
                                    {/* {uInfo().type == "Coach" ? <hr></hr> : ""} */}
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        );
                      })}
                      {this.state.clients.map((client, i) => {
                        return (
                          <div className="parent" key={i}>
                            <div id={client.user_id}>
                              <Link
                                to={`/chat/${client.user_id}/client`}
                                key={`${client.user_id}-${i}`}
                                className="list-group-item list-group-item-action border-0"
                              >
                                <div
                                  className="badge bg-success float-right"
                                  style={{ margin: "0 5px" }}
                                  id={`count_removed${client.user_id}`}
                                >
                                  {client.unread > 0 ? client.unread : ""}
                                </div>
                                <div className="d-flex align-items-start">
                                  <div className="small">
                                    {client.login_status == "yes"
                                      ? <span className="fas fa-circle chat-online"></span>
                                      : <span className="fas fa-circle chat-offine"></span>}
                                  </div>
                                  <Avatar
                                    name={client.name}
                                    size="30"
                                    round={true}
                                  />
                                  <div className="flex-grow-1 ml-3">
                                    {client.name}
                                    <div className="small float-right">{client.type == 'Coach' ? 'COACH' : client.type == 'CoachPartner' ? 'TEAM' : 'CLIENT'} </div>
                                    <div className="small">{client.email} </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <hr className="d-block d-lg-none mt-1 mb-0" />
                  </div>

                  {this.state.id ? (
                    <div className="col-12 col-lg-7 col-xl-9">
                      <div
                        className="pt-2 my-1 px-4 border-bottom d-none d-lg-block"
                        style={{ paddingBottom: "0.6rem" }}
                      >
                        <div className="d-flex align-items-center py-1">
                          <div className="position-relative">
                            <Avatar
                              name={this.state.chatUser.name}
                              size="30"
                              round={true}
                            />
                          </div>
                          <div className="flex-grow-1 pl-3">
                            <strong>{this.state.chatUser.name}</strong>
                            <div className="text-muted small">
                              <em></em>
                            </div>
                          </div>
                          {
                            this.props.match.params.type == 'client' && (
                              <>

                                <VideoChat sendMessage={this.sendMessage} loginStatus={this.state.chatUser.login_status} />

                                {/* <button
                                  title="Start a meeting"
                                  onClick={() => this.sendMessage('meeting')}
                                  className="btn btn-primary"
                                >
                                  Start Meeting
                                </button> */}
                              </>
                            )
                          }
                        </div>
                      </div>

                      <div className="position-relative">
                        <div className="chat-messages p-4 custom-scroll">
                          {this.state.chats.map((chat, k) => {
                            return (
                              <div
                                key={k}
                                className={`${this.state.id != chat.user_id
                                  ? " chat-message-right"
                                  : "chat-message-left"
                                  } pb-3`}
                              >
                                <div className="mr-3 text-center">
                                  <Avatar
                                    name={chat.user?.name}
                                    size="30"
                                    round={true}
                                  />
                                  <div className="text-muted small text-nowrap mt-2">
                                    {chat.created_at}
                                  </div>
                                </div>
                                <div className="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                                  <div className="font-weight-bold mb-1">




                                    {this.state.id != chat.user_id
                                      ? "You"
                                      : chat.user.name}
                                  </div>

                                  {
                                    chat.sub_type == 'video' ?
                                      chat.user_id == uInfo().user_id ?
                                        <button className="btn btn-link p-0"> Video link sent</button>
                                        :
                                        chat.created_ago <= 10 ?
                                          <button className="btn btn-primary joinVideoLink" onClick={() => this.getVideoTokenTwillio(chat.message)}> Click here to join video call</button>
                                          :
                                          <button className="btn btn-link p-0">Call link expired</button>
                                      :
                                      <p className="text-break" dangerouslySetInnerHTML={{ __html: chat.message }} />
                                  }

                                  {/* {
                                    chat.sub_type == 'video' ?
                                      <button> Video link sent</button>
                                      :
                                      <p dangerouslySetInnerHTML={{ __html: chat.message }} />
                                  } */}

                                  {/* <p dangerouslySetInnerHTML={{ __html: chat.message }} /> */}

                                  {/* {chat.message} */}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      <div className="flex-grow-0 py-3 px-4 border-top">
                        <div className="input-group">
                          <input
                            onChange={(e) =>
                              this.setState({ message: e.target.value })
                            }
                            value={this.state.message}
                            type="text"
                            className="form-control"
                            placeholder="Type your message"
                          />
                          <button
                            disabled={this.state.message == "" ? true : false}
                            onClick={() => this.sendMessage('chat')}
                            className="btn btn-primary"
                          >
                            Send
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-12 col-lg-7 col-xl-9"></div>
                  )}
                </div>
              </div>

              {this.state.page11Class == "slide-in" && this.state.token && (
                <div className={`page2 video-chat-call CoachPickup ${this.state.page11Class} custom-scroll`}>
                  <div>
                    <div className="modal-head top-heading">
                      <h2> Video Call </h2>
                      <button
                        onClick={() => {
                          this.setState({
                            token: null,
                            page11Class: 'slide-out2'
                          });
                          // props.history.push('/client/profile');
                        }}
                        className="btn btn-link text-white"
                      >
                        <i className="fas fa-times"> </i>
                      </button>
                    </div>
                    <Room roomName={this.state.roomName} token={this.state.token} handleLogout={this.handleLogout} />
                  </div>
                </div>
              )}
            </main>
          </TabPane>
          <TabPane tabId="2">
            <main className="chat-box">
              <div className="card border">
                <div className="row g-0">
                  <div className="col-12 col-lg-5 col-xl-3 border-right">
                    <div className="px-4 d-none d-md-block border-bottom">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <input
                            onChange={(e) => this.getChatUser(e.target.value, "group")}
                            type="text"
                            className="form-control my-3"
                            placeholder="Search..."
                          />
                        </div>
                      </div>
                    </div>

                    <div className="chat-user-list custom-scroll">
                      {this.state.groups.map((group, i) => {
                        return (
                          <div className="parent" key={i}>
                            <div id={group.id}>
                              <Link
                                to={`/chat/${group.id}/group`}
                                key={`${group.id}-${i}`}
                                className="list-group-item list-group-item-action border-0"
                              >
                                <div
                                  className="badge bg-success float-right"
                                  style={{ margin: "0 5px" }}
                                  id={`count_removed${group.id}`}
                                >
                                  {group.unread > 0 ? group.unread : ""}
                                </div>
                                <div className="d-flex align-items-start">

                                  <Avatar name={group.name} size="30" round={true} />
                                  <div className="flex-grow-1 ml-3">
                                    {group.name}
                                    <div className="small float-right">
                                      {"GROUP"}
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <hr className="d-block d-lg-none mt-1 mb-0" />
                  </div>

                  {this.state.id ? (
                    <div className="col-12 col-lg-7 col-xl-9">
                      <div
                        className="pt-2 my-1 px-4 border-bottom d-none d-lg-block"
                        style={{ paddingBottom: "0.6rem" }}
                      >
                        <div className="d-flex align-items-center py-1">
                          <div className="position-relative">
                            <Avatar
                              name={this.state.chatUser.name}
                              size="30"
                              round={true}
                            />
                          </div>
                          <div className="flex-grow-1 pl-3">
                            <strong>{this.state.chatUser.name}</strong>
                            <div className="text-muted small">
                              <em></em>
                            </div>
                          </div>

                        </div>
                      </div>

                      <div className="position-relative">
                        <div className="chat-messages p-4 custom-scroll">
                          {this.state.chats.map((chat, k) => {
                            return (
                              <div
                                key={k}
                                className={`${uInfo().user_id == chat.user?.user_id
                                  ? " chat-message-right"
                                  : "chat-message-left"
                                  } pb-3`}
                              >
                                <div className="mr-3 text-center">
                                  <Avatar
                                    name={chat.user?.name}
                                    size="30"
                                    round={true}
                                  />
                                  <div className="text-muted small text-nowrap mt-2">
                                    {chat.created_at}
                                  </div>
                                </div>
                                <div className="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                                  <div className="font-weight-bold mb-1">
                                    {uInfo().user_id == chat.user?.user_id
                                      ? "You"
                                      : chat.user.name}
                                  </div>

                                  {
                                    <p className="text-break" dangerouslySetInnerHTML={{ __html: chat.message }} />
                                  }
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      <div className="flex-grow-0 py-3 px-4 border-top">
                        <div className="input-group">
                          <input
                            onChange={(e) =>
                              this.setState({ message: e.target.value })
                            }
                            value={this.state.message}
                            type="text"
                            className="form-control"
                            placeholder="Type your message"
                          />
                          <button
                            disabled={this.state.message == "" ? true : false}
                            onClick={() => this.sendMessage('chat')}
                            className="btn btn-primary"
                          >
                            Send
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-12 col-lg-7 col-xl-9"></div>
                  )}
                </div>
              </div>

              {this.state.page11Class == "slide-in" && this.state.token && (
                <div className={`page2 video-chat-call CoachPickup ${this.state.page11Class} custom-scroll`}>
                  <div>
                    <div className="modal-head top-heading">
                      <h2> Video Call </h2>
                      <button
                        onClick={() => {
                          this.setState({
                            token: null,
                            page11Class: 'slide-out2'
                          });
                          // props.history.push('/client/profile');
                        }}
                        className="btn btn-link text-white"
                      >
                        <i className="fas fa-times"> </i>
                      </button>
                    </div>
                    <Room roomName={this.state.roomName} token={this.state.token} handleLogout={this.handleLogout} />
                  </div>
                </div>
              )}
            </main>
          </TabPane>
        </TabContent>
      </React.Fragment>
    );
  }
}
