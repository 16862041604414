import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  CardImg,
  CardText,
  ModalHeader,
  Nav,
  CardImgOverlay,
  NavItem,
  NavLink,
  Input,
  TabContent,
  TabPane,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Badge from "react-bootstrap/Badge";
import Breadcrumb from "../../../../components/Common/Breadcrumb";
import TrainingProductsSidebar from "./trainingproducts-sidebar";
import ProfileSettings from "../../../Authentication/profile-sidebar";
//Import Action to copy breadcrumb items from local state to redux state
import "react-toastify/dist/ReactToastify.css";
import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../../useToken";
import { ApiUrl, ProductName, AttachementsUrl } from "../../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";

export default class smstemplate extends Component {
  constructor(props) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    super(props);
    this.state = {
      total_pages: 0,
      records: [],
      id: obj.user_id,
      record: {},
      recordrvm: {},
      page_name: this.props.match.params.id ? "Trainings" : "Trainings",
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = (queryString = "", data) => {
    fetch(`${ApiUrl}` + "get/trainingcategories" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({user_id: uInfo().user_id}),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            records: response.data.records,
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  render() {
    return (
      <React.Fragment>

        {/* <Col lg="2" className="pl-0">
            <TrainingProductsSidebar menuOpen={false} props={this.props} />
          </Col> */}

        <Card>
          <CardBody>
            <h3>Trainings</h3>
            <Row>
              {this.state.records.map((row, i) => {
                return (

                  <div className="pulse col-lg-3 col-md-4 col-sm-6">
                    <div className="marketplace_card">
                      <div className="containerw">
                        <img className="image" src={
                          AttachementsUrl +
                          "user_2/training/" +
                          row.image
                        } title={row.title} />
                        <div className="middle">
                          <Link to={"/training/product/content/" + bcrypt(row.cat_id) + "/" + bcrypt(0)} title="Embed Code" data-toggle="modal" className="text">View</Link>
                        </div>
                      </div>
                      <div className="market_content">
                        <div className="row" >
                          <div className="col-md-12">
                            <label className="tooltip-trigger" data-placement="bottom"
                              data-toggle="tooltip" title="" data-original-title="Test">
                              {row.title}                                          </label>
                            <div className="market_price">
                              <label></label>
                              <Link
                                className="btn btn-primary"
                                to={"/training/product/content/" + bcrypt(row.cat_id) + "/" + bcrypt(i)}
                              >View</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                );
              })}
            </Row>
          </CardBody>

        </Card>



      </React.Fragment >
    );
  }
}
