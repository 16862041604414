import React, { useState, createRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

export const Demo = (props) => {
    const [image, setImage] = useState("");
    const [cropData, setCropData] = useState("#");
    const cropperRef = createRef();
    
    const getCropData = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
            props.imageCropperSet(props.data?.id, cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
        }
    };


    return (
        <>

            <div className="modal-body py-0">
                <Cropper
                    ref={cropperRef}
                    style={{ height: 445, width: "100%" }}
                    zoomTo={0.2}
                    initialAspectRatio={1.5}
                    preview=".img-preview"
                    src={props.data?.url}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={true}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    guides={true}
                />
            </div>



            <div className="modal-footer">
                <button className="btn btn-secondary btn-sm" style={{ float: "right" }} onClick={() => props.closeModal(props.data?.id, props.data?.url)}>
                    Close
                </button>
                <button className="btn btn-primary btn-sm" style={{ float: "right" }} onClick={getCropData}>
                    Crop Image
                </button>
            </div>
        </>
    );
};

export default Demo;
