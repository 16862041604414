import React, { Component, Fragment } from "react"
import { Row, Col, Card, CardBody, CardTitle, Form, Label, Button, Modal, ModalHeader, Input, ModalBody, ModalFooter } from "reactstrap"

import Helmet from "react-helmet"
import axios from "axios"
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
} from "availity-reactstrap-validation"
import { uToken, dcrypt, uInfo } from "../../useToken";
import { getCoachPages, getStaffPages, getCoachPartnerPages } from "../../components/Common/PagesLibrary";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select"
import { Link } from "react-router-dom";

export default class PermissionCreateUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      record: {
        pages: [],
        type: uInfo().type == 'Coach'  ? 'partner' : 'coach',
        status: 'Active',
      },
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      permission: '',
      page_name: "Add Permission",
      coach_pages_array: uInfo().type == 'Coach' ? getCoachPartnerPages() : getCoachPages(),
      isCheckAll: {},
      isCheck: {},
    }
  }

  componentDidMount() {
    this.state.id != null && this.getData();
  }

  getData = (queryString = "") => {

    fetch(`${ApiUrl}` + 'getEdit/permission/library', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + uToken()
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        //console.log(data, 'data')
        if (data.status === true) {
          this.setState({
            coach_pages_array: data.data.record.type == 'coach' ? getCoachPages() : data.data.record.type == 'partner' ? getCoachPartnerPages() : getStaffPages()
          })

          this.setState({
            record: data.data.record
          })
          //console.log(data.data.record.pages, 'redccc') 
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }

      })
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
      });

  }
  handleMulti = (selectedMulti) => {
    var filter = [];
    if (selectedMulti) {
      selectedMulti.map(fil => {
        filter.push(fil.value);
      })
    }

    this.setState({
      selectedMulti: selectedMulti,
      pages: filter.toString()
    })
  }

  handlePermissionMulti = (selectedPermissionMulti) => {
    var filter = [];
    if (selectedPermissionMulti) {
      selectedPermissionMulti.map(fil => {
        filter.push(fil.value);
      })
    }

    this.setState({
      selectedPermissionMulti: selectedPermissionMulti,
      permission: filter.toString()
    })
  }
  handleSubmit = async (event, values) => {
    if (this.state.record) {
      values.id = this.state.record.id
    }
    fetch(`${ApiUrl}` + 'edit/permission/library', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + uToken()
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          this.props.history.goBack();
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
      });
  }
  handleSelectAll = (e, sub_tabs, key) => {
    this.setState(prevState => ({
      isCheckAll: {                   // object that we want to update
        ...prevState.isCheckAll,    // keep all other key-value pairs
        [key]: !this.state.isCheckAll[key]    // update the value of specific key
      }
    }))
   

    if (!e) {
      this.setState(prevState => ({
        isCheck: {                   // object that we want to update
          ...prevState.isCheck,    // keep all other key-value pairs
          [key]: []    // update the value of specific key
        }
      }))


      let a  = this.state.record.pages;
      let b  =  sub_tabs.map(li => li.key) 

      let c  = a.filter(item => !b.includes(item));
      let d = c.filter(function(ele){ 
        return ele != key; 
    });
   
      this.setState(prevState => ({
        record: {                   // object that we want to update
          ...prevState.record,    // keep all other key-value pairs
          pages: d    // update the value of specific key
        }
      }))

    }
    if (e) {
      this.setState(prevState => ({
        isCheck: {                   // object that we want to update
          ...prevState.isCheck,    // keep all other key-value pairs
          [key]: sub_tabs.map(li => li.key)    // update the value of specific key
        }
      }))
    }

  }
  handleSubClick = (e, key) => {
    if (!this.state.isCheckAll[key]) {
      this.setState(prevState => ({
        isCheckAll: {                   // object that we want to update
          ...prevState.isCheckAll,    // keep all other key-value pairs
          [key]: !this.state.isCheckAll[key]    // update the value of specific key
        }
      }))
    }
  }


  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.page_name} Tables </title>
        </Helmet>

        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.id == null ? "Add Permission Library" : "Edit Permission Library"}</h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">{ProductName}</li>
                <li key={1} className="breadcrumb-item"><Link to="#">{this.state.page_nam}</Link></li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                type="button"
                color="secondary"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>

        <ToastContainer />
        <Row>
          <Col className="col-12">
            <Card className="package-add">
              <AvForm onValidSubmit={this.handleSubmit} className="needs-validation">
                <ModalBody>
                  <div className="mb-3">
                    <AvField
                      name="name"
                      label="Name"
                      value={this.state.record.name}
                      className="form-control"
                      placeholder="Enter Name"
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <AvField
                      name="type"
                      label="Role for"
                      value={this.state.record.type}
                      className="form-control"
                      type="select"
                      onChange={(e) => this.setState({
                        coach_pages_array: e.target.value == 'coach' ? getCoachPages() : e.target.value == 'partner' ? getCoachPartnerPages() : getStaffPages()
                      })}
                    >
                      {
                        uInfo().type == 'Coach' ? (
                          <option value={'partner'}>Team</option>

                        ) : (
                          <>
                            <option value={'coach'}>Coach</option>
                            <option value={'staff'}>Team Admin</option>
                          </>
                        )
                      }

                    </AvField>

                  </div>


                  <div className="mb-3">
                    <label className="control-label">
                      Pages Permission
                    </label>
                    <div className="row">
                      {this.state.coach_pages_array && (
                        this.state.coach_pages_array.map((row, key) => {
                          return (
                            <div className="col-md-12 mb-3" key={key}>
                              <AvGroup check className="mb-2">
                                <AvInput
                                  checked={this.state.record.pages.indexOf(row.key) > -1 || this.state.isCheckAll[row.key] ? 'checked' : ''}
                                  type="checkbox" name={`master_pages[${row.key}]`}
                                  onClick={(e) => this.handleSelectAll(e.target.checked, row.sub_tabs, row.key)}
                                //isChecked={this.state.isCheckAll[row.key]}
                                />
                                <Label check htmlFor="checkbox" className="fw-bolder"> {row.label}</Label>
                              </AvGroup>
                              <div className="row">
                                {row.sub_tabs.length > 0 && (
                                  row.sub_tabs.map((sub_row, i) => {
                                    return (
                                      <div className="col-md-2 mb-3">

                                        <AvGroup check>
                                          <AvInput
                                            checked={this.state.record.pages.indexOf(sub_row.key) > -1 || (this.state.isCheck[row.key] && this.state.isCheck[row.key].includes(sub_row.key)) ? 'checked' : ''}
                                            type="checkbox" name={`sub_pages[${sub_row.key}]`}
                                            onClick={(e) => this.handleSubClick(e.target.value, row.key)}
                                          // isChecked={this.state.isCheck.includes(sub_row.key)}
                                          />
                                          <Label check htmlFor="checkbox"> {sub_row.label}</Label>
                                        </AvGroup>
                                      </div>
                                    )
                                  })
                                )}
                              </div>
                            </div>
                          )
                        })
                      )
                      }

                    </div>
                  </div>

                  <div className="mb-3">
                    <AvField
                      className="select form-control"
                      type="select" id="s" name="status" label="Status" required
                      value={this.state.record.status}>
                      <option value={'Active'}>Active</option>
                      <option value={'Inactive'}>Inactive</option>
                    </AvField>

                  </div>
                </ModalBody>
                <ModalFooter>
                  <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Save</button>
                </ModalFooter>
              </AvForm>
            </Card>
          </Col>
        </Row>
      </React.Fragment >
    )
  }
}

