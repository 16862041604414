import React, { useState, useEffect } from "react";
import { Row, Col, Card, Alert, CardBody, Media, Button } from "reactstrap";

import Helmet from "react-helmet";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

import { withRouter } from "react-router-dom";

import avatar from "../../assets/images/users/user-1.jpg";
import Breadcrumb from "../../components/Common/Breadcrumb";

import ProfileSettings from "./profile-sidebar";
import { ApiUrl } from "../../config";
import { uToken } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";// actions

//Import Action to copy breadcrumb items from local state to redux state

const UserProfile = (props) => {
    const [user, setUser] = useState("");
    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            setUser(obj);
        }
    }, [!user]);

    const breadcrumbItems = [{ title: "Change Password", link: "#" }];

    function handleValidSubmit(event, values) {
        fetch(`${ApiUrl}` + "change/password", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                if (data.status === true) {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    return (
        <React.Fragment>

            <Helmet>
                <title>Change Password </title>
            </Helmet>
            <ToastContainer />
            <Row>
                <Col lg="2" className="pl-0">
                    <ProfileSettings menuOpen={false} props={props} />
                </Col>
                <Col lg="10">
                    <Breadcrumb title={"Change Password"} breadcrumbItems={breadcrumbItems} />
                    <Card>
                        <CardBody>
                            <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                    handleValidSubmit(e, v);
                                }}
                            >
                                <div className="form-group mb-3">
                                    <AvField
                                        name="current_password"
                                        label="Old password"
                                        className="form-control"
                                        placeholder="Enter old password"
                                        type="password"
                                        required
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="new_password"
                                        label="New password"
                                        className="form-control"
                                        placeholder="Enter new password"
                                        type="password"
                                        required
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <AvField
                                        name="new_confirm_password"
                                        label="Confirm password"
                                        className="form-control"
                                        placeholder="Confirm password"
                                        type="password"
                                        required
                                    />
                                </div>
                                <div className="mt-4">
                                    <Button type="submit" color="danger">
                                        Update password
                                    </Button>
                                </div>
                            </AvForm>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default UserProfile;
