import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import { ApiUrl, ProductName } from "../../../config";
import Context from './Context';
import swal from "sweetalert";
import $ from "jquery";
import { dcrypt, bcrypt, uInfo, uToken } from "../../../useToken";

const Box1 = (props) => {
    const params = React.useContext(Context);
    const [totalFininsh, setTotalFinish] = useState(0);
    const [record, setRecord] = useState({});

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        submitData.append('user_id', uInfo().user_id);
        submitData.append('id', params.form_data.id);
        fetch(`${ApiUrl}` + "update/system/form", {
            method: "POST",
            body: submitData,
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    swal({
                        title: "Action performed successfully",
                        icon: "success",
                    });
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    // useEffect(() => {
    //     $(".image-checkbox").each(function () {
    //         if ($(this).find('input[type="checkbox"]').first().attr("checked")) {
    //             $(this).addClass('image-checkbox-checked');
    //         } else {
    //             $(this).removeClass('image-checkbox-checked');
    //         }
    //     });

    //     $(".image-checkbox").on("click", function (e) {

    //         $(this).toggleClass('image-checkbox-checked');
    //         var $checkbox = $(this).find('input[type="checkbox"]');
    //         $checkbox.prop("checked", !$checkbox.prop("checked"))
    //         let selected = $('.check-fininh-statement').filter(':checked').length;
    //         if (selected >= 4) {
    //             $(".image-checkbox input:checkbox:not(:checked)").each(function () {
    //                 $(this).closest(".image-checkbox").addClass("disabled");
    //             });
    //         } else {
    //             $('.image-checkbox').removeClass('disabled');
    //         }
    //         e.preventDefault();

    //     });
    // });
    useEffect(() => {
        getData();

    }, []);

    const getData = () => {
        fetch(`${ApiUrl}` + "get/systemForm/resourceInfo", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: params.form_data.id, user_id: uInfo().user_id }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setRecord(response.data.record);

                    $(".image-checkbox").each(function () {
                        if ($(this).find('input[type="checkbox"]').first().attr("checked")) {
                            $(this).addClass('image-checkbox-checked');
                        } else {
                            $(this).removeClass('image-checkbox-checked');
                        }
                    });

                    $(".image-checkbox").on("click", function (e) {

                        $(this).toggleClass('image-checkbox-checked');
                        var $checkbox = $(this).find('input[type="checkbox"]');
                        $checkbox.prop("checked", !$checkbox.prop("checked"))
                        let selected = $('.check-fininh-statement').filter(':checked').length;
                        if (selected > 4) {
                            $(".image-checkbox input:checkbox:not(:checked)").each(function () {
                                $(this).closest(".image-checkbox").addClass("disabled");
                            });
                        } else {
                            $('.image-checkbox').removeClass('disabled');
                        }
                        e.preventDefault();

                    });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const finish_statements = [
        {
            'id': 1,
            'title': 'Generate immediate, new sale opportunities',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        },
        {
            'id': 2,
            'title': 'Create competition-crushing marketing',
            'description': ' ',
            'icon': 'theme_4_home4_icon2.png'
        },
        {
            'id': 3,
            'title': 'Develop compelling offers',
            'description': '',
            'icon': 'theme_4_home4_icon3.png'
        },
        {
            'id': 4,
            'title': 'Create sales processes and procedures',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        },
        {
            'id': 5,
            'title': 'Dramatically increase net profits',
            'description': '',
            'icon': 'theme_4_home4_icon2.png'
        },
        {
            'id': 6,
            'title': 'Implement digital marketing campaigns',
            'description': '',
            'icon': 'theme_4_home4_icon3.png'
        },
        {
            'id': 7,
            'title': 'Craft a unique selling proposition',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        },
        {
            'id': 8,
            'title': 'Build a comprehensive business plan',
            'description': ' ',
            'icon': 'theme_4_home4_icon2.png'
        },
        {
            'id': 9,
            'title': 'Reduce wasteful spending in variable and fixed-costs',
            'description': '',
            'icon': 'theme_4_home4_icon3.png'
        },
        {
            'id': 10,
            'title': 'Create unique, engaging content that drives new leads',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        },
        {
            'id': 11,
            'title': 'Implement time management skills',
            'description': '',
            'icon': 'theme_4_home4_icon2.png'
        },
        {
            'id': 12,
            'title': 'Develop clear, concise, and actionable goals',
            'description': '',
            'icon': 'theme_4_home4_icon3.png'
        },
        {
            'id': 13,
            'title': 'Find new strategic partnerships to nurture future growth',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        },
        {
            'id': 14,
            'title': 'Financial resources and advice for scalability',
            'description': ' ',
            'icon': 'theme_4_home4_icon2.png'
        }
    ];


    return (
        <div>
            <div className="mt-1">
                <div className="d-flex align-items-center gap-2 StepHeading">
                    <div className="StepNumber">{params.form_data.id} <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">{params.form_data.name}</h4>
                </div>
                <form method="post" onSubmit={handleSubmit}
                    encType="multipart/form-data" className="form-horizontal" id="selection-myform">


                    <div className="p-2 mt-2">
                        <div className="page-content-box">
                            {/* <div className="TitleWizred d-flex align-items-center gap-2">
                                <span className="fs-5"> b.</span> <h5>Finish this statement: As a {ProductName}, I understand how to help business owners..</h5>
                            </div> */}
                            <div className="WizredForm mt-2">
                                <div className="form-group">
                                    <label>Select 4 of the following options</label>

                                    {
                                        record.finish_statement &&
                                        finish_statements.map((row, i) => {
                                            return (
                                                <div className="position-relative nopad finishStatement" key={`${row.id}`}>
                                                    <label className="image-checkbox align-items-center d-flex">
                                                        <h5>{row.title}</h5>
                                                        <input defaultChecked={record.finish_statement?.includes(`${row.id}`) ? true : false} name="finish_statement[]" className="check-fininh-statement" value={`${row.id}`} type="checkbox" />
                                                        <i className="fa fa-check d-none"></i>
                                                    </label>
                                                </div>
                                            )
                                        })

                                    }



                                </div>
                            </div>
                        </div>
                    </div>


                    <button type="submit" className="btn btn-sm btn-primary mt-2"> Ok &nbsp;<i className="fas fa-check"></i></button>
                </form>
            </div>
        </div>
    );
};

export default Box1;