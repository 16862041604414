import React, { Component, Fragment, useState } from "react"
import { Link } from "react-router-dom";
import { ApiUrl } from "../../config";
import Context from './Context';
import swal from "sweetalert";
import $ from "jquery";
import { useEffect } from "react";
const Box1 = (props) => {
    const params = React.useContext(Context);
    const [totalFininsh, setTotalFinish] = useState(0);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        fetch(`${ApiUrl}` + "update/builder/setting/" + params.user_id, {
            method: "POST",
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    params.goToBox(params.currentBox + 1);
                    params.getBuilderSetting();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    useEffect(() => {

        $(".describe-image-checkbox").each(function () {
            if ($(this).find('input[type="checkbox"]').first().attr("checked")) {
                $(this).addClass('describe-image-checkbox-checked');
            } else {
                $(this).removeClass('describe-image-checkbox-checked');
            }


        });

        // sync the state to the input
        $(".describe-image-checkbox").on("click", function (e) {
            $(this).toggleClass('describe-image-checkbox-checked');
            var $checkbox = $(this).find('input[type="checkbox"]');
            $checkbox.prop("checked", !$checkbox.prop("checked"))
            let selected = $('.check-describe-statement').filter(':checked').length;
            if (selected >= 4) {
                $(".describe-image-checkbox input:checkbox:not(:checked)").each(function () {
                    $(this).closest(".describe-image-checkbox").addClass("disabled");
                });
            } else {
                $('.describe-image-checkbox').removeClass('disabled');
            }

            e.preventDefault();
        });
    });



    const describe_businesses = [
        {
            'id': 1,
            'title': 'Generate massive profits',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        },
        {
            'id': 2,
            'title': 'Provide accountability to clients',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        },
        {
            'id': 3,
            'title': 'Help business owners attain their goals',
            'description': '',
            'icon': 'theme_4_home4_icon3.png'
        },
        {
            'id': 4,
            'title': 'Implement scheduling freedom for maximum efficiency',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        },
        {
            'id': 5,
            'title': 'Create new business opportunities that do not require marketing budgets',
            'description': '',
            'icon': 'theme_4_home4_icon2.png'
        },
        {
            'id': 6,
            'title': 'Generate positive return on investments for clients',
            'description': '',
            'icon': 'theme_4_home4_icon3.png'
        },
        {
            'id': 7,
            'title': 'Experience and skills necessary to scale businesses',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        },
        {
            'id': 8,
            'title': 'Save thousands of dollars in wasteful spending',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        },
        {
            'id': 9,
            'title': 'Ability to implement marketing strategies designed to generate new leads',
            'description': '',
            'icon': 'theme_4_home4_icon3.png'
        },
        {
            'id': 10,
            'title': 'Financial expertise to grow profits while reducing overhead and wasteful spending',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        },
        {
            'id': 11,
            'title': 'Leverage partnership opportunities to create lasting growth',
            'description': '',
            'icon': 'theme_4_home4_icon2.png'
        },
        {
            'id': 12,
            'title': 'Generate higher conversion rates with specific, actionable strategies',
            'description': '',
            'icon': 'theme_4_home4_icon3.png'
        },
        {
            'id': 13,
            'title': 'Ability to facilitate new policies and procedures for expansion and scale',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        },
        {
            'id': 14,
            'title': 'Technical expertise to leverage CRM systems and increase the lifetime value of customers',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        },
        {
            'id': 15,
            'title': 'Generate new sales opportunities by turning inactive customers into repeat buyers',
            'description': '',
            'icon': 'theme_4_home4_icon3.png'
        },
        {
            'id': 16,
            'title': 'Increase customer value using down-sell, up-sell, and cross-sell strategies',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        }
    ];


    return (
        <div>
            <div className="box-center-align mt-3">
                <div className="SmallContaner my-4">
                    <div className="d-flex align-items-center gap-2 StepHeading">
                        <div className="StepNumber">4 <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">Copy/Text</h4>
                    </div>
                    <form method="post" onSubmit={handleSubmit}
                        encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                        <div className="border-top border-bottom border-left border-right p-4 mt-3">
                            <div className="page-content-box">
                                <div className="TitleWizred d-flex align-items-center gap-2">
                                    <span className="fs-5"> c.</span> <h5>
                                        What 4 statements best describe why someone should do business with you?</h5>
                                </div>
                                <div className="WizredForm mt-4">
                                    <div className="form-group">
                                        <label>Select 4 of the following options</label>

                                        {
                                            describe_businesses.map((row, i) => {
                                                return (
                                                    <div class="position-relative nopad finishStatement">
                                                        <label class="describe-image-checkbox align-items-center d-flex">
                                                            <h5>{row.title}</h5>
                                                            <input defaultChecked={params.setting.describe_business?.includes(`${row.id}`) ? true : false} name="describe_business[]" className="check-describe-statement" value={`${row.id}`} type="checkbox" />
                                                            <i class="fa fa-check d-none"></i>
                                                        </label>
                                                    </div>
                                                )
                                            })
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </div>


                        <button type="submit" className="btn btn-lg btn-primary mt-2"> Next &nbsp;<i className="fas fa-check"></i></button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Box1;