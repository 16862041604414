import React, { useState, useEffect, useRef } from "react";
import { ApiUrl, ProductName } from "../../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { bcrypt, dcrypt, uInfo, uToken } from "../../../../useToken";
import { Table, Card, CardBody, Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import SubSectionAccordion from "./SubSectionAccordion";

const SectionAccordion = ({ title, content, section_id, index, defaultSelection, defaultIndex, shown }) => {
  const [isActive, setIsActive] = useState(false);
  const [section_data, setsectiondata] = useState([]);
  const [hasSub, setHasSub] = useState(0);
  const [subCat, setSubCat] = useState([]);

  useEffect(() => {
    // if (shown) {
    getsectioncontent(section_id);
    // }
  }, []);

  const getsectioncontent = (section_id) => {
    if (section_id != undefined && section_id != 'undefined') {
      fetch(`${ApiUrl}` + "get/sectioncontent/" + section_id, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify({ user_id: uInfo().user_id }),
      })
        .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((response) => {

          if (response.status === true) {
            setHasSub(response.data.has_sub);
            setSubCat(response.data.sub_cat);
            setsectiondata(response.data.records);
          } else {


            toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        //Then with the error genereted...
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  return (
    <>
      {
        hasSub == 1 ? (
          <div className="sub-cat">
            {subCat.map((sub, index) => {
              return (
                <SubSectionAccordion
                  title={sub.title}
                  content={sub.title}
                  section_id={sub.cat_id}
                  index={index}
                  defaultIndex={''}
                  defaultSelection={''}
                  shown={false}
                />
              )
            })}
          </div>

        ) :
          (
            <Accordion defaultActiveKey={parseInt(defaultIndex)} className="border-0">
              <Accordion.Item eventKey={index} className={`${index} --aa -- ${defaultIndex} `}>
                <Accordion.Header>
                  {title}
                </Accordion.Header>
                <Accordion.Body>
                  <Table>
                    <tbody>
                      {
                        uInfo().type == 'Client' ? (
                          section_data.map((data, key) => (
                            <tr key={key} className={`${defaultSelection == data.content_id ? 'active' : ''} ${defaultSelection}--0 ${data.content_id}`}>
                              {
                                (key == 0) ?
                                  <td>

                                    {
                                      shown ? (
                                        <div className="grid-style">
                                          <Link to={"/training/product/contentvideo/" + bcrypt(data.cat_id) + "/" + bcrypt(data.content_id) + "/" + bcrypt(index)} params={{ content_id: data.content_id }} >
                                            {data.title}
                                          </Link>

                                          {
                                            data.id && (
                                              <i className="fas fa-check-circle"></i>
                                            )
                                          }

                                        </div>
                                      ) : (
                                        <div className="grid-style">
                                          <a data-id={section_data[key].id}>
                                            {data.title}
                                          </a>
                                        </div>
                                      )
                                    }




                                  </td>
                                  :

                                  (section_data[key - 1].id && shown) ?
                                    <td>
                                      <div className="grid-style">
                                        <Link to={"/training/product/contentvideo/" + bcrypt(data.cat_id) + "/" + bcrypt(data.content_id) + "/" + bcrypt(index)} params={{ content_id: data.content_id }} >
                                          {data.title}
                                        </Link>
                                        {
                                          data.id && (
                                            <i className="fas fa-check-circle"></i>
                                          )
                                        }
                                      </div>
                                    </td>
                                    :
                                    <td>
                                      <div className="grid-style">
                                        <a data-id={section_data[key].id}>
                                          {data.title}
                                        </a>
                                      </div>

                                    </td>
                              }
                            </tr>
                          ))
                        ) :
                          (
                            section_data.map((data, key) => (
                              <tr key={key} className={`${defaultSelection == data.content_id ? 'active' : ''} ${defaultSelection}--0 ${data.content_id}`}>
                                {
                                  <td>
                                    <div className="grid-style">
                                      <Link to={"/training/product/contentvideo/" + bcrypt(data.cat_id) + "/" + bcrypt(data.content_id) + "/" + bcrypt(index)} params={{ content_id: data.content_id }} >
                                        {data.title}
                                      </Link>

                                      {/* {
                                data.id && (
                                  <i className="fas fa-check"></i>
                                )
                              } */}

                                    </div>

                                  </td>
                                }
                              </tr>
                            ))
                          )
                      }
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>


            </Accordion >
          )
      }
    </>
  );
};

export default SectionAccordion;
