import React, { Component, Fragment, useState,useEffect } from "react"
import { Link } from "react-router-dom";
import { ApiUrl } from "../../../config";
import Context from './Context';
import swal from "sweetalert";
import { dcrypt,bcrypt, uInfo, uToken } from "../../../useToken";

const Box9 = (props) => {
    const params = React.useContext(Context);
    const [record, setRecord] =  useState({});


    useEffect(() => {
        getData();
      
    }, []);
    const getData = () => {
        fetch(`${ApiUrl}` + "get/systemForm/resourceInfo", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
              },
            body: JSON.stringify({ id: params.form_data.id, user_id: uInfo().user_id}),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setRecord(response.data.record);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        submitData.append('user_id', uInfo().user_id);
        submitData.append('id', params.form_data.id);
        fetch(`${ApiUrl}` + "update/system/form", {
            method: "POST",
            body: submitData,
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    swal({
                        title: "Action performed successfully",
                        icon: "success",
                    });
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    return (
        <div>
            <div className="mt-1">
                    <div className="d-flex align-items-center gap-2 StepHeading">
                        <div className="StepNumber">{params.form_data.id} <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">{params.form_data.name}</h4>
                    </div>
                    <div className="page-content-box">
                        <form method="post" onSubmit={handleSubmit}
                            encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                            <div className="WizredForm mt-1">
                                <div className="p-2">
                                { record.has_blog && (
                                    <div className="radio-box mt-3">
                                        <label>Do you want a "blog" section on your website?</label>
                                        <br />
                                        <div class="form-check form-check-inline">
                                            <input name="has_blog" defaultChecked={record.has_blog == 'Yes' ? true : false} class="form-check-input" type="radio" id="inlineRadio1" value="Yes" />
                                            <label class="form-check-label" for="inlineRadio1">Yes</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input name="has_blog" defaultChecked={record.has_blog == 'No' ? true : false} class="form-check-input" type="radio" id="inlineRadio2" value="No" />
                                            <label class="form-check-label" for="inlineRadio2">No</label>
                                        </div>
                                    </div>
                                )}
                                </div>



                            </div>
                            <button type="submit" className="btn btn-sm btn-primary mt-2"> Ok &nbsp;<i className="fas fa-check"></i></button>
                        </form>
                    </div>
            </div>
        </div>
    );
};

export default Box9;