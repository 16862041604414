import React, { Component, Fragment } from "react"
import { Row, Col, Card, CardBody, Button, Modal, ModalBody, ModalFooter } from "reactstrap"
import { uToken } from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import ProfileSettings from "../Authentication/profile-sidebar";
import Loader from "react-loader";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import ReactDatatable from "@ashvin27/react-datatable";
import 'suneditor/dist/css/suneditor.min.css';
export default class ManageProGlobalNavigation extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                text: "Id#",
                key: "id",
                cell: (record, index) => {
                    return index + 1;
                }
            },

            {
                text: "Title",
                key: "title",
            },
            {
                text: "Slug",
                key: "slug",
            },
            {
                text: "Created Date",
                key: "created_at",
            },
            {
                key: "action",
                text: "Action",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <button onClick={() => { this.setState({ row: record, modal: true }) }} className="btn btn-sm btn-secondary mr-5">
                                <i className="mdi mdi-pencil"></i>
                            </button>
                            <button onClick={() => { this.removeProduct(record.id) }} className="btn btn-sm btn-danger">
                                <i className="mdi mdi-trash-can-outline"></i>
                            </button>
                            {/* )} */}
                        </Fragment>
                    );
                }
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: false,
                print: false,
                csv: false,
            },
        };
        this.state = {
            records: [],
            loader: true,
            page_name: 'ProGlobal Navigation listings',
            modal: false,
            modal2: false,
            row: {
                id: '',
                master_id: '',
                title: '',
                slug: '',
                type: '',
            },
            masterMenuItems: [],
        }
    }


    componentDidMount() {
        this.getData();
    }

    getData = () => {
        fetch(`${ApiUrl}` + 'get/proglobal/navigations', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + uToken()
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {

                    this.setState({
                        records: response.data.records,
                        masterMenuItems: response.data.menuItems,
                    });

                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                    // toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }

            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }

    submitPage = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        fetch(`${ApiUrl}` + "add/proglobal/navigations", {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    this.setState({
                        row: {
                            id: '',
                            master_id: '',
                            title: '',
                            slug: '',
                            type: '',

                        },
                        modal: false
                    })
                    this.getData();
                } else {
                    swal({
                        title: data.message,
                        icon: "warning",
                    });
                    // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
                this.setState({ loader: true });
            })
            .catch((error) => {
                this.setState({ loader: true });

                console.error("Error:", error);
            });
    }


    handleTypeChange = (event) => {
        const typeValue = event.target.value;
        this.setState({
            row: {
                ...this.state.row,
                type: typeValue,
                master_id: typeValue === '1' ? '' : this.state.row.master_id,
            }
        });
    };

    removeProduct = (id) => {
        swal({
            title: "Are you sure?",
            text: "You want to remove this record.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "delete/proglobal/navigations", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                        body: JSON.stringify({ id: id }),
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.status === true) {
                                this.getData();

                                // toast.success(data.message, {
                                //   theme: "colored",
                                //   autoClose: ToastAutoClose,
                                // });
                            } else {
                                swal({
                                    title: data.message,
                                    icon: "warning",
                                });
                            }
                        })
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    }
    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="2" className="pl-0">
                        <ProfileSettings menuOpen={false} props={this.props} />
                    </Col>
                    <Col lg="10">
                        <Loader loaded={this.state.loader}></Loader>
                        <Row>
                            <Col sm={6} >
                                <div className="page-title-box">
                                    <h4> {this.state.page_name} </h4>
                                    <ol className="breadcrumb m-0" >
                                        <li key={0} className="breadcrumb-item active"> {ProductName} </li>
                                        <li key={1} className="breadcrumb-item"> < Link to="#"> {this.state.page_name} </Link></li>
                                    </ol>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="page-title-box text-align-right ">
                                    <Button type="button"
                                        color="info"
                                        onClick={
                                            () => this.setState({
                                                modal: true, row: {
                                                    id: '',
                                                    master_id: '',
                                                    title: '',
                                                    slug: '',
                                                    type: '',

                                                }
                                            })}>
                                        <i className="mdi mdi-plus"></i> Create
                                    </Button>
                                </div>

                            </Col>
                        </Row>

                        <Modal
                            isOpen={this.state.modal}
                            fade={false}
                            toggle={() => this.setState({ modal: false })}
                        >
                            <form onSubmit={this.submitPage} method="post"
                                encType="multipart/form-data" className="form-horizontal" id="selection-myform-faqs">
                                <ModalBody>
                                    <Row>
                                        <Col md="12">
                                            <div className="form-group mb-3">
                                                <label>Enter Title</label>
                                                <input
                                                    name="title"
                                                    defaultValue={this.state.row.title}
                                                    className="form-control"
                                                    placeholder="Enter title"
                                                    required
                                                />
                                                <input
                                                    name="id"
                                                    defaultValue={this.state.row.id}
                                                    type='hidden'
                                                />
                                            </div>
                                        </Col>

                                        <Col md="12">
                                            <div className="form-group mb-3">
                                                <label>Select Type</label>
                                                <select
                                                    name="type"
                                                    className="form-control"
                                                    onChange={this.handleTypeChange}
                                                    value={this.state.row.type}
                                                >
                                                    <option value=''>Select Type</option>
                                                    <option value='0'>Menu</option>
                                                    <option value='1'>Sub-menu</option>
                                                </select>
                                            </div>
                                        </Col>
                                        {this.state.row.type === '1' && (
                                            <Col md="12">
                                                <div className="form-group mb-3">
                                                    <label>Select Master Menu</label>
                                                    <select
                                                        name="master_id"
                                                        className="form-control"
                                                        onChange={(e) => this.setState({ row: { ...this.state.row, master_id: e.target.value } })}
                                                        value={this.state.row.master_id}
                                                        required
                                                    >
                                                        <option value=''>Select Master Menu</option>
                                                        {this.state.masterMenuItems.map(item => (
                                                            <option key={item.id} value={item.id}>{item.title}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </Col>
                                        )}


                                    </Row>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" type="button" onClick={() => this.setState({ modal: false })}>Cancel</Button>
                                    <Button className="Green" type="submit">Submit</Button>
                                </ModalFooter>
                            </form>
                        </Modal>

                        <Card>
                            <CardBody>
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment >
        )
    }
}