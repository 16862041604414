import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  hasRole,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import AvInput from "availity-reactstrap-validation/lib/AvInput";
import Create from "./create";
import Update from "./update";
import swal from 'sweetalert';

export default class CallerStatus extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        text: "Sr#",
        key: "sr",
      },
      {
        text: "Unsubscribe Keyword",
        key: "key_text",
      },
      {
        text: "Created At",
        key: "created_at",
      },
      {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          return (
            <Fragment>
              <button
                type="button"
                color="info"
                className="btn btn-primary btn-sm mb-1 mr-5"
                onClick={() => { this.setState({ show4Slider: true, page2Class: 'slide-in', sliderShown: 'show4Slider', editId: record.id }) }}
              >
                <i className="mdi mdi-pencil"></i>
              </button>
              <button
                className="btn btn-danger btn-sm mb-1"
                onClick={this.deleteRecord.bind(this, record, index)}
              >
                <i className="mdi mdi-delete"></i>
              </button>
              {/* )} */}
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: false,
      show_pagination: true,
      button: {
        excel: false,
        print: false,
        csv: false,
      },
    };
    this.state = {
      records: [],
      roles: [],
      total_pages: 0,
      modal: false,
      record: {},
      filter: {},
      page_name: "Unsubscribe Keywords",
      customDate: true,
      ClientSubcribeModal: false,
      currentClient: {
        id: "",
        email_status: "",
        call_status: "",
        groups: [],
        tags: [],
      },
    };
  }
  deleteRecord = (record, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "delete/unsubkeyword", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: record.id }),
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                this.getData();
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            //Then with the error genereted...
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
  };

  componentDidMount() {
    this.getData();
  }

  getData = (queryString = "", data) => {
    fetch(`${ApiUrl}` + "unsubkeywords" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {

        if (response.status === true) {
          console.log(response, 'debugging');
          this.setState({
            total_pages: response.data.total,
            records: response.data.records,
            roles: response.data.roles,
          });

        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleFilterSubmit = async (event, values) => {
    this.setState({
      filter: values,
    });
    this.getData("", values);
  };

  cancelFilter = async () => {
    this.form && this.form.reset();
    this.setState({ filter: {}, customDate: true });
    this.getData("", "");
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData("?" + queryString, this.state.filter);
  };

  cancelSlider = async () => {
    this.getData("", "");
    this.setState({
      page2Class: 'slide-out2',
      editId: '',
      sliderShown: ''
      // showSlider: false,
    })
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {this.state.page_name}
            Tables
          </title>
        </Helmet>
        <Row>
          <Col sm={5}>
            <div className="page-title-box">
              <h4>{this.state.page_name}</h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#">
                    {this.state.page_name}
                  </Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={5}>
          </Col>
          <Col sm={2}>
            <div className="page-title-box text-align-right">
              {isAllowed(uInfo(), ["can_create"]) && (
                <button
                  type="button"
                  color="info"
                  className="btn btn-info"
                  onClick={() => { this.setState({ show3Slider: true, page2Class: 'slide-in', sliderShown: 'show3Slider' }) }}
                >
                  <i className="mdi mdi-plus"> </i>
                  Create New
                </button>
              )}
            </div>
          </Col>
        </Row>
        <ToastContainer />

        {
          this.state.show3Slider && this.state.sliderShown == 'show3Slider' && (
            <div className={`page3 ${this.state.page2Class} custom-scroll`} >
              <div>
                <div className="modal-head top-heading">
                  <h2>Add Keyword</h2>
                  <button onClick={() => { this.setState({ page2Class: 'slide-out2' }); }} className="btn btn-link text-white">
                    <i className="fas fa-times"></i>
                  </button>
                </div>
                <Create cancelSlider={this.cancelSlider} />
              </div>
            </div>
          )
        }

        {
          this.state.show4Slider && this.state.sliderShown == 'show4Slider' && (
            <div className={`page3 ${this.state.page2Class} custom-scroll`} >
              <div>
                <div className="modal-head top-heading">
                  <h2>Edit Keyword</h2>
                  <button onClick={() => { this.setState({ page2Class: 'slide-out2' }); }} className="btn btn-link text-white">
                    <i className="fas fa-times"></i>
                  </button>
                </div>
                <Update cancelSlider={this.cancelSlider} id={this.state.editId} />
              </div>
            </div>
          )
        }
        <Row>
          <Col className="col-12">
            <Card>
           
              <div className="alert alert-info fade show" role="alert"><strong>Default Unsub Keywords: </strong>
                <p>
                  Your clients can also opt out of receiving text messages from you at any time by texting in words “STOP,” “QUIT,” “UNSUBSCRIBE,” or “CANCEL.”
                </p>
                <p className="text-break"> Replying above reserved keywords will stop the customer from receiving messages from that particular number, but replying "STOP PLEASE" or "PLEASE CANCEL" will not unsubscribe until you add this type of custom keywords in below list.</p>
              </div>
             
              <CardBody>
                <ReactDatatable
                  config={this.config}
                  records={this.state.records}
                  columns={this.columns}
                  dynamic={true}
                  total_record={this.state.total_pages}
                  onChange={this.tableChangeHandler}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
