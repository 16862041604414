export const logoLightPng = require('../../assets/images/logo-dark.png');

export const bgColor = 'green';
export const textColor = '#ffffff';
export const navTextColor = '#ffffff';
export const navTextHoverColor = '#ffffff';


export const btnPrimaryTextColor = '#ffffff';
export const btnPrimaryBgColor = '#3a3b3d';
export const btnPrimaryHoverText = '#ffffff';
export const btnPrimaryBgHoverColor = '#f16321';

export const btnDangerTextColor = '#ffffff';
export const btnDangerBgColor = '#ec536c';
export const btnDangerHoverText = '#ffffff';
export const btnDangerBgHoverColor = '#c9475c';

export const btnInfoTextColor = '#ffffff';
export const btnInfoBgColor = '#29bbe3';
export const btnInfoHoverText = '#ffffff';
export const btnInfoBgHoverColor = '#239fc1';

export const ProductName = "PG Test Office";
