import React, { useState, useCallback, useEffect } from 'react';
import { ApiUrl } from '../../config';
import { uInfo, uToken, uuidv4 } from '../../useToken';
import Lobby from './Lobby';
import Room from './Room';

import { PusherKey } from "../../config";
import Pusher from "pusher-js";
import swal from "sweetalert";
const VideoChat = ({ sendMessage, loginStatus }) => {
    const [username, setUsername] = useState('host-' + uInfo().user_id);
    const [hostid] = useState(uInfo().user_id);
    const [roomName, setRoomName] = useState(uuidv4());
    const [token, setToken] = useState(null);
    const [page8Class, setpage8Class] = useState("");

    const handleUsernameChange = useCallback(event => {
        setUsername(event.target.value);
    }, []);

    const handleRoomNameChange = useCallback(event => {
        setRoomName(event.target.value);
    }, []);


    useEffect(() => {
        const pusher = new Pusher(PusherKey, {
            cluster: 'mt1'
        })
        const channel1 = pusher.subscribe('my-channel');
        // You can bind more channels here like this
        // const channel2 = pusher.subscribe('channel_name2')
        channel1.bind('room-joining', function (data) {
            console.log(data, 'ras')
            if (
                data.data.user_id == uInfo().user_id
                && data.data.room_id == roomName
            ) {
                swal({
                    title: 'Invitation for joining video call from ' + data.data.name,
                    text: "",
                    icon: "info",
                    buttons: true,
                    dangerMode: true,
                    buttons: ["Cancel", "Join"],
                }).then((willDelete) => {
                    if (willDelete) {
                        actionVideoRequest(data.data.name, 'confirmed');
                    } else {
                        actionVideoRequest(data.data.name, 'cancelled');
                    }
                });
            }
            // Code that runs when channel1 listens to a new message
        })

        return (() => {
            pusher.unsubscribe('my-channel')
            // pusher.unsubscribe('channel_name2')
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const actionVideoRequest = (name, status) => {
        fetch(`${ApiUrl}` + "joining/request/update", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ room_id: roomName, name: name, status: status }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {

                } else {
                    swal({
                        title: 'Something is not right',
                        text: "",
                        icon: "info",
                    })
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const handleSubmit = useCallback(

        async event => {
            event.preventDefault();
            // console.log(roomName);
            const data = await fetch(ApiUrl + 'video/token', {
                method: 'POST',
                body: JSON.stringify({
                    identity: username,
                    room: roomName,
                    'type': 'group'
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + uToken(),
                }
            }).then(res => res.json());
            setToken(data.data.token);
            // setpage8Class("slide-in");
        },
        [roomName, username]
    );

    const handleLogout = useCallback(event => {
        setToken(null);
    }, []);

    let render;
    if (token) {
        render = (

            <div className='host-video'>
                <Room from="host" hostId={hostid} username={username} roomName={roomName} token={token} handleLogout={handleLogout} />



            </div>


        );
    } else {

        render = (
            <Lobby
                from={'host'}
                username={username}
                roomName={roomName}
                handleUsernameChange={handleUsernameChange}
                handleRoomNameChange={handleRoomNameChange}
                handleSubmit={handleSubmit}
            />
        );

    }
    return render;
};

export default VideoChat;