import React, { useState, useCallback, useEffect } from 'react';
import { ApiUrl } from '../../config';
import { uInfo, uToken, uuidv4 } from '../../useToken';
import Lobby from './Lobby';
import Room from './Room';
import { PusherKey } from "../../config";
import Pusher from "pusher-js";
import swal from "sweetalert";
import { logoLightPng, logoLightSmallPng } from "../../config.js";
import {
    Row,
    Col,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Container,
} from "reactstrap";

const VideoChat = (props, { sendMessage, loginStatus }) => {
    const [username, setUsername] = useState('');
    const [roomName, setRoomName] = useState(props.match.params.roomid);
    const [hostid, setHostId] = useState(props.match.params.hostid);
    const [uId] = useState(uuidv4());
    const [lock, setLock] = useState(false);

    const [token, setToken] = useState(null);
    const [page8Class, setpage8Class] = useState("");

    const handleUsernameChange = useCallback(event => {
        setUsername(event.target.value);
    }, []);

    const handleRoomNameChange = useCallback(event => {
        setRoomName(event.target.value);
    }, []);


    useEffect(() => {
        const pusher = new Pusher(PusherKey, {
            cluster: 'mt1'
        })
        const channel1 = pusher.subscribe('my-channel');
        // You can bind more channels here like this
        // const channel2 = pusher.subscribe('channel_name2')
        channel1.bind('room-joined', function (data) {
            console.log(data, 'hello ras', uId,
                roomName)
            if (
                data.data.uId == uId
                && data.data.room_id == roomName
            ) {
                if (data.data.status == 'confirmed') {
                    setLock(false);
                    handleSubmit(data.data.name);
                } else {
                    swal({
                        title: 'Your request has cancelled',
                        text: "",
                        icon: "danger",
                    });
                }
            }
            // Code that runs when channel1 listens to a new message
        })

        return (() => {
            pusher.unsubscribe('my-channel')
            // pusher.unsubscribe('channel_name2')
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const actionVideoRequest = useCallback(

        async event => {
            event.preventDefault();
            fetch(`${ApiUrl}` + "request/join/video/room", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + uToken(),
                },
                body: JSON.stringify({ room_id: roomName, identity: username, host_id: hostid, uId: uId }),
            })
                .then((response) => response.json())
                .then((response) => {
                    if (response.status === true) {
                        swal({
                            title: response.message,
                            text: "",
                            icon: "success",
                        });
                        setLock(true);
                    } else {
                        swal({
                            title: response.message,
                            text: "",
                            icon: "danger",
                        })
                    }
                })
                //Then with the error genereted...
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    );


    const handleSubmit = (username) => {
        fetch(`${ApiUrl}` + "public/video/token", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({
                identity: username,
                room: roomName,
                host_id: hostid
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response)
                setToken(response.data.token);
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });

    };

    const handleLogout = useCallback(event => {
        setToken(null);
        window.location.reload();
    }, []);

    let render;

    if (token) {
        render = (
            <div className='remoote-video'>
                <Room from="remote" username={username} hostId={hostid} roomName={roomName} token={token} handleLogout={handleLogout} />
            </div>
        );
    } else {

        if (lock) {
            render = (

                <h5>Waiting....</h5>

            );

        } else {
            render = (

                <Lobby
                    from={'remote'}
                    username={username}
                    roomName={roomName}
                    handleUsernameChange={handleUsernameChange}
                    handleRoomNameChange={handleRoomNameChange}
                    handleSubmit={actionVideoRequest}
                />
            );
        }

    }

    return (
        <React.Fragment>
            <div className="navbar-header">
                <Container fluid>
                    <Row>
                        <Col lg={12} className="nav-bg mt-0 px-0 d-flex justify-content-center">
                            <div className="">
                                <div className="navbar-brand-box centerr-logo ">
                                    <a to="#" className="logo">
                                        <span className="logo-lg p-3 pb-4">
                                            <img src={logoLightPng} alt="" height="45" />
                                        </span>
                                    </a>


                                </div>

                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            {render}
                        </Col>
                    </Row>
                </Container>

            </div >



        </React.Fragment >
    )


};

export default VideoChat;