import React from 'react';
// import './App.css';
import VideoChat from './VideoChat';

const VideoMeeting = () => {
  return (
    <VideoChat />
  );
};

export default VideoMeeting;
