import React, { Component, createRef } from "react";
import {
    Row,
    Col,
    Card,
} from "reactstrap";

import $ from "jquery";
import { ApiUrl } from "../../config";
import { dcrypt, uToken } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";

window.jQuery = $;
window.$ = $;

require("formBuilder");
require("jquery-ui-sortable");
require('formBuilder/dist/form-render.min.js')
// require("formRender");
export default class FormBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id ? dcrypt(this.props.match.params.id) : null,
        }

        this.updateFormBuilder = this.updateFormBuilder.bind(this)
    }
    componentDidMount() {
        var self = this;
        fetch(`${ApiUrl}` + "get/edit/contract", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },

            body: JSON.stringify({ id: this.state.id },
            ),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    var $fbEditor = $(document.getElementById('fb-editor')),
                        $formContainer = $(document.getElementById('fb-rendered-form')),
                        fbOptions = {
                            formData: response.data.record.metadata,
                            onSave: function () {
                                $fbEditor.toggle();
                                $formContainer.toggle();
                                self.updateFormBuilder(formBuilder.formData);
                                $('form', $formContainer).formRender({
                                    formData: formBuilder.formData
                                });
                            },
                            disabledActionButtons: ['data'],
                            disableFields: ['autocomplete', 'file', 'hidden']
                        },
                        formBuilder = $fbEditor.formBuilder(fbOptions);

                    $('.edit-form', $formContainer).on('click', function () {
                        $fbEditor.toggle();
                        $formContainer.toggle();
                    });
                } else {
                              
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });



        // $(this.fb.current).formBuilder(fbOptions);
    }

    updateFormBuilder = (data) => {
        fetch(`${ApiUrl}` + "update/contract/metadata", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: this.state.id, metadata: data }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                if (data.status === true) {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                    window.location.reload();
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    render() {

        return (
            <>
                <div className="pb-5">
                    <Link
                        to={'/contract/list'}
                        className="btn btn-ld my-3 btn-secondary">
                        Back
                    </Link>
                    <div id="fb-editor"></div>
                    <div id="fb-rendered-form">
                        <form action="#"></form>
                        <button className="btn btn-primary">Edit Form</button>
                    </div>
                </div>
            </>
        )
    }
}

