import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
} from "availity-reactstrap-validation";
import {
  currentDateTime,
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
// import '../../../node_modules/bootstrap/js/dist/tooltip';
import Select from "react-select";
import countries from "../../../common/data/countries";
import AsyncSelect from "react-select/async";

export default class CreateUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      record: {
        group_id: '',
      },
      clients: [],
      clients_id: 0,
      permissions: [],
      permission: [],
      emails: [],
      phoneNumbers: [],
      email_reminder: false,
      sms_reminder: false,
      compannyId: this.props.compannyId ? this.props.compannyId : "",
      pipelines: [],
      type: "",
      campaign_id: props.campaign_id,
      runs: [
        {
          label: "Once",
          value: "once",
        },
        {
          label: "Multiple Times",
          value: "multiple",
        },
      ],
      trigger_type: [
        {
          label: "Trigger",
          value: "Trigger",
        },
        {
          label: "Drip",
          value: "Drip",
        },
        {
          label: "Event Reminder",
          value: "EventReminder",
        },
      ],
      selectedruntype: "",
      selected_trigger_type: "",
    };
  }

  componentDidMount() {
    this.getFormData();
  }

  getFormData = () => {
    fetch(`${ApiUrl}` + "get/campaign/groups", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ 'id': this.state.campaign_id })
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            groups: response.data.groups,
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  setruntype = (selectedruntype) => {
    this.setState({
      selectedruntype: selectedruntype,
    });
  };
  settriggertype = (selectedruntype) => {
    this.setState({
      selected_trigger_type: selectedruntype,
    });
  };
  onChangeTypeAction = (value) => {
    this.setState({
      type: value,
    });
  };
  handleGroup = (selectedGroup) => {
    this.setState(prevState => {
      let record = Object.assign({}, prevState.record);
      record.group_id = selectedGroup.group_id;
      return { record };
    })
  }
  handleSubmit = async (event, values) => {
    if (this.state.type != "") {
      values.event_type = this.state.type;
    }
    if (this.state.campaign_id != "") {
      values.campaign_id = this.state.campaign_id;
    }

    fetch(`${ApiUrl}` + "add/campaign/automation", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data, "ADD EDITING");
        if (data.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
          this.props.cancelSlider();
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });

        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col className="col-12">
            <Card>
              <AvForm
                onValidSubmit={this.handleSubmit}
                // model={this.state.record}
                ref={(c) => (this.form = c)}
                className="needs-validation"
              >
                <ModalBody className="row">
                  <div className="col-md-12 mb-3">
                    <AvField
                      name="title"
                      value={this.state.record.title}
                      label="Title"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="control-label"> Group </label>
                    <div className="CommonSelectTwo">
                      <Select
                        getOptionLabel={option => option.title}
                        getOptionValue={option => option.group_id}
                        value={this.state.groups.filter(({ group_id }) => group_id === this.state.record.group_id)}
                        isMulti={false}
                        options={this.state.groups}
                        classNamePrefix="select2-selection"
                        name="groups_id"
                        onChange={(e) => {
                          this.handleGroup(e)
                        }}
                      />
                      <AvField required type="hidden" name="group_id" value={this.state.record.group_id} />
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <AvGroup>
                      <label>Type </label>
                      <Select
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        isMulti={false}
                        options={this.state.trigger_type}
                        classNamePrefix="select form-control"
                        style={{ position: "absolute" }}
                        className={"is-touched is-dirty av-valid"}
                        name="trigger_type"
                        value={this.state.selected_trigger_type}
                        onChange={(e) => {
                          this.settriggertype(e);
                        }}
                      />
                      <AvField
                        required
                        type="hidden"
                        name="trigger_type"
                        value={this.state.selected_trigger_type.value}
                      />
                    </AvGroup>
                  </div>
                  {this.state.selected_trigger_type.value == 'Trigger' && (
                    <>
                      <div className="col-md-12 mb-3">
                        <AvGroup>
                          <label>Runs </label>
                          <Select
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            isMulti={false}
                            options={this.state.runs}
                            classNamePrefix="select form-control"
                            style={{ position: "absolute" }}
                            className={"is-touched is-dirty av-valid"}
                            name="run_type"
                            value={this.state.selectedruntype}
                            onChange={(e) => {
                              this.setruntype(e);
                            }}
                          />
                          <AvField
                            required
                            type="hidden"
                            name="run_type"
                            value={this.state.selectedruntype.value}
                          />
                        </AvGroup>
                      </div>
                      <div className="col-md-12 mb-3">
                        <label> Type </label>
                        <AvRadioGroup
                          value={this.state.type}
                          inline
                          name="event_type"
                          required
                          onChange={(e) => this.onChangeTypeAction(e.target.value)}
                        >
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3 radio-custoom-flow text-center"><div className={`status-radio ${this.state.type == '1' ? 'checked-radio' : ''}`}><i className="fas fa-phone-alt"></i><AvRadio value="1" /></div><label>Inbound Call</label></div>
                            <div className="col-lg-3 col-md-3 col-sm-3 radio-custoom-flow text-center"><div className={`status-radio ${this.state.type == '2' ? 'checked-radio' : ''}`}><i className="far fa-comment"></i><AvRadio value="2" /></div><label>Inbound SMS</label></div>
                            {/* <div className="col-lg-3 col-md-3 col-sm-3 radio-custoom-flow"><div className={`status-radio ${this.state.type == '3' ? 'checked-radio' : ''}`}><i className="fas fa-envelope"></i><AvRadio value="3" /></div><label>Email Responder</label></div>
                          <div className="col-lg-3 col-md-3 col-sm-3 radio-custoom-flow"><div className={`status-radio ${this.state.type == '4' ? 'checked-radio' : ''}`}><i className="fas fa-unlink"></i><AvRadio value="4" /></div><label>Click Link Email</label></div>
                          <div className="col-lg-3 col-md-3 col-sm-3 radio-custoom-flow"><div className={`status-radio ${this.state.type == '5' ? 'checked-radio' : ''}`}><i className="far fa-envelope"></i><AvRadio value="5" /></div><label>Open Read Email</label></div> */}
                            <div className="col-lg-3 col-md-3 col-sm-3 radio-custoom-flow text-center"><div className={`status-radio ${this.state.type == '6' ? 'checked-radio' : ''}`}><i className="fas fa-mobile-alt"></i><AvRadio value="6" /></div><label>RVM Fail</label></div>
                            {/* <div className="col-lg-3 col-md-3 col-sm-3 radio-custoom-flow"><div className={`status-radio ${this.state.type == '7' ? 'checked-radio' : ''}`}><i className="fas fa-users"></i><AvRadio value="7" /></div><label>Group Added</label></div>
                          <div className="col-lg-3 col-md-3 col-sm-3 radio-custoom-flow"><div className={`status-radio ${this.state.type == '8' ? 'checked-radio' : ''}`}><i className="fas fa-user-md"></i><AvRadio value="8" /></div><label>Group Removed</label></div>
                          <div className="col-lg-3 col-md-3 col-sm-3 radio-custoom-flow"><div className={`status-radio ${this.state.type == '9' ? 'checked-radio' : ''}`}><i className="fas fa-tag"></i><AvRadio value="9" /></div><label>Tag Added</label></div>
                          <div className="col-lg-3 col-md-3 col-sm-3 radio-custoom-flow"><div className={`status-radio ${this.state.type == '10' ? 'checked-radio' : ''}`}><i className="fab fa-xing-square"></i><AvRadio value="10" /></div><label>Tag Removed</label></div>
                          <div className="col-lg-3 col-md-3 col-sm-3 radio-custoom-flow"><div className={`status-radio ${this.state.type == '11' ? 'checked-radio' : ''}`}><i className="fas fa-microphone"></i><AvRadio value="11" /></div><label>VoiceMail Received</label></div>
                          <div className="col-lg-3 col-md-3 col-sm-3 radio-custoom-flow"><div className={`status-radio ${this.state.type == '12' ? 'checked-radio' : ''}`}><i className="fas fa-chart-bar"></i><AvRadio value="12" /></div><label>Deal Status Change</label></div> */}
                          </div>
                        </AvRadioGroup>

                      </div>
                    </>
                  )}
                  <div className="col-md-12 mb-3">
                    <AvField
                      name="status"
                      value={this.state.record.status}
                      label="Status"
                      className="form-control"
                      id="rs"
                      required
                      type="select"
                    >
                      <option value={""}>Select Status</option>
                      <option value={"Active"}>Active</option>
                      <option value={"Inactive"}>Inactive</option>
                    </AvField>
                  </div>
                </ModalBody>
                <ModalFooter className="justify-content-start">
                  <button
                    className="btn btn-primary w-md waves-effect waves-light"
                    type="submit"
                  >
                    Submit
                  </button>
                </ModalFooter>
              </AvForm>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
