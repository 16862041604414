import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardTitle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import Loader from "react-loader";

import { AvField, AvForm } from "availity-reactstrap-validation";
import { ApiUrl } from "../../config";
import { uToken } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
const Guides = (props) => {
  const [modal, setModal] = useState(false);
  const [id, setId] = useState("");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  function NumberWithCommas(x) {
    let y = Math.trunc(x);
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <React.Fragment>
      <h2 className="mb-3">
        {props.module && props.module.replaceAll("_", " ")}
      </h2>
      <Loader loaded={loaded}></Loader>
      <Col sm={12} className="breakthrough sub-nav-bg"><h6 className="mb-0">STRATEGY GUIDES</h6></Col>

      <Row>
        <Col sm={3}>
          <a className="waves-effect btn btn-light p-0" href="">
            <div className="card">
              <img
                src="https://profitaccelerationsoftware.s3.amazonaws.com/images/strategies/dentistg_img.jpg"
                alt="Lexa"
                className="img-fluid card-img-top"
              />
            </div>
            <div className="h6 card-title mt-3"> DENTIST STRATEGY GUIDE</div>
          </a>
        </Col>
        <Col sm={3}>
          <a className="waves-effect btn btn-light p-0" href="">
            <div className="card">
              <img
                src="https://profitaccelerationsoftware.s3.amazonaws.com/images/strategies/mortgageg_img.jpg"
                alt="Lexa"
                className="img-fluid card-img-top"
              />
            </div>
            <div className="h6 card-title  mt-3"> MORTGAGE BROKER STRATEGY GUIDE</div>
          </a>
        </Col>
        <Col sm={3}>
          <a className="waves-effect btn btn-light p-0" href="">
            <div className="card">
              <img
                src="https://profitaccelerationsoftware.s3.amazonaws.com/images/strategies/itg_img.jpg"
                alt="Lexa"
                className="img-fluid card-img-top"
              />
            </div>
            <div className="h6 card-title  mt-3">
              IT SERVICE PROFESSIONAL STRATEGY GUIDE
            </div>
          </a>
        </Col>
        <Col sm={3}>
          <a className="waves-effect btn btn-light p-0" href="">
            <div className="card">
              <img
                src="https://profitaccelerationsoftware.s3.amazonaws.com/images/strategies/dayg_img.jpg"
                alt="Lexa"
                className="img-fluid card-img-top"
              />
            </div>
            <div className="h6 card-title  mt-3">- DAYCARE STRATEGY GUIDE</div>
          </a>
        </Col>
      </Row>

      <Col sm={12} className="breakthrough sub-nav-bg"><h6 className="mb-0">TURNKEYS</h6></Col>
      <Row>
        <Col sm={3}>
          <a className="waves-effect btn btn-light p-0" href="">
            <div className="card">
              <img
                src="https://profitaccelerationsoftware.s3.amazonaws.com/images/strategies/dentistg_img.jpg"
                alt="Lexa"
                className="img-fluid card-img-top"
              />
            </div>
            <div className="h6 card-title mt-3"> DENTIST STRATEGY GUIDE</div>
          </a>
        </Col>
        <Col sm={3}>
          <a className="waves-effect btn btn-light p-0" href="">
            <div className="card">
              <img
                src="https://profitaccelerationsoftware.s3.amazonaws.com/images/strategies/mortgageg_img.jpg"
                alt="Lexa"
                className="img-fluid card-img-top"
              />
            </div>
            <div className="h6 card-title  mt-3"> MORTGAGE BROKER STRATEGY GUIDE</div>
          </a>
        </Col>
        <Col sm={3}>
          <a className="waves-effect btn btn-light p-0" href="">
            <div className="card">
              <img
                src="https://profitaccelerationsoftware.s3.amazonaws.com/images/strategies/itg_img.jpg"
                alt="Lexa"
                className="img-fluid card-img-top"
              />
            </div>
            <div className="h6 card-title  mt-3">
              IT SERVICE PROFESSIONAL STRATEGY GUIDE
            </div>
          </a>
        </Col>
        <Col sm={3}>
          <a className="waves-effect btn btn-light p-0" href="">
            <div className="card">
              <img
                src="https://profitaccelerationsoftware.s3.amazonaws.com/images/strategies/dayg_img.jpg"
                alt="Lexa"
                className="img-fluid card-img-top"
              />
            </div>
            <div className="h6 card-title  mt-3">- DAYCARE STRATEGY GUIDE</div>
          </a>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Guides;
