import React from "react";
import { uInfo } from "../../useToken";
import AdminDashboard from "./admin";
import CoachDashboard from "./coach";
import { Redirect } from "react-router-dom"

const Dashboard = (props) => {
  {
    uInfo().type == 'Client' && (
      props.history.push('/client/profile')
    )
  }
  return (
    <React.Fragment>
      {uInfo().type == 'WlAdmin' && (
        <AdminDashboard />
      )}
      {(uInfo().type == 'Coach' || uInfo().type == 'CoachPartner') && (
        <div className="NewCoachDashboard mt-3">
          <CoachDashboard />
        </div>
      )}
    </React.Fragment>
  );
};

export default Dashboard;
