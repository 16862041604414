import React, { Component, Fragment, useState,useEffect } from "react"
import { Link } from "react-router-dom";
import { ApiUrl } from "../../../config";
import Context from './Context';
import swal from "sweetalert";
import { uInfo, uToken, currentDateTime } from "../../../useToken";

import {
    AvForm,
    AvField,
    AvGroup,
    AvInput,
    AvFeedback,
  } from "availity-reactstrap-validation";
  import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    ModalBody,
    ModalFooter,
  } from "reactstrap";
const Box9 = (props) => {
    const params = React.useContext(Context);
    const [record, setRecord] =  useState({});
    const [holidays, setHolidays] = useState({});
    useEffect(() => {
        getData();
      
    }, []);
    const getData = () => {
        fetch(`${ApiUrl}` + "get/systemForm/resourceInfo", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
              },
            body: JSON.stringify({ id: params.form_data.id, user_id: uInfo().user_id}),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setRecord(response.data.record.constraint_settings);
                    setHolidays(response.data.record.holidays);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleSubmit = async (event, values) => {
        if (values.holidays && values.skip_weak_days) {
            values.holidays = values.holidays.toString();
            values.skip_weak_days = values.skip_weak_days.toString();
        }

        fetch(`${ApiUrl}` + "user/constraint/setting", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
        .then((response) => response.json())
        .then((data) => {
            if (data.status === true) {
            swal({
                text: 'Action performed successfully',
                icon: "success",
            });
            } else {
            
            }
        })
        .catch((error) => {
            console.error("Error:", error);
        });
    }
    return (
        <div>
            <div className="mt-1">
                    <div className="d-flex align-items-center gap-2 StepHeading">
                        <div className="StepNumber">{params.form_data.id} <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">{params.form_data.name}</h4>
                    </div>
                    <div className="page-content-box">
                        <AvForm
                        onValidSubmit={handleSubmit}
                        className="needs-validation"
                    >
                        <ModalBody className="row">
                        <div className="col-md-12">
                            <div className="row">
                            <div className="col-md-12 mb-3">
                                <AvField
                                name="block_days"
                                value={record.block_days}
                                label="Block Days"
                                className="form-control"
                                placeholder="For Example: 2022-04-16,2022-04-17"
                                />
                                <div className="note">
                                Enter comma seperated dates i.e (yyyy-mm-dd,yyyy-mm-dd)
                                </div>
                            </div>
                            <div className="col-sms-6 mb-3">
                                <AvField
                                name="from_time"
                                value={record.from_time}
                                label="From Time"
                                className="form-control"
                                required
                                type="time"
                                />
                            </div>
                            <div className="col-sms-6 mb-3">
                                <AvField
                                name="to_time"
                                value={record.to_time}
                                label="To Time"
                                className="form-control"
                                required
                                type="time"
                                />
                            </div>
                            <div className="col-sms-6 mb-3">
                                <AvField
                                className="select form-control"
                                type="select"
                                name="skip_weak_days"
                                label="Skip Week Days"
                                multiple
                                value={record.skip_weak_days}
                                >
                                <option value="Monday"> Monday </option>
                                <option value="Tuesday"> Tuesday </option>
                                <option value="Wednesday"> Wednesday </option>
                                <option value="Thursday"> Thursday </option>
                                <option value="Friday"> Friday </option>
                                <option value="Saturday"> Saturday </option>
                                <option value="Sunday"> Sunday </option>
                                </AvField>
                            </div>
                            <div className="col-sms-6 mb-3">
                                <AvField
                                className="select form-control"
                                type="select"
                                value={record.holidays}
                                name="holidays"
                                label="USA Holidays"
                                multiple
                                >


                                {Object.entries(holidays).map(
                                    ([key, value]) => {
                                    return <option key={key} value={key}> {value} </option>;
                                    }
                                )}
                                </AvField>
                            </div>
                            </div>
                        </div>
                        </ModalBody>
                        <ModalFooter style={{float:"left"}}>
                        <button
                            className="btn btn-sm btn-primary m-2"
                            type="submit"
                        >
                         Ok &nbsp;<i className="fas fa-check"></i>
                        </button>
                       
                        </ModalFooter>
                        </AvForm>
                    </div>
            </div>
        </div>
    );
};

export default Box9;