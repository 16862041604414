import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "reactstrap";

import Header from "./Header";
import Breadcrumb from "../../components/Common/Breadcrumb";


import Footer from "./Footer";
import Sidebar from "./Sidebar";
import Pusher from 'pusher-js'
import { ApiUrl, PusherKey } from "../../config";


class CampaignLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMenuOpened: false,
            readCount: 0,
        };
    }




    render() {
        console.log(this.props, 'aheheh')
        const styles = {
            position: 'fixed !important',

        };
        return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        );
    }
}

export default CampaignLayout;
