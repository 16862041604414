import React, { Component, Fragment } from "react"
import { Row, Col, Card, CardBody, CardTitle, Form, Label, Button, Modal, ModalHeader, Input, ModalBody, ModalFooter, Table, Badge } from "reactstrap"
import { bcrypt, uInfo, uToken } from "../../useToken";
import { ApiUrl, ProductName, AttachementsUrl } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import ProfileSettings from "../Authentication/profile-sidebar";
import Loader from "react-loader";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import ReactDatatable from "@ashvin27/react-datatable";

export default class LandingPageSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            record: {},
            calendars: [],
            loader: true,
            page_name: 'Landing Page Setting',
        }
    }


    componentDidMount() {
        this.getData();
    }

    getData = () => {
        fetch(`${ApiUrl}` + 'get/coach/landing/setting', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + uToken()
            },
            body: JSON.stringify({ coach_id: uInfo().user_id }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {

                    this.setState({
                        record: response.data.record,
                        calendars: response.data.calendars,
                    });

                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                    // toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }

            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }

    handleSubmit = (event) => {
        this.setState({
            loader: false
        });
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        if (this.state.record.id) {
            submitData.append('id', this.state.record.id);
        }
        fetch(`${ApiUrl}` + "update/coach/landing/setting", {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                if (data.status === true) {
                    this.getData();
                } else {
                    swal({
                        title: data.message,
                        icon: "warning",
                    });
                    // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
                this.setState({ loader: true });
            })
            //Then with the error genereted...
            .catch((error) => {
                this.setState({ loader: true });

                console.error("Error:", error);
            });
    }



    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="2" className="pl-0">
                        <ProfileSettings menuOpen={false} props={this.props} />
                    </Col>
                    <Col lg="10">
                        <Row>
                            <Col sm={3} >
                                <div className="page-title-box">
                                    <h4> {this.state.page_name} </h4>
                                    <ol className="breadcrumb m-0" >
                                        <li key={0} className="breadcrumb-item active"> {ProductName} </li>
                                        <li key={1} className="breadcrumb-item"> <Link to="#"> {this.state.page_name} </Link></li>
                                    </ol>
                                </div>
                            </Col>

                            <Col lg={6} className="text-center">
                                {/* <h4> &nbsp;</h4>
                                <strong>Link:</strong> <a href={` https://extrasalesforyou.com/${this.state.record.slug}`} className="" target={'_blank'}>{`https://extrasalesforyou.com/${this.state.record.slug}`}</a> */}
                            </Col>
                            <Col sm={3}>


                            </Col>
                        </Row>
                        <div className="dialer">
                            <Loader loaded={this.state.loader}></Loader>
                        </div>

                        <Card>
                            <CardBody>
                                <form method="post" onSubmit={this.handleSubmit} encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                                    <div className="page-content-box">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Slug</label>
                                                    <input type="text" name="slug" defaultValue={this.state.record.slug} required className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label>Select Calendar</label>
                                                    <select required name="calendar_url" defaultValue={this.state.record.calendar_url ?? ''} className="form-control no-border">
                                                        {
                                                            this.state.calendars.map((row, i) => {
                                                                return <option key={i} value={row.calendar_url}>{row.internal_name}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="form-group mt-2">
                                                    <label>Button Text</label>
                                                    <input required name="button_text" defaultValue={this.state.record.button_text ?? ''} className="form-control " type="text" placeholder="Enter"></input>
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="form-group mt-2">
                                                    <label>Button Color</label>
                                                    {
                                                        this.state.record.button_color && (
                                                            <input required name="button_color" defaultValue={`${this.state.record.button_color}`} className="form-control " type="color" placeholder="Enter"></input>
                                                        )
                                                    }
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <button type="submit" className="btn btn-md btn-primary mt-2 mb-2"> Save </button>
                                </form>

                                {/* <iframe src={`https://extrasalesforyou.com/${this.state.record.slug}`} width={'100%'} height={'800px'}></iframe> */}

                                <Col lg={12} className="text-center">
                                    <h4> &nbsp;</h4>
                                    <strong>Link:</strong> <a href={` https://extrasalesforyou.com/${this.state.record.slug}`} className="" target={'_blank'}>{`https://extrasalesforyou.com/${this.state.record.slug}`}</a>
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment >
        )
    }
}