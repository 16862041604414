import React from "react"
import { Container, Row, Col } from "reactstrap"
import { ProductName } from "../../config"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col sm={12}>
              © {new Date().getFullYear()} {ProductName}
              <span className="d-none d-sm-inline-block"> - Created by Zamsol.</span>
              {/* <span className="d-none d-sm-inline-block"> - Created <i className="mdi mdi-heart text-danger"></i> by Zamsol.</span> */}
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
