import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,

  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
//Import Action to copy breadcrumb items from local state to redux state
import PackagesSidebar from "./packages-sidebar";
import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { dcrypt, bcrypt, isAllowed, uInfo, uToken } from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import swal from 'sweetalert';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-bootstrap";

export default class Packages extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        text: "#",
        key: "package_id",
        sortable: true,
      },
      {
        text: "Title",
        key: "name",
      },
      {
        text: "Sub Title",
        key: "sub_title",
      },
      {
        text: "Interval",
        key: "package_interval",
      },
      {
        text: "Price",
        key: "price",
      },
      {
        text: "Setup Fee",
        key: "setup_price",
        cell: (record, index) => {
          return record.setup_price + " ( Installment:" + record.setup_fee_installment + ")";
        },
      },
      {
        text: "Coach License",
        key: "has_coach_license",
        cell: (record, index) => {
          if (record.has_coach_license == "Yes") {
            return record.licence_limit;
          } else {
            return '--';
          }
        },
      },
      {
        text: "Additional fee",
        key: "additional_licence_fee",
      },
      {
        text: "Status",
        key: "status",
        cell: (record, index) => {
          return (
            <Fragment>
              {record.status == "Active" && (
                <Badge bg="success">{record.status}</Badge>
              )}
              {record.status == "Inactive" && (
                <Badge bg="danger">{record.status}</Badge>
              )}
            </Fragment>
          );
        },
      },
      {
        text: "Type",
        key: "package_type",
        cell: (record, index) => {
          return (
            <Fragment>
              {record.package_type == "default" && (
                <Badge bg="success">Default</Badge>
              )}
              {record.package_type == "custom" && (
                <Badge bg="info">Custom</Badge>
              )}
            </Fragment>
          );
        },
      },
      {
        key: "link",
        text: "Signup Link",
        cell: (record, index) => {
          return (
            <Fragment>
              {record.package_type == 'default' ? (
                '--'
              ) : (
                <button
                  title="Link"
                  type="button"
                  color="info"
                  className="btn btn-link btn-sm mr-5"
                  onClick={() => { this.setState({ modal_link: true, package_link: record.package_link }) }}
                >
                  <i className="fas fa-fw  fa-link"></i>
                </button>
              )
              }


            </Fragment>
          );
        },
      },
      {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          return (
            <Fragment>
              {isAllowed(uInfo(), ["can_edit"]) && (
                <Link
                  className="btn-primary btn-sm mr-5"
                  to={"edit/" + bcrypt(record.package_id)}
                // onClick={() => {
                //   this.setState({
                //     modal: !this.state.modal
                //   })
                // }}
                >
                  <i className="mdi mdi-pencil"></i>
                </Link>
              )}
              {isAllowed(uInfo(), ["can_delete"]) && record.package_type == 'custom' && (
                <>
                  {/* {" | "} */}
                  <Link
                    className="btn-danger mb-1  btn-sm"
                    onClick={this.deleteRecord.bind(this, record, index)}
                  >
                    <i className="mdi mdi-delete"></i>
                  </Link>
                </>
              )}
            </Fragment>
          );
        },
      },
    ];

    this.columns_two = [
      {
        text: "SR#",
        key: "sr",
        sortable: false,
      },
      {
        text: "Package Title",
        key: "name",
        sortable: true,
      },
      {
        text: "Interval",
        key: "package_interval",
        sortable: true,
      },
      {
        text: "Price",
        key: "price",
        sortable: true,
      },
      /*  {
        text: "Trial package",
        key: "trial_package",
        sortable: true,
      }, */

      {
        text: "Credits (¢)",
        key: "credit_amount",
        sortable: false,
      },
      /* 
      {
        text: "Leads",
        key: "leads",
        sortable: true,
      }, */
      // {
      //   text: "Name",
      //   key: "s_member_limit",
      //   sortable: true,
      // },
      // {
      //   text: "Role",
      //   key: "t_member_limit",
      //   sortable: true
      // },
      {
        text: "Status",
        key: "status",
        sortable: true,
        cell: (record, index) => {
          return (
            <Fragment>
              {record.status == "Active" && (
                <Badge bg="success">{record.status}</Badge>
              )}
              {record.status == "Inactive" && (
                <Badge bg="danger">{record.status}</Badge>
              )}
            </Fragment>
          );
        },
      },
      {
        text: "Created At",
        key: "created_at",
      },
      {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          return (
            <Fragment>
              {isAllowed(uInfo(), ["can_edit"]) && (
                <Link
                  type="button"
                  color="info"
                  className="btn btn-primary btn-sm mr-5"
                  to={"edit/" + bcrypt(record.package_id)}
                // onClick={() => {
                //   this.setState({
                //     modal: !this.state.modal
                //   })
                // }}
                >
                  <i class="mdi mdi-pencil"></i> 
                  
                </Link>
              )}
              {isAllowed(uInfo(), ["can_delete"]) && (
                <button
                  className="btn btn-danger btn-sm"
                  onClick={this.deleteRecord.bind(this, record, index)}
                >
                  <i class="mdi mdi-trash-can-outline"></i>                   
                </button>
              )}
            </Fragment>
          );
        },
      },
    ];

    this.columns_premium = [
      {
        text: "Premium Id#",
        key: "id",
        sortable: false,
      },
      {
        text: "Title",
        key: "title",
        sortable: false,
      },
      {
        text: "Package Name",
        key: "package_name",
        sortable: false,
      },
      {
        text: "Price",
        key: "price_convert",
        sortable: false,
      },
      {
        text: "Setup Fee",
        key: "setup_fee_convert",
        sortable: false,
      },
      {
        text: "License Limit",
        key: "licence_limit",
        sortable: false,
      },
      {
        text: "Created At",
        key: "created_at",
        sortable: false,
      },
      {
        text: "Action",
        key: 'action',
        cell: (record, index) => {
          return <>
            <button onClick={() => { this.setState({ row: record, modal: true }) }} className="btn btn-primary btn-sm mr-5">
              <i className="mdi mdi-pencil"></i>
            </button>

            <button onClick={this.deletePremiunLicense.bind(this, record, index)} className="btn btn-danger btn-sm">
              <i className="mdi mdi-delete"></i>
            </button>
          </>;
        },
      }
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
    };
    this.config1 = {
      page_size: 0,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
    };
    this.state = {
      type: this.props.match.params.type,
      records: [],
      roles: [],
      total_pages: 0,
      modal: false,
      modal_link: false,
      package_link: "",
      record: {},
      filter: {},
      row: {},
      page_name: this.props.match.params.type + " Package ",
      customDate: true,
      coach_license_addon: [],
      premium_records: []
    };
  }

  deleteRecord = (record, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "delete/package", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: record.package_id }),
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                this.getData();
                //    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            //Then with the error genereted...
            .catch((error) => {
              console.error("Error:", error);
            });

        }
      });
  };

  // componentWillupdate(){

  // }
  componentDidMount() {
    this.getData();

  }
  componentDidUpdate(prevProps) {
    if (this.props.match.params.type !== prevProps.match.params.type) {
      this.setState({
        type: this.props.match.params.type,
        page_name: this.props.match.params.type + " Package ",
      });
      this.getData();
    }

  }

  getData = (queryString = this.props.match.params.type + "?all=1", data) => {
    fetch(`${ApiUrl}` + "packages/list/" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            total_pages: response.data.total,
            records: response.data.records,
            coach_license_addon: response.data.records[3],
          });

        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleFilterSubmit = async (event, values) => {
    this.setState({
      filter: values,
    });
    this.getData(this.props.match.params.type, values);
  };

  cancelFilter = async () => {
    this.form && this.form.reset();
    this.setState({ filter: {}, customDate: true });
    this.getData(this.props.match.params.type, "");
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData(this.props.match.params.type + "?" + queryString + "&all=1", this.state.filter);
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }

    fetch(`${ApiUrl}` + "submit/additional/licence", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  getPremiunLicense = (key) => {
    if (key == 'premium_license') {
      fetch(`${ApiUrl}` + "premium/license", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
      })
        .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((response) => {
          if (response.status === true) {
            console.log(response.data.records)
            this.setState({
              premium_records: response.data.records,
            });
          } else {
            toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })

        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  deletePremiunLicense = (record, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "delete/premium/license", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: record.id }),
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                this.getPremiunLicense('premium_license');
                //    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            //Then with the error genereted...
            .catch((error) => {
              console.error("Error:", error);
            });

        }
      });
  };

  submitLicense = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }

    fetch(`${ApiUrl}` + "add/premium/license", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          this.setState({
            row: {},
            modal: false
          })
          this.getPremiunLicense('premium_license');
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        this.setState({ loader: true });
      })
      //Then with the error genereted...
      .catch((error) => {
        this.setState({ loader: true });

        console.error("Error:", error);
      });
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.page_name}</title>
        </Helmet>

        <Row>
          <Col lg="2" className="pl-0">
            <PackagesSidebar menuOpen={false} props={this.props} />

          </Col>
          <Col lg="10">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4>
                    {this.state.page_name}
                    Lists
                  </h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#">
                        {this.state.page_name}
                        Lists
                      </Link>
                    </li>
                  </ol>
                </div>
              </Col>

              {
                this.state.type == 'AccountCredit' && (
                  <Col sm={6}>
                    <div className="page-title-box text-align-right">
                      {isAllowed(uInfo(), ["can_create"]) && (
                        <Link
                          type="button"
                          color="info"
                          className="btn btn-info"
                          to={"add/" + this.state.type}
                        // onClick={() => {
                        //   this.setState({
                        //     modal: !this.state.modal
                        //   })
                        // }}
                        >
                          <i className="mdi mdi-plus"> </i>
                          Create
                        </Link>
                      )}
                    </div>
                  </Col>
                )
              }
            </Row>

            <ToastContainer />

            <Row>
              <Col xl="12" className="d-none">
                <Card>
                  <CardBody>
                    <AvForm
                      onValidSubmit={this.handleFilterSubmit}
                      ref={(c) => (this.form = c)}
                      className="needs-validation"
                    >
                      <Row>
                        <Col md="2">
                          <div className="mb-3">
                            <AvField
                              className="select form-control"
                              type="select"
                              name="date_range"
                              label="Date Filter"
                              onChange={(e) =>
                                e.target.value == "Custom Date"
                                  ? this.setState({
                                    customDate: false,
                                  })
                                  : this.setState({
                                    customDate: true,
                                  })
                              }
                            >
                              <option value=""> All Time </option>
                              <option value="Current Week">

                                Current Week
                              </option>
                              <option value="Last Week"> Last Week </option>
                              <option value="Current Month">

                                Current Month
                              </option>
                              <option value="Last Month"> Last Month </option>
                              <option value="Last 3 Months">

                                Last 3 Months
                              </option>
                              <option value="Last 6 Months">

                                Last 6 Months
                              </option>
                              <option value="Current Year">

                                Current Year
                              </option>
                              <option value="Last Year"> Last Year </option>
                              <option value="Custom Date"> Custom Date </option>
                            </AvField>
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <AvField
                              name="start_date"
                              label="Start date"
                              className="form-control"
                              placeholder="Enter Email Address"
                              type="date"
                              disabled={this.state.customDate}
                              required={!this.state.customDate}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <AvField
                              name="end_date"
                              label="End date"
                              className="form-control"
                              placeholder="Enter Email Address"
                              type="date"
                              disabled={this.state.customDate}
                              required={!this.state.customDate}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <AvField
                              className="select form-control"
                              type="select"
                              id="s"
                              name="status"
                              label="Status"
                            >
                              <option value={""}> Select Status </option>
                              <option value={"Active"}> Active </option>
                              <option value={"Inactive"}> Inactive </option>
                            </AvField>
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <label className=""> {"Action"}</label>
                            <div className="button-items">
                              <Button
                                className="btn-sm"
                                color="primary"
                                type="submit"
                              >
                                Filter
                              </Button>
                              <Button
                                onClick={() => this.cancelFilter()}
                                outline
                                color="secondary"
                                className="waves-effect btn-sm"
                                type="button"
                              >
                                Reset
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      {/* <div className="button-items">
                        <Button
                          className="btn-sm"
                          color="primary"
                          type="submit"
                        >
                          Filter
                        </Button>
                        <Button
                          onClick={() => this.cancelFilter()}
                          outline
                          color="secondary"
                          className="waves-effect btn-sm"
                          type="button"
                        >
                          Reset
                        </Button>
                      </div> */}
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody className="py-0">
                    {/* <CardTitle className="h4">Default Datatable </CardTitle> */}
                    {this.props.match.params.type == 'AccountCredit' && (
                      <ReactDatatable
                        config={this.config}
                        //key={this.state.id}
                        records={this.state.records}
                        columns={this.columns_two}
                        dynamic={true}
                        total_record={this.state.total_pages}
                        onChange={this.tableChangeHandler}
                      />
                    )}
                    {this.props.match.params.type == 'Coach' && (
                      <>

                        <Tabs
                          defaultActiveKey="list"
                          className="mb-1"
                          onSelect={(e) => this.getPremiunLicense(e)}
                        >
                          <Tab eventKey="list" title="Package List">
                            <div className="page-title-box text-align-right ">
                              <Link
                                type="button"
                                color="info"
                                className="btn btn-info"
                                to={"addNew/Coach"}
                              ><i className="mdi mdi-plus"> </i> Create
                              </Link>
                            </div>

                            <ReactDatatable
                              config={this.config1}
                              //key={this.state.id}
                              records={this.state.records}
                              columns={this.columns}
                              dynamic={false}
                            // total_record={this.state.total_pages}
                            //onChange={this.tableChangeHandler}
                            />


                            <Row className="d-none">
                              <Card>
                                <CardTitle> {this.state.coach_license_addon.name != '' ? this.state.coach_license_addon.name : ""}  - Additional Coach License Price Settings </CardTitle>
                                <form onSubmit={this.handleSubmit} method="post"
                                  encType="multipart/form-data" className="form-horizontal" id="setup_fee-myform">
                                  <table className="table table-stripped">
                                    <thead>
                                      <tr>
                                        <th>Title</th>
                                        <th>Price ($)</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        this.state.coach_license_addon.add_on && this.state.coach_license_addon.add_on.map((row, i) => {
                                          return (
                                            <tr>
                                              <td>{row.name}
                                                <input type={'hidden'} name="name" defaultValue={row.name} />
                                              </td>
                                              <td><input type={'number'} step={'any'} className="form-control" name={`price[${i}]`}
                                                defaultValue={row.price} required min={1} /></td>
                                            </tr>
                                          )
                                        })
                                      }
                                      <input type={'hidden'} name="package_id" defaultValue={4} />
                                    </tbody>
                                  </table>

                                  <ModalFooter>
                                    <button
                                      className="btn btn-primary w-md waves-effect waves-light"
                                      type="submit"
                                    >
                                      Submit
                                    </button>
                                  </ModalFooter>
                                </form>
                              </Card>
                            </Row>
                          </Tab>
                          <Tab eventKey="premium_license" title="Premium license">

                            <div className="page-title-box text-align-right ">
                              <Button type="button"
                                className="btn btn-info"
                                onClick={
                                  () => this.setState({
                                    modal: true,
                                    row: {}
                                  })}>
                                <i className="mdi mdi-plus"> </i>
                                Create
                              </Button>
                            </div>

                            <ReactDatatable
                              config={this.config1}
                              records={this.state.premium_records}
                              columns={this.columns_premium}
                            />


                            <Modal
                              isOpen={this.state.modal}
                              fade={false}
                              toggle={() => this.setState({ modal: false, row: {} })}
                            >
                              <form onSubmit={this.submitLicense} method="post"
                                encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                                <ModalBody>
                                  <Row>
                                    <Col md="12">
                                      <div className="form-group mb-3">
                                        <label>Title</label>
                                        <input
                                          name="title"
                                          defaultValue={this.state.row.title}
                                          className="form-control"
                                          placeholder="Enter title"
                                          required
                                        />

                                        <input
                                          name="id"
                                          defaultValue={this.state.row.id}
                                          type='hidden'
                                        />
                                      </div>
                                    </Col>
                                    <Col md="12">
                                      <div className="form-group mb-3">
                                        <label>Price</label>
                                        <div className="group-addnon">
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text" id="validationTooltipUsernamePrepend"> $ </span>
                                            </div>
                                            <input
                                              name="price"
                                              defaultValue={this.state.row.price}
                                              className="form-control w-100"
                                              placeholder="Enter price per license"
                                              required
                                              type={'number'}
                                              step={'any'}
                                              min={1}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md="12">
                                      <div className="form-group mb-3">
                                        <label>Setup Fee</label>
                                        <div className="group-addnon">
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text" id="validationTooltipUsernamePrepend"> $ </span>
                                            </div>
                                            <input
                                              name="setup_fee"
                                              defaultValue={this.state.row.setup_fee}
                                              className="form-control w-100"
                                              placeholder="Enter setup fee"
                                              required
                                              type={'number'}
                                              step={'any'}
                                              min={0}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md="12">
                                      <div className="form-group mb-3">
                                        <label>License Limit</label>
                                        <input
                                          name="licence_limit"
                                          defaultValue={this.state.row.licence_limit}
                                          className="form-control"
                                          placeholder="Enter License limit"
                                          required
                                          type={'number'}
                                          min={0}
                                        />
                                      </div>
                                    </Col>
                                    <Col md="12">
                                      <div className="form-group">
                                        <label> Package</label>
                                        <select
                                          className="select form-control"
                                          type="select"
                                          defaultValue={this.state.row.package_id}
                                          name="package_id"
                                          label="Package"
                                          required
                                        >
                                          {this.state.records.length > 0 && this.state.records.map((row, i) => {
                                            if (row.has_coach_license == "Yes" && row.package_id == 4) {
                                              return (
                                                <option value={row.package_id}> {row.name} </option>
                                              )
                                            }
                                          })
                                          }
                                        </select>
                                      </div>
                                    </Col>
                                  </Row>
                                </ModalBody>
                                <ModalFooter>
                                  <Button
                                    color="info"
                                    type="submit"
                                  >
                                    Submit
                                  </Button>
                                  <Button
                                    color="danger"
                                    type="button"
                                    onClick={() => this.setState({ modal: false })}
                                  >
                                    Cancel
                                  </Button>
                                </ModalFooter>
                              </form>
                            </Modal>
                          </Tab>

                        </Tabs>



                      </>
                    )}
                    {this.props.match.params.type == 'CoachPartner' && (
                      <ReactDatatable
                        config={this.config}
                        //key={this.state.id}
                        records={this.state.records}
                        columns={this.columns}
                        dynamic={true}
                        total_record={this.state.total_pages}
                        onChange={this.tableChangeHandler}
                      />
                    )}

                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal
              isOpen={this.state.modal_link}
              fade={false}
              toggle={() => this.setState({ modal_link: false })}
            >
              <ModalHeader>Package Signup Link</ModalHeader>
              <ModalBody>
                <div className="url">
                  <a
                    href={`${this.state.package_link}`}
                    target="_blank"
                    className="pr-5 mr-5"
                  >
                    {this.state.package_link}
                  </a>

                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="overlay-example">
                        Copy url
                      </Tooltip>
                    }
                  >
                    <button
                      className="btn btn-link btn-md"
                      onClick={() => {
                        navigator.clipboard.writeText(`${this.state.package_link}`);
                      }}
                    >
                      <i className="fas fa-copy"></i>
                    </button>
                  </OverlayTrigger>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="info"
                  onClick={() => this.setState({
                    modal_link: false,
                    package_link: ""
                  })}
                >
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          </Col>
        </Row>

      </React.Fragment>
    );
  }
}
