import React, { useEffect, useState } from "react";

import { Link, Redirect, useHistory } from "react-router-dom";

// reactstrap
import {
  Row,
  Col,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from "reactstrap";

import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import classname from "classnames";

import { ApiUrl, logoLightPng, logoLightSmallPng } from "../../config.js";
import { bcrypt, hasRole, logout, uInfo, uToken } from "../../useToken";
//i18n

const Header = (props) => {
  const [menu, setMenu] = useState(false);
  const [createmenu, setCreateMenu] = useState(false);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }
  return (
    <React.Fragment>
      <div className="navbar-header">
        <Container fluid className="px-0">

          <Col className="nav-bg px-0 d-flex space-between">
            <div className="float-start">
              <div className="navbar-brand-box  d-lg-none">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm pl-2">
                    <img src={logoLightSmallPng} alt="" height="45" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLightPng} alt="" height="45" />
                  </span>
                </Link>


              </div>
              <button
                type="button"
                className="btn btn-sm px-3 font-size-24 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                onClick={() => {
                  setCreateMenu(!createmenu)
                }}
                data-target="#topnav-menu-content"
              >
                <i className="mdi mdi-menu"></i>
              </button>
            </div>
            <div className="top_navbar">
              <div className="container-fluid">
                <div className="topnav">
                  <nav
                    className="navbar navbar-light navbar-expand-lg topnav-menu"
                    id="navigation"
                  >
                    <Collapse
                      isOpen={props.leftMenu}
                      className="navbar-collapse"
                      id="topnav-menu-content"
                    >
                      <ul className="navbar-nav">
                        <li className="nav-item">
                          <Link to="/dashboard" className="nav-link">
                            {"Dashboard"}
                          </Link>
                        </li>
                        {hasRole(uInfo(), ["Users"]) && (
                          <li className="nav-item dropdown">
                            <Link
                              to="/#"
                              className="nav-link dropdown-toggle arrow-none"
                            >
                              {"User"} <i className="mdi mdi-chevron-right"></i>
                            </Link>
                            <div
                              className={classname(
                                "dropdown-menu dropdown-menu-left",
                                {}
                              )}
                            >
                              {hasRole(uInfo(), ["Reseller Management"]) && (
                                <Link to="/users/admin" className="dropdown-item">
                                  <span> {"Admin"} </span>
                                </Link>
                              )}

                              <Link to="/users/staff" className="dropdown-item">
                                <span> {"Staff"} </span>
                              </Link>

                            </div>
                          </li>
                        )}
                        {/*   {hasRole(uInfo(), ['Reseller Packages']) && ( */}
                        <li className="nav-item dropdown">
                          <Link to="/#" className="nav-link dropdown-toggle arrow-none">
                            {"Plans"} <i className="mdi mdi-chevron-right"></i>
                          </Link>
                          <div
                            className={classname(
                              "dropdown-menu dropdown-menu-left",
                              {}
                            )}
                          >
                            {hasRole(uInfo(), ["Reseller Packages"]) && (
                              <Link to="/packages/list/WlAdmin" className="dropdown-item">
                                {"Admin Packages"}
                              </Link>


                            )}
                            <Link to="/packages/list/Coach" className="dropdown-item">
                              {"Coach Packages"}
                            </Link>
                          </div>
                        </li>



                        <div className="navbar-brand-box d-inline-block centerr-logo mx-3">
                          <Link to="/" className="logo logo-dark">
                            <span className="logo-sm pl-2">
                              <img src={logoLightSmallPng} alt="" height="45" />
                            </span>
                            <span className="logo-lg">
                              <img src={logoLightPng} alt="" height="45" />
                            </span>
                          </Link>
                        </div>




                        <li className="nav-item dropdown">
                          <Link to="/#" className="nav-link dropdown-toggle arrow-none">
                            {"Reports"} <i className="mdi mdi-chevron-right"></i>
                          </Link>
                          <div
                            className={classname(
                              "dropdown-menu dropdown-menu-left",
                              {}
                            )}
                          >
                            <Link to="/wl/subscription/log" className="dropdown-item">
                              {"Admin Subscription Log"}
                            </Link>
                            <Link to="/wl/activity/log" className="dropdown-item">
                              {"Activity Log"}
                            </Link>
                            <Link to="/wl/login/history" className="dropdown-item">
                              {"Login History for each Admin"}
                            </Link>
                          </div>
                        </li>
                        <li className="nav-item dropdown">
                          <Link to="/#" className="nav-link dropdown-toggle arrow-none">
                            {"CMS"} <i className="mdi mdi-chevron-right"></i>
                          </Link>
                          <div
                            className={classname(
                              "dropdown-menu dropdown-menu-left",
                              {}
                            )}
                          >

                            <Link to="/cms/disclaimer" className="dropdown-item">
                              {"Disclaimer"}
                            </Link>

                            <Link to="/cms/termsofservice" className="dropdown-item">
                              {"Terms of Service"}
                            </Link>
                            <Link to="/cms/privacypolicy" className="dropdown-item">
                              {"Privacy Policy"}
                            </Link>
                            <Link to="/emails" className="dropdown-item">
                              {"Email Template"}
                            </Link>
                            <Link to="/default/template" className="dropdown-item">
                              {"Default Notification Templates"}
                            </Link>
                          </div>
                        </li>
                        <li className="nav-item dropdown">
                          <Link to="/" className="nav-link dropdown-toggle arrow-none">
                            {"Settings"} <i className="mdi mdi-chevron-right"></i>
                          </Link>
                          <div
                            className={classname(
                              "dropdown-menu dropdown-menu-left",
                              {}
                            )}
                          >

                            <Link to="/settings/integration" className="dropdown-item">
                              {"Integrations"}
                            </Link>

                            <Link to="/settings/email" className="dropdown-item">
                              {"Email"}
                            </Link>
                            <Link to="/settings/domain" className="dropdown-item">
                              {"WL Domains"}
                            </Link>
                            <Link to="/settings/payment" className="dropdown-item">
                              {"Payments"}
                            </Link>
                            <Link to="/settings/clientstable" className="dropdown-item">
                              {"Clients Table"}
                            </Link>
                            {/*   <Link to="/tablessetting" className="dropdown-item">
                        {"Table Setting"}
                      </Link>
                       */}
                            <Link to="/settings/trackingcode" className="dropdown-item">
                              {"Tracking Code"}
                            </Link>
                          </div>
                        </li>
                      </ul>
                    </Collapse>
                  </nav>
                </div>
              </div>

            </div>
            <div className="float-end">



              <Link
                to={"/chat"}
                className="btn header-item noti-icon waves-effect display-inline"
                title="Incoming Chat Message"
              >
                <i className="ion ion-md-chatboxes"></i>
                {props.readCount > 0 && (
                  <span
                    className="badge bg-danger rounded-pill"
                    id={"total_read_count"}
                  >
                    {props.readCount}
                  </span>
                )}
              </Link>

              <div className="dropdown d-none  none">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  onClick={() => {
                    toggleFullscreen();
                  }}
                  data-toggle="fullscreen"
                >
                  <i className="mdi mdi-fullscreen font-size-24"></i>
                </button>
              </div>






              <ProfileMenu />




            </div>
          </Col>
        </Container>

      </div >
      <Row>

        <Col sm={12}>
          <div className="top-navigation px-3">
            <Collapse
              isOpen={createmenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              {uInfo().type == "Coach" &&
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/dashboard" className="nav-link">
                      <span className="DashIcon"><i className="ti-dashboard"></i></span> <span>{"Dashboard"}</span>
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link to="/#"
                      onClick={e => {
                        e.preventDefault()
                        setMenu(menu == 'clients' ? '' : 'clients')
                      }}
                      className="nav-link dropdown-toggle arrow-none">
                      <span className="DashIcon"><i className="mdi mdi-account-box"></i></span> <span>{"Clients"}{" "}
                        <i className="mdi mdi-chevron-right"></i></span>
                    </Link>
                    <div
                      className={`dropdown-menu dropdown-menu-left ${menu == 'clients' ? 'show' : ''}`}
                    >
                      <Link to="/client/list" className="dropdown-item">
                        {"Clients"}
                      </Link>
                      <Link to="/company/list" className="dropdown-item">
                        {"Companies"}
                      </Link>
                      <Link to="/users/coach" className="dropdown-item">
                        {"My Coaches"}
                      </Link>

                      <Link to="/clients/groups" className="dropdown-item">
                        {"Groups & Tags"}
                      </Link>
                      {/* <Link to="/" className="dropdown-item"  >
                     {"Tags"}
                    </Link> */}
                      <Link to="/clients/field/list" className="dropdown-item">
                        {"Custom Fields"}
                      </Link>
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <Link to="/#"
                      onClick={e => {
                        e.preventDefault()
                        setMenu(menu == 'campaign' ? '' : 'campaign')
                      }}
                      className="nav-link dropdown-toggle arrow-none">
                      <span className="DashIcon"><i className="mdi mdi-bullhorn"></i></span> <span> {"Campaign"}{" "}
                        <i className="mdi mdi-chevron-right"></i></span>
                    </Link>
                    <div
                      className={`dropdown-menu dropdown-menu-left ${menu == 'campaign' ? 'show' : ''}`}
                    >
                      <Link to="/campaign/list" className="dropdown-item">
                        {"Campaign"}
                      </Link>
                      <Link to="/callstatus/list" className="dropdown-item">
                        {"Call Status"}
                      </Link>
                      <Link to="/callergroup/list" className="dropdown-item">
                        {"Caller Group"}
                      </Link>
                      <Link to="/emailtemplates/list" className="dropdown-item">
                        {"Templates Library"}
                      </Link>
                      <Link to="/unsubkeywords/list" className="dropdown-item">
                        {"Unsubscribe Keywords"}
                      </Link>
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <Link to="/#"
                      onClick={e => {
                        e.preventDefault()
                        setMenu(menu == 'sales' ? '' : 'sales')

                      }}
                      className="nav-link dropdown-toggle arrow-none">
                       {"Sales"}{" "}
                        <i className="mdi mdi-chevron-right"></i>
                    </Link>
                    <div
                      className={`dropdown-menu dropdown-menu-left ${menu == 'sales' ? 'show' : ''}`}
                    >
                      <Link to="/pipeline/list" className="dropdown-item">
                        {"Pipeline"}
                      </Link>
                      <Link to="/tasks/list" className="dropdown-item">
                        {"Tasks"}
                      </Link>
                      {/* <Link to="/meeting/list" className="dropdown-item">
                      {"Calanders"}
                    </Link> */}
                      <Link to="/contract/list" className="dropdown-item">
                        {"Contracts"}
                      </Link>
                      <Link to="/invoice/list" className="dropdown-item">
                        {"Invoices"}
                      </Link>
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <Link to="/#"
                      onClick={e => {
                        e.preventDefault()
                        setMenu(menu == 'coaching' ? '' : 'coaching')

                      }}
                      className="nav-link dropdown-toggle arrow-none">
                       {"Coaching"}{" "}
                        <i className="mdi mdi-chevron-right"></i>
                    </Link>
                    <div
                      className={`dropdown-menu dropdown-menu-left ${menu == 'coaching' ? 'show' : ''}`}
                    >
                      <Link to="/assessment/list" className="dropdown-item">
                        {"Assessments"}
                      </Link>
                      <Link to="/coach/trainings" className="dropdown-item">
                        {"Trainings"}
                      </Link>
                      <Link to="/assesments/resources" className="dropdown-item">
                        {"Resources"}
                      </Link>
                      {/* <Link to="/" className="dropdown-item">
                      {"Group Coaching"}
                    </Link> */}
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <Link to="/#"
                      onClick={e => {
                        e.preventDefault()
                        setMenu(menu == 'reports' ? '' : 'reports')

                      }}
                      className="nav-link dropdown-toggle arrow-none">
                       {"Reports"}{" "}
                        <i className="mdi mdi-chevron-right"></i>
                    </Link>
                    <div
                      className={`dropdown-menu dropdown-menu-left ${menu == 'reports' ? 'show' : ''}`}
                    >
                      {/* <Link to="/aio/reporting" className="dropdown-item">
                      {"Reporting"}
                    </Link> */}
                      <Link to="/report/summary" className="dropdown-item">
                        {"Reporting"}
                      </Link>

                      <Link to="/report/credithistory" className="dropdown-item">
                        {"Credits Log"}
                      </Link>
                      {/* <Link to="/twillio/usagelog" className="dropdown-item">
                      {"Twilio Usage Log"}
                    </Link> */}
                    </div>
                  </li>
                  {/* <li className="nav-item dropdown">
                  <Link to="/#" className="nav-link dropdown-toggle arrow-none">
                    <i className="ti-settings"></i> {"Settings"} <i className="mdi mdi-chevron-down"></i>
                  </Link>
                  <div
                    className={classname(
                      "dropdown-menu dropdown-menu-left",
                      {}
                    )}
                  >
                    <Link to="/profile" className="dropdown-item"  >
                      {"Time zone settings"}
                    </Link>
                    <Link to="/" className="dropdown-item"  >
                      {"API Credential settings"}
                    </Link>
                    <Link to="/settings/integration/twilio" className="dropdown-item"  >
                      {"Twillio Numbers"}
                    </Link>
                    <Link to="/" className="dropdown-item"  >
                      {"API & Documentation"}
                    </Link>





                  </div>
                </li> */}
                </ul>
              }
            </Collapse>

          </div>
        </Col>

      </Row>


    </React.Fragment >
  );
};

export default Header;
