import React, { useEffect, useRef, useState } from "react"
import { Row, Col, CardBody, Card, Alert, Container, ModalHeader, ModalBody, ModalFooter, } from "reactstrap"

import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"

import { ApiUrl, logoLightPng } from "../../config";
import useToken, { logout } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import Loader from "react-loader";
import ProposalSelectPackage from "./ProposalSelectPackage";
import swal from 'sweetalert';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentComponent from "../../components/Custom/PaymentComponent";
const ProposalSignupPackage = props => {

    const [loaded, setLoaded] = useState(true);
    const [package_id] = useState(props.package_id);
    const [proposal_id] = useState(props.proposal_id);
    const stripePromise =  loadStripe(props.stripe_key);

    const childRef = useRef(null);

    
    const handleValidSubmit = async (event, values) => {

        if (childRef.current) {
            const result = await childRef.current.handlePayment(event);
            if(result.error == 1){
                toast(result.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                return;
            }else{
                values.token = result.token;
            }
        }

        setLoaded(false);
        values.package_id = package_id;
        values.proposal_id = proposal_id;
        values.payment_method = 'stripe';
        values.cardnumber = '';
        values.cvc = '';
        values.expirationdateMM = '';    
        values.expirationdateYYYY = '';

        logout();
        fetch(`${ApiUrl}` + 'signup/proposal/package', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((res) => {
                if (res.status === true) {
                    // props.history.push({
                    //     pathname: `/signup/payment/${bcrypt(package_id)}/${bcrypt(res.data.user_id)}`,
                    // });
                    swal({
                        title: res.message,
                        icon: "success",
                    });

                    props.signUpStep("final");
                } else {
                    swal({
                        title: res.message,
                        icon: "warning",
                    });
                }
                setLoaded(true);
            })
            //Then with the error genereted...
            .catch((error) => {
                setLoaded(true);
                console.error('Error:', error);
            });
    }

    return (
        <React.Fragment>
            <Row className="justify-content-center" >
                <Col md={12}>
                    <Card className="overflow-hidden" >
                        <CardBody className="pt-0" >
                            <AvForm className="form-horizontal mt-4"
                                onValidSubmit={
                                    (e, v) => {
                                        handleValidSubmit(e, v)
                                    }
                                }>
                                {
                                    props.user && props.user ? (<Alert color="success" >
                                        Register User Successfully </Alert>
                                    ) : null
                                }

                                {
                                    props.registrationError &&
                                        props.registrationError ? (<Alert color="danger" > {props.registrationError} </Alert>
                                    ) : null
                                }
                                <Loader loaded={loaded}></Loader>
                                <ModalBody className="px-0">

                                    <div className="row">
                                        <div className="col-lg-5">
                                            <div className="img-logo pricing-logo mb-5">
                                                <img src={logoLightPng} />
                                            </div>
                                            <ProposalSelectPackage package_id={props.package_id}
                                                proposal_id={props.proposal_id}
                                                signUpStep={props.signUpStep} />
                                        </div>

                                        <div className="col-lg-7">
                                            <div className="Informations">
                                                <div className="row">
                                                    <div className="SectionName">Personal Information</div>
                                                    <div className="col-md-6 mb-3">
                                                        <div className="InputsFieds">
                                                            <AvField
                                                                name="first_name"
                                                                // label="Full Name"
                                                                className="form-control"
                                                                placeholder="Enter First Name"
                                                                required
                                                            />
                                                            <i class="fas fa-user"></i>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <div className="InputsFieds">
                                                            <AvField
                                                                name="last_name"
                                                                // label="Full Name"
                                                                className="form-control"
                                                                placeholder="Enter Last Name"
                                                                required
                                                            />
                                                            <i class="fas fa-user"></i>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <div className="InputsFieds">
                                                            <AvField
                                                                name="email"
                                                                // label="Email"
                                                                className="form-control"
                                                                placeholder="Enter Email Address"
                                                                required
                                                            />
                                                            <i class="fas fa-envelope"></i>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 mb-3">
                                                        <div className="InputsFieds">
                                                            <AvField
                                                                name="phone"
                                                                // label="Phone"
                                                                className="form-control"
                                                                placeholder="Enter phone with country code"
                                                                required
                                                            />
                                                            <i class="fas fa-phone-alt"></i>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6 mb-3">
                                                        <div className="InputsFieds">
                                                            <AvField
                                                                name="password"
                                                                // label="Password"
                                                                className="form-control"
                                                                placeholder="Enter Password"
                                                                minLength={8}
                                                                errorMessage="Password must be at least 8 characters"
                                                                required
                                                                type="password"
                                                            />
                                                            <i class="fas fa-lock"></i>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="InputsFieds">
                                                            <AvField
                                                                minLength={8}
                                                                errorMessage="Confirm password must be at least 8 characters and same as password"
                                                                name="confirm_password"
                                                                // label="Confirm Password"
                                                                type="password"
                                                                className="form-control"
                                                                placeholder="Enter Confirm Password"
                                                                validate={{ match: { value: "password" } }}
                                                                required
                                                            />
                                                            <div className="ConfirmPass">
                                                                <i class="fas fa-lock"></i>
                                                                <i class="fas fa-check"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="Informations mt-4">
                                                <div className="row">
                                                    <div className="SectionName PaymentInfo">Payment</div>
                                                    <div className="col-md-12">
                                                        <AvInput type='hidden' name='payment_type' value='recurring' />
                                                    </div>
                                                    {
                                                        stripePromise && (

                                                            <Elements stripe={stripePromise}>
                                                                <PaymentComponent ref={childRef} />
                                                            </Elements>
                                                        )
                                                    }
                                                    {/* <div className="col-md-6 mb-3 d-none">
                                                        <div className="InputsFieds">
                                                            <AvField
                                                                name="cardnumber"
                                                                // label="Card Number"
                                                                className="form-control"
                                                                Placeholder="Enter Card Number"
                                                                required
                                                            />
                                                            <i class="fas fa-credit-card"></i>
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="col-md-6 mb-3 d-none">
                                                        <div className="InputsFieds">
                                                           
                                                            <AvField
                                                                name="cvc"
                                                                className="form-control"
                                                                type="number"
                                                                Placeholder="CVC"
                                                                required
                                                            />
                                                            <i class="fas fa-lock"></i>
                                                        </div>
                                                    </div> */}

                                                    {/* <div className="col-md-6 mb-3 d-none">
                                                        <div className="InputsFieds">
                                                            <AvField
                                                                name="expirationdateMM"
                                                                // label="MM"
                                                                className="form-control"
                                                                type="number"
                                                                Placeholder="MM"
                                                                required
                                                            />
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="col-md-6 mb-3 d-none">
                                                        <div className="InputsFieds">
                                                            <AvField
                                                                name="expirationdateYYYY"
                                                                // label="YYYY"
                                                                className="form-control"
                                                                type="number"
                                                                Placeholder="YYYY"
                                                                required
                                                            />
                                                            <i class="fas fa-calendar-alt"></i>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-md-12 d-none">
                                                        <div className="InputsFieds">
                                                            <AvField
                                                                name="cardname"
                                                                className="form-control"
                                                                type="number"
                                                                Placeholder="Name On Card"

                                                            />
                                                            <i class="fas fa-user"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="Informations mt-4">
                                                <div className="row">
                                                    <div className="SectionName BillingInfor">Billing Information</div>
                                                    <div className="col-md-12 mb-3">
                                                        <AvField
                                                            name="address_line1"
                                                            // label="Billing Address"
                                                            className="form-control"
                                                            type="textarea"
                                                            Placeholder="Billing Addresss"
                                                        />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <AvField
                                                            name="address_city"
                                                            // label="Billing City"
                                                            className="form-control"
                                                            type="text"
                                                            Placeholder="Billing City"
                                                        />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <AvField
                                                            name="address_state"
                                                            // label="Billing State"
                                                            className="form-control"
                                                            type="text"
                                                            Placeholder="Billing State"
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <AvField
                                                            name="address_zip"
                                                            // label="Billing Zip"
                                                            className="form-control"
                                                            type="text"
                                                            Placeholder="Billing Zip"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>





                                    {/* <div className="col-md-12 mb-3">
                                                        <p className="text-muted mb-0 font-size-14" >
                                                            By registering you agree to the PG Virtual Office
                                                            <Link to="#"
                                                                className="text-primary" > Terms of Use </Link></p>
                                                   
                                                </div> */}
                                    <div className="col-md-12 mb-3  mt-4">
                                        <div className="col-12 text-end" >
                                            <button className="btn btn-info w-md waves-effect waves-light mr-3"
                                                type="submit" onClick={() => { window.location.reload(false); }} > Back
                                            </button>
                                            <button disabled={!loaded} className="btn btn-primary w-md waves-effect waves-light ml-1"
                                                type="submit" > Register
                                            </button>
                                        </div>
                                    </div>
                                </ModalBody>
                            </AvForm>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default ProposalSignupPackage