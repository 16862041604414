import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardTitle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import Loader from "react-loader";

import { AvField, AvForm } from "availity-reactstrap-validation";
import { ApiUrl } from "../../config";
import { uToken } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
const Agreements = (props) => {
  const [modal, setModal] = useState(false);
  const [id, setId] = useState("");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, [props]);

  function NumberWithCommas(x) {
    let y = Math.trunc(x);
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <React.Fragment>
      <h2 className="mb-3">
        {props.module && props.module.replaceAll("_", " ")}
      </h2>
      <Loader loaded={loaded}></Loader>

      <Row>
        <Col sm={3}>
          <a
            className="waves-effect btn btn-light"
            href="https://profitaccelerationsoftware.s3.amazonaws.com/images/agreements/Contingency-Agreement.docx"
          >
            <div className="card">
              <img
                src="https://profitaccelerationsoftware.s3.amazonaws.com/images/agreements/1.jpg"
                alt="Lexa"
                className="img-fluid card-img-top"
              />
              <div className="card-body">
                <div className="h6 card-title"> Contingency Agreement</div>
              </div>
            </div>
          </a>
        </Col>
        <Col sm={3}>
          <a
            className="waves-effect btn btn-light"
            href="https://profitaccelerationsoftware.s3.amazonaws.com/images/agreements/MOU-Template.docx"
          >
            <div className="card">
              <img
                src="https://profitaccelerationsoftware.s3.amazonaws.com/images/agreements/2.jpg"
                alt="Lexa"
                className="img-fluid card-img-top"
              />
              <div className="card-body">
                <div className="h6 card-title"> MOU Template </div>
              </div>
            </div>
          </a>
        </Col>
        <Col sm={3}>
          <a
            className="waves-effect btn btn-light"
            href="https://profitaccelerationsoftware.s3.amazonaws.com/images/agreements/Non-disclosure-Agreement-(NDA)-template.docx"
          >
            <div className="card">
              <img
                src="https://profitaccelerationsoftware.s3.amazonaws.com/images/agreements/3.jpg"
                alt="Lexa"
                className="img-fluid card-img-top"
              />
              <div className="card-body">
                <div className="h6 card-title">NDA Agreement</div>
              </div>
            </div>
          </a>
        </Col>
        <Col sm={3}>
          <a
            className="waves-effect btn btn-light"
            href="https://profitaccelerationsoftware.s3.amazonaws.com/images/agreements/Standard-Agreement.docx"
          >
            <div className="card">
              <img
                src="https://profitaccelerationsoftware.s3.amazonaws.com/images/agreements/4.jpg"
                alt="Lexa"
                className="img-fluid card-img-top"
              />
              <div className="card-body">
                <div className="h6 card-title"> Standard Agreement</div>
              </div>
            </div>
          </a>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Agreements;
