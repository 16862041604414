import React, { useState, useEffect } from 'react';
import Video from 'twilio-video';
import { HostUrl } from '../../config';
import ChatApp from './ChatApp';
import Participant from './Participant';

const Room = ({ roomName, token, handleLogout, username, hostId, from }) => {
    const [room, setRoom] = useState(null);
    const [participants, setParticipants] = useState([]);

    useEffect(() => {
        const participantConnected = participant => {
            console.log(participant, 'participantConnected')
            setParticipants(prevParticipants => [...prevParticipants, participant]);
        };

        const participantDisconnected = participant => {
            console.log(participant, 'participantDisconnected')
            setParticipants(prevParticipants =>
                prevParticipants.filter(p => p !== participant)
            );
        };

        Video.connect(token, {
            name: roomName,
        }).then(room => {
            setRoom(room);
            room.on('participantConnected', participantConnected);
            room.on('participantDisconnected', participantDisconnected);
            room.participants.forEach(participantConnected);
        });


        return () => {
            console.log('room state')
            setRoom(currentRoom => {
                console.log(currentRoom, 'room state')
                if (currentRoom && currentRoom.localParticipant.state === 'connected') {
                    currentRoom.localParticipant.tracks.forEach(function (trackPublication) {
                        trackPublication.track.stop();
                    });
                    currentRoom.disconnect();
                    return null;
                } else {
                    return currentRoom;
                }
            });
        };
    }, [roomName, token]);


    const remoteParticipants = participants.map(participant => (
        <Participant key={participant.sid} participant={participant} hostId={hostId} />
    ));

    const audio_mute_button = document.querySelector('#mute-unmute-audio-button');
    const video_mute_button = document.getElementById("mute_video");

    // audio_mute_button.addEventListener("click", audioButtonHandler);

    function audioButtonHandler(event) {

        event.preventDefault();
        room.localParticipant.audioTracks.forEach(publication => {
            console.log(publication, 'hello');
            if (publication.isTrackEnabled) {
                publication.track.disable()
                audio_mute_button.firstChild.className = "fas fa-microphone-slash";
            } else {
                publication.track.enable()
                audio_mute_button.firstChild.className = "fas fa-microphone";
            }
        });
    }

    function videoButtonHandler(event) {
        event.preventDefault();
        room.localParticipant.videoTracks.forEach(publication => {
            console.log(publication.isTrackEnabled, 'publication.isTrackEnabled')
            if (publication.isTrackEnabled) {
                publication.track.disable();
                video_mute_button.firstChild.className = "fas fa-video-slash";
            } else {
                publication.track.enable();
                video_mute_button.firstChild.className = "fas fa-video";
            }
        });

    }



    // sendMsgForm.addEventListener('submit', (e) => {

    //     e.preventDefault();

    //     const chatMessage = document.getElementById('chatMessageInput').value;

    //     // sendChatMessage(chatMessage);

    // });

    return (
        <div className='row'>
            <div className='col-lg-3 px-0'>
                <div className='chat'>
                    <ChatApp hostName={'host-' + hostId} username={username} token={token} roomName={roomName} />
                </div>
            </div>
            <div className='col-lg-9 px-0'>

                <div className="GroupRoom">
                    {
                        from == 'host' && (
                            <div className='shareable-link'>
                                {/* <h5>{roomName}</h5> */}
                                <button className='btn btn-primary'
                                    onClick={() => {
                                        navigator.clipboard.writeText(`${HostUrl}/video/room/remote/${roomName}/${hostId}`);
                                    }}
                                >Copy Link</button>
                            </div>
                        )
                    }
                    {/* <h5>Room: {roomName}</h5> */}

                    <div className='customVideoRoom'>
                        {/* <div className='col-md-12'> */}

                        {room ? (
                            <div className={`local-participant-standalone ${room.localParticipant.identity == 'host-' + hostId ? 'host-local' : 'host-remote'}`}>
                                <Participant
                                    key={room.localParticipant.sid}
                                    participant={room.localParticipant}
                                    hostId={hostId}
                                />
                                <div className='video-control'>
                                    <button className='call-drop' onClick={handleLogout}><i className="fas fa-phone-alt"> </i></button>
                                    <button onClick={audioButtonHandler} id="mute-unmute-audio-button"><i
                                        className="fas fa-microphone"></i></button>
                                    <button onClick={videoButtonHandler} id="mute_video" title="Video Camera"><i
                                        className="fas fa-video"></i></button>
                                </div>

                            </div>
                        ) : (
                            ''
                        )}

                        {/* </div>
                <div className='col-md-12'> */}
                        <div className={`remote-participants-standalone ${room && room.localParticipant.identity == 'host-' + hostId ? 'host-remote' : 'remote-remote'}`}>{remoteParticipants}</div>
                        {/* </div> */}


                    </div>

                </div>
            </div>
        </div>
    );
};

export default Room;