import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  CardImg,
  CardText,
  ModalHeader,
  Nav,
  CardImgOverlay,
  NavItem,
  NavLink,
  Input,
  TabContent,
  TabPane,
  ModalBody,
  ModalFooter,
  Collapse,
} from "reactstrap";
import Badge from "react-bootstrap/Badge";
import Breadcrumb from "../../../../components/Common/Breadcrumb";
import SectionAccordion from "./SectionAccordion";
import ProductFaqs from "./ProductFaqs";
import ProfileSettings from "../../../Authentication/profile-sidebar";
//Import Action to copy breadcrumb items from local state to redux state
import "react-toastify/dist/ReactToastify.css";
import ReactPlayer from "react-player";
import Helmet from "react-helmet";
import TrainingProductsSidebar from "./trainingproducts-sidebar";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  dcrypt,
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../../useToken";
import { ApiUrl, ProductName, AttachementsUrl } from "../../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import ProgressBar from 'react-bootstrap/ProgressBar';

export default class smstemplate extends Component {
  constructor(props) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    super(props);

    this.state = {
      total_pages: 0,
      records: [],
      id: obj.user_id,
      record: {},
      recordrvm: {},
      page_name: this.props.match.params.id ? "" : "",
      product_data: [],
      controls: true,
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      content_url: "",
      index: this.props.match.params.index
        ? dcrypt(this.props.match.params.index)
        : "0",
      playing: true,
      volumne: "1",
      content_id: "",
      product_sections: [],
      params: props,
      first_section_id: "",
      first_content_id: "",
      training_cat: {},
      product_faqs: [],
      recordall: [],
      total_ratio: {
        total_content: 0,
        total_user: 0,
        avg: 0
      },
      showMarkComplete: false,
    };
  }

  componentDidMount() {
    this.getproductsections();
    this.getpoductdata();
    this.getproductfaqs();
  }
  getsectioncontent = () => {
    if (this.state.first_section_id != undefined && this.state.first_section_id != 'undefined') {
      fetch(`${ApiUrl}` + "get/sectioncontent/" + this.state.first_section_id, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify({ user_id: uInfo().user_id }),
      })
        .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((response) => {
          if (response.status === true) {
            this.setState({
              first_content_id: response.data.first.content_id,
            });

            if (this.state.first_content_id) {
              this.getcontentvideo(this.state.first_content_id);
            }
          } else {


            toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        //Then with the error genereted...
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  getpoductdata = (queryString = this.state.id, data) => {
    fetch(`${ApiUrl}` + "getcatgeoryproduct/details/" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            product_data: response.data.record,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing });
  };

  getcontentvideo = (queryString) => {
    fetch(`${ApiUrl}` + "view/training/content/" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ user_id: uInfo().user_id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            content_url: response.data.record,
            recordall: response.data.recordall,
          });

          let self = this;
          setTimeout(function () {
            self.setState({
              showMarkComplete: true
            })
          }, 30000);
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getproductfaqs = (queryString = this.state.id, data) => {
    fetch(`${ApiUrl}` + "get/productfaqs/" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            product_faqs: response.data.records,
          });

          console.log(this.state.product_faqs, "Product FAQS");
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  getproductsections = (queryString = this.state.id, data) => {
    fetch(`${ApiUrl}` + "get/productcontent/" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ user_id: uInfo().user_id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {

          let total_ratio = response.data.total_ratio;
          let avg = (response.data.total_ratio.total_user / response.data.total_ratio.total_content) * 100;
          total_ratio.avg = avg;

          this.setState({
            product_sections: response.data.records,
            first_section_id: response.data.first.cat_id,
            training_cat: response.data.first,
            total_ratio: total_ratio
          });
          if (this.state.first_section_id) {
            this.getsectioncontent(response.data.first.cat_id);
          }
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  markAsComplete = () => {

    let data = {
      user_id: uInfo().user_id,
      content_id: this.state.recordall.content_id,
      cat_id: this.state.recordall.cat_id,
      section_id: this.state.recordall.section_id,
    }

    fetch(`${ApiUrl}` + "content/mark/complete", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            product_sections: []
          });

          this.setState(prevState => {
            let recordall = Object.assign({}, prevState.recordall);
            recordall.id = 10;
            return { recordall };
          })
          this.getproductsections();

        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  render() {
    return (
      <React.Fragment>
        {/* <Row>
          <Col lg="2" className="pl-0">
            <TrainingProductsSidebar menuOpen={false} props={this.props} />
          </Col>
          <Col lg="10" > */}
        <Row>
          <Col className="col-12">
            <Card className="NoShadow HeightAuto">
              <CardBody className="NoPadding">
                <h3>
                  {this.state.product_data.title} {this.state.page_name}
                </h3>
              </CardBody>
              <Row className="mx-0">
                <Col xl={3}>
                  <Card className="NoShadow traning HeightAuto Playlist">
                    <CardBody>
                      <CardTitle className="h4">
                        {this.state.product_data.title}
                      </CardTitle>

                      {
                        uInfo().type == 'Client' && (
                          <ProgressBar now={this.state.total_ratio.avg} />
                        )
                      }
                      <div className="accordion">
                        {this.state.product_sections.map((row, i) => {
                          return (
                            <SectionAccordion
                              title={row.title}
                              content={row.title}
                              section_id={row.cat_id}
                              index={i}
                              defaultIndex={this.state.index}
                              defaultSelection={this.state.first_content_id}
                              shown={i == 0 ? true : row.result_id != null ? true : false}
                            />
                          );
                        })}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={9}>
                  {
                    this.state.training_cat.total_content == this.state.training_cat.total_user && (
                      <>
                        {
                          this.state.training_cat.result_id == null && (
                            <button className="btn btn-info" onClick={() => this.props.history.push('/take/quiz/' + bcrypt(this.state.training_cat.cat_id))}>Take Quiz</button>
                          )
                        }
                      </>
                    )
                  }
                  <Card className="NoShadow traning HeightAuto video p-0">
                    <CardBody>
                      <ReactPlayer
                        width="100%"
                        url={this.state.content_url}
                        playing={this.state.playing}
                        onError={(e) => console.log("onError", e)}
                        volume={this.state.volume}
                        onStart={() => console.log("onStart")}
                        controls={this.state.controls}
                      />
                      <div className="Video-content mt-3 text-center">
                        <h4>{this.state.recordall.title}</h4>
                        {
                          !this.state.recordall.id && this.state.showMarkComplete && uInfo().type == 'Client' && (
                            <button className="btn btn-info btn-md" onClick={() => this.markAsComplete()}>
                              Mark as Complete
                            </button>
                          )
                        }
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="mx-0">
                <Col xl={12}>
                  <Card className="NoShadow HeightAuto">
                    <CardBody>
                      <h4
                        className="ml-5 mr-5">
                        Frequently Asked Questions
                      </h4>
                      <div className="accordion">
                        {this.state.product_faqs.map((row, i) => {
                          return (
                            <ProductFaqs
                              title={row.title}
                              content={row.description}
                            />
                          );
                        })}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {/* </Col>
        </Row> */}
      </React.Fragment>
    );
  }
}
