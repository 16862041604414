import React, { useEffect, useState } from 'react';
import Loader from "react-loader";
import swal from 'sweetalert';
import { ApiUrl, AttachementsUrl } from '../../config';
import { bcrypt, uToken } from '../../useToken';
import WayBookContext from './WaybookContext';
import Dropdown from 'react-bootstrap/Dropdown';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function CategoryLists(props) {
    const params = React.useContext(WayBookContext);
    const [lists, setLists] = useState([]);
    const [list, setList] = useState({});
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        getWayBookLists();
    }, []);

    const getWayBookLists = () => {
        fetch(`${ApiUrl}` + "get/waybook/subcategory/" + params.category, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ can_edit: params.can_edit }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    setLists(response.data.records);
                    setList(response.data.record);
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
                setLoaded(true);

            })
            //Then with the error genereted...
            .catch((error) => {
                setLoaded(true);

                console.error("Error:", error);
            });
    };

    const updateWayBookSubjectListOrder = (newlists) => {
        fetch(`${ApiUrl}` + "update/waybook/subjects/order/" + params.category, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ can_edit: params.can_edit, lists: newlists }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === false) {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                    getWayBookLists();

                } else {
                    params.updatetWayBookLists();
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const dragItem = useRef();
    const dragOverItem = useRef();
    const dragStart = (e, position) => {
        // e.prevenDefault(); remove this for reordering


        if (!params.can_edit) {
            e.prevenDefault();
        }
        dragItem.current = position;
    };

    const dragEnter = (e, position) => {
        // e.preventDefault();
        dragOverItem.current = position;
        // console.log(e.target.innerHTML);
    };

    const dropTwo = (e, i) => {
        const copyListItems = [...lists];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;

        if (!copyListItems.some(element => element === undefined)) {
            setLists(copyListItems);
            updateWayBookSubjectListOrder(copyListItems)
        }
        console.log(copyListItems)
    };


    return (
        <div>
            <Loader loaded={loaded}></Loader>
            <div className='row pb-3'>
                <div className='col-lg-12 ps-0'>
                    <div className='way-subject-header py-4'>
                        <div className='modal-header'>
                            <h5>

                                {
                                    list.image && (
                                        <img className='mr-3' height={20} width={20} src={`${AttachementsUrl}/user_${list.user_id}/training/${list.image}`} alt="" />
                                    )
                                }
                                {list.title}
                                {
                                    params.can_edit && (
                                        <button className="btn btn-sm btn-link" onClick={() => params.subjectOrDocumentAction('Category', list.cat_id, list.title, 'Update', list.training_for)}>
                                            <i className='fas fa-edit'></i>
                                        </button>
                                    )
                                }
                            </h5>
                        </div>
                    </div>
                </div>
            </div>


            <div className='subject-document-lists'>
                <table className='table table-stripped content-document-card-item border-table'>
                    <tbody>
                        {
                            lists.map((row, i) => {
                                return <tr key={i}
                                    onDragEnd={(e) => dropTwo(e, i)}
                                    onDragEnter={(e) => dragEnter(e, i)}
                                    className="content-page-documents-list-item"
                                >
                                    {
                                        params.can_edit && (
                                            <td
                                                onDragStart={(e) => dragStart(e, i)}
                                                draggable
                                                className='draggable drag-handle document-drag-handle has-tooltip'
                                            >
                                                <i className='fas fa-grip-vertical'></i>
                                            </td>
                                        )
                                    }
                                    <td>
                                        <Link to={`/trainings/${bcrypt(params.category_i)}/${bcrypt(params.category)}/${bcrypt(i)}/${bcrypt(row.cat_id)}`}>
                                            
                                            {row.title}
                                        </Link>
                                    </td>
                                    <td>
                                        <img className='mx-1' height={15} width={15} src={`${AttachementsUrl}/user_${row.user_id}/training/${row.image}`} alt="" />
                                    </td>

                                  

                 

                                    {
                                        params.can_edit && (
                                            <td>{row.status == 'Publish' ? 'Published' : row.status}</td>
                                        )
                                    }
                                    {
                                       
                                        params.can_edit && (
                                            <td>
                                           
                                                <Dropdown onSelect={(e) => params.subjectOrDocumentAction('Subject', row.cat_id, row.title, e, row.training_for, row.image, row.user_id)}>
                                                    <Dropdown.Toggle variant="link">
                                                        <i className='fas fa-ellipsis-h'></i>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        {
                                                            row.status == 'Publish' ?
                                                                <Dropdown.Item eventKey="Draft">Switch to Draft</Dropdown.Item>
                                                                :
                                                                <Dropdown.Item eventKey="Publish">Switch to Publish</Dropdown.Item>

                                                        }
                                                        <Dropdown.Item eventKey="Update">Rename</Dropdown.Item>
                                                        <Dropdown.Item eventKey="Move">Move</Dropdown.Item>
                                                        <Dropdown.Item eventKey="Delete">Delete</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        )
                                    }

                                </tr>

                            })
                        }
                    </tbody>
                </table>
            </div>

            {/* {
                params.can_edit && (
                    <div className='add-new-document'>
                        <div className='add-btn text-center'>
                            <button onClick={() => params.Action(2)} className='bwaves-effect waves-light btn btn-outline-dark w-100'> New Document</button>
                        </div>
                    </div>
                )
            } */}

        </div>
    );
}

export default CategoryLists;