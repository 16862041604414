import React, { Component, Fragment } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import Loader from "react-loader";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvInput,
  AvGroup,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import Helmet from "react-helmet";
import { convertDateFormat, currentDateTime, logout, uInfo, uToken } from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose, logoLightPng } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";


export default class TermOfServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      term_record: "{}",
      formData: {
        start_date: currentDateTime(),
      },
      show: false,
      loaded: true,
    };
    
  }

  getData = () => {
    this.setState({
      loaded: false
    })
    fetch(`${ApiUrl}` + 'view/termofservices', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + uToken()
      },
      body: JSON.stringify({ type: 'termsofservice' }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        this.setState({
          loaded: true
        })
        if (response.status === true) {
          this.setState({
            term_record: response.data.record,
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }

      })
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
      });

  }
  componentDidMount() {
    this.getData();
  }
  handleSubmit = (event, data) => {
    data.proposal_id = this.state.term_record.id;
    fetch(`${ApiUrl}` + "save/user/proposal", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          var u_info = uInfo();
          u_info.is_proposal_accepted = 'Yes';
          localStorage.setItem("authUser", JSON.stringify(u_info));

          toast('Accepted successfully', { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
         

          setTimeout(() => this.props.history.push("/dashboard"), 1000);

        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  Logout = () => {
    fetch(`${ApiUrl}` + "logout", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${uToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        logout();
        this.props.history.push("/login");
        // if (data.status === true) {
        //     toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        // } else {
        //     toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        // }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title></title>
        </Helmet>
        <ToastContainer />
        <Loader loaded={this.state.loaded}></Loader>
        <Row>
          <Card className="overflow-hidden" >
            <CardBody className="" >
              <h3 className="mt-2 mb-2" >
                <div className="logout-wizard pull-right">
                  <button type="button" className="btn btn-md btn-link" onClick={() => this.Logout()}>
                    <i className="fas fa-power-off" /> Logout
                  </button>
                </div>
                <Link to="#"
                  className="d-block auth-logo" >

                  <img src={logoLightPng}
                    alt=""
                    height="45"
                  />
                </Link>
              </h3>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div
                id="divToPrint"
                style={{
                  backgroundColor: "#f5f5f5",
                  marginLeft: "auto",
                  marginRight: "auto",
                  padding: "20px",
                }}
              >
                <div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.term_record.body,
                    }}
                  />
                  { this.state.loaded && (
                    <div>
                      <AvForm
                        onValidSubmit={this.handleSubmit}
                        // model={this.state.record}
                        ref={(c) => (this.form = c)}
                        className="needs-validation"
                      >
                        <AvGroup check >
                          <Label check>
                            <AvInput type="checkbox" name="agree" trueValue="User Agreed" onClick={() => { this.setState({ show: !this.state.show }) }} required />I agree and accept the Terms and Conditions
                          </Label>
                        </AvGroup>
                        <br />
                        {
                          this.state.show ? <FormGroup>
                            <Button>Submit</Button>
                          </FormGroup> : null
                        }
                      </AvForm>
                    </div>
                   ) }
                </div>
              </div>
            </CardBody>
          </Card>
        </Row>
      </React.Fragment>
    );
  }
}
