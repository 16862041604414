import React, { useState, useCallback, useEffect, useRef } from 'react';
import { toast, ToastContainer, Flip } from "react-toastify";
import { ApiUrl } from '../../config';
import { uInfo, uToken, truncateString, dcrypt } from '../../useToken';
import $ from "jquery";
import Modal from 'react-bootstrap/Modal'
import SweetAlert from "react-bootstrap-sweetalert"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { Button, ButtonToolbar, Overlay, Tooltip } from 'react-bootstrap';
import Select from "react-select";
const EventReminderFlow = (props) => {
    const [record, setRecord] = useState([]);
    const [show, setShow] = useState(false);
    const [getTypeContent, setTypeContent] = useState("");
    const [alert, setAlert] = useState(false);
    const [msg, setMsg] = useState("");
    const [menu, setMenu] = useState("");
    const [childid, setChildid] = useState(0);
    const [nextId, setNextId] = useState(0);
    const [nodeId, setNodeId] = useState(0);
    const [isOk, setIsOk] = useState('no');
    const [g_cond, setGCond] = useState('');
    const [campaignId] = useState(props.match.params ? props.match.params.id : 0);
    const [group_id] = useState(props.match.params ? props.match.params.group_id : 0);
    //console.log(dcrypt(group_id), 'group_idgroup_id')
    const [automation_id] = useState(props.match.params ? props.match.params.flow_id : 0);
    const [metaData, setMetaData] = useState({
        campaign: {},
        opt_: [{ options: [] }, { options: [] }]
    });
    const [editModal, setEditModal] = useState(false);
    const [formFieldData, setFormFieldData] = useState({
        email_subject: '',
        email_body: '',
        sms_body: '',
        email_from:'',
        groups_id: '',
        email_token: '',
        sms_token: ''

    });
    const [seletedTemplate, setSeletedTemplate] = useState('');
    const [html, setHtml] = useState("");
    const [path, setPath] = useState("");
    const [showP, setShowP] = useState({});
    const [target, setTarget] = useState(null);
    const [emalil_template_selected, setEmailTemaplateSelected] = useState('');
    const ref = useRef(null);
    const handleClick = (event, key) => {
        setShowP({ ...showP, [key]: true });
        // setShowP(false);
        // setShowP(true);
        setTarget(event.target);
    };
    const form = useRef(null);
    const overlayShow = useRef(null);
    useEffect(() => {
        getSeries();
        getMetaData();

        var outerContent = $('.house-tree');
        var innerContent = $('.tree-point');
        outerContent.scrollLeft((innerContent.width() - outerContent.width()) / 2);
    }, []);
    const getMetaData = () => {
        fetch(`${ApiUrl}` + "get/eventreminder/meta/data", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ 'campaign_id': dcrypt(campaignId),'automation_id': dcrypt(automation_id) })
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    setMetaData(response.data)
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    const renderHtml = (data) => {
        if (data.records.length > 0) {
            let obj = Object.values(data.bTree);
            // console.log(obj);
            return obj.map(($treeArray, $parentNode) => {
                //let idx = $treeArray.indexOf(); 
                //console.log($treeArray, 'is here');
                let $nodeId = $treeArray['0'];
                let $childid = $treeArray['1'];
                //let $parentNode = $treeArray['2'];
                //console.log($parentNode, 'parentNode');
                let $nxtId = 0;
                if ($childid != 0) {
                    $nxtId = $nodeId;
                }
                if ($parentNode == 0) {
                    return (
                        <li className="plus_outer startNow" key={$parentNode}>
                            <ul>
                                <li>
                                    {addChildHtml($nodeId, $childid)}
                                </li>
                            </ul>
                            {/* <ul>
                                <li className='child a'>
                                    <a className="plus" onClick={(e) => chooseAction($childid, $nodeId, e)}>
                                        <img src="https://hotprospector.com/glu/assests/images/arrows_plus.png" />
                                    </a>
                                </li>
                            </ul> */}
                        </li>
                    )
                }
            });
        } else {
            return <li className="plus_outer startNow">
                <a className="plus" onClick={(e) => chooseAction(0, 0, e)}>
                    <img src="https://hotprospector.com/glu/assests/images/arrows_plus.png" />
                </a>
            </li>;
        }
    }
    function notIn($type) {
        let $actionArr = ['end', 'goto', 'start'];
        if ($actionArr.includes($type) === false) {
            return true;
        } else {
            return false;
        }
    }
    const addChildHtml = ($nodeId, $childid) => {
        let $dataArray = record.dataArray;
        let $childData = $dataArray[$nodeId];
        let $series = JSON.parse($childData.seriesData);
        let $returnHtml = '<p>A</p>';
        if ($childData.length == 0) {
            return $returnHtml;
        }
        $series.groups_id =  dcrypt(group_id); 
        $series.tags_id = $dataArray[$nodeId].tags_id;
        $series.p_series_id = $dataArray[$nodeId].series_id;
        $series.html = $dataArray[$nodeId].html;
        let reminds = remindOn.find(({ value }) => value == $series.remind_on);
        $series.reminds = reminds ? reminds.label : '';
        let $childNotes = $childData.notes != '' ? JSON.parse($childData.notes) : 0;
        return (
            <>
                <a href="javascript:;" className="delete"
                    onClick={() => deleteNode($nodeId, $childid, $childData.previous_id, $childData.type)}
                    title="Delete"><i className="fa fa-times" aria-hidden="true"></i>
                </a>
                <div style={{ position: 'relative' }}>
                    <div className='button-notes-view'>
                        <button variant='none' className='notes-list' onClick={(e) => handleClick(e, `key_${$nodeId}`)}><i className="fa fa-file" aria-hidden="true"></i></button>
                        <Overlay
                            show={showP[`key_${$nodeId}`]}
                            target={target}
                            placement="left"
                            rootClose={true}
                            onHide={() => setShowP({ ...showP, [`key_${$nodeId}`]: false })}
                        >
                            <Popover id="popover-contained">
                                <Popover.Header as="h3">Notes </Popover.Header>
                                <Popover.Body style={{ padding: "0.5rem 0.75rem 0" }}>
                                    <div className="add_note_drapdown_body">
                                        <div className={`col-md-12 show-notes nkt-${$nodeId}`}>
                                            {
                                                $childNotes.length > 0 && (
                                                    $childNotes.map(($note, i) => {
                                                        return <div className={`notes notee-${i}`}>
                                                            <div className="notes-text">{$note}</div>
                                                            <span className="notes-delete fas fa-trash cursor-pointer" title='Delete a note' href="javascript:;" onClick={() => deleteNote($nodeId, i)}></span>
                                                        </div>
                                                    })
                                                )
                                            }
                                        </div>
                                        <textarea className={`form-control text-note-${$nodeId}`} placeholder="Type note here ..."></textarea>
                                        <div className="add_note_drapdown_button">
                                            <button onClick={() => setShowP({ ...showP, [`key_${$nodeId}`]: false })} className="btn btn-info mr-5">Cancel</button>
                                            <button onClick={(e) => addNote($nodeId, e)} className="btn btn-primary">Add</button>
                                        </div>
                                    </div>
                                </Popover.Body>
                            </Popover>
                        </Overlay>
                    </div>
                </div>
                {
                    $childData.type == 'sms' ?
                        <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'sms', 'sms')}>
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip id="button-tooltip">
                                        FollowUpId # {$series.p_series_id}
                                        <br />
                                        Remind On: {$series.reminds}
                                        <br />
                                        Trigger On: {$series.trigger_interval}
                                    </Tooltip>
                                }
                            >
                                <div className="sb_button_inner btn-primary sb_editable"
                                    childid={`${$nodeId}`}>
                                    <i className="fa fa-mobile" aria-hidden="true"></i> Send SMS ( {truncateString($series.sms_body, 30)} )
                                </div>
                            </OverlayTrigger>
                        </div>
                        :
                        $childData.type == 'voicemail' ?
                            <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'voicemail', 'voicemail')}>
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip id="button-tooltip">
                                            FollowUpId # {$series.p_series_id}
                                            <br />
                                            Remind On: {$series.reminds}
                                            <br />
                                            Trigger On: {$series.trigger_interval}
                                        </Tooltip>
                                    }
                                >
                                    <div className="sb_button_inner btn-primary sb_editable"
                                        childid={`${$nodeId}`}>
                                        <i className="fa fa-phone" aria-hidden="true"></i> RvM
                                    </div>
                                </OverlayTrigger>
                            </div>
                            :
                            $childData.type == 'email' ?
                                <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'email', 'email')} >
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip id="button-tooltip">
                                                FollowUpId # {$series.p_series_id}
                                                <br />
                                                Remind On: {$series.reminds}
                                                <br />
                                                Trigger On: {$series.trigger_interval}
                                            </Tooltip>
                                        }
                                    >
                                        <div className="sb_button_inner btn-primary sb_editable"
                                            childid={`${$nodeId}`}>
                                            <i className="fa fa-envelope" aria-hidden="true"></i> Email
                                        </div>
                                    </OverlayTrigger>
                                </div>
                                : $childData.type == 'condition' ?
                                    <>
                                    </>
                                    // <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'condition', 'condition')}>
                                    //     <div className="sb_button_inner btn-primary sb_editable"
                                    //         childid={`${$nodeId}`}>
                                    //         <i className="fas fa-sync" aria-hidden="true"></i> Condition ({$series.title})
                                    //     </div>
                                    // </div>

                                    : "h"
                }
                {
                    $childData.type == 'condition' ? (
                        conditionHtml($childData, $dataArray, $nodeId, $childid, $childNotes, $series)
                    ) : conditionHtml($childData, $dataArray, $nodeId, $childid, $childNotes, $series)
                }
            </>
        );
    }
    function addNote($nodeId, obj) {
        var note = $.trim($('.text-note-' + $nodeId).val());
        if (note != '') {
            fetch(`${ApiUrl}` + "add/note/series/" + $nodeId, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + uToken(),
                },
                body: JSON.stringify({ note: note })
            })
                .then((response) => response.json())
                //Then with the data from the response in JSON...
                .then((response) => {
                    if (response.status === true) {
                        $('.text-note-' + $nodeId).val(' ');
                        getSeries();
                    } else {
                        toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                    }
                })
                //Then with the error genereted...
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    }

    function conditionHtml($childData, $dataArray, $nodeId, $childid, $childNotes, $series) {
        if ($childData.type == 'condition') {
            let $subChild = $dataArray[$childid] ? $dataArray[$childid] : {};
            let $preChild = [];
            if ($childData.pre_next_id != 0 && $childData.pre_next_id != $childid) {
                $preChild = $dataArray[$childData.pre_next_id];
            }

            let $trueChildId = 0;
            let $falseChildId = 0;

            if ($subChild.condition_status == 'true' && $subChild.series_id == $childid) {
                $trueChildId = $childid;
            }
            if ($subChild.condition_status == 'true' && $subChild.series_id == $childData.pre_next_id) {
                $trueChildId = $childData.pre_next_id;
            }
            if ($preChild.condition_status == 'true' && $preChild.series_id == $childid) {
                $trueChildId = $preChild.series_id;
            }
            if ($preChild.condition_status == 'true' && $preChild.series_id == $childData.pre_next_id) {
                $trueChildId = $childData.pre_next_id;
            }
            if ($subChild.condition_status == 'false' && $subChild.series_id == $childid) {
                $falseChildId = $childid;
            }
            if ($subChild.condition_status == 'false' && $subChild.series_id == $childData.pre_next_id) {
                $falseChildId = $childData.pre_next_id;
            }
            if ($preChild.condition_status == 'false' && $preChild.series_id == $childid) {
                $falseChildId = $preChild.series_id;
            }
            if ($preChild.condition_status == 'false' && $preChild.series_id == $childData.pre_next_id) {
                $falseChildId = $childData.pre_next_id;
            }

            return (
                <>
                    <div className="sb_button" onClick={() => dynamicSetting(true, $series, 'condition', 'condition')}>
                        <div className="sb_button_inner btn-primary sb_editable"
                            childid={`${$nodeId}`}>
                            <i className="fas fa-sync" aria-hidden="true"></i> Condition ({$series.title})
                        </div>
                    </div>

                    <ul className='tree-condition'>
                        <li>
                            <ul className='tree-condition-ul'>
                                <li className='tree-condition-li-yes'>
                                    <ul>
                                        <li className="plus_outer yes-outer">
                                            <a className="sb_button_inner condition_yes " childid="end">Yes</a>
                                            <br />
                                            <a className="plus" onClick={(e) => chooseAction($trueChildId, $nodeId, e, 'yes')}><img src="https://hotprospector.com/glu/assests/images/arrows_plus.png" /></a>
                                            {/* $returnHtml .= $this->getChild($nodeId, $trueChildId); */}
                                            {getChild($nodeId, $trueChildId)}
                                        </li>
                                    </ul>
                                </li>
                                <li className='tree-condition-li-no'>
                                    <ul>
                                        <li className="plus_outer">
                                            <a className="sb_button_inner condition_no" id={`yes_${$nodeId}`} childid="end">No</a>
                                            <br />
                                            <a className="plus" onClick={(e) => chooseAction($falseChildId, $nodeId, e, 'no')}><img src="https://hotprospector.com/glu/assests/images/arrows_plus.png" /></a>
                                            {getChild($nodeId, $falseChildId)}
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </>
            )


        }

        if (notIn($childData.type)) {
            return <ul>
                <li className="plus_outer">
                    <a className="plus" onClick={(e) => chooseAction($childid, $nodeId, e)}>
                        <img src="https://hotprospector.com/glu/assests/images/arrows_plus.png" /></a>
                    {getChild($nodeId, $childid)}
                </li>
            </ul>
        }
    }

    function getChild($parentNode, $nodeId) {
        let $dataArray = record.dataArray;

        if ($nodeId != 0) {
            let $childData = $dataArray[$nodeId];
            let $childid = $childData.next_id;
            return (
                <ul>
                    <li>
                        {addChildHtml($nodeId, $childid)}
                    </li>
                </ul>
            )
        }
    }


    const dynamicSetting = (modal, data, type, contetType) => {
        if (data.html) {
            setHtml(data.html);
        } else {
            setHtml('');
        }

        data.email_token = '';
        data.sms_token = '';
        setShow(modal);
        setFormFieldData(data);
        setMenu(type);
        setTypeContent(contetType);
        setEditModal(true);
    }

    const dynamicModal = (type) => {
        setMenu(type);
        setTypeContent(type);
    }

    const modalHide = () => {
        setShow(false);
        setFormFieldData({
            email_subject: '',
            email_body: '',
            sms_body: '',
            groups_id: '',
            email_token: '',
            sms_token: ''
        });
        setMenu('');
        setTypeContent('');
        setEditModal(false);
        setSeletedTemplate('');
        setHtml('');
        setPath('');
    }

    const deleteNode = ($nodeId, $childid, $previous_id, $type) => {
        fetch(`${ApiUrl}` + "delete/eventreminder/series/" + dcrypt(campaignId)+"/"+ dcrypt(automation_id), {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ nodeId: $nodeId, childId: $childid, previousId: $previous_id, type: $type })
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    getSeries();
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const deleteNote = ($nodeId, $noteIndex) => {
        fetch(`${ApiUrl}` + "delete/note/series/" + $nodeId, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ index: $noteIndex })
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    getSeries();
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }




    const getSeries = () => {
        fetch(`${ApiUrl}` + "eventreminder/series/" + dcrypt(campaignId)+"/"+ dcrypt(automation_id), {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    //console.log(response.data, 'getSeries');
                    setRecord(response.data);
                    // renderHtml(response.data.records);
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }


    const chooseAction = (nId, cId, obj, cond = '') => {
        $('.btn-group-vertical .btn.green').removeClass('active');
        $('.action-description').html('<h4 style="text-align:center;">Select Action From Menu</h4>');
        // getTypeContent('type');
        $('#action-detail').removeClass('col-md-12');
        $('#action-detail').addClass('col-md-9');
        $('#action-nav').show();
        // $('.modal-title').html('Add New Action');
        setShow(true);
        setChildid(0);
        setNextId(nId);
        setNodeId(cId);
        setIsOk('no');
        setGCond(cond);

    }


    const saveAction = (type) => {

        // if (formFieldData.groups_id.length < 1) {
        //     setMsg('Please select group!');
        //     setAlert(true);
        //     return false;
        // }

        if (!formFieldData.event_field) {
            setMsg('Please select event field!');
            setAlert(true);
            return false;
        }

        if ($('select[name=trigger_interval]').val() == '') {
            setMsg('Please select trigger interval!');
            setAlert(true);
            return false;
        }

        if (!formFieldData.remind_on) {
            setMsg('Please select remind on!');
            setAlert(true);
            return false;
        }

        if (!formFieldData.execution_time) {
            setMsg('Please select trigger on(time)!');
            setAlert(true);
            return false;
        }

        if (type == 'sms') {
            if ($('input[name=sms_body]').val() == '') {
                setMsg('Please enter sms body!');
                setAlert(true);
                return false;
            }
        }

        if (type == 'voicemail') {
            if ($('select[name=rvm_template]').val() == '') {
                setMsg('Please enter voicemail!');
                setAlert(true);
                return false;
            }
        }

        if (type == 'email') {

            if ($('input[name=email_subject]').val() == '') {
                setMsg('Please enter email subject!');
                setAlert(true);
                return false;
            }

            if ($('textarea[name=email_body]').val() == '') {
                setMsg('Please enter email body!');
                setAlert(true);
                return false;
            }
        }


        let data = {
            campaignId: dcrypt(campaignId),
            automation_id: dcrypt(automation_id),
            group_id: dcrypt(group_id),
            childId: childid,
            nextId: nextId,
            nodeId: nodeId,
            cond: g_cond,
            isOk: isOk,
            type: type,

        }
        console.log(data, 'data333');

        fetch(`${ApiUrl}` + "save/eventreminder/action?" + $('.sms-form').serialize(), {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    getSeries();
                    modalHide();
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormFieldData({ ...formFieldData, [name]: value });
        if (name == 'sms_template') {
            setFormFieldData({ ...formFieldData, ['sms_body']: value });
        }
        if (name == 'email_template' && value != '') {
            setFormFieldData({ ...formFieldData, ['email_subject']: e.target.selectedOptions[0].getAttribute("data-title") });
            getEmailTemplateHtml(value);
        }

        if (name == 'email_template' && value == '') {
            setFormFieldData({ ...formFieldData, ['email_subject']: '' });
            setHtml('');
            setPath('');
        }

        if (name === 'sms_template' || name === 'email_template' || name === 'rvm_template') {
            setSeletedTemplate(e.target.selectedOptions[0].getAttribute("data-id"));

        }
    }


    const getEmailTemplateHtml = (id) => {
        fetch(`${ApiUrl}` + "get/html/" + id + "/" + uInfo().user_id, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                setHtml(response.theme);
                setPath(response.path);
                setEmailTemaplateSelected(id);

            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    const remindOn = [
        { label: "Same Day", value: "0", isDisabled: false },
        { label: "Before 1 Day", value: "-1" },
        { label: "Before 2 Days", value: "-2" },
        { label: "Before 3 Days", value: "-3" },
        { label: "Before 4 Days", value: "-4" },
        { label: "Before 5 Days", value: "-5" },
        { label: "Before 14 Weeks", value: "-14" },
        { label: "Before 30 Month", value: "-30" },
        { label: "Before 45 Days", value: "-45" },
        { label: "Before 60 Days", value: "-60" },
        { label: "Before 90 Days", value: "-90" },
        { label: "Before 180 Days", value: "-180" },
        { label: "Before 365 Days", value: "-365" },
        { label: "After", value: "-0", isDisabled: true },
        { label: "After 1 Day", value: "1" },
        { label: "After 2 Days", value: "2" },
        { label: "After 3 Days", value: "3" },
        { label: "After 4 Days", value: "4" },
        { label: "After 5 Days", value: "5" },
        { label: "After 14 Weeks", value: "14" },
        { label: "After 30 Month", value: "30" },
        { label: "After 45 Days", value: "45" },
        { label: "After 60 Days", value: "60" },
        { label: "After 90 Days", value: "90" },
        { label: "After 180 Days", value: "180" },
        { label: "After 365 Days", value: "365" },
    ];


    return (
        <React.Fragment>
            <ToastContainer />
            <div className='mb-4'>

                <div className='row'>
                    <div className='col-sm-5' sm={5}>
                        <div className="page-title-box">
                            <h4>
                                {metaData.campaign.title}
                            </h4>
                        </div>
                    </div>
                    <div className='col-sm-3' sm={3}></div>
                    <div className='col-sm-4'>
                        <div className="page-title-box text-align-right">
                            <button className='btn btn-secondary'
                                onClick={() => props.history.goBack()}
                            >
                                Back
                            </button>
                        </div>
                    </div>
                </div>


                {alert ? (
                    <SweetAlert
                        title={msg}
                        warning
                        onConfirm={() => setAlert(false)}
                    />
                ) : null}


                <div className='card card-body house-tree custom-scroll' >


                    <div className='event-tree'>
                        <h5>Start Adding Reminders! </h5>
                    </div>
                    <ul className="tree-point pb-4">
                        {record.html == '' ? renderHtml(record) : ""}
                    </ul>

                </div>

                <Modal
                    show={show}
                    onHide={() => modalHide()}
                    dialogClassName="modal-90w"
                    size="lg"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {editModal ? 'Edit Action' : 'Add Action'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='row'>
                        <div className={`${editModal ? 'd-none' : 'col-md-3 col-sm-3 col-xs-12'}`} id="action-nav">
                            <div className={`btn-group-vertical left_vertical_tabs  ${editModal ? 'd-none' : ''}`}>

                                <div className="btn-group">
                                    <button type="button" className={`btn green dropdown-toggle ${menu == 'email' ? 'active' : ''}`} onClick={() => dynamicModal('email')} data-toggle="dropdown">
                                        <i className="fas fa-envelope"></i> Send Email
                                    </button>
                                </div>

                                <div className="btn-group">
                                    <button type="button" className={`btn green dropdown-toggle ${menu == 'sms' ? 'active' : ''}`} onClick={() => dynamicModal('sms')} data-toggle="dropdown">
                                        <i className="fas fa-mobile"></i> Send SMS
                                    </button>
                                </div>

                                <div className="btn-group">
                                    <button type="button" className={`btn green dropdown-toggle ${menu == 'voicemail' ? 'active' : ''}`} onClick={() => dynamicModal('voicemail')} data-toggle="dropdown">
                                        <i className="fas fa-phone"></i> Voicemail
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className={`${editModal ? 'col-md-12 col-sm-12 col-xs-12' : 'col-md-9 col-sm-9 col-xs-12'}`}>
                            {getTypeContent == 'sms' || getTypeContent == 'voicemail' || getTypeContent == 'email' ? (
                                <form ref={form} className="sms-form" method="post">
                                    {/* <div className="form-group mb-3">
                                        <label>Group(s)</label>
                                        <div className="MainTZone EventReminder One">
                                            <Select
                                                getOptionLabel={option => option.title}
                                                getOptionValue={option => option.group_id}
                                                isMulti={true}
                                                value={dcrypt(group_id)} 
                                                options={metaData.groups}
                                                classNamePrefix="select2-selection"
                                                name="groups_id"
                                                onChange={(selectedGroup) => setFormFieldData({ ...formFieldData, ['groups_id']: selectedGroup  })}
                                            />
                                        </div>
                                    </div> */}
                                    <div className="form-group mb-3">
                                        <label>Tags (Optional)</label>
                                        <div className="MainTZone EventReminder Two">
                                            <Select
                                                getOptionLabel={option => option.title}
                                                getOptionValue={option => option.group_id}
                                                isMulti={true}
                                                value={formFieldData.tags_id}
                                                options={metaData.tags}
                                                classNamePrefix="select2-selection"
                                                name="tags_id[]"
                                                onChange={(selectedTags) => setFormFieldData({ ...formFieldData, ['tags_id']: selectedTags })}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label>Event Field(Type) </label>
                                        <div className="MainTZone EventReminder Three">
                                            <Select
                                                getOptionLabel={option => option.title}
                                                getOptionValue={option => option.status_id}
                                                value={
                                                    metaData.opt_[0].options.find(x => x.status_id == formFieldData.event_field)
                                                }

                                                // value={
                                                //     metaData.opt_[0].options.find(x => x.status_id == formFieldData.event_field) ?
                                                //         metaData.opt_[0].options.find(x => x.status_id == formFieldData.event_field) :
                                                //         metaData.opt_[1].options.find(x => x.status_id == formFieldData.event_field)
                                                // }
                                                isMulti={false}
                                                options={metaData.opt_}
                                                classNamePrefix="select2-selection"
                                                name="event_field"
                                                onChange={(selectedTags) => setFormFieldData({ ...formFieldData, ['event_field']: selectedTags })}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group mb-3" style={{ display: "none" }}>
                                        <label>Trigger Interval:</label>
                                        <div className="input-group">
                                            <select defaultValue={formFieldData.trigger_interval} onChange={(e) => handleChange(e)} name="trigger_interval" className='form-control' >
                                                {/* <option value={''}>Select</option> */}
                                                <option value="Yearly">Yearly</option>
                                                {/* <option value="Biyearly">Biyearly</option>
                                                <option value="Monthly">Monthly</option> */}
                                            </select>
                                        </div>
                                    </div>



                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className="form-group mb-3">
                                                <label>Remind On:</label>
                                                <div className="MainTZone EventReminder Four">
                                                    <Select
                                                        getOptionLabel={option => option.label}
                                                        getOptionValue={option => option.value}
                                                        value={
                                                            remindOn.find(x => x.value == formFieldData.remind_on) ?
                                                                remindOn.find(x => x.value == formFieldData.remind_on) :
                                                                remindOn.find(x => x.value == formFieldData.remind_on)
                                                        }
                                                        options={remindOn}
                                                        classNamePrefix="select2-selection"
                                                        name="remind_on"
                                                        onChange={(selectedTags) => setFormFieldData({ ...formFieldData, ['remind_on']: selectedTags })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>

                                            <div className="form-group mb-3">
                                                <label>Trigger on(Time):</label>
                                                <div className="MainTZone EventReminder Five">
                                                    <Select
                                                        getOptionLabel={option => option}
                                                        getOptionValue={option => option}
                                                        value={metaData.time_duration.filter(
                                                            (x) => x === formFieldData.execution_time
                                                        )}
                                                        // value={metaData.time_duration.formFieldData.execution_time}
                                                        options={metaData.time_duration}
                                                        classNamePrefix="select2-selection"
                                                        name="execution_time"
                                                        onChange={(selectedTags) => setFormFieldData({ ...formFieldData, ['execution_time']: selectedTags })}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <input readOnly type={'hidden'} name="template_id" value={seletedTemplate} />


                                    {
                                        getTypeContent == 'sms' ?
                                            <div className="sms-content">
                                                <div className="form-group mb-3">
                                                    <label>SMS Template</label>
                                                    <div className="input-group">
                                                        <select defaultValue={formFieldData.sms_template} onChange={(e) => handleChange(e)} name="sms_template" className='form-control' >
                                                            <option value={''}>Select</option>
                                                            {
                                                                metaData.smsTemplates.map((sms, s) => {
                                                                    return <option data-id={sms.id} key={s} value={sms.body}>{sms.name}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="form-group mb-3">
                                                    <label> SMS Token</label>
                                                    <div className="input-group">
                                                        <select onChange={(e) => handleChange(e)} name="sms_token" className='form-control' >
                                                            <option value={''}>Select Token</option>
                                                            <optgroup label="Default Fields" >
                                                                {
                                                                    metaData.tokens.default_fields.map((df, d) => {
                                                                        return <option key={d} value={`{${df.key}}`}>{df.label}</option>
                                                                    })
                                                                }
                                                            </optgroup>
                                                            <optgroup label="Custom Fields" >
                                                                {
                                                                    metaData.tokens.custom_fields.map((cf, c) => {
                                                                        return <option key={c} value={`{${cf.key}}`}>{cf.label}</option>
                                                                    })
                                                                }
                                                            </optgroup>
                                                            <optgroup label="User Tokens" >
                                                                {
                                                                    metaData.tokens.users_token.map((cf, c) => {
                                                                        return <option key={'custom' + c} value={`{{${cf.key}}}`}>{cf.label}</option>
                                                                    })
                                                                }
                                                            </optgroup>
                                                        </select>

                                                        <button onClick={() => setFormFieldData({ ...formFieldData, ['sms_body']: formFieldData.sms_body + '  ' + formFieldData.sms_token })} type='button' className='btn btn-primary btn-md'>Insert Into Body</button>
                                                    </div>
                                                </div>

                                                <div className="form-group mb-3">
                                                    <label>SMS Body</label>
                                                    <div className="input-group">
                                                        <input value={formFieldData.sms_body} onChange={(e) => handleChange(e)} name="sms_body" className='form-control' />
                                                    </div>
                                                </div>
                                                <input type={'hidden'} name="series_id" value={formFieldData.p_series_id} />
                                                <div className="form-group">
                                                    <a href="javascript:void(0)" className="btn btn-primary pull-right save-btn mt-2" onClick={() => saveAction('sms')}>Save</a>
                                                </div>
                                            </div>
                                            :
                                            getTypeContent == 'voicemail' ?
                                                <div className="voicemail-content">
                                                    <div className="form-group mb-3">
                                                        <label>RVM Template</label>
                                                        <div className="input-group">
                                                            <select defaultValue={formFieldData.rvm_template} onChange={(e) => handleChange(e)} name="rvm_template" className='form-control' >
                                                                <option value={''}>Select</option>
                                                                {
                                                                    metaData.voiceTemplates.map((rvm, r) => {
                                                                        return <option data-id={rvm.id} key={r} value={rvm.body}>{rvm.name}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <input type={'hidden'} name="series_id" value={formFieldData.p_series_id} />

                                                    <div className="form-group">
                                                        <a href="javascript:void(0)" className="btn btn-primary pull-right save-btn mt-2" onClick={() => saveAction('voicemail')}>Save</a>
                                                    </div>
                                                </div>
                                                : getTypeContent == 'email' ?
                                                    <div className="email-content">
                                                        <div className="form-group mb-3">
                                                            <label>Email Template</label>
                                                            <div className="input-group">
                                                                <select defaultValue={formFieldData.email_template} onChange={(e) => handleChange(e)} name="email_template" className='form-control' >
                                                                    <option value={''}>Select</option>
                                                                    {
                                                                        metaData.emailTemplates.map((email, e) => {
                                                                            return <option data-title={email.title} data-id={email.id} key={e} value={email.id}>{email.title}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="form-group mb-3">
                                                            <label> Email Token</label>
                                                            <div className="input-group">
                                                                <select onChange={(e) => handleChange(e)} name="email_token" className='form-control' >
                                                                    <option value={''}>Select Token</option>
                                                                    <optgroup label="Default Fields" >
                                                                        {
                                                                            metaData.tokens.default_fields.map((df, d) => {
                                                                                return <option key={d} value={`{${df.key}}`}>{df.label}</option>
                                                                            })
                                                                        }
                                                                    </optgroup>
                                                                    <optgroup label="Custom Fields" >
                                                                        {
                                                                            metaData.tokens.custom_fields.map((cf, c) => {
                                                                                return <option key={c} value={`{${cf.key}}`}>{cf.label}</option>
                                                                            })
                                                                        }
                                                                    </optgroup>
                                                                    <optgroup label="User Tokens" >
                                                                        {
                                                                            metaData.tokens.users_token.map((cf, c) => {
                                                                                return <option key={'custom' + c} value={`{{${cf.key}}}`}>{cf.label}</option>
                                                                            })
                                                                        }
                                                                    </optgroup>
                                                                </select>

                                                                <button
                                                                    onClick={() => setFormFieldData({ ...formFieldData, ['email_subject']: formFieldData.email_subject + '  ' + formFieldData.email_token })}
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                >
                                                                    Insert Subject
                                                                </button>
                                                                {/* {html == "" && (
                                                                    <button onClick={() => setFormFieldData({ ...formFieldData, ['email_body']: formFieldData.email_body + '  ' + formFieldData.email_token })} type='button' className='btn btn-primary btn-md'>Insert Into Body</button>
                                                                )} */}
                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-3 d-none">
                                                        <label>Email From</label>
                                                        <div className="input-group">
                                                            <select defaultValue={formFieldData.email_from} onChange={(e) => handleChange(e)} name="email_from" className='form-control' >
                                                                <option value={''}>Select</option>
                                                                {
                                                                    metaData.FromEmail.map((email, e) => {
                                                                        return <option data-title={email.from_email} data-id={email.id} key={e} value={email.id}>{email.from_name}:{email.from_email}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                       </div>

                                                        <div className="form-group mb-3">
                                                            <label>Email Subject</label>
                                                            <div className="input-group">
                                                                <input value={formFieldData.email_subject} onChange={(e) => handleChange(e)} className='form-control' name="email_subject" />
                                                            </div>
                                                        </div>

                                                        {html == "" ? (
                                                            <div className="form-group mb-3">
                                                                <label>Email Body</label>
                                                                <div className="input-group">
                                                                    <textarea value={formFieldData.email_body} onChange={(e) => handleChange(e)} name="email_body" className='form-control' />
                                                                </div>
                                                            </div>
                                                        ) : (<>
                                                            <div
                                                                id="custom-template"
                                                                className="height-500 custom-scroll"
                                                                dangerouslySetInnerHTML={{ __html: html }}
                                                            />
                                                            <input value={path} name="email_body" className='d-none' />
                                                        </>
                                                        )}
                                                        {/* <textarea readOnly value={JSON.stringify(html)} name="email_body" className='form-control d-none' /> */}
                                                        <input type={'hidden'} name="series_id" value={formFieldData.p_series_id} />
                                                        <div className="form-group">
                                                            <a href="javascript:void(0)" className="btn btn-primary pull-right save-btn mt-2" onClick={() => saveAction('email')}>Save</a>
                                                        </div>
                                                    </div>
                                                    : <></>
                                    }
                                </form>
                            ) : <></>}
                        </div>
                    </Modal.Body>
                </Modal >
            </div>
        </React.Fragment >
    );
};

export default EventReminderFlow;
