import React, { Component, Fragment } from "react"
import { Row, Col, Card, CardBody, CardTitle, Form, Label, Button, Modal, ModalHeader, Input, ModalBody, ModalFooter, Table } from "reactstrap"
import { bcrypt, uToken } from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import ProfileSettings from "../Authentication/profile-sidebar";
import Loader from "react-loader";
import { Link } from "react-router-dom";
import swal from 'sweetalert';

export default class Branding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            records: [],
            loader: true,
            page_name: 'Mangage page',
            modal: false,
            modal2: false,
            row: {
                title: '',
                meta_keywords: '',
                meta_description: '',
                content_id: 0
            },
            domain: ''

        }
    }


    componentDidMount() {
        this.getData();
    }

    getData = () => {
        fetch(`${ApiUrl}` + 'get/content/pages', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + uToken()
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {

                    this.setState({
                        records: response.data.records,
                        domain: response.data.domain
                    });

                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }

            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }

    submitPage = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        fetch(`${ApiUrl}` + "crate/page", {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                if (data.status === true) {
                    this.props.history.push(`/content/${bcrypt(data.data.user_id)}/${data.data.slug}`);
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
                this.setState({ loader: true });
            })
            //Then with the error genereted...
            .catch((error) => {
                this.setState({ loader: true });

                console.error("Error:", error);
            });
    }

    submitSeoContent = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        fetch(`${ApiUrl}` + "add/seo/content", {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                if (data.status === true) {
                    this.setState({
                        modal2: false,
                        row: {}
                    })
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
                this.setState({ loader: true });
            })
            //Then with the error genereted...
            .catch((error) => {
                this.setState({ loader: true });

                console.error("Error:", error);
            });
    }

    removeWebsiteContent = () => {

        swal({
            title: "Are you sure?",
            text: "All information related to your domain will be removed.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {

                    fetch(`${ApiUrl}` + "remove/builder/theme", {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ` + uToken()
                        },
                        body: JSON.stringify({ id: 0 }),
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((data) => {
                            if (data.status === true) {
                                let newtoken = {
                                    domain_status: 0
                                };

                                const authUser = {
                                    ...JSON.parse(localStorage.getItem("authUser")),
                                    ...newtoken,
                                };
                                localStorage.setItem("authUser", JSON.stringify(authUser));
                                this.props.history.push('/build/website');
                            } else {
                                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                            }
                        })
                        //Then with the error genereted...
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    }
    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="2" className="pl-0">
                        <ProfileSettings menuOpen={false} props={this.props} />
                    </Col>
                    <Col lg="10">
                        <Loader loaded={this.state.loader}></Loader>
                        <Row>
                            <Col sm={3} >
                                <div className="page-title-box">
                                    <h4> {this.state.page_name} </h4>
                                    <ol className="breadcrumb m-0" >
                                        <li key={0} className="breadcrumb-item active"> {ProductName} </li>
                                        <li key={1} className="breadcrumb-item"> < Link to="#"> {this.state.page_name} </Link></li>

                                    </ol>

                                </div>

                            </Col>
                            <Col lg={6} className="text-center">
                                <h4> &nbsp;</h4>
                                <strong>Website Link:</strong> <a href={`${this.state.domain}`} className="" target={'_blank'}>{this.state.domain}</a>
                            </Col>

                            <Col sm={3}>
                                <div className="page-title-box text-align-right ">
                                    <Button type="button"
                                        color="secondary"
                                        onClick={
                                            () => this.props.history.push('create/content')}>
                                        Create Page
                                    </Button>

                                    <Button type="button"
                                        className="ml-5"
                                        color="danger"
                                        onClick={() => this.removeWebsiteContent()}>
                                        Reset
                                    </Button>
                                </div>


                            </Col>
                        </Row>

                        <Modal
                            isOpen={this.state.modal}
                            fade={false}
                            toggle={() => this.setState({ modal: false })}
                        >
                            <form onSubmit={this.submitPage} method="post"
                                encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                                <ModalBody>
                                    <Row>
                                        <Col md="12">
                                            <div className="form-group mb-3">
                                                <label>Page Title</label>
                                                <input
                                                    name="title"
                                                    label="Page Title"
                                                    className="form-control"
                                                    placeholder="Enter page name"
                                                    required
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        color="info"
                                        type="submit"
                                    >
                                        Create
                                    </Button>
                                    <Button
                                        color="danger"
                                        type="button"
                                        onClick={() => this.setState({ modal: false })}
                                    >
                                        Cancel
                                    </Button>
                                </ModalFooter>
                            </form>
                        </Modal>

                        <Card>
                            <CardBody>
                                <table className="table table-border">
                                    <thead>
                                        <tr>
                                            <th>Id#</th>
                                            <th>Name</th>
                                            <th>Preview Link</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.records.map((row, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{row.id}</td>
                                                    <td>{row.title}</td>
                                                    <td>
                                                        {
                                                            (row.slug != 'header' && row.slug != 'footer') && (
                                                                <a className="border-0" href={`${this.state.domain}/${row.slug}`} target={'_blank'}>
                                                                    Preview
                                                                </a>
                                                            )
                                                        }

                                                    </td>
                                                    <td>
                                                        <Link to={`/content/${bcrypt(row.user_id)}/${row.slug}`} className="btn btn-secondary btn-sm mr-5">
                                                            Edit
                                                        </Link>
                                                        {
                                                            (row.slug != 'header' && row.slug != 'footer') && (
                                                                <button onClick={() => this.setState({ modal2: true, row: row.seo_contents })} className="btn btn-info btn-sm">
                                                                    SEO
                                                                </button>
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                        <Modal isOpen={this.state.modal2}
                            fade={false}
                            toggle={() => this.setState({ modal2: false, row: {} })}>
                            <ModalHeader>
                                Seo Setting
                            </ModalHeader>
                            <form onSubmit={this.submitSeoContent} method="post"
                                encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                                <ModalBody>
                                    <Row>
                                        <Col md="12">
                                            <div className="form-group mb-3">
                                                <label>Title</label>
                                                <input
                                                    name="title"
                                                    defaultValue={this.state.row.title}
                                                    className="form-control"
                                                    placeholder="Enter title"
                                                    required
                                                />
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="form-group mb-3">
                                                <label>Meta keywords</label>
                                                <textarea
                                                    name="meta_keywords"
                                                    defaultValue={this.state.row.meta_keywords}
                                                    className="form-control"
                                                    placeholder="Enter keywords"
                                                    required
                                                />
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="form-group mb-3">
                                                <label>Meta Description</label>
                                                <textarea
                                                    name="meta_description"
                                                    defaultValue={this.state.row.meta_description}
                                                    className="form-control"
                                                    placeholder="Enter description"
                                                    required
                                                />
                                            </div>
                                        </Col>
                                        <input type={'hidden'} name="id" value={this.state.row.content_id} />
                                    </Row>
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        color="info"
                                        onClick={() => this.setState({
                                            modal2: false,
                                            row: {}
                                        })}
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        color="primary"
                                        type="submit"
                                    >
                                        Create
                                    </Button>
                                </ModalFooter>
                            </form>
                        </Modal>
                    </Col>
                </Row>

            </React.Fragment >
        )
    }
}