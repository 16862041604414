import React, { useState, useEffect, useRef } from "react";
import {
    Row,
    Col,
    Card,
    CardTitle,
    Modal,
    ModalBody,
    ModalFooter,
    Button

} from "reactstrap";
import Loader from 'react-loader';


import { AvField, AvForm } from "availity-reactstrap-validation";
import { ApiUrl } from "../../config";
import { bcrypt, uToken } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import C3Chart from 'react-c3js';
import 'c3/c3.css';
import { Link } from "react-router-dom";
const Results = (props) => {

    const [modal, setModal] = useState(false);

    const [id, setId] = useState("");
    const [results, setResults] = useState([]);
    const [loaded, setLoaded] = useState(false);


    useEffect(() => {
        setLoaded(true);
        setResults(props.results)
    }, [props])

    const [data, setData] = React.useState({
        chartData: {
            columns: [
                ["Increase in reveneue", props.results.expected_revenue, props.results.year_profit],
                ["Profit", 0, props.results.profit_increase]

            ],
            types: {
                "Increase in reveneue": "bar",
                "Profit": "bar"
            },
            groups: [["Increase in reveneue", "Profit"]]
        }
    });


    console.log(data)

    return (
        <React.Fragment>
            <h2 className="mb-3">{props.module && props.module.replaceAll('_', ' ')}</h2>
            <Loader loaded={loaded}></Loader>

            <div className="results card card-body">
                <C3Chart data={data.chartData} />
            </div>

            <div className="d-flex space-between">
                <Link to={`/assessment/${bcrypt(props.assessment)}/summary`} className="btn btn-primary btn-sm result-btn">Summary</Link>
                <Link to={`/assessment/${bcrypt(props.assessment)}/${props.module}`} className="btn btn-info btn-sm result-btn">Go to Questionnaire</Link>

            </div>
    

        </React.Fragment>
    );
};

export default Results;
