import React, { Component, Fragment } from "react"
import { Row, Col, Card, CardBody, CardTitle, Form, Label, Button, Modal, ModalHeader, Input, ModalBody, ModalFooter } from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import ReactDatatable from '@ashvin27/react-datatable';

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet"
import axios from "axios"
import { AvForm, AvField, AvInput, AvFeedback, AvGroup } from "availity-reactstrap-validation"
import { dcrypt, getPages, getPermission, getRoles, isAllowed, uInfo, uRole, uToken } from "../../useToken";
import { ApiUrl, AttachementsUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select"
import { Link } from "react-router-dom";
import ProfileSettings from "../Authentication/profile-sidebar";
import Loader from "react-loader";
import SunEditor, { buttonList } from "suneditor-react";
import grapesjs from 'grapesjs'
import 'grapesjs/dist/css/grapes.min.css'
import 'grapesjs/dist/grapes.min.js'
import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.css'
import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.js'
export default class Branding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: dcrypt(this.props.match.params.id),
            page: this.props.match.params.page,
            record: "",
            loader: true,
            domain: ""
        }
        this.editor = null;
    }


    componentDidMount() {
        this.getData();
        document.getElementById('my-form').addEventListener('submit', event => {
            event.preventDefault();
            const projectHtmlEl = document.getElementById('project-html');
            this.handleSubmit(projectHtmlEl.value)
        });

        // Inline storage
        const inlineStorage = (editor) => {
            const projectHtmlEl = document.getElementById('project-html');
            editor.Storage.add('inline', {
                store(data) {
                    const component = editor.Pages.getSelected().getMainComponent();
                    projectHtmlEl.value = `<html>
                  <head>
                    <style>${editor.getCss({ component })}</style>
                  </head>
                  ${editor.getHtml({ component })}
                <html>`;
                }
            });
        };

        // Init editor
        this.editor = grapesjs.init({
            container: '#gjs',
            height: '500px',
            plugins: [inlineStorage, 'gjs-preset-webpage'],
            storageManager: { type: 'inline' },
            // plugins: ['gjs-preset-webpage'],
            pluginsOpts: {
                'gjs-preset-webpage': {
                    // options
                }
            },
            canvas: {
                // scripts: ['https://.../somelib.min.js'],
                // The same would be for external styles
                styles: ['https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css',
                    AttachementsUrl + `user_${uInfo().user_id}/wesite_builder_settings/theme/css/style.css`
                    , AttachementsUrl + `user_${uInfo().user_id}/wesite_builder_settings/theme/css/responsivness.css`],
            }
        });
    }

    getData = () => {
        fetch(`${ApiUrl}` + 'get/page/content/' + this.state.id + '/' + this.state.page, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + uToken()
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    if (response.data.record != null) {
                        this.setState({
                            record: response.data.record,
                            domain: response.data.domain
                        });

                        this.editor.addComponents(response.data.record);
                    }
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }

            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }


    handleSubmit = async (body) => {
        this.setState({ loader: false });
        const submitData = new FormData();
        submitData.append('content', body);
        fetch(`${ApiUrl}` + "update/page/content/" + this.state.id + '/' + this.state.page, {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                if (data.status === true) {
                    this.props.history.push('/manage-contents');
                    // window.location.reload(true);
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
                this.setState({ loader: true });
            })
            //Then with the error genereted...
            .catch((error) => {
                this.setState({ loader: true });

                console.error("Error:", error);
            });
    };

    render() {
        return (
            <React.Fragment>
                <Loader loaded={this.state.loader}></Loader>
                <form id="my-form">
                    <Row>
                        <Col sm={6}>
                        </Col>
                        <Col sm={6}>
                            <div className="page-title-box text-align-right">
                                {
                                    (this.state.page != 'header' && this.state.page != 'footer') && (
                                        <a href={`${this.state.domain}/${this.state.page}`} className="ml-5 mr-5 btn btn-danger" target={'_blank'}>Preview Page</a>
                                    )
                                }
                                <Button
                                    type="button"
                                    color="secondary"
                                    onClick={() => this.props.history.push('/manage-contents')}
                                >
                                    Back
                                </Button>
                                <button className="ml-5 mr-5 btn btn-info" type="submit">Submit</button>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>

                                    <input id="project-html" type="hidden" />
                                    <div id="gjs" style={{ minHeight: '100vh' }}></div>




                                    {/* <form onSubmit={this.handleSubmit} method="post"
                                            encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                                            <div className="mb-3" >
                                                <SunEditor name="content"
                                                    setOptions={
                                                        {
                                                            minHeight: 400,
                                                            height: '100%',
                                                            width: '100%',
                                                            resizeEnable: 'false',
                                                            // height: 400,
                                                            buttonList: [
                                                                ["undo", "redo", "font", "fontSize", "formatBlock"],
                                                                [
                                                                    "bold",
                                                                    "underline",
                                                                    "italic",
                                                                    "strike",
                                                                    "subscript",
                                                                    "superscript",
                                                                    "removeFormat",
                                                                ],
                                                                [
                                                                    "fontColor",
                                                                    "hiliteColor",
                                                                    "outdent",
                                                                    "indent",
                                                                    "align",
                                                                    "horizontalRule",
                                                                    "list",
                                                                    "table",
                                                                ],
                                                                [
                                                                    "link",
                                                                    "image",
                                                                    "video",
                                                                    "fullScreen",
                                                                    "showBlocks",
                                                                    "codeView",
                                                                    "preview",
                                                                ],

                                                            ],
                                                            proportion: 'Constrain proportions assasa',
                                                        }
                                                    }
                                                    setContents={this.state.record}

                                                // appendContents={this.state.record}
                                                // onChange={this.handleChange}
                                                // appendContents={this.state.appendContents}
                                                />
                                            </div>
                                            <button
                                                className="btn btn-primary w-md waves-effect waves-light"
                                                type="submit"
                                                disabled={this.state.loader ? false : true}
                                            >
                                                Submit
                                            </button>
                                        </form> */}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </form>
            </React.Fragment >
        )
    }
}