import * as React from 'react';
import { ApiUrl } from '../../../config';
import { uToken } from '../../../useToken';
import swal from "sweetalert";
import ListGroup from 'react-bootstrap/ListGroup';
import {
    AvForm,
    AvField,
    AvInput,
    AvRadioGroup,
    AvRadio,
    AvCheckboxGroup,
    AvCheckbox,
    AvGroup,
  } from "availity-reactstrap-validation";
  import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
  } from "reactstrap";
  import Select from "react-select";
  import { Link } from "react-router-dom";
  import { Accordion } from "react-bootstrap";

export default class WayTrainingAssign extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            user_id: props.userId,
            training_for: props.trainingFor,
            type: props.type?props.type:"single",
            trainingList: [],
            groups: [],
           
            record: {
              group_id: [],
              selectedGroup: [],
              permission_type: "group",
            }
        }
    }

    componentDidMount() {
      console.log(this.props, 'props.props')
   
      this.getData();
      
        const setInputState = (el, state) => {
          if (state === 'indeterminate') {
              el.indeterminate = true
          } else {
              el.indeterminate = false
              el.checked = state
          }
        }

      const updateOwned = (el) => {
          if (el.hasAttribute('data-children')) {
              let state = el.checked
              el.getAttribute('data-children').split(' ').forEach(id => {
                  let owned = document.getElementById(id)
                  setInputState(owned, state)
                  updateOwned(owned)
              })
          }
      }

      const updateOwner = (el) => {
          if (el.hasAttribute('data-parent')) {
              let owner = document.getElementById(el.getAttribute('data-parent'))
              let states = []
              let collectiveState
              owner.getAttribute('data-children').split(' ').every(id => {
                  let owned = document.getElementById(id)
                  let state = owned.indeterminate === true ? 'indeterminate' : owned.checked
                  if (states.length > 0 && states.indexOf(state) === -1) {
                      collectiveState = 'indeterminate'
                      return false
                  } else {
                      states.push(state)
                      return true
                  }
              })
              collectiveState = collectiveState || states[0]
              setInputState(owner, collectiveState)
              updateOwner(owner)
          }
      }

      document.querySelectorAll('.nested-multiselect').forEach(multiselect => {
          multiselect.querySelectorAll('input[type="checkbox"][data-children], input[type="checkbox"][data-parent]').forEach(input => {
              input.addEventListener('change', event => {
                  updateOwned(event.currentTarget)
                  updateOwner(event.currentTarget)
              })
          })
      });

    
    }
    getData = (queryString = "", data) => {
        fetch(`${ApiUrl}` + "traininggroups" + queryString, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
          },
          body: JSON.stringify({user_id: this.state.user_id,type: this.state.type}),
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.status === true) {
              this.setState({
                groups: response.data.records,
                
              });
              this.setState(prevState => {
                let record = Object.assign({}, prevState.record);
                record.selectedGroup = response.data.selectedGroup;
                record.group_id = response.data.group_id;
                record.permission_type = response.data.selected_option;
                return { record };
              });
  
              this.getWayUserTraining();
             
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      };
      handleGroup = (selectedGroup) => {
        var filter = [];
        if (selectedGroup) {
          selectedGroup.map((fil) => {
            filter.push(fil.id);
          });
        }
        this.setState(prevState => {
          let record = Object.assign({}, prevState.record);
          record.selectedGroup = selectedGroup;
          record.group_id = filter.toString();
          return { record };
        });
      };
      getWayUserTraining = () => {
        fetch(`${ApiUrl}` + "get/group/trainingCategory", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: 0, user_id: this.state.user_id, training_for: 3, type: this.state.type }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        trainingList: response.data.record
                    });
                    const setInputState = (el, state) => {
                        if (state === 'indeterminate') {
                            el.indeterminate = true
                        } else {
                            el.indeterminate = false
                            el.checked = state
                        }
                    }
    
                    const updateOwned = (el) => {
                        if (el.hasAttribute('data-children')) {
                            let state = el.checked
                            el.getAttribute('data-children').split(' ').forEach(id => {
                                let owned = document.getElementById(id)
                                setInputState(owned, state)
                                updateOwned(owned)
                            })
                        }
                    }
    
                    const updateOwner = (el) => {
    
                        if (el.hasAttribute('data-parent')) {
                            let owner = document.getElementById(el.getAttribute('data-parent'))
                            let states = []
                            let collectiveState
                            owner.getAttribute('data-children').split(' ').every(id => {
                                let owned = document.getElementById(id)
                                let state = owned.indeterminate === true ? 'indeterminate' : owned.checked
                                if (states.length > 0 && states.indexOf(state) === -1) {
                                    collectiveState = 'indeterminate'
                                    return false
                                } else {
                                    states.push(state)
                                    return true
                                }
                            })
                            collectiveState = collectiveState || states[0]
                            setInputState(owner, collectiveState)
                            updateOwner(owner)
                        }
                    }
    
                    document.querySelectorAll('.nested-multiselect').forEach(multiselect => {
                        multiselect.querySelectorAll('input[type="checkbox"][data-children], input[type="checkbox"][data-parent]').forEach(input => {
                            input.addEventListener('change', event => {
                                updateOwned(event.currentTarget)
                                updateOwner(event.currentTarget)
                            })
                        })
                    });
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
    
            })
            .catch((error) => {
                console.error("Error:", error);
            });
      }
    handleSubmit = async (event) => {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      const submitData = new FormData();
      for (let [key, value] of formData.entries()) {
        submitData.append(key, value);
      }
      if (this.state.user_id != "") {
        submitData.append('user_id', this.state.user_id);
      }
      submitData.append('type', this.state.type);
      submitData.append('permission_type', this.state.record.permission_type);
        fetch(`${ApiUrl}` + "assign/training/group", {
          method: "POST",
          headers: {
              Authorization: `Bearer ` + uToken(),
          },
          body: submitData,
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    swal({
                        title: response.message,
                        icon: "success",
                    });
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    render() {

        return (
            <div>
               <form method="post" onSubmit={this.handleSubmit}
                    encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                <ModalBody className="row">
                
                    <div className="col-md-12 mb-3 d-flex">
                      <label className="control-label pr-3">Training Permission Type: {this.state.record.permission_type}</label>
                      <div className="radio mr-5">
                        <label>
                        <input
                          type="radio"
                          checked={this.state.record.permission_type === "group"}
                          onChange={(e) =>
                        {
                        this.setState(prevState => {
                          let record = Object.assign({}, prevState.record);
                          record.permission_type = e.target.value;
                          return { record };
                          });
                          this.getWayUserTraining();
                          }
                        } 
                        value="group"
                        />
                        {" "}Group
                        </label>
                      </div>
                      <div className="radio ml-5">
                        <label>
                        <input
                          type="radio"
                          checked={this.state.record.permission_type == "manual"}
                          onChange={(e) =>
                          {
                            this.setState(prevState => {
                              let record = Object.assign({}, prevState.record);
                              record.permission_type = e.target.value;
                              return { record };
                              });
                              this.getWayUserTraining();
                            }
                          } 
                          value="manual"
                        />
                        {" "}Individual
                        </label>
                      </div>
                    </div>
             
                  {
                    this.state.record.permission_type == 'group' ? (
                    <div className="row mb-3">
                      <div className="col-md-9">
                 
                        <label>Training Groups </label>
                      
                        <Select 
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          isMulti={true}
                          options={this.state.groups}
                          classNamePrefix="select form-control"
                          style={{ position: "absolute" }}
                          className={"is-touched is-dirty av-valid"}
                          name="group_id"
                      
                          value={this.state.record.selectedGroup}
                          onChange={(e) => {
                            this.handleGroup(e);
                          }}
                        />
                        <input
                          required
                          type="hidden"
                          name="group_id"
                          value={this.state.record.group_id}
                        />
                      
                    
                      </div>
                      <div className="col-md-2">
                        <label className="col-md-3">&nbsp;</label>
                        <Link
                          type="button"
                          className="btn btn-primary w-md waves-effect waves-light"
                          to={"/traininggroup/list"}
                          target="_blank"
                        >
                        <i className="mdi mdi-plus"> </i>Add New
                        </Link>
                        </div>
                    </div>
                    ):(
                    <fieldset className="nested-multiselect-14">
                        {
                        this.state.trainingList.map((cat, i) => {
                            return (
                            <fieldset className="nested-multiselect">
                                <Accordion defaultActiveKey={parseInt(cat.cat_id)} className="border-0">
                                <Accordion.Item eventKey={i} className={`${i} --aa -- ${cat.cat_id} `}>
                                    <Accordion.Header>
                                    <label id={`label-${cat.cat_id}`}>
                                        <input id={`${cat.cat_id}`}
                                        type="checkbox"
                                        name="categories"
                                        value={`${cat.cat_id}`}
                                        defaultChecked={cat.assign_checked}
                                        aria-owns={`subcategories-${cat.cat_id}`}
                                        data-children={cat.childeren} />
                                        <span> {cat.title}</span>
                                    </label>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    {
                                        cat.sub_cat.map((sub, s) => {
                                        return (
                                            <Accordion defaultActiveKey={parseInt(sub.cat_id)} className="border-0">
                                            <Accordion.Item eventKey={s} className={`${s} --aa -- ${sub.cat_id} `}>
                                                <Accordion.Header>
                                                <label id={`label-${sub.cat_id}`}>
                                                    <input id={`${sub.cat_id}`}
                                                    type="checkbox"
                                                    name="categories"
                                                    value={`${sub.cat_id}`}
                                                    defaultChecked={sub.assign_checked}
                                                    aria-owns={`subcategories-${sub.cat_id}`}
                                                    data-parent={cat.cat_id}
                                                    data-children={sub.childeren} />
                                                    <span> {sub.title}</span>
                                                </label>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                {
                                                    sub.sub_cat.map((final, f) => {
                                                    return (
                                                        <Accordion defaultActiveKey={parseInt(final.cat_id)} className="border-0">
                                                        <Accordion.Item eventKey={f} className={`${f} --aa -- ${final.cat_id} `}>
                                                            <Accordion.Header>
                                                            <label id={`label-${final.cat_id}`}>
                                                                <input
                                                                id={`${final.cat_id}`}
                                                                type="checkbox"
                                                                name="category[]"
                                                                value={final.cat_id}
                                                                defaultChecked={final.assign_checked}
                                                                data-parent={sub.cat_id}
                                                                aria-owns={`subcategories-${final.cat_id}`}
                                                                data-children={final.childeren}
                                                                />
                                                                <span> {final.title}</span>
                                                            </label>
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                            {
                                                                final.sub_cat.map((step, s) => {
                                                                return (
                                                                    // <Accordion defaultActiveKey={parseInt(step.step_id)} className="border-0">
                                                                    //   <Accordion.Item eventKey={s} className={`${s} --aa -- ${step.step_id} `}>
                                                                    //     <Accordion.Header>
                                                                        <label id={`label-s-${step.step_id}`} className="col-md-12">
                                                                            <input
                                                                            id={`s-${step.step_id}`}
                                                                            type="checkbox"
                                                                            name="subcategory[]"
                                                                            value={`${cat.cat_id}-${sub.cat_id}-${final.cat_id}-${step.step_id}`}
                                                                            defaultChecked={step.assign_checked}
                                                                            data-parent={final.cat_id}
                                                                            />
                                                                            <span> {step.title}</span>
                                                                        </label>
                                                                    //     </Accordion.Header>
                                                                    //   </Accordion.Item>
                                                                    // </Accordion>
                                                                )
                                                                })}
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        </Accordion>
                                                    )
                                                    })
                                                }
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            </Accordion >
                                        )
                                        })
                                    }
                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion >
                            </fieldset>
                            );

                        })
                        }
                    </fieldset>
                    //     <fieldset class="nested-multiselect-14">
                    //     {
                    //         this.state.trainingList.map((cat, i) => {
                    //             return (
                    //                 <fieldset class="nested-multiselect">
                    //                     <ListGroup>
                    //                         <ListGroup.Item>
                    //                             <label id={`label-${cat.cat_id}`}>
                    //                                 <input id={`${cat.cat_id}`}
                    //                                     type="checkbox"
                    //                                     name="categories"
                    //                                     value={`${cat.cat_id}`}
                    //                                     aria-owns={`subcategories-${cat.cat_id}`}
                    //                                     defaultChecked={cat.assign_checked}
                    //                                     data-children={cat.childeren} />
                    //                                 <span> {cat.title}</span>
                    //                             </label>
                    //                             <fieldset id={`subcategories-${cat.cat_id}`}
                    //                                 aria-label={`${cat.cat_id} subcategories`}>
                    //                                 <ListGroup>
                    //                                     {
                    //                                         cat.sub_cat.map((sub, s) => {
                    //                                             return (
                    //                                                 <ListGroup.Item>
                    //                                                     <label id={`label-${sub.cat_id}`}>
                    //                                                         <input id={`${sub.cat_id}`}
                    //                                                             type="checkbox"
                    //                                                             name="categories"
                    //                                                             value={`${sub.cat_id}`}
                    //                                                             aria-owns={`subcategories-${sub.cat_id}`}
                    //                                                             data-parent={cat.cat_id}
                    //                                                             defaultChecked={sub.assign_checked}
                    //                                                             data-children={sub.childeren} />
                    //                                                         <span> {sub.title}</span>
                    //                                                     </label>
                    //                                                     <fieldset id={`subcategories-${sub.cat_id}`}
                    //                                                         aria-label={`${sub.cat_id} subcategories`}
                    //                                                     >
                    //                                                         <ListGroup>
                    //                                                             {
                    //                                                                 sub.sub_cat.map((final, f) => {
                    //                                                                     return (
                    //                                                                         <ListGroup.Item>
                    //                                                                             <label id={`label-${final.cat_id}`}>
                    //                                                                                 <input
                    //                                                                                     id={`${final.cat_id}`}
                    //                                                                                     type="checkbox"
                    //                                                                                     name="category[]"
                    //                                                                                     defaultChecked={final.assign_checked}
                    //                                                                                     value={final.cat_id}
                    //                                                                                     data-parent={sub.cat_id}
                    //                                                                                     aria-owns={`subcategories-${final.cat_id}`}
                    //                                                                                     data-children={final.childeren}
    
                    //                                                                                 />
                    //                                                                                 <span> {final.title}</span>
                    //                                                                             </label>
                    //                                                                             <fieldset id={`subcategories-${final.cat_id}`}
                    //                                                                                 aria-label={`${final.cat_id} subcategories`}
                    //                                                                             >
                    //                                                                                 <ListGroup>
                    //                                                                                     {
                    //                                                                                         final.sub_cat.map((step, s) => {
                    //                                                                                             return (
                    //                                                                                                 <ListGroup.Item>
                    //                                                                                                     <label id={`label-s-${step.step_id}`}>
                    //                                                                                                         <input
                    //                                                                                                             id={`s-${step.step_id}`}
                    //                                                                                                             type="checkbox"
                    //                                                                                                             name="subcategory[]"
                    //                                                                                                             defaultChecked={step.assign_checked}
                    //                                                                                                             value={`${cat.cat_id}-${sub.cat_id}-${final.cat_id}-${step.step_id}`}
                    //                                                                                                             data-parent={final.cat_id}
    
                    //                                                                                                         />
                    //                                                                                                         <span> {step.title}</span>
                    //                                                                                                     </label>
                    //                                                                                                 </ListGroup.Item>
                    //                                                                                             )
                    //                                                                                         })}
                    //                                                                                 </ListGroup>
                    //                                                                             </fieldset>
                    //                                                                         </ListGroup.Item>
                    //                                                                     )
                    //                                                                 })
                    //                                                             }
                    //                                                         </ListGroup>
    
                    //                                                     </fieldset>
                    //                                                 </ListGroup.Item>
                    //                                             )
                    //                                         })
                    //                                     }
                    //                                 </ListGroup>
    
                    //                             </fieldset>
                    //                         </ListGroup.Item>
                    //                     </ListGroup>
                    //                 </fieldset>
                    //             );
    
                    //         })
                    //     }
                    //   </fieldset>
                    )}
                </ModalBody>
                <ModalFooter>
                  <button
                    className="btn btn-primary w-md waves-effect waves-light"
                    type="submit"
                    style={{float:"left"}}
                  >
                    Submit
                  </button>
                </ModalFooter>
              </form>
            </div >
        );
    };
};