import React, { Component, Fragment, useState } from "react"
import { Link } from "react-router-dom";
import { ApiUrl, AttachementsUrl } from "../../config";
import Context from './Context';
import swal from "sweetalert";
import {
    Modal,
    Card,
    CardBody,
    Button,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ImageCropper from "./ImageCropper";

const Box6_1 = (props) => {
    const params = React.useContext(Context);
    const [modal, setModal] = useState(false);
    const [imageData, setImageData] = useState({
        id: '',
        url: '',
    });

    const [inputFields, setInputFields] = useState(
        { preview_logo: '', preview_hero_image: '', preview_favicon: '' }
    );


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        if (inputFields.preview_logo != '') {
            submitData.append('preview_logo_base_64', inputFields.preview_logo);
        }

        if (inputFields.preview_hero_image != '') {
            submitData.append('preview_hero_image_base_64', inputFields.preview_hero_image);
        }

        if (inputFields.preview_favicon != '') {
            submitData.append('preview_favicon_base_64', inputFields.preview_favicon);
        }

        fetch(`${ApiUrl}` + "update/builder/setting/" + params.user_id, {
            method: "POST",
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    params.goToBox(params.currentBox + 1);
                    params.getBuilderSetting();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const changeHandlerImg = (e, id) => {
        if (e.target.files[0].type.match('image.*')) {
            const preview = document.querySelector('#' + id);
            var reader = new FileReader();
            reader.onload = () => {
                preview.src = reader.result;
                imageCropper(id, reader.result)
            };
            reader.readAsDataURL(e.target.files[0]);
        } else {
            swal({
                title: 'Olny images allowed',
                icon: "warning",
            });
        }
    };

    const imageCropper = (id, url) => {
        // var url = document.getElementById(id).src;
        setImageData({
            id: id,
            url: url
        });
        setTimeout(() => {
            setModal(true);
        }, 200);
    }

    const imageCropperSet = (id, url) => {
        const preview = document.querySelector('#' + id);
        preview.src = url;

        setInputFields({ ...inputFields, [id]: url })

        setModal(false);
    }

    const closeModal = () => {
        setInputFields({ ...inputFields, [imageData.id]: imageData.url })
        setModal(false);
    }
    return (
        <div>
            <div className="box-center-align mt-3">
                <div className="SmallContaner my-4">
                    <div className="d-flex align-items-center gap-2 StepHeading">
                        <div className="StepNumber">3 <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">Graphic Uploads / Selections</h4>
                    </div>
                    <form method="post" onSubmit={handleSubmit}
                        encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                        <div className="border-top border-bottom border-left border-right p-4 mt-3">

                            <div className="page-content-box">
                                <div className="TitleWizred d-flex align-items-center gap-2">
                                    <span className="fs-5"> b.</span> <h5>Upload Media</h5>
                                </div>
                                <div className="WizredForm mt-2">
                                    <div className="form-group">
                                        <label>Upload your logo file <note className="text-muted">(Preferably size 250 x 150 & Preferably in a PNG file.)</note></label>
                                        <input name="logo" className="form-control no-border px-0"
                                            accept="image/*"
                                            type="file" placeholder="https://"
                                            onChange={(e) => changeHandlerImg(e, 'preview_logo')}
                                        />
                                        <img width={'50'} id="preview_logo" src={params.setting.logo} className="img img-thumbnail mt-1" alt="Not Found" />
                                    </div>

                                    <div className="form-group mt-4">
                                        <label>Upload a favicon file <note className="text-muted">(Preferably size 48 x 48 & & Preferably in a PNG file)</note></label>
                                    <br /><h6 className="text-muted my-3">You can use free online tools such as: <a target="_blank" href="https://favicon.io/favicon-converter/">https://favicon.io/favicon-converter/</a></h6>
                                    <input name="favicon" className="form-control no-border px-0"
                                        accept="image/*"
                                        type="file" placeholder="https://"
                                        onChange={(e) => changeHandlerImg(e, 'preview_favicon')}
                                    />
                                    <img width={'50'} id="preview_favicon" src={params.setting.favicon} className="img img-thumbnail mt-1" alt="Not Found" />
                                </div>

                                {/* <div className="form-group mt-4">
                                        <label>If you have your own background hero image, upload here <note className="text-muted">(Preferably size 1920 x 760)</note></label>
                                        <input name="hero_image" className="form-control no-border px-0"
                                            accept="image/*"
                                            type="file" placeholder="https://"
                                            onChange={(e) => changeHandlerImg(e, 'preview_hero_image')}
                                        />
                                        <img width={'50'} id="preview_hero_image" src={params.setting.hero_image} className="img img-thumbnail mt-1" alt="Not Found" />
                                    </div> */}
                            </div>
                        </div>
                </div>
                <button type="submit" className="btn btn-lg btn-primary mt-2"> Next &nbsp;<i className="fas fa-check"></i></button>
            </form>
        </div>
            </div >

    <Modal
        isOpen={modal}
        fade={true}
        size="lg"
        className="subActionTraining websitebuilder"
        toggle={() => closeModal()}
        centered={true}
        backdrop={'static'}

    >
        <div className="">
            <div className='modal-header'>
                <div className='modal-title'>
                    Crop Image
                </div>
                {/* <button onClick={() => closeModal()} className="mb-3 btn btn-link pull-right BtnCenterClose"><i className="fas fa-times"></i></button> */}
            </div>

            <ImageCropper closeModal={closeModal} imageCropperSet={imageCropperSet} data={imageData} />



        </div>
    </Modal>
        </div >
    );
};

export default Box6_1;