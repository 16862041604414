import React, { useState, useEffect, useRef } from "react";
import {
    Row,
    Col,
    Card,
    CardTitle,
    Modal,
    ModalBody,
    ModalFooter,
    Button

} from "reactstrap";
import Loader from 'react-loader';


import { AvField, AvForm } from "availity-reactstrap-validation";
import { ApiUrl } from "../../config";
import { bcrypt, uToken } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from "react-router-dom";
import Implementation from "./implementation";
import bulb from './../../assets/images/assessments-icons/bulb.png';

const Survey = (props) => {

    const [modal1, setModal1] = useState(true);
    const [modal, setModal] = useState(false);
    const [id, setId] = useState("");
    const [comment, setComment] = useState("");
    const [question, setQuestion] = useState([]);
    const [index, setIndex] = useState(-1)
    const [surveyModal, setSurveyModal] = useState(false);
    const [surveyIndex, setSurveyIndex] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [button, setButton] = useState(false);
    const [productQuantity, setProductQuantity] = useState({});
    const [fillSurvey, setFillSurvey] = useState(props.fillSurvey);
    const [implementation_show, setImplementationShow] = useState(true);
    const [showAll, setshowAll] = useState(false);
    const [allQuestion, setAllQuestion] = useState(props.questions);
    const [local, setLocal] = useState(false);


    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoaded(false);
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        submitData.append('assessment_id', props.assessment);
        submitData.append('module_id', props.module_id);
        submitData.append('cat_key', props.slug);
        if (showAll) {
            submitData.append('all_submit', 1);
        }
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        let v = submitData.get('question');

        if(!props.canEdit && !surveyModal){
            setLoaded(true);
            setIndex(index + 1);
            return true;
        }


        fetch(`${ApiUrl}` + "submit/assessment/survey", {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    // props.defaultSummary();
                    setLoaded(true);
                    if (showAll) {
                        CloseSurveyModal();
                    } else {
                        if (!surveyModal) {
                            let v = submitData.get('question');
                            let is_child = submitData.get('is_child');

                            let newArr = [...question];
                            console.log(newArr, index, is_child, submitData.get(`question_id[q_${is_child}]`))

                            newArr[index][0]["ans"] = submitData.get(`question_id[q_${v}]`);
                            if (is_child) {
                                let question_index = newArr[index].findIndex(x => x.question_id == is_child);
                                newArr[index][question_index]["ans"] = submitData.get(`question_id[q_${is_child}]`);

                                let newAllArr = [...allQuestion];
                                let question_index_all = newAllArr.findIndex(x => x.question_id == is_child);
                                newAllArr[question_index_all]["ans"] = submitData.get(`question_id[q_${is_child}]`);
                                setAllQuestion(newAllArr);

                            }

                            setQuestion(newArr);

                            setIndex(index + 1);
                        } else {

                            let v = submitData.get('question');
                            let is_child = submitData.get('is_child');
                            let index1 = submitData.get('index');
                            let newArr = [...question];
                            if (is_child) {
                                let question_index = newArr[surveyIndex].findIndex(x => x.question_id == is_child);
                                newArr[surveyIndex][question_index]["ans"] = submitData.get(`question_id[q_${is_child}]`);

                                let newAllArr = [...allQuestion];
                                let question_index_all = newAllArr.findIndex(a => a.question_id == is_child);
                                newAllArr[question_index_all]["ans"] = submitData.get(`question_id[q_${is_child}]`);
                                console.log(newAllArr, 'newArr[newAllArr]', is_child, question_index, submitData.get(`question_id[q_${is_child}]`))

                                setAllQuestion(newAllArr);

                            }

                            newArr[surveyIndex][index1]["ans"] = submitData.get(`question_id[q_${v}]`);

                            setQuestion(newArr);

                            setSurveyModal(false);
                        }

                    }
                    // toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
                setLoaded(true);
                setLocal(false);
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
                setLoaded(true);
                setLocal(false);
            });
    }

    const handleCommentSubmit = async (event, values) => {
        values.assessment_id = props.assessment;
        values.cat_key = props.slug;
        values.question_id = id;
        fetch(`${ApiUrl}` + "submit/assessment/survery/comment", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                if (data.status === true) {
                    if (showAll) {
                        let newArr = [...allQuestion];
                        let question_index = newArr.findIndex(x => x.question_id == id);
                        newArr[question_index]["question_comment"] = values.comment;
                        setAllQuestion(newArr);
                    } else {
                        let newArr = [...question];
                        let question_index = newArr[index].findIndex(x => x.question_id == id);
                        if (question_index >= 0) {
                            newArr[index][question_index]["question_comment"] = values.comment;
                        } else {
                            let question_index1 = newArr[surveyIndex].findIndex(x => x.question_id == id);
                            newArr[surveyIndex][question_index1]["question_comment"] = values.comment;
                        }
                        setQuestion(newArr);
                    }
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                    setModal(false);
                    setId('');
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    useEffect(() => {
        chunkarray();
        setIndex(0);
        setLoaded(false);
        setFillSurvey(props.fillSurvey);
    }, [props])


    function chunks(arr, e, n) {
        for (let i = 0; i < arr.length; i += n) {
            if (n == undefined) {
                return arr.slice(e + 1, props.questions.length);
            } else {
                return arr.slice(e + 1, n + 1);
            }
        }
    }

    function chunkArrayInGroups(arr) {
        const odds = [];
        arr.forEach((number, index) => {
            if (index % 2 !== 0) {
                odds.push(index);
            }
        });
        return odds;
    }



    async function chunkarray() {
        let array = [];
        // let a = await chunkArrayInGroups(props.questions);
        let a = await indexOfAll(props.questions, "next");

        if (a.length > 0) {

            for (let k = 0; k <= a.length; k++) {
                if (k == 0) {
                    array.push(chunks(props.questions, -1, a[k]))
                }
                else {
                    array.push(chunks(props.questions, a[k - 1], a[k]))
                }
                // console.log(a[k] *  a[k-1]);
                // console.log([...chunks(props.questions])
            }
            // setQuestion(array);
        } else {
            array.push(props.questions)
        }

        setQuestion(array);

    }

    const indexOfAll = (arr, val) => arr.reduce((acc, el, i) => (el.type === val ? [...acc, i] : acc), []);



    useEffect(() => {
        CreateCustomFieldUI();
        setLoaded(true);
    }, [question])



    const CreateSurveyCustomFieldUI = () => {
        return question.length > 0 && surveyIndex < question.length && question[surveyIndex].map((row, i) => {
            if (row.type == "text" || row.type == "date" || row.type == "number") {
                return <div key={row.type + '-m-' + i} className={`mb-3 form-group ${row.question_id}  ${row.p_question_info != row.p_question_ans && ('d-none')}`}>
                    <label htmlFor={row.question_id} className={`formbuilder-text-label`} dangerouslySetInnerHTML={{ __html: row.question }}>
                    </label>
                    {
                        row.is_child == 0 && (
                            <input
                                type={'hidden'}
                                name={`question`}
                                defaultValue={row.question_id}

                            />
                        )
                    }

                    {
                        row.is_child != 0 && (
                            <input
                                type={'hidden'}
                                name={`is_child`}
                                defaultValue={row.question_id}

                            />
                        )
                    }

                    <input
                        type={'hidden'}
                        name={`index`}
                        defaultValue={i}

                    />

                    {row.info_text && (
                        <OverlayTrigger
                            key={row.type + '--' + i}
                            placement={'auto'}
                            overlay={
                                <Tooltip>
                                    {row.info_text}
                                </Tooltip>
                            }
                        >
                            <i className="fas fa-question-circle pl-3"></i>
                        </OverlayTrigger>
                    )}
                    <input
                        type={row.type}
                        name={`question_id[q_${row.question_id}]`}
                        defaultValue={productQuantity[`question_id[q_${row.question_id}]`] ? productQuantity[`question_id[q_${row.question_id}]`] : row.ans}
                        className={'form-control'}
                        placeholder={'Type your answer here'}
                        onChange={(e) => setProductQuantity(state => ({
                            ...state,
                            [`question_id[q_${row.question_id}]`]: e.target.value
                        }))}
                    />
                </div>
            } else if (row.type == "select") {
                console.log(row, 'row.ans')
                return <div key={row.type + '-m-' + i} className={`mb-3 fb-select form-group field-${row.question_id}>`}>
                    <label htmlFor={row.question_id} className={`formbuilder-text-label`}>{row.question}
                        {row.info_text && (
                            <OverlayTrigger
                                key={row.type + '-m-' + i}
                                placement={'auto'}
                                overlay={
                                    <Tooltip>
                                        {row.info_text}
                                    </Tooltip>
                                }
                            >
                                <i className="fas fa-question-circle pl-3"></i>
                            </OverlayTrigger>
                        )}
                    </label>
                    <input
                        type={'hidden'}
                        name={`question`}
                        defaultValue={row.question_id}

                    />

                    <input
                        type={'hidden'}
                        name={`index`}
                        defaultValue={i}

                    />
                    <select
                        className={'form-control select'}
                        name={`question_id[q_${row.question_id}]`}
                        defaultValue={row.ans}
                        onChange={(e) => {
                            let newArr = [...question];
                            let question_index = newArr[surveyIndex].findIndex(x => x.is_child == row.question_id);
                            newArr[surveyIndex][question_index]["p_question_ans"] = e.target.value;
                            setQuestion(newArr);

                        }}
                    >
                        <option key={"selct" + i} value=''>Select</option>
                        {row.options.map((option, r) => {
                            return <option key={option + '-m-' + r} value={option}
                            // selected={row.ans == option ? 'selected' : ''}
                            >
                                {option}
                            </option>

                        })}
                    </select>
                </div>
            } else if (row.type == "radio") {
                return <div key={row.type + '-m-' + i} className={`fb-select mb-3 form-group field-${row.question_id}>`}>
                    <label htmlFor={row.question_id} className={`formbuilder-text-label`}>{row.question}
                        {row.info_text && (
                            <OverlayTrigger
                                key={row.type + '-m-' + i}
                                placement={'auto'}
                                overlay={
                                    <Tooltip>
                                        {row.info_text}
                                    </Tooltip>
                                }
                            >
                                <i className="fas fa-question-circle pl-3"></i>
                            </OverlayTrigger>
                        )}
                    </label>
                    {row.options.map((option, i) => {
                        return <div key={option.value + '-m-' + i} className={'radio form-check-inline'}>
                            <input
                                defaultChecked={row.ans}
                                name={`question_id[q_${row.question_id}]`} type="radio"
                                className="form-check-input" value={option} />
                            <label className="form-check-label pl-3">{`${option}`}</label>
                        </div>
                    })}
                </div>
            } else if (row.type == 'paragraph') {
                return <div key={row.type + '-m-' + i} className="paragraph">{row.question}</div>
            } else if (row.type == 'html') {
                return <div key={row.type + '-m-' + i} className="html "><div dangerouslySetInnerHTML={{ __html: row.question }} /></div>
            }
        })
    }


    function NumberWithCommas(x) {
        let y = Math.trunc(x);
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const CreateCustomFieldUI = () => {

        return question.length > 0 && index < question.length && question[index].map((row, i) => {
            if (row.ans != '' && row.ans != null && question[index].length <= 2 && !local) {
                setIndex(index + 1)
            }
            if (row.type == "text" || row.type == "date" || row.type == "number") {
                return <div key={row.type + '--' + i} className={`mb-3 form-group ${row.question_id} ${row.p_question_info != row.p_question_ans && ('d-none')
                    }`}>
                        <div className="d-flex flex-row-reverse">
                            <div className="ms-1">
                            <label className="w-100">&nbsp;</label>
                        {
                            props.canEdit && (
                                <button className="btn btn-primary btn-sm d-block pull-right mb-1"
                                    type="button"
                                    onClick={() => { setModal(true); setId(row.question_id); setComment(row.question_comment) }}>
                                    <i className="fas fa-comment"></i>
                                </button>
                            )
                        }
                        </div>
                    <div className="w-100">
                    <label htmlFor={row.question_id} className={`formbuilder-text-label`} dangerouslySetInnerHTML={{ __html: row.question }}>
                    </label>
                    {
                        row.is_child == 0 && (
                            <input
                                type={'hidden'}
                                name={`question`}
                                defaultValue={row.question_id}

                            />
                        )
                    }

                    {
                        row.is_child != 0 && (
                            <input
                                type={'hidden'}
                                name={`is_child`}
                                defaultValue={row.question_id}

                            />
                        )
                    }

                    {row.info_text && (
                        <OverlayTrigger
                            key={row.type + '--' + i}
                            placement={'auto'}
                            overlay={
                                <Tooltip>
                                    {row.info_text}
                                </Tooltip>
                            }
                        >
                            <i className="fas fa-question-circle pl-3"></i>
                        </OverlayTrigger>
                    )}
                    {
                        row.front_calculation == 1 ? (
                            <div className="grid-question">
                                <input
                                    type={row.type}
                                    name={`question_id[q_${row.question_id}]`}
                                    defaultValue={row.ans}
                                    className={'form-control'}
                                    onChange={(e) => calculation(e.target.value, row)}
                                    placeholder={'Type your answer here'}
                                />

                                {
                                    row.ans != '' ? (
                                        <small><label id={`question_id${row.question_id}`}> {props.currency}{NumberWithCommas(row.calculate * row.ans)} </label><span>{row.calculate_text}</span></small>

                                    ) : (
                                        <small><label id={`question_id${row.question_id}`}> {props.currency}{NumberWithCommas(row.calculate)} </label><span>{row.calculate_text}</span></small>

                                    )
                                }


                            </div>
                        ) : (
                            <div className="d-flex">
                                <input
                                    key={`question_id[q_${row.question_id}]`}
                                    type={row.type}
                                    name={`question_id[q_${row.question_id}]`}
                                    defaultValue={row.ans}
                                    className={'form-control'}
                                    placeholder={row.type == 'number' ? ' Enter numeric value' : 'Type your answer here'}
                                    onChange={(e) => setProductQuantity(state => ({
                                        ...state,
                                        [`question_id[q_${row.question_id}]`]: e.target.value
                                    }))}
                                />

                                {/* {
                                    row.type == 'number' && (
                                        <small><label id={`question_id${row.question_id}`}> Enter numeric value</label></small>

                                    )
                                } */}
                            </div>
                            
                        )
                    }
                    </div>
                    </div>
                </div>
            } else if (row.type == "select") {
                return <div key={row.type + '--' + i} className={`mb-3 fb-select form-group field-${row.question_id}>`}>
                    <button className="btn btn-primary btn-sm d-block pull-right mb-1"
                        type="button"
                        onClick={() => { setModal(true); setId(row.question_id); setComment(row.question_comment) }}>
                        <i className="fas fa-comment"></i>
                    </button>
                    <label htmlFor={row.question_id} className={`formbuilder-text-label`}>{row.question}
                        {row.info_text && (
                            <OverlayTrigger
                                key={row.type + '--' + i}
                                placement={'auto'}
                                overlay={
                                    <Tooltip>
                                        {row.info_text}
                                    </Tooltip>
                                }
                            >
                                <i className="fas fa-question-circle pl-3"></i>
                            </OverlayTrigger>
                        )}
                    </label>
                    <input
                        type={'hidden'}
                        name={`question`}
                        defaultValue={row.question_id}

                    />
                    <select
                        className={'form-control select'}
                        name={`question_id[q_${row.question_id}]`}
                        onChange={(e) => {
                            let newArr = [...question];
                            let question_index = newArr[index].findIndex(x => x.is_child == row.question_id);
                            newArr[index][question_index]["p_question_ans"] = e.target.value;

                            console.log(newArr)
                            setQuestion(newArr);

                        }}
                        defaultValue={row.ans}
                    >
                        <option key={"selct" + i} value=''>Select</option>
                        {row.options.map((option, r) => {
                            return <option key={option + '--' + r} value={option}
                            // selected={row.ans == option ? 'selected' : ''}
                            >
                                {option}
                            </option>

                        })}
                    </select>
                </div>
            } else if (row.type == "radio") {
                return <div key={row.type + '--' + i} className={`fb-select mb-3 form-group field-${row.question_id}>`}>
                    <button className="btn btn-primary btn-sm d-block pull-right mb-1"
                        type="button"
                        onClick={() => { setModal(true); setId(row.question_id); setComment(row.question_comment) }}>
                        <i className="fas fa-comment"></i>
                    </button>
                    <label htmlFor={row.question_id} className={`formbuilder-text-label`}>{row.question}
                        {row.info_text && (
                            <OverlayTrigger
                                key={row.type + '--' + i}
                                placement={'auto'}
                                overlay={
                                    <Tooltip>
                                        {row.info_text}
                                    </Tooltip>
                                }
                            >
                                <i className="fas fa-question-circle pl-3"></i>
                            </OverlayTrigger>
                        )}
                    </label>
                    {row.options.map((option, i) => {
                        return <div key={option.value + '--' + i} className={'radio form-check-inline'}>
                            <input
                                defaultChecked={row.ans == option ? 'checked' : ''}
                                // defaultChecked={row.ans}
                                name={`question_id[q_${row.question_id}]`} type="radio"
                                className="form-check-input" value={option} />
                            <label className="form-check-label pl-3">{`${option}`}</label>
                        </div>
                    })}
                </div>
            } else if (row.type == 'paragraph') {
                return <div key={row.type + '--' + i} className="paragraph mt-3 mb-3 text-bold">{row.question}</div>
            } else if (row.type == 'html') {
                return <div key={row.type + '--' + i} className="html "><div dangerouslySetInnerHTML={{ __html: row.question }} /></div>
            } else if (row.type == 'next' && !surveyModal) {
                // props.question.splice(0, i);
                return <button key={row.type + '-next-' + i} type="submit" className="btn btn-primary pull-right mt-1 mb-1" >Next</button>;
            }
            else if (row.type == 'next_module' && !surveyModal) {
                return (
                    <div key={row.type + '-next-m-' + i}>
                        {
                            row.question == 'Finish' ?
                                <button type="button" onClick={() => { CloseSurveyModal(); }} className="btn btn-success ml-5">Finish</button>
                                :
                                <>
                                    <Link to={`/assessment/${bcrypt(props.assessment)}/${row.info_text}`} className="btn btn-dark result-btn">{row.question}</Link>
                                    <button type="button" onClick={() => CloseSurveyModal()} className="btn btn-success ml-5">Finish</button>
                                </>
                        }
                    </div>
                )




            }
            // return <div className="hello">here is this</div>
        })
    }


    const CreateCustomFieldUIAll = () => {
        return allQuestion.length > 0 && allQuestion.map((row, i) => {

            if (row.type == "text" || row.type == "date" || row.type == "number") {
                return <div key={row.type + '--' + i} className={`mb-3 form-group ${row.question_id} ${row.p_question_info != row.p_question_ans && ('d-none')} `}>
                    {/* <button className="btn btn-primary btn-sm d-block pull-right mb-1"
                        type="button"
                        onClick={() => { setModal(true); setId(row.question_id); setComment(row.question_comment) }}>
                        <i className="fas fa-comment"></i>
                    </button> */}
                    <label htmlFor={row.question_id} className={`formbuilder-text-label`} dangerouslySetInnerHTML={{ __html: row.question }}>
                    </label>
                    <input
                        type={'hidden'}
                        name={`question`}
                        defaultValue={row.question_id}

                    />

                    {row.info_text && (
                        <OverlayTrigger
                            key={row.type + '--' + i}
                            placement={'auto'}
                            overlay={
                                <Tooltip>
                                    {row.info_text}
                                </Tooltip>
                            }
                        >
                            <i className="fas fa-question-circle pl-3"></i>
                        </OverlayTrigger>
                    )}
                    {
                        row.front_calculation == 1 ? (
                            <div className="grid-question">
                                <input
                                    type={row.type}
                                    name={`question_id[q_${row.question_id}]`}
                                    defaultValue={row.ans}
                                    className={'form-control'}
                                    onChange={(e) => calculation(e.target.value, row)}
                                    placeholder={'Type your answer here'}
                                />

                                {
                                    row.ans != '' ? (
                                        <small><label id={`question_id${row.question_id}`}> {props.currency}{NumberWithCommas(row.calculate * row.ans)} </label><span>{row.calculate_text}</span></small>

                                    ) : (
                                        <small><label id={`question_id${row.question_id}`}> {props.currency}{NumberWithCommas(row.calculate)} </label><span>{row.calculate_text}</span></small>

                                    )
                                }


                            </div>
                        ) : (
                            <div className="d-flex">
                                <input
                                    key={`question_id[q_${row.question_id}]`}
                                    type={row.type}
                                    name={`question_id[q_${row.question_id}]`}
                                    defaultValue={row.ans}
                                    className={'form-control'}
                                    placeholder={row.type == 'number' ? ' Enter numeric value' : 'Type your answer here'}
                                    onChange={(e) => setProductQuantity(state => ({
                                        ...state,
                                        [`question_id[q_${row.question_id}]`]: e.target.value
                                    }))}
                                />

                                {/* {
                                    row.type == 'number' && (
                                        <small><label id={`question_id${row.question_id}`}> Enter numeric value</label></small>

                                    )
                                } */}
                            </div>
                        )
                    }
                </div>
            } else if (row.type == "select") {
                return <div key={row.type + '--' + i} className={`mb-3 fb-select form-group field-${row.question_id}>`}>
                    <button className="btn btn-primary btn-sm d-block pull-right mb-1"
                        type="button"
                        onClick={() => { setModal(true); setId(row.question_id); setComment(row.question_comment) }}>
                        <i className="fas fa-comment"></i>
                    </button>
                    <label htmlFor={row.question_id} className={`formbuilder-text-label`}>{row.question}
                        {row.info_text && (
                            <OverlayTrigger
                                key={row.type + '--' + i}
                                placement={'auto'}
                                overlay={
                                    <Tooltip>
                                        {row.info_text}
                                    </Tooltip>
                                }
                            >
                                <i className="fas fa-question-circle pl-3"></i>
                            </OverlayTrigger>
                        )}
                    </label>
                    <select
                        className={'form-control select'}
                        name={`question_id[q_${row.question_id}]`}
                        defaultValue={row.ans}
                        onChange={(e) => {
                            let newArr = [...allQuestion];
                            let question_index = newArr.findIndex(x => x.is_child == row.question_id);
                            newArr[question_index]["p_question_ans"] = e.target.value;
                            setAllQuestion(newArr);

                        }}
                    >
                        <option key={"selct" + i} value=''>Select</option>
                        {row.options.map((option, r) => {
                            return <option key={option + '--' + r} value={option}
                            // selected={row.ans == option ? 'selected' : ''}
                            >
                                {option}
                            </option>

                        })}
                    </select>
                </div>

            } else if (row.type == "radio") {
                return <div key={row.type + '--' + i} className={`fb-select mb-3 form-group field-${row.question_id}>`}>
                    <button className="btn btn-primary btn-sm d-block pull-right mb-1"
                        type="button"
                        onClick={() => { setModal(true); setId(row.question_id); setComment(row.question_comment) }}>
                        <i className="fas fa-comment"></i>
                    </button>
                    <label htmlFor={row.question_id} className={`formbuilder-text-label`}>{row.question}
                        {row.info_text && (
                            <OverlayTrigger
                                key={row.type + '--' + i}
                                placement={'auto'}
                                overlay={
                                    <Tooltip>
                                        {row.info_text}
                                    </Tooltip>
                                }
                            >
                                <i className="fas fa-question-circle pl-3"></i>
                            </OverlayTrigger>
                        )}
                    </label>
                    {row.options.map((option, i) => {
                        return <div key={option.value + '--' + i} className={'radio form-check-inline'}>
                            <input
                                defaultChecked={row.ans == option ? 'checked' : ''}
                                // defaultChecked={row.ans}
                                name={`question_id[q_${row.question_id}]`} type="radio"
                                className="form-check-input" value={option} />
                            <label className="form-check-label pl-3">{`${option}`}</label>
                        </div>
                    })}
                </div>
            } else if (row.type == 'paragraph') {
                return <div key={row.type + '--' + i} className="paragraph mt-3 mb-3 text-bold">{row.question}</div>
            } else if (row.type == 'html') {
                return <div key={row.type + '--' + i} className="html "><div dangerouslySetInnerHTML={{ __html: row.question }} /></div>
            }
            // return <div className="hello">here is this</div>
        })
    }

    const calculation = (value, row) => {
        let calculated = 0;
        if (value != '') {
            if (row.operator == 'add') {
                calculated = parseInt(value) + parseInt(row.calculate);
            } else if (row.operator == 'sub') {
                calculated = row.calculate - value;
            } else if (row.operator == 'multiple') {
                calculated = value * row.calculate;
            } else if (row.operator == 'divide') {
                calculated = value / row.calculate;
            }
        }
        setProductQuantity(state => ({
            ...state,
            [`question_id[q_${row.question_id}]`]: value
        }));
        document.getElementById('question_id' + row.question_id).textContent = props.currency + '' + NumberWithCommas(calculated);
    }


    const truncate = (input) => input.length > 5000 ? `${input.substring(0, 5000)}...` : input;

    const CloseSurveyModal = () => {
        setImplementationShow(false);

        setTimeout(() => {
            props.getAssessment();
            setImplementationShow(true);
            setModal1(false);
            setshowAll(false);
        }, 1000);


    }

    // $('input[type=text]').on('keyup', function () {
    //     updateTextView($(this));
    // });

    // function updateTextView(_obj) {
    //     var num = getNumber(_obj.val());
    //     if (num == 0) {
    //         _obj.val('');
    //     } else {
    //         _obj.val(num.toLocaleString());
    //     }
    // }

    // function getNumber(_str) {
    //     var arr = _str.split('');
    //     var out = new Array();
    //     for (var cnt = 0; cnt < arr.length; cnt++) {
    //         if (isNaN(arr[cnt]) == false) {
    //             out.push(arr[cnt]);
    //         }
    //     }
    //     return Number(out.join(''));
    // }

    return (
        <React.Fragment>
            <div className="row">

                <div className="Graph">

                    {/* <div className={`card card-body QuestionWizred ${modal1 ? 'd-none' : ''}`}>
                        <div className="OverLay"></div>


                        <div className="go-result d-none">
                            {
                                props.report && (
                                    <Link to={`/assessment/${bcrypt(props.assessment)}/${props.module}/result`} className="btn btn-dark result-btn">Go to Results</Link>
                                )
                            }
                        </div>
                    </div> */}

                    {
                        implementation_show && (
                            <Implementation
                                slug={props.slug}
                                assessment={props.assessment}
                                module={props.module}
                                name={props.assessment_name}
                                getAssessment={props.getAssessment}
                                canEdit={props.canEdit}
                            />
                        )
                    }

                </div>


                <Modal
                    isOpen={modal1}
                    fade={true}
                    size="lg"
                    className="survey-content-question"
                    backdrop={'static'}
                    centered={true}
                >
                    <div className="card card-body QuestionWizred">
                        <div className="modalQuestion">
                            <div className="d-flex justify-content-between mb-4">
                                <div className="m-title d-flex">
                                    <button className="btn btn-primary mr-2">{props.module_name}</button>
                                    <div className="ml-3">
                                        {/* {
                                            props.module_description != '' && ( */}
                                        <OverlayTrigger
                                            placement={'auto'}
                                            overlay={
                                                <Tooltip>
                                                    {props.module_description}
                                                </Tooltip>
                                            }
                                        >
                                            <img src={bulb} />
                                        </OverlayTrigger>
                                        {/* )
                                        } */}
                                    </div>
                                </div>
                                <div className="m-action">

                                    <button onClick={() => setshowAll(!showAll)} className="btn btn-sm btn-link text-decoration-underline" >{showAll ? 'Default' : 'View All'}</button>
                                    <button onClick={() => CloseSurveyModal()} className="btn btn-sm" ><i className="fas fa-times"></i></button>

                                </div>
                            </div>

                            {/* to={`/assessment/${bcrypt(props.assessment)}/summary`} */}

                            {/* cross-cls */}


                            <Loader loaded={loaded}></Loader>

                            {
                                showAll && (
                                    <form onSubmit={handleSubmit} method="post"
                                        encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                                        {CreateCustomFieldUIAll()}

                                        {
                                            props.canEdit && (
                                                <button type="submut" className="btn btn-success ml-5">Submit</button>
                                            )
                                        }
                                    </form>
                                )
                            }

                            {
                                !showAll && (
                                    <>
                                        {/* {
                                            index >= 1 && Array.from({ length: index }, (_, i) => {
                                                return <div key={i} className="d-flex mb-3">
                                                    <div className="response-area w-100">

                                                        <p className="q-width">{truncate(question[i][0].question)}</p>
                                                        <p  className="q-width">A: {question[i][0].ans}</p>
                                                        {
                                                            question[i][0].question_comment != '' && (
                                                                <p> C: {question[i][0].question_comment} <i title="Update comment" onClick={() => { setSurveyIndex(i); setModal(true); setId(question[i][0].question_id); setComment(question[i][0].question_comment) }} className="fas fa-edit pl-5"></i></p>
                                                            )
                                                        }
                                                    </div>
                                                    <button style={{ height: '27px' }} type="button" className="btn btn-info btn-sm ml-3" onClick={() => { setSurveyIndex(i); setSurveyModal(true) }}> <i className="fas fa-edit"></i></button>

                                                </div>
                                            })
                                        } */}

                                        {
                                            index >= 1 && Array.from({ length: index }, (_, i) => {
                                                return <div key={i} className="d-flex mb-3">
                                                    <div className="response-area">

                                                        <p>{truncate(question[i][0].question)}</p>
                                                        <p>A: {question[i][0].ans}</p>
                                                        {
                                                            props.canEdit && question[i][0].question_comment != '' && (
                                                                <p> C: {question[i][0].question_comment} <i title="Update comment" onClick={() => { setSurveyIndex(i); setModal(true); setId(question[i][0].question_id); setComment(question[i][0].question_comment) }} className="fas fa-edit pl-5"></i></p>
                                                            )
                                                        }
                                                    </div>
                                                    {
                                                        props.canEdit && (
                                                            <button style={{ height: '27px' }} type="button" className="btn btn-info btn-sm ml-3" onClick={() => { setIndex(i); setLocal(true) }}> <i className="fas fa-edit"></i></button>
                                                        )
                                                    }
                                                </div>
                                            })
                                        }


                                        <form onSubmit={handleSubmit} method="post"
                                            encType="multipart/form-data" className="form-horizontal" id="selection-myform">


                                            {CreateCustomFieldUI()}


                                        </form>

                                    </>
                                )
                            }

                        </div>
                    </div>
                </Modal>


                <Modal
                    isOpen={surveyModal}
                    fade={true}
                    size="lg"
                    toggle={() => {
                        setSurveyModal(false); setSurveyIndex(0);
                    }}
                    className="survey"
                >
                    <form onSubmit={handleSubmit} method="post"
                        encType="multipart/form-data" className="form-horizontal" id="selection-myform-2">

                        <div className="note">
                            <div className="modal-head top-heading">
                                <h2> {question.length > 1 && question[surveyIndex] && truncate(question[surveyIndex][0].question)} </h2>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setSurveyModal(false); setSurveyIndex(0);
                                    }}
                                    className="btn btn-link text-white"
                                >
                                    <i className="fas fa-times"> </i>
                                </button>
                            </div>
                            <ModalBody>
                                {CreateSurveyCustomFieldUI()}
                            </ModalBody>
                            <ModalFooter>

                                {question[surveyIndex] && question[surveyIndex].find(x => ['number', 'text', 'date', 'select', 'radio'].includes(x.type)) && (
                                    <Button color="info" type="submit">
                                        Submit
                                    </Button>
                                )}
                                <Button color="danger" onClick={() => { setSurveyModal(false); setSurveyIndex(0); }}>
                                    Cancel
                                </Button>
                            </ModalFooter>
                        </div>

                    </form>
                </Modal>



                <Modal
                    isOpen={modal}
                    fade={true}
                    size="md"
                    toggle={() => {
                        setModal(false);
                    }}
                >
                    <AvForm
                        onValidSubmit={handleCommentSubmit}
                        // model={this.state.record}
                        className="needs-validation"
                    >

                        <div className="note">
                            <div className="modal-head top-heading">
                                <h2> Add Comment </h2>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setModal(false); setId(0);
                                    }}
                                    className="btn btn-link text-white"
                                >
                                    <i className="fas fa-times"> </i>
                                </button>
                            </div>
                            <ModalBody>
                                <div className="mb-3">
                                    <AvField
                                        className="form-control"
                                        type="textarea"
                                        name="comment"
                                        label=""
                                        placeholder="Add a comment"
                                        required
                                        value={comment}
                                    />
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="info" type="submit">
                                    Add Comment
                                </Button>
                                <Button color="danger" onClick={() => { setModal(false); setId(0); }}>
                                    Cancel
                                </Button>
                            </ModalFooter>
                        </div>

                    </AvForm>
                </Modal>

            </div>

        </React.Fragment>
    );
};

export default Survey;
