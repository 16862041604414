import React, { useState, useEffect } from "react"
import $ from "jquery"
import Intro from "./Intro";
import Box1 from "./Box1";
import Context from './Context';
import swal from "sweetalert";
import { ApiUrl, logoLightPng, logoLightSmallPng } from "../../config";
import { dcrypt, uInfo } from "../../useToken";
import Loader from "react-loader";
import Box2 from "./Box2";
import Box3 from "./Box3";
import Box4 from "./Box4";
import Box5 from "./Box5";
import Box6 from "./Box6";
import Box6_1 from "./Box6_1";
import Box6_2 from "./Box6_2";
import Box7 from "./Box7";
import Box7_1 from "./Box7_1";
import Box7_2 from "./Box7_2";

import Box8 from "./Box8";
import Box8_1 from "./Box8_1";
import Box8_2 from "./Box8_2";
import Box8_3 from "./Box8_3";


import Box9 from "./Box9";
import Box9_1 from "./Box9_1";
import Box9_2 from "./Box9_2";


import Box10 from "./Box10";
import { Link, useLocation } from "react-router-dom";
import { Col, Container } from "reactstrap";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const Index = (props) => {
  let query = useQuery();
  const [user_id] = useState(dcrypt(props.match.params.user_id));
  const [loaded, setLoaded] = useState(false);
  const [currentBox, setCurrentBox] = useState(0);
  const [setting, setSetting] = useState({});
  const [metaData, setMetaData] = useState({});
  const [from] = useState(query.get("f") ? query.get("f") : '')

  useEffect(() => {
    getBuilderSetting();
  }, []);

  const getBuilderSetting = () => {
    fetch(`${ApiUrl}` + "get/builder/setting/" + user_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user_id: user_id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setSetting(response.data.record);
          setMetaData(response.data);

          if (response.data.domain_status == 'un-verified' && uInfo().type == 'Coach') {
            setCurrentBox(19);
          }

        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
        setLoaded(true);
      })
      //Then with the error genereted...
      .catch((error) => {
        setLoaded(true);
        console.error("Error:", error);
      });
  };

  const goToBox = (id) => {
    setCurrentBox(id);
  };

  const updateSetting = (key, value) => {
    setLoaded(false);

    setTimeout(() => {
      setSetting((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }, 100);

    console.log(setting, 'sss')

    setLoaded(true);
  }

  const removeWebsiteContent = () => {

    swal({
      title: "Are you sure?",
      text: "All information related to your domain will be removed.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {

          fetch(`${ApiUrl}` + "remove/builder/new/" + user_id, {
            method: "POST",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user_id: user_id }),
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                let newtoken = {
                  domain_status: 0
                };

                const authUser = {
                  ...JSON.parse(localStorage.getItem("authUser")),
                  ...newtoken,
                };
                localStorage.setItem("authUser", JSON.stringify(authUser));
                props.history.push('/settings/profile');
              } else {
                swal({
                  title: data.message,
                  icon: "warning",
                });
              }
            })
            //Then with the error genereted...
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
  }
  const data = {
    currentBox: currentBox,
    user_id: user_id,
    setting: setting,
    goToBox: goToBox,
    getBuilderSetting: getBuilderSetting,
    metaData: metaData,
    updateSetting: updateSetting,
    from: from
  }
  return (
    <Context.Provider value={data}>
      <Loader loaded={loaded}></Loader>
      <div className="px-0 WebsiteBuilderTheme">

        <div className={`nav-bg container-fluid ${from != '' ? 'd-none' : ''}`}>
          <div className="row">
            <div className="col-lg-4"></div>
            <div className="col-lg-4">
              <div className="navbar-brand-box d-flex centerr-logo m-auto text-center justify-content-center">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm pl-2">
                    <img src={logoLightSmallPng} alt="" height="45" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLightPng} alt="" height="45" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="action-btn-builder d-flex align-items-center h-100 justify-content-end">
                {
                  uInfo().type == 'WlAdmin' ?
                    <button className="btn btn-sm btn-primary" onClick={() => props.history.push('/settings/domain')}>Back</button>
                    :
                    <button className="btn btn-sm btn-primary" onClick={() => props.history.push('/settings/profile')}>Back</button>
                }
                <button className="btn btn-sm btn-primary d-none" onClick={() => removeWebsiteContent()}>Reset</button>
                {/* <button className="btn btn-sm btn-primary">Btn 2</button> */}

              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="WizredBox">
        <div id="stage">
          <div id="targets">
            {
              currentBox == 0 && (
                <div className="box box0">
                  <Intro />
                </div>
              )
            }

            {
              currentBox == 1 && (
                <div class="box box1">
                  <Box1 />
                </div>
              )
            }

            {
              currentBox == 2 && (
                <div class="box box2">
                  <Box2 />
                </div>
              )
            }
            {
              currentBox == 3 && (
                <div class="box box3">
                  <Box3 />
                </div>
              )
            }
            {
              currentBox == 4 && (
                <div class="box box4">
                  <Box4 />
                </div>
              )
            }
            {
              currentBox == 5 && (
                <div class="box box5">
                  <Box5 />
                </div>
              )
            }

            {
              currentBox == 6 && (
                <div class="box box6">
                  <Box6 />
                </div>
              )
            }

            {
              currentBox == 7 && (
                <div class="box box6">
                  <Box6_1 />
                </div>
              )
            }

            {
              currentBox == 8 && (
                <div class="box box6">
                  <Box6_2 />
                </div>
              )
            }


            {
              currentBox == 9 && (
                <div class="box box7">
                  <Box7 />
                </div>
              )
            }

            {
              currentBox == 10 && (
                <div class="box box7">
                  <Box7_1 />
                </div>
              )
            }

            {
              currentBox == 11 && (
                <div class="box box7">
                  <Box7_2 />
                </div>
              )
            }

            {
              currentBox == 12 && (
                <div class="box box8">
                  <Box8 />
                </div>
              )
            }
            {
              currentBox == 13 && (
                <div class="box box8">
                  <Box8_1 />
                </div>
              )
            }
            {
              currentBox == 14 && (
                <div class="box box8">
                  <Box8_2 />
                </div>
              )
            }
            {
              currentBox == 15 && (
                <div class="box box8">
                  <Box8_3 />
                </div>
              )
            }

            {
              currentBox == 16 && (
                <div class="box box9">
                  <Box9 />
                </div>
              )
            }

            {
              currentBox == 17 && (
                <div class="box box9">
                  <Box9_1 />
                </div>
              )
            }

            {
              currentBox == 18 && (
                <div class="box box9">
                  <Box9_2 />
                </div>
              )
            }

            {
              currentBox == 19 && (
                <div class="box box10">
                  <Box10 />
                </div>
              )
            }

          </div>
        </div>

        {
          currentBox > 0 && currentBox < 19 && (
            <div className="btn-action-slider">
              <button disabled={currentBox <= 1 ? true : false} onClick={() => goToBox(currentBox - 1)} className="btn btn-primary"><i className="fas fa-angle-up"></i></button>
              <button disabled={currentBox >= 18 ? true : false} onClick={() => goToBox(currentBox + 1)} className="btn btn-primary ml-5"><i className="fas fa-angle-down"></i></button>
            </div>
          )
        }
      </div>
    </Context.Provider >
  );
};

export default Index;