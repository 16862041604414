import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import { isAllowed, uInfo, uRole, uToken } from "../../../useToken";
import { ApiUrl, ProductName } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";

export default class DatatableTables extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        text: "Sr#",
        key: "sr",
        sortable: false,
      },
      {
        text: "Name",
        key: "prefix_name",
        sortable: false,
      },
      // {
      //   text: "Total Manager",
      //   key: "total_manager",
      //   sortable: false,
      // },
      {
        text: "Total lead",
        key: "total_lead",
        sortable: false,
      },
      {
        text: "Created At",
        key: "created_at",
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
      sort: { column: "total_lead", order: "desc" },
      button: {
        excel: false,
        print: true,
        csv: true,
      },
    };
    this.state = {
      records: [],
      total_pages: 0,
      record: {},
      page_name: "Table Setting",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData = (queryString = "") => {
    fetch(`${ApiUrl}` + "info" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
       
        if (data.status === true) {
          this.setState({
            total_pages: data.data.total,
            records: data.data.records,
          });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = async () => {
   
    fetch(`${ApiUrl}` + "add/table", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        console.log(data, "ADD Table");
        if (data.status === true) {
          this.getData();
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
          // return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData("?" + queryString);
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
          
            {this.state.page_name}
            Tables
          </title>
        </Helmet>
        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4>
              
                {this.state.page_name}
                Lists
              </h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  
                  <Link to="#">
                   
                    {this.state.page_name}
                    Lists
                  </Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
             
              {isAllowed(uInfo(), ["can_create"]) && (
                <Button
                  type="button"
                  color="info"
                  onClick={(e) => {
                    if (
                      window.confirm(
                        "Are you sure you want to create new table?"
                      )
                    )
                      this.handleSubmit(e);
                  }}
                >
                  Create
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <ToastContainer />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                
                {/* <CardTitle className="h4">Default Datatable </CardTitle> */}
                <ReactDatatable
                  config={this.config}
                  records={this.state.records}
                  columns={this.columns}
                  dynamic={true}
                  total_record={this.state.total_pages}
                  onChange={this.tableChangeHandler}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
