import React, { useEffect, useState } from "react";
import { toast, ToastContainer, Flip } from "react-toastify";
import swal from 'sweetalert';
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { uToken } from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";

const UiDropdown = (props) => {
  useEffect(() => { });
  const [btnInfo1, setBtnInfo1] = useState(false);
  const unlockPhone = () => {
    swal({
      title: "Are you sure?",
      text: "You want to unlock this phone number.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((isUnlock) => {
        if (isUnlock) {
          fetch(`${ApiUrl}` + "unlock/phone/number", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ phone_id: props.phone_id, phone_number: props.phone_number }),
          })
            .then((response) => response.json())
            .then((response) => {
              swal({
                text: response.message,
                icon: "success",
              });
              //toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      })

  }


  return (
    <React.Fragment>
      {props.is_locked == "Yes" && (
        <Dropdown
          direction="up"
          isOpen={btnInfo1}
          toggle={() => setBtnInfo1(!btnInfo1)}
          className=""
        >
          <DropdownToggle ><i className="fa fa-cog"></i></DropdownToggle>

          <DropdownMenu>
            {/* <DropdownItem>
            <li>
              <a
                onClick={() => {
                  // history.push("view/login/" + bcrypt(phone_id));
                }}
              >
                <i className="fas fa-eye"> </i> View Purchase Log
              </a>
            </li>
          </DropdownItem> */}
            {props.is_locked == "Yes" && (
              <DropdownItem>
                <li>
                  <a
                    onClick={() => {
                      // setAlert(true);
                      unlockPhone()
                    }}
                  >
                    <i className="fas fa-unlock"> </i> Unlock Phone
                  </a>
                </li>
              </DropdownItem>
            )}
          </DropdownMenu>
        </Dropdown>
      )
      }

    </React.Fragment>
  );
};

export default UiDropdown;
