import React from 'react';

const Folder = ({ folder }) => {
  const { name, folders } = folder;
  
  return (
    <div>
      <h3>{name}</h3>
      {folders && folders.map(folder => <Folder key={folder.name} folder={folder} />)}
    </div>
  );
};

export default Folder;