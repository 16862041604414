import React, { Component, Fragment, useState } from "react"
import { Link } from "react-router-dom";
import { ApiUrl, ProductName } from "../../config";
import Context from './Context';
import swal from "sweetalert";
import $ from "jquery";
import { useEffect } from "react";
const Box1 = (props) => {
    const params = React.useContext(Context);
    const [totalFininsh, setTotalFinish] = useState(0);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        fetch(`${ApiUrl}` + "update/builder/setting/" + params.user_id, {
            method: "POST",
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    params.goToBox(params.currentBox + 1);
                    params.getBuilderSetting();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    useEffect(() => {
        $(".image-checkbox").each(function () {
            if ($(this).find('input[type="checkbox"]').first().attr("checked")) {
                $(this).addClass('image-checkbox-checked');
            } else {
                $(this).removeClass('image-checkbox-checked');
            }


        });

        // sync the state to the input
        $(".image-checkbox").on("click", function (e) {
            $(this).toggleClass('image-checkbox-checked');
            var $checkbox = $(this).find('input[type="checkbox"]');
            $checkbox.prop("checked", !$checkbox.prop("checked"))
            let selected = $('.check-fininh-statement').filter(':checked').length;
            if (selected >= 4) {
                $(".image-checkbox input:checkbox:not(:checked)").each(function () {
                    $(this).closest(".image-checkbox").addClass("disabled");
                });
            } else {
                $('.image-checkbox').removeClass('disabled');
            }

            e.preventDefault();
        });


    });



    const finish_statements = [
        {
            'id': 1,
            'title': 'Generate immediate, new sale opportunities',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        },
        {
            'id': 2,
            'title': 'Create competition-crushing marketing',
            'description': ' ',
            'icon': 'theme_4_home4_icon2.png'
        },
        {
            'id': 3,
            'title': 'Develop compelling offers',
            'description': '',
            'icon': 'theme_4_home4_icon3.png'
        },
        {
            'id': 4,
            'title': 'Create sales processes and procedures',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        },
        {
            'id': 5,
            'title': 'Dramatically increase net profits',
            'description': '',
            'icon': 'theme_4_home4_icon2.png'
        },
        {
            'id': 6,
            'title': 'Implement digital marketing campaigns',
            'description': '',
            'icon': 'theme_4_home4_icon3.png'
        },
        {
            'id': 7,
            'title': 'Craft a unique selling proposition',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        },
        {
            'id': 8,
            'title': 'Build a comprehensive business plan',
            'description': ' ',
            'icon': 'theme_4_home4_icon2.png'
        },
        {
            'id': 9,
            'title': 'Reduce wasteful spending in variable and fixed-costs',
            'description': '',
            'icon': 'theme_4_home4_icon3.png'
        },
        {
            'id': 10,
            'title': 'Create unique, engaging content that drives new leads',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        },
        {
            'id': 11,
            'title': 'Implement time management skills',
            'description': '',
            'icon': 'theme_4_home4_icon2.png'
        },
        {
            'id': 12,
            'title': 'Develop clear, concise, and actionable goals',
            'description': '',
            'icon': 'theme_4_home4_icon3.png'
        },
        {
            'id': 13,
            'title': 'Find new strategic partnerships to nurture future growth',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        },
        {
            'id': 14,
            'title': 'Financial resources and advice for scalability',
            'description': ' ',
            'icon': 'theme_4_home4_icon2.png'
        }
    ];


    return (
        <div>
            <div className="box-center-align mt-3">
                <div className="SmallContaner my-4">
                    <div className="d-flex align-items-center gap-2 StepHeading">
                        <div className="StepNumber">4 <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">Copy/Text</h4>
                    </div>
                    <form method="post" onSubmit={handleSubmit}
                        encType="multipart/form-data" className="form-horizontal" id="selection-myform">


                        <div className="border-top border-bottom border-left border-right p-4 mt-3">
                            <div className="page-content-box">
                                <div className="TitleWizred d-flex align-items-center gap-2">
                                    <span className="fs-5"> b.</span> <h5>Finish this statement: As a {ProductName}, I understand how to help business owners..</h5>
                                </div>
                                <div className="WizredForm mt-4">
                                    <div className="form-group">
                                        <label>Select 4 of the following options</label>

                                        {
                                            finish_statements.map((row, i) => {
                                                return (
                                                    <div class="position-relative nopad finishStatement">
                                                        <label class="image-checkbox align-items-center d-flex">
                                                            <h5>{row.title}</h5>
                                                            <input defaultChecked={params.setting.finish_statement?.includes(`${row.id}`) ? true : false} name="finish_statement[]" className="check-fininh-statement" value={`${row.id}`} type="checkbox" />
                                                            <i class="fa fa-check d-none"></i>
                                                        </label>
                                                    </div>
                                                )
                                            })
                                        }


                                        
                                    </div>
                                </div>
                            </div>
                        </div>


                        <button type="submit" className="btn btn-lg btn-primary mt-2"> Next &nbsp;<i className="fas fa-check"></i></button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Box1;