import MetaTags from 'react-meta-tags';
import React, { useState } from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

// Redux
import { Link } from "react-router-dom"
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import { toast, ToastContainer , Flip } from 'react-toastify';


// import images
import Helmet from "react-helmet"
import { ApiUrl, ProductName , logoLightPng} from '../../config';
import useToken from '../../useToken';


const ResetPassword = props => {

  const { setToken } = useToken();
  const [token] = useState(props.match.params.token)
  const [email] = useState(decodeURIComponent(props.match.params.email))

  // handleValidSubmit

  const handleValidSubmit = (event, values) => {
    values.token = token;
    fetch(`${ApiUrl}` + 'updatepassword', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          window.location.href = '/login?msg=Password changed successfully';
          //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

        }

      })
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
      });

    // values.role = 1;
    // values.rights = ['can_view_articles']
    // console.log(values)
    // setToken(values)
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Login </title>
      </Helmet>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <ToastContainer />
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">
                    <Link to="/" className="d-block auth-logo">
                      <img src={logoLightPng} alt="" height="30" className="auth-logo-dark" />
                    </Link>
                  </h3>
                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">Reset Password</h4>
                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          value={email}
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3">
                          <AvField
                            name="password"
                            label="Password"
                            className="form-control"
                            placeholder="Enter Password"
                            minLength={8}
                            errorMessage="Password must be at least 8 characters"
                            required
                            type="password"
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            minLength={8}
                            errorMessage="Confirm password must be at least 8 characters and same as password"
                            name="confirm_password"
                            label="Confirm Password"
                            type="password"
                            className="form-control"
                            placeholder="Enter Confirm Password"
                            validate={{ match: { value: 'password' } }}
                            required
                          />
                        </div>
                      <div className="mb-3 row mt-4">
                        <div className="col-6">
                          <div className="form-check pl-0">
                            {/* <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                            /> */}
                            <Link to="/login" className="text-muted"><i className="mdi mdi-lock"></i>Login</Link>
                          </div>
                        </div>
                        <div className="col-6 text-end">
                          <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Update Password</button>
                        </div>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/* <p>
                  Don&#39;t have an account ?{" "}
                  <Link
                    to="register"
                    className="text-primary"
                  >
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p> */}
                <p>
                  © {new Date().getFullYear()} {ProductName}
                  <span className="d-none d-sm-inline-block"></span>
                  {/* <span className="d-none d-sm-inline-block"> - Created <i className="mdi mdi-heart text-danger"></i> by Zamsol.</span> */}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}


export default ResetPassword;