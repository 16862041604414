import React from "react";
import "./Dialler.css";
import KeypadButton from "./KeypadButton";

const Dialler = ({ number, setNumber, connection, mobile, phone }) => {
  const handleNumberChange = event => {
    setNumber(event.target.value);
  };

  const handleBackSpace = () => {
    setNumber(number.substring(0, number.length - 1));
  };

  const handleNumberPressed = (newNumber) => {
    if (connection && newNumber != '') {
      connection.sendDigits(newNumber);
      return () => {
        setNumber(`${number}`);
      };
    } else {
      return () => {
        setNumber(`${number}${newNumber}`);
      };
    }

  };

  return (
    <>
      <select onChange={handleNumberChange} value={number} className="form-control text-center mb-2" name="to_number">
        {
          phone && (
            <option value={phone}>{phone}</option>
          )
        }

        {
          mobile && (
            <option value={mobile}>{mobile}</option>
          )
        }

        {
          !mobile && !phone && (
            <option value={number}>{number}</option>
          )
        }

      </select>
      {/* <input
        type="tel"
        value={number}
        onChange={handleNumberChange}
        className="form-control input mt-0"
      /> */}
      <ol className="keypad">
        <li>
          <KeypadButton handleClick={() => handleNumberPressed("1")}>1</KeypadButton>
        </li>
        <li>
          <KeypadButton handleClick={() => handleNumberPressed("2")}>2</KeypadButton>
        </li>
        <li>
          <KeypadButton handleClick={() => handleNumberPressed("3")}>3</KeypadButton>
        </li>
        <li>
          <KeypadButton handleClick={() => handleNumberPressed("4")}>4</KeypadButton>
        </li>
        <li>
          <KeypadButton handleClick={() => handleNumberPressed("5")}>5</KeypadButton>
        </li>
        <li>
          <KeypadButton handleClick={() => handleNumberPressed("6")}>6</KeypadButton>
        </li>
        <li>
          <KeypadButton handleClick={() => handleNumberPressed("7")}>7</KeypadButton>
        </li>
        <li>
          <KeypadButton handleClick={() => handleNumberPressed("8")}>8</KeypadButton>
        </li>
        <li>
          <KeypadButton handleClick={() => handleNumberPressed("9")}>9</KeypadButton>
        </li>
        <li>
          <KeypadButton handleClick={() => handleNumberPressed("+")}>+</KeypadButton>
        </li>
        <li>
          <KeypadButton handleClick={() => handleNumberPressed("0")}>0</KeypadButton>
        </li>
        {number.length > 0 && (
          <li>
            <KeypadButton handleClick={handleBackSpace}>&lt;&lt;</KeypadButton>
          </li>
        )}
      </ol>
    </>
  );
};

export default Dialler;
