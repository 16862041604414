import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
    Row,
    Col,
    Container,
    Card,
    CardBody,
    Alert
} from "reactstrap";


import { ApiUrl, logoLightPng, ProductName } from "../../config.js";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Helmet } from "react-helmet";
import { AvField, AvForm } from "availity-reactstrap-validation";
import swal from 'sweetalert';
import { dcrypt } from "../../useToken.js";

//i18n

const VerificationApprovalRequest = (props) => {
    const [vData, setVData] = useState({});
    const [loaded, setLoaded] = useState(true);
    const [msg, setMsg] = useState('');
    const [showCode, setShowCode] = useState(false);

    const sendNotificationToAdmin = (event, values) => {
        setLoaded(false);
        values.user_id = dcrypt( props.match.params.uid);
        values.coach_id = dcrypt( props.match.params.id);

        fetch(`${ApiUrl}` + 'send/account/notification', {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((res) => {
                if (res.status === true) {
                    setMsg(res.message);
                    setShowCode(true);
                    // setLoaded(true);

                } else {
                    swal({
                        title: "Sorry...",
                        text: res.message,
                        button: "Ok",
                        icon: "warning",
                    })
                    // setLoaded(true);
                }

            })
            //Then with the error genereted...
            .catch((error) => {
                setLoaded(true);
                console.error('Error:', error);
            });
    }

    return (

        <React.Fragment>

            <Helmet>
                <title> Verification wizrad </title>
            </Helmet>

            <div className="account-pages my-5 pt-sm-5" >

                <Container >
                    <ToastContainer />

                    <Row className="justify-content-center" >

                        <Col md={8}
                            lg={8}
                            xl={8} >

                            <Card className="overflow-hidden" >
                                <CardBody className="pt-0" >
                                    <h3 className="text-center mt-5 mb-4" >
                                        <Link to="#"
                                            className="d-block auth-logo" >

                                            <img src={logoLightPng}
                                                alt=""
                                                height="45"
                                                className="auth-logo-dark" />
                                        </Link>
                                    </h3>
                                    <Alert color="warning">{dcrypt(props.match.params.reason)}</Alert>
                                    {
                                        msg && msg != '' && (
                                            <Alert color="info">{msg}</Alert>

                                        )
                                    }
                                    <AvForm className="form-horizontal mt-4"
                                        onValidSubmit={
                                            (e, v) => {
                                                sendNotificationToAdmin(e, v)
                                            }
                                        }>
                                        <div className="mt-4">
                                            <AvField
                                                name="subject"
                                                value={'Error Code: 302'}
                                                readOnly
                                                required
                                            />
                                        </div>
                                        <div className="mt-4">
                                            <AvField
                                                name="reason"
                                                type="textarea"
                                                placeholder="Enter note"
                                            />
                                        </div>
                                        <div className="mt-4" >
                                            <div className="col-12 text-end" >
                                                <button disabled={loaded ? false : true} className="btn btn-primary w-md waves-effect waves-light"
                                                    type="submit" > Send
                                                </button>
                                            </div>
                                        </div>
                                    </AvForm>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>

        </React.Fragment>


    );
};

export default VerificationApprovalRequest;
