import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Input,
  TabContent,
  TabPane,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Badge from "react-bootstrap/Badge";
import Breadcrumb from "../../../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";
import ProfileSettings from "../../../Authentication/profile-sidebar";
//Import Action to copy breadcrumb items from local state to redux state
import "react-toastify/dist/ReactToastify.css";
import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import swal from 'sweetalert';
import $ from "jquery";

export default class smstemplate extends Component {
  constructor(props) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    super(props);
    this.state = {
      total_pages: 0,
      tab: "1",
      records: [],
      id: obj.user_id,
      record: {},
      recordrvm: {},
      page_name: this.props.match.params.id ? "Templates" : "Templates",
      customFieldData: { ids: [] },

    };
    this.columns = [
      {
        text: <input type='checkbox' onChange={(e) => {
          if (e.target.checked) {
            $(".data-checkbox").prop("checked", false);
            this.setState((prevState) => ({
              customFieldData: {
                ...prevState.customFieldData,
                ['ids']: [],
              },
            }));
            let x = this.state.records.map(row => row.id.toString());

            this.setState((prevState) => ({
              customFieldData: {
                ...prevState.customFieldData,
                ['ids']: [...prevState.customFieldData['ids'], ...x],
              },
            }));

            $(".data-checkbox").prop("checked", true);
          } else {
            $(".data-checkbox").prop("checked", false);
            this.setState((prevState) => ({
              customFieldData: {
                ...prevState.customFieldData,
                ['ids']: [],
              },
            }));
          }

        }} />,
        key: "id_checkbox",
        cell: (record, index) => {
          return <label> {" "} <input className="data-checkbox" onChange={this.handleChange.bind(this)} type={'checkbox'} name="ids" value={record.id} /></label>;
        },
      },
      {
        text: "Sr#",
        key: "sr",
      },
      {
        text: "Title",
        key: "title",
      },
      // {
      //   text: "Type",
      //   key: "type",
      //   cell: (record) => {
      //     return (
      //       <Fragment>
      //         {record.type == 1 && <Badge bg="success">OutBound</Badge>}
      //         {record.type == 2 && <Badge bg="danger">Inbound</Badge>}
      //       </Fragment>
      //     );
      //   },
      // },
      {
        text: "Created At",
        key: "created_at",
      },

      {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          return (
            <Fragment>
              <button
                type="button"
                color="info"
                className="btn btn-primary btn-sm mb-1 mr-5"
                onClick={() => this.props.history.push("/emailtemplate/edit/" + bcrypt(record.id))}
              >
                <i className="mdi mdi-pencil"></i>
              </button>
              <button
                className="btn btn-danger btn-sm mb-1"
                onClick={this.deleteRecord.bind(this, [record.id], index)}
              >
                <i className="mdi mdi-delete"></i>
              </button>
              {/* )} */}
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
      button: {
        excel: false,
        print: true,
        csv: true,
      },
    };
  }

  handleChange(e) {
    const { name, value, checked } = e.target;
    if (!checked) {
      this.setState((prevState) => ({
        customFieldData: {
          ...prevState.customFieldData,
          ['ids']: this.state.customFieldData['ids'].filter(
            (color) => color !== value
          ),
        },
      }));

      $(e).prop("checked", false);
    } else {
      this.setState((prevState) => ({
        customFieldData: {
          ...prevState.customFieldData,
          ['ids']: [...prevState.customFieldData['ids'], value],
        },
      }));
    }

    console.log(this.state.customFieldData, 'customFieldData')

  }
  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData("?" + queryString, this.state.filter);
  };
  toggle(index) {
    if (index == "1") {
      this.props.history.push("/emailtemplates/list");
    }
    if (index == "2") {
      this.props.history.push("/smstemplates/list");
    }
    if (index == "3") {
      this.props.history.push("/voicetemplates/list");
    }
    if (index == "4") {
      this.props.history.push("/scripttemplates/list");
    }
  }
  componentDidMount() {
    this.getData();
  }

  getData = (queryString = "") => {
    fetch(`${ApiUrl}` + "get/templates/emails" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())

      .then((response) => {
        if (response.status === true) {
          console.log(response, "debugging");
          this.setState({
            total_pages: response.data.total,
            records: response.data.records,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  deleteRecord = (id, index) => {
    if (id.length < 1) {
      swal({
        title: "Empty",
        text: `No record is selected, select a row.`,
        icon: "warning",
      })

      return true;
    }

    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "delete/emailtemplate", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: id }),
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                this.getData();
                //   toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            //Then with the error genereted...
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
  };

  render() {
    const search = this.props.location.search;
    const u = new URLSearchParams(search).get('c_in');

    return (
      <React.Fragment>
        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4>{this.state.page_name}</h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#">{this.state.page_name}</Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              {isAllowed(uInfo(), ["can_create"]) && (
                <Link
                  type="button"
                  color="info"
                  className="btn btn-info"
                  to={"/emailtemplate/add/"}
                // onClick={() => {
                //   this.setState({
                //     modal: !this.state.modal
                //   })
                // }}
                >
                  <i className="mdi mdi-plus"> </i>
                  Create
                </Link>
              )}

              {
                u && u != '' && (
                  <Link
                    type="button"
                    className="btn btn-secondary ml-5"
                    to={`/campaign/inbound/${u}`}
                  >
                    Back
                  </Link>
                )
              }
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="col-lg-12">
            <Card className="mt-2 mb-2">
              <CardBody className="py-0">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={this.state.tab == 1 ? "active" : ""}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      Email Template
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={this.state.tab == 2 ? "active" : ""}
                      onClick={() => {
                        this.toggle("2");
                      }}
                    >
                      SMS Template
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={this.state.tab == 3 ? "active" : ""}
                      onClick={() => {
                        this.toggle("3");
                      }}
                    >
                      Voice Template
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={this.state.tab == 4 ? "active" : ""}
                      onClick={() => {
                        this.toggle("4");
                      }}
                    >
                      Script Template
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardBody>
            </Card>
          </Col>
          <TabContent activeTab={this.state.tab}>
            <TabPane tabId="1">
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody className="pt-0">
                      {

                        this.state.customFieldData.ids.length > 0 && (
                          <div className="text-align-right mt-3 mb-3">
                            <button className="btn btn-danger btn-sm" onClick={() => this.deleteRecord(this.state.customFieldData.ids, 0)}>Delete</button>
                          </div>
                        )
                      }
                      <ReactDatatable
                        config={this.config}
                        records={this.state.records}
                        columns={this.columns}
                        dynamic={true}
                        total_record={this.state.total_pages}
                        onChange={this.tableChangeHandler}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Row>
      </React.Fragment>
    );
  }
}
