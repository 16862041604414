import { uToken } from "../../useToken";
import http from "./http-common";

class UploadFilesService {
    upload(file, userId, folderId, fileName, onUploadProgress) {
        let formData = new FormData();

        formData.append("user_id", userId);
        formData.append("folder_id", folderId);
        formData.append("name", fileName);
        formData.append("file", file);
        formData.append("type", 'file');

        return http.post("/submit/drive/file", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ` + uToken(),
            },
            onUploadProgress,
        });
    }

    getFiles() {
        return http.get("/files");
    }
}

export default new UploadFilesService();