import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumb from "../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import axios from "axios";
import {
  AvForm,
  AvField,
  AvInput,
  AvFeedback,
  AvGroup,
} from "availity-reactstrap-validation";
import {
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uTheme,
  uToken,
} from "../../useToken";
import {
  ApiUrl,
  bgColor,
  ProductName,
  navTextColor,
  navTextHoverColor,
  btnPrimaryBgColor,
  btnPrimaryBgHoverColor,
  btnPrimaryHoverText,
  btnPrimaryTextColor,
  logoLightPng,
  getLocalStorage,
  ToastAutoClose
} from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";

import ProfileSettings from "../Authentication/profile-sidebar";
import * as file from "../../WL/localhost/info";

import preval from "babel-plugin-preval/macro";

// const fileLastModifiedDate = preval.require('../../zuck.js.txt');
// const ProductName1 = preval`
//   const fs = require('fs').promises
//   module.exports = fs.readFile(('../wl/localhost/info_copy.js'),'utf8')`;

export default class UserOperation extends Component {
  constructor(props) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    super(props);
    this.state = {
      id: obj.id,
      record: {},
      page_name: "Theme Settings",
      count: 0,
    };
  }


  changeTheme = (num) => {
    fetch(`${ApiUrl}` + "update/builder/theme", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ select_theme: num }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    //console.log(this.props, "this.state.count");
    return (
      <React.Fragment>
        <Row>
          <Col lg={2} className="pl-0">
            <ProfileSettings menuOpen={false} props={this.props} />
          </Col>
          <Col lg={10}>
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4> {this.state.page_name} </h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> {this.state.page_name} </Link>
                    </li>
                  </ol>
                </div>
              </Col>
            </Row>

            <ToastContainer />
            <Row className="mt-1 wldomain_setting">
              <Col lg={4}>
                <div className="card bg-success text-white">
                  <div className="card-header h4 font-16 mt-0 card-header">
                    Featured
                  </div>
                  <div className="card-body">
                    <div className="h4 card-title">Pick Your Theme</div>
                    <p className="card-text">Theme 1</p>
                    <button
                      onClick={() => this.changeTheme(1)}
                      className="waves-effect waves-light btn btn-secondary text-white bg-secondary"
                    >
                      Pick
                    </button>
                  </div>
                </div>
              </Col>

              <Col lg={4}>
                <div className="card text-white" style={{ background: '#fb6704' }}>
                  <div className="card-header h4 font-16 mt-0 card-header">
                    Featured
                  </div>
                  <div className="card-body">
                    <div className="h4 card-title">Pick Your Theme</div>
                    <p className="card-text">Theme 2</p>
                    <button
                      onClick={() => this.changeTheme(2)}
                      className="waves-effect waves-light btn btn-secondary text-white bg-secondary"
                    >
                      Pick
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
