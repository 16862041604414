import React, { Component, Fragment, useState, useEffect } from "react"
import { Link } from "react-router-dom";
import { ApiUrl } from "../../../config";
import Context from './Context';
import swal from "sweetalert";
import $ from "jquery";
import { dcrypt,bcrypt, uInfo, uToken } from "../../../useToken";

const Box1 = (props) => {
    const params = React.useContext(Context);
    const [totalFininsh, setTotalFinish] = useState(0);
    const [record, setRecord] =  useState({});

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        submitData.append('user_id', uInfo().user_id);
        submitData.append('id', params.form_data.id);

        fetch(`${ApiUrl}` + "update/system/form", {
            method: "POST",
            body: submitData,
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    swal({
                        title: "Action performed successfully",
                        icon: "success",
                    });
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    useEffect(() => {
        // $(".describe-image-checkbox").each(function () {
        //     if ($(this).find('input[type="checkbox"]').first().attr("checked")) {
        //         $(this).addClass('describe-image-checkbox-checked');
        //     } else {
        //         $(this).removeClass('describe-image-checkbox-checked');
        //     }


        // });
        // $(".describe-image-checkbox").on("click", function (e) {
        //     $(this).toggleClass('describe-image-checkbox-checked');
        //     var $checkbox = $(this).find('input[type="checkbox"]');
        //     $checkbox.prop("checked", !$checkbox.prop("checked"))
        //     let selected = $('.check-describe-statement').filter(':checked').length;
        //     if (selected >= 4) {
        //         $(".describe-image-checkbox input:checkbox:not(:checked)").each(function () {
        //             $(this).closest(".describe-image-checkbox").addClass("disabled");
        //         });
        //     } else {
        //         $('.describe-image-checkbox').removeClass('disabled');
        //     }
        //     e.preventDefault();
        // });
    });
    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        fetch(`${ApiUrl}` + "get/systemForm/resourceInfo", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
              },
            body: JSON.stringify({ id: params.form_data.id, user_id: uInfo().user_id}),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setRecord(response.data.record);
                    $(".describe-image-checkbox").each(function () {
                        if ($(this).find('input[type="checkbox"]').first().attr("checked")) {
                            $(this).addClass('describe-image-checkbox-checked');
                        } else {
                            $(this).removeClass('describe-image-checkbox-checked');
                        }
            
            
                    });
                    $(".describe-image-checkbox").on("click", function (e) {
                        $(this).toggleClass('describe-image-checkbox-checked');
                        var $checkbox = $(this).find('input[type="checkbox"]');
                        $checkbox.prop("checked", !$checkbox.prop("checked"))
                        let selected = $('.check-describe-statement').filter(':checked').length;
                        if (selected >= 4) {
                            $(".describe-image-checkbox input:checkbox:not(:checked)").each(function () {
                                $(this).closest(".describe-image-checkbox").addClass("disabled");
                            });
                        } else {
                            $('.describe-image-checkbox').removeClass('disabled');
                        }
                        e.preventDefault();
                    });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
   


    const describe_businesses = [
        {
            'id': 1,
            'title': 'Generate massive profits',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        },
        {
            'id': 2,
            'title': 'Provide accountability to clients',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        },
        {
            'id': 3,
            'title': 'Help business owners attain their goals',
            'description': '',
            'icon': 'theme_4_home4_icon3.png'
        },
        {
            'id': 4,
            'title': 'Implement scheduling freedom for maximum efficiency',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        },
        {
            'id': 5,
            'title': 'Create new business opportunities that do not require marketing budgets',
            'description': '',
            'icon': 'theme_4_home4_icon2.png'
        },
        {
            'id': 6,
            'title': 'Generate positive return on investments for clients',
            'description': '',
            'icon': 'theme_4_home4_icon3.png'
        },
        {
            'id': 7,
            'title': 'Experience and skills necessary to scale businesses',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        },
        {
            'id': 8,
            'title': 'Save thousands of dollars in wasteful spending',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        },
        {
            'id': 9,
            'title': 'Ability to implement marketing strategies designed to generate new leads',
            'description': '',
            'icon': 'theme_4_home4_icon3.png'
        },
        {
            'id': 10,
            'title': 'Financial expertise to grow profits while reducing overhead and wasteful spending',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        },
        {
            'id': 11,
            'title': 'Leverage partnership opportunities to create lasting growth',
            'description': '',
            'icon': 'theme_4_home4_icon2.png'
        },
        {
            'id': 12,
            'title': 'Generate higher conversion rates with specific, actionable strategies',
            'description': '',
            'icon': 'theme_4_home4_icon3.png'
        },
        {
            'id': 13,
            'title': 'Ability to facilitate new policies and procedures for expansion and scale',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        },
        {
            'id': 14,
            'title': 'Technical expertise to leverage CRM systems and increase the lifetime value of customers',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        },
        {
            'id': 15,
            'title': 'Generate new sales opportunities by turning inactive customers into repeat buyers',
            'description': '',
            'icon': 'theme_4_home4_icon3.png'
        },
        {
            'id': 16,
            'title': 'Increase customer value using down-sell, up-sell, and cross-sell strategies',
            'description': '',
            'icon': 'theme_4_home4_icon1.png'
        }
    ];


    return (
        <div>
            <div className="mt-1">
            <div className="d-flex align-items-center gap-2 StepHeading">
                    <div className="StepNumber">{params.form_data.id} <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">{params.form_data.name}</h4>
                </div>
                <form method="post" onSubmit={handleSubmit}
                    encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                   <div className="p-2 mt-2">
                        <div className="page-content-box">
                            <div className="WizredForm mt-1">
                                <div className="form-group">
                                    <label>Select 4 of the following options</label>

                                    {
                                        record.describe_business &&   
                                            describe_businesses.map((row, i) => {
                                                return (
                                                    <div className="position-relative nopad finishStatement" key={`${row.id}`}>
                                                        <label className="describe-image-checkbox align-items-center d-flex">
                                                            <h5>{row.title}</h5>
                                                            <input defaultChecked={record.describe_business?.includes(`${row.id}`) ? true : false} name="describe_business[]" className="check-describe-statement" value={`${row.id}`} type="checkbox" />
                                                            <i className="fa fa-check d-none"></i>
                                                        </label>
                                                    </div>
                                                )
                                            })
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>


                    <button type="submit" className="btn btn-sm btn-primary mt-2"> Ok &nbsp;<i className="fas fa-check"></i></button>
                </form>
            </div>
        </div>
    );
};

export default Box1;