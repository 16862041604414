import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom";
import { ApiUrl } from "../../config";
import Context from './Context';
import swal from "sweetalert";
const Box5 = (props) => {
    const params = React.useContext(Context);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        submitData.append('basic_type', 'social_link');
        fetch(`${ApiUrl}` + "update/builder/setting/" + params.user_id, {
            method: "POST",
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    params.goToBox(params.currentBox + 1);
                    params.getBuilderSetting();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    return (
        <div>
            <div className="box-center-align mt-3">
                <div className="SmallContaner my-4">
                <div className="d-flex align-items-center gap-2 StepHeading">
                        <div className="StepNumber">2 <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">Social accounts</h4>
                    </div>
                    <form method="post" onSubmit={handleSubmit} encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                        <div className="border-top border-bottom border-left border-right p-4 mt-3">
                            <div className="page-content-box">
                                <div className="TitleWizred d-flex align-items-center gap-2">
                                    <span className="fs-5"> a.</span> <h5>Provide links to the social accounts you would like displayed on your new website</h5>
                                </div>
                                <div className="WizredForm mt-4">
                                    <div className="form-group mt-4">
                                        <label>LinkedIn Page</label>
                                        <input name="linkedin_url" defaultValue={params.setting.linkedin_url ?? ''} className="form-control no-border px-0" type="text" placeholder="https://"></input>
                                    </div>

                                    <div className="form-group mt-4">
                                        <label>Facebook Page</label>
                                        <input name="facebook_url" defaultValue={params.setting.facebook_url ?? ''} className="form-control no-border px-0" type="text" placeholder="https://"></input>
                                    </div>


                                    <div className="form-group mt-4">
                                        <label>Instagram Page</label>
                                        <input name="instagram_url" defaultValue={params.setting.instagram_url ?? ''} className="form-control no-border px-0" type="text" placeholder="https://"></input>
                                    </div>

                                    <div className="form-group mt-4">
                                        <label>Twitter Page</label>
                                        <input name="twitter_url" defaultValue={params.setting.twitter_url ?? ''} className="form-control no-border px-0" type="text" placeholder="https://"></input>
                                    </div>

                                    <div className="form-group mt-4">
                                        <label>Google My Business Page</label>
                                        <input name="bussiness_page" defaultValue={params.setting.bussiness_page ?? ''} className="form-control no-border px-0" type="text" placeholder="https://"></input>
                                    </div>

                                    <div className="form-group mt-4">
                                        <label>Other</label>
                                        <input name="other" defaultValue={params.setting.other ?? ''} className="form-control no-border px-0" type="text" placeholder="https://"></input>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-lg btn-primary mt-2"> Next &nbsp;<i className="fas fa-check"></i></button>
                    </form>
                </div>
            </div>
        </div >
    );
};

export default Box5;