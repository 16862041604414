import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
  Tabs,
  Nav,
  NavItem,
  NavLink,
  Table,
  TabContent,
  TabPane,
  CardText,
  Tablist,
  TabPanel,
} from "reactstrap";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import ProfileSettings from "./profile-sidebar";
import { Tab } from "react-bootstrap";
export default class UpgradePlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      packages: [],
      currentPackage: {
        package_data: {},
      },
      page_name: "Upgrade Plan",
      activeTab: "1",
    };
  }

  componentDidMount() {
    this.getData();
  }

  changeSubscription = (package_id, subscription_id) => {
    fetch(`${ApiUrl}` + "user/change/subscription", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        package_id: package_id,
        current_subscription: subscription_id,
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          this.getData();
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getData = () => {
    fetch(`${ApiUrl}` + "user/subscription", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            currentPackage: response.data.current_subscription,
            packages: response.data.packages,
          });
          console.log(this.state.currentPackage, "Testing");
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    let currentDescription = this.state.currentPackage.package_data.description
      ? JSON.parse(this.state.currentPackage.package_data.description)
      : "{}";
    //console.log(currentDescription[0].description_meta,'test');

    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name} </title>
        </Helmet>
        <ToastContainer />
        <Row>
          <Col lg="2" className="pl-0">
            <ProfileSettings menuOpen={false} props={this.props} />
          </Col>
          <Col lg="10">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4> {this.state.page_name} </h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> {this.state.page_name} </Link>
                    </li>
                  </ol>
                </div>
              </Col>
            </Row>

            <Nav tabs>
              <NavItem>
                <NavLink
                  className={this.state.activeTab == 1 ? "active" : ""}
                  onClick={() => this.setState({ activeTab: "1" })}
                >
                  Current Package
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={this.state.activeTab == 2 ? "active" : ""}
                  onClick={() => this.setState({ activeTab: "2" })}
                >
                  Monthly
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.state.activeTab == 3 ? "active" : ""}
                  onClick={() => this.setState({ activeTab: "3" })}
                >
                  Yearly
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent className="p-0" activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <Col md={12}>
                  <div className="card mb-5 mb-lg-0 my-2">
                    <div className="card-body">
                      <h5 className="card-title text-muted text-uppercase text-center">
                        {this.state.currentPackage.package_data.name}
                      </h5>
                      <h6 className="card-price text-center">
                        ${this.state.currentPackage.package_data.price}
                        <span className="period">
                          /
                          {
                            this.state.currentPackage.package_data
                              .package_interval
                          }
                        </span>
                      </h6>
                      <hr />
                      <ul className="fa-ul">
                        {currentDescription.length > 0
                          ? Object.keys(currentDescription).map(function (
                              k,
                              idx
                            ) {
                              return (
                                <li key={idx}>
                                  <span className="fa-li">
                                    <i className="fas fa-check"></i>
                                  </span>
                                  {k} {currentDescription[k].description_meta}
                                </li>
                              );
                            })
                          : ""}
                      </ul>
                      <div className="d-grid">
                        <a href="#" className="btn btn-primary text-uppercase">
                          Current Active Package
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>
              </TabPane>

              <TabPane tabId={"2"}>
                <Row>
                  {this.state.packages.map((row, i) => {
                    if (row.package_interval == "Monthly") {
                      let description = JSON.parse(row.description);

                      return (
                        <Col md={3} key={i}>
                          <div className="card mb-5 mb-lg-0 my-2">
                            <div className="card-body">
                              <h5 className="card-title text-muted text-uppercase text-center">
                                {row.name}
                              </h5>
                              <h6 className="card-price text-center">
                                ${row.price}
                                <span className="period">
                                  /{row.package_interval}
                                </span>
                              </h6>
                              <hr />
                              <ul className="fa-ul">
                                {/* {
                                                                    Object.keys(currentDescription).map(function (k, idx) {
                                                                        return <li key={idx}><span className="fa-li"><i className="fas fa-check"></i></span>{k} {currentDescription[k]}</li>
                                                                    })
                                                                }  */}
                                {description.map((desc, i) => {
                                  return (
                                    <li key={i}>
                                      <span className="fa-li">
                                        <i className="fas fa-check"></i>
                                      </span>
                                      {desc.description_meta}
                                    </li>
                                  );
                                })}
                              </ul>
                              <div className="d-grid">
                                <button
                                  onClick={() =>
                                    this.changeSubscription(
                                      row.package_id,
                                      this.state.currentPackage.subscription_id
                                    )
                                  }
                                  className="btn btn-primary text-uppercase"
                                >
                                  {this.state.currentPackage.price <= row.price
                                    ? "Downgrade"
                                    : "Upgrade"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    }
                  })}
                </Row>
              </TabPane>

              <TabPane tabId={"3"}>
                <Row>
                  {this.state.packages.map((row, i) => {
                    if (row.package_interval == "Yearly") {
                      let description = JSON.parse(row.description);
                      return (
                        <Col md={3} key={i}>
                          <div className="card mb-5 mb-lg-0 my-2">
                            <div className="card-body">
                              <h5 className="card-title text-muted text-uppercase text-center">
                                {row.name}
                              </h5>
                              <h6 className="card-price text-center">
                                ${row.price}
                                <span className="period">
                                  /{row.package_interval}
                                </span>
                              </h6>
                              <hr />
                              {/* <ul className="fa-ul">
                                                                    {
                                                                        Object.keys(description).map(function (k, idx) {
                                                                            return <li key={idx}><span className="fa-li"><i className="fas fa-check"></i></span>{k} {description[k]}</li>
                                                                        })
                                                                    }
                                                                </ul> */}

                              {description.map((desc, i) => {
                                return (
                                  <ul className="fa-ul">
                                    <li key={i}>
                                      <span className="fa-li">
                                        <i className="fas fa-check"></i>
                                      </span>
                                      {desc.description_meta}
                                    </li>
                                  </ul>
                                );
                              })}
                              <div className="d-grid">
                                <button
                                  onClick={() =>
                                    this.changeSubscription(
                                      row.package_id,
                                      this.state.currentPackage.subscription_id
                                    )
                                  }
                                  className="btn btn-primary text-uppercase"
                                >
                                  {this.state.currentPackage.price > row.price
                                    ? "Downgrade"
                                    : "Upgrade"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    }
                  })}
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
