import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
    Container,
} from "reactstrap";
import Helmet from "react-helmet";
import {
    uToken,
} from "../../../useToken";
import { ApiUrl, ProductName } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import TrainingSidebar from "./training-sidebar";
import swal from 'sweetalert';
import Loader from "react-loader";
import ReactPlayer from "react-player";

export default class TrainingCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            records: [],
            roles: [],
            total_pages: 0,
            modal: false,
            record: {},
            filter: {},
            page_name: "Media Library",
            customDate: true,
            loader: true,

        };
    }


    componentDidMount() {
        this.getData();
    }

    getData = () => {
        fetch(`${ApiUrl}` + "get/media", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        records: response.data.records,
                    });
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    handleSubmit = async (event) => {

        this.setState({ loader: false });

        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        fetch(`${ApiUrl}` + "save/media", {
            method: "POST",
            headers: {
                // Accept: "application/json",
                // "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                if (data.status === true) {
                    this.getData();
                    document.getElementById("selection-myform").reset();
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }

                this.setState({ loader: true });

            })
            //Then with the error genereted...
            .catch((error) => {
                this.setState({ loader: true });

                console.error("Error:", error);
            });
    };

    changeHandler = (e) => {
        if (e.target.files[0].type.match('video.*') || e.target.files[0].type.match('image.*')) {
            return true;
        } else {
            toast('Olny video allowed', { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
    };

    render() {
        return (
            <>
                <Helmet>
                    <title>
                        {this.state.page_name}
                        Tables
                    </title>
                </Helmet>
                <Row>
                    <Col lg="2" className="pl-0">
                        <TrainingSidebar menuOpen={false} props={this.props} />
                    </Col>
                    <Col lg="10">
                        <Loader loaded={this.state.loader}></Loader>

                        <Row>
                            <Col sm={6}>
                                <div className="page-title-box">
                                    <h4>{this.state.page_name}</h4>
                                    <ol className="breadcrumb m-0">
                                        <li key={0} className="breadcrumb-item active">
                                            {ProductName}
                                        </li>
                                        <li key={1} className="breadcrumb-item">
                                            <Link to="#">{this.state.page_name}</Link>
                                        </li>
                                    </ol>
                                </div>
                            </Col>
                        </Row>

                        <ToastContainer />

                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody className="pb-0">
                                        <form onSubmit={this.handleSubmit} method="post"
                                            encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                                            <Row>
                                                <Col md="4">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            name="title"
                                                            label="Title"
                                                            className="form-control"
                                                            placeholder="Enter Title"
                                                            required
                                                        />
                                                    </div>
                                                </Col>

                                                <Col md="4">
                                                    <div className="form-group mb-3">
                                                        <input
                                                            name="file"
                                                            label="Select Video"
                                                            accept="video/*,image/*"
                                                            className="form-control"
                                                            type="file"
                                                            required
                                                            onChange={(e) => this.changeHandler(e)}
                                                        />
                                                    </div>
                                                </Col>





                                                <Col md="2">
                                                    <div className="mb-3">
                                                        <div className="button-items">
                                                            <Button
                                                                className="btn-md"
                                                                color="primary"
                                                                type="submit"
                                                                disabled={!this.state.loader}
                                                            >
                                                                Submit
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody className="pt-0">

                                        <div className="row media-video">
                                            {
                                                this.state.records.map((row, i) => {
                                                    return <div className="col-md-3 mb-3">
                                                        <div className="MediaPlayer">

                                                            {
                                                                row.type == 'image' ?
                                                                    <img src={row.url}
                                                                        className="img img-thumbnail"
                                                                    />
                                                                    :
                                                                    <ReactPlayer
                                                                        width="100%"
                                                                        url={row.url}
                                                                        playing={false}
                                                                        onError={(e) => console.log("onError", e)}
                                                                        onStart={() => console.log("onStart")}
                                                                        controls={false}
                                                                    />

                                                            }
                                                        </div>
                                                        <h5 className="pt-2 pb-2">{row.title}
                                                            <i
                                                                title="copy url"
                                                                onClick={() => {
                                                                    navigator.clipboard.writeText(`${row.url}`);
                                                                }}
                                                                className="fas fa-copy pull-right"></i>
                                                        </h5>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row >
            </>
        );
    }
}
