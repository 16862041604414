import React, { useState, useEffect, useRef } from "react";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import {
  Row,
  Table,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  FormGroup,
} from "reactstrap";
import Loader from "react-loader";
import Select from "react-select";

import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
} from "availity-reactstrap-validation";
import { ApiUrl } from "../../config";
import { uToken } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const Priorities = (props) => {
  const [modal, setModal] = useState(false);
  const [index, setindex] = useState("");
  const [id, setId] = useState(props.assessment);
  const [question, setQuestion] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [meetingnumber, setmeetingno] = useState(false);
  const [weeks_no, updateweekno] = useState([]);
  const [assesmentsofusers, setassessments] = useState([]);
  const [salesteamtotal, setsalesteamtotal] = useState(0);
  const [percentage, setpercentage] = useState(0);
  const [total_profit_increase, settotalprofitincrease] = useState(0);
  const [sales_team_amount, setsalesteamamount] = useState("");
  const [noselected, setnoselected] = useState(false);
  const [implementation_date, setimplementationdate] = useState("");
  const [items, setitems] = useState([]);

  const [profit_increase, setprofitincrease] = useState(0);

  const sales_team = [
    "general_questions",
    "sales_manager",
    "compensation",
    "superstars",
    "training",
    "prospecting_and_lists",
    "dream_clients",
    "trade_shows",
    "dealing_with_decision_makers",
    "closing_the_sale",
    "order_fulfillment",
    "buyers_remorse",
  ];

  const meetingnumbers = [
    { label: "1", id: "1" },
    { label: "2", id: "2" },
    { label: "3", id: "3" },
    { label: "4", id: "4" },
    { label: "5", id: "5" },
  ];

  useEffect(() => {
    setLoaded(true);
    getassessments(props.assessment);
  }, []);

  function NumberWithCommas(x) {
    let y = Math.trunc(x);
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function changemeetingno(no) {
    setmeetingno(no);
  }

  function getassessments(id) {
    fetch(`${ApiUrl}` + "get/assessment_implementation", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setassessments(response.data.records);
          setQuestion(response.data.questions);
          setitems(response.data.questions);
          setsalesteamtotal(response.data.total_amount);
          setpercentage(response.data.total_percent);
          settotalprofitincrease(response.data.total_profit_increase);
          setsalesteamamount(parseInt(response.data.sales_team[0]));
          setimplementationdate(response.data.implementation_date[0]);
          if (response.data.meeting_number) {
            setmeetingno(response.data.meeting_number);
            var x = document.getElementById("meet_id");
            x.style.display = "";
            setnoselected(true);
          }
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  
  function handleSubmitAll(event, values) {
    values.assessment_id = id;
    values.items_arr = items;
    fetch(`${ApiUrl}` + "update/assessment_implementation", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {

          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function showmeetigno(e) {
    var x = document.getElementById("meet_id");
    if (e.target.checked == true) {
      x.style.display = "";
    } else {
      x.style.display = "none";
    }
  }
  const onDragEnd = e => {
    if (!e.destination) {
      return;
    }
    const sorted = reorder(items, e.source.index, e.destination.index);
    setitems(sorted);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  return (
    <React.Fragment>
      <h2 className="mb-3">
        {props.module && props.module.replaceAll("_", " ")}
      </h2>
      <Loader loaded={loaded}></Loader>
      <Card>
        <CardBody>
          <ul className="steps-list">
            <li>
              <b>Step 1: </b>Enter the time to implement in Weeks in each
              module. Choose a number of 1 or greater.
            </li>
            <li>
              <b>Step 2: </b>Click the Save Button.
            </li>
            <li>
              <b>Step 3: </b>Click the Prioritize Button.
            </li>
            <li>
              <b>Step 4: </b>Indicate if you want to add an intial planning
              meeting and quarterly reviews to the calendar.
            </li>
          </ul>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleSubmitAll(e, v);
            }}
          >
            <div className="col-md-12 p-1">
              <label>Select implementation starting date</label>

              <AvInput
                name="implementation_date"
                type="date"
                className="form-control"
                value={implementation_date}
              />
            </div>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="Table">
                {provided => (
                  <div className="table-responsive mb-0 col-md-12" data-pattern="priority-columns">
                    <table className="table table-striped table-bordered responsiveTable" {...provided.droppableProps} ref={provided.innerRef}>
                      <thead>
                        <tr>
                          <th>Module Name</th>
                          <th>Expected Increase</th>
                          <th>Revenue Impact</th>
                          <th>Profit Impact</th>
                          <th>Imp. Time (Weeks)</th>
                        </tr>
                      </thead>
                      <tbody>


                        {
                          items.map((row, i) => {
                            return (
                              <Draggable
                                key={row.id}
                                draggableId={row.id}
                                index={i}
                              >
                                {provided => (
                                  <tr
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <td align="center">
                                      {row.type_key &&
                                        row.type_key.replaceAll("_", " ")}
                                    </td>
                                    <td align="center">{row.percentage}% </td>
                                    <td align="center">
                                      {props.currency}
                                      {NumberWithCommas(row.amount)}
                                    </td>
                                    <td align="center">
                                      {props.currency}
                                      {NumberWithCommas(row.profit_increase)}
                                    </td>
                                    <td align="center">
                                      <div className="col-sm-10  p-1">
                                        <AvInput
                                          type="number"
                                          name={row.type_key}
                                          defaultValue={row.imp_weeks}
                                          className="form-control"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            );
                          })
                        }
                      {props.module==3  ? (
                      items.length > 0 ? (
                        <>
                        <tr key="-1">
                        <td align="center">Sales Team</td>
                        <td align="center">{percentage}%</td>
                        <td align="center">
                          {props.currency}
                          {NumberWithCommas(salesteamtotal)}
                        </td>
                        <td align="center">
                          {props.currency}
                          {NumberWithCommas(total_profit_increase)}
                        </td>
                        <td align="center">
                          <div className="col-sm-10  p-1">
                            <AvInput
                              type="number"
                              name="sales_team"
                              value={sales_team_amount}
                              className="form-control"
                            />
                          </div>
                        </td>
                        </tr> 
                        </>
                        ):( 
                          <tr key="-1"><div className="col-12 col-lg-7 col-xl-9 text-center">No Record Found</div></tr>
                        )
                        ):( 
                          ""
                        )
                      }
                      </tbody>
                    </table>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Row>
              <div className="col-md-12">
                <AvGroup check>
                  <AvInput
                    type="checkbox"
                    name="initaite_meeting"
                    onChange={(e) => {
                      showmeetigno(e);
                    }}
                    checked={noselected}
                  />
                  <Label check htmlFor="checkbox" className="mt-1">
                    Add Initial Planning Meeting(s)
                  </Label>
                </AvGroup>
              </div>
            </Row>
            <Row>
              <div className="col-md-12">
                <div id="meet_id" style={{ display: "none" }}>
                  <Select
                    value={meetingnumber}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.id}
                    isMulti={false}
                    options={meetingnumbers}
                    classNamePrefix="select2-selection"
                    name="meeting"
                    onChange={(e) => {
                      changemeetingno(e);
                    }}
                  />
                  <AvInput
                    type="number"
                    name="meeting_number"
                    value={meetingnumber.id}
                    className="form-control"
                    hidden
                  />
                </div>
              </div>
            </Row>
            {/* <Row>
              <div className="col-md-12">
                <AvGroup check>
                  <AvInput type="checkbox" name="quarterly_reviews" />
                  <Label check htmlFor="checkbox">
                    Add Quarterly Reviews
                  </Label>
                </AvGroup>
              </div>
            </Row> */}
            <Row>
              <Col sm={4}></Col>
              <Col sm={8}>
                <div>
                  <button
                    className="btn btn btn-info pull-right mt-3"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </CardBody>
        {/* <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="Table">
                {provided => (
                  <div className="table-responsive mb-0 col-md-12" data-pattern="priority-columns">
                    <Table className="table table-striped table-bordered responsiveTable" {...provided.droppableProps} ref={provided.innerRef}>
                      <thead>
                        <tr>
                          <th>Module Name</th>
                          <th>Expected Increase</th>
                          <th>Revenue Impact</th>
                          <th>Profit Impact</th>
                          <th>Imp. Time (Weeks)</th>
                        </tr>
                      </thead>
                      <tbody>
                  

                        {items.length > 0 ? (
                        items.map((row, i) => {
                          return (
                            <Draggable
                            key={row.imp_id}
                            draggableId={row.imp_id.toString()}
                            index={i}
                            >
                            {provided => (
                                <tr
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                <td align="center">
                                  {row.type_key &&
                                    row.type_key.replaceAll("_", " ")}
                                </td>
                                <td align="center">{row.percentage}% </td>
                                <td align="center">
                                  {props.currency}
                                  {NumberWithCommas(row.amount)}
                                </td>
                                <td align="center">
                                  {props.currency}
                                  {NumberWithCommas(row.profit_increase)}
                                </td>
                                <td align="center">
                                  <div className="col-sm-10  p-1">
                                    <AvInput
                                      type="number"
                                      name={row.type_key}
                                      defaultValue={row.imp_weeks}
                                      className="form-control"
                                    />
                                    <AvInput
                                      type="number"
                                      name={row.type_key+'_index'}
                                      defaultValue={i+1}
                                      className="form-control"
                                      hidden
                                    />

                                  </div>
                                </td>
                              </tr>
                            )}
                            </Draggable>
                          );
                        })
                        ) : (
                          <tr key="1">
                            <td colSpan={4}>
                              <div className="col-md-12 text-center text-center">
                                No record found
                              </div>
                            </td>
                          </tr>
                        )}
                        {provided.placeholder}
                         <tr key={index}>
                          <td align="center">Sales Team</td>
                          <td align="center">{percentage}%</td>
                          <td align="center">
                            {props.currency}
                            {NumberWithCommas(salesteamtotal)}
                          </td>
                          <td align="center">
                            {props.currency}
                            {NumberWithCommas(total_profit_increase)}
                          </td>
                          <td align="center">
                            <div className="col-sm-10  p-1">
                              <AvInput
                                type="number"
                                name="sales_team"
                                value={sales_team_amount}
                                className="form-control"
                              />
                              <AvInput
                                type="number"
                                name={"sales_team_index"}
                                value={0}
                                className="form-control"
                                hidden
                              />
                            </div>
                          </td>
                        </tr> 
                      </tbody>
                    </Table>
                  </div>
                )}
              </Droppable>
            </DragDropContext> */}
      </Card>
    </React.Fragment>
  );
};

export default Priorities;
