import React, { Component } from 'react';
import { Client } from "twilio-chat";
import Avatar from "react-avatar";

class ChatApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoading: true,
            messages: [],
            message: "",
            hostName: props.hostName
        };

        this.user = {
            id: props.username,
            name: props.username
        };

        this.setupChatClient = this.setupChatClient.bind(this);
        this.messagesLoaded = this.messagesLoaded.bind(this);
        this.messageAdded = this.messageAdded.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.handleError = this.handleError.bind(this);

    }

    async componentDidMount() {
        try {
            const client = await Client.create(this.props.token);
            this.setupChatClient(client);
        } catch (error) {
            this.handleError(error);
        }


    }

    handleError(error) {
        console.error(error);
        this.setState({
            error: 'Could not load chat.'
        });
    }

    setupChatClient(client) {
        this.client = client;
        this.client
            .getChannelByUniqueName(this.props.roomName)
            .then(channel => channel)
            .catch(error => {
                return this.client.createChannel({ uniqueName: this.props.roomName });
            })
            .then(channel => {
                this.channel = channel;
                return this.channel.join().catch(() => { });
            })
            .then(() => {
                this.setState({ isLoading: false });
                this.channel.getMessages().then(this.messagesLoaded);
                this.channel.on('messageAdded', this.messageAdded);
            })
            .catch(this.handleError);
    }

    twilioMessageToKendoMessage(message) {
        return {
            text: message.body,
            author: { id: message.author, name: message.author },
            timestamp: message.timestamp
        };
    }

    messagesLoaded(messagePage) {
        this.setState({
            messages: messagePage.items.map(this.twilioMessageToKendoMessage)
        });
    }

    messageAdded(message) {
        this.setState(prevState => ({
            messages: [
                ...prevState.messages,
                this.twilioMessageToKendoMessage(message)
            ]
        }));
    }

    sendMessage(event) {
        let message = this.state.message;
        this.channel.sendMessage(message);
        this.setState({
            message: ''
        })
        // console.log(this.channel, 'this.channel')
    }

    componentWillUnmount() {
        this.client.shutdown();
    }

    render() {
        if (this.state.error) {
            return <p>{this.state.error}</p>;
        } else if (this.state.isLoading) {
            return <p>Loading chat...</p>;
        }
        return (
            <>
                <div className='message p-2'>
                    <ul className='message-list'>
                        {
                            this.state.messages.map((message, i) => {
                                return (
                                    <li key={i} className={message.author.name == this.user.name ? 'me mb-3' : 'other mb-3'}>
                                        <div className='d-flex flex-column justify-content-center'>
                                            <Avatar name={message.author.name} size="30" round={true} />
                                            {/* <small>12:01 AM</small> */}
                                        </div>
                                        <div className="user-message bg-light rounded py-2 px-3 mr-3 ml-3">
                                            {
                                                this.state.hostName == message.author.name ?
                                                    <p>Host</p>
                                                    :
                                                    <p>{message.author.name}</p>

                                            }

                                            {message.text}
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                <div className='send-message'>
                    <div className="form-group mx-0 row">
                        <div className="col-10 p-0">
                            <input value={this.state.message} onChange={(e) => this.setState({ message: e.target.value })} type="text" className="form-control border-radius-0" id="chatMessageInput" placeholder="Type your message here" autocomplete="off" />
                        </div>
                        <button onClick={(e) => this.sendMessage(e)} className="col-2 btn btn-secondary p-0 btn-custom-remote border-radius-0" type="button"><i className="fas fa-paper-plane"></i></button>
                    </div>
                </div>
            </>
        );
    }
}

export default ChatApp;