import React, { useState, useEffect} from "react"
import { Link } from "react-router-dom";
import { ApiUrl, AttachementsUrl } from "../../../config";
import { dcrypt,bcrypt, uInfo, uToken } from "../../../useToken";
import Context from './Context';
import swal from "sweetalert";
const Box6 = (props) => {
    const params = React.useContext(Context);
    const [record, setRecord] =  useState({});

    useEffect(() => {
        getData();
    }, []);
    const getData = () => {
        fetch(`${ApiUrl}` + "get/systemForm/resourceInfo", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
              },
            body: JSON.stringify({ id: params.form_data.id, user_id: uInfo().user_id}),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    //console.log(response.data.record, 'response.data.record')
                    setRecord(response.data.record);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        submitData.append('user_id', uInfo().user_id);
        submitData.append('id', params.form_data.id);
        fetch(`${ApiUrl}` + "update/system/form", {
            method: "POST",
            body: submitData,
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    swal({
                        title: "Action performed successfully",
                        icon: "success",
                    });
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const changeHandlerImg = (e, id) => {
        if (e.target.files[0].type.match('video.*') || e.target.files[0].type.match('image.*')) {
            const preview = document.querySelector('#' + id);
            console.log(preview)
            var reader = new FileReader();
            reader.onload = () => {
                preview.src = reader.result;
            };
            reader.readAsDataURL(e.target.files[0]);
        } else {
            swal({
                title: 'Olny video allowed',
                icon: "warning",
            });
        }
    };
    return (
        <div>
            <div className="mt-1">
                <div className="d-flex align-items-center gap-2 StepHeading">
                    <div className="StepNumber">{params.form_data.id} <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">{params.form_data.name}</h4>
                </div>
                <form method="post" onSubmit={handleSubmit}
                    encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                    <div className="p-1 mt-1">
                        <div className="page-content-box">

                            <div className="WizredForm mt-0">
                                <div className="form-group mt-0">
                                    <div class="parent themeSelection">
                                    <div className="TitleWizred d-flex align-items-center gap-5">
                                        <small>Your website theme will create a foundation for the assets that you are uploading. Choose the layout and structure that you like and we will take care of the rest!</small>
                                    </div>
                                        <div class="row">
                                            {record.theme && (
                                            <>
                                            <div class='col-sm-4'>
                                                <div className=" theme-img justify-content-center">
                                                    <input type="radio" name="theme" defaultChecked={record.theme == 3 ? true : false} id="img3" class="d-none imgbgchk" value="3" />
                                                    <label for="img3">
                                                        <img src={`${AttachementsUrl}/default/website_builder_resources/themes/index3.png`} alt="Image 3" />
                                                        <div class="tick_container">
                                                            <div class="tick"><i class="fa fa-check"></i></div>
                                                        </div>
                                                    </label>
                                                </div>
                                                <a className="d-block text-center mt-2" href={`${AttachementsUrl}/default/website_builder_resources/themes/theme1.png`} target="_blank">Preview</a>

                                            </div>
                                            <div class='col-sm-4'>
                                                <div className=" theme-img justify-content-center">
                                                    <input type="radio" name="theme" defaultChecked={record.theme == 4 ? true : false} id="img4" class="d-none imgbgchk" value="4" />
                                                    <label for="img4">
                                                        <img src={`${AttachementsUrl}/default/website_builder_resources/themes/index4.png`} alt="Image 4" />
                                                        <div class="tick_container">
                                                            <div class="tick"><i class="fa fa-check"></i></div>
                                                        </div>
                                                    </label>
                                                </div>
                                                <a className="d-block text-center mt-2" href={`${AttachementsUrl}/default/website_builder_resources/themes/theme2.png`} target="_blank">Preview</a>

                                            </div>
                                            <div class='col-sm-4'>
                                                <div className=" theme-img justify-content-center">
                                                    <input type="radio" name="theme" defaultChecked={record.theme == 5 ? true : false} id="img5" class="d-none imgbgchk" value="5" />
                                                    <label for="img5">
                                                        <img src={`${AttachementsUrl}/default/website_builder_resources/themes/index5.png`} alt="Image 5" />
                                                        <div class="tick_container">
                                                            <div class="tick"><i class="fa fa-check"></i></div>
                                                        </div>
                                                    </label>
                                                </div>
                                                <a className="d-block text-center mt-2" href={`${AttachementsUrl}/default/website_builder_resources/themes/theme3.png`} target="_blank">Preview</a>

                                            </div>
                                            </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button type="submit" className="btn btn-sm btn-primary mt-2"> Ok &nbsp;<i className="fas fa-check"></i></button>

                </form>
            </div>
        </div >
    );
};

export default Box6;