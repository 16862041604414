import React, { useEffect, useState } from "react"
import { uToken } from "../../useToken";
import { ApiUrl } from "../../config";
import { toast, Flip } from "react-toastify"
import Incoming from "./Incoming";
import { Device } from "twilio-client";

const Incall = props => {
    const [conn, setConn] = useState(false);
    const [device, setDevice] = useState(true);
    const [page2Class, setPage2Class] = useState('');
    const [displayMessage, setdisplayMessage] = useState('');
    const [showSlider, setShowSlider] = useState(false);;
    const [state, setStateVal] = useState("Connecting");
    useEffect(() => {
        handleOutCall();
    }, []);

    const handleOutCall = () => {
        fetch(`${ApiUrl}` + "voice/token", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setConn(true);

                    setPage2Class('slide-in');
                    setShowSlider(true);

                    const device = new Device();
                    device.setup(response.data.token, { debug: false });

                    device.on("ready", () => {
                        setDevice(device);
                        //setStateVal("Ready");
                    });
                    device.on("connect", (connection) => {
                        console.log("Connect event");
                        setConn(connection);
                        setStateVal("ON_CALL");
                        setdisplayMessage("Connected with phone number  " + connection.parameters.From);
                    });
                    device.on("disconnect", () => {
                        //setStateVal("Ready");
                        //setConn(null);
                        console.log("disconnect", 'abc');
                        setdisplayMessage("Incoming call disconnected");
                        setStatusOnReject();
                        device.destroy();
                        setDevice(null);
                    });
                    device.on("incoming", (connection) => {
                        setStateVal("Incoming");
                        setConn(connection);
                        connection.on("reject", () => {
                            //setStateVal("Ready");
                            //setConn(null);
                            console.log("incoming_rejct", 'abc');
                            setdisplayMessage("Incoming call rejected");
                            sliderOut();
                            device.destroy();
                            setDevice(null);
                        });
                    });
                    device.on("cancel", () => {
                        console.log("cancel", 'abc');
                        //setStateVal("Ready");
                        //setConn(null);
                        setdisplayMessage("Incoming call canceled");
                        setStatusOnReject();
                        device.destroy();
                        setDevice(null);
                    });
                    device.on("reject", () => {
                        //setStateVal("Ready");
                        //setConn(null);
                        console.log("reject", 'abc');
                        setdisplayMessage("Incoming call rejected");
                        sliderOut();
                        device.destroy();
                        setDevice(null);
                    });

                    return () => {
                        device.destroy();
                        //setConn(null);
                        setDevice(null);
                        //setStateVal("Offline");
                    };
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const sliderOut = () => {
        setPage2Class('slide-out2');
        setShowSlider(false);
        setConn(null);
        window.location.reload(false);
    }
    const setStatusOnReject = () => {
        setStateVal("ON_CALL");
    }

    console.log(state, 'stata', conn)
    return (
        <>
            {showSlider && (
                <>
                    {conn && (
                        <>
                            {(state === "Incoming" || state === "ON_CALL") && (
                                <div className={`page3 ${page2Class} custom-scroll ${state == 'Incoming' ? 'w-50 height-130' : 'w-100'}`} >
                                    <div className="modal-head top-heading">
                                        <h2>Incoming Call</h2>
                                        <button onClick={() => { sliderOut(); }} className="btn btn-link text-white">
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </div>
                                    {
                                        conn.parameters && (
                                            <Incoming state={state} displayMessage={displayMessage} connection={conn} setStatusOnReject={setStatusOnReject}></Incoming>
                                        )
                                    }
                                </div>
                            )}
                        </>
                    )}
                </>
            )
            }
        </>
    );
}
export default Incall
