import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Container,
} from "reactstrap";
import { Dropdown } from "react-bootstrap";

import Helmet from "react-helmet";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    bcrypt,
    isAllowed,
    uInfo,
    uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import Create from "./create.js";
import Update from "./update.js";
import StatusCreate from "./statusCreate.js";
import StatusUpdate from "./statusUpdate.js";
import swal from 'sweetalert';
const arrowsPlus = require('../../assets/images/arrows_plus.png');


export default class Pipeline extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                text: "Title",
                key: "title",
            },
            {
                text: "Pipeline",
                key: "pipline_id",
                cell: (record, index) => {
                    return (
                        <div className="PipeLineDrop">
                            <div className="PipelineList">
                                <Select
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option.pipeline_id}
                                    isMulti={false}
                                    value={record.pipelines}
                                    options={this.state.pipelines}
                                    classNamePrefix="select2-selection"
                                    onChange={(e) => {
                                        let updatedRows = this.state.records
                                        updatedRows[index].pipelines = e;
                                        this.setState({
                                            records: updatedRows
                                        });
                                        this.statusPipline(e, record.status_id)

                                    }}
                                />
                            </div>
                        </div>
                    );
                },
            },
            {
                text: "Status",
                key: "status",
            },
            {
                key: "action",
                text: "Action",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <button
                                type="button"
                                color="info"
                                className="btn btn-primary btn-sm mb-1 mr-5"
                                onClick={() => { this.setState({ show4Slider: true, page2Class: 'slide-in', sliderShown: 'show4Slider', editId: record.status_id }) }}
                            >
                                Edit
                            </button>
                            <button
                                className="btn btn-danger btn-sm mb-1  mr-5"
                                onClick={this.deleteRecord.bind(this, record, index)}
                            >
                                Delete
                            </button>
                            {/* )} */}
                        </Fragment>
                    );
                }
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: false,
                print: true,
                csv: true,
            },
        };

        
        this.state = {
            records: [],
            roles: [],
            selected_pipeline: this.props.location.search.split('=')[1] ? this.props.location.search.split('=')[1]:0,
            total_pages: 0,
            permissions: [],
            modal: false,
            record: {},
            filter: {},
            page_name: "Pipeline",
            customDate: true,
            statusModal: false,
            page2Class: '',
            editId: '',
            sliderShown: '',
            current: {
                id: "",
                email_status: "",
                call_status: "",
                groups: [],
                tags: [],
            },
            status_id: 0,
            pipelines: [],
            filter: 'All',
            pipeline_id: 0,
            PrevStatus: null,
            masterPiplines: [],
            master_pipeline_id: 0,
            pipeline_category_id: 0,
        };
        this.dragItem = React.createRef();
        this.dragOverItem = React.createRef();

    }


    dragStart = (e, position) => {
        this.dragItem.current = position;
    };

    dragEnter = (e, position) => {
        this.dragOverItem.current = position;
    };

    drop = (e) => {
        const copyListItems = [...this.state.records];
        const dragItemContent = copyListItems[this.dragItem.current];
        copyListItems.splice(this.dragItem.current, 1);
        copyListItems.splice(this.dragOverItem.current, 0, dragItemContent);
        this.dragItem.current = null;
        this.dragOverItem.current = null;
        this.setState({
            records: copyListItems
        });
        this.updateSequence(copyListItems);
    };

    updateSequence = (items_arr) => {
        fetch(`${ApiUrl}` + "update/pipeline/order", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ items_arr: items_arr }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {

                    // toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    statusPipline = (pipeline_id, status_id) => {
        var filter = [];
        if (pipeline_id) {
            filter.push(pipeline_id.pipeline_id)
            // pipeline_id.map(fil => {
            //     filter.push(fil.pipeline_id);
            // })
        }
        fetch(`${ApiUrl}` + "add/status/pipeline", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ pipeline_id: filter.toString(), status_id: status_id }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    this.setState({
                        status_id: 0
                    })

                    this.getData();

                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    deleteRecord = (record, index) => {
        swal({
            title: "Are you sure?",
            text: "You want to remove this record.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "delete/pipeline/status", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                        body: JSON.stringify({ id: record.status_id }),
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((data) => {
                            if (data.status === true) {
                                this.getData("", { 'pipeline_id': this.state.pipeline_id });
                                //    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                            } else {
                                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                            }
                        })
                        //Then with the error genereted...
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    };

    addStatus = (record, index) => {
        this.setState({
            statusModal: true,
            statusModalId: record.pipeline_id
        })
    };

    componentDidMount() {
        this.getMasterPipelie();
        // this.getData();
        // this.getPipline();
    }


    getPipline = (queryString = "", data) => {
        fetch(`${ApiUrl}` + "pipelines" + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {
                    //console.log(response, 'debugging');
                    this.setState({
                        pipelines: response.data.records,
                    });

                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    deletePipelineRecord = (id) => {
        swal({
            title: "Are you sure?",
            text: "You want to remove this record.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "delete/pipeline", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                        body: JSON.stringify({ id: id }),
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((data) => {
                            if (data.status === true) {
                                this.getData(this.state.current.pipeline_id);
                                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                            } else {
                                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                            }
                        })
                        //Then with the error genereted...
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    };

    deleteMasterPipelineRecord = (id) => {
        swal({
            title: "Are you sure?",
            text: "You want to remove this record.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "delete/pipeline", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                        body: JSON.stringify({ id: id }),
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((data) => {
                            if (data.status === true) {
                                this.getMasterPipelie();
                                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                            } else {
                                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                            }
                        })
                        //Then with the error genereted...
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    };

    handlePermission = (selectedPermission) => {

        var filter = [];
        if (selectedPermission) {
            selectedPermission.map(fil => {
                filter.push(fil.user_id);
            })
        }

        this.setState({
            permission: filter.toString(),
            selectedPermission: selectedPermission
        })
    }
    getMasterPipelie = () => {
        fetch(`${ApiUrl}` + "get/master/pipeline", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({selected_pipeline: this.state.selected_pipeline})
        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {
                    this.setState({
                        masterPiplines: response.data.records,
                        master_pipeline_id: response.data.current_pipeline.pipeline_id,
                        filter: response.data.current_pipeline.name,
                        current: response.data.current_pipeline,
                        permissions: response.data.coach_partners,
                    });

                    this.getData(response.data.current_pipeline.pipeline_id)
                    // this.forceUpdate();



                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    getData = (master_id) => {
        fetch(`${ApiUrl}` + "new/pipeline/status/deal", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ 'master_pipeline_id': master_id }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    console.log(response, 'debugging');
                    this.setState({
                        records: response.data.records,
                    });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    cancelSlider = async () => {
        this.getData(this.state.current.pipeline_id);
        this.getPipline("", "");
        this.setState({
            page2Class: 'slide-out2',
            editId: '',
            sliderShown: '',
            pipeline_category_id: 0
            // showSlider: false,
        })
    };

    addMasterPipeline = (event, values) => {
        fetch(`${ApiUrl}` + "add/master/pipeline", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.getMasterPipelie();
                    this.setState({ statusModal: false });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{this.state.page_name}</title>
                </Helmet>

                <Row>
                    <Col sm={5}>
                        <div className="page-title-box">
                            <h4>{this.state.page_name} Lists</h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#">{this.state.page_name} Lists</Link>
                                </li>
                            </ol>
                        </div>
                    </Col>
                    <Col sm={3}>
                        {/* <div className="page-title-box text-align-right">
                            {hasRole(uInfo(), ["Import Leads"]) && (
                                <Link to={"import/lead"} className="btn  btn-success">
                                    Import
                                </Link>
                            )}
                        </div> */}
                    </Col>
                    <Col sm={4}>
                        <div className="page-title-box text-align-right">
                            <button
                                type="button"
                                color="info"
                                className="btn btn-info mr-5"
                                onClick={() => { this.setState({ showSlider: true, page2Class: 'slide-in', sliderShown: 'showSlider' }) }}
                            >
                                <i className="mdi mdi-plus"> </i>
                                Pipeline Category
                            </button>

                            {/* <button
                                type="button"
                                color="info"
                                className="btn btn-info"
                                onClick={() => { this.setState({ show3Slider: true, page2Class: 'slide-in', sliderShown: 'show3Slider' }) }}
                            >
                                <i className="mdi mdi-plus"> </i>
                                Category Status
                            </button> */}
                        </div>
                    </Col>
                </Row>

                <ToastContainer />

                {
                    this.state.show3Slider && this.state.sliderShown == 'show3Slider' && (
                        <div className={`page3 ${this.state.page2Class} custom-scroll`} >
                            <div>
                                <div className="modal-head top-heading">
                                    <h2>Add Category Status</h2>
                                    <button onClick={() => { this.setState({ show3Slider :  false ,page2Class: 'slide-out2' , pipeline_category_id: 0 }); }} className="btn btn-link text-white">
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                                <StatusCreate pipeline_category_id={this.state.pipeline_category_id} masterPipeline={this.state.current} cancelSlider={this.cancelSlider} prev_status_id={this.state.PrevStatus} />
                            </div>
                        </div>
                    )
                }

                {
                    this.state.show4Slider && this.state.sliderShown == 'show4Slider' && (
                        <div className={`page3 ${this.state.page2Class} custom-scroll`} >
                            <div>
                                <div className="modal-head top-heading">
                                    <h2>Edit Category Status</h2>
                                    <button onClick={() => { this.setState({ page2Class: 'slide-out2' }); }} className="btn btn-link text-white">
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                                <StatusUpdate cancelSlider={this.cancelSlider} id={this.state.editId} />
                            </div>
                        </div>
                    )
                }


                {
                    this.state.showSlider && this.state.sliderShown == 'showSlider' && (
                        <div className={`page3 ${this.state.page2Class} custom-scroll`} >
                            <div>
                                <div className="modal-head top-heading">
                                    <h2>Add Pipeline Category ({this.state.current.name})</h2>
                                    <button onClick={() => { this.setState({ page2Class: 'slide-out2' }); }} className="btn btn-link text-white">
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                                <Create masterPipeline={this.state.current} cancelSlider={this.cancelSlider} />
                            </div>
                        </div>
                    )
                }

                {
                    this.state.show2Slider && this.state.sliderShown == 'show2Slider' && (
                        <div className={`page3 ${this.state.page2Class} custom-scroll`} >
                            <div>
                                <div className="modal-head top-heading">
                                    <h2>Edit Pipeline Category</h2>
                                    <button onClick={() => { this.setState({ page2Class: 'slide-out2' }); }} className="btn btn-link text-white">
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                                <Update cancelSlider={this.cancelSlider} id={this.state.editId} />
                            </div>
                        </div>
                    )
                }


                <Row className="piple-status">
                    <Col xl="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md="2">

                                        <Dropdown className="d-inline mx-2">
                                            <Dropdown.Toggle className="btn text-primary" id="dropdown-autoclose-true">
                                                {this.state.filter} <i className="fas fa-angle-down float-end mt-1"></i>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {
                                                    this.state.masterPiplines.map((row, i) => {
                                                        return (
                                                            <Dropdown.Item key={i} eventKey={row.pipeline_id}>
                                                                <span>
                                                                    <i title="Edit info" 
                                                                    onClick={() => 
                                                                    {
                                                                        this.setState({
                                                                        statusModal: true,
                                                                        current: row,
                                                                        selectedPermission: row.permission,
                                                                    });
                                                                    console.log(row, 'permissionsss')
                                                                    }
                                                                    } className="fas fa-edit px-2"></i>
                                                                    <i title="delete" onClick={() => this.deleteMasterPipelineRecord(row.pipeline_id)} className="fas fa-trash-alt px-2 mr-2"></i>

                                                                    {/* <i title="Sort order" onClick={() => this.props.history.push(`/view/pipeline/${bcrypt(row.pipeline_id)}`)} className="fas fa-sort px-2 mr-2"></i> */}

                                                                </span>
                                                                <button onClick={() => {
                                                                    this.getData(row.pipeline_id);
                                                                    this.setState({
                                                                        filter: row.name,
                                                                        master_pipeline_id: row.pipeline_id,
                                                                        current: row,
                                                                        page2Class: 'slide-out2',
                                                                        editId: '',
                                                                        sliderShown: '',
                                                                    })
                                                                }} className="btn btn-link">{row.name}</button>


                                                            </Dropdown.Item>
                                                        )
                                                    })
                                                }
                                                <Dropdown.Item key={'a'} eventKey={'a'}>

                                                    <button onClick={() => {
                                                        this.setState({
                                                            current: {},
                                                            statusModal: true
                                                        })
                                                    }} className="btn btn-link"><span>
                                                            <i className="fas fa-plus px-2 mr-2 text-dark"></i>
                                                        </span>
                                                        Create Pipeline</button>


                                                </Dropdown.Item>

                                            </Dropdown.Menu>
                                        </Dropdown>

                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>




                <Modal
                    isOpen={this.state.statusModal}
                    role="dialog"
                    centered={true}
                    className="exampleModal"
                    tabIndex="-1"
                    toggle={() => {
                        this.setState({
                            statusModal: !this.state.statusModal,
                        });
                        this.getMasterPipelie();
                    }}
                >
                    <div className="modal-content">
                        <ModalHeader
                            toggle={() => {
                                this.setState({
                                    statusModal: !this.state.statusModal,
                                });
                                this.getMasterPipelie();
                            }}
                        >
                            {this.state.current.id ? 'Update' : 'Add'} Pipeline
                        </ModalHeader>
                        <AvForm
                            onValidSubmit={this.addMasterPipeline}
                            className="needs-validation"
                        >
                            <ModalBody>
                                <div className="mb-3">
                                    <AvField
                                        className="select form-control"
                                        name="name"
                                        label="Pipeline name"
                                        required
                                        value={this.state.current.name}
                                    />
                                    {
                                        this.state.current.pipeline_id && (
                                            <AvField type="hidden" name={'pipeline_id'} value={this.state.current.pipeline_id} />
                                        )
                                    }
                                </div>
                                <div className="mb-3">
                                        <label className="control-label">Assign to</label>
                                        <Select
                                            getOptionLabel={option => option.name}
                                            getOptionValue={option => option.user_id}
                                            isMulti={true}
                                            value={this.state.selectedPermission}
                                            options={this.state.permissions}
                                            classNamePrefix="select2-selection"
                                            onChange={(e) => {
                                                this.handlePermission(e)
                                            }}
                                        />
                                        <AvField type="hidden" name="permission" value={this.state.permission} />


                                    </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    type="button"
                                    color="secondary"
                                    onClick={() => {
                                        this.setState({
                                            statusModal: !this.state.statusModal,
                                        });
                                        this.getMasterPipelie();
                                    }}
                                >
                                    Close
                                </Button>
                                <button
                                    className="btn btn-primary waves-effect waves-light"
                                    type="submit"
                                >
                                    Save
                                </button>
                            </ModalFooter>
                        </AvForm>
                    </div>
                </Modal>
                <Container fluid>
                    <Row>
                        {this.state.records.length > 0 ? (
                            this.state.records.map((pipeline, i) => {
                                return <Col lg={6} className="mb-3">
                                    <div className="table-responsive PipeLineStatus" key={i}>
                                        <div className="TableName d-flex justify-content-between" style={{ color: "#000" }}>
                                            <h4><i className="fas fa-circle"></i> {pipeline.name}</h4>
                                            <span className="pull-right pe-1">

                                                <i
                                                    title="Create category status"
                                                    type="button"
                                                    color="info"
                                                    className="fas fa-plus"
                                                    onClick={() => { this.setState({ pipeline_category_id: pipeline.pipeline_id, show3Slider: true, page2Class: 'slide-in', sliderShown: 'show3Slider' }) }}
                                                >
                                                </i>


                                                <i type="button" title="Edit category" onClick={() => this.setState({
                                                    show2Slider: true,
                                                    page2Class: 'slide-in',
                                                    sliderShown: 'show2Slider',
                                                    editId: pipeline.pipeline_id
                                                })} className="fas fa-edit ps-2 pe-1"></i>


                                                <i type="button" title="Delete category" onClick={() => this.deletePipelineRecord(pipeline.pipeline_id)} className="fas fa-trash-alt mr-2"></i>
                                            </span>
                                        </div>
                                        <table className="table table-borderless mb-0">
                                            <tbody>
                                                {
                                                    pipeline.pipelines.map((row, k) => {
                                                        return <tr key={k}>
                                                            <td width={50} style={{ background: row.color_code, color: "#fff" }} scope="row">
                                                                {/* <i className="fas fa-arrow-down"></i> */}
                                                            </td>
                                                            <td width={250}>{row.title}</td>
                                                            <td width={150}>
                                                                <Link className="card-link" to={`/pipeline/status/flow/${bcrypt(row.status_id)}`}><i className="fas fa-cog"></i> Automation settings </Link>
                                                                {/* <Select
                                                                    getOptionLabel={option => option.name}
                                                                    getOptionValue={option => option.pipeline_id}
                                                                    isMulti={false}
                                                                    value={row.pipelines}
                                                                    options={this.state.pipelines}
                                                                    classNamePrefix="select2-selection"
                                                                    onChange={(e) => {
                                                                        this.statusPipline(e, row.status_id)
                                                                    }}
                                                                /> */}
                                                            </td>
                                                            <td width={40} className="text-right px-0">
                                                                <i
                                                                    title="Edit pipeline status"
                                                                    color="info"
                                                                    type="button"
                                                                    className="fas fa-edit  text-dark"
                                                                    onClick={() => { this.setState({ show4Slider: true, page2Class: 'slide-in', sliderShown: 'show4Slider', editId: row.status_id }); }}
                                                                >

                                                                </i>
                                                                <i
                                                                    title="Delete pipeline status"
                                                                    type="button"
                                                                    className="fas fa-trash-alt px-1 text-dark"
                                                                    onClick={this.deleteRecord.bind(this, row, i)}
                                                                >

                                                                </i>
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            }))
                            : (
                                <Card>
                                    <CardBody>
                                        <div className="col-12 col-lg-12 col-xl-12 text-center">No record found in pipeline: {this.state.filter}</div>
                                    </CardBody>
                                </Card>
                            )
                        }
                    </Row>
                </Container>
                <Row className="Pipline justify-content-start d-none">
                    {this.state.records.length > 0 ? (
                        this.state.records.map((row, i) => {
                            return (
                                <div className="col-lg-3 row"
                                // onDragStart={(e) => this.dragStart(e, i)}
                                // onDragEnter={(e) => this.dragEnter(e, i)}
                                // onDragEnd={this.drop}
                                // draggable
                                >
                                    <div className="col-md-11 ju">
                                        <div className="card">
                                            <div className="card-header h4 font-16 mt-0 card-header">
                                                <div className="h4 card-title">
                                                    {
                                                        row.status == 'Active' ? (
                                                            <span title="Pipeline Status" onClick={() => { this.setState({ show4Slider: true, page2Class: 'slide-in', sliderShown: 'show4Slider', editId: row.status_id }) }} className="bg-success badge badge-success mr-5"><i className="fas fa-check"></i>{" "}</span>
                                                        ) : (
                                                            <span title="Pipeline Status" onClick={() => { this.setState({ show4Slider: true, page2Class: 'slide-in', sliderShown: 'show4Slider', editId: row.status_id }) }} className="bg-danger badge badge-danger mr-5"><i className="fas fa-times"></i>{" "}</span>
                                                        )
                                                    }
                                                    <span className="pipelint-title" title={row.title}>
                                                        {row.title}
                                                    </span>

                                                    <span className="pull-right">
                                                        <i
                                                            title="Edit pipeline status"
                                                            color="info"
                                                            type="button"
                                                            className="fas fa-edit"

                                                            onClick={() => { this.setState({ show4Slider: true, page2Class: 'slide-in', sliderShown: 'show4Slider', editId: row.status_id }) }}
                                                        >

                                                        </i>
                                                        <i
                                                            title="Delete pipeline status"
                                                            type="button"
                                                            className="fas fa-trash-alt px-1"
                                                            onClick={this.deleteRecord.bind(this, row, i)}
                                                        >

                                                        </i>
                                                    </span>

                                                </div>
                                                <button className={`btn btn-link btn-sm ${this.state.status_id == row.status_id ? 'd-none' : 'd-block'}`} onClick={() => this.setState({ status_id: row.status_id })}>{row.pipelines.length} Pipeline</button>
                                                <div className={`PipeLineDrop ${this.state.status_id == row.status_id ? 'd-block' : 'd-none'}`}>
                                                    <Select
                                                        getOptionLabel={option => option.name}
                                                        getOptionValue={option => option.pipeline_id}
                                                        isMulti={false}
                                                        value={row.pipelines}
                                                        options={this.state.pipelines}
                                                        classNamePrefix="select2-selection"
                                                        onChange={(e) => {
                                                            let updatedRows = this.state.records
                                                            updatedRows[i].pipelines = e;
                                                            this.setState({
                                                                records: updatedRows
                                                            });
                                                            this.statusPipline(e, row.status_id)

                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="card-body text-center">
                                                <Link className="card-link" to={`/pipeline/status/flow/${bcrypt(row.status_id)}`}><i className="fas fa-cog"></i> Automation settings </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1 justify-content-center mt-5">
                                        <a className=""
                                            onClick={() => { this.setState({ show3Slider: true, page2Class: 'slide-in', sliderShown: 'show3Slider', PrevStatus: row.status_id }) }}>
                                            <img src={arrowsPlus} />
                                        </a>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <Card>
                            <CardBody>
                                <div className="col-12 col-lg-12 col-xl-12 text-center">No record found in pipeline: {this.state.filter}</div>
                            </CardBody>
                        </Card>
                    )}

                </Row>
            </React.Fragment>
        );
    }
}
