import React, { useEffect, useState, useRef } from "react";
import ReactDOM from 'react-dom';
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import Helmet from "react-helmet"
import { Link } from "react-router-dom"
import { toast, ToastContainer, Flip } from "react-toastify";
import { uToken, bcrypt, dcrypt } from "../../useToken";
import SimpleBar from "simplebar-react";
import { logoLightPng, ApiUrl, AttachementsUrl } from "../../config";
import ProposalSignature from "./ProposalSignature";
import ProposalSignatureAccepted from "./ProposalSignatureAccepted";
import { currencies } from "../../common/Common-Currency.js";
const PrivateProposal = props => {
    console.log('  props', props.currency)
    //const query = new URLSearchParams(props.location.search);
    const ref = useRef();
    const [invoice_id] = useState(props.invoice_id);
    const [client_id] = useState(props.client_id ? props.client_id : 0);
    const [page_id, setPageId] = useState(props.page_id);
    const [relational_id] = useState(props.invoice_id ? props.invoice_id : 0);
    const [type] = useState(props.type ? props.type : 'product-proposal');
    const [proposal, setProposal] = useState([]);
    const [user_id, setUserId] = useState(props.user_id ? props.user_id : 0);
    const [accept_proposal_id, setAcceptedProposalId] = useState(0);
    const [proposal_pages, setProposalPages] = useState([]);
    const [page_body, setPageBody] = useState("");
    const [page_index, setPageIndex] = useState("");
    const [currency_code, setCurrency_code] = useState(props.currency ? props.currency : null);
    const [currency, setCurrency] = useState("");
    const [sign_up_step, setsignUpStep] = useState("one");
    const [showLoginLink, setShowLoginLink] = useState("No");
    const [isProposalAccepted, setisProposalAccepted] = useState("No");

    const [proposal_id, setproposalId] = useState(props.proposal_id ? props.proposal_id : null);
    useEffect(() => {
        getProposal();
        getPageBody(page_id);
        getAcceptedProposal();
        signUpStep("one");
        findSymbol(props.currency)
    }, []);

    const getAcceptedProposal = () => {

        fetch(`${ApiUrl}` + "proposal/accepted/data", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ proposal_id: proposal_id, type: type, relational_id: relational_id }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    if (response.data.proposal_accepted.id > 0) {
                        if (response.data.proposal_accepted.status == 'Active') {
                            signUpStep("two");
                        } else {
                            setisProposalAccepted("Yes");
                        }
                        if (response.data.proposal_accepted.user_id > 0) {
                            setShowLoginLink("Yes");
                        }
                    }
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    const getProposal = (data) => {
        console.log('private proposal ')
        //let id = props.match.params.id ? dcrypt(props.match.params.id) : null;
        fetch(`${ApiUrl}` + "proposal/pages/list/" + proposal_id, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ proposal_id: proposal_id, page_id: 0, invoice_id: invoice_id }),

        })

            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setProposal(response.data.propoal_record);
                    setProposalPages(response.data.records);
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    const getPageBody = (new_page_id) => {
        fetch(`${ApiUrl}` + "proposal/page/content", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ proposal_id: proposal_id, page_id: new_page_id, invoice_id: invoice_id }),
        })

            .then((response) => response.json())
            .then((response) => {
                console.log('body ', response.data.record.body)
                if (response.status === true) {
                    setPageBody(response.data.record.body);
                    console.log(response.data.record.sub_type, 'sub_type');
                    setPageIndex(response.data.record.sub_type);
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    const getUserDetails = (user_id, accept_proposal_id) => {
        setUserId(user_id);
        setAcceptedProposalId(accept_proposal_id);
    }
    const signUpStep = (step) => {
        setsignUpStep(step);
        if (step == "two") {
            setisProposalAccepted("Yes");
            props.isProposalAccepted("Yes");
        }
        // if (step == "five") {
        //     getAcceptedProposal();
        //     setShowLoginLink("Yes");
        // }
    }
    const findSymbol = (code) => {
        let currency = currencies[code];
        if (currency) {
            setCurrency(currency.symbol);
        }
    };
    useEffect(() => {
        const placeholderElement = document.getElementById('signup_pkg_form_placeholder');
        console.log(showLoginLink, isProposalAccepted, 'isProposalAccepted');

        if (placeholderElement && showLoginLink === 'No') {
            if (isProposalAccepted !== 'Yes') {
                ReactDOM.render(<ProposalSignature
                    package_id={proposal.package_id}
                    proposal_id={proposal.proposal_id}
                    relational_id={relational_id}
                    user_id={user_id}
                    proposal_type={proposal.type}
                    signUpStep={signUpStep}
                    isSecureRequuest="Yes"
                    type={'product-proposal'}
                />, placeholderElement);
            } else {
                ReactDOM.render(
                    <div id="FormID2">
                        <ProposalSignatureAccepted
                            package_id={proposal.package_id}
                            proposal_id={proposal.proposal_id}
                            relational_id={relational_id}
                            type={'product-proposal'}
                        />
                    </div>,
                    placeholderElement
                );
            }
        }

        return () => {
            // Cleanup function to remove the rendered component if necessary
            if (placeholderElement && placeholderElement.parentNode) {
                ReactDOM.unmountComponentAtNode(placeholderElement);
            }
        };
    }, [showLoginLink, isProposalAccepted, proposal.package_id, proposal.proposal_id, signUpStep]);

    const replaceTokenWithComponent = (content) => {
        const updatedContent = content.replace(
            '{signup_pkg_form}',
            '<div id="signup_pkg_form_placeholder"></div>'
        );

        return <div dangerouslySetInnerHTML={{ __html: updatedContent }} />;
    };

    return (
        <React.Fragment>
            {/* {proposal && proposal.type != 'Coaching' && (
                <Helmet>
                    <title> {proposal.name ? proposal.name : " Propoal"} </title>
                    <link rel="stylesheet" href={`${AttachementsUrl}default/proposals-resources/all.css`} />
                    <link rel="stylesheet" href={`${AttachementsUrl}default/proposals-resources/responsive.css`} />
                </Helmet>
            )} */}

            <Row>

                <Col lg="1" className="pl-0"></Col>
                <Col lg="10">
                    <CardBody className="pt-0" style={{ background: "white" }}>

                        {
                            proposal_pages.map((row, i) => {
                                let replace = replaceTokenWithComponent(row.body);
                                return <div key={i} >{replace}</div>
                            })
                        }




                        {(page_index == 'next_steps') && showLoginLink == 'Yes' && (
                            <div className="p-3" >
                                <h5 className="text-center mb-2" >
                                    <Link to="/" style={{ color: "#1e87f0" }}>
                                        Cick here to login now
                                    </Link>
                                </h5>
                            </div>
                        )}
                    </CardBody>
                </Col>
                <Col lg="1" className="pl-0"></Col>

            </Row>
        </React.Fragment>
    )
}

export default PrivateProposal