import React, { useState, useEffect, useRef } from "react";
import {
    Row, Col, Card, CardBody, CardTitle, Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Button,
    Alert, ModalBody,
    ModalFooter,
    Modal,
} from "reactstrap"
import Helmet from "react-helmet"
import { ApiUrl, MeetingUrl, ProductName } from "../../config";
import { Link, useLocation, useParams } from "react-router-dom";
import { uInfo, uToken, dcrypt, bcrypt } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import * as SignaturePad from 'signature_pad';


const ClientForm = (props) => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const search = props.location.search;
    const [modal, setModal] = useState(false);
    const [remaingTime, setRemaingTime] = useState(0);
    // const query = queryString.parse(search);
    const u = new URLSearchParams(search).get('u');
    const c = new URLSearchParams(search).get('c');
    const m = new URLSearchParams(search).get('meta');

    useEffect(() => {
        getData();
    }, []);


    const getData = () => {
        fetch(`${ApiUrl}` + "view/meeting", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: dcrypt(u), meeting_id: dcrypt(m), client_id: c }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setData(response.data.records);
                    setRemaingTime(response.data.remaingTime);
                    setLoading(false);
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const confirmSchedule = () => {
        fetch(`${ApiUrl}` + "confirm/meeting", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ meeting_id: dcrypt(m), client_id: dcrypt(u) }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    window.location.reload(false);
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }




    return (
        <>
            {
                !loading && (
                    <div className="row">
                        <div className="col-lg-12">
                            <Button
                            className="float-end"
                                type="button"
                                color="secondary" 
                                onClick={() => props.history.push('/client/profile')}
                            >
                                Back
                            </Button>
                            
                        </div>
                        <div className="col-lg-4 offset-lg-4 mt-5">
                            <Card className="meeting-detail">
                                <CardBody>
                                    <div className="meeting">

                                        {
                                            data.type == 'schedule' ? (

                                                <>
                                                    {
                                                        data.status == 'confirm' ? (
                                                            <> </>
                                                            // uInfo().type == 'Coach' && remaingTime == 1 ? (
                                                            //     <div className="ask-btns mt-3">
                                                            //         <a target={'_blank'} href={`${MeetingUrl}/host/${bcrypt(data.meeting_id)}`} className="btn btn-outline-secondary"> <i className="mdi mdi-arrow-up-bold-box-outline"></i> Start</a>
                                                            //     </div>
                                                            // )
                                                            //     : uInfo().type == 'Client' && remaingTime == 1 ? (
                                                            //         <div className="ask-btns mt-3">
                                                            //             <a target={'_blank'} href={`${MeetingUrl}/remote/${bcrypt(data.meeting_id)}?r=${data.coach_id}`} className="btn btn-primary">Ask to Join</a>
                                                            //         </div>
                                                            //     )

                                                            //         : <>
                                                            //             <div className="ask-btns mt-3">
                                                            //                 <button disabled={true} className="btn btn-primary">Meeting not start yet </button>
                                                            //             </div>
                                                            //         </>
                                                        ) : (
                                                            <>
                                                                <Alert color="danger">Events/Appointment is not confirmed yet</Alert>

                                                                {
                                                                    uInfo().type == 'Client' && (
                                                                        <div className="ask-btns mt-3">
                                                                            <button onClick={() => setModal(true)} className="btn btn-primary"> Click to confirm</button>
                                                                        </div>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </>



                                            ) : (
                                                <></>

                                                // uInfo().type == 'Coach' && data.type == 'auto' && remaingTime == 1 ? (
                                                //     <div className="ask-btns mt-3">
                                                //         <a target={'_blank'} href={`${MeetingUrl}/host/${bcrypt(data.meeting_id)}`} className="btn btn-outline-secondary"> <i className="mdi mdi-arrow-up-bold-box-outline"></i> Start</a>
                                                //     </div>
                                                // )
                                                //     : uInfo().type == 'Client' && data.type == 'auto' && remaingTime == 1 ? (
                                                //         <div className="ask-btns mt-3">
                                                //             <a target={'_blank'} href={`${MeetingUrl}/remote/${bcrypt(data.meeting_id)}?r=${bcrypt(data.coach_id)}`} className="btn btn-primary">Ask to Join</a>

                                                //         </div>
                                                //     )

                                                //         : <>
                                                //             <div className="ask-btns mt-3">
                                                //                 <button disabled={true} className="btn btn-primary">Meeting not start yet </button>
                                                //             </div>
                                                //         </>
                                            )



                                        }

                                        <Modal
                                            isOpen={modal}
                                            fade={false}
                                            toggle={() => setModal(false)}
                                        >
                                            <ModalBody>
                                                <div className="warning-box text-center">
                                                    <span className="warning">
                                                        <i className="mdi mdi-alert-circle"> </i>
                                                    </span>
                                                    <h3> Are you sure ? </h3>
                                                </div>
                                            </ModalBody>
                                            <ModalFooter>
                                                <Button color="info" onClick={() => confirmSchedule()}>
                                                    Yes, Change it
                                                </Button>
                                                <Button color="danger" onClick={() => setModal(false)}>
                                                    Cancel
                                                </Button>
                                            </ModalFooter>
                                        </Modal>
                                        <h4 className="mt-4">Events/Appointment Details</h4>
                                        <ul className="user-details pt-3">
                                            <li><label> Title:</label> {data.event_title}</li>
                                            <li><label>Date:</label> {data.type == 'schedule' ? data.server_date + ' ' + data.from_time : data.created_at} </li>
                                            <li><label>Status:</label> {data.status}</li>
                                        </ul>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>

                    </div>
                )
            }
        </>
    )

}

export default ClientForm;
