import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import {
  currentDateTime,
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../useToken";
import { ApiUrl, ProductName,ToastAutoClose} from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
// import '../../../node_modules/bootstrap/js/dist/tooltip';
import Select from "react-select";
import countries from "../../common/data/countries";

export default class CreateUpdate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      page_name: this.props.match.params.id
        ? "Edit Caller Group"
        : "Create Caller Group",
      currentuser: JSON.parse(localStorage.getItem("authUser")),
      record: {},
      coachPartners: [],
      coachdata:[],
    };
  }

  componentDidMount() {
    this.state.id != null && this.getData();
    this.getcoachpartners();
  }

  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/callergroup", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },

      body: JSON.stringify({
        id: this.state.id,
        coach_id: this.state.coach_id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            record: response.data.record,
            coachdata:response.data.record.members,
          });

         
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getcoachpartners = () => {
    fetch(`${ApiUrl}` + "coachpartners", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            coachPartners: response.data.records,
          });

          this.setState({
            coachPartners: [
              ...this.state.coachPartners,
              this.state.currentuser,
            ],
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = async (event, values) => {
    if (this.state.id) {
        values.id = this.state.record.id ? this.state.record.id : -1;
    }
    fetch(`${ApiUrl}` + "add/callergroup", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }
      //                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });


            setTimeout(() => this.props.history.push("/callergroup/list"), 1000);
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name} </title>
        </Helmet>
        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.page_name} </h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#"> {this.state.page_name} </Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                type="button"
                color="secondary"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
        <ToastContainer />

        <Row>
          <Col className="col-12">
            <Card>
              <AvForm
                onValidSubmit={this.handleSubmit}
                // model={this.state.record}
                ref={(c) => (this.form = c)}
                className="needs-validation"
              >
                <ModalBody className="row">
                  <div className="col-md-12 mb-3">
                    <AvField
                      name="name"
                      value={this.state.record.name}
                      label="Name"
                      className="form-control"
                      required
                    />
                  </div>
                  {this.state.coachPartners.slice(0).reverse().map((row) => {
                    return (
                      <Row>
                        <Col sm={4}>
                          <div className="mb-3">
                            <h6 className="mt-2">{row.name}</h6>
                          </div>
                        </Col>
                        {this.state.coachdata.map((data) => {
                            
                              if(data.user_id==row.user_id){
                              return (
                                <Col sm={4}>
                                <div className="mb-3">
                              
                                  <AvField
                                    className="select form-control"
                                    type="select"
                                    name={"data_" + row.user_id}
                                    label=""
                                    value={data.value}
                                    required
                               
                                    
                                  >
                                    <option key="1" value="1" selected>
                                      None
                                    </option>
      
                                    <option key="2" value="2">
                                      Ring Web Browser Only
                                    </option>
                                    <option key="3" value="3">
                                      Ring Phone Only
                                    </option>
                                    <option key="4" value="4">
                                      Ring Web Browser and forward number
                                      Simultaneously
                                    </option>
                                  </AvField>
                                </div>
                              </Col>

                              );

                              }

                            })}
                       
                      </Row>
                    );
                  })}
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => this.props.history.goBack()}
                  >
                    Cancel
                  </Button>

                  <button
                    className="btn btn-primary waves-effect waves-light"
                    type="submit"
                  >
                    Submit
                  </button>
                </ModalFooter>
              </AvForm>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
