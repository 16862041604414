import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"

//Import images
import avatar3 from "../../../assets/images/users/user-3.jpg"
import avatar4 from "../../../assets/images/users/user-4.jpg"

//i18n
import { withTranslation } from "react-i18next"
import { ApiUrl } from "../../../config"
import { uToken } from "../../../useToken"
import { toast } from "react-toastify"
import Avatar from "react-avatar";

const OnlineClientDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [records, setRecords] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    getData();
  }, [!records]);

  const getData = () => {
    fetch(`${ApiUrl}` + "get/online/client", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setRecords(response.data.records)
          setTotalCount(response.data.records.length);
        } else {
          toast.error(response.message, { theme: "colored" });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block ms-1 SystemNotification"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
          title="Total Online Clients"
        >
          <i className="ti-user"></i>
          <span className="badge bg-danger rounded-pill">{totalCount}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h5 className="m-0"> {props.t("Online Clients")}</h5>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ maxHeight: "160px" }}>
            {
              records.map((row, i) => {
                return (
                  <Link title={row.name} to={`/chat/${row.user_id}/client`} key={i} className="text-reset notification-item">
                    <div className="media onlineClient">
                      <Avatar
                        name={`${row.name}`}
                        size="30"
                        round={true}
                      />
                      <div className="media-body pl-3">
                        <h6 className="mt-0 mb-1">{row.name}</h6>
                        <div className="text-muted">
                          <p className="mb-1">{row.email}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                )
              })
            }


          </SimpleBar>
          {/* <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="#"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>
              {" "}
              {props.t("View all")}{" "}
            </Link>
          </div> */}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(OnlineClientDropdown)

OnlineClientDropdown.propTypes = {
  t: PropTypes.any
}