import React, { useEffect, useState } from "react"
import CampaignDialer from "./dialer.js";

const Incoming = ({ connection, state, displayMessage, setStatusOnReject }) => {
  const [showPage, setShowPage] = useState(false);
  const [muted, setMuted] = useState(false);
  const [hangupCall, setHangupCall] = useState(false);

  const acceptConnection = () => {
    connection.accept();
    setShowPage(true);

  };
  const rejectConnection = () => {
    connection.reject();
    setStatusOnReject();
  };

  const cancelConnection = () => {
    connection.disconnect();
    setHangupCall(true);
    setStatusOnReject();
  };
  const handleMute = () => {
    connection.mute(!muted);
    setMuted(!muted);

  };

  let params = Object.fromEntries(new URLSearchParams(connection.parameters.Params));
  params.From = connection.parameters.From;

  return (
    <div className="incoming-twilio-call">
      <>
        {state != "ON_CALL" ? (
          <div className="d-flex">
            <h4 className="incomint-notify"> <i className="fas fa-phone"></i> Incoming call From  {connection.parameters.From}</h4>
            <div className="accept-rejact mb-5">
              <button className="accept" onClick={() => acceptConnection()} color="green"><i className="fas fa-phone"></i></button>
              <button className="rejact" onClick={rejectConnection} color="red"><i className="fas fa-phone"></i></button>
            </div>

          </div>
        ) : (
          <div className="d-flex mb-5">
            <h4 className="incomint-notify"> <i className="fas fa-phone"></i> {displayMessage}</h4>
            {
              !hangupCall && (
                <div className="accept-rejact mb-5">
                  {/* <button className="accept" onClick={() => acceptConnection()} color="green"><i className="fas fa-phone"></i></button> */}
                  <button className="rejact btn-sm btn btn-danger rounded-circle" onClick={cancelConnection} color="red"><i className="fas fa-phone"></i></button>

                  {
                    muted && (
                      <button className="rejact btn-sm unmute btn green rounded-circle" onClick={handleMute} color="red"><i class="fas fa-microphone-slash"></i></button>
                    )
                  }

                  {
                    !muted && (
                      <button className="rejact btn-sm mute btn red rounded-circle" onClick={handleMute} color="red"><i class="fas fa-microphone"></i></button>
                    )
                  }

                </div>
              )
            }
          </div>
        )}
      </>

      {
        showPage && params.coach_id && state === "ON_CALL" && (
          <div className="incoming">
            <CampaignDialer dialerInfo={params} CallSid={connection.parameters.CallSid} />
          </div>
        )
      }


    </div>
  );
};

export default Incoming;
