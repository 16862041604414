import React, { Component, Fragment } from "react";
import FileViewer from "react-file-viewer";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  CardImg,
  CardText,
  ModalHeader,
  Nav,
  CardImgOverlay,
  NavItem,
  NavLink,
  Input,
  TabContent,
  TabPane,
  ModalBody,
  ModalFooter,
  Collapse,
} from "reactstrap";
import Badge from "react-bootstrap/Badge";
import Breadcrumb from "../../../../components/Common/Breadcrumb";
import SectionAccordion from "./SectionAccordion";
import ProductFaqs from "./ProductFaqs";
import ProfileSettings from "../../../Authentication/profile-sidebar";
//Import Action to copy breadcrumb items from local state to redux state
import "react-toastify/dist/ReactToastify.css";
import TrainingProductsSidebar from "./trainingproducts-sidebar";
import ReactPlayer from "react-player";
import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  dcrypt,
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../../useToken";
import { ApiUrl, ProductName, AttachementsUrl } from "../../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";

export default class smstemplate extends Component {
  constructor(props) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    super(props);

    this.state = {
      total_pages: 0,
      records: [],
      id: obj.user_id,
      record: {},
      recordrvm: {},
      page_name: this.props.match.params.id ? "Content" : "Content",
      product_data: [],
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      content_url: "",
      playing: true,
      volumne: "1",
      content_id: this.props.match.params.cont_id
        ? dcrypt(this.props.match.params.cont_id)
        : "1",
      product_sections: [],
      params: props,
      controls: true,
      pip: false,

      light: false,
      volume: 0.8,
      muted: false,
      played: 0,
      loaded: 0,
      duration: 0,
      playbackRate: 1.0,
      loop: false,
      recordall: [],
      product_faqs: [],
      records: [],
      type: "",
      playing: true,
      volumne: "1",
      numPages: "",
    };
  }

  componentDidMount() {
    this.getcontentdata(this.state.content_id);
  }
  onDocumentLoadSuccess = (numPages) => {
    this.setState({ numPages: numPages });
  };
  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing });
  };
  componentDidUpdate(prevProps) {
    if (this.props.match.params.cont_id !== prevProps.match.params.cont_id) {
      this.setState({
        content_id: dcrypt(this.props.match.params.cont_id),
      });
      this.getcontentdata(this.props.match.params.cont_id);
    }

  }
  getcontentdata = (contentid) => {
    fetch(`${ApiUrl}` + "get/resourcecontentdata/" + contentid, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {

        if (response.status === true) {
          this.setState({
            records: response.data.records,
            content_url: response.data.resourceContenturl,
          });
          if (response.data.records.file_type == "link") {
            this.setState({
              type: "link",
            });
            window.open(this.state.records.file_name, "_blank");
          }
          if (response.data.records.file_type == "application/pdf") {
            this.setState({
              type: "pdf",
            });
            //window.open(this.state.content_url, "_blank");
          }
          if (
            response.data.records.file_type == "image/png" ||
            response.data.records.file_type == "image/jpeg"
          ) {
            this.setState({
              type: "png",
            });
            //window.open(this.state.content_url, "_blank");
          }
          if (
            response.data.records.file_type ==
            "application/vnd.openxmlformats-officedocument.word"
          ) {
            this.setState({
              type: "docx",
            });
          }
          if (
            response.data.records.file_type.match('video.*')
          ) {
            this.setState({
              type: "mp4",
            });
          }
          if (
            response.data.records.file_type ==
            "application/vnd.openxmlformats-officedocument.presentationml.presentation"
          ) {
            this.setState({
              type: "pptx",
            });
          }


        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col lg="12">
            <Row>
              <Col className="col-12">
                <Card className="NoShadow HeightAuto">
                  <div className="page-title-box text-align-right pb-1">
                    <Button
                      type="button"
                      color="secondary"
                      onClick={() => this.props.history.goBack()}
                    >
                      Back
                    </Button>
                  </div>
                  <Row className="mx-0">
                    <Col xl={12}>
                      <Card
                        className="NoShadow traning  VideoHeight"
                        style={{ borderRight: 0 }}
                      >
                        <CardBody className="px-0">
                          {this.state.type == "mp4" && (
                            <Row>
                              <Col xl="3">
                              </Col>
                              <Col xl="6">
                                <ReactPlayer
                                  url={this.state.content_url}
                                  playing={this.state.playing}
                                  onError={(e) => console.log("onError", e)}
                                  volume={this.state.volume}
                                  onStart={() => console.log("onStart")}
                                  controls={this.state.controls}
                                />
                              </Col>
                              <Col xl="3">
                              </Col>
                            </Row>
                          )}

                          {this.state.type !== "pdf" && this.state.type !== "mp4" &&
                            this.state.type !== "link" && (
                              <FileViewer
                                fileType={this.state.type}
                                filePath={this.state.content_url}
                                onError={this.onError}
                              />
                            )}
                          {this.state.type == "pdf" && (
                            <div className="ipharediv" style={{ height: "100%" }}>
                              <iframe
                                frameborder="0"
                                scrolling="no"
                                height="100%"
                                width="100%"
                                src={this.state.content_url}
                              ></iframe>
                            </div>
                          )}
                          {this.state.type == "png" && (
                            <div className="ipharediv" style={{ height: "100%" }}>
                              <iframe
                                frameborder="0"
                                scrolling="no"
                                height="100%"
                                width="100%"
                                src={this.state.content_url}
                              ></iframe>
                            </div>
                          )}

                          <div className="m3">

                            <div dangerouslySetInnerHTML={{ __html: this.state.records.content }} />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
