import React from "react";
import list_view from "../../assets/images/resource-drive/list_view.jpg";
import info from "../../assets/images/resource-drive/info.png";
import "../../assets/css/resource-drive/DisplayContainer.css";
import DisplayCard from "./DisplayCard";
import { Link } from "react-router-dom";
import ResourcesContext from "./ResourcesContext";
import { bcrypt } from "../../useToken";
import Dropdown from 'react-bootstrap/Dropdown';

export default function DisplayDrive() {
  const params = React.useContext(ResourcesContext);

  return (
    <>
      <div id="displayCont">
        <div id="displayInfoNav">

          <div className="w-100 mt-2">
            <ol className="breadcrumb">
              {
                params.breadcrumbs.map((row, i) => {
                  if (params.breadcrumbs.length == i + 1) {
                    return <li key={i} className="breadcrumb-item active" >{row.name}</li>
                  } else {
                    return <li key={i} className="breadcrumb-item"><Link to={`/resourcesDrive/${params.type}/${bcrypt(params.user_id)}/${row.id}`}>{row.name}</Link></li>
                  }
                })
              }
            </ol>
          </div>

          {/* <p>Heading</p> */}

          {
            params.viewType == 'list' ?
              <button onClick={() => params.changeViewType('list')} className="opacity"  >
                <i className="fas fa-th-list"></i>
              </button>
              :
              <button onClick={() => params.changeViewType('grid')} className="opacity"  >
                <i className="fas fa-th-large"></i>
              </button>

          }



          {/* <button>
            <img src={info} alt="Reload page" className="opacity" />
          </button> */}
        </div>

        <h5 className="mt-3">Folder</h5>
        {
          params.viewType == 'grid' && (
            <div className="folders d-flex">
              <div className="row w-100">
                {
                  params.lists.folders.map((row, i) => {
                    return (
                      <div className="col-lg-3" key={i}>
                        <div className="folder justify-content-between">
                          <Link to={`/resourcesDrive/${params.type}/${bcrypt(params.user_id)}/${row.id}`} key={i}>
                            <div className="info">
                              <i className="fas fa-folder"></i>
                              <p>{row.name}</p>
                            </div>
                          </Link>
                          {
                            params.type == 'my-resources' && (
                              <div className="action">
                                <Dropdown drop={'start'} onSelect={(e) => params.DriveAction('DisplayDrive', e, row)}>
                                  <Dropdown.Toggle id="button" variant="link">
                                    <i className="fas fa-ellipsis-v"></i>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu variant="link">
                                    <Dropdown.Item eventKey="4">Rename</Dropdown.Item>
                                    <Dropdown.Item eventKey="1">Delete</Dropdown.Item>
                                    <Dropdown.Item eventKey="2">Move</Dropdown.Item>
                                    <Dropdown.Item eventKey="3">Share</Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            )
                          }
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          )
        }

        {
          params.viewType == 'list' && (
            <table className="table table-stripped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Created By</th>
                  <th>Created At</th>
                  {
                    params.type == 'my-resources' && (
                      <th>Action</th>
                    )
                  }
                </tr>
              </thead>
              <tbody>
                {
                  params.lists.folders.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <Link to={`/resourcesDrive/${params.type}/${bcrypt(params.user_id)}/${row.id}`} key={i}>
                            <div className="info d-flex text-black align-items-center">
                              <div className="fileIcon">
                                <i className="fas fa-folder"></i>
                              </div>
                              <p>{row.name}</p>
                            </div>
                          </Link>
                        </td>
                        <td>{row.created_by?.name}</td>
                        <td>{row.created_at}</td>
                        <td>
                          {
                            params.type == 'my-resources' && (
                              <div className="action">
                                <Dropdown drop={'start'} onSelect={(e) => params.DriveAction('DisplayDrive', e, row)}>
                                  <Dropdown.Toggle id="button" variant="link">
                                    <i className="fas fa-ellipsis-v"></i>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu variant="link">
                                    <Dropdown.Item eventKey="4">Rename</Dropdown.Item>
                                    <Dropdown.Item eventKey="1">Delete</Dropdown.Item>
                                    <Dropdown.Item eventKey="2">Move</Dropdown.Item>
                                    <Dropdown.Item eventKey="3">Share</Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            )
                          }
                        </td>

                      </tr>

                    )
                  })
                }
                {
                  params.lists.files.map((row, i) => {
                    return (
                      <tr>

                        <td className="d-flex align-items-center">
                          <div className="fileIcon">
                            <div className="icon-class-design">


                              {
                                row.file_type.match('image.*') && (
                                  <img src={row.url} className="img img-thumbnail me-2" />
                                )
                              }

                              {
                                row.file_type.match('video.*') && (
                                  <i className="fab fa-youtube"></i>
                                )
                              }

                              {
                                row.file_type.match('pdf') && (
                                  <i className="far fa-file-pdf"></i>
                                )
                              }

                              {
                                row.file_type.match('openxmlformats') && (
                                  <i className="far fa-file-word"></i>
                                )
                              }

                              {
                                (row.file_type.match('csv') || row.file_type.match('text/plain')) && (
                                  <i className="far fa-file-excel"></i>
                                )
                              }
                            </div>



                          </div>
                          <button className="btn btn-link" onClick={() => params.FileAction('DisplayFile', '5', row)} key={i}>
                            <div className="info d-flex text-black gap-2">
                              {/* <i className="fas fa-file"></i> */}
                              <p>{row.name}</p>

                            </div>
                          </button>
                        </td>
                        <td>{row.created_by?.name}</td>
                        <td>{row.created_at}</td>
                        <td>
                          {
                            params.type == 'my-resources' && (
                              <div className="action">
                                <Dropdown drop={'start'} onSelect={(e) => params.FileAction('DisplayFile', e, row)}>
                                  <Dropdown.Toggle id="button" variant="link">
                                    <i className="fas fa-ellipsis-v"></i>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu variant="link">
                                    <Dropdown.Item eventKey="4">Rename</Dropdown.Item>
                                    <Dropdown.Item eventKey="5">View</Dropdown.Item>
                                    <Dropdown.Item eventKey="1">Delete</Dropdown.Item>
                                    <Dropdown.Item eventKey="2">Move</Dropdown.Item>
                                    <Dropdown.Item eventKey="3">Share</Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            )
                          }
                        </td>

                      </tr>

                    )
                  })
                }
              </tbody>
            </table>
          )
        }




        {
          params.viewType == 'grid' && (
            <div>
              <h5 className="mt-3">Files</h5>
              <div className="files d-flex">
                <div className="row w-100">
                  {
                    params.lists.files.map((row, i) => {
                      return (
                        <div className="col-lg-3 mb-4">

                          <div className="folder justify-content-between p-2">
                            <div className="d-flex flex-column  w-100">
                              <div className="d-flex justify-content-between px-2 pb-1 border-bottom">
                                {/* <a href={`#`}  key={i}> */}
                                <div className="info ">
                                  {
                                    row.file_type.match('image.*') ? (
                                      <i className="far fa-image"></i>
                                    ) :
                                      row.file_type.match('video.*') ? (
                                        <i className="fab fa-youtube"></i>
                                      ) :
                                        row.file_type.match('audio.*') ? (
                                          <i className="far fa-file-audio"></i>
                                        ) : row.file_type.match('pdf') ? (
                                          <i className="far fa-file-pdf"></i>
                                        ) :
                                          row.file_type.match('ms-powerpoint') ? (
                                            <i className="fas fa-file-powerpoint"></i>
                                          ) :
                                            (row.file_type.match('csv') || row.file_type.match('ms-excel') || row.file_type.match('xlsx')) ? (
                                              <i className="far fa-file-excel"></i>
                                            ) :
                                              row.file_type.match('application/msword') ? (
                                                <i className="far fa-file-word"></i>
                                              ) :
                                                <i className="far fa-file"></i>
                                  }

                                  <p>{row.name}</p>

                                </div>
                                {/* </a> */}
                                {
                                  params.type == 'my-resources' && (
                                    <div className="action">
                                      <Dropdown onSelect={(e) => params.FileAction('DisplayFile', e, row)}>
                                        <Dropdown.Toggle id="button" variant="link">
                                          <i className="fas fa-ellipsis-v"></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu variant="link">
                                          <Dropdown.Item eventKey="4">Rename</Dropdown.Item>
                                          <Dropdown.Item eventKey="5">View</Dropdown.Item>
                                          <Dropdown.Item eventKey="1">Delete</Dropdown.Item>
                                          <Dropdown.Item eventKey="2">Move</Dropdown.Item>
                                          <Dropdown.Item eventKey="3">Share</Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  )
                                }
                              </div>

                              <div className="icon-class-design" role="button" onClick={() => params.FileAction('DisplayFile', '5', row)}>

                                {
                                  row.file_type.match('image.*') ? (
                                    <img src={row.url} className="img img-thumbnail" />
                                  ) :
                                    row.file_type.match('video.*') ? (
                                      <i className="fab fa-youtube"></i>
                                    ) :
                                      row.file_type.match('audio.*') ? (
                                        <i className="far fa-file-audio"></i>
                                      ) : row.file_type.match('pdf') ? (
                                        <i className="far fa-file-pdf"></i>
                                      ) :
                                        row.file_type.match('ms-powerpoint') ? (
                                          <i className="fas fa-file-powerpoint"></i>
                                        ) :
                                          (row.file_type.match('csv') || row.file_type.match('excel') || row.file_type.match('xlsx')) ? (
                                            <i className="far fa-file-excel"></i>
                                          ) :
                                           row.file_type.match('application/msword') ? (
                                              <i className="far fa-file-word"></i>
                                            ) :
                                              <i className="far fa-file"></i>
                                }


                              </div>


                            </div>
                          </div>
                        </div>
                      );

                    })
                  }
                </div>
              </div>
            </div>
          )
        }
      </div>
    </>
  );
}
