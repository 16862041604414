import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Nav, NavItem } from "reactstrap";
export default class CampaignNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: this.props.activeTab,
            campaignId: this.props.campaignId
        };
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col className="col-12">
                        <Card className="mb-0">
                            <Nav tabs>
                                <NavItem>
                                    <Link
                                        className={`nav-link  ${this.state.tab == 1 ? "active" : ""} `}
                                        to={`/campaign/edit/${this.state.campaignId}`}
                                    >
                                        Basic Info
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link
                                        className={`nav-link  ${this.state.tab == 2 ? "active" : ""} `}
                                        to={`/campaign/inbound/${this.state.campaignId}`}
                                    >
                                        Inbound Setting
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link
                                        className={`nav-link  ${this.state.tab == 3 ? "active" : ""} `}
                                        to={`/campaign/outbound/${this.state.campaignId}`}

                                    >
                                        Outbound Setting
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link
                                        className={`nav-link  ${this.state.tab == 4 ? "active" : ""} `}
                                        to={`/campaign/broadcasts/${this.state.campaignId}`}
                                    >
                                        Broadcasts
                                    </Link>
                                </NavItem>
                                {/* <NavItem>
                                    <Link
                                        className={`nav-link  ${this.state.tab == 5 ? "active" : ""} `}
                                        to={`/campaign/drips/${this.state.campaignId}`}

                                    >
                                        Drips
                                    </Link>
                                </NavItem> */}
                                {/* <NavItem>
                                    <Link
                                        className={`nav-link  ${this.state.tab == 6 ? "active" : ""} `}
                                        to={`/campaign/eventreminder/${this.state.campaignId}`}
                                    >
                                        Event Reminder
                                    </Link>
                                </NavItem> */}
                                <NavItem>
                                    <Link
                                        className={`nav-link  ${this.state.tab == 7 ? "active" : ""} `}
                                        to={`/campaign/automation/list/${this.state.campaignId}`}
                                    >
                                        Automations
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link
                                        className={`nav-link  ${this.state.tab == 8 ? "active" : ""} `}
                                        to={`/campaign/batches/${this.state.campaignId}/MA==/email`}

                                    >
                                       
                                        Batches
                                    </Link>
                                </NavItem>
                                <NavItem>
                                    <Link
                                        className={`nav-link  ${this.state.tab == 9 ? "active" : ""} `}
                                        to={`/campaign/report/summary/${this.state.campaignId}`}
                                    >
                                        Reporting
                                    </Link>
                                </NavItem>
                            </Nav>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
