import React, { useState, useEffect, useRef } from "react";
import { ApiUrl } from "../../../config";
import Context from './Context';
import swal from "sweetalert";
import { dcrypt, bcrypt, uInfo, uToken } from "../../../useToken";
import {
    Modal,
    Card,
    CardBody,
    Button,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ImageCropper from "./ImageCropper";
const Box = (props) => {
    const params = React.useContext(Context);
    const [record, setRecord] = useState({});
    const [modal, setModal] = useState(false);
    const [imageData, setImageData] = useState({
        id: '',
        url: '',
    });
    const [inputFields, setInputFields] = useState(
        { preview_logo: '', preview_hero_image: '', preview_favicon: '' }
    );
    useEffect(() => {
        getData();
    }, []);
    const getData = () => {
        fetch(`${ApiUrl}` + "get/systemForm/resourceInfo", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: params.form_data.id, user_id: uInfo().user_id }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setRecord(response.data.record);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        submitData.append('user_id', uInfo().user_id);
        submitData.append('id', params.form_data.id);

        if (inputFields.preview_logo != '') {
            submitData.append('preview_logo_base_64', inputFields.preview_logo);
        }

        if (inputFields.preview_hero_image != '') {
            submitData.append('preview_hero_image_base_64', inputFields.preview_hero_image);
        }

        if (inputFields.preview_favicon != '') {
            submitData.append('preview_favicon_base_64', inputFields.preview_favicon);
        }

        fetch(`${ApiUrl}` + "update/system/form", {
            method: "POST",
            body: submitData,
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    // params.goToBox(params.currentBox + 1);
                    // params.getBuilderSetting();
                    swal({
                        title: "Action performed successfully",
                        icon: "success",
                    });
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const changeHandlerImg = (e, id) => {
        if (e.target.files[0].type.match('image.*')) {
            const preview = document.querySelector('#' + id);
            var reader = new FileReader();
            reader.onload = () => {
                preview.src = reader.result;
                imageCropper(id, reader.result)
            };
            reader.readAsDataURL(e.target.files[0]);
        } else {
            swal({
                title: 'Olny images allowed',
                icon: "warning",
            });
        }
    };

    const imageCropper = (id, url) => {
        // var url = document.getElementById(id).src;
        setImageData({
            id: id,
            url: url
        });
        setTimeout(() => {
            setModal(true);
        }, 200);
    }

    const imageCropperSet = (id, url) => {
        const preview = document.querySelector('#' + id);
        preview.src = url;

        setInputFields({ ...inputFields, [id]: url })

        setModal(false);
    }
    const closeModal = () => {
        setInputFields({ ...inputFields, [imageData.id]: imageData.url })
        setModal(false);
    }
    return (
        <div className="mt-1">
            <div className="d-flex align-items-center gap-2 StepHeading">
                <div className="StepNumber">{params.form_data.id} <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">{params.form_data.name}</h4>
            </div>
            <form method="post" onSubmit={handleSubmit} encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                <div className="border-right p-2">
                    <div className="page-content-box">
                        <div className="WizredForm mt-2">

                            <div className="form-group mt-4">
                                <label>Upload Your Favicon File <note className="text-muted">(Preferably size 48 x 48  & Preferably in a PNG file.)</note></label>
                                <br /><h6 className="text-muted my-3">You can use free online tools such as: <a target="_blank" href="https://favicon.io/favicon-converter/">https://favicon.io/favicon-converter/</a></h6>

                                <input name="favicon" className="form-control no-border px-0"
                                    accept="image/*"
                                    type="file" placeholder="https://"
                                    onChange={(e) => changeHandlerImg(e, 'preview_favicon')}
                                />
                                <img width={'50'} id="preview_favicon" src={record.favicon} className="img img-thumbnail mt-1" alt="Not Found" />
                            </div>
                        </div>
                    </div>
                </div>
                <button type="submit" className="btn btn-sm btn-primary mt-2"> Ok &nbsp;<i className="fas fa-check"></i></button>
            </form>
            <Modal
                isOpen={modal}
                fade={true}
                size="lg"
                toggle={() => closeModal()}
                centered={true}
                backdrop={'static'}
                style={{ height: "calc(156vh - 186px) !important" }}
            >
                <div className="">
                    <div className='modal-header'>
                        <div className='modal-title'>
                            Crop Image
                        </div>
                        {/* <button onClick={() => closeModal()} className="mb-3 btn btn-link pull-right BtnCenterClose"><i className="fas fa-times"></i></button> */}
                    </div>

                    <ImageCropper closeModal={closeModal} imageCropperSet={imageCropperSet} data={imageData} />



                </div>
            </Modal>
        </div>
    );
};

export default Box;