import React, { useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { hasRole, uInfo, uRole } from "../../useToken";

const SidebarContent = (props) => {
  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    // console.log(window.location,'loc')
    const pathName = window.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [window.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                <span className="badge rounded-pill bg-primary float-end">
                  2
                </span>
                <span>{"Dashboard"}</span>
              </Link>
            </li>

            {hasRole(uInfo(), ["Users"]) && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ion ion-md-settings"></i>
                  <span>{"Users"}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  {hasRole(uInfo(), ["Reseller Management"]) && (
                    <li>
                      <Link to="/resellers" className=" waves-effect">
                        {/* <i className="mdi mdi-email-outline"></i> */}
                        <span>{"WL Admin Management"}</span>
                      </Link>
                    </li>
                  )}

                  {hasRole(uInfo(), ["Managers Management"]) && (
                    <li>
                      <Link to="/managers" className=" waves-effect">
                        {/* <i className="mdi mdi-email-outline"></i> */}
                        <span>{"Coache's Management"}</span>
                      </Link>
                    </li>
                  )}
                  {hasRole(uInfo(), ["Leads Management"]) && (
                    <li>
                      <Link to="/leads" className=" waves-effect">
                        {/*  <i className="mdi mdi-calendar-check"></i> */}
                        <span>{"Leads/Clients Management"}</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}

            {/*   {hasRole(uInfo(), ['Reseller Packages']) && ( */}
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ion ion-md-settings"></i>
                <span>{"Packages"}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                {hasRole(uInfo(), ["Reseller Packages"]) && (
                  <li>
                    <Link to="/resellerpackages" className=" waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"WL Admin Packages"}</span>
                    </Link>
                  </li>
                )}
                {hasRole(uInfo(), ["Packages"]) && (
                  <li>
                    <Link to="/packages" className=" waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"Coache's Packages"}</span>
                    </Link>
                  </li>
                )}
              </ul>
            </li>
            {/*  )} */}
            {hasRole(uInfo(), ["Roles"]) && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ion ion-md-settings"></i>
                  <span>{"Staff"}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/roles" className="waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"Admin Staff"}</span>
                    </Link>
                  </li>
                  {hasRole(uInfo(), ["Roles"]) && (
                    <li>
                      <Link to="/roles" className=" waves-effect">
                        {/* <i className="mdi mdi-email-outline"></i> */}
                        <span>{"Staff Permission"}</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
            {hasRole(uInfo(), ["Credits"]) && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ion ion-md-settings"></i>
                  <span>{"Credits"}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                {hasRole(uInfo(), ["WL Admin Credit Management"]) && (
                  <li>
                    <Link to="/" className="waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"WL Admin Credit Management"}</span>
                    </Link>
                  </li>
                   )}
                  {hasRole(uInfo(), ["Coaches Credit Management"]) && (
                    <li>
                      <Link to="/roles" className=" waves-effect">
                        {/* <i className="mdi mdi-email-outline"></i> */}
                        <span>{"Coaches Credit Management"}</span>
                      </Link>
                    </li>
                  )}
                  {hasRole(uInfo(), ["Coaches Credit Criteria"]) && (
                    <li>
                      <Link to="/roles" className=" waves-effect">
                        {/* <i className="mdi mdi-email-outline"></i> */}
                        <span>{"Coaches Credit Criteria"}</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}

            {hasRole(uInfo(), ["Coaching Stuff"]) && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ion ion-md-settings"></i>
                  <span>{"Coaching Stuff"}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/" className="waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"Manage All contracts"}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/roles" className=" waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"Manage documents"}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/roles" className=" waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"Manage assessments"}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/roles" className=" waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"Manage media"}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/roles" className=" waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"Manage academy controls"}</span>
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {hasRole(uInfo(), ["Billing"]) && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ion ion-md-settings"></i>
                  <span>{"Billing"}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/" className="waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"Subscription Log for WL Admin"}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/roles" className=" waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"Subscription Log for Coaches"}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/roles" className=" waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"Credit Purchase Log"}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/roles" className=" waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"Monthly Subscription Revenue Report"}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/roles" className=" waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"Monthly Revenue (Non-Subscription)"}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/roles" className=" waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"Net Revenue"}</span>
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {hasRole(uInfo(), ["Reports"]) && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ion ion-md-settings"></i>
                  <span>{"Reports"}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/" className="waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"WL Admin activities log"}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/roles" className=" waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"Coaches or Coach partner activity log"}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/roles" className=" waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"Client’s activity log"}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/logs" className=" waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"Staff Activity log"}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/roles" className="waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>
                        {"Twillio Usage log(*Daily/Weekly/Monthly Coach Usage)"}
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/roles" className=" waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"Credit consumption log for each WL Admin"}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/roles" className=" waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"Credit consumption log for each Coach"}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/roles" className=" waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"Login history for each WL Admin"}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/roles" className=" waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"Login history for each Coach"}</span>
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {hasRole(uInfo(), ["CMS"]) && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ion ion-md-settings"></i>
                  <span>{"CMS"}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/" className="waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"Disclaimer management "}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/roles" className=" waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"Terms of service management"}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/roles" className=" waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"Privacy policy management"}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/roles" className=" waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"Email Template settings for transactional emails"}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/roles" className=" waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>
                        {"Notification management "}
                      </span>
                    </Link>
                  </li>
                 
                </ul>
              </li>
            )}

            {hasRole(uInfo(), ["Group Management"]) && (
              <li>
                <Link to="/groups" className=" waves-effect">
                  <i className="mdi mdi-calendar-check"></i>
                  <span>{"Group Management"}</span>
                </Link>
              </li>
            )}

          {/*   {hasRole(uInfo(), ["Leads Management"]) && (
              <li>
                <Link to="/leads" className=" waves-effect">
                  <i className="mdi mdi-calendar-check"></i>
                  <span>{"Leads Management"}</span>
                </Link>
              </li>
            )}
        */}

      

           {/*  {hasRole(uInfo(), ["Roles", "Packages", "Logs", "Email"]) && ( */}
            {hasRole(uInfo(), ["Settings"]) && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="ion ion-md-settings"></i>
                  <span>{"Settings"}</span>
                </Link>

                
                <ul className="sub-menu" aria-expanded="false">
                   <li>
                      <Link to="/logs" className=" waves-effect">
                        {/* <i className="mdi mdi-history"></i> */}
                        <span>{"WL Theme setting"}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/logs" className=" waves-effect">
                        {/* <i className="mdi mdi-history"></i> */}
                        <span>{"Twillio account settings"}</span>
                      </Link>
                    </li>
              
                  {hasRole(uInfo(), ["Email"]) && (
                    <li>
                      <Link to="/emails" className=" waves-effect">
                        {/* <i className="mdi mdi-email-outline"></i> */}
                        <span>{"Email Settings"}</span>
                      </Link>
                    </li>
                  )}
                      <li>
                      <Link to="/logs" className=" waves-effect">
                        {/* <i className="mdi mdi-history"></i> */}
                        <span>{"Manage WL Domains"}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/logs" className=" waves-effect">
                        {/* <i className="mdi mdi-history"></i> */}
                        <span>{"WL Domains Settings"}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/logs" className=" waves-effect">
                        {/* <i className="mdi mdi-history"></i> */}
                        <span>{"Twillio Usage log"}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/logs" className=" waves-effect">
                        {/* <i className="mdi mdi-history"></i> */}
                        <span>{"Payment settings "}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/tablessetting" className=" waves-effect">
                        {/* <i className="mdi mdi-history"></i> */}
                        <span>{"Table settings "}</span>
                      </Link>
                    </li>
                    <li>
                    <Link to="/leadsetting" className="waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"Leads Setting"}</span>
                    </Link>
                  </li> 
                   <li>
                    <Link to="/leadsetting" className="waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"Chat or support code Embedding"}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/leadsetting" className="waves-effect">
                      {/* <i className="mdi mdi-email-outline"></i> */}
                      <span>{"Tag or code Embedding in WL domains"}</span>
                    </Link>
                  </li>



                 
                  {hasRole(uInfo(), ["Api Credentials"]) && (
                    <li>
                      <Link to="/apicredentials" className=" waves-effect">
                        {/* <i className="mdi mdi-history"></i> */}
                        <span>{"Api Credentials"}</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default SidebarContent;
