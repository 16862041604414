import React, { useState, useEffect, useRef } from "react";
import {
    Row, Col, Card, CardBody, CardTitle, Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Button,
} from "reactstrap"
import Helmet from "react-helmet"
import { ApiUrl, ProductName } from "../../config";
import { uToken} from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import swal from 'sweetalert';

const ClientForm = (props) => {
  const [id, setId] = useState(props.match.params.id ? props.match.params.id : null);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [tab, setTab] = useState("1");
    const [disclaimer_status, setDisclaimerStatus] = useState(false);
    
    useEffect(() => {
        getData();
    }, []);


    const getData = () => {
        fetch(`${ApiUrl}` + "get/edit/clientForms", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ id: id}),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setData(response.data.record);
                    setLoading(false);
                } else {
                     toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const CreateCustomFieldUI = () => {
        return data.metadata.map((row, i) => {
            if (row.type == "text" || row.type == "date" || row.type == "number") {
                return <div key={row.type + '--' + i} className={`mb-3 form-group ${row.name}`}>
                    <label htmlFor={row.name} className={`formbuilder-text-label`}>{row.label}
                        {row.description && (
                            <OverlayTrigger
                                key={row.type + '--' + i}
                                placement={'auto'}
                                overlay={
                                    <Tooltip>
                                        {row.description}
                                    </Tooltip>
                                }
                            >
                                <i className="fas fa-question-circle pl-3"></i>
                            </OverlayTrigger>
                        )}
                    </label>
                    <input
                        type={row.type}
                        placeholder={row.placeholder}
                        name={row.name}
                        defaultValue={data.lead_rec_array[data.mapped_data[row.name]] && (data.lead_rec_array[data.mapped_data[row.name]])}
                        readOnly={data.lead_rec_array[data.mapped_data[row.name]] ? true : false}
                        maxLength={row.maxlength ? row.maxlength : ''}
                        className={row.className}
                        id={row.name}
                        title={row.description}
                        required={row.required}

                    />
                </div>
            } else if (row.type == "select") {
                return <div key={row.type + '--' + i} className={`mb-3 fb-select form-group field-${row.name}>`}>
                    <label htmlFor={row.name} className={`formbuilder-text-label`}>{row.label}
                        {row.description && (
                            <OverlayTrigger
                                key={row.type + '--' + i}
                                placement={'auto'}
                                overlay={
                                    <Tooltip>
                                        {row.description}
                                    </Tooltip>
                                }
                            >
                                <i className="fas fa-question-circle pl-3"></i>
                            </OverlayTrigger>


                            // <span className="tooltip-element"><i className="fas fa-question-circle"></i><div className="helper_tooltip">{row.description}</div></span>
                        )}
                    </label>
                    <select className={row.className} name={row.name}
                        defaultValue={data.lead_rec_array[data.mapped_data[row.name]]}
                        disabled={data.lead_rec_array[data.mapped_data[row.name]] ? true : false}

                    >
                        {row.values.map((row, i) => {
                            return <option key={row.value + '--' + i} value={row.value}


                                selected={data.lead_rec_array[data.mapped_data[row.name]] == row.value ? 'selected' : ''}
                                disabled={
                                    row.value == data.lead_rec_array[data.mapped_data[row.name]] ? true : false}
                            >{row.label}</option>

                        })}
                    </select>
                </div>

            } else if (row.type == "radio-group") {
                return <div key={row.type + '--' + i} className={`fb-select mb-3 form-group field-${row.name}>`}>
                    <label htmlFor={row.name} className={`formbuilder-text-label`}>{row.label}
                        {row.description && (
                            <OverlayTrigger
                                key={row.type + '--' + i}
                                placement={'auto'}
                                overlay={
                                    <Tooltip>
                                        {row.description}
                                    </Tooltip>
                                }
                            >
                                <i className="fas fa-question-circle pl-3"></i>
                            </OverlayTrigger>
                            // <span className="tooltip-element"><i className="fas fa-question-circle"></i><div className="helper_tooltip">{row.description}</div></span>
                        )}
                    </label>
                    {row.values.map((option, i) => {
                        return <div key={option.value + '--' + i} className={`form-check ${row.className}`}>
                            <input
                                name={row.name} type="radio"
                                className="form-check-input" value={option.value} />
                            <label className="form-check-label">{`${option.label}`}</label>
                        </div>
                    })}
                </div>
            } else if (row.type == "checkbox-group") {
                return <div key={row.type + '--' + i} className={`fb-select mb-3 form-group field-${row.name}>`}>
                    <label htmlFor={row.name} className={`formbuilder-text-label pr-3`}>{row.label}
                        {row.description && (
                            <OverlayTrigger
                                key={row.type + '--' + i}
                                placement={'auto'}
                                overlay={
                                    <Tooltip>
                                        {row.description}
                                    </Tooltip>
                                }
                            >
                                <i className="fas fa-question-circle pl-3"></i>
                            </OverlayTrigger>
                        )}
                    </label>
                    {row.values.map((option, i) => {
                        return <div key={option.value + '--' + i} className="form-check form-check-inline">
                            <input name={`${row.name}[]`} type="checkbox"
                                className=" form-check-input" value={`${option.value}`} />
                            <label className="form-check-label">{`${option.label}`}</label>
                        </div>
                    })}
                </div>
            } else if (row.type == "textarea") {
                return <div className={`fb-select mb-3 form-group field-${row.name}>`}>
                    <label htmlFor={row.name} className={`formbuilder-text-label pr-3`}>{row.label}
                        {row.description && (
                            <OverlayTrigger
                                key={row.type + '--' + i}
                                placement={'auto'}
                                overlay={
                                    <Tooltip>
                                        {row.description}
                                    </Tooltip>
                                }
                            >
                                <i className="fas fa-question-circle pl-3"></i>
                            </OverlayTrigger>
                        )}
                    </label>
                    <textarea
                        type={row.type}
                        placeholder={row.placeholder}
                        name={row.name}
                        defaultValue={data.lead_rec_array[data.mapped_data[row.name]] && (data.lead_rec_array[data.mapped_data[row.name]])}
                        readOnly={data.lead_rec_array[data.mapped_data[row.name]] ? true : false}
                        maxLength={row.maxlength ? row.maxlength : ''}
                        className={row.className}
                        id={row.name}
                        title={row.description}
                        required={row.required}

                    >

                    </textarea>

                </div>
            } else if (row.type == 'file') {
                return <div key={row.type + '--' + i} className={`mb-3 form-group ${row.name}`}>
                    <label htmlFor={row.name} className={`formbuilder-text-label`}>{row.label}
                        {row.description && (
                            <OverlayTrigger
                                key={row.type + '--' + i}
                                placement={'auto'}
                                overlay={
                                    <Tooltip>
                                        {row.description}
                                    </Tooltip>
                                }
                            >
                                <i className="fas fa-question-circle pl-3"></i>
                            </OverlayTrigger>
                        )}
                    </label>
                    <input
                        type={row.type}
                        placeholder={row.placeholder}
                        name={row.name}
                        className={row.className}
                        id={row.name}
                        title={row.description}
                        required={row.required}
                    />
                </div>
            } else if (row.type == 'paragraph') {
                return <div key={row.type + '--' + i} className="fb-text  form-group "><div dangerouslySetInnerHTML={{ __html: row.label }}></div></div>
            } else if (row.type == 'header') {
                return <div key={row.type + '--' + i} className="fb-text  form-group "><p className={row.subtype}>{row.label}</p></div>
            }
        })
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        // if(!disclaimer_status){
        //     swal({
        //         text: "Please accept disclaimer to.",
        //         icon: "warning",
        //       });
        // }
        
       
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        } 
        fetch(`${ApiUrl}` + "clientForm/meta/saved/" + id, {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData,
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    swal({
                        text: "Action performed successfully.",
                        icon: "success",
                      });
                      if(response.data.record.redirect_url !=''){
                        console.log(response.data.record.redirect_url)
                        setTimeout(
                            () => window.location.href = response.data.record.redirect_url,
                            1000
                        );
                      }else{
                        setTimeout(
                            () => window.location.reload(),
                            1000
                        );
                      }
                    
                } else {
                     toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });

    }

    const toggle = (index) => {
        if (tab !== index) {
            setTab(index);
        }
    };



    return (
        <div className="container">
            {
                !loading && (
                    <div className="row">
                        <div className="col-md-12">
                            {/* <div className="page-title-box d-flex space-between">
                                <h4 className="ml-3">  {data.name}  </h4>
                            </div> */}
                            <ToastContainer />
                        
                            <form onSubmit={handleSubmit} method="post" encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                                {CreateCustomFieldUI()}
                                {data.disclaimer && (
                                    <>
                                    <div className="form-group d-flex align-items-center">
                                            <input className="custom-checkbox" style={{margin:'10px 10px 26px 4px'}} name="disclaimer_status" onChange={(e) => {
                                                setDisclaimerStatus(e.target.checked);
                                            }} type={'checkbox'} checked={disclaimer_status ? true : false} />
                                             <label className="vertical-align-middle ml-2 mb-0">  <div className="fb-text form-group mb-0"><div dangerouslySetInnerHTML={{ __html: data.disclaimer }}></div></div></label>
                                            <input type="hidden" name="disclaimer_id" value={data.disclaimer_id} />
                                       
                                    </div>
                                    </>
                                )}
   
                                <div className="form-group mt-3">
                                    <button className="btn btn-sm btn-block btn-primary" type="submit" id="submit_selection_myform" value="submit">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                )
            }
        </div>
    )

}

export default ClientForm;
