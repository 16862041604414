import React, { Component, Fragment } from "react"
import { Row, Col, Card, CardBody, CardTitle, Form, Label, Button, Modal, ModalHeader, Input, ModalBody, ModalFooter, Table } from "reactstrap"
import { bcrypt, uToken } from "../../useToken";
import { ApiUrl, AttachementsUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import ProfileSettings from "../Authentication/profile-sidebar";
import Loader from "react-loader";
import { Link } from "react-router-dom";
import swal from 'sweetalert';

export default class Branding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            records: [],
            loader: true,
            page_name: 'Create page',
            template_id: 0
        }
    }


    componentDidMount() {
        this.getData();
    }

    getData = () => {
        fetch(`${ApiUrl}` + 'get/template/pages', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + uToken()
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        records: response.data.records,
                    });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }

            })
            .catch((error) => {
                console.error('Error:', error);
            });

    }

    submitPage = (event) => {
        event.preventDefault();
        if (this.state.template_id == 0) {
            swal({
                title: "Template",
                text: "You need to select one template.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            });
            return true;
        }

        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        fetch(`${ApiUrl}` + "crate/page", {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                if (data.status === true) {
                    this.props.history.push(`/content/${bcrypt(data.data.user_id)}/${data.data.slug}`);
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
                this.setState({ loader: true });
            })
            //Then with the error genereted...
            .catch((error) => {
                this.setState({ loader: true });

                console.error("Error:", error);
            });
    }


    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="2" className="pl-0">
                        <ProfileSettings menuOpen={false} props={this.props} />
                    </Col>
                    <Col lg="10">
                        <Loader loaded={this.state.loader}></Loader>
                        <Row>
                            <Col sm={6} >
                                <div className="page-title-box">
                                    <h4> {this.state.page_name} </h4>
                                    <ol className="breadcrumb m-0" >
                                        <li key={0} className="breadcrumb-item active"> {ProductName} </li>
                                        <li key={1} className="breadcrumb-item"> < Link to="#"> {this.state.page_name} </Link></li>
                                    </ol>
                                </div>
                            </Col>

                        </Row>

                        <Card>
                            <CardBody>
                                <form onSubmit={this.submitPage} method="post"
                                    encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                                    <Row>
                                        <Col md="12">
                                            <div className="mb-3">
                                                <div className="pull-right">
                                                    <Button
                                                        className="btn-md mr-5"
                                                        color="warning"
                                                        type="button"
                                                        onClick={() => this.props.history.push('/manage-contents')}
                                                    >
                                                        Back
                                                    </Button>
                                                    <Button
                                                        className="btn-md"
                                                        color="primary"
                                                        type="submit"
                                                    >
                                                        Submit
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="form-group mb-3">
                                                <label>Page name</label>
                                                <input
                                                    name="title"
                                                    className="form-control"
                                                    placeholder="Enter name"
                                                    required
                                                />
                                            </div>
                                        </Col>
                                        {
                                            this.state.records.map((row, i) => {
                                                return <div className="col-md-3 mb-5">
                                                    <div className={`${this.state.template_id == row.id ? 'active img-page' : 'img-page'}`}>
                                                        <button type="button" className="btn btn-link" onClick={() => this.setState({ template_id: row.id })}>
                                                            <h3 className="title-page">{row.page_title}</h3>
                                                            <img src={`${AttachementsUrl}/${row.path}.png`} width={'100%'} />
                                                        </button>
                                                    </div>
                                                </div>;
                                            })
                                        }

                                        <input type={'hidden'} name="template_id" value={this.state.template_id} />

                                    </Row>
                                </form>
                            </CardBody>
                        </Card>

                    </Col>
                </Row>

            </React.Fragment >
        )
    }
}