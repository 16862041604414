import React, { useState, useEffect, useRef } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import { Accordion, Dropdown, Tab, Tabs, Tooltip } from "react-bootstrap";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import axios from "axios";
import {
    AvForm,
    AvField,
    AvInput,
    AvRadioGroup,
    AvRadio,
    AvCheckboxGroup,
    AvCheckbox,
    AvGroup,
} from "availity-reactstrap-validation";
import {
    bcrypt,
    dcrypt,
    isValidJSONString,
    uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Collapse from 'react-bootstrap/Collapse';
import swal from 'sweetalert';


const TrainingSectionsQuiz = (props) => {

    const [id] = useState(props.match.params.id
        ? dcrypt(props.match.params.id)
        : null);
    const [records, setRecords] = useState([]);
    const [open, setOpen] = useState(false);

    const [record, setRecord] = useState({
        question: '',
        answer: '',
        options: [{ [`option`]: "" }, { [`option`]: "" }]
    });

    useEffect(() => {
        getData();
    }, []);


    const getData = () => {
        fetch(`${ApiUrl}` + "training/quiz/question/" + id, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            // body: JSON.stringify({ cat_id: id }),
        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {
                    console.log(response, 'debugging');
                    setRecords(response.data.records);

                } else {
                              
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    const onDragEnd = e => {
        if (!e.destination) {
            return;
        }
        const sorted = reorder(records, e.source.index, e.destination.index);
        setRecords(sorted);
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };


    function handleSubmit(event) {
        fetch(`${ApiUrl}` + "update/quiz/order", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ cat_id: id, items_arr: records }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {

                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                              
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const submitSetupPrice = async (event) => {
        event.preventDefault();
        const arrayOfObject = record.options;
        const checkValue = obj => obj.option === record.answer;
        if (!arrayOfObject.some(checkValue)) {
            swal({
                title: "Incorrect Answer",
                text: "Your correct answer does not match any of your options",
                icon: "warning",
                button: "Close",
            });
            return true;
        }
        if (record.quiz_id) {
            record.id = record.quiz_id;
        }
        record.cat_id = id;
        fetch(`${ApiUrl}` + "add/quiz/question", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(record),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setRecord({
                        question: '',
                        answer: '',
                        options: [{ [`option`]: "" }, { [`option`]: "" }]
                    });
                    setOpen(false);
                    getData();
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                              
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }


    function handleOptionChange(e, k) {
        const { name, value } = e.target;
        console.log(name, value, 'ss')
        // let customFields = [...this.state.customFields];
        // customFields[i].options[k] = { ...customFields[i].options[k], [name]: value };
        // this.setState({ customFields });

        // setRecord((prevState) => {
        //     return { ...prevState, options: prevState.options = { ...prevState.options[k], [name]: value } };
        // });

        setRecord((prevRecord) => {
            const newRecord = { ...prevRecord }
            newRecord.options[k][name] = value
            return newRecord
        })
    }


    function removeOptionClick(e, k) {
        const temp = [...record.options];
        temp.splice(k, 1);
        setRecord({ ...record, ['options']: temp });
        return;
    }

    function addOption(e) {
        setRecord((prevState) => {
            return { ...prevState, options: prevState.options = [...prevState.options, { [`option`]: "" }] };
        });
        return;
    }

    const deleteRecord = (id) => {
        swal({
            title: "Are you sure?",
            text: "You want to remove this question.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "delete/quiz/question/" + id, {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((data) => {
                            if (data.status === true) {
                                getData();
                            } else {
                                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                            }
                        })
                        //Then with the error genereted...
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    };




    return (
        <React.Fragment>

            <div className="row">
                <div className="col-md-12">
                    <div className="pb-10 pull-right">
                        <Button
                            type="button"
                            color="secondary"
                            onClick={() => props.history.goBack()}
                            className="mr-3"
                        >
                            Back
                        </Button>
                        <Button
                            onClick={() => {
                                setOpen(!open); !open && (setRecord({
                                    question: '',
                                    answer: '',
                                    options: [{ [`option`]: "" }, { [`option`]: "" }]
                                }))
                            }}
                            className="btn btn-info"
                            aria-controls="example-collapse-text"
                            aria-expanded={open}
                        >
                            Add Question
                        </Button>
                    </div>
                </div>
            </div>

            <div>
                <Collapse in={open}>
                    <div id="example-collapse-text">
                        <Card body>
                            <form onSubmit={submitSetupPrice} method="post"
                                encType="multipart/form-data" className="form-horizontal" id="setup_fee-myform">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <input name="question"
                                                placeholder="Enter question"
                                                label="Question"
                                                className="form-control"
                                                type="text"
                                                required
                                                onChange={(e) => {
                                                    setRecord((prevRecord) => {
                                                        const newRecord = { ...prevRecord }
                                                        newRecord.question = e.target.value
                                                        return newRecord
                                                    })
                                                }}
                                                value={record.question} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <input
                                                name="answer"
                                                placeholder="Enter answer"
                                                label="Answer"
                                                className="form-control"
                                                type="text"
                                                required
                                                onChange={(e) => {
                                                    setRecord((prevRecord) => {
                                                        const newRecord = { ...prevRecord }
                                                        newRecord.answer = e.target.value
                                                        return newRecord
                                                    })
                                                }}
                                                value={record.answer}
                                            />
                                        </div>
                                    </div>
                                    {
                                        record.options.map((a, k) => (
                                            <div className="col-md-3 mb-3" key={k}>

                                                <div className="input-group mb-3 form-group">
                                                    <input placeholder="Enter Option" name={`option`} required="required" type="text"
                                                        className="form-control "
                                                        value={`${a[`option`]}` || ''}

                                                        onChange={(e) => handleOptionChange(e, k)}
                                                    />
                                                    <div className="input-group-text">
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger"
                                                            onClick={(e) => removeOptionClick(e, k)}>
                                                            <i className="ion ion-md-trash"> </i>
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        ))
                                    }
                                    {
                                        record.options.length > 0 && (
                                            <div className="col-md-12 text-center mb-3">
                                                <button
                                                    type="button"
                                                    className="btn btn-info btn-sm width-350"
                                                    onClick={(e) => addOption(e)}
                                                >
                                                    Add Optios
                                                </button>
                                            </div>
                                        )
                                    }
                                </div>



                                <input type={'hidden'} name="package_setup" defaultValue={'submit'} />


                                <ModalFooter>
                                    <Button
                                        type="button"
                                        color="secondary"
                                        onClick={() => {
                                            setRecord({
                                                question: '',
                                                answer: '',
                                                options: [{ [`option`]: "" }, { [`option`]: "" }]
                                            });
                                            setOpen(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <button
                                        className="btn btn-primary w-md waves-effect waves-light"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </ModalFooter>
                            </form>
                        </Card>
                    </div>
                </Collapse>
            </div >

            <Card>
                <CardTitle className="p-3">Quiz Questions</CardTitle>
                <CardBody>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="Table">
                            {provided => (
                                <div className="table-responsive mb-0 col-md-12" data-pattern="priority-columns">
                                    <table className="table table-striped table-bordered responsiveTable" {...provided.droppableProps} ref={provided.innerRef}>
                                        <thead>
                                            <tr>
                                                <th>Order Sequence</th>
                                                <th>Question</th>
                                                <th>Answer</th>
                                                {/* <th>Options</th> */}
                                                <th>Created At</th>
                                                <th>Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                records.map((row, i) => {
                                                    return (
                                                        <Draggable
                                                            key={row.quiz_id.toString()}
                                                            draggableId={row.quiz_id.toString()}
                                                            index={i}
                                                        >
                                                            {provided => (
                                                                <tr
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <td>{i + 1}</td>
                                                                    <td>{row.question}</td>
                                                                    <td>{row.answer}</td>
                                                                    {/* <td><pre>{JSON.stringify(row.options, null, 2)}</pre></td> */}
                                                                    <td>{row.created_at}</td>
                                                                    <td>
                                                                        <button
                                                                            color="info"
                                                                            className="btn btn-primary btn-sm mb-1 mr-5"
                                                                            onClick={() => { setOpen(!open); setRecord(row) }}
                                                                            aria-controls="example-collapse-text"
                                                                            aria-expanded={open}
                                                                        >
                                                                            Edit
                                                                        </button>

                                                                        <button
                                                                            className="btn btn-danger btn-sm mb-1"
                                                                            onClick={() => deleteRecord(row.quiz_id)}
                                                                        >
                                                                            Delete
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })
                                            }


                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    {
                        records.length < 1 && (
                            <div className="text-center">No row found</div>
                        )
                    }
                    {
                        records.length > 0 && (
                            <button className="btn btn-primary btn-md" onClick={() => handleSubmit()}>Update Question Order</button>
                        )
                    }
                </CardBody>
            </Card>



        </React.Fragment >
    );
};

export default TrainingSectionsQuiz;
