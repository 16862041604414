import React, { Component, Fragment } from "react"
import { Row, Col, Card, CardBody, CardTitle, Form, Label, Button, Modal, ModalHeader, Input, ModalBody, ModalFooter } from "reactstrap"
import Breadcrumb from "../../components/Common/Breadcrumb"
import ReactDatatable from '@ashvin27/react-datatable';

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet"
import axios from "axios"
import { AvForm, AvField, AvInput, AvFeedback, AvGroup } from "availity-reactstrap-validation"
import { getPages, getPermission, getRoles, isAllowed, uInfo, uRole, uToken } from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select"
import { Link } from "react-router-dom";
import ProfileSettings from "../Authentication/profile-sidebar";
import Loader from "react-loader";
import WLDomain from "./WLDomain";


export default class Branding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            record: {},
            loader: true
        }
    }


    componentDidMount() {
        this.getData();
    }

    getData = () => {
        fetch(`${ApiUrl}` + 'get/branding', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + uToken()
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    if (response.data.record != null) {
                        this.setState({
                            record: response.data.record,
                        });
                    }
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }

            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    handleSubmit = async (event) => {
        this.setState({ loader: false });
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            if (key == 'domain') {
                submitData.append('domain', value + '.profitcoach.app');
            } else {
                submitData.append(key, value);
            }
        }
        fetch(`${ApiUrl}` + "update/branding", {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                if (data.status === true) {
                    this.getData();
                    // document.getElementById("selection-myform").reset();
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
                this.setState({ loader: true });
            })
            //Then with the error genereted...
            .catch((error) => {
                this.setState({ loader: true });

                console.error("Error:", error);
            });
    };
    changeHandler = (e) => {
        if (e.target.files[0].type.match('image.*')) {
            const preview = document.querySelector('#preview_img');
            var reader = new FileReader();
            reader.onload = () => {
                preview.src = reader.result;
            };
            reader.readAsDataURL(e.target.files[0]);
        } else {
            toast('Olny image allowed', { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

        }
    };
    changeHandlerFavicon = (e) => {
        if (e.target.files[0].type.match('image.*')) {
            const preview = document.querySelector('#preview_img_favicon');
            var reader = new FileReader();
            reader.onload = () => {
                preview.src = reader.result;
            };
            reader.readAsDataURL(e.target.files[0]);
        } else {
            toast('Olny image allowed', { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

        }
    };
    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="2" className="pl-0">
                        <ProfileSettings menuOpen={false} props={this.props} />
                    </Col>
                    <Col lg="10">
                        <Loader loaded={this.state.loader}></Loader>
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <form onSubmit={this.handleSubmit} method="post"
                                            encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                                            <Row>
                                                <Col md="12">
                                                    <div className="form-group mb-3">
                                                        <label>Brand/Business Name</label>
                                                        <input
                                                            name="brand_name"
                                                            label="Brand Nane"
                                                            defaultValue={this.state.record.brand_name}
                                                            className="form-control"
                                                            placeholder="Enter brand name"
                                                            required
                                                        />
                                                    </div>
                                                </Col>
                                                {/* <Col md="12">
                                                    <div className="form-group mb-3">
                                                        <label>Domain Name</label>
                                                        <div className="input-group  mt-2 mb-3">
                                                            <input
                                                                required
                                                                defaultValue={this.state.record.domain}
                                                                pattern="^[a-z](-?[a-z])*$"                                                               
                                                                placeholder="example , ex-example-ex , e.g..."
                                                                className="form-control" name="domain" />
                                                            <div className="input-group-text">.profitcoach.app</div>
                                                        </div>
                                                    </div>
                                                </Col> */}
                                                <Col md="6">
                                                    <div className="form-group mb-3">
                                                        <label>Logo</label>
                                                        <input
                                                            name="logo"
                                                            label="Select logo"
                                                            accept="image/*"
                                                            className="form-control"
                                                            type="file"
                                                            onChange={(e) => this.changeHandler(e)}
                                                        />
                                                    </div>
                                                    <img id="preview_img" width={250} src={this.state.record.logo} className="img img-thumbnail mb-1" />
                                                </Col>
                                                <Col md="6">
                                                    <div className="form-group mb-3">
                                                        <label>Favicon</label>
                                                        <input
                                                            name="favicon"
                                                            label="Select favicon"
                                                            accept="image/*"
                                                            className="form-control"
                                                            type="file"
                                                            onChange={(e) => this.changeHandlerFavicon(e)}
                                                        />
                                                    </div>

                                                    <img id="preview_img_favicon" width={80} src={this.state.record.favicon} className="img img-thumbnail mb-1" />
                                                </Col>
                                                <Col md="2">
                                                    <div className="mt-3">
                                                        <div className="button-items">
                                                            <Button
                                                                className="btn-md"
                                                                color="primary"
                                                                type="submit"
                                                                disabled={!this.state.loader}
                                                            >
                                                                Submit
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <WLDomain />
                        </Row>
                    </Col>
                </Row>
            </React.Fragment >
        )
    }
}