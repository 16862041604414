import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
    Container,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation";
import { bcrypt, convertPhoneFormat, uInfo, uToken } from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select"

import $ from "jquery";
import { Link } from "react-router-dom";
window.jQuery = $;
window.$ = $;
require("formBuilder");
require("jquery-ui-sortable");
require('formBuilder/dist/form-render.min.js')

export default class ClientContract extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.clientId != '' ? this.props.clientId : '',
            record: {
                contract_id: ''
            },
            emails: [],
            contracts: [],
            phoneNumbers: [],
            tokens: [],
            notificationEmail: {
                cc_email: '',
                token: '',
                subject: '',
                body: `Hello  {first_name},
                Please fill out the form by clicking on the {profile_url} .
                Thanks `,
                email_notify: false,
                sms_notify: false
            },
            contractSelect: {},
            formModal: false,
            prevContracts: []
        };
    }

    componentDidMount() {
        if (this.state.clientId != '') {
            this.getData();
        }
        this.getFormData();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.id != this.props.id) {
            this.setState({
                id: this.props.id != '' ? this.props.id : '',
                record: {}, notificationEmail: {
                    cc_email: '',
                    token: '',
                    subject: '',
                    body: ''
                }
            }, () => this.getData())
        }
    }



    getFormData = () => {
        fetch(`${ApiUrl}` + "assign/contract/formdata", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {

                if (response.status === true) {
                    var tokens_group = [
                        { label: 'Default Field', options: response.data.tokens.default_fields },
                        { label: 'Custom Field', options: response.data.tokens.custom_fields }
                    ];

                    this.setState({
                        tokens: tokens_group,
                        emails: response.data.emails,
                        contracts: response.data.contracts,
                        phoneNumbers: response.data.phoneNumbers,
                    });
                } else {
                              
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    getData = () => {

        fetch(`${ApiUrl}` + "get/client/contract", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },

            body: JSON.stringify({ id: this.state.id },
            ),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        prevContracts: response.data.records,
                    });

                } else {
                              
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    handleSubmit = async (event, values) => {

        if (this.state.id) {
            values.client_id = this.state.id ? this.state.id : -1;
        }
        fetch(`${ApiUrl}` + "assign/contract", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    this.form && this.form.reset();
                    this.props.cancelSlider();
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    getAlert = () => {
        this.form && this.form.reset();
    }

    showForm = () => {

        var contract = this.state.contractSelect.metadata;

        $(function ($) {
            var fbRender = document.getElementById('fb-render'),
                formData = contract

            var formRenderOpts = {
                formData,
                dataType: 'json'
            };

            $(fbRender).formRender(formRenderOpts);
        });
        this.setState({
            formModal: true
        })
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col className="col-12 CompanyDetail ">
                        <Card>
                            <AvForm
                                onValidSubmit={this.handleSubmit}
                                // model={this.state.record}
                                ref={(c) => (this.form = c)}
                                className="needs-validation"
                            >
                                <ModalBody className="row">

                                    <div className="col-md-12">
                                        <AvField
                                            name="contract_id"
                                            label="Client Contracts"
                                            className="form-control"
                                            value={this.state.record.contract_id}
                                            type="select"
                                            required
                                            onChange={(e) => {
                                                this.setState(prevState => {
                                                    let record = Object.assign({}, prevState.record);
                                                    record.contract_id = e.target.value
                                                    return { record };
                                                });

                                                let contract = this.state.contracts.find(x => x.contract_id == e.target.value);
                                                this.setState({
                                                    contractSelect: contract
                                                });

                                                this.setState(prevState => {
                                                    let notificationEmail = Object.assign({}, prevState.notificationEmail);
                                                    notificationEmail.subject = contract.name
                                                    return { notificationEmail };
                                                });
                                            }}
                                        >
                                            <option value={''}>Select Contract</option>
                                            {
                                                this.state.contracts.map((row, i) => {
                                                    return <option datac={row} value={row.contract_id} key={i}>{row.name}</option>
                                                })
                                            }
                                        </AvField>

                                    </div>
                                    {
                                        this.state.record.contract_id != '' && (
                                            <>
                                                <button type="button" onClick={() => this.showForm()}
                                                    className="btn btn-link text-left mb-3">View Contract Form</button>
                                                <div className="col-md-12 mb-1">
                                                    <Select
                                                        getOptionLabel={option => option.label}
                                                        getOptionValue={option => option.key}
                                                        // value={options.filter(({ id }) => id === this.state.country_code)}
                                                        isMulti={false}
                                                        options={this.state.tokens}
                                                        classNamePrefix="select2-selection"
                                                        name="country_id"
                                                        placeholder="Select Token"
                                                        onChange={(e) => {
                                                            this.setState(prevState => {
                                                                let notificationEmail = Object.assign({}, prevState.notificationEmail);
                                                                notificationEmail.token = `{${e.key}}`;
                                                                return { notificationEmail };
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <button onClick={() =>
                                                        this.setState(prevState => {
                                                            let notificationEmail = Object.assign({}, prevState.notificationEmail);
                                                            notificationEmail.body = this.state.notificationEmail.token + '' + this.state.notificationEmail.body;
                                                            return { notificationEmail };
                                                        })}
                                                        type="button" className="btn btn-primary">Insert Body</button>
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <button onClick={() =>
                                                        this.setState(prevState => {
                                                            let notificationEmail = Object.assign({}, prevState.notificationEmail);
                                                            notificationEmail.subject = this.state.notificationEmail.token + '' + this.state.notificationEmail.subject;
                                                            return { notificationEmail };
                                                        })}
                                                        type="button" className="btn btn-primary">Insert Subject</button>
                                                </div>

                                                <div className="mb-3">
                                                    <AvField
                                                        className="form-control"
                                                        type="text"
                                                        label="Subject"
                                                        name="subject"
                                                        placeholder="Subject"
                                                        required
                                                        value={this.state.notificationEmail.subject}
                                                        onChange={(e) => this.setState(prevState => {
                                                            let notificationEmail = Object.assign({}, prevState.notificationEmail);
                                                            notificationEmail.subject = e.target.value;
                                                            return { notificationEmail };
                                                        })}
                                                    />
                                                </div>


                                                <div className="mb-3">
                                                    <AvField
                                                        className="form-control"
                                                        type="textarea"
                                                        label="Email Body"
                                                        name="body"
                                                        placeholder="Enter body"
                                                        required
                                                        value={this.state.notificationEmail.body}
                                                        rows={3}
                                                        onChange={(e) => this.setState(prevState => {
                                                            let notificationEmail = Object.assign({}, prevState.notificationEmail);
                                                            notificationEmail.body = e.target.value;
                                                            return { notificationEmail };
                                                        })}
                                                    />

                                                </div>
                                                <div className="mb-3">
                                                    <AvGroup check>
                                                        <AvInput checked={this.state.notificationEmail.email_notify} onChange={(e) => this.setState(prevState => {
                                                            let notificationEmail = Object.assign({}, prevState.notificationEmail);
                                                            notificationEmail.email_notify = e.target.checked;
                                                            return { notificationEmail };
                                                        })}
                                                            type="checkbox" name="email_notify" />
                                                        <Label check htmlFor="email_notify">Email Notify</Label>
                                                    </AvGroup>
                                                </div>

                                                {
                                                    this.state.notificationEmail.email_notify === true && (
                                                        <div className="mb-3">
                                                            <AvField
                                                                className="form-control"
                                                                type="text"
                                                                label="From Email"
                                                                name="email_from"
                                                                required
                                                                placeholder="Enter from email"
                                                            />
                                                        </div>
                                                    )
                                                }


                                                <div className="mb-3">
                                                    <AvGroup check>
                                                        <AvInput checked={this.state.notificationEmail.sms_notify} onChange={(e) => this.setState(prevState => {
                                                            let notificationEmail = Object.assign({}, prevState.notificationEmail);
                                                            notificationEmail.sms_notify = e.target.checked;
                                                            return { notificationEmail };
                                                        })}
                                                            type="checkbox" name="sms_notify" />
                                                        <Label check htmlFor="sms_notify">Sms Notify</Label>
                                                    </AvGroup>
                                                </div>

                                                {
                                                    this.state.notificationEmail.sms_notify === true && (
                                                        <div className="mb-3">
                                                            <AvField
                                                                className="select form-control"
                                                                type="select"
                                                                name="sms_from"
                                                                label="From Number"
                                                                required
                                                            >
                                                                <option value=""> Select Phone Number </option>
                                                                {
                                                                    this.state.phoneNumbers.map((row, i) => {
                                                                        return <option key={i} value={row.phone_number}>{convertPhoneFormat(row.phone_number)}</option>
                                                                    })
                                                                }
                                                            </AvField>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    }


                                </ModalBody>
                                <ModalFooter>
                                    <button
                                        className="btn btn-primary w-md waves-effect waves-light"
                                        type="submit"
                                    >
                                        Assign Contracts
                                    </button>
                                </ModalFooter>
                            </AvForm>


                            <p className="btn btn-link text-left">Showing Assigned Lead Contracts</p>
                            <CardBody>
                                {
                                    this.state.prevContracts && this.state.prevContracts.map((row, i) => {
                                        return (
                                            <div className="note activity-info">
                                                <div className="activity-body">
                                                    <div className="activity-bubble">
                                                        <div className="activity-content">
                                                            <div className="activity_time">
                                                                <div className="ActivityTitle">
                                                                    {
                                                                        row.linked_user_id == 0 ?
                                                                            <Link target={'_blank'} to={`/complete/profile?u=${bcrypt(row.client_id)}&meta=${bcrypt(row.id)}&c=${bcrypt(row.contract_id)}`}>
                                                                                <span>{row.contract.name}</span>
                                                                            </Link>
                                                                            :

                                                                            row.submitted_dated == "0000-00-00 00:00:00" ?

                                                                                <Link
                                                                                    type="button"
                                                                                    color="info"
                                                                                    className="btn btn-link btn-sm mb-1 mr-5"
                                                                                    // target={'_blank'}

                                                                                    to={{
                                                                                        pathname: "/form/details",
                                                                                        search: `?u=${bcrypt(row.client_id)}&meta=${bcrypt(row.id)}&c=${bcrypt(row.contract_id)}`,
                                                                                        state: { from: true }
                                                                                    }}
                                                                                // to={`/form/details?u=${bcrypt(row.client_id)}&meta=${bcrypt(row.id)}&c=${bcrypt(row.contract_id)}`}

                                                                                >
                                                                                    <span>{row.contract.name}</span>
                                                                                </Link>

                                                                                :
                                                                                <Link
                                                                                    title="View"
                                                                                    type="button"
                                                                                    color="info"
                                                                                    className="btn btn-link btn-sm mb-1 mr-5"
                                                                                    to="#"
                                                                                // to={`/show/form?u=${bcrypt(row.client_id)}&meta=${bcrypt(row.id)}&c=${bcrypt(row.contract_id)}`}
                                                                                >
                                                                                    <span>{row.contract.name}</span>
                                                                                </Link>

                                                                    }
                                                                </div>
                                                                <div className="view-date">
                                                                    {row.submitted_dated == "0000-00-00 00:00:00" ?
                                                                        row.assign_dated
                                                                        :
                                                                        <Link
                                                                            title="View"
                                                                            type="button"
                                                                            color="info"
                                                                            className="btn btn-link btn-sm mb-1 mr-5"
                                                                            to={{
                                                                                pathname: "/show/form",
                                                                                search: `?u=${bcrypt(row.client_id)}&meta=${bcrypt(row.id)}&c=${bcrypt(row.contract_id)}`,
                                                                                state: { from: true }
                                                                            }}
                                                                        // to={`/show/form?u=${bcrypt(row.client_id)}&meta=${bcrypt(row.id)}&c=${bcrypt(row.contract_id)}`}
                                                                        >
                                                                            <span className="fas fa-eye"></span>
                                                                        </Link>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }


                            </CardBody>

                        </Card>
                    </Col>

                    <Modal
                        isOpen={this.state.formModal}
                        fade={true}
                        toggle={() => this.setState({ formModal: false })}
                        zIndex={9999999}
                        size={'lg'}
                        closeButton

                    >
                        <div className="modal-head top-heading">
                            <h2>{this.state.contractSelect.name}</h2>
                            <button onClick={() => { this.setState({ formModal: false }) }} className="btn btn-link text-white">
                                <i className="fas fa-times"></i>
                            </button>
                        </div>
                        <ModalBody>
                            <div id="fb-render"></div>


                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="danger"
                                onClick={() => this.setState({ formModal: false })}
                            >
                                Close
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Row>
            </React.Fragment >
        );
    }
}
