import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom";
import { ApiUrl } from "../../config";
import Context from './Context';
import swal from "sweetalert";
const Box9 = (props) => {
    const params = React.useContext(Context);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        fetch(`${ApiUrl}` + "update/builder/setting/" + params.user_id, {
            method: "POST",
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    params.goToBox(params.currentBox + 1);
                    params.getBuilderSetting();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    return (
        <div>
            <div className="box-center-align mt-3">
                <div className="SmallContaner my-4">
                    <div className="d-flex align-items-center gap-2 StepHeading">
                        <div className="StepNumber">5 <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">Section Information</h4>
                    </div>
                    <div className="page-content-box">
                        <form method="post" onSubmit={handleSubmit}
                            encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                            <div className="WizredForm mt-3">
                                <div className="border-top border-bottom border-left border-right p-4">
                                    <div className="TitleWizred d-flex align-items-center gap-2">
                                        <span className="fs-5"> b.</span> <h5>Blog.</h5>
                                    </div>


                                    <div className="radio-box mt-3">
                                        <label>Do you want a "blog" section on your website?</label>
                                        <br />
                                        <div class="form-check form-check-inline">
                                            <input name="has_blog" defaultChecked={params.setting.has_blog == 'Yes' ? true : false} class="form-check-input" type="radio" id="inlineRadio1" value="Yes" />
                                            <label class="form-check-label" for="inlineRadio1">Yes</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input name="has_blog" defaultChecked={params.setting.has_blog == 'No' ? true : false} class="form-check-input" type="radio" id="inlineRadio2" value="No" />
                                            <label class="form-check-label" for="inlineRadio2">No</label>
                                        </div>
                                    </div>
                                </div>



                            </div>
                            <button type="submit" className="btn btn-lg btn-primary mt-2"> Next &nbsp;<i className="fas fa-check"></i></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Box9;