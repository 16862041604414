import React from 'react';

const Lobby = ({
  username,
  handleUsernameChange,
  roomName,
  handleRoomNameChange,
  handleSubmit,
  from
}) => {
  return (
    <div className='JoinVideoChat'>
      <form onSubmit={handleSubmit}>
        <div className={from == 'host' ? 'd-none' : ''}>
          {/* <label htmlFor="name">:</label> */}
          <input
            type="text"
            id="field"
            className='form-control' placeholder='Enter Your Name'
            value={username}
            onChange={handleUsernameChange}
            required
          />
        </div>

        <div className='d-none'>
          <label htmlFor="room">Room name:</label>
          <input

            type="text"
            id="room"
            value={roomName}
            onChange={handleRoomNameChange}
            required
          />
        </div>

        <div className='text-center w-100'><button className='btn btn-primary  mt-3' type="submit">Join Room</button></div>
      </form>
    </div>
  );
};

export default Lobby;