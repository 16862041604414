import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
// Redux
import { withRouter, Link, useHistory } from "react-router-dom";
import Avatar from "react-avatar";
// users
import user1 from "../../../assets/images/users/user.png";
import useToken, { logout, switchUserList, uInfo, useSwitchUser, uToken } from "../../../useToken";
import { ApiUrl } from "../../../config";
import { toast, ToastContainer } from "react-toastify";

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState("Admin");
  const history = useHistory();
  const userdata = JSON.parse(localStorage.getItem("authUser"));

  const switchUser = switchUserList();

  const { setToken } = useToken();
  const { setSwitchUser } = useSwitchUser();

  useEffect(() => {
    // if (localStorage.getItem("authUser")) {
    //   if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    //     const obj = JSON.parse(localStorage.getItem("authUser"));
    //     setusername(obj.displayName);
    //   } else if (
    //     process.env.REACT_APP_DEFAULTAUTH === "fake" ||
    //     process.env.REACT_APP_DEFAULTAUTH === "jwt"
    //   ) {
    //     const obj = JSON.parse(localStorage.getItem("authUser"));
    //     setusername(obj.username);
    //   }
    // }
  }, [props.success]);


  const UserToggle = (user_id) => {
    fetch(`${ApiUrl}` + 'login/choice', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 'user_id': user_id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((res) => {
        if (res.status === true) {
          localStorage.setItem("theme", res.data.theme);
          setToken(res.data.user);
          setSwitchUser(res.data.users);
          window.location.href = '/dashboard';
          // toast.success(res.message, { theme: "colored" });
        } else {
          toast.error(res.message, { theme: "colored" });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
      });
  }


  const Logout = () => {
    fetch(`${ApiUrl}` + "logout", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${uToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((res) => {
        localStorage.setItem("theme", res.data.theme);
        logout();
        window.location.href = '/login';
        //history.push("/login");
        // if (res.status === true) {
        //   logout();
        //   toast.success(res.message, { theme: "colored" });
        //   history.push("/login");
        // } else {
        //   toast.error(res.message, { theme: "colored" });
        // }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          {
            props.from == 1 ? (
              <i className="fas fa-cog" />
            ) : (
              <Avatar name={userdata.name} size="25" round={true} />
            )
          }
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link tag="a" to="/settings/profile" className="dropdown-item">
            {" "}
            <i className="ti-settings font-size-17 text-muted align-middle me-1" />
            {"Settings"}{""}
          </Link>
          {
            switchUser.map((user, i) => {
              if (uInfo().user_id != user.user_id) {

                return (
                  <button
                    key={i}
                    onClick={() => UserToggle(user.user_id)}
                    className="dropdown-item"
                  >
                    <i className="mdi mdi-account-switch font-size-17 text-muted align-middle me-1" />
                    <span>Switch To {user.type == "WlAdmin" ? "Admin" : user.type}</span>
                  </button>
                )
              }
            })
          }

          <a
          href="http://intercom.help/profit-coach/en"
          target="_blank"
            className="dropdown-item"
          >
            <i className="mdi mdi-information font-size-17 text-muted align-middle me-1 " />
            <span>{"Help Center"}</span>
          </a>

          {/* <div className="dropdown-divider" /> */}
          <button
            onClick={() => Logout()}
            className="dropdown-item text-danger"
          >
            <i className="mdi mdi-power font-size-17 text-muted align-middle me-1 text-danger" />
            <span>{"Logout"}</span>
          </button>
        </DropdownMenu>
      </Dropdown>
      <ToastContainer />

    </React.Fragment>

  );
};

export default ProfileMenu;
