import React, { useState, useCallback } from 'react';
import { ApiUrl } from '../../config';
import { uInfo, uToken, uuidv4 } from '../../useToken';
import Lobby from './Lobby';
import Room from './Room';

const ClientVideoChat = ({ sendMessage, loginStatus, userId, chatInit }) => {
    const [username, setUsername] = useState(uInfo().name);
    const [roomName, setRoomName] = useState(uuidv4());
    const [token, setToken] = useState(null);
    const [page8Class, setpage8Class] = useState("");

    const handleUsernameChange = useCallback(event => {
        setUsername(event.target.value);
    }, []);

    const handleRoomNameChange = useCallback(event => {
        setRoomName(event.target.value);
    }, []);

    const handleSubmit = useCallback(

        async event => {
            event.preventDefault();
            sendMessage('video', roomName,userId,chatInit)
            const data = await fetch(ApiUrl + 'video/token', {
                method: 'POST',
                body: JSON.stringify({
                    identity: username,
                    room: roomName
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + uToken(),
                }
            }).then(res => res.json());
            setToken(data.data.token);
            setpage8Class("slide-in");
        },
        [roomName, username]
    );

    const handleLogout = useCallback(event => {
        setToken(null);
    }, []);

    let render;
    if (token) {
        render = (
            page8Class == "slide-in" && (
                <div
                    className={`page3 w-75 ${page8Class}`}
                >
                    <div className='from-coach'>
                        <div className="modal-head top-heading">
                            <h2>Video Call </h2>
                            <button
                                onClick={() => {
                                    setToken(null);
                                    setpage8Class("slide-out2");
                                }}
                                className="btn btn-link text-white"
                            >
                                <i className="fas fa-times"></i>
                            </button>
                        </div>
                        <Room roomName={roomName} token={token} handleLogout={handleLogout} />
                    </div>
                </div>
            )

        );
    } else {
        if (loginStatus == 'yes') {
            render = (
                <Lobby
                    username={username}
                    roomName={roomName}
                    handleUsernameChange={handleUsernameChange}
                    handleRoomNameChange={handleRoomNameChange}
                    handleSubmit={handleSubmit}
                />
            );
        } else {
            render = (
                <></>
            )
        }
    }
    return render;
};

export default ClientVideoChat;