import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap"
import Helmet from "react-helmet"
import { ApiUrl, AttachementsUrl, ProductName } from "../../config";
import { Link, useLocation, useParams } from "react-router-dom";
import { uInfo, uToken, dcrypt } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
const ClientFormShow = (props) => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const search = props.location.search;
    // const query = queryString.parse(search);
    const u = new URLSearchParams(search).get('u');
    const c = new URLSearchParams(search).get('c');
    const m = new URLSearchParams(search).get('meta');
    useEffect(() => {
        getData();
        window.onafterprint = function () {
            window.location.reload()
        }
    }, []);


    const getData = (queryString = "", data) => {
        fetch(`${ApiUrl}` + "get/contract/" + dcrypt(c), {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: dcrypt(u), meta: dcrypt(m) }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setData(response.data.records);
                    setLoading(false);
                } else {
                     toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const CreateCustomFieldTableUI = () => {
        return data.form_data_array.map((row, i) => {
            if (row.type == "paragraph") {
                return <tr key={i} className={`mb-3 ${row.name}`}>
                    <td colSpan={2}>
                        <div dangerouslySetInnerHTML={{ __html: row.label }}></div>
                    </td>
                </tr>
            } else if (row.type == "header") {
                return <tr key={i} className={`mb-3 ${row.name}`}>
                    <td colSpan={2}>
                        <p className={row.subtype}>{row.label}</p>
                    </td>
                </tr>
            } else if (row.type == "file") {
                return <tr></tr>

            } else {
                return <tr key={i} className={`mb-3 ${row.name}`}>
                    <td>
                        {row.label}
                    </td>
                    <td>{data.client_response[row.name]} </td>
                </tr>
            }
        })
    }




    return (
        <>
            {
                !loading && (
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2">
                            <div className="page-title-box d-flex space-between">
                                <h4 className="mb-3"> Contract {data.form_res.name}  </h4>
                                <div className="">
                                    <Button
                                        type="button"
                                        color="secondary"
                                        className="mr-3"
                                        onClick={() => window.print()}
                                    >
                                        <i className="fas fa-download"></i>
                                    </Button>
                                    <Button
                                        type="button"
                                        color="primary"
                                        onClick={() => props.location.state ? props.location.state.from ? props.history.goBack() : props.history.push('/client/profile') : props.history.push('/client/profile')}
                                    >
                                        Back
                                    </Button>
                                </div>
                            </div>

                            <Card>
                                <CardBody>
                                    <CardTitle>{data.form_res.name}</CardTitle>

                                    <table className="table" id="print-data">
                                        <tbody>
                                            {CreateCustomFieldTableUI()}
                                            <tr>
                                                <td>IP Address</td>
                                                <td>{data.complete_response.ip_address}</td>
                                            </tr>
                                            <tr>
                                                <td>Submission Date	</td>
                                                <td>{data.complete_response.submitted_dated}</td>
                                            </tr>
                                            {
                                                (data.form_res.signature == 'Y') && (
                                                    <tr>
                                                        <td>Signature</td>
                                                        <td>
                                                            {
                                                                data.client_response.signature_type == 'drwa_it' ?
                                                                    <img className="img-thumbnail" src={`${AttachementsUrl + '/' + data.client_response.digital_signature_image_url}`} />
                                                                    : (data.client_response.signature_type == 'type_it') ?

                                                                        <div className="outer_type_it">
                                                                            <div className="type_it">{data.client_response.digital_signature}</div>

                                                                        </div>
                                                                        :
                                                                        <>
                                                                        </>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </CardBody>
                            </Card>
                        </div>

                    </div>
                )
            }
        </>
    )

}

export default ClientFormShow;
