import React, { Component, Fragment, useState } from "react"
import { Link } from "react-router-dom";
import { ApiUrl, ServerIP } from "../../config";
import Context from './Context';
import swal from "sweetalert";
import { uInfo } from "../../useToken";
const Box3 = (props) => {
    const params = React.useContext(Context);
    // const [domainType, setDomainType] = useState(params.setting.domain_type);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            if (key == 'domain' && params.setting.domain_type == 'sub') {
                submitData.append('domain', value + '.profitcoach.app');
            } else {
                submitData.append(key, value);
            }
        }

        if(uInfo().user_id == 2){
            submitData.append('edited_by', 0);
        }else{
            submitData.append('edited_by', 1);
        }

        fetch(`${ApiUrl}` + "update/builder/setting/" + params.user_id, {
            method: "POST",
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    params.goToBox(params.currentBox + 1);
                    params.getBuilderSetting();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    return (
        <div>
            <div className="box-center-align mt-3">
                <div className="SmallContaner my-4">
                <div className="d-flex align-items-center gap-2 StepHeading">
                        <div className="StepNumber">1 <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">Contact Information</h4>
                    </div>
                    <form method="post" onSubmit={handleSubmit} encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                        <div className="border-top border-bottom border-left border-right p-4 mt-3">
                            <div className="page-content-box">
                                <div className="TitleWizred d-flex align-items-center gap-2">
                                    <span className="fs-5"> c.</span> <h5>Domain Setting</h5>
                                </div>
                                <div className="WizredForm mt-4">
                                    <div className="form-group">
                                        <label>What is your website domain?</label>
                                        <div className="domain-selection px-3 mt-2">
                                            <div className="form-check mb-3 col-md-12">
                                                <input className="form-check-input mb-2" type="radio" name="domain_type" id="domain_type"
                                                    value="sub"
                                                    required
                                                    onChange={() => params.updateSetting('domain_type', 'sub')}
                                                    defaultChecked={params.setting.domain_type == 'sub' ? true : false} />
                                                <label className="form-check-label" htmlFor="domain_type">Default domain</label>
                                                {
                                                    params.setting.domain_type == 'sub' && (
                                                        <div className="input-group mt-2 mb-3  col-md-6">
                                                            <input
                                                                required
                                                                defaultValue={params.setting.domain ?? ''}
                                                                // onChange={(e) => this.setState({ domain: e.target.value })}
                                                                placeholder="example"
                                                                className="form-control no-border px-0" name="domain" />
                                                            <div className="input-group-text">profitcoach.app</div>
                                                        </div>
                                                    )
                                                }
                                            </div>

                                            <div className="form-check mb-3 col-md-12">
                                                <input className="form-check-input mb-2" type="radio" name="domain_type"
                                                    defaultChecked={params.setting.domain_type == 'master' ? true : false}
                                                    id="domain_type1" value="master"
                                                    onChange={() => params.updateSetting('domain_type', 'master')} />
                                                <label className="form-check-label" htmlFor="domain_type1">Add your own domain</label>
                                                {
                                                    params.setting.domain_type == 'master' && (
                                                        <div className="row">
                                                            <div className="form-check mb-3 col-md-6">
                                                                <input
                                                                    required
                                                                    placeholder="www.example.com"
                                                                    defaultValue={params.setting.domain ?? ''}
                                                                    // onChange={(e) => this.setState({ domain: e.target.value })}
                                                                    className="form-control no-border px-0" name="domain" />
                                                                <p>Note: Please make sure to have either an A record pointing to <span className="text-danger">{ServerIP}</span></p>
                                                            </div>

                                                            <div className="form-check mb-3 col-md-6">
                                                                <p>
                                                                    <i className="fas fa-question"> </i> Help
                                                                </p>
                                                                <p><span><i class="fa fa-chevron-right"></i> Step 1</span> You must setup an NS "A" record to our server IP {ServerIP}</p>

                                                                <p><span><i class="fa fa-chevron-right"></i> Step 2</span>  After completing step 1, please submit to admin for final configuration.</p>

                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>

                                        {/* <div class="input-group">
                                        <input name="domain" defaultValue={params.setting.domain ?? ''}
                                            className="form-control no-border px-0"
                                            type="text" placeholder="https://"></input>
                                        <div class="input-group-text">.profitcoach.app</div>
                                    </div> */}
                                    </div>

                                    <div className="form-group mt-4">
                                        <label>What is your domain registrar? <br />
                                            <small>I.e. where did you purchase your domain? GoDaddy, Google, Siteground, etc.</small>
                                        </label>

                                        <input name="domain_registrar" defaultValue={params.setting.domain_registrar ?? ''}
                                            className="form-control no-border px-0"
                                            type="text" placeholder="Enter"></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-lg btn-primary mt-2"> Next &nbsp;<i className="fas fa-check"></i></button>
                    </form>
                </div>
            </div>
        </div >
    );
};

export default Box3;