import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom";
import { ApiUrl, AttachementsUrl } from "../../config";
import Context from './Context';
import swal from "sweetalert";
const Box7 = (props) => {
    const params = React.useContext(Context);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        fetch(`${ApiUrl}` + "update/builder/setting/" + params.user_id, {
            method: "POST",
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    params.goToBox(params.currentBox + 1);
                    params.getBuilderSetting();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const changeHandlerImg = (e, id) => {
        if (e.target.files[0].type.match('video.*') || e.target.files[0].type.match('image.*')) {
            const preview = document.querySelector('#' + id);
            console.log(preview)
            var reader = new FileReader();
            reader.onload = () => {
                preview.src = reader.result;
            };
            reader.readAsDataURL(e.target.files[0]);
        } else {
            swal({
                title: 'Olny video allowed',
                icon: "warning",
            });
        }
    };
    return (
        <div>
            <div className="box-center-align PopUpBox mt-3">
                <div className="SmallContaner my-4">
                    <div className="d-flex align-items-center gap-2 StepHeading">
                        <div className="StepNumber">3 <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">Graphic Uploads / Selections</h4>
                    </div>
                    <form method="post" onSubmit={handleSubmit}
                        encType="multipart/form-data" className="form-horizontal" id="selection-myform">

                        <div className="border-top border-bottom border-left border-right p-4 mt-3">
                            <div className="page-content-box">
                                <div className="TitleWizred d-flex align-items-center gap-2">
                                    <span className="fs-5"> d.</span> <h5>Select your theme color and pallet for your website</h5>
                                </div>

                                <div className="WizredForm">
                                    <div className="form-group">
                                        {/* <label>Select Theme</label> */}
                                        <div class="container parent themeSelection">
                                            <div class="row">
                                                <div class='col-lg-4 col-sm-4 col-md-4'>
                                                    <div className="theme-img justify-content-center">
                                                        <input onChange={() => params.updateSetting('theme_color', 'blue.jfif')} type="radio" name="theme_color" defaultChecked={params.setting.theme_color == 'blue.jfif' ? true : false} id="theme_color_img1" class="d-none imgbgchk" value="blue.jfif" />
                                                        <label for="theme_color_img1">
                                                            <div className="hero-img-area">
                                                                <img src={`${AttachementsUrl}/default/images/theme-color/blue.jfif`} alt="Image 1" />
                                                            </div>
                                                            <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                            </div>
                                                            <div className="img-footer">
                                                                <label className="img-box">A</label>
                                                                <p>Blue</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class='col-lg-4 col-sm-4 col-md-4'>
                                                    <div className="theme-img justify-content-center">
                                                        <input onChange={() => params.updateSetting('theme_color', 'yellow.jfif')} type="radio" name="theme_color" defaultChecked={params.setting.theme_color == 'yellow.jfif' ? true : false} id="theme_color_img2" class="d-none imgbgchk" value="yellow.jfif" />
                                                        <label for="theme_color_img2">
                                                            <div className="hero-img-area">
                                                                <img src={`${AttachementsUrl}/default/images/theme-color/yellow.jfif`} alt="Image 1" />
                                                            </div>
                                                            <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                            </div>
                                                            <div className="img-footer">
                                                                <label className="img-box">B</label>
                                                                <p>Yellow</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class='col-lg-4 col-sm-4 col-md-4'>
                                                    <div className="theme-img justify-content-center">
                                                        <input onChange={() => params.updateSetting('theme_color', 'red.jfif')} type="radio" name="theme_color" defaultChecked={params.setting.theme_color == 'red.jfif' ? true : false} id="theme_color_img3" class="d-none imgbgchk" value="red.jfif" />
                                                        <label for="theme_color_img3">
                                                            <div className="hero-img-area">
                                                                <img src={`${AttachementsUrl}/default/images/theme-color/red.jfif`} alt="Image 1" />
                                                            </div>
                                                            <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                            </div>
                                                            <div className="img-footer">
                                                                <label className="img-box">C</label>
                                                                <p>Red</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div class='col-lg-4 col-sm-4 col-md-4'>
                                                    <div className="theme-img justify-content-center">
                                                        <input onChange={() => params.updateSetting('theme_color', 'green.png')} type="radio" name="theme_color" defaultChecked={params.setting.theme_color == 'green.png' ? true : false} id="theme_color_img4" class="d-none imgbgchk" value="green.png" />
                                                        <label for="theme_color_img4">
                                                            <div className="hero-img-area">
                                                                <img src={`${AttachementsUrl}/default/images/theme-color/green.png`} alt="Image 1" />
                                                            </div>
                                                            <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                            </div>
                                                            <div className="img-footer">
                                                                <label className="img-box">D</label>
                                                                <p>Green</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div class='col-lg-4 col-sm-4 col-md-4'>
                                                    <div className="theme-img justify-content-center">
                                                        <input onChange={() => params.updateSetting('theme_color', 'gray.png')} type="radio" name="theme_color" defaultChecked={params.setting.theme_color == 'gray.png' ? true : false} id="theme_color_img5" class="d-none imgbgchk" value="gray.png" />
                                                        <label for="theme_color_img5">
                                                            <div className="hero-img-area">
                                                                <img src={`${AttachementsUrl}/default/images/theme-color/gray.png`} alt="Image 1" />
                                                            </div>
                                                            <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                            </div>
                                                            <div className="img-footer">
                                                                <label className="img-box">E</label>
                                                                <p>Gray</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div class='col-lg-4 col-sm-4 col-md-4'>
                                                    <div className="theme-img justify-content-center">
                                                        <input onChange={() => params.updateSetting('theme_color', 'slate.png')} type="radio" name="theme_color" defaultChecked={params.setting.theme_color == 'slate.png' ? true : false} id="theme_color_img6" class="d-none imgbgchk" value="slate.png" />
                                                        <label for="theme_color_img6">
                                                            <div className="hero-img-area">
                                                                <img src={`${AttachementsUrl}/default/images/theme-color/slate.png`} alt="Image 1" />
                                                            </div>
                                                            <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                            </div>
                                                            <div className="img-footer">
                                                                <label className="img-box">F</label>
                                                                <p>Slate</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                {/* /// */}
                                                <div class='col-lg-4 col-sm-4 col-md-4'>
                                                    <div className="theme-img justify-content-center">
                                                        <input onChange={() => params.updateSetting('theme_color', 'beige.png')} type="radio" name="theme_color" defaultChecked={params.setting.theme_color == 'beige.png' ? true : false} id="theme_color_img7" class="d-none imgbgchk" value="beige.png" />
                                                        <label for="theme_color_img7">
                                                            <div className="hero-img-area">
                                                                <img src={`${AttachementsUrl}/default/images/theme-color/beige.png`} alt="Image 1" />
                                                            </div>
                                                            <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                            </div>
                                                            <div className="img-footer">
                                                                <label className="img-box">G</label>
                                                                <p>Beige</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div class='col-lg-4 col-sm-4 col-md-4'>
                                                    <div className="theme-img justify-content-center">
                                                        <input onChange={() => params.updateSetting('theme_color', 'navy.png')} type="radio" name="theme_color" defaultChecked={params.setting.theme_color == 'navy.png' ? true : false} id="theme_color_img8" class="d-none imgbgchk" value="navy.png" />
                                                        <label for="theme_color_img8">
                                                            <div className="hero-img-area">
                                                                <img src={`${AttachementsUrl}/default/images/theme-color/navy.png`} alt="Image 1" />
                                                            </div>
                                                            <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                            </div>
                                                            <div className="img-footer">
                                                                <label className="img-box">H</label>
                                                                <p>Navy</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div class='col-lg-4 col-sm-4 col-md-4'>
                                                    <div className="theme-img justify-content-center">
                                                        <input onChange={() => params.updateSetting('theme_color', 'orange.png')} type="radio" name="theme_color" defaultChecked={params.setting.theme_color == 'orange.png' ? true : false} id="theme_color_img9" class="d-none imgbgchk" value="orange.png" />
                                                        <label for="theme_color_img9">
                                                            <div className="hero-img-area">
                                                                <img src={`${AttachementsUrl}/default/images/theme-color/orange.png`} alt="Image 1" />
                                                            </div>
                                                            <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                            </div>
                                                            <div className="img-footer">
                                                                <label className="img-box">I</label>
                                                                <p>Orange</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div class='col-lg-4 col-sm-4 col-md-4'>
                                                    <div className="theme-img justify-content-center">
                                                        <input onChange={() => params.updateSetting('theme_color', 'Other')} type="radio" name="theme_color" defaultChecked={!['blue.jfif', 'yellow.jfif', 'red.jfif', 'green.png', 'gray.png', 'slate.png', 'beige.png', 'navy.png', 'orange.png'].includes(params.setting.theme_color) ? true : false} id="theme_color_img10" class="d-none imgbgchk" value="other" />
                                                        <label for="theme_color_img10">
                                                            <div className="hero-img-area">
                                                                <img src={`${AttachementsUrl}/default/images/theme-color/other.png`} alt="Image 1" />
                                                            </div>
                                                            <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                            </div>
                                                            <div className="img-footer">
                                                                <label className="img-box">J</label>
                                                                <p>Custom Color</p>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    {
                                                        !['blue.jfif', 'yellow.jfif', 'red.jfif', 'green.png', 'gray.png', 'slate.png', 'beige.png', 'navy.png', 'orange.png'].includes(params.setting.theme_color) && (
                                                            <>

                                                                <div className="d-flex align-items-center mt-2">
                                                                    <label className="w-100">Background Color</label>

                                                                    <input type="color" required name="theme_color" className="form-control no-border px-0 w-50"
                                                                        defaultValue={params.setting.theme_color}
                                                                        placeholder="Enter Hex code e.g #cecece"
                                                                    />

                                                                </div>

                                                                <div className="d-flex align-items-center mt-2">
                                                                    <label className="w-100">Text Color</label>

                                                                    <input type="color" required name="theme_text_color" className="form-control no-border px-0 w-50"
                                                                        defaultValue={params.setting.theme_text_color ?? '#fff'}
                                                                        placeholder="Enter Hex code e.g #cecece"
                                                                    />

                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>


                        <button type="submit" className="btn btn-lg btn-primary mt-2"> Next &nbsp;<i className="fas fa-check"></i></button>
                    </form>
                </div>
            </div>
        </div >
    );
};

export default Box7;

