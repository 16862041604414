import React, { useEffect, useRef, useState } from "react";
import {
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";

import Helmet from "react-helmet";
// availity-reactstrap-validation

import { withRouter } from "react-router-dom";

import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import { Link } from "react-router-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { ApiUrl } from "../../config";
import { bcrypt, dcrypt, uToken } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import Survey from "./survey.js";
import Summary from "./summary.js";
import Results from "./results";
import Agreements from "./agreements.js";
import Priorities from "./priorities.js";
import Reports from "./reports.js";
import Guides from "./guides.js";
import Roi from "./roi.js";
import Implementation from "./implementation";
import "./style.css";
import $ from "jquery";
import Loader from "react-loader";
import { currencies } from "../../common/Common-Currency.js";
import { Accordion } from "react-bootstrap";

require("highcharts/modules/exporting")(Highcharts);

const Assesments = (props) => {
    const ref = useRef();
    const [params] = useState(props.match.params);
    const defaultPages = [
        "priorities",
        "implementation",
        "reports",
        "roi",
        "agreements",
        "guides",
        "coaching",
    ];
    const [sidebar, setSideBar] = useState([]);
    const [records, setRecords] = useState({ report: [] });
    const [dafault_summary, setDafaultSummary] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [currency, setCurrency] = useState("");
    const [steps, setSteps] = useState([]);

    const [AssessmentTab, setAssessmentTab] = useState("1");
    const initMenu = () => {
        //console.log(defaultPages.indexOf(props.match.params.slug),'ddddddd');
        const pathName = window.location.pathname;
        new MetisMenu("#side-menu");
        const ul = document.getElementById("side-menu");
        const items = ul.getElementsByTagName("a");
        for (let i = 0; i < items.length; ++i) {
            if (pathName === items[i].pathname) {
                if (items[i].parentNode.parentElement.tagName == "LI") {
                    items[i].parentNode.parentElement.firstChild.classList.add("active");
                }
                items[i].parentNode.classList.add("d-block");
                items[i].classList.add("active");
                // break;
            } else {
                items[i].classList.remove("active");
            }
        }
    };

    React.useEffect(() => {
        initMenu();
    }, [props]);

    React.useEffect(() => {
        initMenu();
    }, [sidebar]);

    useEffect(() => {
        getModules();
        getSteps();
    }, []);

    const findSymbol = (code) => {
        let currency = currencies[code];
        if (currency) {
            setCurrency(currency.symbol);
        }
    };
    const getModules = () => {
        fetch(`${ApiUrl}` + "get/assessment/modules", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({
                id: dcrypt(params.id),
                slug: props.match.params.slug,
            }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    setSideBar(response.data.records);
                    setRecords(response.data);
                    setDafaultSummary(response.data.report);
                    findSymbol(response.data.assessment.currency_code);
                    setLoading(true);

                    setChartData({
                        chartData: {
                            chart: {
                                type: "column",
                            },
                            title: {
                                text: "1 YEAR IMPACTS",
                            },
                            xAxis: {
                                categories: ["Profit", "Revenue"],
                                crosshair: true,
                            },
                            yAxis: {
                                min: 0,
                                title: {
                                    text: "",
                                },
                            },

                            series: [
                                {
                                    name: "Current",
                                    data: [
                                        JSON.parse(response.data.report[4].amount),
                                        JSON.parse(response.data.report[1].amount),
                                    ],
                                },
                                {
                                    name: "1 Year",
                                    data: [
                                        JSON.parse(response.data.report[5].amount),
                                        JSON.parse(response.data.report[3].amount),
                                    ],
                                },
                            ],
                        },
                    });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                    setLoading(true);
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
                setLoading(true);
            });
    };
    const defaultSummary = async () => {
        getModules();
    };


    const data = {
        columns: [
            ["Current", 150, 80, 70, 152, 250, 95],
            ["Years", 200, 130, 90, 240, 130, 220],
        ],
        type: "bar",
        colors: { Current: "#4499d7", Years: "#408f4d" },
    };

    const [options, setChartData] = React.useState({
        chartData: {
            chart: {
                type: "column",
            },
            title: {
                text: "1 YEAR IMPACTS",
            },
            xAxis: {
                categories: ["Profit", "Revenue", "Valuation"],
                crosshair: true,
            },
            yAxis: {
                min: 0,
                title: {
                    text: "",
                },
            },
            series: [
                {
                    name: "Current",
                    data: [0.0, 0.0, 0.0],
                },
                {
                    name: "1 Year",
                    data: [0.0, 0.0, 0.0],
                },
            ],
        },
    });

    function NumberWithCommas(x) {
        let y = Math.trunc(x);
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const toggleAssTab = (index, tab) => {
        setAssessmentTab(index);
        props.history.push("/assessment/" + params.id + "/" + tab);
    };


    const getSteps = () => {
        fetch(`${ApiUrl}` + "get/implementation/steps", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({
                key: props.match.params.key,
            }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    // setSideBar(response.data.records);
                    setSteps(response.data.lists);
                    setLoading(true);
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                    setLoading(true);
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
                setLoading(true);
            });
    };
    return (
        <React.Fragment>

            <Row className="mb-4">
                <Col lg="2" className="assessment">
                    <div className="assessment-vertical-menu">
                        <div data-simplebar className="h-100">
                            <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
                                <div id="sidebar-menu">
                                    <ul className="metismenu list-unstyled" id="side-menu">
                                        {sidebar.map((row, i) => {
                                            return (
                                                <li className="all-item" key={"s-" + i}>
                                                    {row.has_child == 0 ? (
                                                        <Link
                                                            to={`/assessment/${params.id}/${row.key}`}
                                                            className={`waves-effect dropdown-btn i_${i}`}
                                                            onClick={() => {
                                                                $(".dropdown-btn").removeClass("active");
                                                                $(".all-item").removeClass("active");
                                                                $(".dropdown-container").removeClass("d-block");
                                                                $(".i_" + i).addClass("active");
                                                            }}
                                                        >
                                                            <span>{row.label}</span>
                                                        </Link>
                                                    ) : row.has_child == 1 ? (
                                                        <>
                                                            <button
                                                                className={`dropdown-btn i_${i}`}
                                                                onClick={() => {
                                                                    $(".dropdown-btn").removeClass("active");
                                                                    $(".dropdown-container").removeClass(
                                                                        "d-block"
                                                                    );
                                                                    $(".i_" + i).addClass("active");
                                                                    $(".k_" + i).addClass("d-block");
                                                                }}
                                                            >
                                                                {row.label}
                                                                {/* <i className="fa fa-caret-down"></i> */}
                                                            </button>
                                                            <div className={`dropdown-container k_` + i}>
                                                                {row.child &&
                                                                    row.child.map((c, k) => {
                                                                        return (
                                                                            <Link
                                                                                key={i + "---" + k}
                                                                                to={`/assessment/${params.id}/${c.key}`}
                                                                                className="waves-effect"
                                                                            >
                                                                                <span>{c.label}</span>
                                                                            </Link>
                                                                        );
                                                                    })}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </SimpleBar>
                        </div>
                    </div>
                </Col>
                <Col lg="10">
                    <Row>
                        <Col lg="12">

                            <section className="breakthrough d-flex space-between sub-nav-bg">
                                <h5 className="mb-0">
                                    {records.assessment && records.assessment.module == 3
                                        ? "Profit Acceleration Deep Dive 40"
                                        : ""}
                                </h5>
                                <h6 className="mb-0">
                                    {records.assessment && records.assessment.name}
                                </h6>
                            </section>

                            <div className="card card-body mb-2 p-0">
                                <Nav tabs className="border-0">
                                    <NavItem>
                                        <NavLink
                                            className={AssessmentTab == 1 ? "active" : ""}
                                            onClick={() => {
                                                toggleAssTab("1", "summary");
                                            }}
                                        >
                                            Summary
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={AssessmentTab == 2 ? "active" : ""}
                                            onClick={() => {
                                                toggleAssTab("2", "priorities");
                                            }}
                                        >
                                            Priorities
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={AssessmentTab == 3 ? "active" : ""}
                                            onClick={() => {
                                                toggleAssTab("3", "implementation");
                                            }}
                                        >
                                            Implementation
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={AssessmentTab == 4 ? "active" : ""}
                                            onClick={() => {
                                                toggleAssTab("4", "reports");
                                            }}
                                        >
                                            Reports
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={AssessmentTab == 5 ? "active" : ""}
                                            onClick={() => {
                                                toggleAssTab("5", "roi");
                                                props.history.push("/assessment/" + params.id + "/roi");
                                            }}
                                        >
                                            ROI
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={AssessmentTab == 6 ? "active" : ""}
                                            onClick={() => {
                                                toggleAssTab("6", "agreements");
                                            }}
                                        >
                                            Agreements
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={AssessmentTab == 7 ? "active" : ""}
                                            onClick={() => {
                                                toggleAssTab("7", "guides");
                                            }}
                                        >
                                            Strategies
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>

                            <section className="top-section card card-body">
                                <Row>
                                    <Col xs="5">
                                        <h4>
                                            TOTAL PROFIT IMPACT:{" "}
                                            <span className="pull-right">
                                                {" "}
                                                {dafault_summary.length > 0
                                                    ? `${currency}` +
                                                    NumberWithCommas(dafault_summary[0].amount) +
                                                    " / " +
                                                    dafault_summary[0].percentage +
                                                    "%"
                                                    : "$0/0%+"}
                                            </span>{" "}
                                        </h4>
                                    </Col>

                                    <Col xs="7"></Col>

                                    <Col xs={8}>
                                        <Row>
                                            {dafault_summary.map((row, i) => {
                                                return (
                                                    <>
                                                        {i > 0 ? (
                                                            <div key={i} className="col-md-6">
                                                                <h6 className="text-transform-capitalize">
                                                                    {row.type_key
                                                                        .replace("n_y", "5_y")
                                                                        .replaceAll("_", " ")}
                                                                    <span className="pull-right">
                                                                        {" "}
                                                                        {currency}
                                                                        {NumberWithCommas(row.amount)}
                                                                    </span>
                                                                </h6>
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </>
                                                );
                                            })}
                                        </Row>
                                    </Col>
                                </Row>
                            </section>
                            <Loader loaded={loading}></Loader>

                            <section className="mt-3 ">
                                <Row>


                                    <Col xs="8">
                                        <div className="survey card card-body">
                                            <h2 className="mb-3 text-transform-captalize">
                                                {params.key && params.key.replaceAll("_", " ")} Action Steps
                                            </h2>

                                            <p>Below are the suggested {params.key && params.key.replaceAll("_", " ")} action steps - click to expand.</p>

                                            <div className="details">
                                                <Accordion className="border-0">
                                                    {
                                                        steps.map((row, i) => {
                                                            return (

                                                                <Accordion.Item eventKey={i}>
                                                                    <Accordion.Header>{row.title}</Accordion.Header>
                                                                    <Accordion.Body>

                                                                        <div dangerouslySetInnerHTML={{ __html: row.description }} />

                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            )
                                                        })
                                                    }
                                                </Accordion>
                                            </div>

                                            <div className="mt-3">
                                                <Link to={`/assessment/${params.id ? params.id : bcrypt(0)}/implementation`} className="btn btn-sm mr-5 btn-info pull-right">Go to Implementation Calendar</Link>
                                                <Link to={`/assessment/${params.id ? params.id : bcrypt(0)}/summary`} className="btn btn-sm mr-5 btn-info pull-right">Go to Summary</Link>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md="4" className="">
                                        <div className="card card-body">
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={options.chartData}
                                            />
                                        </div>
                                    </Col>


                                </Row>
                            </section>
                        </Col>
                    </Row>
                    <Row></Row>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Assesments;
