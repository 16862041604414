import React from "react";
import drive from "../../assets/images/resource-drive/myDrive.png";
import search from "../../assets/images/resource-drive/search.png";
import filters from "../../assets/images/resource-drive/filters.png";
import interrogation from "../../assets/images/resource-drive/interrogation.png"
import settings from "../../assets/images/resource-drive/settings.png"
import list from "../../assets/images/resource-drive/list.png";
import "../../assets/css/resource-drive/Navbar.css";
import ResourcesContext from "./ResourcesContext";

export default function Navbar() {
  const params = React.useContext(ResourcesContext);

  return (
    <>
      <nav>
        <ul className="m-0 justify-content-between">
          <li>
            <a href='/'>
              <div id="icon">
                <i className="fas fa-hdd h3 text-dark"></i>
                <p className="h1 text-dark">Resource Center</p>
              </div>
            </a>
          </li>

          <li>
            <div id="searchBar">
              <button>
                <img src={search} alt="Reload page" className="opacity" />
              </button>

              {
                ['users'].includes(params.driveType) && (
                  <input type="text" id="myInput" onKeyUp={(e) => params.getResourcesUsersLists(e.target.value)}  placeholder={`Search in ${params.type} lists`} title="Type in a name" />
                )
              }

              {
                 ["my-resources", "shared-with-me",'resources','drive'].includes(params.driveType)  && (
                  <input type="text" id="myInput" onKeyUp={(e) => params.getResourcesLists(e.target.value)}  placeholder="Search in Resources" title="Type in a name" />
                )
              }

              {/* <button>
                <img src={filters} alt="Reload page" className="opacity" />
              </button> */}
            </div>
          </li>

         
        </ul>
      </nav>
    </>
  );
}
