import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import axios from "axios";
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
} from "availity-reactstrap-validation";
import {
  dcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import ResourcesSidebar from "./resources-sidebar";
export default class ResourceCategoryCreateUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      record: {},
      page_name: this.props.match.params.id
        ? "Edit Resource Product"
        : "Create Resource Product",
      roles: [],
      packages: [],
      resellers: [],
      image: "",
      path: "",
    };
  }

  componentDidMount() {
    if (this.state.id) {
      this.getData();
    }
  }
  changeHandler = (e) => {
    if (e.target.files[0].type.match('image.*')) {
      const preview = document.querySelector('#preview');
      let image = [];
      var reader = new FileReader();
      reader.onload = () => {
        image.push(reader.result);
        preview.src = reader.result;
      };
      reader.readAsDataURL(e.target.files[0]);
      this.setState({
        image: image,
      });
    } else {
      this.setState({
        image: "",
      });
      toast('Olny image allowed', { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
    }
  };
  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/resource/Category", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          console.log(response, "DEBUGGING");
          this.setState({
            record: response.data.record,
            image: response.data.record.image,
            path: response.data.path
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = async (event, values) => {
    if (this.state.id) {
      values.id = this.state.id ? this.state.id : -1;
    }
    if (this.state.image != '') {
      values.image = this.state.image;
      fetch(`${ApiUrl}` + "edit/resource/category", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify(values),
      })
        .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((data) => {
          if (data.status === true) {
            if (this.state.id == null) {
              this.form && this.form.reset();
            }
            this.props.history.goBack();
            // this.getData();
            //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });

          } else {
            toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        //Then with the error genereted...
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      toast('Image Name: is required', { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
    }
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {this.state.page_name}
            Tables
          </title>
        </Helmet>

        <Row>
          <Col lg="2" className="pl-0">
            <ResourcesSidebar menuOpen={false} props={this.props} />
          </Col>
          <Col lg="10">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4> {this.state.page_name} </h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> {this.state.page_name} </Link>
                    </li>
                  </ol>
                </div>
              </Col>
              <Col sm={6}>
                <div className="page-title-box text-align-right">
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => this.props.history.goBack()}
                  >
                    Back
                  </Button>
                </div>
              </Col>
            </Row>
            <ToastContainer />
            <Row>
              <Col className="col-12">
                <Card>
                  <AvForm
                    onValidSubmit={this.handleSubmit}
                    ref={(c) => (this.form = c)}
                    className="needs-validation"
                  >
                    <ModalBody>
                      <div className="mb-3">
                        <AvField
                          name="title"
                          label="Title"
                          value={this.state.record.title}
                          className="form-control"
                          placeholder="Enter Title"
                          required
                          validate={{
                            // required: { value: true, errorMessage: 'This field is invalid' },
                            // pattern: { value: '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$', errorMessage: 'This field is invalid' },
                            maxLength: { value: 100, errorMessage: 'This field is invalid' }

                          }}
                        />
                      </div>

                      <div className="mb-3">
                        Image Name: {this.state.record.image}
                        <AvInput
                          name="image"
                          //  value={this.state.record.image}
                          label="Image"
                          className="form-control"
                          type="file"
                          accept="image/*"
                          onChange={(e) => this.changeHandler(e)}
                        />
                        <div className="m-1">
                          <img id="preview" src={this.state.path} height="200" alt="Image preview" />
                        </div>
                      </div>
                      {this.state.id && (
                        <div className="mb-3">
                          <AvField
                            className="select form-control"
                            type="select"
                            id="s"
                            name="status"
                            label="Status"
                            required
                            value={this.state.record.status}
                            onChange={(e) => this.setState(prevState => {
                              let record = Object.assign({}, prevState.record);
                              record.status = e.target.value;
                              return { record };
                            })}
                          >
                            <option value={""}> Select Status </option>
                            <option value={"Active"}> Active </option>
                            <option value={"Inactive"}> Inactive </option>
                          </AvField>
                        </div>
                      )}
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        type="button"
                        color="secondary"
                        onClick={() => this.props.history.goBack()}
                      >
                        Cancel
                      </Button>
                      <button
                        className="btn btn-primary waves-effect waves-light"
                        type="submit"
                      >
                        Submit
                      </button>
                    </ModalFooter>
                  </AvForm>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
