import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  ModalBody,
  CardBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader
} from "reactstrap";

import Helmet from "react-helmet";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import swal from 'sweetalert';
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

export default class NotificaionSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: this.props.from ? this.props.from : '',
      task: {
        confirmation_email: false,
        confirmation_sms: false,
        reminder: false,
        email_body: '',
        reminders: [
          {
            interval: '1',
            type: 'day_before',
            sms_template_id: '',
            email_template_id: ''

          }
        ],
      },
      event: {
        confirmation_email: false,
        confirmation_sms: false,
        reminder: false,
        email_body: '',
        reminders: [
          {
            interval: '1',
            type: 'day_before',
            sms_template_id: '',
            email_template_id: ''
          }
        ],
      },
      action_step: {
        confirmation_email: false,
        confirmation_sms: false,
        sms_template_id: '',
        email_template_id: '',
      },
      tab: "3",
      modal: false,
      template: {
        id: '',
        title: '',
        body: '',
        master_type: 'event',
        type: 'sms',
        token: ''
      },
      templates: [],
      loading: true,

    };
    this.columns = [
      {
        text: "Template Id#",
        key: "id",
      },
      {
        text: "Title",
        key: "title",
      },
      {
        text: "Template For",
        key: "master_type",
      },
      {
        text: "Type",
        key: "type",
      },
      {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          return (
            <Fragment>
              <button onClick={() => {
                this.setState({
                  modal: true,
                  template: record
                })

              }} type="button" className="btn btn-link btn-sm mr-5">
                <i className="fas fa-edit"></i>
              </button>
              <button type="button" className="btn btn-link  btn-sm"
                onClick={this.deleteRecord.bind(this, record, index)}>
                <i className="fas fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];

    this.addField = this.addField.bind(this);
    this.handleChange = this.handleChange.bind(this);

  }

  componentDidMount() {
    this.getData();
    this.getTemplate();
  }

  deleteRecord = (record, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "delete/notification/template/" + record.id, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.status === true) {
                this.getTemplate();
              } else {
                swal({
                  title: response.message,
                  icon: "warning",
                });
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });

        }
      });
  };


  getData = () => {
    fetch(`${ApiUrl}` + "get/notification/settings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          if (response.data.record.task) {
            this.setState({
              task: response.data.record.task
            })
          } else {
            this.setState(prevState => {
              let task = Object.assign({}, prevState.task);
              return { task };
            })
          }
          if (response.data.record.event) {
            this.setState({
              event: response.data.record.event
            })
          } else {
            this.setState(prevState => {
              let event = Object.assign({}, prevState.event);
              return { event };
            })
          }
          if (response.data.record.action_step) {
            this.setState({
              action_step: response.data.record.action_step
            })
          } else {
            this.setState(prevState => {
              let action_step = Object.assign({}, prevState.action_step);
              return { action_step };
            })
          }
          this.setState({
            loading: false
          })

        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getTemplate = () => {
    fetch(`${ApiUrl}` + "get/notification/template", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            templates: response.data.records
          })
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  handleSubmit = async (event, errors, values) => {

    if (errors.length < 1) {

      values.task = JSON.stringify(this.state.task);
      values.event = JSON.stringify(this.state.event);
      values.action_step = JSON.stringify(this.state.action_step);
      fetch(`${ApiUrl}` + "save/notification/settings", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify(values),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data, 'Editing');
          if (data.status === true) {
            swal({
              text: "Action performed successfully.",
              icon: "success",
            });
          } else {
            toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        //Then with the error genereted...
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {

      console.log('not fount');
      this.setState({
        warningAlert: true
      })

    }
  };


  toggle = (index) => {
    if (this.state.tab !== index) {
      this.setState({
        tab: index,
      });
    }
  };

  handleChange = (e) => {
    this.setState(prevState => {
      let template = Object.assign({}, prevState.template);
      template.body = e;
      return { template };
    })
  };

  addField = (data) => {
    this.setState(prevState => ({
      scheduling_forms: [...prevState.scheduling_forms, { [`label`]: data.label, key: data.key, [`alternative_text`]: data.alternative_text, required: false }]
    }));
    this.setState({
      addMore: false
    })
  }

  removeTaskReminder = (index) => {
    let automation = this.state.task;
    automation.reminders.splice(index, 1);
    this.setState(automation)
  }
  removeEventReminder = (index) => {
    let automation = this.state.event;
    automation.reminders.splice(index, 1);
    this.setState(automation)
  }
  removeOptionClick(k, e) {
    let scheduling_forms = [...this.state.scheduling_forms];
    scheduling_forms.splice(k, 1);
    this.setState({ scheduling_forms });
  }

  handleTemplateSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }

    fetch(`${ApiUrl}` + "submit/notification/template", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          swal({
            title: response.message,
            icon: "success",
          });
          this.getTemplate();
          this.closeModal();

        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  closeModal = () => {
    this.setState({
      template: {
        id: '',
        title: '',
        body: '',
        master_type: 'event',
        type: 'sms',
        token: ''
      },
      modal: false
    })
  }

  render() {
    return (
         <div className="mt-1">
            <div className="d-flex align-items-center gap-2 StepHeading">
                <div className="StepNumber">30 <i className="fas fa-arrow-right"></i></div> <h3 className="m-0">Action Step Notifications Settings</h3>
            </div>
            <Row>
            <Col lg="12">
                <Row>
                <Col className="col-12">
                    <Card>
                    <AvForm
                        onSubmit={this.handleSubmit}
                        ref={(c) => (this.form = c)}
                        className="needs-validation"
                    >
                        <TabContent activeTab={this.state.tab}>
                      
                     
                        <TabPane tabId={"3"} className="mt-1">
                            <Row>
                            <Col md={12}>
                                <ModalBody>
                                <Row>
                                    <Col md={12}>
                                        <div className="py-3 px-3">
                                            <div className="d-flex space-between">
                                                <div className="email">
                                                    <div className="d-flex">
                                                    <h3>Email Notification</h3>
                                                    <div className="toggle form-check form-switch form-switch-lg ml-3">
                                                        <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="confirmation_email"
                                                        checked={this.state.action_step.confirmation_email}
                                                        onChange={(e) => {
                                                            this.setState(prevState => {
                                                            let action_step = Object.assign({}, prevState.action_step);
                                                            action_step.confirmation_email = e.target.checked;
                                                            return { action_step };
                                                            })
                                                        }}
                                                        />
                                                    </div>
                                                    </div>
                                                    <p>Send a confirmation email to the client when an action step is assigned.</p>
                                                </div>
                                            </div>

                                        </div>
                                    </Col>
                                    <Col md={11}>
                                        {this.state.action_step.confirmation_email && (
                                            <div className="py-1 px-3">
                                            <AvField
                                                name="email_template_id"
                                                value={this.state.action_step.email_template_id}
                                                className="form-control"
                                                type="select"
                                                onChange={(e) =>
                                                this.setState(prevState => {
                                                    let action_step = Object.assign({}, prevState.action_step);
                                                    action_step.email_template_id = e.target.value
                                                    return { action_step };
                                                })
                                                }
                                            >
                                                <option value={''} >Select Email Template</option>
                                                {
                                                this.state.templates.map((row, i) => {
                                                    if (row.master_type == 'action_step' && row.type == 'email') {
                                                    return (
                                                        <option key={i} value={row.id}>{row.title}</option>
                                                    )
                                                    }
                                                })
                                                }
                                            </AvField>
                                            </div>
                                        )}
                                    </Col>
                                    <Col md={1}>
                                        <div className="float-right">
                                        <button title="Create notification template" onClick={() => this.setState({
                                        modal: true
                                        })} className="btn btn-info" type="button"><i className="mdi mdi-plus"> </i></button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                    <div className="mt-3 px-3">
                                        <div className="d-flex space-between">
                                        <div className="email">
                                            <div className="d-flex">
                                            <h3>Sms Notification</h3>
                                            <div className="toggle form-check form-switch form-switch-lg ml-3">
                                                <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="confirmation_sms"
                                                checked={this.state.action_step.confirmation_sms}
                                                onChange={(e) => {
                                                    this.setState(prevState => {
                                                    let action_step = Object.assign({}, prevState.action_step);
                                                    action_step.confirmation_sms = e.target.checked;
                                                    return { action_step };
                                                    })
                                                }}
                                                />
                                            </div>
                                            </div>
                                            <p>Send sms notification to the client when an action step is assigned.</p>
                                        </div>


                                        </div>

                                    </div>
                                    </Col>
                                    <Col md={11}>
                                        {this.state.action_step.confirmation_sms && (
                                            <div className="px-3 mt-3">
                                            `<AvField
                                                name="sms_template_id"
                                                value={this.state.action_step.sms_template_id}
                                                className="form-control"
                                                type="select"
                                                onChange={(e) =>
                                                this.setState(prevState => {
                                                    let action_step = Object.assign({}, prevState.action_step);
                                                    action_step.sms_template_id = e.target.value
                                                    return { action_step };
                                                })
                                                }
                                            >
                                                <option value={''} >Select Sms Template</option>
                                                {
                                                this.state.templates.map((row, i) => {
                                                    if (row.master_type == 'action_step' && row.type == 'sms') {
                                                    return (
                                                        <option key={i} value={row.id}>{row.title}</option>
                                                    )
                                                    }
                                                })
                                                }
                                            </AvField>`
                                            </div>
                                        )}
                                    </Col>
                                    <Col md={1}>
                                        <div className="float-right">
                                        <button title="Create notification template" onClick={() => this.setState({
                                        modal: true
                                        })} className="btn btn-info" type="button"><i className="mdi mdi-plus"> </i></button>
                                        </div>
                                    </Col>
                                </Row>
                                </ModalBody>
                                <ModalFooter className="d-flex justify-content-start">
                                <button
                                    className="btn btn-primary"
                                    type="submit"
                                >
                                     Ok &nbsp;<i className="fas fa-check"></i>
                                </button>
                                </ModalFooter>
                            </Col>
                            <Col md={6}></Col>
                            </Row>
                        </TabPane>

                    
                        </TabContent>
                    </AvForm>
                    </Card>
                    <Modal
                    isOpen={this.state.modal}
                    fade={true}
                    size="lg"
                    toggle={() => this.closeModal()}
                    >
                    <form method="post" onSubmit={this.handleTemplateSubmit}
                        encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                        <ModalHeader> Notification Template </ModalHeader>
                        <ModalBody>

                        <div className="form-group mb-3">
                            <label htmlFor="title" className="form-label">Title</label>
                            <input placeholder='Title'
                            value={this.state.template.title}
                            required
                            className="form-control"
                            type={'text'}
                            onChange={(e) => {
                                this.setState(prevState => {
                                let template = Object.assign({}, prevState.template);
                                template.title = e.target.value;
                                return { template };
                                })
                            }}
                            name="title" />
                        </div>

                        <div className="row">
                            <div className="col-sm-6">
                            <div className="form-group mb-3">
                                <label htmlFor="master_type" className="form-label">Template For</label>
                                <select name="master_type" required className="form-control" value={this.state.template.master_type}
                                onChange={(e) => {
                                    this.setState(prevState => {
                                    let template = Object.assign({}, prevState.template);
                                    template.master_type = e.target.value;
                                    return { template };
                                    })
                                }}
                                >
                                <option value={'event'}>Event</option>
                                <option value={'task'}>Task</option>
                                <option value={'action_step'}>Action Step</option>
                                </select>
                            </div>
                            </div>
                            {
                            this.state.template.id && (
                                <input name="id" type={'hidden'} className={'form-control'} required value={this.state.template.id} />
                            )
                            }
                            <div className="col-sm-6">
                            <div className="form-group mb-3">
                                <label htmlFor="type" className="form-label">Type</label>
                                <select name="type" required className="form-control" value={this.state.template.type}
                                onChange={(e) => {
                                    this.setState(prevState => {
                                    let template = Object.assign({}, prevState.template);
                                    template.type = e.target.value;
                                    template.body = '';
                                    template.token = '';
                                    return { template };
                                    })
                                }}
                                >
                                <option value={'sms'}>Sms</option>
                                <option value={'email'}>Email</option>
                                </select>
                            </div>
                            </div>

                        </div>

                        <label htmlFor="token" className="form-label">Token</label>
                        <div className="d-flex mb-3 justify-content-between">
                            <div className="form-group w-75 ">
                            <select className="form-control" value={this.state.template.token}
                                onChange={(e) => {
                                this.setState(prevState => {
                                    let template = Object.assign({}, prevState.template);
                                    template.token = e.target.value;
                                    return { template };
                                })
                                }}
                            >
                                <option value={''}>Select Token</option>
                                {
                                this.state.template.master_type == 'task' && (
                                    <>
                                    <option value={'{name}'}>Name</option>
                                    <option value={'{task_title}'}>Task Title</option>
                                    <option value={'{date_time}'}>Date Time</option>

                                    </>
                                )
                                }

                                {
                                this.state.template.master_type == 'event' && (
                                    <>
                                    <option value={'{client_name}'}>Client Name</option>
                                    <option value={'{event_title}'}>Event Title</option>
                                    <option value={'{date_time}'}>Date Time</option>

                                    </>
                                )
                                }

                                {
                                this.state.template.master_type == 'action_step' && (
                                    <>
                                    <option value={'{client_name}'}>Client Name</option>
                                    <option value={'{task_number}'}>Task Number</option>
                                    </>
                                )
                                }
                            </select>
                            </div>
                            <button onClick={() => {
                            this.setState(prevState => {
                                let template = Object.assign({}, prevState.template);
                                template.body = this.state.template.body + ' ' + this.state.template.token;
                                template.token = '';
                                return { template };
                            })
                            }} className="btn btn-primary btn-sm" type="button" >Insert Token</button>
                        </div>

                        <div className="form-group mb-3">
                            {
                            this.state.template.type == 'email' && (
                                <div className="">
                                <SunEditor
                                    setOptions={{
                                    height: 200,
                                    buttonList: [
                                        ['font', 'fontSize', 'bold', 'underline', 'italic', 'fontColor', 'align', 'codeView']
                                    ]
                                    }}
                                    name="body"
                                    onChange={this.handleChange}
                                    setContents={this.state.template.body}
                                />
                                </div>
                            )
                            }

                            {
                            this.state.template.type == 'sms' && (
                                <div className="form-group">
                                <textarea className="form-control" name="body" required rows={5}
                                    value={this.state.template.body}
                                    placeholder='Enter text'
                                    onChange={e => {
                                    this.setState(prevState => {
                                        let template = Object.assign({}, prevState.template);
                                        template.body = e.target.value;
                                        return { template };
                                    })
                                    }}
                                ></textarea>
                                </div>

                            )
                            }
                        </div>

                        </ModalBody>
                        <ModalFooter>
                        <Button
                            color="info"
                        >
                            Submit
                        </Button>
                        <Button
                            color="danger"
                            onClick={() => this.closeModal()}
                        >
                            Cancel
                        </Button>
                        </ModalFooter>
                    </form>
                    </Modal>
                </Col>
                </Row>
            </Col>
            </Row>
        </div>
    );
  }
}
