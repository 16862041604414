import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import Badge from 'react-bootstrap/Badge';
//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import { bcrypt, hasRole, isAllowed, uInfo, uRole, uToken } from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ProfileSettings from "../Authentication/profile-sidebar";
import swal from 'sweetalert';
export default class DatatableTables extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                text: "Sr#",
                key: "sr",
                sortable: false,
            },
            {
                text: "Name",
                key: "name",
                sortable: false,
            },
            {
                text: "Email",
                key: "email",
                sortable: false,
            },
            {
                text: "Domain",
                key: "domain",
                sortable: false,
                cell: (record, index) => {
                    return (
                        <div className="">
                            <a href={`https://${record.domain}`} target={'_blank'} >
                                {record.domain}
                            </a>

                            <a className="ml-3 text-dark" title="Click here for dns setting" href={`https://mxtoolbox.com/SuperTool.aspx?action=a:${record.domain}&run=toolpage`} target={'_blank'}>
                                <i className="fas fa-info"></i>
                            </a>


                        </div>
                    );
                },
            },
            {
                text: "Domain DNS",
                key: "domain_dns",
                sortable: false,
            },
            {
                text: "Domain SSl",
                key: "domain_ssl",
                sortable: false,
            },
            {
                text: "Domain Verified",
                key: "domain_verified",
                sortable: false,
            },
            {
                text: "Status",
                key: "status",
                sortable: false,
                cell: (record, index) => {
                    return (
                        <Fragment>
                            {record.status == 'verified' && (
                                <Badge bg="success">{record.status}</Badge>
                            )}
                            {record.status == 'un-verified' && (
                                <Badge bg="danger">{record.status}</Badge>
                            )}

                            {record.status == 'draft' && (
                                <Badge bg="info">{record.status}</Badge>
                            )}
                        </Fragment>
                    );
                },

            },
            {
                text: "Submission Date",
                key: "created_at",
                sortable: false,
            },
            {
                key: "action",
                text: "Action",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            {/* {
                                record.status == 'un-verified' && (
                                    <button
                                        className="btn btn-primary btn-sm mb-1 mr-3"
                                        onClick={this.VerifyDomain.bind(this, record, index)}>
                                        <i className="fas fa-check"></i>
                                    </button>
                                )
                            } */}

                            <a className="btn btn-link btn-sm mx-1" href={`https://${record.domain}?preview=${bcrypt(record.user_id)}`} target={'_blank'} >
                                <i className="fas fa-eye"></i>
                                {/* {record.domain} */}
                            </a>

                            <button
                                type="button"
                                color="info"
                                className="btn btn-primary btn-sm mb-1 mx-1"
                                onClick={this.changeApprovalUser.bind(this, record, index)}
                                title="Change status"

                            >
                                <i class="fas fa-sync-alt"></i>
                            </button>
                            <button
                                className="btn btn-info btn-sm mb-1 ml-1  mx-1"
                                onClick={() => this.props.history.push('/website/build/' + bcrypt(record.user_id))}>
                                <i className="fas fa-edit"></i>
                            </button>

                            <button
                                className="btn btn-danger btn-sm mb-1 ml-1"
                                onClick={this.deleteRecord.bind(this, record, index)}>
                                <i className="fas fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                },
            }
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            show_filter: true,
            show_pagination: true,
            sort: { column: "total_lead", order: "desc" },
            button: {
                excel: false,
                print: true,
                csv: true,
            },
        };
        this.state = {
            records: [],
            total_pages: 0,
            record: {},
            page_name: "Website ",
            filter: {},
            customDate: true,
            leads: [],
            approvalModal: false,
            approvalUser: {},
            approvalStatus: '',
        };
    }

    componentDidMount() {
        this.getData();
    }

    changeApprovalUser = (record, index) => {
        //console.log(record, 'recordrecordrecord')
        this.setState({
            approvalModal: true,
            approvalUser: record,
            approvalStatus: ''
        })
    }

    changeApprovalStatus = (status) => {
        let record = this.state.approvalUser;

        swal({
            title: "Are you sure?",
            text: 'You want to perform this action',
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "update/domain/status", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                        body: JSON.stringify({ user_id: record.user_id, status: status, id: record.id })
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((data) => {
                            if (data.status === true) {
                                this.getData();
                                this.setState({
                                    approvalUser: {},
                                    approvalModal: false
                                })
                            } else {
                                swal({
                                    title: data.message,
                                    icon: "warning",
                                });
                            }
                        })
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                } else {
                    this.setState({
                        approvalUser: {},
                        approvalModal: false
                    })
                }
            });
    };



    getData = (queryString = "", data) => {
        fetch(`${ApiUrl}` + "get/domain/request" + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                console.log(data, "data");
                if (data.status === true) {
                    this.setState({
                        total_pages: data.data.total,
                        records: data.data.records,
                    });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    deleteRecord = (record, index) => {
        swal({
            title: "Are you sure?",
            text: "All information related to domain will be removed.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {

                    fetch(`${ApiUrl}` + "remove/builder/new/" + record.user_id, {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ user_id: record.user_id }),
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((data) => {
                            if (data.status === true) {
                                this.getData("", this.state.filter);
                            } else {
                                swal({
                                    title: data.message,
                                    icon: "warning",
                                });
                            }
                        })
                        //Then with the error genereted...
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    }

    VerifyDomain = (record, index) => {
        fetch(`${ApiUrl}` + 'verify/websidte/request', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + uToken()
            },
            body: JSON.stringify({ 'id': record.id }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                if (data.status === true) {
                    this.getData();
                    swal({
                        title: 'Action performed successfully',
                        icon: "success",
                    });
                    //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    handleFilterSubmit = async (event, values) => {
        this.setState({
            filter: values,
        });
        this.getData("", values);
    };
    cancelFilter = async () => {
        this.form && this.form.reset();
        this.setState({ filter: {}, customDate: true });
        this.getData("", "");
    };

    tableChangeHandler = (data) => {
        let queryString = Object.keys(data)
            .map((key) => {
                if (key === "sort_order" && data[key]) {
                    return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
                    // return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
                } else {
                    return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
                }
            })
            .join("&");
        this.getData("?" + queryString, this.state.filter);
    };

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="2" className="pl-0">
                        <ProfileSettings menuOpen={false} props={this.props} />
                    </Col>
                    <Col lg="10">
                        <Row>
                            <Col sm={6}>
                                <div className="page-title-box">
                                    <h4> {this.state.page_name} Lists</h4>
                                    <ol className="breadcrumb m-0">
                                        <li key={0} className="breadcrumb-item active">{ProductName}</li>
                                        <li key={1} className="breadcrumb-item">
                                            <Link to="#">{this.state.page_name}  Lists </Link>
                                        </li>
                                    </ol>
                                </div>
                            </Col>

                        </Row>
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody className="pb-0">
                                        <AvForm
                                            onValidSubmit={this.handleFilterSubmit}
                                            ref={(c) => (this.form = c)}
                                            className="needs-validation"
                                        >
                                            <Row>
                                                <Col md="3">
                                                    <div className="mb-0">
                                                        <AvField
                                                            className="select form-control"
                                                            type="select"
                                                            name="date_range"
                                                            label="Date Filter"
                                                            onChange={(e) =>
                                                                e.target.value == "Custom Date"
                                                                    ? this.setState({
                                                                        customDate: false,
                                                                    })
                                                                    : this.setState({
                                                                        customDate: true,
                                                                    })
                                                            }
                                                        >
                                                            <option value=""> All Time </option>
                                                            <option value="Current Week"> Current Week </option>
                                                            <option value="Last Week"> Last Week </option>
                                                            <option value="Current Month"> Current Month </option>
                                                            <option value="Last Month"> Last Month </option>
                                                            <option value="Last 3 Months"> Last 3 Months </option>
                                                            <option value="Last 6 Months"> Last 6 Months </option>
                                                            <option value="Current Year"> Current Year </option>
                                                            <option value="Last Year"> Last Year </option>
                                                            <option value="Custom Date"> Custom Date </option>
                                                        </AvField>
                                                    </div>
                                                </Col>
                                                <Col md="2">
                                                    <div className="mb-0">
                                                        <AvField
                                                            name="start_date"
                                                            label="Start date"
                                                            className="form-control"
                                                            placeholder="Enter Email Address"
                                                            type="date"
                                                            disabled={this.state.customDate}
                                                            required={!this.state.customDate}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="2">
                                                    <div className="mb-0">
                                                        <AvField
                                                            name="end_date"
                                                            label="End date"
                                                            className="form-control"
                                                            placeholder="Enter Email Address"
                                                            type="date"
                                                            disabled={this.state.customDate}
                                                            required={!this.state.customDate}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="3">
                                                    <div className="mb-0">
                                                        <AvField
                                                            className="select form-control"
                                                            type="select"
                                                            id="s"
                                                            name="status"
                                                            label="Status"
                                                        >
                                                            <option value={""}> Select Status </option>
                                                            <option value={"draft"}> Draft </option>
                                                            <option value={"Verified"}> Verified </option>
                                                            <option value={"un-verified"}> Not Verified </option>
                                                        </AvField>
                                                    </div>
                                                </Col>
                                                <Col md="2">
                                                    <div className="button-items mt-2">
                                                        <label className="w-100"></label>
                                                        <Button className="btn-sm" color="primary" type="submit">
                                                            Filter
                                                        </Button>
                                                        <Button
                                                            onClick={() => this.cancelFilter()}
                                                            outline
                                                            color="secondary"
                                                            className="waves-effect btn-sm"
                                                            type="button"
                                                        >
                                                            Reset
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <ToastContainer />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody className="pt-0">

                                        {/* <CardTitle className="h4">Default Datatable </CardTitle> */}
                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.records}
                                            columns={this.columns}
                                            dynamic={true}
                                            total_record={this.state.total_pages}
                                            onChange={this.tableChangeHandler}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Modal
                    isOpen={this.state.approvalModal}
                    fade={false}
                    toggle={() => this.setState({ approvalModal: false })}
                >
                    <ModalBody>
                        <div className="form-group mb-3">
                            <label>Select Status</label>
                            <select onChange={(e) => this.setState({ approvalStatus: e.target.value })} name="from_number" className="form-control" >
                                <option value={''}>Select option</option>
                                <option value={'draft'}>Draft</option>
                                <option value={'verified'}>Verified</option>
                                <option value={'un-verified'}>Un-Verified</option>
                            </select>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.state.approvalStatus != '' ?
                                <Button color="info" onClick={() => this.changeApprovalStatus(this.state.approvalStatus)}>
                                    Yes, Change it
                                </Button>
                                :
                                <Button disabled color="info" >
                                    Yes, Change it
                                </Button>
                        }
                        <Button color="danger" onClick={() => this.setState({ approvalModal: false })}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>

            </React.Fragment>
        );
    }
}
