import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom";
import { ApiUrl } from "../../config";
import Context from './Context';
import swal from "sweetalert";
const Box4 = (props) => {
    const params = React.useContext(Context);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        fetch(`${ApiUrl}` + "update/builder/setting/" + params.user_id, {
            method: "POST",
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    params.goToBox(params.currentBox + 1);
                    params.getBuilderSetting();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    return (
        <div>
            <div className="box-center-align mt-3">
                <div className="SmallContaner my-4">
                <div className="d-flex align-items-center gap-2 StepHeading">
                        <div className="StepNumber">1 <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">Contact Information</h4>
                    </div>
                    <form method="post" onSubmit={handleSubmit} encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                        <div className="border-top border-bottom border-left border-right p-4 mt-3">
                            <div className="page-content-box">
                                <div className="TitleWizred d-flex align-items-center gap-2">
                                    <span className="fs-5"> d.</span> <h5>Website Email Setting</h5>
                                </div>
                                <div className="WizredForm mt-4">
                                    <div className="form-group">
                                        <label>What is the email you would like to use for your website?</label>
                                        <input name="website_email" defaultValue={params.setting.website_email ?? ''} className="form-control no-border px-0" type="text" placeholder="Enter"></input>
                                    </div>
                                    <div className="form-group mt-4">
                                        <label>What is your email hosting provider?
                                            <br />
                                            <small>I.e. GoDaddy, Namecheap, Google, etc.</small>
                                        </label>
                                        <input name="website_email_host" defaultValue={params.setting.website_email_host ?? ''} className="form-control no-border px-0" type="text" placeholder="Enter"></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-lg btn-primary mt-2"> Next &nbsp;<i className="fas fa-check"></i></button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Box4;