import React, { useState, useEffect, useRef } from "react";
import { ApiUrl } from "../../../config";
import Context from './Context';
import swal from "sweetalert";
import { dcrypt, bcrypt, uInfo, uToken } from "../../../useToken";
import {
    Row,
    Col,
    Card,
    Alert,
    CardBody,
    Media,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import {
    Asia,
    Africa,
    America,
    Antartica,
    Atlantic,
    Australia,
    Europe,
    Indian,
    Pacific,
} from "../../../common/data/timezones";
import {
    AvForm,
    AvField,
    AvGroup,
    AvInput,
    AvFeedback,
} from "availity-reactstrap-validation";
import Select from "react-select";

const Box = (props) => {
    const params = React.useContext(Context);
    const [record, setRecord] = useState({});
    const [timeZone_data, setnewzone] = useState(Asia);
    const [timezone, setTimeZone] = useState("");
    const [maintimezone, setMainTimeZone] = useState("");
    const maintimezones = [
        { id: "Asia", label: "Asia" },
        { id: "America", label: "America" },
        { id: "Australia", label: "Australia" },
        { id: "Europe", label: "Europe" },
        { id: "Indian", label: "Indian" },
    ];
    useEffect(() => {
        getData();
    }, []);
    const getData = () => {
        fetch(`${ApiUrl}` + "get/systemForm/resourceInfo", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: params.form_data.id, user_id: uInfo().user_id }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setRecord(response.data.record);
                    if (response.data.record.time_zone != "") {
                        let data = response.data.record.time_zone.split("/");
                        setMainTimeZone({ id: data[0], label: data[0] });
                        setTimeZone({ id: response.data.record.time_zone, label: data[1] });
                        if (data[0] == "Australia") {
                            setnewzone(Australia);
                        } else if (data[0] == "America") {
                            console.log(data[0], "DEBUG1");
                            setnewzone(America);
                        } else if (data[0] == "Asia") {
                            setnewzone(Asia);
                        } else if (data[0] == "Europe") {
                            setnewzone(Europe);
                        } else if (data[0] == "Indian") {
                            setnewzone(Indian);
                        }
                    }
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    function settimezone(e) {
        setMainTimeZone(e);

        if (e.label == "Australia") {
            setnewzone(Australia);
        } else if (e.label == "America") {
            setnewzone(America);
        } else if (e.label == "Asia") {
            setnewzone(Asia);
        } else if (e.label == "Europe") {
            setnewzone(Europe);
        } else if (e.label == "Indian") {
            setnewzone(Indian);
        }
    }

    const handleSubmit = async (event, values) => {
        values.user_id = uInfo().user_id;
        values.id = params.form_data.id;
        fetch(`${ApiUrl}` + "update/system/form", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    let newtoken = {
                        time_zone: response.data.record.time_zone,
                      };
                    
                      const authUser = {
                        ...JSON.parse(localStorage.getItem("authUser")),
                        ...newtoken,
                      };
                      localStorage.setItem("authUser", JSON.stringify(authUser));
                    swal({
                        title: "Action performed successfully",
                        icon: "success",
                    });
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    return (
        <Row>
            <Col>
                <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                        handleSubmit(e, v);
                    }}
                >
                    <div className="d-flex align-items-center gap-2 StepHeading  mt-3">
                        <div className="StepNumber">{params.form_data.id} <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">Time Zone</h4>
                    </div>
                    <div className="p-2 mt-1">
                    <div className="d-flex align-items-center gap-2 StepHeading  mt-3">
                        <h5 className="m-0">Main Time Zone(Country)</h5>
                    </div>
                    <div className="page-content-box">
                        <AvGroup>
                            <label> Please select the main timezone that you wish to be available when customers reach out to you. </label>
                            <div className="TimeZoneMain MainTime">
                                <Select
                                    value={maintimezone}
                                    getOptionLabel={(option) => option.label}
                                    getOptionValue={(option) => option.id}
                                    isMulti={false}
                                    options={maintimezones}
                                    classNamePrefix="select2-selection"
                                    className={`${maintimezone != ""
                                        ? "is-touched is-dirty av-valid"
                                        : "is-touched is-pristine av-invalid is-invalid"
                                        }`}
                                    name="time_zone"
                                    onChange={(e) => {
                                        settimezone(e);
                                    }}
                                />
                            </div>
                            <AvInput
                                type="hidden"
                                required
                                name="time_zone_1"
                                value={maintimezone.id}
                            />
                            <AvFeedback> This is required * </AvFeedback>
                        </AvGroup>
                    </div>
                    <div className="d-flex align-items-center gap-2 StepHeading mt-3">
                        <h5 className="m-0">Time Zone(City)</h5>
                    </div>
                    <div className="page-content-box">
                        <AvGroup>
                            <label> Please select the timezone that you wish to be available when customers reach out to you. </label>
                            <div className="TimeZoneMain">
                                <Select
                                    value={timezone}
                                    getOptionLabel={(option) => option.label}
                                    getOptionValue={(option) => option.id}
                                    isMulti={false}
                                    options={timeZone_data}
                                    classNamePrefix="select2-selection"
                                    className={`${timezone != ""
                                        ? "is-touched is-dirty av-valid"
                                        : "is-touched is-pristine av-invalid is-invalid"
                                        }`}
                                    name="time_zone1"
                                    onChange={(e) => {
                                        setTimeZone(e);
                                    }}
                                />
                            </div>
                            <AvInput
                                type="hidden"
                                required
                                name="time_zone"
                                value={timezone.id}
                            />
                            <AvFeedback> This is required * </AvFeedback>
                        </AvGroup>
                    </div>
                    </div>
                    <button type="submit" className="btn btn-sm btn-primary mt-2"> Ok &nbsp;<i className="fas fa-check"></i></button>

                </AvForm>
            </Col>
        </Row>
    );
};

export default Box;