import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import Badge from 'react-bootstrap/Badge';
//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import { hasRole, isAllowed, uInfo, uRole, uToken } from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ProfileSettings from "../Authentication/profile-sidebar";
import swal from 'sweetalert';

export default class DatatableTables extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                text: "UserId#",
                key: "user_id",
                sortable: false,
            },
            {
                text: "Name",
                key: "name",
                sortable: false,
            },
            {
                text: "Email",
                key: "email",
                sortable: false,
            },
            {
                text: "User Type",
                key: "user_type",
                sortable: false,
            },
            {
                text: "Secondary Profile ID	",
                key: "secondary_profile_sid",
                sortable: false,
            },
            {
                text: "Request Type",
                key: "request_type_desp",
                sortable: false,
            },
            {
                text: "Request Date",
                key: "request_date",
                sortable: false,
            },
            {
                key: "action",
                text: "Action",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <button
                                className="btn btn-link btn-sm"
                                title="View User Profile Data"
                                onClick={() => this.props.history.push('/standard-profile/request/'+ record.th_id)}>
                                <i className="fas fa-eye"></i>
                            </button>
                            |
                            {
                                record.request_type == 0 ? (
                                    <button
                                        className="btn btn-link btn-sm"
                                        title="Add Secondary Business Profile SID"
                                        onClick={() => this.setState({
                                            extendSid: { coach_id: record.coach_id, th_id: record.th_id, request_type: record.request_type },
                                            modal: true
                                        })}>
                                        <i className="fas fa-plus"></i>
                                    </button>
                                ) : (
                                    <button
                                        className="btn btn-link btn-sm"
                                        title="Add US A2P Brand Registration SID"
                                        onClick={() => this.setState({
                                            extendSid: { coach_id: record.coach_id, th_id: record.th_id, request_type: record.request_type },
                                            modal: true
                                        })}>
                                        <i className="fas fa-plus"></i>
                                    </button>
                                )
                            }
                            |
                            <button
                                className="btn btn-link btn-sm"
                                title="Delete this profile request"
                                onClick={this.deleteRecord.bind(this, record, index)}>
                                <i className="fas fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                },
            }
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            show_filter: true,
            show_pagination: true,
            sort: { column: "total_lead", order: "desc" },
            button: {
                excel: false,
                print: true,
                csv: true,
            },
        };
        this.state = {
            records: [],
            total_pages: 0,
            record: {},
            page_name: "Standard Profile Request",
            filter: {},
            customDate: true,
            leads: [],
            modal: false,
            extendSid: {}
        };
    }

    componentDidMount() {
        this.getData();
    }

    changeRequestType = async (event, values) => {
        values.request_type = this.state.extendSid.request_type;
        values.th_id = this.state.extendSid.th_id;
        values.coach_id = this.state.extendSid.coach_id;
        fetch(`${ApiUrl}` + "sync/trusthub/profile", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                              
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }


    deleteRecord = (record, index) => {
        swal({
            title: "Are you sure?",
            text: "You want to remove this record.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "delete/trusthub/profile", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                        body: JSON.stringify({ id: record.th_id }),
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((data) => {
                            if (data.status === true) {
                                this.getData();
                                //    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                            } else {
                                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                            }
                        })
                        //Then with the error genereted...
                        .catch((error) => {
                            console.error("Error:", error);
                        });

                }
            });
    };

    getData = (queryString = "", data) => {
        fetch(`${ApiUrl}` + "get/trusthub/profile/request" + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                console.log(data, "data");
                if (data.status === true) {
                    this.setState({
                        total_pages: data.data.total,
                        records: data.data.records,
                    });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    tableChangeHandler = (data) => {
        let queryString = Object.keys(data)
            .map((key) => {
                if (key === "sort_order" && data[key]) {
                    return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
                    // return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
                } else {
                    return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
                }
            })
            .join("&");
        this.getData("?" + queryString, this.state.filter);
    };

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col lg="2" className="pl-0">
                        <ProfileSettings menuOpen={false} props={this.props} />
                    </Col>
                    <Col lg="10">
                        <Row>
                            <Col sm={6}>
                                <div className="page-title-box">
                                    <h4> {this.state.page_name} Lists</h4>
                                    <ol className="breadcrumb m-0">
                                        <li key={0} className="breadcrumb-item active">{ProductName}</li>
                                        <li key={1} className="breadcrumb-item">
                                            <Link to="#">{this.state.page_name}  Lists </Link>
                                        </li>
                                    </ol>
                                </div>
                            </Col>

                        </Row>

                        <ToastContainer />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>

                                        {/* <CardTitle className="h4">Default Datatable </CardTitle> */}
                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.records}
                                            columns={this.columns}
                                            dynamic={true}
                                            total_record={this.state.total_pages}
                                            onChange={this.tableChangeHandler}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Modal
                            isOpen={this.state.modal}
                            fade={false}
                            toggle={() => this.setState({ extendSid: {}, modal: false })}
                            size={'lg'}
                        >
                            <AvForm
                                onValidSubmit={this.changeRequestType}
                                // model={this.state.record}
                                className="needs-validation"
                            >
                                <ModalBody>

                                    {
                                        this.state.extendSid.request_type == 0 && (
                                            <Row>
                                                <Col md={12}>
                                                    <h3>Add Secondary Business Profile SID
                                                    </h3>
                                                </Col>
                                                <Col md="12" className="mb-3 mt-3">
                                                    <AvField
                                                        name="business_profile_sId"
                                                        label="Secondary Business Profile SID"
                                                        className="form-control "
                                                        placeholder={'Enter Secondary Business Profile SID'}
                                                        type="text"
                                                        required
                                                    />
                                                </Col>
                                            </Row>
                                        )
                                    }

                                    {
                                        this.state.extendSid.request_type == 1 && (
                                            <Row>
                                                <Col md={12}>
                                                    <h3>Add US A2P Brand Registration SID </h3>
                                                </Col>
                                                <Col md="12" className="mb-3 mt-3">
                                                    <AvField
                                                        name="a2p_registration_sid"
                                                        label="US A2P Brand Registration SID"
                                                        className="form-control "
                                                        placeholder={'Enter US A2P Brand Registration SID'}
                                                        type="text"
                                                        required
                                                    />
                                                </Col>
                                            </Row>
                                        )
                                    }

                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" onClick={() => this.setState({ extendSid: {}, modal: false })}>Cancel</Button>
                                    <Button className="Green">Submit</Button></ModalFooter>
                            </AvForm>
                        </Modal>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }
}
