import React, { useState, useEffect, useRef } from "react";
import {
    Row, Col, Card, CardBody, CardTitle, Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Button,
} from "reactstrap"
import Helmet from "react-helmet"
import { ApiUrl, AttachementsUrl } from "../../config";
import { Link, useLocation, useParams } from "react-router-dom";
import { uInfo, uToken, dcrypt } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import * as SignaturePad from 'signature_pad';
import ProposalSignupPackage from "./ProposalSignupPackage";


const ProposalSignatureAccepted = (props) => {
    const [data, setData] = useState({});
    const [relational_id] = useState(props.relational_id ? props.relational_id : 0);
    const [type] = useState(props.type ? props.type : 'signup-proposal');
    const [loading, setLoading] = useState(true);
    const [tab, setTab] = useState("1");
    const [textSig, setTextSig] = useState("");
    const [drawSig, setDrawSig] = useState([]);
    const [imageSig, setImageSig] = useState(null);
    const [signature, setSignature] = useState('Y');
    const [propostType, setProposalType] = useState("signup-proposal");
    const [stripeKey, setStripeKey] = useState("");

    
    const [proposalAcceptedData, setProposalAcceptedData] = useState({
        proposal_data_json: {}
    });
    useEffect(() => {
        setLoading(false);
        getProposal();
    }, []);

    const getProposal = () => {
        console.log(relational_id, 'relational_id')
        fetch(`${ApiUrl}` + "proposal/accepted/data", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ proposal_id: props.proposal_id, type: type, relational_id: relational_id, stripe_user: 2 }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setStripeKey(response.data.stripe_key);
                    setProposalAcceptedData(response.data.proposal_accepted);
                    setProposalType(response.data.proposal_accepted.type);
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const signUpStep = (step) => {
        props.signUpStep("final");
    }

    return (
        <>
            {
                !loading && (
                    <div className="row w-100">
                        <div className="col-md-12">
                            <Card>
                                <CardBody>
                                    <CardTitle style={{ fontWeight: "bold" }}>I, agree to the terms of this agreement and I agree that my typed name below can be used as a digital representation of my signature to that fact</CardTitle>

                                    <div className="row">
                                        {
                                            proposalAcceptedData && proposalAcceptedData.proposal_data_json && proposalAcceptedData.proposal_data_json.signature_type == 'drwa_it' ?
                                                <img className="img-thumbnail" src={`${AttachementsUrl + '/' + proposalAcceptedData.proposal_data_json.digital_signature_image_url}`} />
                                                : (proposalAcceptedData && proposalAcceptedData.proposal_data_json && proposalAcceptedData.proposal_data_json.signature_type == 'type_it') ?

                                                    <div className="outer_type_it">
                                                        <div className="type_it">{proposalAcceptedData.proposal_data_json.digital_signature}</div>

                                                    </div>
                                                    :
                                                    <>
                                                    </>
                                        }
                                    </div>
                                    <CardTitle style={{ marginTop: "12px" }}>Signed on {proposalAcceptedData.new_created_at}</CardTitle>
                                    {/* <button className="btn btn-primary" onClick={() => props.signUpStep("two")}>Next</button> */}

                                    <div id={'FormID1'}>

                                        {
                                            propostType == 'signup-proposal' && (

                                                <ProposalSignupPackage
                                                    package_id={props.package_id}
                                                    proposal_id={props.proposal_id}
                                                    stripe_key={stripeKey}
                                                    signUpStep={signUpStep}
                                                />
                                            )
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        </div>

                    </div>
                )
            }
        </>
    )

}

export default ProposalSignatureAccepted;
