import React, { Component, useEffect } from "react"
import { Container, Row, Col } from "reactstrap"
import { ProductName } from "../../config";
import { uInfo } from "../../useToken";
import Incall from "../../pages/Incall";
export default class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="in-call-cls">
          {( uInfo().type == 'Coach' || uInfo().type == 'CoachPartner') && (
                <Incall />
          )}
        </div>
        <footer className="footer">
          <Container fluid={true}>
            <Row>
              <Col sm={12}>
                © {new Date().getFullYear()} {ProductName}
                <span className="d-none d-sm-inline-block"> </span>
              </Col>
            </Row>
          </Container>
        </footer>
      </React.Fragment>
    )
  }
}
