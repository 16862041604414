import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import SweetAlert from "react-bootstrap-sweetalert"
import Helmet from "react-helmet";
import {
    bcrypt,
    isAllowed,
    uInfo,
    uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-bootstrap";
import swal from 'sweetalert';
import ProfileSettings from "../Authentication/profile-sidebar";

export default class SystemForms extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                text: "Form#",
                key: "id",
            },
            {
                text: "Name",
                key: "name",
            },
            {
                key: "action",
                text: "Action",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <Link
                                type="button"
                                color="info"
                                className="btn btn-link btn-sm mb-1 mr-5"
                                to={
                                    "/system/forms/edit/" + bcrypt(record.id)
                                }
                            >
                                <i className="fas fa-fw  fa-edit"></i>
                            </Link>
                            <button
                                title="Link"
                                type="button"
                                color="info"
                                className="btn btn-link btn-sm mb-1 mr-5"
                                onClick={() => { this.setState({ modal: true, ClientForm: record }) }}
                            >
                                <i className="fas fa-fw  fa-link"></i>
                            </button>
                            {/* <button
                                className="btn btn-link btn-sm mb-1 mr-5"
                                onClick={this.deleteRecord.bind(this, record, index)}
                            >
                                <i className="fas fa-trash"></i>
                            </button> */}
                        </Fragment>
                    );
                }
            },
        ];
        this.config = {
            page_size: 50,
            length_menu: [10, 20, 50, 100],
            show_filter: true,
            show_pagination: false,

        };
        this.state = {
            records: [],
            roles: [],
            total_pages: 0,
            record: {},
            filter: {},
            page_name: "System Forms",
            customDate: true,
            ClientSubcribeModal: false,
            modal: false,
            ClientForm: {},
            currentClient: {
                id: "",
                email_status: "",
                call_status: "",
                groups: [],
                tags: [],
            },
        };
    }
    deleteRecord = (record, index) => {
        swal({
            title: "Are you sure?",
            text: "You want to remove this record.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "delete/systemForms", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                        body: JSON.stringify({ id: record.id }),
                    }).then((response) => response.json())
                        .then((data) => {
                            if (data.status === true) {
                                this.getData();
                            } else {
                                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                            }
                        })
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    };

    componentDidMount() {
        this.getData();
    }

   

    getData = (queryString = "", data) => {
        fetch(`${ApiUrl}` + "systemForms/list" + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {
                    console.log(response, 'debugging');
                    this.setState({
                        total_pages: response.data.total,
                        records: response.data.records,
                        roles: response.data.roles,
                    });

                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };




    FormBuilder(formurl) {

        var iframe = document.createElement('iframe');
        iframe.style = "height:" + 360 + "px; width:100%; border:none";
        iframe.setAttribute('allowTransparency', true);
        iframe.src = formurl;
        // document.body.appendChild(iframe);

        console.log(iframe);
        return iframe;
    }


    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.state.page_name}
                    </title>
                </Helmet>
                <Row>
                    <Col lg="2" className="pl-0">
                        <ProfileSettings menuOpen={false} props={this.props} />
                    </Col>
                    <Col lg="10">
                        <Row>

                            <Col sm={5}>
                                <div className="page-title-box">
                                    <h4>{this.state.page_name}</h4>
                                    <ol className="breadcrumb m-0">
                                        <li key={0} className="breadcrumb-item active">
                                            {ProductName}
                                        </li>
                                        <li key={1} className="breadcrumb-item">
                                            <Link to="#">{this.state.page_name}</Link>
                                        </li>
                                    </ol>
                                </div>
                            </Col>
                            <Col sm={6}>
                            </Col>
                            <Col sm={1}>
                                {/* <div className="page-title-box text-align-right">
                                    {isAllowed(uInfo(), ["can_create"]) && (
                                        <Link
                                            type="button"
                                            color="info"
                                            className="btn btn-info"
                                            to={"/lead/forms/add"}
                                        >
                                            <i className="mdi mdi-plus"> </i>
                                            Create
                                        </Link>
                                    )}
                                </div> */}
                            </Col>
                        </Row>
                        <ToastContainer />
                        <Modal show={this.state.modal}>
                            <Modal.Header>
                                <Modal.Title>Embed Code</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <div className="url">
                                    <a
                                        href={`${this.state.ClientForm.form_url}`}
                                        target="_blank"
                                        className="pr-5 mr-5"
                                    >
                                        {this.state.ClientForm.form_url}
                                    </a>

                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip id="overlay-example">
                                                Copy url
                                            </Tooltip>
                                        }
                                    >
                                        <button
                                            className="btn btn-link btn-md"
                                            onClick={() => {
                                                navigator.clipboard.writeText(`${this.state.ClientForm.form_url}`);
                                            }}
                                        >
                                            <i className="fas fa-copy"></i>
                                        </button>
                                    </OverlayTrigger>
                                </div>

                                <div className="url d-flex">
                                    <label className="pt-3">Form Url </label>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip id="overlay-example">
                                                Copy iframe url
                                            </Tooltip>
                                        }
                                    >
                                        <button
                                            className="btn btn-link btn-md"
                                            onClick={() => {
                                                navigator.clipboard.writeText(`<iframe allowtransparency="true" frameborder="0" scrolling="no" src="${this.state.ClientForm.form_url}" style="height: ${this.state.ClientForm.iframe_height}px; width: 100%;"></iframe>`);
                                            }}
                                        >
                                            <i className="fas fa-copy"></i>
                                        </button>
                                    </OverlayTrigger>

                                </div>


                                <textarea rows={'5'} className="form-control" defaultValue={`<iframe allowtransparency="true" frameborder="0" scrolling="no" src="${this.state.ClientForm.form_url}" style="height: ${this.state.ClientForm.iframe_height}px; width: 100%;"></iframe>`}></textarea>



                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    color="info"
                                    onClick={() => this.setState({
                                        modal: false,
                                        ClientForm: {}
                                    })}
                                >
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.records}
                                            columns={this.columns}
                                            dynamic={false}
                                            onChange={this.tableChangeHandler}
                                            key={'a'}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
            </Row>
            </React.Fragment>
        );
    }
}
