import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  ModalBody,
} from "reactstrap";
import PackagesSidebar from "../../Packages/packages-sidebar";
import "react-toastify/dist/ReactToastify.css";
import Helmet from "react-helmet";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import swal from 'sweetalert';
export default class RevenueGoalsSettings extends Component {
  constructor(props) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    super(props);
    this.state = {
      roles: [],
      total_pages: 0,
      record: {},
      productRecords: {},
      tab: "1",
      page_name: "Set Default Goals",
      filter: {},
      customDate: true,
    };

  }
  componentDidMount() {
    this.getData();
  }


  getData = () => {
    fetch(`${ApiUrl}` + "get/revneue/goals", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: 2 }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            record: response.data.record,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  toggle(index) {
    this.setState({
      tab: index,
    });
    if (index == "2") {
      this.getProductData("", "");
    }
  }
  handleSubmit = async (event, values) => {
    fetch(`${ApiUrl}` + "save/revneue/goals", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())

      .then((response) => {
        if (response.status === true) {
          //toast.configure();
          //toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          swal({
            text: 'Action performed successfully',
            icon: "success",
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };
  handleFilterSubmit = async (event, values) => {
    this.setState({
      filter: values,
    });
    this.getProductData("", values);
  };
  getProductData = (queryString, data) => {
    fetch(`${ApiUrl}` + "revenueProduct/list" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            total_pages: response.data.total,
            productRecords: response.data.records,
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  cancelFilter = async () => {
    this.form && this.form.reset();
    this.setState({ filter: {}, customDate: true });
    this.getProductData("", "");
  };
  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getProductData("?" + queryString, this.state.filter);
  };
  deleteRecord = (record, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "delete/revenueProduct", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: record.product_id }),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.status === true) {
                this.getProductData("", "");
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.page_name} </title>
        </Helmet>
        <Helmet>
          <title>{this.state.page_name}</title>
        </Helmet>

        <Row>
          <Col lg={2} className="pl-0">
            <PackagesSidebar menuOpen={false} props={this.props} />
          </Col>
          <Col lg={10}>
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4> {this.state.page_name}</h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> {this.state.page_name}</Link>
                    </li>
                  </ol>
                </div>
              </Col>
            </Row>
            <Row>
              <AvForm
                onValidSubmit={this.handleSubmit}
                ref={(c) => (this.form = c)}
                className="needs-validation"
              >
                <Row>
                  <Col className="col-4">
                    <Card className="mb-0">
                      <ModalBody className="pb-0">
                        <div className="mb-3">
                          <label>Coaching Monthly Revenue</label>
                          <div className="group-addnon">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                              </div>
                              <AvField
                                name="monthly_revenue"
                                type="number"
                                value={this.state.record.monthly_revenue}
                                className="form-control"
                                placeholder="Monthly Revenue"
                                required
                                validate={{
                                  pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label> Coaching Annual Revenue Earned</label>
                          <div className="group-addnon">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                              </div>
                              <AvField
                                name="annual_revenue_earned"
                                type="number"
                                value={this.state.record.annual_revenue_earned}
                                className="form-control"
                                placeholder="Annual Revenue Earned"
                                required
                                validate={{
                                  pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="total_clients"
                            label="Total Clients"
                            value={this.state.record.total_clients}
                            className="form-control"
                            placeholder="Total Clients"
                            required
                            type="number"
                            validate={{
                              pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="calendar_events"
                            type="number"
                            value={this.state.record.calendar_events}
                            label="Calendar Events"
                            className="form-control"
                            placeholder="Calendar Events"
                            required
                            validate={{
                              pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },
                            }}
                          />
                        </div>
                      </ModalBody>
                    </Card>
                  </Col>
                  <Col className="col-4">
                    <Card className="mb-0">
                      <ModalBody className="pb-0">
                        <div className="mb-3">
                          <label>Academy Monthly Revenue </label>
                          <div className="group-addnon">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                              </div>
                              <AvField
                                name="lmc_monthly_revenue"
                                type="number"
                                value={this.state.record.lmc_monthly_revenue}
                                className="form-control"
                                placeholder="Academy Monthly Revenue"
                                required
                                validate={{
                                  pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label>Academy Annual Revenue </label>
                          <div className="group-addnon">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                              </div>
                              <AvField
                                name="lmc_annual_revenue_earned"
                                type="number"
                                value={this.state.record.lmc_annual_revenue_earned}
                                className="form-control"
                                placeholder="Academy Annual Revenue"
                                required
                                validate={{
                                  pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="lmc_total_subscriptions"
                            label="Total Subscriptions"
                            value={this.state.record.lmc_total_subscriptions}
                            className="form-control"
                            placeholder="Total Subscriptins"
                            required
                            type="number"
                            validate={{
                              pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },
                            }}

                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="assessment_completed"
                            type="number"
                            value={this.state.record.assessment_completed}
                            label="Assessment Completed"
                            className="form-control"
                            placeholder="Assessment Completed"
                            required
                            validate={{
                              pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },
                            }}
                          />
                        </div>
                      </ModalBody>
                    </Card>
                  </Col>
                  <Col className="col-4">
                    <Card className="mb-0">
                      <ModalBody className="pb-0">
                        <div className="mb-3">
                          <AvField
                            name="outbound_call"
                            type="number"
                            value={this.state.record.outbound_call}
                            label="Oubound Calls"
                            className="form-control"
                            placeholder="Oubound Calls"
                            required
                            validate={{
                              pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="outbound_email"
                            type="number"
                            value={this.state.record.outbound_email}
                            label="Oubound Emails"
                            className="form-control"
                            placeholder="Oubound Emails"
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="outbound_sms"
                            type="number"
                            value={this.state.record.outbound_sms}
                            label="Oubound SMS"
                            className="form-control"
                            placeholder="Oubound SMS"
                            required
                            validate={{
                              pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },
                            }}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="pipeline_goal"
                            type="number"
                            value={this.state.record.pipeline_goal}
                            label="Pipeline Default Goal"
                            className="form-control"
                            placeholder="Pipeline Default Goal"
                            required
                            validate={{
                              pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },
                            }}
                          />
                        </div>

                      </ModalBody>
                    </Card>
                  </Col>
                  {/* <Col className="col-4">
                    <Card>
                      <ModalBody>
                        <div className="mb-3">
                          <AvField
                            name="pipeline_goal"
                            type="number"
                            value={this.state.record.pipeline_goal}
                            label="Pipeline Default Goal"
                            className="form-control"
                            placeholder="Pipeline Default Goal"
                            required
                            validate={{
                              pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },
                            }}
                          />
                        </div>
                      </ModalBody>
                    </Card>
                  </Col> */}
                  <Row>
                  </Row>
                  <Row className="p-0">
                    <Col md={12} className="mb-4">
                      <button className="btn btn-primary waves-effect waves-light pull-right" type="submit">Submit</button>
                    </Col>
                  </Row>

                </Row>
              </AvForm>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
