import React, { Component, Fragment, useState } from "react"
import { Link } from "react-router-dom";
import { ApiUrl, AttachementsUrl } from "../../config";
import Context from './Context';
import swal from "sweetalert";
import {
    Modal,
    Card,
    CardBody,
    Button,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ImageCropper from "./ImageCropper";
const Box6_2 = (props) => {
    const params = React.useContext(Context);
    const [modal, setModal] = useState(false);
    const [imageData, setImageData] = useState({
        id: '',
        url: '',
    });

    const [inputFields, setInputFields] = useState(
        { preview_hero_image: '' }
    );

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        if (inputFields.preview_hero_image != '') {
            submitData.append('preview_hero_image_base_64', inputFields.preview_hero_image);
        }


        fetch(`${ApiUrl}` + "update/builder/setting/" + params.user_id, {
            method: "POST",
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    params.goToBox(params.currentBox + 1);
                    params.getBuilderSetting();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const changeHandlerImg = (e, id) => {
        if (e.target.files[0].type.match('image.*')) {
            const preview = document.querySelector('#' + id);
            var reader = new FileReader();
            reader.onload = () => {
                preview.src = reader.result;
                imageCropper(id, reader.result)
            };
            reader.readAsDataURL(e.target.files[0]);
        } else {
            swal({
                title: 'Olny images allowed',
                icon: "warning",
            });
        }
    };

    const imageCropper = (id, url) => {
        // var url = document.getElementById(id).src;
        setImageData({
            id: id,
            url: url
        });
        setTimeout(() => {
            setModal(true);
        }, 200);
    }


    const imageCropperSet = (id, url) => {
        const preview = document.querySelector('#' + id);
        preview.src = url;

        setInputFields({ ...inputFields, [id]: url })

        setModal(false);
    }

    const closeModal = () => {
        setInputFields({ ...inputFields, [imageData.id]: imageData.url })
        setModal(false);
    }
    return (
        <div>
            <div className="box-center-align mt-3">
                <div className="SmallContaner my-4">
                    <div className="d-flex align-items-center gap-2 StepHeading">
                        <div className="StepNumber">3 <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">Graphic Uploads / Selections</h4>
                    </div>
                    <form method="post" onSubmit={handleSubmit}
                        encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                        <div className="border-top border-bottom border-left border-right p-4 mt-3">
                            <div className="page-content-box">
                                <div className="TitleWizred d-flex align-items-center gap-2">
                                    <span className="fs-5"> c.</span> <h5>Select the background for your main hero image
                                        <br />
                                        <small>Note: your background will have a filter based on your theme color selections
                                            and will have your personal photo superimposed in front of the background you select.</small>
                                    </h5>
                                </div>

                                <div className="WizredForm">
                                    <div className="form-group">
                                        {/* <label>Select Theme</label> */}
                                        <div class="container parent themeSelection">
                                            <div className="form-group mt-4">
                                                <div className="radio-box mt-3">
                                                    <label>Do you have an own hero image?</label>
                                                    <br />
                                                    <div class="form-check form-check-inline">
                                                        <input name="own_hero_image" defaultChecked={params.setting.own_hero_image == 'Yes' ? true : false}
                                                            onChange={() => params.updateSetting('own_hero_image', 'Yes')}
                                                            class="form-check-input" type="radio" id="inlineRadio1" value="Yes" />
                                                        <label class="form-check-label" for="inlineRadio1">Yes</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input name="own_hero_image" defaultChecked={params.setting.own_hero_image == 'No' ? true : false}
                                                            onChange={() => params.updateSetting('own_hero_image', 'No')}
                                                            class="form-check-input" type="radio" id="inlineRadio2" value="No" />
                                                        <label class="form-check-label" for="inlineRadio2">No</label>
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                params.setting.own_hero_image == 'Yes' && (
                                                    <div className="form-group mt-4">
                                                        <label>If you have your own background hero image, upload here <note className="text-muted">(Preferably size 1920 x 760)</note></label>
                                                        <input name="hero_image" className="form-control no-border px-0"
                                                            accept="image/*"
                                                            type="file" placeholder="https://"
                                                            onChange={(e) => changeHandlerImg(e, 'preview_hero_image')}
                                                        />
                                                        <img width={'50'} id="preview_hero_image" src={params.setting.hero_image} className="img img-thumbnail mt-1" alt="Not Found" />
                                                    </div>
                                                )
                                            }

                                            {
                                                params.setting.own_hero_image == 'No' && (

                                                    <div class="row">
                                                        <div class='col-lg-4 col-sm-4 col-md-4'>
                                                            <div className="theme-img justify-content-center">
                                                                <input type="radio" name="backgroung_hero_image" defaultChecked={params.setting.backgroung_hero_image == 'corporate-option-1.jpg' ? true : false} id="backgroung_hero_image1" class="d-none bgimgbgchk" value="corporate-option-1.jpg" />
                                                                <label for="backgroung_hero_image1">
                                                                    <div className="hero-img-area">
                                                                        <img src={`${AttachementsUrl}/default/images/Background-Options/Compresed/corporate-option-1.jpg`} alt="Image 1" />
                                                                    </div>
                                                                    <div class="tick_container">
                                                                        <div class="tick"><i class="fa fa-check"></i></div>
                                                                    </div>
                                                                    <div className="img-footer">
                                                                        <label className="img-box">A</label>
                                                                        <p>Corporate Option 1</p>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class='col-lg-4 col-sm-4 col-md-4'>
                                                            <div className="theme-img justify-content-center">
                                                                <input type="radio" name="backgroung_hero_image" defaultChecked={params.setting.backgroung_hero_image == 'landscape-option-1.jpg' ? true : false} id="backgroung_hero_image2" class="d-none bgimgbgchk" value="landscape-option-1.jpg" />
                                                                <label for="backgroung_hero_image2">
                                                                    <div className="hero-img-area">
                                                                        <img src={`${AttachementsUrl}/default/images/Background-Options/Compresed/landscape-option-1.jpg`} alt="Image 1" />
                                                                    </div>
                                                                    <div class="tick_container">
                                                                        <div class="tick"><i class="fa fa-check"></i></div>
                                                                    </div>
                                                                    <div className="img-footer">
                                                                        <label className="img-box">B</label>
                                                                        <p>Landscape Option 1</p>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div class='col-lg-4 col-sm-4 col-md-4'>
                                                            <div className="theme-img justify-content-center">
                                                                <input type="radio" name="backgroung_hero_image" defaultChecked={params.setting.backgroung_hero_image == 'office-option-1.jpg' ? true : false} id="backgroung_hero_image3" class="d-none bgimgbgchk" value="office-option-1.jpg" />
                                                                <label for="backgroung_hero_image3">
                                                                    <div className="hero-img-area">
                                                                        <img src={`${AttachementsUrl}/default/images/Background-Options/Compresed/office-option-1.jpg`} alt="Image 1" />
                                                                    </div>
                                                                    <div class="tick_container">
                                                                        <div class="tick"><i class="fa fa-check"></i></div>
                                                                    </div>
                                                                    <div className="img-footer">
                                                                        <label className="img-box">C</label>
                                                                        <p>Office Option 1</p>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div class='col-lg-4 col-sm-4 col-md-4'>
                                                            <div className="theme-img justify-content-center">
                                                                <input type="radio" name="backgroung_hero_image" defaultChecked={params.setting.backgroung_hero_image == 'office-option-2.jpg' ? true : false} id="backgroung_hero_image4" class="d-none bgimgbgchk" value="office-option-2.jpg" />
                                                                <label for="backgroung_hero_image4">
                                                                    <div className="hero-img-area">
                                                                        <img src={`${AttachementsUrl}/default/images/Background-Options/Compresed/office-option-2.jpg`} alt="Image 1" />
                                                                    </div>
                                                                    <div class="tick_container">
                                                                        <div class="tick"><i class="fa fa-check"></i></div>
                                                                    </div>
                                                                    <div className="img-footer">
                                                                        <label className="img-box">D</label>
                                                                        <p>Office Option 2</p>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div class='col-lg-4 col-sm-4 col-md-4'>
                                                            <div className="theme-img justify-content-center">
                                                                <input type="radio" name="backgroung_hero_image" defaultChecked={params.setting.backgroung_hero_image == 'skyline-option-1.jpg' ? true : false} id="backgroung_hero_image5" class="d-none bgimgbgchk" value="skyline-option-1.jpg" />
                                                                <label for="backgroung_hero_image5">
                                                                    <div className="hero-img-area">
                                                                        <img src={`${AttachementsUrl}/default/images/Background-Options/Compresed/skyline-option-1.jpg`} alt="Image 1" />
                                                                    </div>
                                                                    <div class="tick_container">
                                                                        <div class="tick"><i class="fa fa-check"></i></div>
                                                                    </div>
                                                                    <div className="img-footer">
                                                                        <label className="img-box">E</label>
                                                                        <p>Skyline Option 1</p>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div class='col-lg-4 col-sm-4 col-md-4'>
                                                            <div className="theme-img justify-content-center">
                                                                <input type="radio" name="backgroung_hero_image" defaultChecked={params.setting.backgroung_hero_image == 'skyline-option-2.jpg' ? true : false} id="backgroung_hero_image6" class="d-none bgimgbgchk" value="skyline-option-2.jpg" />
                                                                <label for="backgroung_hero_image6">
                                                                    <div className="hero-img-area">
                                                                        <img src={`${AttachementsUrl}/default/images/Background-Options/Compresed/skyline-option-2.jpg`} alt="Image 1" />
                                                                    </div>
                                                                    <div class="tick_container">
                                                                        <div class="tick"><i class="fa fa-check"></i></div>
                                                                    </div>
                                                                    <div className="img-footer">
                                                                        <label className="img-box">F</label>
                                                                        <p>Skyline Option 2</p>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div class='col-lg-4 col-sm-4 col-md-4'>
                                                            <div className="theme-img justify-content-center">
                                                                <input type="radio" name="backgroung_hero_image" defaultChecked={params.setting.backgroung_hero_image == 'speaker-option-1.jpg' ? true : false} id="backgroung_hero_image9" class="d-none bgimgbgchk" value="speaker-option-1.jpg" />
                                                                <label for="backgroung_hero_image9">
                                                                    <div className="hero-img-area">
                                                                        <img src={`${AttachementsUrl}/default/images/Background-Options/Compresed/speaker-option-1.jpg`} alt="Image 1" />
                                                                    </div>
                                                                    <div class="tick_container">
                                                                        <div class="tick"><i class="fa fa-check"></i></div>
                                                                    </div>
                                                                    <div className="img-footer">
                                                                        <label className="img-box">G</label>
                                                                        <p>Speaker Option 1</p>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div class='col-lg-4 col-sm-4 col-md-4'>
                                                            <div className="theme-img justify-content-center">
                                                                <input type="radio" name="backgroung_hero_image" defaultChecked={params.setting.backgroung_hero_image == 'speaker-option-2.jpg' ? true : false} id="backgroung_hero_image7" class="d-none bgimgbgchk" value="speaker-option-2.jpg" />
                                                                <label for="backgroung_hero_image7">
                                                                    <div className="hero-img-area">
                                                                        <img src={`${AttachementsUrl}/default/images/Background-Options/Compresed/speaker-option-2.jpg`} alt="Image 1" />
                                                                    </div>
                                                                    <div class="tick_container">
                                                                        <div class="tick"><i class="fa fa-check"></i></div>
                                                                    </div>
                                                                    <div className="img-footer">
                                                                        <label className="img-box">H</label>
                                                                        <p>Speaker Option 2</p>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div class='col-lg-4 col-sm-4 col-md-4'>
                                                            <div className="theme-img justify-content-center">
                                                                <input type="radio" name="backgroung_hero_image" defaultChecked={params.setting.backgroung_hero_image == 'speaker-option-3.jpg' ? true : false} id="backgroung_hero_image8" class="d-none bgimgbgchk" value="speaker-option-3.jpg" />
                                                                <label for="backgroung_hero_image8">
                                                                    <div className="hero-img-area">
                                                                        <img src={`${AttachementsUrl}/default/images/Background-Options/Compresed/speaker-option-3.jpg`} alt="Image 1" />
                                                                    </div>
                                                                    <div class="tick_container">
                                                                        <div class="tick"><i class="fa fa-check"></i></div>
                                                                    </div>
                                                                    <div className="img-footer">
                                                                        <label className="img-box">I</label>
                                                                        <p>Speaker Option 3</p>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>

                                                    </div>
                                                )
                                            }

                                            <div className="form-group mt-4">
                                                <div className="radio-box mt-3">
                                                    <label>Do you select overlay background overlay color?</label>
                                                    <br />
                                                    <div class="form-check form-check-inline">
                                                        <input name="overlay_bg_color" defaultChecked={params.setting.overlay_bg_color == 'Yes' ? true : false}
                                                            onChange={() => params.updateSetting('overlay_bg_color', 'Yes')}
                                                            class="form-check-input" type="radio" id="inlineRadio1" value="Yes" />
                                                        <label class="form-check-label" for="inlineRadio1">Yes</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input name="overlay_bg_color" defaultChecked={params.setting.overlay_bg_color == 'No' ? true : false}
                                                            onChange={() => params.updateSetting('overlay_bg_color', 'No')}
                                                            class="form-check-input" type="radio" id="inlineRadio2" value="No" />
                                                        <label class="form-check-label" for="inlineRadio2">No</label>
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                params.setting.overlay_bg_color == 'Yes' && (
                                                    <div className="form-group mt-3">
                                                        <label>Select the background overlay color</label>
                                                        <input name="hero_image_background_color" defaultValue={params.setting.hero_image_background_color ?? ''} className="form-control no-border px-0 w-100" type="color" placeholder="Jane"></input>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-lg btn-primary mt-2"> Next &nbsp;<i className="fas fa-check"></i></button>
                    </form>
                </div>
            </div>
            <Modal
                isOpen={modal}
                fade={true}
                size="lg"
                className="subActionTraining websitebuilder"
                toggle={() => closeModal()}
                centered={true}
                backdrop={'static'}

            >
                <div className="">
                    <div className='modal-header'>
                        <div className='modal-title'>
                            Crop Image
                        </div>
                        {/* <button onClick={() => closeModal()} className="mb-3 btn btn-link pull-right BtnCenterClose"><i className="fas fa-times"></i></button> */}
                    </div>

                    <ImageCropper closeModal={closeModal} imageCropperSet={imageCropperSet} data={imageData} />



                </div>
            </Modal>
        </div >
    );
};

export default Box6_2;