import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    Button,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import {
    AvForm,
    AvField,
} from "availity-reactstrap-validation";
import {
    dcrypt,
    bcrypt,
    uToken,
    isValidJSONString,
} from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import CampaignNav from "./nav";
import swal from 'sweetalert';
export default class CampaignOutbound extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id
                ? dcrypt(this.props.match.params.id)
                : null,
            page_name: "Campaign Outbound",
            from: this.props.from ? this.props.from : "",
            record: {},
        };
    }

    componentDidMount() {
        this.state.id != null && this.getData();
    }


    getData = () => {
        fetch(`${ApiUrl}` + "get/edit/campaign", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },

            body: JSON.stringify({
                id: this.state.id,
                coach_id: this.state.coach_id,
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {

                    let campaign = response.data.record;
                    let setting = isValidJSONString(campaign.other_settings);
                    if (setting.dial_attempts) {
                        this.setState({
                            dial_attempts: setting.dial_attempts,
                        });
                    }

                    this.setState({
                        record: response.data.record,
                    });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    handleSubmit = async (event, values) => {
        let setting = isValidJSONString(this.state.record.other_settings);

        setting.dial_attempts = values.dial_attempts;
        values.other_settings = JSON.stringify(setting);
        console.log(values);
        if (this.state.id) {
            values.id = this.state.record.campaign_id
                ? this.state.record.campaign_id
                : -1;
        }

        fetch(`${ApiUrl}` + "add/campaign/outbound", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data, "Editing");
                if (data.status === true) {
                    if (this.state.id == null) {
                        this.form && this.form.reset();
                    }
                    swal({
                        text: 'Action performed successfully',
                        icon: "success",
                    });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };



    render() {
        return (
            <React.Fragment>

                <Row>
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4> {this.state.page_name} </h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#"> {this.state.page_name} </Link>
                                </li>
                            </ol>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className="page-title-box text-align-right">
                            <Button
                                type="button"
                                color="secondary"
                                onClick={() => this.props.history.push('/campaign/list')}
                            >
                                Back
                            </Button>
                        </div>
                    </Col>
                </Row>
                <ToastContainer />
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CampaignNav activeTab={'3'} campaignId={bcrypt(this.state.id)} />
                            <AvForm
                                onValidSubmit={this.handleSubmit}
                                // model={this.state.record}
                                ref={(c) => (this.form = c)}
                                className="needs-validation"
                            >
                                <ModalBody className="row">
                                    <div className="col-md-12 mb-3">
                                        <AvField
                                            name="dial_attempts"
                                            value={this.state.dial_attempts}
                                            label="Maximum Dial Attempts"
                                            className="form-control"
                                            type="number"
                                            validate={{
                                                // required: { value: true, errorMessage: 'This field is invalid' },
                                                pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                                            }}
                                            required
                                        />
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        type="button"
                                        color="secondary"
                                        onClick={() => this.props.history.goBack()}
                                    >
                                        Cancel
                                    </Button>
                                    <button
                                        className="btn btn-primary waves-effect waves-light"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </ModalFooter>
                            </AvForm>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
