import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import SweetAlert from "react-bootstrap-sweetalert"
import Helmet from "react-helmet";
import {
    bcrypt,
    isAllowed,
    uInfo,
    uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-bootstrap";
import swal from 'sweetalert';


export default class ClientForms extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                text: "Form#",
                key: "id",
            },
            {
                text: "Name",
                key: "name",
            },
            {
                text: "Group",
                key: "group_id",
            },
            {
                key: "action",
                text: "Default Book Download Form",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            {
                                record.is_book_form == 'Yes' ?
                                    <>
                                    <button
                                        disabled={true}
                                        className="btn btn-success btn-sm mb-1"
                                        key={record.is_book_form + '-' + index}
                                        onClick={() => this.setState({ warningAlert: true, record: record })}
                                    >
                                       Yes 
                                    </button>
                                    
                                    <button
                                        className="btn btn-link btn-sm ml-3"
                                        key={record.is_book_form + '-' + index}
                                        onClick={() => this.setState({ warningAlert2: true, record: record })}
                                    > 
                                        Reset
                                    </button>
                                    </>
                                    :
                                    <button
                                        key={record.is_book_form + '-' + index}
                                        className="btn btn-danger btn-sm mb-1"
                                        onClick={() => this.setState({ warningAlert: true, record: record })}
                                    >
                                        No
                                    </button>
                            }
                        </Fragment>
                    );
                }
            },
            {
                text: "Created At",
                key: "created_at",
            },
            {
                key: "action",
                text: "Action",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <Link
                                type="button"
                                color="info"
                                className="btn btn-link btn-sm mb-1 mr-5"
                                to={
                                    "/lead/forms/edit/" + bcrypt(record.id)
                                }
                            >
                                <i className="fas fa-fw  fa-edit"></i>
                            </Link>
                            <button
                                title="Link"
                                type="button"
                                color="info"
                                className="btn btn-link btn-sm mb-1 mr-5"
                                onClick={() => { this.setState({ modal: true, ClientForm: record }) }}
                            >
                                <i className="fas fa-fw  fa-link"></i>
                            </button>
                            <button
                                className="btn btn-link btn-sm mb-1 mr-5"
                                onClick={this.deleteRecord.bind(this, record, index)}
                            >
                                <i className="fas fa-trash"></i>
                            </button>
                            {/* <button
                                title="Link"
                                type="button"
                                color="info"
                                className="btn btn-link btn-sm mb-1 mr-5"
                                onClick={() => this.props.history.push('/appointment/list/' + record.id)}
                            >
                                <i className="fas fa-fw  fa-eye"></i>
                            </button> */}
                        </Fragment>
                    );
                }
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            show_filter: true,
            show_pagination: true,

        };
        this.state = {
            records: [],
            roles: [],
            total_pages: 0,
            record: {},
            filter: {},
            page_name: "Lead Forms",
            customDate: true,
            ClientSubcribeModal: false,
            modal: false,
            ClientForm: {},
            currentClient: {
                id: "",
                email_status: "",
                call_status: "",
                groups: [],
                tags: [],
            },
        };
    }
    deleteRecord = (record, index) => {
        swal({
            title: "Are you sure?",
            text: "You want to remove this record.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "delete/clientForms", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                        body: JSON.stringify({ id: record.id }),
                    }).then((response) => response.json())
                        .then((data) => {
                            if (data.status === true) {
                                this.getData();
                            } else {
                                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                            }
                        })
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    };

    componentDidMount() {
        this.getData();
    }

    updateForm = () => {
        console.log(this.state.record);
        fetch(`${ApiUrl}` + "update/lead/form/status", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: this.state.record.id }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                if (data.status === true) {
                    this.getData();
                    this.setState({
                        record: {},
                        warningAlert: false
                    })
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    restForm = () => {
        console.log(this.state.record);
        fetch(`${ApiUrl}` + "reset/lead/form/status", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: this.state.record.id }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                if (data.status === true) {
                    this.getData();
                    this.setState({
                        record: {},
                        warningAlert2: false
                    })
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    getData = (queryString = "", data) => {
        fetch(`${ApiUrl}` + "clientForms/list" + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {
                    console.log(response, 'debugging');
                    this.setState({
                        total_pages: response.data.total,
                        records: response.data.records,
                        roles: response.data.roles,
                    });

                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };




    FormBuilder(formurl) {

        var iframe = document.createElement('iframe');
        iframe.style = "height:" + 360 + "px; width:100%; border:none";
        iframe.setAttribute('allowTransparency', true);
        iframe.src = formurl;
        // document.body.appendChild(iframe);

        console.log(iframe);
        return iframe;
    }


    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.state.page_name}
                    </title>
                </Helmet>
                {this.state.warningAlert ? (
                    <SweetAlert
                        title="Do you want to set this as the default book download lead form?"
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        warning
                        onConfirm={() => this.updateForm()}
                        onCancel={() => this.setState({
                            warningAlert: false,
                            record: {}
                        })}
                    />
                ) : null}
                  {this.state.warningAlert2 ? (
                    <SweetAlert
                        title="Do you want to RESET this form. By performing this action, default form will set as website download book form?"
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        warning
                        onConfirm={() => this.restForm()}
                        onCancel={() => this.setState({
                            warningAlert2: false,
                            record: {}
                        })}
                    />
                ) : null}
                <Row>

                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4>{this.state.page_name}</h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#">{this.state.page_name}</Link>
                                </li>
                            </ol>
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div className="page-title-box text-end">
                        <Link
                                    type="button"
                                    color="info"
                                    className="btn btn-primary me-2"
                                    to={"/lead/list"}
                                >
                                   
                                    Back
                                </Link>
                            {isAllowed(uInfo(), ["can_create"]) && (
                                <Link
                                    type="button"
                                    color="info"
                                    className="btn btn-info"
                                    to={"/lead/forms/add"}
                                >
                                    <i className="mdi mdi-plus"> </i>
                                    Create
                                </Link>
                            )}
                        </div>
                    </Col>
                </Row>

                <ToastContainer />


                <Modal show={this.state.modal}>
                    <Modal.Header>
                        <Modal.Title>Embed Code</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="url">
                            <a
                                href={`${this.state.ClientForm.form_url}`}
                                target="_blank"
                                className="pr-5 mr-5"
                            >
                                {this.state.ClientForm.form_url}
                            </a>

                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip id="overlay-example">
                                        Copy url
                                    </Tooltip>
                                }
                            >
                                <button
                                    className="btn btn-link btn-md"
                                    onClick={() => {
                                        navigator.clipboard.writeText(`${this.state.ClientForm.form_url}`);
                                    }}
                                >
                                    <i className="fas fa-copy"></i>
                                </button>
                            </OverlayTrigger>
                        </div>

                        <div className="url d-flex">
                            <label className="pt-3">Lead Form Url </label>
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip id="overlay-example">
                                        Copy iframe url
                                    </Tooltip>
                                }
                            >
                                <button
                                    className="btn btn-link btn-md"
                                    onClick={() => {
                                        navigator.clipboard.writeText(`<iframe allowtransparency="true" src="${this.state.ClientForm.form_url}" style="height: 360px; width: 100%; border: none;"></iframe>`);
                                    }}
                                >
                                    <i className="fas fa-copy"></i>
                                </button>
                            </OverlayTrigger>

                        </div>


                        <textarea rows={'5'} className="form-control" defaultValue={`<iframe allowtransparency="true" src="${this.state.ClientForm.form_url}" style="height: 360px; width: 100%; border: none;"></iframe>`}></textarea>



                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            color="info"
                            onClick={() => this.setState({
                                modal: false,
                                ClientForm: {}
                            })}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                    dynamic={false}
                                    onChange={this.tableChangeHandler}
                                    key={'a'}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
