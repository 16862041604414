import React, { useState, useEffect, useRef } from "react";
import { ApiUrl, ServerIP } from "../../../config";
import Context from './Context';
import swal from "sweetalert";
import { dcrypt,bcrypt, uInfo, uToken } from "../../../useToken";
const Box = (props) => {
    const params = React.useContext(Context);
    const [record, setRecord] =  useState({});
    useEffect(() => {
        getData();
    }, []);
    const getData = () => {
        fetch(`${ApiUrl}` + "get/systemForm/resourceInfo", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
              },
            body: JSON.stringify({ id: params.form_data.id, user_id: uInfo().user_id}),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    console.log(response.data.record, 'response.data.record')
                    setRecord(response.data.record);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
      
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        submitData.append('user_id', uInfo().user_id);
        submitData.append('id', params.form_data.id);
        fetch(`${ApiUrl}` + "update/system/form", {
            method: "POST",
            body: submitData,
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    swal({
                        title: "Action performed successfully",
                        icon: "success",
                    });
                }else{
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    return (
            <div className="mt-1">
                    <div className="d-flex align-items-center gap-2 StepHeading">
                        <div className="StepNumber">{params.form_data.id} <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">{params.form_data.name}</h4>
                    </div>
                    <form method="post" onSubmit={handleSubmit} encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                        <div className="p-2 mt-1">
                            <div className="page-content-box">
                                <div className="TitleWizred d-flex align-items-center gap-2">
                                   <h5>Answer each of the following questions exactly as you would like them to appear on your website.</h5>
                                </div>
                                <div className="WizredForm mt-3">
                                    {record.domain_type && (
                                        <div className="form-group">
                                        <label>What is your website domain?</label>
                                        <div className="domain-selection px-3 mt-2">
                                            <div className="form-check mb-3 col-md-4">
                                                <input className="form-check-input mb-2" type="radio" name="domain_type" id="domain_type"
                                                    value="sub"
                                                    required
                                                    onChange={() => 
                                                        setRecord((prevState) => ({
                                                            ...prevState,
                                                            ['domain_type']: 'sub',
                                                            }))
                                                    }
                                                    defaultChecked={record.domain_type == "sub" ? true : false} 
                                                    />
                                                <label className="form-check-label" htmlFor="domain_type">Default domain {record.domain_type}</label>
                                                {
                                                    record.domain_type == 'sub' && (
                                                        <div className="input-group mt-2 mb-1  col-md-6">
                                                            <input
                                                                required
                                                                defaultValue={record.domain ?? ''}
                                                                // onChange={(e) => this.setState({ domain: e.target.value })}
                                                                placeholder="example"
                                                                className="form-control no-border px-0" name="domain" />
                                                            <div className="input-group-text">.profitcoach.app</div>
                                                        </div>
                                                    )
                                                }
                                            </div>

                                            <div className="form-check mb-1 col-md-12">
                                                <input className="form-check-input mb-1" type="radio" name="domain_type"
                                                    defaultChecked={record.domain_type == 'master' ? true : false}
                                                    id="domain_type1" value="master" 
                                                    onChange={() => 
                                                        setRecord((prevState) => ({
                                                        ...prevState,
                                                        ['domain_type']: 'master',
                                                        }))
                                                    }
                                                    
                                                    />
                                                <label className="form-check-label" htmlFor="domain_type1">Add your own domain</label>
                                                {
                                                    record.domain_type == 'master' && (
                                                        <div className="row">
                                                            <div className="form-check mb-1 col-md-6">
                                                                <input
                                                                    required
                                                                    placeholder="www.example.com"
                                                                    defaultValue={record.domain ?? ''}
                                                                    // onChange={(e) => this.setState({ domain: e.target.value })}
                                                                    className="form-control no-border px-0" name="domain" />
                                                                <p>Note: Please make sure to have either an A record pointing to <span className="text-danger">{ServerIP}</span></p>
                                                            </div>

                                                            <div className="form-check mb-1 col-md-6">
                                                                <p>
                                                                    <i className="fas fa-question"> </i> Help
                                                                </p>
                                                                <p><span><i class="fa fa-chevron-right"></i> Step 1</span> You must setup an NS "A" record to our server IP {ServerIP}</p>

                                                                <p><span><i class="fa fa-chevron-right"></i> Step 2</span>  After completing step 1, please submit to admin for final configuration.</p>

                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>

                                    </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-sm btn-primary mt-1"> Ok &nbsp;<i className="fas fa-check"></i></button>
                    </form>
            </div>
    );
};

export default Box;