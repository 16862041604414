import React, { useState, useEffect, useRef } from "react";
import {
    Card, CardBody
} from "reactstrap"
import { ApiUrl, ProductName } from "../../config";
import { uToken } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import swal from 'sweetalert';
import Context from './forms/Context';
import Box1 from "./forms/1";
import Box2 from "./forms/2";
import Box3 from "./forms/3";
import Box4 from "./forms/4";
import Box5 from "./forms/5";
import Box6 from "./forms/6";
import Box7 from "./forms/7";
import Box8 from "./forms/8";
import Box9 from "./forms/9";
import Box10 from "./forms/10";
import Box11 from "./forms/11";
import Box12 from "./forms/12";
import Box13 from "./forms/13";
import Box14 from "./forms/14";
import Box15 from "./forms/15";
import Box16 from "./forms/16";
import Box17 from "./forms/17";
import Box18 from "./forms/18";
import Box19 from "./forms/19";
import Box20 from "./forms/20";
import Box21 from "./forms/21";
import Box22 from "./forms/22";
import Box23 from "./forms/23";
import Box24 from "./forms/24";
import Box25 from "./forms/25";
import Box26 from "./forms/26";
import Box27 from "./forms/27";
import Box28 from "./forms/28";
import Box29 from "./forms/29";
import Box30 from "./forms/30";
import Box31 from "./forms/31";
import Box32 from "./forms/32";
import Box33 from "./forms/33";
const ClientForm = (props) => {
    const [id, setId] = useState(props.match.params.id ? props.match.params.id : null);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getData();
    }, []);


    const getData = () => {
        fetch(`${ApiUrl}` + "get/edit/systemForms", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ id: id }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setData(response.data.record);
                    setLoading(false);
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    const form_data = {
        user_id: 2,
        setting: {},
        form_data: data,
    }
    const Components = {
        1: Box1, 2: Box2,
        3: Box3, 4: Box4, 5: Box5, 6: Box6, 7: Box7, 8: Box8, 9: Box9, 10: Box10,
        11: Box11, 12: Box12, 13: Box13, 14: Box14, 15: Box15, 16: Box16, 17: Box17, 18: Box18,
        19: Box19, 20: Box20, 21: Box21, 22: Box22, 23: Box23, 24: Box24, 25: Box25, 26: Box26, 27: Box27, 28: Box28,
        29: Box29, 30: Box30, 31: Box31, 32: Box32, 33: Box33,
    };
    return (
        <Context.Provider value={form_data}>
            <>
                {
                    !loading && (
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    {
                                        typeof Components[data.id] !== "undefined" && (
                                            React.createElement(Components[data.id], {})
                                        )
                                    }
                                </div>

                            </div>
                        </div>
                    )
                }
            </>
        </Context.Provider >
    )

}

export default ClientForm;
