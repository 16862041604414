import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom";
import { ApiUrl, AttachementsUrl } from "../../config";
import Context from './Context';
import swal from "sweetalert";
const Box6 = (props) => {
    const params = React.useContext(Context);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        fetch(`${ApiUrl}` + "update/builder/setting/" + params.user_id, {
            method: "POST",
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    params.goToBox(params.currentBox + 1);
                    params.getBuilderSetting();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const changeHandlerImg = (e, id) => {
        if (e.target.files[0].type.match('video.*') || e.target.files[0].type.match('image.*')) {
            const preview = document.querySelector('#' + id);
            console.log(preview)
            var reader = new FileReader();
            reader.onload = () => {
                preview.src = reader.result;
            };
            reader.readAsDataURL(e.target.files[0]);
        } else {
            swal({
                title: 'Olny video allowed',
                icon: "warning",
            });
        }
    };
    return (
        <div>
            <div className="box-center-align mt-3">
                <div className="SmallContaner my-4">
                    <div className="d-flex align-items-center gap-2 StepHeading">
                        <div className="StepNumber">3 <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">Graphic Uploads / Selections</h4>
                    </div>
                    <form method="post" onSubmit={handleSubmit}
                        encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                        <div className="border-top border-bottom border-left border-right p-4 mt-3">
                            <div className="page-content-box">
                                <div className="TitleWizred d-flex align-items-center gap-2">
                                    <span className="fs-5"> a.</span> <h5>Select the theme of your new website</h5>
                                </div>

                                <div className="WizredForm mt-0">
                                    <div className="form-group mt-0">
                                        {/* <label>Select Theme</label> */}
                                        <div class="container parent themeSelection">
                                            <div class="row">
                                                <div class='col-lg-4 col-sm-4 col-md-4'>
                                                    <div className=" theme-img justify-content-center">
                                                        <input type="radio" name="theme" defaultChecked={params.setting.theme == 3 ? true : false} id="img3" class="d-none imgbgchk" value="3" />
                                                        <label for="img3">
                                                            <img src={`${AttachementsUrl}/default/website_builder_resources/themes/index3.png`} alt="Image 3" />
                                                            <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <a className="d-block text-center mt-2" href={`${AttachementsUrl}/default/website_builder_resources/themes/theme1.png`} target="_blank">Preview</a>
                                                </div>
                                                <div class='col-lg-4 col-sm-4 col-md-4'>
                                                    <div className=" theme-img justify-content-center">
                                                        <input type="radio" name="theme" defaultChecked={params.setting.theme == 4 ? true : false} id="img4" class="d-none imgbgchk" value="4" />
                                                        <label for="img4">
                                                            <img src={`${AttachementsUrl}/default/website_builder_resources/themes/index4.png`} alt="Image 4" />
                                                            <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <a className="d-block text-center mt-2" href={`${AttachementsUrl}/default/website_builder_resources/themes/theme2.png`} target="_blank">Preview</a>

                                                </div>
                                                <div class='col-lg-4 col-sm-4 col-md-4'>
                                                    <div className=" theme-img justify-content-center">
                                                        <input type="radio" name="theme" defaultChecked={params.setting.theme == 5 ? true : false} id="img5" class="d-none imgbgchk" value="5" />
                                                        <label for="img5">
                                                            <img src={`${AttachementsUrl}/default/website_builder_resources/themes/index5.png`} alt="Image 5" />
                                                            <div class="tick_container">
                                                                <div class="tick"><i class="fa fa-check"></i></div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <a className="d-block text-center mt-2" href={`${AttachementsUrl}/default/website_builder_resources/themes/theme3.png`} target="_blank">Preview</a>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button type="submit" className="btn btn-lg btn-primary mt-2"> Next &nbsp;<i className="fas fa-check"></i></button>

                    </form>
                </div>
            </div>
        </div >
    );
};

export default Box6;