import React, { useContext, useRef, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';
import WayBookContext from './WaybookContext';
import Card from 'react-bootstrap/Card';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { Link } from 'react-router-dom';
import { Collapse } from "reactstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { AttachementsUrl } from '../../config';
import Knob from '../Campaign/knob';
import { bcrypt } from '../../useToken';
export default function WaySideBar(props) {
    const params = React.useContext(WayBookContext);
    const [isOpen, setIsOpen] = useState('sub' + params.category_i + '-' + params.subject_i);
    const [defaultIndex] = useState(params.category_i);
    const [lists, setLists] = useState(params.lists);

    const toggleOpen = (id) => () =>
        setIsOpen((isOpen) => (isOpen === id ? null : id));


    const Subject = ({ cat_id, index, subindex, content, subject, isOpen, toggle, status, image, user_id, training_for, master_id, disabled }) => {
        return (
            <div key={cat_id} className="subject"
                onDragStart={(e) => dragStart(e, subindex)}
                onDragEnter={(e) => dragEnter(e, subindex)}
                onDragEnd={(e) => dropTwo(e, index)}
                draggable={params.can_edit}
            >
                <div className={`${isOpen ? 'active' : ''}`}>
                    <div className='d-flex justify-content-between align-items-center ThreeDots'>
                        <div className='subject-data ps-2'>
                            <button disabled={disabled} className={`${disabled ? 'disabled' : ''} btn btn-link p-0`} onClick={toggle}>{isOpen ? <i className='fas fa-caret-down pe-2' /> : <i className='fas fa-caret-right pe-2 ' />}</button>
                            <img className='me-2' height={20} width={20} src={`${AttachementsUrl}/user_${user_id}/training/${image}`} alt="" />
                            <Link className={`${disabled ? 'disabled' : ''}`} disabled={disabled} to={`/trainings/${bcrypt(index)}/${bcrypt(master_id)}/${bcrypt(subindex)}/${bcrypt(cat_id)}`}>{subject}</Link>
                        </div>

                        {
                            params.can_edit && (
                                <div className='subject-action'>
                                    {
                                        status != 'Publish' && (
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={<Tooltip id="button-tooltip">
                                                    This document is unpublished
                                                </Tooltip>}
                                            >
                                                <i className='fas fa-info-circle'></i>
                                            </OverlayTrigger>
                                        )
                                    }


                                    <Dropdown onSelect={(e) => params.subjectOrDocumentAction('Subject', cat_id, subject, e, training_for, image, user_id)}>
                                        <Dropdown.Toggle variant="link">
                                            <i className='fas fa-ellipsis-h'></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {
                                                status == 'Publish' ?
                                                    <Dropdown.Item eventKey="Draft">Switch to Draft</Dropdown.Item>
                                                    :
                                                    <Dropdown.Item eventKey="Publish">Switch to Publish</Dropdown.Item>

                                            }
                                            <Dropdown.Item eventKey="Update">Rename</Dropdown.Item>
                                            <Dropdown.Item eventKey="Move">Move</Dropdown.Item>
                                            <Dropdown.Item eventKey="Delete">Delete</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            )
                        }
                    </div>
                </div>
                <Collapse isOpen={isOpen} className="content-way">
                    {content}
                </Collapse>
                {/* <hr className='m-0' /> */}
            </div>
        );
    };

    const dragItem = useRef();
    const dragOverItem = useRef();

    const dragStart = (e, position) => {
        e.preventDefault(); //remove this for reordering


        if (!params.can_edit) {
            e.preventDefault();
        }
        dragItem.current = position;
    };

    const dragEnter = (e, position) => {
        // e.preventDefault();
        dragOverItem.current = position;
        // console.log(e.target.innerHTML);
    };

    const drop = (e, i, k) => {
        const copyListItems = [...lists[i].sub_cat[k].sub_cat];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        let newState = [...lists];
        let two = newState[i].sub_cat[k];
        two.sub_cat = copyListItems;
        newState[i].sub_cat[k] = two;
        setLists(newState);
    };

    const dropTwo = (e, i) => {

        const copyListItems = [...lists[i].sub_cat];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        let newState = [...lists];
        newState[i].sub_cat = copyListItems;
        setLists(newState);
    };


    return (
        <div className='subjexts ps-2 side-change-dropdown'>
            <Accordion defaultActiveKey={[defaultIndex]} alwaysOpen flush className='WayBookCate'>
                {
                    lists.map((item, i) => {
                        return <Accordion.Item disabled={item.disabled} key={item.cat_id} className={`${item.disabled ? 'disabled' : ''} mainCate active`} eventKey={`${i}`}>
                            <Accordion.Header>
                                <Link disabled={item.disabled} to={`/trainings/${bcrypt(i)}/${bcrypt(item.cat_id)}`}
                                    className={`${item.disabled ? 'disabled' : ''}`}>{item.title}
                                    {
                                        !params.can_edit && item.onboard_training == 1 && (
                                            <i title='Onboard Training' className='fas fa-chalkboard-teacher ml-5' ></i>
                                        )
                                    }
                                </Link>
                                {
                                    params.can_edit && (
                                        <div className='category-action'>
                                            {
                                                item.status != 'Publish' && (
                                                    <OverlayTrigger
                                                        placement="right"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={<Tooltip id="button-tooltip">
                                                            This category is unpublished
                                                        </Tooltip>}
                                                    >
                                                        <i className='fas fa-info-circle'></i>
                                                    </OverlayTrigger>
                                                )
                                            }


                                            <Dropdown onSelect={(e) => params.subjectOrDocumentAction('Category', item.cat_id, item.title, e, item.training_for)}>
                                                <Dropdown.Toggle variant="link">
                                                    <i className='fas fa-ellipsis-h'></i>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {
                                                        item.status == 'Publish' ?
                                                            <Dropdown.Item eventKey="Draft">Switch to Draft</Dropdown.Item>
                                                            :
                                                            <Dropdown.Item eventKey="Publish">Switch to Publish</Dropdown.Item>

                                                    }
                                                    <Dropdown.Item eventKey="Update">Rename</Dropdown.Item>
                                                    <Dropdown.Item eventKey="Delete">Delete</Dropdown.Item>
                                                    {
                                                        item.status == 'Publish' && (
                                                            <>
                                                                {
                                                                    item.onboard_training == 1 ?
                                                                        <Dropdown.Item disabled>OnBoard Training <i className='pl-3 fas fa-check'></i> </Dropdown.Item>
                                                                        :
                                                                        <Dropdown.Item eventKey="OnBoarding">Set OnBoard Training</Dropdown.Item>
                                                                }
                                                            </>

                                                        )
                                                    }

                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    )
                                }

                            </Accordion.Header>
                            <Accordion.Body className='p-1'>
                                {
                                    item.sub_cat.map((sub_item, k) => {

                                        return <Subject
                                            key={'sub' + i + '-' + k}
                                            cat_id={sub_item.cat_id}
                                            index={i}
                                            subindex={k}
                                            content={
                                                <ListGroup key={'list' + sub_item.cat_id} variant="flush">
                                                    {
                                                        sub_item.sub_cat.map((sub, m) => {
                                                            return <ListGroup.Item
                                                                disabled={sub.disabled}
                                                                key={sub.cat_id}
                                                                // onDragStart={(e) => dragStart(e, m)}
                                                                // onDragEnter={(e) => dragEnter(e, m)}
                                                                // onDragEnd={(e) => drop(e, i, k)}
                                                                // draggable
                                                                className={`${params.documents == sub.cat_id && ('active')} p-2`}
                                                            >
                                                                <Link to={`/trainings/${bcrypt(i)}/${bcrypt(item.cat_id)}/${bcrypt(k)}/${bcrypt(sub_item.cat_id)}/${bcrypt(m)}/${bcrypt(sub.cat_id)}`}>{sub.title} </Link>
                                                                {
                                                                    params.can_edit ? (
                                                                        <div className='document-action'>
                                                                            {
                                                                                sub.status != 'Publish' && (
                                                                                    <OverlayTrigger
                                                                                        placement="right"
                                                                                        delay={{ show: 250, hide: 400 }}
                                                                                        overlay={<Tooltip id="button-tooltip">
                                                                                            This document is unpublished
                                                                                        </Tooltip>}
                                                                                    >
                                                                                        <i className='fas fa-info-circle'></i>
                                                                                    </OverlayTrigger>
                                                                                )
                                                                            }

                                                                            
                                                                            <Dropdown onSelect={(e) => params.subjectOrDocumentAction('Document', sub.cat_id, sub.title, e, sub.training_for)}>
                                                                                <Dropdown.Toggle variant="link">
                                                                                    <i className='fas fa-ellipsis-h'></i>
                                                                                </Dropdown.Toggle>

                                                                                <Dropdown.Menu>
                                                                                    {
                                                                                        sub.status == 'Publish' ?
                                                                                            <Dropdown.Item eventKey="Draft">Switch to Draft</Dropdown.Item>
                                                                                            :
                                                                                            <Dropdown.Item eventKey="Publish">Switch to Publish</Dropdown.Item>

                                                                                    }
                                                                                    <Dropdown.Item eventKey="Update">Rename</Dropdown.Item>
                                                                                    <Dropdown.Item eventKey="Move">Move</Dropdown.Item>
                                                                                    <Dropdown.Item eventKey="Delete">Delete</Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>
                                                                    ) : (
                                                                        <div className='document-action d-flex'>
                                                                            <OverlayTrigger
                                                                                placement="auto"
                                                                                overlay={<Tooltip id="button-tooltip">
                                                                                    You have completed {sub.percentage}% of this document
                                                                                </Tooltip>}
                                                                            >
                                                                                <div className='dummy-box'></div>

                                                                            </OverlayTrigger>
                                                                         

                                                                            <Knob
                                                                                value={sub.percentage}
                                                                                fgColor="#008037"
                                                                                thickness={1}
                                                                                readOnly={true}
                                                                                height={12}
                                                                                width={12}
                                                                                onChange={e => {
                                                                                    console.log(e)
                                                                                }}
                                                                            />


                                                                        </div>
                                                                    )
                                                                }
                                                            </ListGroup.Item>
                                                        })
                                                    }
                                                </ListGroup>
                                            }
                                            subject={sub_item.title}
                                            isOpen={isOpen == 'sub' + i + '-' + k}
                                            toggle={toggleOpen('sub' + i + '-' + k)}
                                            status={sub_item.status}
                                            image={sub_item.image}
                                            user_id={sub_item.user_id}
                                            training_for={sub_item.training_for}
                                            master_id={item.cat_id}
                                            disabled={sub_item.disabled}

                                        />
                                    })
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                    })
                }
            </Accordion>
        </div>
    )
}