import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom";
import { ApiUrl } from "../../config";
import Context from './Context';
import swal from "sweetalert";
const Box1 = (props) => {
    const params = React.useContext(Context);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        submitData.append('basic_type', 'info');
        fetch(`${ApiUrl}` + "update/builder/setting/" + params.user_id, {
            method: "POST",
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    params.goToBox(params.currentBox + 1);
                    params.getBuilderSetting();

                    var sentence = params.setting.full_name ?? '';
                    var parts = sentence.split(" ");

                    var first_name = parts[0] || '';
                    var last_name = parts[1] || '';

                    if (parts.length > 2) {
                        // If there are more than two parts, combine them as the last name
                        last_name = parts.slice(1).join(" ");
                    }

                    let newtoken = {
                        first_name: first_name,
                        last_name: last_name,
                        name: sentence,
                        email: params.setting.email ?? '',
                        phone: params.setting.phone ?? '',
                        company_name: params.setting.company_name ?? ''
                    };

                    const authUser = {
                        ...JSON.parse(localStorage.getItem("authUser")),
                        ...newtoken,
                    };
                    localStorage.setItem("authUser", JSON.stringify(authUser));
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    return (
        <div>

            <div className="box-center-align mt-3">
                <div className="SmallContaner my-4">
                    <div className="d-flex align-items-center gap-2 StepHeading">
                        <div className="StepNumber">1 <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">Contact Information</h4>
                    </div>
                    <form method="post" onSubmit={handleSubmit} encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                        <div className="border-top border-bottom border-left border-right p-4 mt-3">
                            <div className="page-content-box">
                                <div className="TitleWizred d-flex align-items-center gap-2">
                                    <span className="fs-5"> a.</span> <h5>Answer each of the following questions exactly as you would like them to appear on your website.</h5>
                                </div>
                                <div className="WizredForm mt-4">
                                    <div className="form-group">
                                        <label>Full name</label>
                                        <input name="full_name" defaultValue={params.setting.full_name ?? ''} className="form-control no-border px-0" type="text" placeholder="Jane"></input>
                                    </div>
                                    <div className="form-group mt-4">
                                        <label>Phone number</label>
                                        <input name="phone" defaultValue={params.setting.phone ?? ''} className="form-control no-border px-0" type="text" placeholder="Jane"></input>
                                    </div>
                                    <div className="form-group mt-4">
                                        <label>Email</label>
                                        <input name="email" defaultValue={params.setting.email ?? ''} className="form-control no-border px-0" type="email" placeholder="name@example.com"></input>
                                    </div>
                                    <div className="form-group mt-4">
                                        <label>Company</label>
                                        <input name="company_name" defaultValue={params.setting.company_name ?? ''} className="form-control no-border px-0" type="text" placeholder="Acme Corporation"></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-lg btn-primary mt-2"> Next &nbsp;<i className="fas fa-check"></i></button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Box1;