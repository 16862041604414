import React from 'react'

import {
    Card,
    CardBody,
    Button,
} from "reactstrap";

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap";
import { bcrypt, uToken } from '../../useToken';
import { ApiUrl } from '../../config';
import { toast, ToastContainer, Flip } from "react-toastify";
export default class DemoApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            events: [],
            offDays: []
        };
    }
    calendarRef = React.createRef();

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        fetch(`${ApiUrl}` + "get/appointments/" + this.state.id, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },

            body: JSON.stringify({ id: this.state.id, coach_id: this.state.coach_id },
            ),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        events: response.data.records,
                        offDays: response.data.offDays
                    });
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    handleDateClick = arg => {
        // alert('date-click' + arg);
    }

    handleEventClick = arg => {
        let client_id = bcrypt(arg.event.extendedProps.client_id);
        let coach_id = bcrypt(arg.event.extendedProps.coach_id);
     //   window.location.href = '/client/dialer/' + client_id + '/' + coach_id;
        window.open(
            '/lead/dialer/' + client_id + '/' + coach_id,
            '_blank' // <- This is what makes it open in a new window.
          );
        // this.props.history.push('/client/dialer/' + client_id + '/' + coach_id)

    }

    renderEventContent(eventInfo) {
        if (eventInfo.view.type == 'timeGridWeek') {
            return <div className='event-content-timeGrid'>{eventInfo.timeText}</div>
        } else {
            return (
                <div className='event-content'>
                    <span className='event-label'><label>Time:</label><p>{eventInfo.event._def.extendedProps.time}</p></span>
                    <span className='event-label'><label>Title:</label> <p>{eventInfo.event.title}</p></span>
                    <span className='event-label'><label>With: </label><p>{eventInfo.event._def.extendedProps.with}</p></span>
                    <span className='event-label'><label>By: </label><p>{eventInfo.event._def.extendedProps.by}</p></span>
                </div>
            )
        }
    }

    render() {
        const date = new Date();
        const d = date.getDate();
        const m = date.getMonth();
        const y = date.getFullYear();
        const self = this;
        return (
            <div className='appointment-calander'>

                <div className='row p-3'>
                    <div className="col-md-8">
                        <label className='mr-5 ml-5'>Completed
                            <span style={{ background: "Green", display: 'block' }}>&nbsp;</span>
                        </label>
                        <label className='mr-5 ml-5'>Expired
                            <span style={{ background: "Red", display: 'block' }}>&nbsp;</span>
                        </label>
                        <label className='mr-5 ml-5'>Off Days
                            <span style={{ background: "Gray", display: 'block' }}>&nbsp;</span>
                        </label>
                        {/* <label className='mr-5 ml-5'>Busy
                            <span style={{ background: "#BD3539", display: 'block' }}>&nbsp;</span>
                        </label> */}
                        <label className='ml-5'>Appointment
                            <span style={{ background: "Blue", display: 'block' }}>&nbsp;</span>
                        </label>
                    </div>
                         
                    <div className='col-md-4'>
                        <div className="text-align-right">
                            <Button
                                type="button"
                                color="secondary"
                                onClick={() => this.props.history.goBack()}
                            >
                                Back
                            </Button>
                        </div>
                    </div>
                </div>

                <div className='calander-full appointmentList'>
                    <Card>
                        <CardBody>
                            <FullCalendar
                                height={'auto'}
                                plugins={[
                                    BootstrapTheme,
                                    dayGridPlugin,
                                    timeGridPlugin,
                                    interactionPlugin
                                ]}
                                handleWindowResize={false}
                                themeSystem="bootstrap"
                                headerToolbar={{
                                    left: "prev,next today",
                                    center: "title",
                                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                                }}
                                events={this.state.events}
                                dayCellClassNames={function (arg) {
                                    if (self.state.offDays.includes(arg.dow)) {
                                        return ['blockdays']
                                    } else {
                                        return ['normaldays']
                                    }
                                }}
                                eventContent={this.renderEventContent}
                                editable={false}
                                droppable={false}
                                dateClick={this.handleDateClick}
                                eventClick={this.handleEventClick}
                                ref={this.calendarRef}

                            />
                        </CardBody>
                    </Card>
                </div>

            </div>
        )
    }
}