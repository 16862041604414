import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    Button,
    ModalBody,
    ModalFooter,
    CardBody,
} from "reactstrap";

import {
    dcrypt,
    bcrypt,
    uToken,
    isValidJSONString,
} from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import CampaignNav from "./nav";

import $ from "jquery"
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { AvField, AvForm } from "availity-reactstrap-validation";
import swal from 'sweetalert';
export default class CampaignIvrUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id
                ? dcrypt(this.props.match.params.id)
                : null,
            ivrId: this.props.match.params.ivr_id
                ? dcrypt(this.props.match.params.ivr_id)
                : null,
            page_name: "Campaign Ivr",
            from: this.props.from ? this.props.from : "",
            metaData: {
                voiceTemplates: [],
                ivrFlow: {}
            },
            AfterFields: [],
            greeting: {
                content: 1
            }
        };
    }

    componentDidMount() {
        this.getFormData();
        // this.getIvrMenu();
        // this.state.id != null && this.getData();
    }


    addClickAfter() {
        this.setState(prevState => ({
            AfterFields: [...prevState.AfterFields, { flow_type: "", [`w_id`]: "0" }]
        }))
    }


    createUI() {
        return this.state.AfterFields.map((el, i) => (
            <div className={`py-3 px-2 ${el.w_id ? el.w_id : 'd-none'}`} key={i} id={`element_after_${i}`}>
                <div className="row">

                    <input type={'hidden'} name={`wid[${i}][w_id]`} value={el.w_id} />

                    {
                        i == 0 ? (
                            <>
                                <div className="col-md-3 mb-3">
                                    <div className="form-group">
                                        <label className="control-label">If no number is pressed, play IVR Greeting</label>
                                    </div>
                                </div>

                                <div className="col-md-1 mb-3">
                                    <select
                                        defaultValue={this.state.AfterFields[i].repeat_time || '1'}
                                        className="form-control " name={`wid[${i}][keyopt]`}  >
                                        <option value="1">1</option>
                                        <option value="2" >2</option>
                                        <option value="3">3</option>
                                    </select>
                                </div>

                                <div className="col-md-2 mb-3">
                                    <input readOnly type="button" value="times,then" className="form-control" />

                                    <input readOnly type="hidden" name={`wid[${i}][default]`} value="1" className="form-control" />
                                </div>
                            </>

                        ) : (
                            <>
                                <div className="col-md-3 mb-3">
                                    <div className="form-group">
                                        <label className="control-label">When the caller presses
                                            <button type="button" className="btn-link">
                                                <i onClick={this.removeClickAfter.bind(this, i, el.w_id)} className="fa fa-times" aria-hidden="true"></i></button>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-1 mb-3">
                                    <select defaultValue={this.state.AfterFields[i].keypress || '1'} className="form-control " name={`wid[${i}][keyopt]`}  >
                                        <option value="1">1</option>
                                        <option value="2" >2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="0">0</option>
                                    </select>
                                </div>

                                <div className="col-md-1 mb-3">
                                    <input readOnly type="button" value="then" className="form-control" />
                                    <input readOnly name={`wid[${i}][default]`} type="hidden" value="0" className="form-control" />
                                </div>

                            </>

                        )
                    }



                    <div className="col-md-2">
                        <div className="form-group">
                            <select className="select form-control"
                                type="select"
                                name={`wid[${i}][flow_type]`}
                                onChange={this.handleChangeAfter.bind(this, i)}
                                defaultValue={this.state.AfterFields[i].flow_type == 'Menu' ? 'Hangup' : this.state.AfterFields[i].flow_type || ''}
                                required
                            >
                                <option value="">Select Call Flow</option>
                                <optgroup label="IVR Options">
                                    <option value="Forward">Forward To</option>
                                    <option value="Voicemail">Voicemail</option>
                                    <option value="Greeting">Greeting</option>
                                    {
                                        i != 0 && (
                                            <option value="SMS">SMS</option>
                                        )
                                    }
                                    <option value="Connect_To_Agent">Connect To Agent</option>
                                    <option value="Unsubscribe">Unsubscribe</option>
                                    <option value="Hangup">Hangup/End Call</option>
                                </optgroup>
                                {/* <optgroup label="My Call Flows">
                                    <option value="1">IVR - Migration Campaign -56057 (IVR)</option>
                                </optgroup> */}
                            </select>
                        </div>
                    </div>

                    {this.addContent(i, this.state.AfterFields[i].flow_type)}


                    {
                        i != 0 && (
                            <div className="col-md-2">
                                <div className="form-group">
                                    <select defaultValue={this.state.AfterFields[i].tag_id} name={`wid[${i}][tag_id]`} className="form-control">
                                        <option value="">Select Tag</option>
                                        {
                                            this.state.metaData.tags.map((row, i) => {
                                                return <option key={'agent' + i} value={row.group_id}>{row.title}</option>
                                            })
                                        }

                                    </select>
                                </div>
                            </div>
                        )}


                </div>
            </div>
        ))
    }


    handleChangeAfter(i, e) {
        const { name, value } = e.target;
        let AfterFields = [...this.state.AfterFields];
        AfterFields[i] = { ...AfterFields[i], [name]: value };
        AfterFields[i] = { ...AfterFields[i], [`flow_type`]: value };
        this.setState({ AfterFields });
    }


    addContent = (i, type) => {
        if (type == 'Forward') {
            return (
                <>
                    <div className="col-md-1"  >
                        <select defaultValue={this.state.AfterFields[i].country_code}
                            name={`wid[${i}][country_code]`} className="form-control">
                            <option value="+1" >+1</option>
                            <option value="+61" >+61</option>
                            <option value="+44" >+44</option>
                            <option value="+64" >+64</option>
                        </select>
                    </div>
                    <div className="col-md-2">
                        <input defaultValue={this.state.AfterFields[i].content} type="text" className="form-control" name={`wid[${i}][content]`} maxLength="10" />
                    </div>
                </>
            )
        } else if (type == 'Voicemail') {
            return (
                <>
                    <div className="col-md-3" >
                        <select defaultValue={this.state.AfterFields[i].content} name={`wid[${i}][content]`} className="form-control">
                            <option value="">Select Voicemail</option>
                            {
                                this.state.metaData.voiceTemplates.map((row, i) => {
                                    return <option key={'voice_' + i} value={row.id}>{row.name}</option>
                                })
                            }

                        </select>
                    </div>
                </>
            )
        } else if (type == 'Greeting') {
            return (
                <>
                    <div className="col-md-3" >
                        <select defaultValue={this.state.AfterFields[i].content} name={`wid[${i}][content]`} className="form-control">
                            <option value="">Select Greeting</option>
                            {
                                this.state.metaData.voiceTemplates.map((row, i) => {
                                    return <option key={'greeting_' + i} value={row.id}>{row.name}</option>
                                })
                            }

                        </select>
                    </div>
                </>
            )
        } else if (type == 'SMS') {
            return (
                <>
                    <div className="col-md-3" >
                        <select defaultValue={this.state.AfterFields[i].content} name={`wid[${i}][content]`} className="form-control">
                            <option value="">Select SMS</option>
                            {
                                this.state.metaData.smsTemplates.map((row, i) => {
                                    return <option key={'sms_' + i} value={row.id}>{row.name}</option>
                                })
                            }

                        </select>
                    </div>
                </>
            )
        } else if (type == 'Connect_To_Agent') {
            return (
                <>
                    <div className="col-md-3" >
                        <select defaultValue={this.state.AfterFields[i].content} name={`wid[${i}][content]`} className="form-control">
                            <option value="">Select Agent</option>
                            {
                                this.state.metaData.callerGroup.map((row, i) => {
                                    return <option key={'agent' + i} value={row.id}>{row.name}</option>
                                })
                            }

                        </select>
                    </div>
                </>
            )
        }
    }


    handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);


        const submitData = new FormData();
        submitData.append('ivr_id', this.state.ivrId);
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        fetch(`${ApiUrl}` + "update/ivr/menu", {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data, "Editing");
                if (data.status === true) {
                    this.getFormData();
                    //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                    swal({
                        text: 'Action performed successfully',
                        icon: "success",
                    });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    getFormData = () => {
        fetch(`${ApiUrl}` + "ivr/metadata", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({
                id: this.state.id,
                ivr_id: this.state.ivrId
            }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        metaData: response.data,
                        AfterFields: response.data.ivrWidget
                    });

                    if (response.data.ivrWidget.length > 0) {
                        let defaultGretting = response.data.ivrWidget[0];
                        let greeting = defaultGretting;
                        this.setState({
                            greeting: greeting
                        })
                    }
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    removeClickAfter(i, w_id) {
        let AfterFields = [...this.state.AfterFields];
        AfterFields[i] = {};
        $('#element_after_' + i).html('');
        this.setState({ AfterFields });

        if (w_id != '0') {
            this.removeIvrWidget(w_id);
        }
    }

    removeIvrWidget = (w_id) => {
        fetch(`${ApiUrl}` + "delete/ivr/widget", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({
                id: w_id,
            }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    this.getFormData();
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    render() {
        return (
            <React.Fragment>
                <ToastContainer />

                <Row>
                    <Col sm={12}>
                        <div className="page-title-box text-align-right">
                            <Button
                                type="button"
                                color="secondary"
                                onClick={() => this.props.history.push('/campaign/inbound/' + bcrypt(this.state.id))}
                            >
                                Back
                            </Button>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col className="col-12">
                        <Card>
                            <CampaignNav activeTab={'2'} campaignId={bcrypt(this.state.id)} />

                            <CardBody>

                                <div className="row">
                                    <div className="col-md-10 offset-2">
                                        <form encType="multipart/form-data" className="form-horizontal" method="POST" onSubmit={this.handleSubmit}>
                                            <input name="id" value={this.state.id} type="hidden" />
                                            <input name="default_flow" value={this.state.defaultFlow} type="hidden" />

                                            <div className="row mb-3">
                                                <label className="col-md-3">Title</label>
                                                <div className="col-md-7">
                                                    <input defaultValue={this.state.metaData.ivrFlow.ivr_title} placeholder="Enter label" name={`ivr_title`} required="" type="text"
                                                        className="form-control" />
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label className="col-md-3">Greeting File</label>
                                                <div className="col-md-7">
                                                    {this.state.greeting && (
                                                        <select name={`content_main`} className="form-control">
                                                            <option value="">Select Voicemail</option>
                                                            {
                                                                this.state.metaData.voiceTemplates.map((row, i) => {
                                                                    return <option selected={row.id == this.state.greeting.content_main ? true : false} key={'voice_' + i} value={row.id}>{row.name} ({row.type}) </option>
                                                                })
                                                            }

                                                        </select>
                                                    )}
                                                </div>
                                            </div>


                                            <div className="row mb-3">
                                                <div className="col-md-12">
                                                    {this.createUI()}
                                                </div>
                                            </div>

                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm"
                                                onClick={this.addClickAfter.bind(this)}>
                                                New IVR Prompt
                                            </button>







                                            <div className="row">
                                                <div className="col-md-6 offset-2">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-info btn-sm mt-4 width-100-percent"
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>


                            </CardBody>

                        </Card>
                    </Col>
                </Row>
            </React.Fragment >
        );
    }
}
