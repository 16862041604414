import React, { useState, useEffect} from "react"
import { Link } from "react-router-dom";
import { ApiUrl, AttachementsUrl } from "../../../config";
import Context from './Context';
import swal from "sweetalert";
import { dcrypt,bcrypt, uInfo, uToken } from "../../../useToken";
import {
    Modal,
    Card,
    CardBody,
    Button,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ImageCropper from "./ImageCropper";
const Box6_2 = (props) => {
    const params = React.useContext(Context);
    const [modal, setModal] = useState(false);
    const [record, setRecord] =  useState({});
    const [imageData, setImageData] = useState({
        id: '',
        url: '',
    });

    const [inputFields, setInputFields] = useState(
        { preview_hero_image: '' }
    );
    useEffect(() => {
        getData();
    }, []);
    const getData = () => {
        fetch(`${ApiUrl}` + "get/systemForm/resourceInfo", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
              },
            body: JSON.stringify({ id: params.form_data.id, user_id: uInfo().user_id}),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    //console.log(response.data.record, 'response.data.record')
                    setRecord(response.data.record);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        submitData.append('user_id', uInfo().user_id);
        submitData.append('id', params.form_data.id);
        if (inputFields.preview_hero_image != '') {
            submitData.append('preview_hero_image_base_64', inputFields.preview_hero_image);
        }


        fetch(`${ApiUrl}` + "update/system/form", {
            method: "POST",
            body: submitData,
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    swal({
                        title: "Action performed successfully",
                        icon: "success",
                    });
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const changeHandlerImg = (e, id) => {
        if (e.target.files[0].type.match('image.*')) {
            const preview = document.querySelector('#' + id);
            var reader = new FileReader();
            reader.onload = () => {
                preview.src = reader.result;
                imageCropper(id, reader.result)
            };
            reader.readAsDataURL(e.target.files[0]);
        } else {
            swal({
                title: 'Olny images allowed',
                icon: "warning",
            });
        }
    };

    const imageCropper = (id, url) => {
        setImageData({
            id: id,
            url: url
        });
        setTimeout(() => {
            setModal(true);
        }, 200);
    }


    const imageCropperSet = (id, url) => {
        const preview = document.querySelector('#' + id);
        preview.src = url;

        setInputFields({ ...inputFields, [id]: url })

        setModal(false);
    }

    const closeModal = () => {
        setInputFields({ ...inputFields, [imageData.id]: imageData.url })
        setModal(false);
    }
    return (
        <div>
            <div className="mt-1">
                <div className="d-flex align-items-center gap-2 StepHeading">
                    <div className="StepNumber">{params.form_data.id} <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">Graphic Uploads / Selections</h4>
                </div>
                <form method="post" onSubmit={handleSubmit}
                    encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                    <div className="border-right p-2 mt-1">
                        <div className="page-content-box">
                            <div className="TitleWizred d-flex align-items-center gap-2">
                                <h5>Select the background for your main hero image
                                </h5>
                            </div>
                            <div className="TitleWizred d-flex align-items-center gap-2">
                                <label>
                                    <small>Note: your background will have a filter based on your theme color selections
                                        and will have your personal photo superimposed in front of the background you select.</small>
                                </label>
                            </div>
                            <div className="WizredForm">
                                <div className="form-group">
                                {record.own_hero_image && (
                                        <>
                                    <div class="row parent themeSelection p-1"  style={{overflow: "auto",height: "210px"}}>
                                        <div className="form-group mt-1">
                                            <div className="radio-box mt-3">
                                                <label>Do you have an own hero image?</label>
                                                <br />
                                                <div class="form-check form-check-inline">
                                                    <input name="own_hero_image" defaultChecked={record.own_hero_image == 'Yes' ? true : false}
                                                        onChange={() => 
                                                            setRecord((prevState) => ({
                                                                ...prevState,
                                                                ['own_hero_image']: 'Yes',
                                                            }))
                                                        }
                                                        class="form-check-input" type="radio" id="inlineRadio1" value="Yes" />
                                                    <label class="form-check-label" for="inlineRadio1">Yes</label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input name="own_hero_image" defaultChecked={record.own_hero_image == 'No' ? true : false}
                                                        onChange={() => 
                                                            setRecord((prevState) => ({
                                                                ...prevState,
                                                                ['own_hero_image']: 'No',
                                                            }))
                                                        }
                                                        class="form-check-input" type="radio" id="inlineRadio2" value="No" />
                                                    <label class="form-check-label" for="inlineRadio2">No</label>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            record.own_hero_image == 'Yes' && (
                                                <div className="form-group mt-4">
                                                    <label>If you have your own background hero image, upload here <note className="text-muted">(Preferably size 1920 x 760)</note></label>
                                                    <input name="hero_image" className="form-control no-border px-0"
                                                        accept="image/*"
                                                        type="file" placeholder="https://"
                                                        onChange={(e) => changeHandlerImg(e, 'preview_hero_image')}
                                                    />
                                                    <img width={'50'} id="preview_hero_image" src={record.hero_image} className="img img-thumbnail mt-1" alt="Not Found" />
                                                </div>
                                            )
                                        }

                                        {
                                            record.own_hero_image == 'No' && (

                                                <div class="row">

                                                    <div class='col-sm-3 '>
                                                        <div className="theme-img justify-content-center">
                                                            <input type="radio" name="backgroung_hero_image" defaultChecked={record.backgroung_hero_image == 'corporate-option-1.png' ? true : false} id="backgroung_hero_image1" class="d-none bgimgbgchk" value="corporate-option-1.png" />
                                                            <label for="backgroung_hero_image1">
                                                                <div className="hero-img-area">
                                                                    <img src={`${AttachementsUrl}/default/images/Background-Options/corporate-option-1.png`} alt="Image 1" />
                                                                </div>
                                                                <div class="tick_container">
                                                                    <div class="tick"><i class="fa fa-check"></i></div>
                                                                </div>
                                                                <div className="img-footer">
                                                                    <label className="img-box">A</label>
                                                                    <p>Corporate Option 1</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class='col-sm-3 '>
                                                        <div className="theme-img justify-content-center">
                                                            <input type="radio" name="backgroung_hero_image" defaultChecked={record.backgroung_hero_image == 'landscape-option-1.png' ? true : false} id="backgroung_hero_image2" class="d-none bgimgbgchk" value="landscape-option-1.png" />
                                                            <label for="backgroung_hero_image2">
                                                                <div className="hero-img-area">
                                                                    <img src={`${AttachementsUrl}/default/images/Background-Options/landscape-option-1.png`} alt="Image 1" />
                                                                </div>
                                                                <div class="tick_container">
                                                                    <div class="tick"><i class="fa fa-check"></i></div>
                                                                </div>
                                                                <div className="img-footer">
                                                                    <label className="img-box">B</label>
                                                                    <p>Landscape Option 1</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div class='col-sm-3 '>
                                                        <div className="theme-img justify-content-center">
                                                            <input type="radio" name="backgroung_hero_image" defaultChecked={record.backgroung_hero_image == 'office-option-1.png' ? true : false} id="backgroung_hero_image3" class="d-none bgimgbgchk" value="office-option-1.png" />
                                                            <label for="backgroung_hero_image3">
                                                                <div className="hero-img-area">
                                                                    <img src={`${AttachementsUrl}/default/images/Background-Options/office-option-1.png`} alt="Image 1" />
                                                                </div>
                                                                <div class="tick_container">
                                                                    <div class="tick"><i class="fa fa-check"></i></div>
                                                                </div>
                                                                <div className="img-footer">
                                                                    <label className="img-box">C</label>
                                                                    <p>Office Option 1</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div class='col-sm-3 '>
                                                        <div className="theme-img justify-content-center">
                                                            <input type="radio" name="backgroung_hero_image" defaultChecked={record.backgroung_hero_image == 'office-option-2.png' ? true : false} id="backgroung_hero_image4" class="d-none bgimgbgchk" value="office-option-2.png" />
                                                            <label for="backgroung_hero_image4">
                                                                <div className="hero-img-area">
                                                                    <img src={`${AttachementsUrl}/default/images/Background-Options/office-option-2.png`} alt="Image 1" />
                                                                </div>
                                                                <div class="tick_container">
                                                                    <div class="tick"><i class="fa fa-check"></i></div>
                                                                </div>
                                                                <div className="img-footer">
                                                                    <label className="img-box">D</label>
                                                                    <p>Office Option 2</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div class='col-sm-3 '>
                                                        <div className="theme-img justify-content-center">
                                                            <input type="radio" name="backgroung_hero_image" defaultChecked={record.backgroung_hero_image == 'skyline-option-1.png' ? true : false} id="backgroung_hero_image5" class="d-none bgimgbgchk" value="skyline-option-1.png" />
                                                            <label for="backgroung_hero_image5">
                                                                <div className="hero-img-area">
                                                                    <img src={`${AttachementsUrl}/default/images/Background-Options/skyline-option-1.png`} alt="Image 1" />
                                                                </div>
                                                                <div class="tick_container">
                                                                    <div class="tick"><i class="fa fa-check"></i></div>
                                                                </div>
                                                                <div className="img-footer">
                                                                    <label className="img-box">E</label>
                                                                    <p>Skyline Option 1</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div class='col-sm-3 '>
                                                        <div className="theme-img justify-content-center">
                                                            <input type="radio" name="backgroung_hero_image" defaultChecked={record.backgroung_hero_image == 'skyline-option-2.png' ? true : false} id="backgroung_hero_image6" class="d-none bgimgbgchk" value="skyline-option-2.png" />
                                                            <label for="backgroung_hero_image6">
                                                                <div className="hero-img-area">
                                                                    <img src={`${AttachementsUrl}/default/images/Background-Options/skyline-option-2.png`} alt="Image 1" />
                                                                </div>
                                                                <div class="tick_container">
                                                                    <div class="tick"><i class="fa fa-check"></i></div>
                                                                </div>
                                                                <div className="img-footer">
                                                                    <label className="img-box">F</label>
                                                                    <p>Skyline Option 2</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div class='col-sm-3 '>
                                                        <div className="theme-img justify-content-center">
                                                            <input type="radio" name="backgroung_hero_image" defaultChecked={record.backgroung_hero_image == 'speaker-option-1.png' ? true : false} id="backgroung_hero_image9" class="d-none bgimgbgchk" value="speaker-option-1.png" />
                                                            <label for="backgroung_hero_image9">
                                                                <div className="hero-img-area">
                                                                    <img src={`${AttachementsUrl}/default/images/Background-Options/speaker-option-1.png`} alt="Image 1" />
                                                                </div>
                                                                <div class="tick_container">
                                                                    <div class="tick"><i class="fa fa-check"></i></div>
                                                                </div>
                                                                <div className="img-footer">
                                                                    <label className="img-box">G</label>
                                                                    <p>Speaker Option 1</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div class='col-sm-3 '>
                                                        <div className="theme-img justify-content-center">
                                                            <input type="radio" name="backgroung_hero_image" defaultChecked={record.backgroung_hero_image == 'speaker-option-2.png' ? true : false} id="backgroung_hero_image7" class="d-none bgimgbgchk" value="speaker-option-2.png" />
                                                            <label for="backgroung_hero_image7">
                                                                <div className="hero-img-area">
                                                                    <img src={`${AttachementsUrl}/default/images/Background-Options/speaker-option-2.png`} alt="Image 1" />
                                                                </div>
                                                                <div class="tick_container">
                                                                    <div class="tick"><i class="fa fa-check"></i></div>
                                                                </div>
                                                                <div className="img-footer">
                                                                    <label className="img-box">H</label>
                                                                    <p>Speaker Option 2</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div class='col-sm-3 '>
                                                        <div className="theme-img justify-content-center">
                                                            <input type="radio" name="backgroung_hero_image" defaultChecked={record.backgroung_hero_image == 'speaker-option-3.png' ? true : false} id="backgroung_hero_image8" class="d-none bgimgbgchk" value="speaker-option-3.png" />
                                                            <label for="backgroung_hero_image8">
                                                                <div className="hero-img-area">
                                                                    <img src={`${AttachementsUrl}/default/images/Background-Options/speaker-option-3.png`} alt="Image 1" />
                                                                </div>
                                                                <div class="tick_container">
                                                                    <div class="tick"><i class="fa fa-check"></i></div>
                                                                </div>
                                                                <div className="img-footer">
                                                                    <label className="img-box">I</label>
                                                                    <p>Speaker Option 3</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        }
                                        
                                    </div>
                                    </>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                    <button type="submit" className="btn btn-sm btn-primary mt-2"> Ok &nbsp;<i className="fas fa-check"></i></button>
                </form>
            </div>
            <Modal
                isOpen={modal}
                fade={true}
                size="lg"
                // className="subActionTraining websitebuilder"
                toggle={() => closeModal()}
                centered={true}
                backdrop={'static'}
                style={{height: "calc(156vh - 186px) !important"}}
            >
                <div className="">
                    <div className='modal-header'>
                        <div className='modal-title'>
                            Crop Image
                        </div>
                        {/* <button onClick={() => closeModal()} className="mb-3 btn btn-link pull-right BtnCenterClose"><i className="fas fa-times"></i></button> */}
                    </div>

                    <ImageCropper closeModal={closeModal} imageCropperSet={imageCropperSet} data={imageData} />



                </div>
            </Modal>
        </div >
    );
};

export default Box6_2;