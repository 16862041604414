import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  CardImg,
  CardText,
  ModalHeader,
  Nav,
  CardImgOverlay,
  NavItem,
  NavLink,
  Input,
  TabContent,
  TabPane,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Badge from "react-bootstrap/Badge";
import Breadcrumb from "../../../../components/Common/Breadcrumb";
import img1 from "./img-1.jpg";
import ProfileSettings from "../../../Authentication/profile-sidebar";
//Import Action to copy breadcrumb items from local state to redux state
import "react-toastify/dist/ReactToastify.css";
import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../../useToken";
import { ApiUrl, ProductName } from "../../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";

export default class smstemplate extends Component {
  constructor(props) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    super(props);
    this.state = {
      total_pages: 0,
      tab: "1",
      records: [],
      id: obj.user_id,
      record: {},
      recordrvm: {},
      page_name: this.props.match.params.id ? "Templates" : "Templates",
    };
  }

  toggle(index) {
    if (index == "1") {
      this.props.history.push("/emailtemplates/list");
    }
    if (index == "2") {
      this.props.history.push("/smstemplates/list");
    }
    if (index == "3") {
      this.props.history.push("/voicetemplates/list");
    }
    if (index == "4") {
      this.props.history.push("/scripttemplates/list");
    }
  }
  componentDidMount() { }
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.page_name} </h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#"> {this.state.page_name} </Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <button
                className="btn btn-primary btn-md"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </button>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <h3> Choose Template </h3>
              </CardBody>
              <Row>
                <Col lg={3}>
                  <Link
                    to={"/emailtemplate/create/0"}
                    className="waves-effect TemplateCard btn btn-light"
                  >
                    <Card>
                      <div className="CardImages">
                        <CardImg
                          top
                          className="img-fluid"
                          src={img1}
                          alt="Lexa"
                        />
                      </div>
                      <CardBody>
                        <CardTitle className="h4"> Blank Template </CardTitle>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col lg={3}>
                  <Link
                    to={"/emailtemplate/create/1"}
                    className="waves-effect TemplateCard btn btn-light"
                  >
                    <Card>
                      <div className="CardImages">
                        <CardImg
                          top
                          className="img-fluid"
                          src={"https://cdn.templates.unlayer.com/previews/subscription-software-re-engagement/1650872866749.png"}
                          alt="Lexa"
                        />
                      </div>
                      <CardBody>
                        <CardTitle className="h4"> Subscription software re-engagement </CardTitle>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col lg={3}>
                  <Link
                    to={"/emailtemplate/create/2"}
                    className="waves-effect TemplateCard btn btn-light"
                  >
                    <Card>
                      <div className="CardImages">
                        <CardImg
                          top
                          className="img-fluid"
                          src={"https://cdn.templates.unlayer.com/previews/apology-email-to-company-boss/1644874149048.png"}
                          alt="Lexa"
                        />
                      </div>
                      <CardBody>
                        <CardTitle className="h4"> Apology to Company Boss </CardTitle>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>

                <Col lg={3}>
                  <Link
                    to={"/emailtemplate/create/3"}
                    className="waves-effect TemplateCard btn btn-light"
                  >
                    <Card>
                      <div className="CardImages">
                        <CardImg
                          top
                          className="img-fluid"
                          src={"https://cdn.templates.unlayer.com/previews/juneteenth-invitation/1653390513342.png"}
                          alt="Lexa"
                        />
                      </div>
                      <CardBody>
                        <CardTitle className="h4"> Invitation </CardTitle>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col lg={3}>
                  <Link
                    to={"/emailtemplate/create/4"}
                    className="waves-effect TemplateCard btn btn-light"
                  >
                    <Card>
                      <div className="CardImages">
                        <CardImg
                          top
                          className="img-fluid"
                          src={"https://cdn.templates.unlayer.com/previews/fathers-day-perfume-sale/1655119497045.png"}
                          alt="Lexa"
                        />
                      </div>
                      <CardBody>
                        <CardTitle className="h4"> Father's Day Sale </CardTitle>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col lg={3}>
                  <Link
                    to={"/emailtemplate/create/5"}
                    className="waves-effect TemplateCard btn btn-light"
                  >
                    <Card>
                      <div className="CardImages">
                        <CardImg
                          top
                          className="img-fluid"
                          src={"https://cdn.templates.unlayer.com/previews/natural-products-promotion/1625734477417.png"}
                          alt="Lexa"
                        />
                      </div>
                      <CardBody>
                        <CardTitle className="h4"> Product Promotion </CardTitle>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col lg={3}>
                  <Link
                    to={"/emailtemplate/create/6"}
                    className="waves-effect TemplateCard btn btn-light"
                  >
                    <Card>
                      <div className="CardImages">
                        <CardImg
                          top
                          className="img-fluid"
                          src={"https://cdn.templates.unlayer.com/previews/juneteenth-pre-school-activates/1653390690738.png"}
                          alt="Lexa"
                        />
                      </div>
                      <CardBody>
                        <CardTitle className="h4"> Pre School Activities</CardTitle>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col lg={3}>
                  <Link
                    to={"/emailtemplate/create/7"}
                    className="waves-effect TemplateCard btn btn-light"
                  >
                    <Card>
                      <div className="CardImages">
                        <CardImg
                          top
                          className="img-fluid"
                          src={"https://cdn.templates.unlayer.com/previews/corporate-anniversary/1653391908271.png"}
                          alt="Lexa"
                        />
                      </div>
                      <CardBody>
                        <CardTitle className="h4"> Corporate Aniversary </CardTitle>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col lg={3}>
                  <Link
                    to={"/emailtemplate/create/8"}
                    className="waves-effect TemplateCard btn btn-light"
                  >
                    <Card>
                      <div className="CardImages">
                        <CardImg
                          top
                          className="img-fluid"
                          src={"https://cdn.templates.unlayer.com/previews/past-due-invoice/1650874185850.png"}
                          alt="Lexa"
                        />
                      </div>
                      <CardBody>
                        <CardTitle className="h4"> Past Due Invoice</CardTitle>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col lg={3}>
                  <Link
                    to={"/emailtemplate/create/9"}
                    className="waves-effect TemplateCard btn btn-light"
                  >
                    <Card>
                      <div className="CardImages">
                        <CardImg
                          top
                          className="img-fluid"
                          src={"https://cdn.templates.unlayer.com/previews/birthday-discounts/1655875909491.png"}
                          alt="Lexa"
                        />
                      </div>
                      <CardBody>
                        <CardTitle className="h4">Birthday Discounts</CardTitle>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col lg={3}>
                  <Link
                    to={"/emailtemplate/create/10"}
                    className="waves-effect TemplateCard btn btn-light"
                  >
                    <Card>
                      <div className="CardImages">
                        <CardImg
                          top
                          className="img-fluid"
                          src={"https://cdn.templates.unlayer.com/assets/1637569455026-2867961.png"}
                          alt="Lexa"
                        />
                      </div>
                      <CardBody>
                        <CardTitle className="h4"> Product Delivery Notification Email</CardTitle>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col lg={3}>
                  <Link
                    to={"/emailtemplate/create/11"}
                    className="waves-effect TemplateCard btn btn-light"
                  >
                    <Card>
                      <div className="CardImages">
                        <CardImg
                          top
                          className="img-fluid"
                          src={"https://cdn.templates.unlayer.com/assets/1623070126558-ssdf.png"}
                          alt="Lexa"
                        />
                      </div>
                      <CardBody>
                        <CardTitle className="h4"> Invoice Payment Reminder</CardTitle>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col lg={3}>
                  <Link
                    to={"/emailtemplate/create/12"}
                    className="waves-effect TemplateCard btn btn-light"
                  >
                    <Card>
                      <div className="CardImages">
                        <CardImg
                          top
                          className="img-fluid"
                          src={"https://uploads-ssl.webflow.com/5daaade3e3e3f04da71daa8e/60684efe4996201d8e89fc1b_1617448699956.png"}
                          alt="Lexa"
                        />
                      </div>
                      <CardBody>
                        <CardTitle className="h4"> Receipt Email</CardTitle>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col lg={3}>
                  <Link
                    to={"/emailtemplate/create/13"}
                    className="waves-effect TemplateCard btn btn-light"
                  >
                    <Card>
                      <div className="CardImages">
                        <CardImg
                          top
                          className="img-fluid"
                          src={"https://uploads-ssl.webflow.com/5daaade3e3e3f04da71daa8e/61db14f03d472e30535afee0_1641747573568.png"}
                          alt="Lexa"
                        />
                      </div>
                      <CardBody>
                        <CardTitle className="h4"> Order Delivery Status</CardTitle>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>

                <Col lg={3}>
                  <Link
                    to={"/emailtemplate/create/14"}
                    className="waves-effect TemplateCard btn btn-light"
                  >
                    <Card>
                      <div className="CardImages">
                        <CardImg
                          top
                          className="img-fluid"
                          src={"https://uploads-ssl.webflow.com/5daaade3e3e3f04da71daa8e/60c74f91e41fbf9ae629d6c2_1623674754420.png"}
                          alt="Lexa"
                        />
                      </div>
                      <CardBody>
                        <CardTitle className="h4">Appointment Reminder</CardTitle>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col lg={3}>
                  <Link
                    to={"/emailtemplate/create/15"}
                    className="waves-effect TemplateCard btn btn-light"
                  >
                    <Card>
                      <div className="CardImages">
                        <CardImg
                          top
                          className="img-fluid"
                          src={"https://uploads-ssl.webflow.com/5daaade3e3e3f04da71daa8e/6268ff1874f8e846cd933f56_1650877950050.png"}
                          alt="Lexa"
                        />
                      </div>
                      <CardBody>
                        <CardTitle className="h4">Corporate Meeting</CardTitle>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col lg={3}>
                  <Link
                    to={"/emailtemplate/create/16"}
                    className="waves-effect TemplateCard btn btn-light"
                  >
                    <Card>
                      <div className="CardImages">
                        <CardImg
                          top
                          className="img-fluid"
                          src={"https://uploads-ssl.webflow.com/5daaade3e3e3f04da71daa8e/606068cac64c343977cc6e97_1616931012580.png"}
                          alt="Lexa"
                        />
                      </div>
                      <CardBody>
                        <CardTitle className="h4">Business Services</CardTitle>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>

                <Col lg={3}>
                  <Link
                    to={"/emailtemplate/create/17"}
                    className="waves-effect TemplateCard btn btn-light"
                  >
                    <Card>
                      <div className="CardImages">
                        <CardImg
                          top
                          className="img-fluid"
                          src={"https://uploads-ssl.webflow.com/5daaade3e3e3f04da71daa8e/60bf41d937cb951617993a13_1623146265929.png"}
                          alt="Lexa"
                        />
                      </div>
                      <CardBody>
                        <CardTitle className="h4">B2B Promotions</CardTitle>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col lg={3}>
                  <Link
                    to={"/emailtemplate/create/18"}
                    className="waves-effect TemplateCard btn btn-light"
                  >
                    <Card>
                      <div className="CardImages">
                        <CardImg
                          top
                          className="img-fluid"
                          src={"https://cdn.templates.unlayer.com/previews/apology-to-ecommerce-customer/1647810080697.png"}
                          alt="Lexa"
                        />
                      </div>
                      <CardBody>
                        <CardTitle className="h4">Apology To Customer</CardTitle>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col lg={3}>
                  <Link
                    to={"/emailtemplate/create/19"}
                    className="waves-effect TemplateCard btn btn-light"
                  >
                    <Card>
                      <div className="CardImages">
                        <CardImg
                          top
                          className="img-fluid"
                          src={"https://cdn.templates.unlayer.com/previews/apology-for-cancellation-of-meeting/1651212174420.png"}
                          alt="Lexa"
                        />
                      </div>
                      <CardBody>
                        <CardTitle className="h4">Apology For Meeting Cancellation</CardTitle>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col lg={3}>
                  <Link
                    to={"/emailtemplate/create/20"}
                    className="waves-effect TemplateCard btn btn-light"
                  >
                    <Card>
                      <div className="CardImages">
                        <CardImg
                          top
                          className="img-fluid"
                          src={"https://uploads-ssl.webflow.com/5daaade3e3e3f04da71daa8e/61acc6299dba2de90a2b4bed_1638712866051.png"}
                          alt="Lexa"
                        />
                      </div>
                      <CardBody>
                        <CardTitle className="h4">Mobile OPT Verification</CardTitle>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>


              </Row>
            </Card>
          </Col>
        </Row>

      </React.Fragment>
    );
  }
}
