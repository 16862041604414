import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { bcrypt, isAllowed, uInfo, uToken } from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import swal from 'sweetalert';

export default class Group extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        text: "#",
        key: "group_id",
        sortable: false,
      },
      {
        text: "Title",
        key: "title",
        sortable: false,
      },
      {
        text: "Type",
        key: "type",
        sortable: false,
      },
      {
        text: "Total Clients",
        key: "total_clients",
      },
      {
        text: "Total Email",
        key: "total_email",
      },
      {
        text: "Total Phone",
        key: "total_phone",
      },
      {
        text: "Total Mobile 1",
        key: "total_mobile",
      },
      {
        text: "Status",
        key: "status",
        cell: (record, index) => {
          if (record.status == 'Active') {
            return <p key={index} className="badge bg-success">{record.status}</p>
          } else if (record.status == 'Inactive') {
            return <p key={index} className="badge bg-danger">{record.status}</p>
          } else {
            return <p key={index}>{record.status}</p>
          }
        }
      },
      {
        text: "Created At",
        key: "created_at",
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
      button: {
        excel: false,
        print: true,
        csv: true,
      },
    };
    this.state = {
      records: [],
      roles: [],
      total_pages: 0,
      modal: false,
      record: {},
      filter: {},
      page_name: "Group",
      customDate: true,
      user_id: this.props.user_id ? this.props.user_id : "",
    };
  }
  deleteRecord = (record, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "delete/group", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: record.group_id }),
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                this.getData();
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            //Then with the error genereted...
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
  };
  refreshRecord = (record, index) => {
    let url = `${ApiUrl}` + "update/groupleads/" + record.group_id;

    fetch(`${url}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.getData();
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  componentDidMount() {
    if (this.state.user_id == "") {
      this.columns = [
        ...this.columns,
        {
          key: "action",
          text: "Action",
          cell: (record, index) => {
            return (
              <Fragment>
                {/* {record.title != "Default-coaches" && ( */}
                <button
                  className="btn btn-success btn-sm mr-5"
                  onClick={this.refreshRecord.bind(this, record, index)}
                >
                  <i className="mdi mdi-refresh"></i>                  
                </button>
                {/* )} */}
                {isAllowed(uInfo(), ["can_edit"]) &&
                  record.can_delete == 1 && (
                    <button
                      type="button"
                      color="info"
                      className="btn btn-primary btn-sm mr-5"
                      onClick={() => this.props.history.push("/group/edit/" + bcrypt(record.group_id))}
                    // onClick={() => {
                    //   this.setState({
                    //     modal: !this.state.modal
                    //   })
                    // }}
                    >
                      <i className="mdi mdi-pencil"></i>
                    </button>
                  )}
                {isAllowed(uInfo(), ["can_delete"]) &&
                  record.can_delete == 1 && (
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={this.deleteRecord.bind(this, record, index)}
                    >
                      <i className="mdi mdi-delete"></i>
                    </button>
                  )}
              </Fragment>
            );
          },
        },
      ];
    }

    this.getData();
  }

  getData = (queryString = "", data) => {
    let url = `${ApiUrl}` + "groupstags";
    if (this.state.user_id != "") {
      url = `${ApiUrl}` + "groupstags/" + this.state.user_id;
    }
    fetch(`${url}` + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            total_pages: response.data.total,
            records: response.data.records,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleFilterSubmit = async (event, values) => {
    this.setState({
      filter: values,
    });
    this.getData("", values);
  };

  cancelFilter = async () => {
    this.form && this.form.reset();
    this.setState({ filter: {}, customDate: true });
    this.getData("", "");
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData("?" + queryString, this.state.filter);
  };

  render() {
    return (
      <React.Fragment>
        {this.state.user_id == "" && (
          <>
            <Helmet>
              <title>{this.state.page_name} Tables </title>
            </Helmet>

            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4>{this.state.page_name} Lists</h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#">{this.state.page_name} Lists</Link>
                    </li>
                  </ol>
                </div>
              </Col>
              <Col sm={6}>
                <div className="page-title-box text-align-right">
                  {isAllowed(uInfo(), ["can_create"]) && (
                    <Link
                      type="button"
                      color="info"
                      className="btn btn-info"
                      to={"/group/add"}
                    >
                      <i className="mdi mdi-plus"></i>
                      Create
                    </Link>
                  )}
                </div>
              </Col>
            </Row>

            <Row>
              <Col xl="12">
                <Card>
                  <CardBody className="pb-0">
                    <AvForm
                      onValidSubmit={this.handleFilterSubmit}
                      ref={(c) => (this.form = c)}
                      className="needs-validation"
                    >
                      <Row>
                        <Col md="2">
                          <div className="mb-0">
                            <AvField
                              className="select form-control"
                              type="select"
                              name="date_range"
                              label="Duration"
                              onChange={(e) =>
                                e.target.value == "Custom Date"
                                  ? this.setState({
                                    customDate: false,
                                  })
                                  : this.setState({
                                    customDate: true,
                                  })
                              }
                            >
                              <option value="">All Time</option>
                              <option value="Current Week">Current Week</option>
                              <option value="Last Week">Last Week</option>
                              <option value="Current Month">
                                Current Month
                              </option>
                              <option value="Last Month">Last Month</option>
                              <option value="Last 3 Months">
                                Last 3 Months
                              </option>
                              <option value="Last 6 Months">
                                Last 6 Months
                              </option>
                              <option value="Current Year">Current Year</option>
                              <option value="Last Year">Last Year</option>
                              <option value="Custom Date">Custom Range</option>
                            </AvField>
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-0">
                            <AvField
                              name="start_date"
                              label="Start date"
                              className="form-control"
                              placeholder="Enter Email Address"
                              type="date"
                              disabled={this.state.customDate}
                              required={!this.state.customDate}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-0">
                            <AvField
                              name="end_date"
                              label="End date"
                              className="form-control"
                              placeholder="Enter Email Address"
                              type="date"
                              disabled={this.state.customDate}
                              required={!this.state.customDate}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-0">
                            <AvField
                              className="select form-control"
                              type="select"
                              id="s"
                              name="type"
                              label="Type"
                            >
                              <option value={""}>Select Type</option>
                              <option value={"Group"}>Group</option>
                              <option value={"Tag"}>Tag</option>
                            </AvField>
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-0">
                            <AvField
                              className="select form-control"
                              type="select"
                              id="s"
                              name="status"
                              label="Status"
                            >
                              <option value={""}>Select Status</option>
                              <option value={"Active"}>Active</option>
                              <option value={"Inactive"}>Inactive</option>
                            </AvField>
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-0">
                            <label className=""> {"Action"}</label>
                            <div className="button-items">
                              <Button
                                className="btn-sm"
                                color="primary"
                                type="submit"
                              >
                                Filter
                              </Button>
                              <Button
                                onClick={() => this.cancelFilter()}
                                outline
                                color="secondary"
                                className="waves-effect btn-sm"
                                type="button"
                              >
                                Reset
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
        <ToastContainer />

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="pt-0">
                {this.state.user_id != "" && (
                  <CardTitle className="h4">Group Lists</CardTitle>
                )}
                <ReactDatatable
                  config={this.config}
                  records={this.state.records}
                  columns={this.columns}
                  dynamic={true}
                  total_record={this.state.total_pages}
                  onChange={this.tableChangeHandler}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
