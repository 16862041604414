import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import SweetAlert from "react-bootstrap-sweetalert"
import Helmet from "react-helmet";
import {
    bcrypt,
    getPages,
    getPermission,
    getRoles,
    hasRole,
    isAllowed,
    uInfo,
    uRole,
    uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose, WebUrl } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-bootstrap";
import ProfileSettings from "../Authentication/profile-sidebar";
import googleCalendar from "../../assets/images/google-calendar.png";
import outlookCalendar from "../../assets/images/outlook-calendar.png";
import swal from 'sweetalert';
import Loader from "react-loader";

export default class Meeting extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                text: "#",
                key: "calendar_id",
            },
            {
                text: "Title",
                key: "event_title",
            },
            {
                text: "Group",
                key: "group_id",
            },
            {
                key: "action",
                text: "Calendar Type",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            {
                                record.default_status == 'Yes' ?
                                    <button
                                        disabled={true}
                                        className="btn btn-success btn-sm mb-1"
                                        key={record.default_status + '-' + index}
                                        onClick={() => this.setState({ warningAlert: true, record: record })}
                                    >
                                        Default
                                    </button>
                                    :
                                    <button
                                        key={record.default_status + '-' + index}
                                        className="btn btn-danger btn-sm mb-1"
                                        onClick={() => this.setState({ warningAlert: true, record: record })}
                                    >
                                        Custom
                                    </button>
                            }
                            {/* )} */}
                        </Fragment>
                    );
                }
            },
            {
                text: "Created Date",
                key: "created_at",
            },
            {
                key: "action",
                text: "Action",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <Link
                                type="button"
                                color="info"
                                className="btn btn-link btn-sm mb-1 mr-5"
                                to={
                                    "/meeting/edit/" + bcrypt(record.calendar_id)
                                }
                            >
                                <i className="fas fa-fw  fa-edit"></i>
                            </Link>
                            <button
                                title="Link"
                                type="button"
                                color="info"
                                className="btn btn-link btn-sm mb-1 mr-5"
                                onClick={() => { this.setState({ modal: true, selectedContract: record }) }}
                            >
                                <i className="fas fa-fw  fa-link"></i>
                            </button>

                            <button
                                title="Link"
                                type="button"
                                color="info"
                                className="btn btn-link btn-sm mb-1 mr-5"
                                onClick={() => this.props.history.push('/appointment/list/' + record.calendar_id)}
                            >
                                <i className="fas fa-fw  fa-eye"></i>
                            </button>
                            <button
                                title="Delete this calendar"
                                type="button"
                                color="info"
                                className="btn btn-link btn-sm mb-1 mr-5"
                                onClick={this.deleteRecord.bind(this, record, index)}
                            >
                                <i className="fas fa-trash"></i>
                            </button>
                            {
                                record.outlook_calendar_id == '0' && (
                                    record.google_calendar_id != '0' ?
                                        <>
                                            <button
                                                title="Unlink from google calandar"
                                                type="button"
                                                color="info"
                                                className="btn btn-link btn-sm mb-1 mr-5"
                                                onClick={() => this.unLinkGoogleCalendar(record.calendar_id)}
                                            >
                                                <i className="fas fa-window-close"></i>
                                            </button>

                                            <button
                                                title="Get Events from google calendar"
                                                type="button"
                                                color="info"
                                                className="btn btn-link btn-sm mb-1 mr-5"
                                                onClick={() => this.downloadEvent(record.calendar_id, record.google_calendar_id)}
                                            >
                                                <i className="fas fa-download"></i>
                                            </button>
                                        </>
                                        :
                                        <a title={'Click here to sync google calendar event'} className="btn btn-link btn-sm mb-1" href={`${WebUrl}/google/sync?calId=` + record.calendar_id} > <img src={googleCalendar} height={24} /></a>
                                )
                            }
                            {
                                record.google_calendar_id == '0' && (
                                    record.outlook_calendar_id != '0' ?
                                        <>
                                            <button
                                                title="Unlink from outlook calandar"
                                                type="button"
                                                color="info"
                                                className="btn btn-link btn-sm mb-1 mr-5"
                                                onClick={() => this.unLinkGoogleCalendar(record.calendar_id)}
                                            >
                                                <i className="fas fa-window-close"></i>
                                            </button>

                                            <button
                                                title="Get Events from outlook calendar"
                                                type="button"
                                                color="info"
                                                className="btn btn-link btn-sm mb-1 mr-5"
                                                onClick={() => this.downloadEvent(record.calendar_id, record.outlook_calendar_id)}
                                            >
                                                <i className="fas fa-download"></i>
                                            </button>
                                        </>
                                        :
                                        // ""
                                        <a title={'Click here to sync outlook calendar event'} className="btn btn-link btn-sm mb-1" href={`${WebUrl}/outloot/sync?calId=` + record.calendar_id} > <img src={outlookCalendar} height={24} /></a>
                                )
                            }

                        </Fragment>
                    );
                }
            },
        ];
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            show_filter: true,
            show_pagination: true,

        };
        this.state = {
            records: [],
            roles: [],
            total_pages: 0,
            record: {},
            filter: {},
            page_name: "Calendar",
            customDate: true,
            ClientSubcribeModal: false,
            modal: false,
            selectedContract: {},
            loaded: true,
            currentClient: {
                id: "",
                email_status: "",
                call_status: "",
                groups: [],
                tags: [],
            },
        };
    }
    downloadEvent = (id, google_id) => {
        this.setState({ loaded: false });
        fetch(`${ApiUrl}` + "download/google/calander/events", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: id, google_id: google_id },
            ),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    swal({
                        text: response.message,
                        icon: "success",
                    });
                } else {

                    swal({
                        text: response.message,
                        icon: "warning",
                    });
                }
                this.setState({ loaded: true });
            })
            .catch((error) => {
                this.setState({ loaded: true });
                console.error("Error:", error);
            });
    }


    unLinkGoogleCalendar = (id) => {
        this.setState({ loaded: false });

        fetch(`${ApiUrl}` + "update/google/calander", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: id, google_id: '0' },
            ),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    this.getData();
                } else {


                    swal({
                        text: data.message,
                        icon: "warning",
                    });
                }
                this.setState({ loaded: true });

            })
            .catch((error) => {
                this.setState({ loaded: true });
                console.error("Error:", error);
            });
    }
    deleteRecord = (record, index) => {
        swal({
            title: "Are you sure?",
            text: "You want to remove this record.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "delete/calendar", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                        body: JSON.stringify({ id: record.calendar_id }),
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((data) => {
                            if (data.status === true) {
                                this.getData();
                                //   toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                            } else {
                                swal({
                                    text: data.message,
                                    icon: "success",
                                });
                                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                            }
                        })
                        //Then with the error genereted...
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    };

    componentDidMount() {
        if (this.props.location.search != '' && this.props.location.search != null) {
            window.location.replace(`${WebUrl}/google/sync` + this.props.location.search);
        }
        this.getData();
    }

    updateCalendar = () => {
        console.log(this.state.record);
        fetch(`${ApiUrl}` + "update/status/meeting", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: this.state.record.calendar_id }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                if (data.status === true) {
                    this.getData();
                    this.setState({
                        record: {},
                        warningAlert: false
                    })
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    getData = (queryString = "", data) => {
        this.setState({ loaded: false });

        fetch(`${ApiUrl}` + "meetings" + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {
                    console.log(response, 'debugging');
                    this.setState({
                        total_pages: response.data.total,
                        records: response.data.records,
                        roles: response.data.roles,
                    });

                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
                this.setState({ loaded: true });

            })
            .catch((error) => {
                this.setState({ loaded: true });
                console.error("Error:", error);
            });
    };




    FormBuilder(formurl) {

        var iframe = document.createElement('iframe');
        iframe.style = "height:" + 360 + "px; width:100%; border:none";
        iframe.setAttribute('allowTransparency', true);
        iframe.src = formurl;
        // document.body.appendChild(iframe);

        console.log(iframe);
        return iframe;
    }


    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.state.page_name}
                    </title>
                </Helmet>
                <ToastContainer />
                <div className="dialer">
                    <Loader loaded={this.state.loaded} className="spinner" />
                </div>

                <Row>
                    <Col lg="2" className="pl-0">
                        <ProfileSettings menuOpen={false} props={this.props} />
                    </Col>
                    <Col lg="10">
                        {this.state.warningAlert ? (
                            <SweetAlert
                                title="You want to set this calendar as default calendar?"
                                showCancel
                                confirmBtnBsStyle="success"
                                cancelBtnBsStyle="danger"
                                warning
                                onConfirm={() => this.updateCalendar()}
                                onCancel={() => this.setState({
                                    warningAlert: false,
                                    record: {}
                                })}
                            />
                        ) : null}
                        <Row>
                            <Col sm={5}>
                                <div className="page-title-box">
                                    <h4>{this.state.page_name} List</h4>
                                    <ol className="breadcrumb m-0">
                                        <li key={0} className="breadcrumb-item active">
                                            {ProductName}
                                        </li>
                                        <li key={1} className="breadcrumb-item">
                                            <Link to="#">{this.state.page_name} List</Link>
                                        </li>
                                    </ol>
                                </div>
                            </Col>
                            <Col sm={4}>
                            </Col>
                            <Col sm={3}>
                                <div className="page-title-box text-align-right">
                                    {isAllowed(uInfo(), ["can_create"]) && (
                                        <Link
                                            type="button"
                                            color="info"
                                            className="btn btn-info"
                                            to={"/meeting/add"}
                                        >
                                            <i className="mdi mdi-plus"> </i>
                                            Create
                                        </Link>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <ReactDatatable
                                            config={this.config}
                                            records={this.state.records}
                                            columns={this.columns}
                                            dynamic={false}
                                            onChange={this.tableChangeHandler}
                                            key={'a'}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Modal show={this.state.modal}>
                            <Modal.Header>
                                <Modal.Title>Embed Code</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <div className="url">
                                    <a
                                        href={`${this.state.selectedContract.calendar_url}`}
                                        target="_blank"
                                        className="pr-5 mr-5"
                                    >
                                        {this.state.selectedContract.calendar_url}
                                    </a>

                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip id="overlay-example">
                                                Copy url
                                            </Tooltip>
                                        }
                                    >
                                        <button
                                            className="btn btn-link btn-md"
                                            onClick={() => {
                                                navigator.clipboard.writeText(`${this.state.selectedContract.calendar_url}`);
                                            }}
                                        >
                                            <i className="fas fa-copy"></i>
                                        </button>
                                    </OverlayTrigger>
                                </div>

                                <div className="url d-flex">
                                    <label className="pt-3">Calendar Url </label>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip id="overlay-example">
                                                Copy iframe url
                                            </Tooltip>
                                        }
                                    >
                                        <button
                                            className="btn btn-link btn-md"
                                            onClick={() => {
                                                navigator.clipboard.writeText(`<iframe allowtransparency="true" src="${this.state.selectedContract.calendar_url}" style="height: 360px; width: 100%; border: none;"></iframe>`);
                                            }}
                                        >
                                            <i className="fas fa-copy"></i>
                                        </button>
                                    </OverlayTrigger>

                                </div>


                                <textarea rows={'5'} className="form-control" defaultValue={`<iframe allowtransparency="true" src="${this.state.selectedContract.calendar_url}" style="height: 360px; width: 100%; border: none;"></iframe>`}></textarea>



                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    color="info"
                                    onClick={() => this.setState({
                                        modal: false,
                                        selectedContract: {}
                                    })}
                                >
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
