import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
  Tabs,
  Nav,
  NavItem,
  NavLink,
  Table,
  TabContent,
  TabPane,
  CardText,
  Tablist,
  TabPanel,
} from "reactstrap";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import { AvForm, AvField, AvInput, AvGroup, AvFeedback } from "availity-reactstrap-validation";
import {
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import ProfileSettings from "./profile-sidebar";
import { Tab } from "react-bootstrap";
import Loader from "react-loader";

export default class UpgradePlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      packages: [],
      currentPackage: {
        package_data: {},
      },
      page_name: "Coach Partner Packages",
      activeTab: "1",
      modal: false,
      select_package: {},
      loader: true
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    fetch(`${ApiUrl}` + "get/Coachpartner/packages", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            packages: response.data.records,
            //currentPackage: response.data.current_subscription,
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleValidSubmit = (event, values) => {
    this.setState({
      loader: false
    });

    values.billing_for = 'CoachPartner';
    values.payment_method = 'stripe';
    fetch(`${ApiUrl}` + 'payment/coachpartner', {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((res) => {
        if (res.status === true) {
          this.props.history.push({
            pathname: `/settings/coachpartners`,
          });
        } else {
          toast(res.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

        }

        this.setState({
          loader: true
        })

      })
      //Then with the error genereted...
      .catch((error) => {
        this.setState({
          loader: true
        })
        console.error('Error:', error);
      });
  }

  closeModal = () => {
    this.setState({
      modal: false,
      select_package: {}
    })
  }

  render() {
    let currentDescription = this.state.currentPackage.package_data.description
      ? JSON.parse(this.state.currentPackage.package_data.description)
      : "{}";

    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name} </title>
        </Helmet>
        <ToastContainer />
        <Row>
          <Col lg="2" className="pl-0">
            <ProfileSettings menuOpen={false} props={this.props} />
          </Col>
          <Col lg="10">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4> {this.state.page_name} </h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> {this.state.page_name} </Link>
                    </li>
                  </ol>
                </div>
              </Col>
            </Row>

            <Loader loaded={this.state.loader}></Loader>

            <Nav tabs>
              <NavItem>
                <NavLink
                  className={this.state.activeTab == 1 ? "active" : ""}
                  onClick={() => this.setState({ activeTab: "1" })}
                >
                  Monthly
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.state.activeTab == 2 ? "active" : ""}
                  onClick={() => this.setState({ activeTab: "2" })}
                >
                  Yearly
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent className="p-0" activeTab={this.state.activeTab}>
              <TabPane tabId={"1"}>
                <Row>
                  {this.state.packages.map((row, i) => {
                    if (row.package_interval == "Monthly" && row.status == "Active") {
                      let description = JSON.parse(row.description);

                      return (
                        <Col md={3} key={i}>
                          <div className="card mb-5 mb-lg-0 my-2">
                            <div className="card-body">
                              <h5 className="card-title text-muted text-uppercase text-center">
                                {row.name}
                              </h5>
                              <h6 className="card-price text-center">
                                {row.price}
                                <span className="period">
                                  /{row.package_interval}
                                </span>
                              </h6>
                              <hr />
                              <ul className="fa-ul">
                                {description.map((desc, i) => {
                                  if (desc.key == "description_meta") {
                                    return (
                                      <li key={i}>
                                        <span className="fa-li">
                                          <i className="fas fa-check"></i>
                                        </span>
                                        {desc.description_meta}
                                      </li>
                                    );
                                  }
                                })}
                              </ul>
                              <div className="d-grid">
                                <button onClick={() => this.setState({ modal: true, select_package: row })} className="btn btn-primary text-uppercase">
                                  Get
                                </button>
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    }
                  })}
                </Row>
              </TabPane>

              <TabPane tabId={"2"}>
                <Row>
                  {this.state.packages.map((row, i) => {
                    if (row.package_interval == "Yearly" && row.status == "Active") {
                      let description = JSON.parse(row.description);
                      return (
                        <Col md={3} key={i}>
                          <div className="card mb-5 mb-lg-0 my-2">
                            <div className="card-body">
                              <h5 className="card-title text-muted text-uppercase text-center">
                                {row.name}
                              </h5>
                              <h6 className="card-price text-center">
                                {row.price}
                                <span className="period">
                                  /{row.package_interval}
                                </span>
                              </h6>
                              <hr />

                              <ul className="fa-ul">
                                {description.map((desc, i) => {
                                  if (desc.key == "description_meta") {
                                    return (
                                      <li key={i}>
                                        <span className="fa-li">
                                          <i className="fas fa-check"></i>
                                        </span>
                                        {desc.description_meta}
                                      </li>
                                    );
                                  }
                                })}
                              </ul>
                              <div className="d-grid">
                                <button onClick={() => this.setState({ modal: true, select_package: row })} className="btn btn-primary text-uppercase">
                                  Get
                                </button>
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    }
                  })}
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row>

        <Modal
          isOpen={this.state.modal}
          fade={true}
          size="lg"
          toggle={() => {
            this.closeModal();
          }}
          className="paymemt-coach-partner"
        >
          <AvForm className="form-horizontal"
            onValidSubmit={
              (e, v) => {
                this.handleValidSubmit(e, v)
              }
            }>

            <div className="note">
              <div className="modal-head top-heading">
                <h2> Payment for ({this.state.select_package.name}) </h2>
                <button
                  type="button"
                  onClick={() => {
                    this.closeModal();
                  }}
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"> </i>
                </button>
              </div>
              <ModalBody>
                <div className="row">
                  <AvInput type='hidden' name='payment_type' value='recurring' />
                  <AvGroup>
                    <AvInput name="package_id" value={this.state.select_package.package_id} required type="hidden" />
                    <AvFeedback>You must select a package to continue!</AvFeedback>
                  </AvGroup>
                  <div className="col-md-6 mb-3">
                    <AvField
                      name="cardnumber"
                      label="Card Number"
                      className="form-control"
                      placeholder="Enter Card Number"
                      maxLength={16}
                      validate={{
                        required: { value: true, errorMessage: 'This field is invalid' },
                        pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                      }}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="control-label"> CVC</label>
                    <AvField
                      name="cvc"
                      className="form-control"
                      placeholder="CVC"
                      maxLength={3}
                      validate={{
                        required: { value: true, errorMessage: 'This field is invalid' },
                        pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                      }}
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <AvField
                      name="expirationdateMM"
                      label="MM"
                      className="form-control"
                      placeholder="MM"
                      required
                      maxLength={2}
                      validate={{
                        required: { value: true, errorMessage: 'This field is invalid' },
                        pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                      }}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <AvField
                      name="expirationdateYYYY"
                      label="YYYY"
                      className="form-control"
                      placeholder="YYYY"
                      maxLength={4}
                      validate={{
                        required: { value: true, errorMessage: 'This field is invalid' },
                        pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                      }}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <AvField
                      name="address_line1"
                      label="Billing Address"
                      className="form-control"
                      type="textarea"
                      placeholder="Billing Addresss"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <AvField
                      name="address_city"
                      label="Billing City"
                      className="form-control"
                      placeholder="Billing City"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <AvField
                      name="address_state"
                      label="Billing State"
                      className="form-control"
                      placeholder="Billing State"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <AvField
                      name="address_zip"
                      label="Billing Zip"
                      className="form-control"
                      placeholder="Billing Zip"
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>

                <Button color="info" type="submit">
                  Pay
                </Button>
                <Button color="danger" onClick={() => { this.closeModal(); }}>
                  Cancel
                </Button>
              </ModalFooter>
            </div>

          </AvForm>
        </Modal>
      </React.Fragment>
    );
  }
}
