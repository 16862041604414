import React from "react"
import { Link } from "react-router-dom";
import { logoLightPng } from "../../config";
import Context from './Context';

const Intro = (props) => {
    const params = React.useContext(Context);
    return (
        <div>
            <div className="box-center-align">
                <div className="page-content-box text-center">
                    <div className="mb-4" >
                        <Link to="#"
                            className="d-block auth-logo" >

                            <img src={logoLightPng}
                                alt=""
                                className="auth-logo-dark" />
                        </Link>
                    </div>
                    <h1>ONBOARDING & ACCOUNT SETUP</h1>
                    <h5 className="mt-3"><i>Welcome to PROFITCOACH, please take a moment to fill out your information throughout this form. Your information will be used to setup your account. Take your time, be precise, and don't overthink it!.</i></h5>  
                    <note><h6>Please Note: Please Do Not Spend More Than A Week To Complete.</h6></note>
                    <button onClick={() => params.goToBox(params.currentBox + 1)} className="btn btn-md btn-primary mt-4"> Let's Begin! </button>
                </div>
            </div>
        </div>
    );
};

export default Intro;