import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardTitle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import Loader from "react-loader";

import { ApiUrl } from "../../config";
import { bcrypt, uToken } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
const Reports = (props) => {
  const [assessment_id, setAssessmentId] = useState(props.assessment);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState("");
  const [loaded, setLoaded] = useState(false);
  //const [content_url, setContenturl] = useState(ApiUrl + 'feature_test');
  const [content_url, setContenturl] = useState("");
  //const [page, setPageData] = useState("");
  const [file_type, setfiletype] = useState("pdf");
  useEffect(() => {
    //setLoaded(true);
    getData(false);
  }, []);

  function NumberWithCommas(x) {
    let y = Math.trunc(x);
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const getData = (e) => {
    setContenturl('');
    setLoaded(false);
    console.log('first');
    fetch(`${ApiUrl}` + "assesment/report", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },

      body: JSON.stringify({
        assessment_id: assessment_id,
        report_type: e,
      }),
    })
      .then((response) => response.json())
      .then((response) => {

        if (response.status === true) {
          setContenturl(response.data.file_path);
          setLoaded(true);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <React.Fragment>

      {/* <Row>
        <Col sm={9}>
          <div className="page-title-box">
            <h2 className="mb-3">
              {props.module && props.module.replaceAll("_", " ")}
            </h2>
          </div>
        </Col>
      </Row> */}

      <div className="row sub-nav-bg col p-2 col">
        <div className="col-md-2">
          <label className="form-check-label pt-1">VIEW REPORT </label>
        </div>
        <div className="col-md-8">
          <div className="toggle form-check form-switch form-switch-lg pl-1">
            <input
              type="checkbox"
              className="form-check-input"
              id="report_type"
              defaultChecked={""}
              onClick={e => {
                getData(e.target.checked);
              }}
            />
            <label className="form-check-label p-1">VIEW ROADMAP</label>
          </div>
        </div>
      </div>

   
      <Loader loaded={loaded}></Loader>
      <Row>
        <Col sm={12}>
          {/* <FileViewer fileType={file_type} filePath={content_url} /> */}
          <iframe src={content_url} height="600" width="100%" title="Assessment Report"/>
          {/* <div
            dangerouslySetInnerHTML={{__html: page}}
          /> */}
          <Link to={`/assessment/${bcrypt(assessment_id)}/summary`} className="btn btn-info btn-sm result-btn">Go to Summary</Link>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Reports;
