import React, { useEffect, useState } from "react"

const Incoming = ({ connection, device }) => {
  const acceptConnection = () => {
    connection.accept();
  };
  const rejectConnection = () => {
    connection.reject();
  };
  return (
    <>
      <div className="accept-rejact">
        <button className="accept" onClick={acceptConnection} color="green"><i className="fas fa-phone"></i></button>
        <button className="rejact" onClick={rejectConnection} color="red"><i className="fas fa-phone"></i></button>
      </div>
    </>
  );
};

export default Incoming;
