import React, { useEffect, useState, useRef } from "react"
import PropTypes from 'prop-types'
import { Link, Redirect, useHistory, useLocation } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import { ApiUrl } from "../../config"
import { uToken } from "../../useToken"
import { toast, Flip } from "react-toastify"
import swal from "sweetalert";
const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const SystemWarning = props => {
  const queryParms = useQuery();
  const [records, setRecords] = useState([]);
  const [type, setType] = useState(queryParms.get('type') ? queryParms.get('type') : 'trusthub');
  const containerRef = useRef(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    fetch(`${ApiUrl}` + "get/warnings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ type: type }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setRecords(response.data.records)
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const actionStepComplete = (step_id) => {
    swal({
      title: "Are you sure?",
      text: "You want to perform this action",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "action/step/complete", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({
              id: step_id,
            }),
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
              if (response.status === true) {
                swal({
                  title: response.message,
                  icon: "success",
                });
                //getActionStepsResults();
              } else {
                swal({
                  title: response.message,
                  icon: "warning",
                });
              }
            })
            //Then with the error genereted...
            .catch((error) => {
              console.error("Error:", error);
            });
        } else {
          document.getElementById("complete_" + step_id).checked = false;
        }
      });
  }

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('click', handleClick);
    }

    return () => {
      if (container) {
        container.removeEventListener('click', handleClick);
      }
    };
  }, []);

  const handleClick = (event) => {
    if (event.target && event.target.matches('#dynamicButton')) {

      swal({
        title: "Are you sure?",
        text: "You want to continue with limited access",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
        .then((confirmation) => {
          if (confirmation) {
            fetch(`${ApiUrl}` + "apply/limited/access", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
              },
              body: JSON.stringify({}),
            })
              .then((response) => response.json())
              //Then with the data from the response in JSON...
              .then((response) => {
                if (response.status === true) {
                  swal({
                    title: response.message,
                    icon: "success",
                  });
                  getData();
                } else {
                  swal({
                    title: response.message,
                    icon: "warning",
                  });
                }
              })
              //Then with the error genereted...
              .catch((error) => {
                console.error("Error:", error);
              });
          }
        });
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <div className="card">
            <div className="card-body">
              <div className="h4 card-title mb-3">System Notifications </div>
              {/* <p className="card-title-desc"> System <strong>Warnings</strong> </p> */}
              <div className="" ref={containerRef}>
                {
                  records.map((row, i) => {
                    return <div key={i} dangerouslySetInnerHTML={{ __html: row }} />;
                  })
                }
              </div>


            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default SystemWarning;