import React, { Component } from 'react';
import { Flip, toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ApiUrl } from '../../../config';
import { uToken } from '../../../useToken';
import {
    AvForm,
    AvField
} from "availity-reactstrap-validation";
class UpdateCredential extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        };
    }

    handleSubmit = async (event, values) => {
        values.user_id = this.props.user_id;
        fetch(`${ApiUrl}` + "user/credential/update", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    this.setState({
                        modal: false
                    })
                    this.props.refreshScreen();
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    render() {
        return (
            <div className='update-user-credential'>
                <div>
                    <button
                        className="btn btn-link btn-md"
                        type="button"
                        onClick={() => this.setState({ modal: true })}
                    >
                        <i className="fab fa-expeditedssl"></i> Update Credential
                    </button>
                    <Modal isOpen={this.state.modal}>
                        <ModalHeader toggle={this.toggle}>Update Credentials</ModalHeader>
                        <AvForm
                            onValidSubmit={this.handleSubmit}
                            className="needs-validation"
                        >
                            <ModalBody>
                                <div className='row'>
                                    <div className="col-md-12">
                                        <AvField
                                            name="email"
                                            value={this.props.email}
                                            label="User Email"
                                            className="form-control"
                                            placeholder="For Example: 2022-04-16,2022-04-17"
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <AvField
                                            name="password"
                                            label="User Password"
                                            className="form-control"
                                            placeholder="********"
                                        />
                                    </div>
                                </div>


                            </ModalBody>
                            <ModalFooter>
                                <Button type='submit' color="primary" >Update</Button>{' '}
                                <Button color="secondary" onClick={() => this.setState({ modal: false })}>Cancel</Button>
                            </ModalFooter>
                        </AvForm>
                    </Modal>
                </div>
            </div >
        );
    }
}

export default UpdateCredential;