import React, { useState, useEffect } from 'react';
import WaySideBar from './WaySideBar';
import WayBookContext from './WaybookContext';
import SubjectLists from './SubjectLists';
import CategoryLists from './CategoryLists';
import DocumentContents from './DocumentContents';
import { Alert, ModalHeader } from 'reactstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { ApiUrl, AttachementsUrl } from '../../config';
import swal from "sweetalert";
import { dcrypt, uInfo, uToken } from '../../useToken';
import Loader from "react-loader";
import Select from "react-select";
import {
    Modal,
    Card,
    CardBody,
    Button,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import Knob from "../Campaign/knob";
import { Link } from 'react-router-dom';
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Froala from '../Froala';

function App(props) {
    const [id] = useState(props.match.params.id ? props.match.params.id.split('/') : []);
    const [canEdit, setCanEdit] = useState(uInfo().user_id == 2 ? JSON.parse(localStorage.getItem("can_edit")) : false);
    const [loaded, setLoaded] = useState(false);

    const [waybookList, setWayBookList] = useState([]);
    const [list, setList] = useState({});

    const [action, setAction] = useState(0);

    const [subAction, setSubAction] = useState({});
    const [subActionModal, setSubActionModal] = useState(false);

    const [stepAction, setStepAction] = useState({});
    const [stepActionModal, setStepActionModal] = useState(false);
    const [welcomeNotes, setWelcomeNotes] = useState({});

    const [onboard, setOnBoard] = useState({});


    useEffect(() => {
        getWayBookLists();
    }, []);

    const getWayBookLists = () => {
        setLoaded(false);
        fetch(`${ApiUrl}` + "get/waybook/category", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ can_edit: canEdit }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    setWayBookList(response.data.record);
                    setList(response.data.summary);
                    setWelcomeNotes(response.data.welcome_notes);
                    setOnBoard(response.data.onBoardTraining);
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
                setLoaded(true);

            })
            //Then with the error genereted...
            .catch((error) => {
                setLoaded(true);

                console.error("Error:", error);
            });
    };


    const handleWelcomeSubmit = async (event, meta_value) => {
        fetch(`${ApiUrl}` + "upate/waybook/notes", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ meta_value: meta_value }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    // props.history.push('/waybook');
                    setAction(0);
                    getWayBookLists();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const handleOnboardTraining = async (cat_id) => {
        // event.preventDefault();
        // const formData = new FormData(event.currentTarget);
        // const submitData = new FormData();
        // for (let [key, value] of formData.entries()) {
        //     submitData.append(key, value);
        // }

        fetch(`${ApiUrl}` + "set/waybook/category/onboarding", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ cat_id: cat_id }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    // props.history.push('/waybook');
                    setAction(0);
                    getWayBookLists();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        fetch(`${ApiUrl}` + "submit/waybook/category", {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    // props.history.push('/waybook');
                    setAction(0);
                    getWayBookLists();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const changeHandler = (e) => {
        if (!e.target.files[0].type.match('image.*')) {
            swal({
                title: 'Olny image allowed',
                icon: "warning",
            });
        }
    };

    const closeSubActionModal = () => {
        setSubAction({});
        setSubActionModal(false);
        setStepAction({});
        setStepActionModal(false);
    };

    const changeStatusTraining = (cat_id, action) => {
        fetch(`${ApiUrl}` + "status/update/waybook/category", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ cat_id: cat_id, status: action }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    getWayBookLists();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handlMoveAction = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        fetch(`${ApiUrl}` + "move/waybook/category", {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    getWayBookLists();
                    closeSubActionModal();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    const handlStepMoveAction = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        fetch(`${ApiUrl}` + "update/waybook/category/step", {
            method: "POST",
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    getWayBookLists();
                    closeSubActionModal();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const subjectOrDocumentAction = (type, cat_id, title, action, training_for, image = '', user_id = 2) => {
        setAction(0);
        setSubAction({});
        setTimeout(() => {
            if (action == 'Move') {
                setSubAction({
                    type: type,
                    cat_id: cat_id,
                    title: title,
                });
                setSubActionModal(true);
            } else if (action == 'Delete' || action == 'Draft' || action == 'Publish') {
                changeStatusTraining(cat_id, action);
            } else if (action == 'AddStep') {
                setSubAction({
                    type: type,
                    document_id: cat_id,
                    title: title,
                });
                setSubActionModal(true);
            } else if (action == 'Update') {
                setSubAction({
                    type: type,
                    cat_id: cat_id,
                    title: title,
                    image: image,
                    user_id: user_id,
                    training_for: training_for
                });
                setAction(5);
            } else if (action == 'OnBoarding') {
                handleOnboardTraining(cat_id)
            }
        }, 100)
    };

    const changeStatusStep = (step_id, action) => {
        fetch(`${ApiUrl}` + "status/update/waybook/category/step", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ step_id: step_id, status: action }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    getWayBookLists();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    const stepMethodAction = (type, step_id, title, action, training_for) => {
        if (action == 'Move' || action == 'Update') {
            setStepAction({
                type: type,
                step_id: step_id,
                title: title,
                action: action,
                training_for: training_for
            });
            setStepActionModal(true);

        } else if (action == 'Delete' || action == 'Draft' || action == 'Publish') {
            changeStatusStep(step_id, action);
        }
    }

    let templates_group = [];
    waybookList.map((row, index) => {
        let sub_cat = [];
        row.sub_cat.map((type, index) => {
            sub_cat[index] = {
                cat_id: type.cat_id,
                title: type.title,
                onboard_training: type.onboard_training
            }
        });
        templates_group[index] = { label: row.title, options: sub_cat };
    });

    let templates_documents_group = [];
    waybookList.map((row, index) => {
        row.sub_cat.map((sub, i) => {
            let subs = [];
            sub.sub_cat.map((doc, d) => {
                subs[d] = {
                    cat_id: doc.cat_id,
                    title: doc.title
                }
            });
            templates_documents_group[index + i] = { label: sub.title + ' - ' + row.title, options: subs };
        });
    });


    const Action = (action) => {
        setAction(action);
    };

    const documentLogsReset = (document_id, step_id = '') => {
        fetch(`${ApiUrl}` + "waybook/category/logs/reset", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ cat_id: document_id, step_id: step_id }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    getWayBookLists();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const markAsComplete = (cat_id, step_id) => {

        let data = {
            cat_id: cat_id,
            step_id: step_id,
        }

        fetch(`${ApiUrl}` + "step/mark/complete", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    getWayBookLists();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const data = {
        category_i: typeof id[0] != 'undefined' ? dcrypt(id[0]) : "0",
        category: typeof id[1] != 'undefined' ? dcrypt(id[1]) : "",
        subject_i: typeof id[2] != 'undefined' ? dcrypt(id[2]) : "",
        subject: typeof id[3] != 'undefined' ? dcrypt(id[3]) : "",
        documents_i: typeof id[4] != 'undefined' ? dcrypt(id[4]) : "",
        documents: typeof id[5] != 'undefined' ? dcrypt(id[5]) : "",
        step_i: typeof id[6] != 'undefined' ? dcrypt(id[6]) : "",
        step: typeof id[7] != 'undefined' ? dcrypt(id[7]) : "",
        can_edit: canEdit,
        lists: waybookList,
        subjectOrDocumentAction: subjectOrDocumentAction,
        Action: Action,
        stepMethodAction: stepMethodAction,
        documentLogsReset: documentLogsReset,
        markAsComplete: markAsComplete,
        updatetWayBookLists: getWayBookLists
    }

    const user_types = [
        { value: '1', label: 'Coach' },
        { value: '2', label: 'Diamond Partner' },
        { value: '3', label: 'Client' },
        { value: '4', label: 'Team Admin' },
        { value: '6', label: 'Team Coach' }

    ];
    return (
        <WayBookContext.Provider value={data}>
            <Loader loaded={loaded}></Loader>
            <div className='main-way'>
                <div className='row'>
                    <div className='col-lg-3 px-0'>
                        <div className="WayBookBar">
                            {
                                uInfo().user_id == 2 && (
                                    <div className="form-check form-switch float-end p-2" >
                                        <label>Edit</label>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="customSwitch2"
                                            checked={JSON.parse(canEdit)}
                                            onChange={e => {
                                                setCanEdit(e.target.checked);
                                                localStorage.setItem("can_edit", e.target.checked);
                                            }}
                                        />
                                    </div>
                                )
                            }
                           
                            <div className='d-flex  w-100 WayBookDropDown py-2 justify-content-between'>

                                {
                                    canEdit ? (
                                        <>
                                            <Link to={'/trainings'} className="p-1" ><i className="fas fa-home"></i> Dashboard</Link>
                                            <Dropdown className='align-self-center' onSelect={(e) => setAction(e)}>
                                                <Dropdown.Toggle title='Add New' variant="link" id="dropdown-basic">
                                                    <i className='fas fa-plus'></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item eventKey="1">Category</Dropdown.Item>
                                                    <Dropdown.Item eventKey="2">Subject</Dropdown.Item>
                                                    <Dropdown.Item eventKey="3">Document</Dropdown.Item>
                                                    {/* <Dropdown.Item eventKey="7">Set Onboadrd Training</Dropdown.Item> */}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </>
                                    ) : (
                                        <>
                                            {
                                                uInfo().type == 'Client' ?
                                                    <>
                                                        <Link to={'/trainings'} className="p-1" ><i className="fas fa-home"></i> Dashboard </Link>

                                                        <Link to={'/client/profile'} className="p-1" ><i className="fas fa-angle-left"></i> Back</Link>
                                                    </>
                                                    :
                                                    <Link to={'/trainings'} className="p-1" ><i className="fas fa-home"></i> Dashboard </Link>
                                            }
                                        </>
                                    )
                                }

                            </div>
                            <div className='way-sidebar'>
                          
                                {
                                    loaded && (
                                        <WaySideBar lists={waybookList} />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-9 ps-0'>
                        {
                            loaded && (
                                <div className='way-content'>

                                    {
                                        action == 0 ?
                                            data.category != '' && data.subject == '' ?
                                                (
                                                    <CategoryLists />

                                                ) :
                                                data.subject != '' && data.documents == '' ?
                                                    (
                                                        <SubjectLists />

                                                    ) : data.subject != '' && data.documents != '' ?
                                                        (
                                                            <DocumentContents />

                                                        ) :
                                                        (
                                                            <div className='default-layout'>
                                                                <div className="dash-welcome-left mt-5">
                                                                    <div className='row'>
                                                                        <div className='col-lg-8'>
                                                                            <div className='d-flex justify-content-between'>
                                                                                <div className='body-area' dangerouslySetInnerHTML={{ __html: welcomeNotes.body }} />
                                                                                {
                                                                                    data.can_edit && (
                                                                                        <button className="btn btn-sm btn-link d-inline-flex z_index-1" onClick={() => setAction(6)}>
                                                                                            <i className='fas fa-edit'></i>
                                                                                        </button>
                                                                                    )
                                                                                }
                                                                            </div>

                                                                        </div>
                                                                        <div className='col-lg-4'>


                                                                            <div className='postion-absolute'>
                                                                                <Knob
                                                                                    value={list.percentage + '%'}
                                                                                    title={"Complete all documents to reach 100%. You are currently " + list.percentage + "% complete."}
                                                                                    fgColor="#4ac18e"
                                                                                    // thickness={0.12}
                                                                                    readOnly={true}
                                                                                    height={200}
                                                                                    width={200}
                                                                                    onChange={e => {
                                                                                        console.log(e)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='border-bottom-1 w-100'></div>
                                                                </div>
                                                            </div>
                                                        )
                                            :
                                            <>
                                                <div className={`action-way d-flex m-auto justify-content-center current_${action}`}>
                                                    <div className='widthWise'>
                                                        <div className='title text-center mb-1'>
                                                            {
                                                                action == 1 ?
                                                                    (
                                                                        <h4>Create a new Category</h4>
                                                                    ) :
                                                                    action == 2 ?
                                                                        (
                                                                            <h4>Create a new Subject</h4>
                                                                        ) :
                                                                        action == 3 ?
                                                                            (
                                                                                <h4>Create a new Document</h4>
                                                                            )
                                                                            :
                                                                            action == 4 ?
                                                                                (
                                                                                    <h4>Create a new Subject</h4>
                                                                                )
                                                                                :
                                                                                action == 5 ?
                                                                                    (
                                                                                        <h4>Update</h4>
                                                                                    )
                                                                                    :
                                                                                    action == 6 ?
                                                                                        (
                                                                                            <h4>Welcome Message</h4>
                                                                                        ) :
                                                                                        action == 7 ?
                                                                                            (
                                                                                                <h4>Set Onboard Training</h4>
                                                                                            ) :

                                                                                            <></>
                                                            }

                                                        </div>
                                                        <div className='form-action'>
                                                            {
                                                                action == 6 ? (
                                                                    <>
                                                                        <Froala inlineEditior={true} btnId={'handle-welcome-submit'} content={welcomeNotes.meta_value} handleSubmit={handleWelcomeSubmit} use_token={'users'} />
                                                                        <div className="row mt-3">
                                                                            <div className="col-md-12 text-center">
                                                                                <button type="button" id="handle-welcome-submit" className="btn btn-primary w-100" >Update Note</button>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ) : action == 7 ?
                                                                    <>
                                                                        <h6>Current Onboard Training : {onboard.title} ({onboard.master.title ?? ''})</h6>
                                                                        <form method="post" onSubmit={handleOnboardTraining}
                                                                            encType="multipart/form-data" className="form-horizontal" id="selection-myform">


                                                                            <div className="form-group mt-3">
                                                                                <label htmlFor="example-text-input" className="form-label">Category</label>
                                                                                <Select
                                                                                    isClearable
                                                                                    getOptionLabel={option => option.title}
                                                                                    getOptionValue={option => option.cat_id}
                                                                                    isMulti={false}
                                                                                    menuIsOpen={true}
                                                                                    placeholder={'Select category which you want to show as onboard trianing'}
                                                                                    options={templates_group}
                                                                                    classNamePrefix="select2-selection"
                                                                                    name="cat_id"
                                                                                />
                                                                            </div>
                                                                            <p className='text-danger my-1'>The category you are applying for should be Publish Documents and Steps.</p>

                                                                            <div className="row">
                                                                                <div className="col-md-12 text-center">
                                                                                    <button type="submit" className="btn btn-primary w-100" >Set Training</button>
                                                                                </div>
                                                                            </div>

                                                                        </form>
                                                                    </>

                                                                    : (

                                                                        <form method="post" onSubmit={handleSubmit}
                                                                            encType="multipart/form-data" className="form-horizontal" id="selection-myform">

                                                                            {
                                                                                action == 1 && (
                                                                                    <>

                                                                                        <div className="form-group mb-3">
                                                                                            <label htmlFor="example-text-input" className="form-label">Name</label>
                                                                                            <input placeholder='The name of this Category' required className="form-control" type={'text'}
                                                                                                name="title" />

                                                                                            <input type={'hidden'} name="type" value={1} />
                                                                                        </div>
                                                                                        <div className="form-group mb-3">
                                                                                            <label htmlFor="training_for-text-label" className="form-label">Training for</label>
                                                                                            <Select
                                                                                                getOptionLabel={option => option.label}
                                                                                                getOptionValue={option => option.value}
                                                                                                isMulti={true}
                                                                                                options={user_types}
                                                                                                classNamePrefix="select2-selection position-releative"
                                                                                                name="training_for[]"
                                                                                            />
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            }

                                                                            {
                                                                                action == 2 && (
                                                                                    <>
                                                                                        <div className="form-group mb-3">
                                                                                            <label htmlFor="example-text-input" className="form-label">Category</label>
                                                                                            <Select
                                                                                                getOptionLabel={option => option.title}
                                                                                                getOptionValue={option => option.cat_id}
                                                                                                isMulti={false}
                                                                                                options={waybookList}
                                                                                                classNamePrefix="select2-selection"
                                                                                                name="master_id"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="form-group mb-3">
                                                                                            <label htmlFor="example-text-input" className="form-label">Name</label>
                                                                                            <input placeholder='The name of this Subject' required className="form-control" type={'text'}
                                                                                                name="title" />

                                                                                            <input type={'hidden'} name="type" value={2} />
                                                                                        </div>
                                                                                        <div className="form-group mb-3">
                                                                                            <label htmlFor="example-text-input" className="form-label">Image</label>
                                                                                            <input accept="image/*" required className="form-control" type={'file'}
                                                                                                onChange={(e) => changeHandler(e)}
                                                                                                name="image" />
                                                                                        </div>

                                                                                        <div className="form-group mb-3">
                                                                                            <label htmlFor="training_for-text-label" className="form-label">Training for</label>
                                                                                            <Select
                                                                                                getOptionLabel={option => option.label}
                                                                                                getOptionValue={option => option.value}
                                                                                                isMulti={true}
                                                                                                options={user_types}
                                                                                                classNamePrefix="select2-selection position-releative"
                                                                                                name="training_for[]"
                                                                                            />
                                                                                        </div>
                                                                                    </>

                                                                                )
                                                                            }

                                                                            {
                                                                                action == 3 && (
                                                                                    <>
                                                                                        <div className="form-group mb-3">
                                                                                            <label htmlFor="example-text-input" className="form-label">Category</label>
                                                                                            <Select
                                                                                                getOptionLabel={option => option.title}
                                                                                                getOptionValue={option => option.cat_id}
                                                                                                isMulti={false}
                                                                                                options={templates_group}
                                                                                                classNamePrefix="select2-selection"
                                                                                                name="master_id"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="form-group mb-3">
                                                                                            <label htmlFor="example-text-input" className="form-label">Name</label>
                                                                                            <input placeholder='The name of this Document' required className="form-control" type={'text'}
                                                                                                name="title" />

                                                                                            <input type={'hidden'} name="type" value={3} />
                                                                                        </div>

                                                                                        <div className="form-group mb-3">
                                                                                            <label htmlFor="training_for-text-label" className="form-label">Training for</label>
                                                                                            <Select
                                                                                                getOptionLabel={option => option.label}
                                                                                                getOptionValue={option => option.value}
                                                                                                isMulti={true}
                                                                                                options={user_types}
                                                                                                classNamePrefix="select2-selection position-releative"
                                                                                                name="training_for[]"
                                                                                            />
                                                                                        </div>
                                                                                    </>

                                                                                )
                                                                            }

                                                                            {
                                                                                action == 4 && (
                                                                                    <>
                                                                                        <div className="form-group mb-3">
                                                                                            <label htmlFor="example-text-input" className="form-label">Select a document</label>
                                                                                            <Select
                                                                                                getOptionLabel={option => option.title}
                                                                                                getOptionValue={option => option.cat_id}
                                                                                                isMulti={false}
                                                                                                options={templates_group}
                                                                                                classNamePrefix="select2-selection"
                                                                                                name="master_id"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="form-group mb-3">
                                                                                            <label htmlFor="example-text-input" className="form-label">Name</label>
                                                                                            <input placeholder='The name of this Subject' required className="form-control" type={'text'}
                                                                                                name="title" />

                                                                                            <input type={'hidden'} name="type" value={3} />
                                                                                        </div>
                                                                                    </>

                                                                                )
                                                                            }

                                                                            {
                                                                                (action == 5) && (
                                                                                    <>

                                                                                        <div className="form-group mb-3">
                                                                                            <label htmlFor="example-text-input" className="form-label">Name</label>
                                                                                            <input defaultValue={subAction.title} placeholder='The name of this Category' required className="form-control" type={'text'}
                                                                                                name="title" />

                                                                                            {
                                                                                                subAction.type == 'Subject' && (
                                                                                                    <div className="form-group mt-3 mb-3">
                                                                                                        <label htmlFor="example-text-input" className="form-label">Image</label>
                                                                                                        <input accept="image/*" className="form-control" type={'file'}
                                                                                                            onChange={(e) => changeHandler(e)}
                                                                                                            name="image" />
                                                                                                        <img className='mr-3 mt-3' height={40} width={40} src={`${AttachementsUrl}/user_${subAction.user_id}/training/${subAction.image}`} alt="" />
                                                                                                    </div>
                                                                                                )
                                                                                            }

                                                                                            <input type={'hidden'} name='id' value={subAction.cat_id} />
                                                                                            <input type={'hidden'} name="master_type" value={subAction.type} />

                                                                                            <input type={'hidden'} name="type" value={1} />
                                                                                        </div>
                                                                                        <div className="form-group mb-3">
                                                                                            <label htmlFor="training_for-text-label" className="form-label">Training for</label>
                                                                                            <Select
                                                                                                getOptionLabel={option => option.label}
                                                                                                getOptionValue={option => option.value}
                                                                                                isMulti={true}
                                                                                                value={user_types.filter(obj => subAction.training_for.includes(obj.value))}
                                                                                                options={user_types}
                                                                                                onChange={(e) => {
                                                                                                    var res = e.map(s => s.value);
                                                                                                    setSubAction((prevState) => ({
                                                                                                        ...prevState,
                                                                                                        training_for: res.toString(),
                                                                                                    }));
                                                                                                }}
                                                                                                classNamePrefix="select2-selection position-releative"
                                                                                                name="training_for[]"
                                                                                            />
                                                                                        </div>

                                                                                    </>


                                                                                )
                                                                            }



                                                                            {
                                                                                (action == 5) ? (
                                                                                    <div className="row">
                                                                                        <div className="col-md-12 text-center">
                                                                                            <button type="submit" className="btn btn-primary w-100" >Update</button>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : (

                                                                                    <div className="row">
                                                                                        <div className="col-md-12 text-center">
                                                                                            <button type="submit" className="btn btn-primary w-100" >Create</button>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }


                                                                        </form>
                                                                    )
                                                            }
                                                            <div className='cancel-button TraningBook text-center mt-2'>
                                                                <button onClick={() => setAction(0)} type="button" className="bwaves-effect waves-light btn btn-outline-dark w-100 " >Cancel</button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                    }

                                </div>
                            )
                        }
                    </div>
                </div>

                <Modal
                    isOpen={subActionModal}
                    fade={true}
                    size="md"
                    className="subActionTraining"
                    toggle={() => closeSubActionModal()}
                    centered={true}
                >
                    <div className="card card-body">
                        <div className="">
                            <div className='modal-header'>
                                <div className='modal-title'>
                                    {
                                        subAction.document_id ? (
                                            <h5>Add step to {subAction.title}.</h5>
                                        )
                                            : (
                                                <h5>Where do you want to move the {subAction.title} to?</h5>
                                            )
                                    }

                                </div>
                                <button onClick={() => closeSubActionModal()} className="mb-3 btn btn-link pull-right BtnCenterClose"><i className="fas fa-times"></i></button>
                            </div>



                            <form method="post" onSubmit={handlMoveAction}
                                encType="multipart/form-data" className="form-horizontal" id="selection-myform">

                                {
                                    subAction.type == 'Subject' && (
                                        <div className="form-group mt-3">
                                            <label htmlFor="example-text-input" className="form-label">Category</label>
                                            <Select
                                                isClearable
                                                getOptionLabel={option => option.title}
                                                getOptionValue={option => option.cat_id}
                                                isMulti={false}
                                                menuIsOpen={true}
                                                placeholder={'Select Category which you want to move'}
                                                options={waybookList}
                                                classNamePrefix="select2-selection"
                                                name="master_id"
                                            />
                                        </div>
                                    )
                                }


                                {
                                    subAction.type == 'Document' && (
                                        <div className="form-group mt-3">
                                            <label htmlFor="example-text-input" className="form-label">Category</label>
                                            <Select
                                                isClearable
                                                getOptionLabel={option => option.title}
                                                getOptionValue={option => option.cat_id}
                                                isMulti={false}
                                                menuIsOpen={true}
                                                placeholder={'Select Category which you want to move'}
                                                options={templates_group}
                                                classNamePrefix="select2-selection"
                                                name="master_id"
                                            />
                                        </div>
                                    )
                                }

                                {
                                    subAction.type == 'Step' && (
                                        <>

                                            <div className="form-group mt-3 mb-3">
                                                <label htmlFor="example-text-input" className="form-label">Add title to step</label>
                                                <input placeholder='Add title' required className="form-control" type={'text'}
                                                    name="title" />
                                            </div>
                                            <div className="form-group mb-3">
                                                <label htmlFor="training_for-text-label" className="form-label">Training for</label>
                                                <Select
                                                    getOptionLabel={option => option.label}
                                                    getOptionValue={option => option.value}
                                                    isMulti={true}
                                                    options={user_types}
                                                    classNamePrefix="select2-selection position-releative"
                                                    name="training_for[]"
                                                />
                                            </div>

                                        </>
                                    )
                                }

                                {
                                    subAction.document_id ? (

                                        <>
                                            <input type={'hidden'} name="document_id" value={subAction.document_id} />


                                            <div className="row mt-3">
                                                <div className="col-md-8">
                                                    <button type="submit" className="btn btn-primary pull-right w-50" >Create</button>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <input type={'hidden'} name="cat_id" value={subAction.cat_id} />


                                            <div className="row mt-3">
                                                <div className="col-md-8">
                                                    <button type="submit" className="btn btn-primary pull-right w-50" >Move</button>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }


                            </form>
                        </div>
                    </div>
                </Modal>

                <Modal
                    isOpen={stepActionModal}
                    fade={true}
                    size="md"
                    className="subActionTraining"
                    toggle={() => closeSubActionModal()}
                    centered={true}
                >
                    <div className="card card-body">
                        <div className="">
                            <div className='modal-header'>
                                <div className='modal-title'>

                                    {
                                        stepAction.action == 'Move' ?
                                            <h5>Where do you want to move the {stepAction.title} to?</h5>
                                            :
                                            'Update Step Title'
                                    }
                                </div>
                                <button onClick={() => closeSubActionModal()} className="mb-3 btn btn-link pull-right BtnCenterClose"><i className="fas fa-times"></i></button>
                            </div>



                            <form method="post" onSubmit={handlStepMoveAction}
                                encType="multipart/form-data" className="form-horizontal" id="selection-myform">

                                {
                                    stepAction.action == 'Move' && (

                                        <div className="form-group mt-3">
                                            <label htmlFor="example-text-input" className="form-label">Document</label>
                                            <Select
                                                isClearable
                                                getOptionLabel={option => option.title}
                                                getOptionValue={option => option.cat_id}
                                                isMulti={false}
                                                menuIsOpen={true}
                                                placeholder={'Select Document which you want to move'}
                                                options={templates_documents_group}
                                                classNamePrefix="select2-selection"
                                                name="document_id"
                                            />
                                        </div>
                                    )
                                }

                                {
                                    stepAction.action == 'Update' && (
                                        <>
                                            <div className="form-group mt-3 mb-3">
                                                <label htmlFor="example-text-input" className="form-label">Name</label>
                                                <input defaultValue={stepAction.title} placeholder='The name of this Step' required className="form-control" type={'text'}
                                                    name="title" />
                                            </div>

                                            <Select
                                                getOptionLabel={option => option.label}
                                                getOptionValue={option => option.value}
                                                isMulti={true}
                                                value={user_types.filter(obj => stepAction.training_for.includes(obj.value))}
                                                options={user_types}
                                                onChange={(e) => {
                                                    var res = e.map(s => s.value);
                                                    setStepAction((prevState) => ({
                                                        ...prevState,
                                                        training_for: res.toString(),
                                                    }));
                                                }}
                                                classNamePrefix="select2-selection position-releative"
                                                name="training_for[]"
                                            />
                                        </>
                                    )
                                }

                                <input type={'hidden'} name="action" value={stepAction.action} />


                                <input type={'hidden'} name="step_id" value={stepAction.step_id} />


                                <div className="row mt-3">
                                    <div className="col-md-8">

                                        <button type="submit" className="btn btn-primary pull-right w-50" >
                                            {
                                                stepAction.type == 'Move' ?
                                                    'Move'
                                                    :
                                                    'Update'
                                            }
                                        </button>
                                    </div>
                                </div>


                            </form>
                        </div>
                    </div>
                </Modal>
            </div >

        </WayBookContext.Provider >
    );
}

export default App;