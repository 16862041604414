import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import { AvForm, AvField, AvInput, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox, AvGroup } from "availity-reactstrap-validation";
import {
    currentDateTime,
    dcrypt,
    getPages,
    getPermission,
    getRoles,
    isAllowed,
    uInfo,
    uRole,
    uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
// import '../../../node_modules/bootstrap/js/dist/tooltip';
import Select from "react-select"
import countries from "../../common/data/countries";
import AsyncSelect from 'react-select/async';

export default class CreateUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            record: {},
            clients: [],
            clients_id: 0,
            coachTraining: [],
            cat_ids: 0,
            permission: [],
            emails: [],
            phoneNumbers: [],
            email_reminder: false,
            sms_reminder: false,
            compannyId: this.props.compannyId ? this.props.compannyId : '',

        };
    }

    componentDidMount() {
        this.getFormData();
    }

    getFormData = () => {
        fetch(`${ApiUrl}` + "get/pipeline/formdata", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {

                if (response.status === true) {
                    this.setState({
                        coachTraining: response.data.coachTraining,
                    });
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };



    handleSubmit = async (event, values) => {
        if (this.state.compannyId != '') {
            values.client_id = this.state.compannyId;
        }

        fetch(`${ApiUrl}` + "add/pipeline", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data, 'Editing');
                if (data.status === true) {
                    if (this.state.id == null) {
                        this.form && this.form.reset();
                    }
                    this.props.cancelSlider();
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    handlePermission = (selectedPermission) => {

        var filter = [];
        if (selectedPermission) {
            selectedPermission.map(fil => {
                filter.push(fil.user_id);
            })
        }

        this.setState({
            permission: filter.toString(),
            selectedPermission: selectedPermission
        })
    }


    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <AvForm
                                onValidSubmit={this.handleSubmit}
                                // model={this.state.record}
                                ref={(c) => (this.form = c)}
                                className="needs-validation"
                            >
                                <ModalBody className="row">

                                    <div className="col-md-12 mb-3">
                                        <AvField
                                            name="name"
                                            value={this.state.record.name}
                                            label="Name"
                                            className="form-control"
                                            required
                                        />
                                    </div>
                                    {/* <div className="col-md-12 mb-3">
                                        <AvField
                                            name="label"
                                            value={this.state.record.label}
                                            label="Label"
                                            className="form-control"
                                            required
                                        />
                                    </div> */}
                                    <div className="col-md-12 mb-3">
                                        <label className="control-label">Training (Optional)</label>
                                        <Select
                                            getOptionLabel={option => option.title}
                                            getOptionValue={option => option.cat_id}
                                            isMulti={false}
                                            value={this.state.coachTraining.filter(({ cat_id }) => cat_id === this.state.cat_ids)}
                                            options={this.state.coachTraining}
                                            classNamePrefix="select2-selection"
                                            isClearable
                                            placeholder="Select Training"
                                            onChange={e => this.setState({
                                                cat_ids: e ? e.cat_id : 0
                                            })}

                                        />
                                        <AvField type="hidden" name="previous_id" value={this.state.cat_ids} />

                                    </div>
                                    <AvField type="hidden" name="type" value={"training"} />

                                </ModalBody>
                                <ModalFooter>
                                    <button
                                        className="btn btn-primary waves-effect waves-light"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </ModalFooter>
                            </AvForm>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment >
        );
    }
}
