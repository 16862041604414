import React, { useState, useEffect, useRef } from "react";
import { ApiUrl, ProductName } from "../../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { bcrypt, dcrypt, uInfo, uToken } from "../../../../useToken";
import { Table, Card, CardBody, Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import SubSectionAccordion from "./SubSectionAccordion";
const SectionAccordion = ({ title, content, cat_id }) => {
  const [isActive, setIsActive] = useState(false);
  const [section_data, setsectiondata] = useState([]);

  useEffect(() => {
    getcatgegorysubsections(cat_id);
  }, []);

  const getcatgegorysubsections = (cat_id) => {
    fetch(`${ApiUrl}` + "get/resourceofsubsections/" + cat_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          console.log(response, "Sub sections");
          setsectiondata(response.data.records);
        } else {
           toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div className="accordion" id="accordion">
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button
            className="accordion-button fw-medium"
            type="button"
            style={{ cursor: "pointer" }}
            onClick={() => setIsActive(!isActive)}
          >
            {title}
          </button>
        </h2>
        {isActive && (
          <div className="accordion-body p-0">
            {section_data.map((data, key) => {
              if (
                data.file_type ==
                "application/vnd.openxmlformats-officedocument.word"
              ) {
                return (
                  <Link
                    to={
                      "/resource/product/contentview/" +
                      bcrypt(data.cat_id) +
                      "/" +
                      bcrypt(data.content_id)
                    }
                    className="dropdown-item"
                    params={{ content_id: data.content_id }}
                  >
                    <i className="fas fa-solid fa-file-word"></i> {data.title}
                  </Link>
                );
              } else if (data.file_type == "link") {
                return (
                  <Link
                    to={{ pathname: data.file_name }}
                    className="dropdown-item"
                    target="_blank"
                  >
                    <i className="fab fa-google"></i> {data.title}
                  </Link>
                );
              } 
              else if(data.file_type == "file"){
                return <Link
                  to={{pathname: data.file_name  } }
                  className="dropdown-item"
                  target="_blank"
                >
                  <i className="fab fa-file"></i> {data.title}
                </Link>;
              }else if (data.file_type == "application/pdf") {
                return (
                  <Link
                    to={
                      "/resource/product/contentview/" +
                      bcrypt(data.cat_id) +
                      "/" +
                      bcrypt(data.content_id)
                    }
                    className="dropdown-item"
                    params={{ content_id: data.content_id }}
                  >
                    <i className="fas fa-solid fas fa-file-pdf"></i> {data.title}
                  </Link>
                );
              } else if (data.file_type.match('video.*')) {
                return (
                  <Link
                    to={
                      "/resource/product/contentview/" +
                      bcrypt(data.cat_id) +
                      "/" +
                      bcrypt(data.content_id)
                    }
                    className="dropdown-item"
                    params={{ content_id: data.content_id }}
                  >
                    <i className="fas fa-solid fas fas fa-video"></i> {data.title}
                  </Link>
                );
              }
              else if(data.file_type == "application/vnd.openxmlformats-officedocument.presentationml.presentation"){
                return  <Link
                to={
                  "/resource/product/contentview/" +
                  bcrypt(data.cat_id) +
                  "/" +
                  bcrypt(data.content_id)
                }
                className="dropdown-item"
                params={{ content_id: data.content_id }}
              >

                
                <i className="fas fa-solid fas fas fa-tv"></i> {data.title}
              </Link>;
              }
              else if(data.file_type == "image/png" || data.file_type == "image/jpeg"){
                return  <Link
                to={
                  "/resource/product/contentview/" +
                  bcrypt(data.cat_id) +
                  "/" +
                  bcrypt(data.content_id)
                }
                className="dropdown-item"
                params={{ content_id: data.content_id }}
              >

                
                <i className="fas fa-solid 	far fa-file-image"></i> {data.title}
              </Link>;
              }
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionAccordion;
