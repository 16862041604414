import React, { useState, useEffect, useRef } from "react";
import { ApiUrl } from "../../../config";
import Context from './Context';
import swal from "sweetalert";
import { dcrypt,bcrypt, uInfo, uToken } from "../../../useToken";
const Box = (props) => {
    const params = React.useContext(Context);
    const [record, setRecord] =  useState({});
    useEffect(() => {
        getData();
    }, []);
    const getData = () => {
        fetch(`${ApiUrl}` + "get/systemForm/resourceInfo", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
              },
            body: JSON.stringify({ id: params.form_data.id, user_id: uInfo().user_id}),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setRecord(response.data.record);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        // swal({
        //     title: "Sorry, this component is still under development.",
        //     icon: "info",
        // });
        // return false;
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }
        submitData.append('user_id', uInfo().user_id);
        submitData.append('id', params.form_data.id);
        fetch(`${ApiUrl}` + "update/system/form", {
            method: "POST",
            body: submitData,
            headers: {
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    swal({
                        title: "Action performed successfully",
                        icon: "success",
                    });
                }else{
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    return (
            <div className="mt-1">
                    <div className="d-flex align-items-center gap-2 StepHeading">
                        <div className="StepNumber">{params.form_data.id} <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">{params.form_data.name}</h4>
                    </div>
                    <form method="post" onSubmit={handleSubmit} encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                        <div className="border-right p-2 mt-1">
                            <div className="page-content-box">
                                <div className="WizredForm">
                                    <div className="form-group">
                                        <label>
                                            <small>I.e. GoDaddy, Namecheap, Google, etc.</small>
                                        </label>

                                        <input name="website_email_host" defaultValue={record.website_email_host ?? ''}
                                            className="form-control no-border px-0"
                                            type="text" placeholder="Enter"></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-sm btn-primary mt-2"> Ok &nbsp;<i className="fas fa-check"></i></button>
                    </form>
            </div>
    );
};

export default Box;