import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardTitle,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import Loader from "react-loader";

import { AvField, AvForm } from "availity-reactstrap-validation";
import { ApiUrl } from "../../config";
import { bcrypt, uToken } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ItemShape from './../../assets/images/shape.png';

const Summary = (props) => {
  const [modal, setModal] = useState(false);
  const [id, setId] = useState("");
  const [question, setQuestion] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [toggleSwitch, settoggleSwitch] = useState(true)

  useEffect(() => {
    setLoaded(true);
    setQuestion(props.questions);
  }, [props]);

  function NumberWithCommas(x) {
    let y = Math.trunc(x);
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const twoGreaterThanZero = question => question.filter(x => x.profit_increase > 0);
  let completedCount = twoGreaterThanZero(question);
  return (
    <React.Fragment>
      <Loader loaded={loaded}> </Loader>

      <ul className={`summary-report h-100px`} >
        {question.map((row, i) => {
          return (
            <li key={i} className={row.profit_increase > 0 ? 'd-inline-block' : 'd-none'}  style={{ background:`${row.total_complete == 100 && 'rgb(244, 188, 51)'}` }}>
              <OverlayTrigger
                key={i}
                placement={'auto'}
                overlay={
                  <Tooltip>
                    <p
                      className={`${row.profit_increase > 0
                        ? "text-default"
                        : row.profit_increase < 0
                          ? "text-danger"
                          : "text-default"
                        }`}
                    >
                      Title: {row.cat_name}
                    </p>

                    <p
                      className={`${row.profit_increase > 0
                        ? "text-default"
                        : row.profit_increase < 0
                          ? "text-danger"
                          : "text-default"
                        }`}
                    >
                      Amount: {props.currency} {NumberWithCommas(row.amount)}
                    </p>
                    <p
                      className={`${row.profit_increase > 0
                        ? "text-default"
                        : row.profit_increase < 0
                          ? "text-danger"
                          : "text-default"
                        }`}
                    >
                      Percentage:  {row.percentage} %
                    </p>
                  </Tooltip>
                }
              >

                <p>{row.cat_name}</p>
              </OverlayTrigger>
              <div className="BottomShade"><img src={ItemShape}></img></div>
            </li>
          );
        })}
      </ul>

    </React.Fragment>
  );
};

export default Summary;
