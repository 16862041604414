import React, { useState, useEffect, useRef } from "react";
import { ApiUrl, ProductName, AttachementsUrl } from "../../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { bcrypt, dcrypt, uInfo, uToken } from "../../../../useToken";
import { Table, Card, CardBody, Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";

import SubSectionAccordion from "./SubSectionAccordion";
const SectionAccordion = ({ title, image, content, cat_id }) => {
  const [isActive, setIsActive] = useState(false);
  const [section_data, setsectiondata] = useState([]);
  const [subsection_data, setsubsectiondata] = useState([]);
  useEffect(() => {
    getcatgegorysections(cat_id);
    getcatgeorysubsections(cat_id);
  }, []);

  const getcatgeorysubsections = (cat_id) => {
    fetch(`${ApiUrl}` + "get/resourcecatsubsections/" + cat_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setsubsectiondata(response.data.records);
        } else {
           toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const getcatgegorysections = (cat_id) => {
    fetch(`${ApiUrl}` + "get/resourcecatsections/" + cat_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setsectiondata(response.data.records);
        } else {
           toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div className="accordion products" id="accordion">
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button
            className="accordion-button fw-medium"
            type="button"
            style={{ cursor: "pointer" }}
            onClick={() => setIsActive(!isActive)}
          >
            <img
              width="70px"
              className="accorion-image"
              src={AttachementsUrl + "user_2/training/" + image}
            ></img>
            {title}
          </button>
        </h2>
        {isActive && (
          <div className="accordion-body">
            {section_data.map((data, key) => {
              if (data.file_type == "application/vnd.openxmlformats-officedocument.word" || data.file_type =="application/msword" || data.file_type =="application/vnd.ms-powerpoint") {
                return  <Link
                  to={
                    "/resource/product/contentview/" +
                    bcrypt(data.cat_id) +
                    "/" +
                    bcrypt(data.content_id)
                  }
                  className="dropdown-item"
                  params={{ content_id: data.content_id }}
                >

                  
                  <i className="fas fa-solid fa-file-word"></i> {data.title}
                </Link>;
              } else if(data.file_type == "link"){
                return <Link
                  to={{ pathname: data.file_name }}
                  className="dropdown-item"
                  target="_blank"
                >
                  <i className="fab fa-google"></i> {data.title}
                </Link>;
              }
              else if(data.file_type == "file"){
                return <Link
                  to={{pathname: data.file_name  } }
                  className="dropdown-item"
                  target="_blank"
                >
                  <i className="fab fa-file"></i> {data.title}
                </Link>;
              }
              else if(data.file_type == "application/pdf"){
                return  <Link
                to={
                  "/resource/product/contentview/" +
                  bcrypt(data.cat_id) +
                  "/" +
                  bcrypt(data.content_id)
                }
                className="dropdown-item"
                params={{ content_id: data.content_id }}
              >

                
                <i className="fas fa-solid fas fa-file-pdf"></i> {data.title}
              </Link>;
              }
              else if(data.file_type.match('video.*')){
                return  <Link
                to={
                  "/resource/product/contentview/" +
                  bcrypt(data.cat_id) +
                  "/" +
                  bcrypt(data.content_id)
                }
                className="dropdown-item"
                params={{ content_id: data.content_id }}
              >

                
                <i className="fas fa-solid fas fas fa-video"></i> {data.title}
              </Link>;
              }
              else if(data.file_type == "application/vnd.openxmlformats-officedocument.presentationml.presentation"){
                return  <Link
                to={
                  "/resource/product/contentview/" +
                  bcrypt(data.cat_id) +
                  "/" +
                  bcrypt(data.content_id)
                }
                className="dropdown-item"
                params={{ content_id: data.content_id }}
              >

                
                <i className="fas fa-solid fas fas fa-tv"></i> {data.title}
              </Link>;
              }
              else if(data.file_type == "image/png" || data.file_type == "image/jpeg"){
                return  <Link
                to={
                  "/resource/product/contentview/" +
                  bcrypt(data.cat_id) +
                  "/" +
                  bcrypt(data.content_id)
                }
                className="dropdown-item"
                params={{ content_id: data.content_id }}
              >

                
                <i className="fas fa-solid 	far fa-file-image"></i> {data.title}
              </Link>;
              }

              
            })}

            {subsection_data.map((data, key) => (
              <SubSectionAccordion
                title={data.title}
                content={data.title}
                cat_id={data.cat_id}
                // props={row}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionAccordion;
