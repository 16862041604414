import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import Helmet from "react-helmet"



// Redux
import { Link } from "react-router-dom"

// import images
import { ProductName, logoLightPng, ApiUrl } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { uToken , bcrypt} from "../../useToken";
import { Tabs, Tab } from "react-bootstrap";

const SignupSelectPackage = props => {
    // handleValidSubmit

    const [packages, setPackages] = useState([]);
  

    useEffect(() => {
        getPackages();
    }, []);

    const handleValidSubmit = (event, values) => {
        console.log(values)
    }

    const getPackages = () => {
        fetch(`${ApiUrl}` + "packages/list/Coach/paid", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setPackages(response.data.records)
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    return (
        <React.Fragment>
            <Helmet>
                <title> Register </title>
            </Helmet>


            <div className="account-pages m-5" >



                <Card className="overflow-hidden" >

                    <CardBody className="pt-0" >

                        <h3 className="text-center mt-5 mb-4" >

                            <Link to="/"
                                className="d-block auth-logo" >

                                <img src={logoLightPng}
                                    alt=""
                                    height="50"
                                    className="auth-logo-dark" />
                            </Link>
                        </h3>
                    </CardBody>
                    <Tabs defaultActiveKey="monthly" id="uncontrolled-tab-example" className="mb-3 justify-center">
                        <Tab eventKey="monthly" title="Monthly">
                            <section className="pricing p-4">
                                <div className="row">
                                    {
                                        packages.map((row, i) => {
                                            if (row.package_interval == 'Monthly' && row.status == 'Active' ) {
                                                return (
                                                    <div className="col-lg-3 mt-4">
                                                        <div className="card mb-5 mb-lg-0">
                                                            <div className="card-body">

                                                                <h5 className="card-title text-muted text-uppercase text-center">{row.name}</h5>
                                                                <h6 className="card-price text-center">{row.price}<span className="period">/month</span></h6>
                                                                <hr />
                                                                <ul className="fa-ul">
                                                                    <li><span className="fa-li"><i className="fas fa-check"></i></span>{row.monthly_credits} Monthly Credits</li>
                                                                    {
                                                                        row.trial_package == 'Yes' ?
                                                                            <li><span className="fa-li"><i className="fas fa-check"></i></span>Trial Package</li>

                                                                            :
                                                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"></i></span>Trial Package
                                                                            </li>
                                                                    }

                                                                </ul>
                                                                <div className="d-grid">
                                                                    <Link to={`/signup/account/info/${bcrypt(row.package_id)}`} className="btn btn-primary text-uppercase">
                                                                        Sign Up
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }

                                </div>
                            </section>
                        </Tab>
                        <Tab eventKey="yearly" title="Yearly">
                            <section className="pricing p-4">
                                <div className="row">
                                    {
                                        packages.map((row, i) => {
                                            if (row.package_interval == 'Yearly'  && row.status == 'Active') {
                                                return (
                                                    <div className="col-lg-3 mt-4">
                                                        <div className="card mb-5 mb-lg-0">
                                                            <div className="card-body">

                                                                <h5 className="card-title text-muted text-uppercase text-center">{row.name}</h5>
                                                                <h6 className="card-price text-center">{row.price}<span className="period">/year</span></h6>
                                                                <hr />
                                                                <ul className="fa-ul">
                                                                    <li><span className="fa-li"><i className="fas fa-check"></i></span>{row.monthly_credits} Monthly Credits</li>
                                                                    {
                                                                        row.trial_package == 'Yes' ?
                                                                            <li><span className="fa-li"><i className="fas fa-check"></i></span>Trial Package</li>

                                                                            :
                                                                            <li className="text-muted"><span className="fa-li"><i className="fas fa-times"></i></span>Trial Package
                                                                            </li>
                                                                    }

                                                                </ul>
                                                                <div className="d-grid">
                                                                    <Link to={`/signup/account/info/${bcrypt(row.package_id)}`} className="btn btn-primary text-uppercase">
                                                                        Sign Up
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </section>
                        </Tab>
                    </Tabs>
                </Card>
                <div className="mt-5 text-center" >
                    <p>
                        Already have an account ? {" "}
                        <Link to="/login"
                            className="text-primary" > {" "}
                            Login
                        </Link>{" "}
                    </p>
                    <p> ©{new Date().getFullYear()} {ProductName}
                        <span className="d-none d-sm-inline-block" > </span>
                        { /* <span className="d-none d-sm-inline-block"> - Created <i className="mdi mdi-heart text-danger"></i> by Zamsol.</span> */}
                    </p>
                </div>


            </div>
        </React.Fragment>
    )
}

export default SignupSelectPackage