import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import {
  bcrypt,
  dcrypt,
  isAllowed,
  uInfo,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";

import StatusCreate from "./statusCreate.js";
import StatusUpdate from "./statusUpdate.js";
import CampaignNav from "../nav";
import swal from 'sweetalert';

export default class AutomationIndex extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        text: "#",
        key: "automation_id",
      },
      {
        text: "Title",
        key: "title",
        cell: (record, index) => {
          return (
            <Fragment>
              <a
                className="btn btn-link btn-sm mb-1"
                title="Add flow"
                onClick={this.flowpage.bind(this, record, index)}
              >
                {record.type == 'Trigger' ? record.title + " ( " + record.event_type + " )" : record.title}
              </a>
            </Fragment>
          );
        },
      },
      {
        text: "Group Name",
        key: "group_title",
      },
      {
        text: "Type",
        key: "type",
      },
      {
        text: "Status",
        key: "status",
        cell: (record, index) => {
          if (record.status == 'Active') {
            return <p key={index} className="badge bg-success">{record.status}</p>
          } else if (record.status == 'Inactive') {
            return <p key={index} className="badge bg-danger">{record.status}</p>
          } else {
            return <p key={index}>{record.status}</p>
          }
        }
      },
      {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          return (
            <Fragment>
              <button
                className="btn btn-link btn-sm"
                title="Add flow"
                onClick={this.flowpage.bind(this, record, index)}
              >
                <i className="fas fa-plus"></i>
              </button>
              <button
                type="button"
                color="info"
                className="btn btn-link btn-sm mx-2"
                onClick={() => {
                  this.setState({
                    show4Slider: true,
                    page2Class: "slide-in",
                    sliderShown: "show4Slider",
                    editId: record.automation_id,
                  });
                }}
              >
                <i className="fas fa-edit"></i>
              </button>
              <button
                className="btn btn-link btn-sm"
                onClick={this.deleteRecord.bind(this, record, index)}
              >
                <i className="fas fa-trash"></i>
              </button>

            </Fragment>
          );
        },
      },
    ];
    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
      button: {
        excel: false,
        print: false,
        csv: false,
      },
    };
    this.state = {
      camapign_id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      records: [],
      total_pages: 0,
      record: {},
      filter: {},
      page_name: "Automation",
      customDate: true,
      page2Class: "",
      editId: "",
      sliderShown: "",
      filter: "All Automation",
      tab: "7",
    };
  }

  flowpage = (record, index) => {
    if (record.type == 'Trigger') {
      this.props.history.push("/campaign/automation/flow/" + bcrypt(record.campaign_id) + '/' + bcrypt(record.group_id) + '/' + bcrypt(record.automation_id))
    } else if (record.type == 'Drip') {
      this.props.history.push("/campaign/drips/flow/" + bcrypt(record.campaign_id) + '/' + bcrypt(record.group_id) + '/' + bcrypt(record.automation_id))
    } else if (record.type == 'EventReminder') {
      this.props.history.push("/campaign/eventreminder/" + bcrypt(record.campaign_id) + '/' + bcrypt(record.group_id) + '/' + bcrypt(record.automation_id))
    }
  }
  deleteRecord = (record, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "delete/campaign/automation", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: record.automation_id }),
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                this.getData(this.state.camapign_id, "");
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });

              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            //Then with the error genereted...
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
  };

  componentDidMount() {
    this.getData(this.state.camapign_id, "");
  }


  getData = (queryString = this.state.camapign_id, data) => {
    fetch(`${ApiUrl}` + "campaign/automations/" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          console.log(response, "debugging");
          this.setState({
            total_pages: response.data.total,
            records: response.data.records,
            roles: response.data.roles,
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleFilterSubmit = async (event, values) => {
    this.setState({
      filter: values,
    });
    this.getData(this.state.camapign_id, values);
  };

  cancelFilter = async () => {
    console.log(this.state.records);
    this.form && this.form.reset();
    this.setState({ filter: {}, customDate: true });
    this.getData(this.state.camapign_id, "");
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData(this.state.camapign_id + "?" + queryString, this.state.filter);
  };

  cancelSlider = async () => {
    this.getData(this.state.camapign_id, "");

    this.setState({
      page2Class: "slide-out2",
      editId: "",
      sliderShown: "",
      // showSlider: false,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col sm={5}>
            <div className="page-title-box">
              <h4> {this.state.page_name} Lists</h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#">{this.state.page_name} Lists</Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={3}></Col>
          <Col sm={4}>

          </Col>
        </Row>

        <CampaignNav activeTab={'7'} campaignId={bcrypt(this.state.camapign_id)} />
        <ToastContainer />
        {this.state.show3Slider && this.state.sliderShown == "show3Slider" && (
          <div className={`page3 ${this.state.page2Class} custom-scroll`}>
            <div>
              <div className="modal-head top-heading">
                <h2> Add Automation </h2>
                <button
                  onClick={() => {
                    this.setState({ page2Class: "slide-out2" });
                  }}
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"> </i>
                </button>
              </div>
              <StatusCreate
                cancelSlider={this.cancelSlider}
                campaign_id={this.state.camapign_id}
              />
            </div>
          </div>
        )}
        {this.state.show4Slider && this.state.sliderShown == "show4Slider" && (
          <div className={`page3 ${this.state.page2Class} custom-scroll`}>
            <div>
              <div className="modal-head top-heading">
                <h2> Edit Automation </h2>
                <button
                  onClick={() => {
                    this.setState({ page2Class: "slide-out2" });
                  }}
                  className="btn btn-link text-white"
                >
                  <i className="fas fa-times"> </i>
                </button>
              </div>
              <StatusUpdate
                cancelSlider={this.cancelSlider}
                id={this.state.editId}
              />
            </div>
          </div>
        )}

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="pipeline-status">
                <div className="page-title-box text-align-right">
                  {isAllowed(uInfo(), ["can_create"]) && (
                    <button
                      type="button"
                      color="info"
                      className="btn btn-info"
                      onClick={() => {
                        this.setState({
                          show3Slider: true,
                          page2Class: "slide-in",
                          sliderShown: "show3Slider",
                        });
                      }}
                    >
                      <i className="mdi mdi-plus"> </i>
                      Create Automation
                    </button>
                  )}
                </div>
                <ReactDatatable
                  config={this.config}
                  records={this.state.records}
                  columns={this.columns}
                  dynamic={true}
                  total_record={this.state.total_pages}
                  onChange={this.tableChangeHandler}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
