import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom";
import { ApiUrl } from "../../config";
import Context from './Context';
import swal from "sweetalert";
import { uInfo } from "../../useToken";
const Box10 = (props) => {
    const params = React.useContext(Context);


    return (
        <div>
            <div className="WizredTopBar">
                <div className="SmallContaner">
                    <div className="d-flex align-items-center gap-2 justify-content-between">
                        <h3 className="m-0">   <i className="fas fa-arrow-right"></i> Finish
                        </h3>
                        <button type="button" className={`btn btn-md btn-link ${params.from != '' ? 'd-none' : ''}`} onClick={() => window.location.href = "/dashboard"}>
                            <i className="fas fa-home" /> Home
                        </button>
                    </div>
                </div>
            </div>
            <div className="box-center-align mt-5">
                <div className="SmallContaner">
                    <div className="border-top border-bottom border-left border-right p-4 mt-4">
                        <div className="page-content-box mt-4">
                            <div class="alert alert-success" role="alert">
                                {
                                    uInfo().user_id != 2 && (
                                        <>
                                            <h4 class="alert-heading">{uInfo().name}</h4>
                                            <p>We have received your website request. The entire process is expected to take up to 14 days.
                                                You'll be notified as soon as it's completed. Thank you for your patience.</p>
                                            <hr />
                                        </>
                                    )
                                }

                                {
                                    uInfo().user_id == 2 && (
                                        <div className="">
                                            <h4 class="alert-heading">Preview Website</h4>
                                            <hr />
                                            {
                                                params.setting.domain_type == 'sub' ? (
                                                    <>
                                                        Website Link: <a className="" href={`https://${params.setting.domain}.profitcoach.app`} target={'_blank'}>{`https://${params.setting.domain}.profitcoach.app`}</a>
                                                    </>
                                                ) : (
                                                    <>
                                                        Website Link: <a className="" href={`https://${params.setting.domain}`} target={'_blank'}>{`https://${params.setting.domain}`}</a>
                                                    </>
                                                )
                                            }
                                        </div>
                                    )
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Box10;