import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  ModalFooter,
} from "reactstrap";
import Helmet from "react-helmet";
import {
  dcrypt,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import ErrorBoundary from "../../../components/Common/ErrorBoundary";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Froala from "../../Froala";

export default class TrainingContentView extends Component {

  constructor(props) {
    super(props);
    console.log(props.location.state)
    this.state = {
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      record: {},
      trackerCategories: [],
      page_name: "Edit Action Step",
      loader: true,
      categoryfilter: props.location.state ? props.location.state : null
    };
  }

  componentDidMount() {
    this.getData(this.props.match.params.id);

  }

  getData = (id) => {
    fetch(`${ApiUrl}` + "get/edit/tracker/" + dcrypt(id), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            record: response.data.record,
            trackerCategories: response.data.trackerCategories
          });
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    formData.append('id', this.state.id ? this.state.id : -1);


    this.setState({ loader: false });
    fetch(`${ApiUrl}` + "add/tracker", {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: formData,
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          this.props.history.goBack();
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        this.setState({ loader: true });

      })
      //Then with the error genereted...
      .catch((error) => {
        this.setState({ loader: true });
        console.error("Error:", error);
      });
  };

  render() {
    return (
      <ErrorBoundary>
        <Helmet>
          <title>
            {this.state.page_name}
            Tables
          </title>
        </Helmet>

        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4>{this.state.page_name}</h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#">{this.state.page_name}</Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                type="button"
                color="secondary"
                onClick={() => this.props.history.push("/tracker", this.state.categoryfilter)}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>

        <ToastContainer />

        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <form onSubmit={this.handleSubmit} method="post"
                  encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                  <div className="form-group mb-3">
                    <label>Category </label>
                    <select onChange={(e) =>
                      this.setState(prevState => {
                        let record = Object.assign({}, prevState.record);
                        record.cat_key = e.target.value;
                        return { record };
                      })
                    }
                      value={this.state.record.cat_key} required name="cat_key" className="form-control">
                      <option value=""> Select Option </option>
                      {
                        this.state.trackerCategories.map((row, i) => {
                          return (
                            <option key={i} value={row.cat_key} > {`${row.cat_name}`}</option>
                          );
                        })
                      }
                    </select>
                  </div>

                  <div className="form-group mb-3">
                    <label>Title </label>
                    <input defaultValue={this.state.record.title} className="form-control" name="title" required />
                  </div>

                  {
                    this.state.record.description && (

                      <div className="mb-3" >

                        <Froala content={this.state.record.description} nameKey={'description'} insideForm={true} btnId={'submit-tracker'} handleSubmit={() => console.log('hi')} use_token={'users'} />


                        {/* <SunEditor name="description"
                      setOptions={
                        {
                          height: 600,
                          width: '100%',
                          resizeEnable: 'false',
                          buttonList: [
                            ["undo", "redo", "font", "fontSize", "formatBlock"],
                            [
                              "bold",
                              "underline",
                              "italic",
                              "strike",
                              "subscript",
                              "superscript",
                              "removeFormat",
                            ],
                            [
                              "fontColor",
                              "hiliteColor",
                              "outdent",
                              "indent",
                              "align",
                              "horizontalRule",
                              "list",
                              "table",
                            ],
                            [
                              "link",
                              "image",
                              "video",
                              "fullScreen",
                              "showBlocks",
                              "codeView",
                              "preview",
                            ],

                          ],
                          proportion: 'Constrain proportions assasa',
                        }
                      }
                      setContents={this.state.record.description}
                    /> */}



                      </div>
                    )
                  }
                  <ModalFooter className="border-0 py-0 pe-0">
                    <Button
                      type="button"
                      color="secondary"
                      onClick={() => this.props.history.goBack()}
                      disabled={this.state.loader ? false : true}
                    >
                      Cancel
                    </Button>
                    <button
                      className="btn btn-primary waves-effect waves-light"
                      type="submit"
                      disabled={this.state.loader ? false : true}
                    >
                      Submit
                    </button>
                  </ModalFooter>
                </form>
                {/* <div dangerouslySetInnerHTML={{ __html: this.state.recordall.content }} /> */}
              </CardBody>

            </Card>

            {/* <iframe width="100%" height="315" src={this.state.content_url} frameborder="0" allowfullscreen></iframe> */}
            {/* 
                <ReactPlayer
                  width="100%"
                  url={this.state.content_url}
                  playing={this.state.playing}
                  onError={(e) => console.log("onError", e)}
                  onStart={() => console.log("onStart")}
                  controls={true}
                /> */}
            {/* <div className="button-center" style={{textAlign: 'center'}}>
                  <button
                    className="btn btn-primary btn-sm mb-1 mr-50 mt-3"
                    onClick={this.handlePlayPause}
                  >
                    {this.state.playing ? "Pause" : "Play"}
                  </button>
                </div> */}
          </Col>
        </Row>
      </ErrorBoundary>
    );
  }
}
