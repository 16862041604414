import React, { Component, Fragment } from "react";
import { Document, Page } from "react-pdf";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";

import ReactPlayer from "react-player";
//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  dcrypt,
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
// import test from "./test.docx";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import ResourcesSidebar from "./resources-sidebar";
import ErrorBoundary from "../../../components/Common/ErrorBoundary";
import FileViewer from "react-file-viewer";
export default class ResourceContentView extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        text: "SR#",
        key: "sr",
        sortable: false,
      },
      {
        text: "Title",
        key: "title",
        sortable: true,
      },
      {
        text: "Category",
        key: "category",
        sortable: true,
      },
      {
        text: "Created At",
        key: "created_at",
      },
      {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          return (
            <Fragment>
              {isAllowed(uInfo(), ["can_edit"]) && (
                <Link
                  color="info"
                  className="btn btn-primary btn-sm mb-1 mr-5"
                  to={"/training/content/view/" + bcrypt(record.id)}
                >
                  View
                </Link>
              )}
              {isAllowed(uInfo(), ["can_delete"]) && (
                <button
                  className="btn btn-danger btn-sm mb-1"
                  onClick={this.deleteRecord.bind(this, record, index)}
                >
                  Delete
                </button>
              )}
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      key_column: "sr",
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
      button: {
        excel: false,
        print: true,
        csv: true,
      },
    };
    this.state = {
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      records: [],
      roles: [],
      total_pages: 0,
      modal: false,
      record: {},
      recordall: {},
      controls: true,
      filter: {},
      page_name: "Resource Content",
      customDate: true,
      content_url: "",
      playing: true,
      volumne: "1",
      type: "",
      numPages: "",
    };
  }

  componentDidMount() {
    this.getData();
  }

  onError(e) {
    console.log(e, "error in file-viewer");
  }
  getData = (queryString = this.state.id, data) => {
    fetch(`${ApiUrl}` + "view/training/content/" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          //console.log(response.data,'data here'); 
          this.setState({
            content_url: response.data.record,
            recordall: response.data.recordall
          });
          if (response.data.recordall.file_type == "application/pdf") {
            this.setState({
              type: "pdf",
            });
            //window.open(this.state.content_url, "_blank");
          }
          if (
            response.data.recordall.file_type ==
            "application/vnd.openxmlformats-officedocument.word"
          ) {
            this.setState({
              type: "docx",
            });
          }
          if (
            response.data.recordall.file_type.match('video.*')
          ) {
            this.setState({
              type: "mp4",
            });
          }
          if (
            response.data.recordall.file_type ==
            "application/vnd.openxmlformats-officedocument.presentationml.presentation"
          ) {
            this.setState({
              type: "pptx",
            });
          }
          if (
            response.data.recordall.file_type ==
            "image/png"
          ) {
            this.setState({
              type: "png",
            });
          }
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  onDocumentLoadSuccess = (numPages) => {
    this.setState({ numPages: numPages });
  };
  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing });
  };

  render() {
    return (
      <ErrorBoundary>
        <Helmet>
          <title>
            {this.state.page_name}
            Tables
          </title>
        </Helmet>
        <Row>
          {/* <Col lg="2" className="pl-0">
            <ResourcesSidebar menuOpen={false} props={this.props} />
          </Col> */}
          <Col lg="12">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4> {this.state.page_name} </h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> {this.state.page_name} </Link>
                    </li>
                  </ol>
                </div>
              </Col>
              <Col sm={6}>
                <div className="page-title-box text-align-right">
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => this.props.history.goBack()}
                  >
                    Back
                  </Button>
                </div>
              </Col>
            </Row>
            <ToastContainer />
            <Row>
              <Col xl="12">
                <CardTitle>Title: {this.state.recordall.title}</CardTitle>
                {/* <iframe width="100%" height="315" src={this.state.content_url} 
              frameborder="0" allowfullscreen></iframe> */}
                {this.state.type == "mp4" && this.state.type !== "pdf" && (
                  <Row>
                    <Col xl="3">
                    </Col>
                    <Col xl="6">
                      <ReactPlayer
                        url={this.state.content_url}
                        playing={this.state.playing}
                        onError={(e) => console.log("onError", e)}
                        volume={this.state.volume}
                        onStart={() => console.log("onStart")}
                        controls={this.state.controls}
                      />
                    </Col>
                    <Col xl="3">
                    </Col>
                  </Row>
                )}

                {this.state.type !== "pdf" && this.state.type !== "mp4" && (
                  <FileViewer
                    fileType={this.state.type}
                    filePath={this.state.content_url}
                    onError={this.onError}
                  />
                )}

                {this.state.type == "pdf" && (
                  <div className="ipharediv" style={{ height: "calc(100vh - 185px)" }}>
                    <iframe
                      frameborder="0"
                      scrolling="no"
                      height="100%"
                      width="100%"
                      src={this.state.content_url}
                    ></iframe>
                  </div>
                )}
                {this.state.type == "png" && (
                  <div className="ipharediv" style={{ height: "calc(100vh - 185px)" }}>
                    {this.state.content_url}
                    <iframe
                      frameborder="0"
                      scrolling="no"
                      height="100%"
                      width="100%"
                      src={this.state.content_url}
                    ></iframe>
                  </div>
                )}

                <div className="m3">
                  <label>Content</label>

                  <div dangerouslySetInnerHTML={{ __html: this.state.recordall.content }} />
                </div>

              </Col>
            </Row>
          </Col>
        </Row>
      </ErrorBoundary>
    );
  }
}
