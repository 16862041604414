import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    Button,
    ModalBody,
    ModalFooter,
    CardBody,
    Badge
} from "reactstrap";
import {
    AvForm,
    AvField,
} from "availity-reactstrap-validation";
import {
    dcrypt,
    bcrypt,
    uToken,
    isValidJSONString,
} from "../../useToken";
import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import CampaignNav from "./nav";
import ReactDatatable from "@ashvin27/react-datatable";
import { Modal, Tab, Tabs } from "react-bootstrap";

export default class CampaignClientBatches extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id
                ? dcrypt(this.props.match.params.id)
                : null,
            batchID: this.props.match.params.batch_id
                ? dcrypt(this.props.match.params.batch_id)
                : null,
            page_name: "Campaign Batches Clients",
            from: this.props.from ? this.props.from : "",
            record: {},
            showModal: false,
        };
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            show_filter: false,
            show_pagination: true,
            button: {
                excel: false,
                print: false,
                csv: false,
            },
        };
        this.columns = [
            {
                text: "Client Id#",
                key: "client_id",
            },
            {
                text: "First name",
                key: "first_name",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <Link
                                key={index}
                                to={
                                    "/client/dialer/" +
                                    bcrypt(record.client_id) +
                                    "/" +
                                    bcrypt(record.coach_id)
                                }
                                className="waves-effect btn btn-light"
                                style={{ textAlign: 'left' }}
                            >
                                <i className="fas fa-newspaper pr-5"> </i>
                                {record.first_name + " " + record.last_name}
                            </Link>
                        </Fragment>
                    );
                },
            },
            {
                text: "Email",
                key: "email",
            },
            // {
            //     text: 'Country Code',
            //     key: 'country_code'
            // },
            {
                text: "Mobile",
                key: "mobile",
            },
            {
                text: "Phone",
                key: "phone",
            },
            {
                text: "Status",
                key: "report_status",
                cell: (record, index) => {
                    if (record.report_status == 'sent') {
                        return <Badge color="success" className="bg-success">{record.report_status}</Badge>
                    } else if (record.report_status == 'delivered') {
                        return <Badge color="info" className="bg-info">{record.report_status}</Badge>
                    } else if (record.report_status == 'failed') {
                        return <Badge color="danger" className="bg-danger">{record.report_status}</Badge>
                    } else if (record.report_status == 'pending') {
                        return <Badge color="warning" className="bg-warning">{record.report_status}</Badge>
                    }else{
                        return <Badge color="light" className="bg-light">{record.report_status}</Badge>
                    }
                },
            },
            {
                key: "action",
                text: "Action",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <button
                                className="btn btn-link btn-sm mb-1"
                                onClick={this.viewStatus.bind(this, record, index)}
                            >
                                <i className="fas fa-eye" />
                            </button>
                            {/* )} */}
                        </Fragment>
                    );
                }
            },
        ]
    }

    componentDidMount() {
        this.state.id != null && this.getData();
    }

    viewStatus = (record, index) => {

        fetch(`${ApiUrl}` + "get/sub/report/body/" + record.sub_log_id + '/' + record.client_id, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    record.body = response.data.body;
                    record.subject = response.data.subject;
                    this.setState({
                        record: record,
                        showModal: true
                    });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });

        // this.setState({
        //     record: record,
        //     showModal: true
        // })
    };


    getData = () => {
        fetch(`${ApiUrl}` + "get/batches/clients/" + this.state.batchID, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        records: response.data.records,
                    });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    render() {
        return (
            <React.Fragment>

                <Row>
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4> {this.state.page_name} </h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#"> {this.state.page_name} </Link>
                                </li>
                            </ol>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className="page-title-box text-align-right">
                            <Button
                                type="button"
                                color="secondary"
                                onClick={() => this.props.history.goBack()}
                            >
                                Back
                            </Button>
                        </div>
                    </Col>
                </Row>
                <ToastContainer />
                <Row>
                    <Col className="col-12">
                        {/* <CampaignNav activeTab={'8'} campaignId={bcrypt(this.state.id)} /> */}
                        <Row className="mt-3">
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <ReactDatatable
                                            key={1}
                                            config={this.config}
                                            records={this.state.records}
                                            columns={this.columns}
                                            dynamic={false}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Modal
                    show={this.state.showModal}
                    onHide={() => this.setState({ showModal: false, record: {} })}
                    dialogClassName="modal-90w"
                    size="lg"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Log Details
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='row'>
                        <div className="row campddd_log">
                            <div className="col-md-6">
                                <h4 className="font-size-14"><span><b>From:</b> </span> {this.state.record.from}</h4>
                                <h4 className="font-size-14"><span><b>To:</b> </span> {this.state.record.to}</h4>
                            </div>
                            <div className="col-md-6">
                                <h4 className="font-size-14"><span><b>Client:</b> </span> {this.state.record.first_name} {this.state.record.last_name}</h4>
                                <h4 className="font-size-14"><span><b>Status:</b> </span> {this.state.record.report_status}</h4>

                            </div>
                            {
                                this.state.record.report_reason != '' && (
                                    <div className="col-md-12">
                                        <h4 className="font-size-14"><span><b>Reason:</b> </span> {this.state.record.report_reason}</h4>
                                    </div>
                                )
                            }
                            {
                                this.state.record.type == 'email' && (
                                    <div className="col-md-12">
                                        <h4 className="font-size-14"><span><b>Subject:</b> </span> {this.state.record.subject}</h4>

                                    </div>
                                )
                            }


                            {
                                this.state.record.type == 'voice' || this.state.record.type == 'call' || this.state.record.type == 'rvm' ? (
                                    <>
                                        {
                                            this.state.record.body && (
                                                <audio controls>
                                                    <source src={this.state.record.body} type="audio/ogg" />
                                                    <source src={this.state.record.body} type="audio/mpeg" />
                                                    Your browser does not support the audio element.
                                                </audio>
                                            )
                                        }
                                    </>
                                ) : (
                                    <>
                                        {
                                            this.state.record.body && (
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div dangerouslySetInnerHTML={{ __html: this.state.record.body }} />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </>

                                )
                            }


                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment >
        );
    }
}
