import React, { useEffect, useState } from "react";

import { Link, Redirect, useHistory } from "react-router-dom";

// reactstrap
import {
  Row,
  Col,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from "reactstrap";

import { ApiUrl, logoLightPng, logoLightSmallPng } from "../../config.js";
import { bcrypt, logout, uInfo, uToken } from "../../useToken";
import { toast } from "react-toastify";
import ProfileMenuClient from "../CommonForBoth/TopbarDropdown/ProfileMenuClient";
//i18n

const Header = (props) => {
  const [createmenu, setCreateMenu] = useState(false)
  let history = useHistory();


  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
  });

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }



  const checkVerfication = () => {
    fetch(`${ApiUrl}` + "check/verification", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          if (response.data.verification_status == 'N' || response.data.is_approved != 'Yes') {
            setTimeout(history.push("/verification/wizard"), 4000);
          }
        } else {
          toast.error(response.message, { theme: "colored" });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const Logout = () => {
    fetch(`${ApiUrl}` + "logout", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${uToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        logout();
        history.push("/login");
        // if (data.status === true) {
        //   toast.success(data.message, { theme: "colored" });
        // } else {
        //   toast.error(data.message, { theme: "colored" });
        // }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  function tToggle() {
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }
  return (
    <React.Fragment>
      <div className="navbar-header client-header">
        <Container fluid className="px-0">

          <Col className="nav-bg px-0 d-flex space-between">
            <div className="float-start">
              <div className="navbar-brand-box d-lg-none">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm pl-2">
                    <img src={logoLightSmallPng} alt="" height="45" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLightPng} alt="" height="45" />
                  </span>
                </Link>


              </div>
              <button
                type="button"
                className="btn btn-sm px-3 font-size-24 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                onClick={() => {
                  setCreateMenu(!createmenu)
                }}
                data-target="#topnav-menu-content"
              >
                <i className="mdi mdi-menu"></i>
              </button>
            </div>
            <div className="top_navbar">
              <div className="container-fluid">
                <div className="topnav">
                  <nav
                    className="navbar navbar-light navbar-expand-lg topnav-menu"
                    id="navigation"
                  >
                    <Collapse
                      isOpen={props.leftMenu}
                      className="navbar-collapse"
                      id="topnav-menu-content"
                    >
                      <ul className="navbar-nav">
                        {/* <li className="nav-item">
                                    <Link to="/client/profile" className="nav-link">
                                        <i className="ti-dashboard"></i> {"My Activity"}
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="/client/trainings" className="nav-link">
                                        <i className="mdi mdi-file-document"></i> {"Trainings"}
                                    </Link>
                                </li> */}
                        <div className="navbar-brand-box d-inline-block centerr-logo mx-3">
                          <Link to="/" className="logo logo-dark">
                            <span className="logo-sm pl-2">
                              <img src={logoLightSmallPng} alt="" height="45" />
                            </span>
                            <span className="logo-lg">
                              <img src={logoLightPng} alt="" height="45" />
                            </span>
                          </Link>
                        </div>
                      </ul>
                    </Collapse>
                  </nav>
                </div>
              </div>
            </div>
            <div className="float-end">
              <div className="dropdown d-none  none">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  onClick={() => {
                    toggleFullscreen();
                  }}
                  data-toggle="fullscreen"
                >
                  <i className="mdi mdi-fullscreen font-size-24"></i>
                </button>
              </div>
              <ProfileMenuClient />
              {/* <ul className="navbar-nav client-nav-bar">
                <li className="nav-item">
                  <Link to="/settings/profile" className="nav-link">
                    <i className="ti-settings"></i> {"Settings"}
                  </Link>
                </li>

                <li className="nav-item">
                  <button
                    onClick={() => Logout()}
                    className="nav-link btn-btn-link"
                  >
                    <i className="mdi mdi-power" />
                    <span>{"Logout"}</span>
                  </button>
                </li>
              </ul> */}

            </div>
          </Col>
        </Container>

      </div >
      <Row>
      </Row>


    </React.Fragment >
  );
};

export default Header;
