import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert"
import Helmet from "react-helmet";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {dcrypt, uToken,} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import Select from "react-select"
import Loader from "react-loader";
import $ from "jquery";
window.jQuery = $;
window.$ = $;

require("formBuilder");
require("jquery-ui-sortable");
require('formBuilder/dist/form-render.min.js')

export default class ClientFormCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id ? dcrypt(this.props.match.params.id) : null,
      page_name: this.props.match.params.id ? "Edit System Form" : "Create System Form",
      from: this.props.from ? this.props.from : '',
      record: {
        name: '',
        permission: '',
        group_id: '',
      },
      groups: [],
      permissions: [],
      tag_id: [],
      tab: "1",
      tokens: [],
      warningAlert: false,
      loading: true,
      columns: [],
      mapping: [],
      formData: [],
      group_id: 0
    };

    this.updateFormBuilder = this.updateFormBuilder.bind(this)
  }

  componentDidMount() {
      this.getData();
      this.getFormData();
  }

  getData = () => {
    var self = this;
    fetch(`${ApiUrl}` + "get/edit/systemForms", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ` + uToken(),
        },

        body: JSON.stringify({ id: this.state.id },
        ),
    })
    .then((response) => response.json())
    .then((response) => {
        if (response.status === true) {
           
            this.setState({
              record: response.data.record,
            });

        } else {
             toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
    })
    .catch((error) => {
        console.error("Error:", error);
    });
  }

  getFormData = () => {
    fetch(`${ApiUrl}` + "get/meeting/formdata", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {

        if (response.status === true) {


          this.setState({
            groups: response.data.groups,
            permissions: response.data.coachPartners,
            tokens: response.data.tokens.default_fields
          });
        } else {
           toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
 


  handleSubmit = async (event, errors, values) => {
    values.id = this.state.id;
      this.setState({
          loading:  false
      });
      fetch(`${ApiUrl}` + "edit/systemForms", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify(values),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === true) {
            toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
              setTimeout(
                  () => this.props.history.push('/system/forms'),
                  1000
              );
          } else {
            this.setState({
              loading:  false
            });
            toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
   
  };



  toggleTab = (index) => {
    if (this.state.tab !== index) {
      this.setState({
        tab: index,
      });
    }
  };

  updateFormBuilder = (data) => {
     fetch(`${ApiUrl}` + "update/clientForms/metaData", {
         method: "POST",
         headers: {
             Accept: "application/json",
             "Content-Type": "application/json",
             Authorization: `Bearer ` + uToken(),
         },
         body: JSON.stringify({ id: this.state.id, metadata: data }),
     })
         .then((response) => response.json())
         .then((data) => {
             if (data.status === true) {
                $('.fb-editor').empty();
                $('.fb-rendered-form').empty();
                this.getData();
                this.toggleTab("3");
             } else {
                 toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                 window.location.reload();
             }
         })
         .catch((error) => {
             console.error("Error:", error);
         });
 };
  render() {
    let label = '';
    console.log(this.state.group_id ,'aaaaaaaaaaaaaaaaaaa')
    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name} </title>
        </Helmet>
        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.page_name} </h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#"> {this.state.page_name} </Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                type="button"
                color="secondary"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
        <ToastContainer />
        <Loader loaded={this.state.loading}></Loader>
        {this.state.warningAlert ? (
          <SweetAlert
            title="Please fill all fields"
            warning
            onConfirm={() => {
              this.setState({
                warningAlert: false
              })
            }}
          />
        ) : null}

        <Row>
          <Col className="col-12">
            <Card>
             
              <AvForm
                onSubmit={this.handleSubmit}
                ref={(c) => (this.form = c)}
                className="needs-validation"
              >
              <Row>
                <Col md={6}>
                    <ModalBody className="row">
                        <div className="col-md-12 mb-3">
                            <AvField
                                name="name"
                                value={this.state.record.name}
                                label="Name"
                                className="form-control"
                                required
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter style={{float:"left"}}>
                        <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                        >
                            Submit
                        </button>
                    </ModalFooter>
                </Col>
                <Col md={6}></Col>
              </Row>
              </AvForm>
            </Card>
          </Col>
        </Row>
        
      </React.Fragment >
    );
  }
}
