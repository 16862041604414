import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import { ApiUrl, ProductName } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import CampaignNav from "../nav";
import { dcrypt, bcrypt, uToken } from "../../../useToken";
import CampaignReportingNav from "./nav";
import { AvField, AvForm } from "availity-reactstrap-validation";

export default class CampaigLogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id
                ? dcrypt(this.props.match.params.id)
                : null,
            page_name: "Campaign Logs",
            filter: {},
            record: {},
            showModal: false,
            records: [],
            total_pages: 0,
            series: [],
            master_type: '0'
        };
        this.columns = [
            {
                text: "Sr#",
                key: "id",
            },
            {
                text: "Type",
                key: "type",
            },
            {
                text: "Activity",
                key: "operation",
            },
            {
                text: "Created At",
                key: "created_at",
            },
        ]
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            show_filter: false,
            show_pagination: true,
            button: {
                excel: false,
                print: true,
                csv: true,
            },
        };
    }


    componentDidMount() {
        this.getData();
    }


    getData = (queryString = "", data) => {
        fetch(`${ApiUrl}` + `campaign/activity/log/${this.state.id}` + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        total_pages: response.data.total,
                        records: response.data.records,
                    });
                } else {
                     toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    handleFilterSubmit = async (event, values) => {
        this.setState({
            filter: values,
        });
        this.getData("", values);
    };

    cancelFilter = async () => {
        this.form && this.form.reset();
        this.setState({ filter: {} });
        this.getData("", "");
    };


    tableChangeHandler = (data) => {
        let queryString = Object.keys(data)
            .map((key) => {
                if (key === "sort_order" && data[key]) {
                    return (
                        encodeURIComponent("sort_order") +
                        "=" +
                        encodeURIComponent(data[key].order) +
                        "&" +
                        encodeURIComponent("sort_column") +
                        "=" +
                        encodeURIComponent(data[key].column)
                    );
                } else {
                    return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
                }
            })
            .join("&");
        this.getData("?" + queryString, this.state.filter);
    };


    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.state.page_name}
                        Tables
                    </title>
                </Helmet>

                <Row>
                    <Col sm={5}>
                        <div className="page-title-box">
                            <h4>{this.state.page_name} Lists</h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#">{this.state.page_name} Lists</Link>
                                </li>
                            </ol>
                        </div>
                    </Col>
                </Row>

                <ToastContainer />

                <CampaignNav activeTab={'9'} campaignId={bcrypt(this.state.id)} />
                <Row>
                    <Col className="col-12">
                        <Card className="mb-0">
                            <CampaignReportingNav activeTab={'8'} campaignId={bcrypt(this.state.id)} />
                            <CardBody className="py-0">
                                <AvForm
                                    onValidSubmit={this.handleFilterSubmit}
                                    ref={(c) => (this.form = c)}
                                    className="needs-validation"
                                >
                                    <Row>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <AvField
                                                    className="select form-control"
                                                    type="select"
                                                    name="type"
                                                    label="Select Type"
                                                >
                                                    <option value="0"> All </option>
                                                    <option value="Automation">Automation</option>
                                                    <option value="Batch">Batch</option>
                                                    <option value="notify">Notify</option>
                                                    
                                                </AvField>
                                            </div>
                                        </Col>
                                     
                                   
                                        <Col md="2">
                                            <div className="mb-3">
                                                <AvField
                                                    name="from_date"
                                                    label="From Date"
                                                    className="form-control"
                                                    placeholder="Enter Email Address"
                                                    type="date"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <AvField
                                                    name="to_date"
                                                    label="To date"
                                                    className="form-control"
                                                    type="date"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <label className="visibilty-hidden"> {"Action"}</label>
                                                <div className="button-items">

                                                    <Button className="btn-sm" color="primary" type="submit">
                                                        Filter
                                                    </Button>
                                                    <Button
                                                        onClick={() => this.cancelFilter()}
                                                        outline
                                                        color="secondary"
                                                        className="waves-effect btn-sm"
                                                        type="button"
                                                    >
                                                        Reset
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody className="pt-0">
                                {/* <CardTitle className="h4">Default Datatable </CardTitle> */}
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                    dynamic={true}
                                    total_record={this.state.total_pages}
                                    onChange={this.tableChangeHandler}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment >
        );
    }
}
