import React, { Component, createRef } from "react";
import {
    Row, Col, Card, CardBody, Button, Label, Table, CardTitle,
    Nav, NavItem, NavLink, TabContent, TabPane, CardText
} from "reactstrap";

import { ApiUrl } from "../../config";
import { dcrypt, uToken } from "../../useToken";
import { toast, ToastContainer, Flip } from "react-toastify";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import { AvField } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";


// require("formRender");
export default class FormBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id ? dcrypt(this.props.match.params.id) : null,
            records: [],
            columns: [],
            mapping: {}
        }

        this.handleSubmit = this.handleSubmit.bind(this)
    }
    componentDidMount() {
        this.getData();
    }

    getData = () => {
        fetch(`${ApiUrl}` + "get/edit/contract", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: this.state.id }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    var tokens_group = [
                        ...response.data.fields.default_fields,
                        ...response.data.fields.custom_fields
                    ];
                    this.setState({
                        records: response.data.record.metadata != '' ? JSON.parse(response.data.record.metadata) : [],
                        columns: tokens_group,
                        mapping: response.data.record.mapped_data != '' ? JSON.parse(response.data.record.mapped_data) : {},
                    })
                } else {
                              
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });

    }

    handleSubmit = async (event, values) => {
        values.id = this.state.id;
        fetch(`${ApiUrl}` + "update/contract/from/mapping", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                if (data.status === true) {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    render() {
        let label = '';
        return (
            <>
                <CardTitle className="h4">MAP YOUR DATA</CardTitle>
                <div className="table-responsive">
                    <AvForm onValidSubmit={this.handleSubmit} className="needs-validation">
                        <Table className="table table-light mb-0">
                            <thead>
                                <tr>
                                    <th>Label</th>
                                    <th>Index</th>
                                    <th>System Map Columns</th>
                                </tr>
                                {
                                    this.state.records.map((row, index) => {
                                        label = row.label.replace(/(<([^>]+)>)/gi, "");
                                        return (
                                            <tr>
                                                <td>{label.length > 30 ? label.substring(0, 30) + '...' : label}</td>
                                                <td>{row.name}</td>
                                                <td>
                                                    <AvField
                                                        type="select"
                                                        name={`fields[${row.name}]`}
                                                        value={this.state.mapping[row.name]}
                                                        at={row.label}
                                                        a={index}
                                                    >
                                                        <option value="">Map Column</option>
                                                        {this.state.columns.map((column, c) => {
                                                            return (
                                                                <option a={column.key}
                                                                    value={`${column.key}`}>{column.label}</option>
                                                            )
                                                        })}

                                                    </AvField>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </thead>
                            {/* <AvField type="hidden" name="csv_data_file_id" value={this.state.csv_data_file_id} required /> */}

                            <div className="button-items">
                                <Link
                                    to={'/contract/list'}
                                    className="btn btn-ld my-3 btn-secondary">
                                    Back
                                </Link>
                                <Button
                                    className="btn btn-ld my-3" color="primary" type="submit">
                                    Map Data
                                </Button>

                            </div>
                        </Table>
                    </AvForm>
                </div>
            </>
        )
    }
}

