import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button
} from "reactstrap";

import Helmet from "react-helmet";
// availity-reactstrap-validation
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation";

import ProfileSettings from "./profile-sidebar";
import { isValidJSONString, uInfo, uToken } from "../../useToken";
import { ApiUrl, AttachementsUrl, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Accordion } from "react-bootstrap";
import swal from 'sweetalert';

const UserProfile = (props) => {
  const [user, setUser] = useState("");
  const [json, setJson] = useState({});

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setUser(obj);
    }
  }, [!user]);

  useEffect(() => {
    getData();
  }, []);

  function generateApiKey(event, values) {
    fetch(`${ApiUrl}` + "update/custom/apikey", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          let newtoken = {
            api_key: data.data.api_key,
          };
          const authUser = {
            ...JSON.parse(localStorage.getItem("authUser")),
            ...newtoken,
          };
          localStorage.setItem("authUser", JSON.stringify(authUser));

          setUser(authUser);
          //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          swal({
            text: 'Action performed successfully',
            icon: "success",
          });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const getData = () => {
    fetch(`${ApiUrl}` + "custom/api/json", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setJson(response.json);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  const data = { name: 'John', age: 42 };

  return (
    <React.Fragment>
      <Row>
        <Col lg="2" className="pl-0">
          <ProfileSettings menuOpen={false} props={props} />
        </Col>
        <Col lg="10">

          <ToastContainer />
          <Row>
            <Col className="col-lg-12">
              <Card>
                <CardBody className="api-custom">


                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => {
                      generateApiKey(e, v);
                    }}
                  >
                    <div className="row">
                      <div className="col-md-4 form-group mb-3">
                        <AvField
                          name="api_uId"
                          label="Api Id"
                          className="form-control"
                          required
                          readOnly
                          value={user.user_id}
                        />
                      </div>

                      <div className="col-md-4 form-group mb-3">
                        <AvField
                          name="api_key"
                          label="Api Key"
                          className="form-control"
                          type="text"
                          required
                          value={user.api_key}
                          readOnly
                        />
                      </div>
                      <div className="mt-4 col-md-4">
                        <Button type="submit" color="danger">
                          Re-Generate
                        </Button>
                      </div>
                    </div>

                  </AvForm>




                  <div className="text-align-right" >
                    <a target="_blank" href={`${AttachementsUrl}/default/custom_api/sample.txt`} download  className="btn btn-link">View Sample File</a>
                  </div>
                  <Accordion defaultActiveKey={0} className="border-0">

                    {
                      Object.keys(json).map(function (key, i) {
                        return (
                          <Accordion.Item eventKey={`${i}`}>
                            <Accordion.Header> <button className="btn btn-md btn-success mr-3">{json[key].action}</button> {json[key].name} </Accordion.Header>
                            <Accordion.Body>
                              <div className="api-info">
                                <div className="panel-body">
                                  <div className="paremtr mt-2"><b>Method Name:</b> {json[key].title}</div>
                                  <div className="paremtr mt-2"><b>Post information to URL:</b> https://ws.profitcoach.app/api/request</div>
                                  <div className="paremtr mt-2"><b>Parameters:</b></div>
                                  <div className="table_container">
                                    <div className="parameters">
                                      <div className="d-flex mt-2">
                                        <p className="parameters-col_name pr-3"><b>Name</b></p>
                                        <p className="parameters-col_description pr-3"><b>Description</b></p>
                                      </div>
                                      <div>
                                        {
                                          Object.entries(json[key].parameters).map((param, k) => {
                                            return <div className="d-flex">
                                              <p className="pr-3">{param[0]}*</p>
                                              <p className="pr-3">{param[1]}</p>
                                            </div>
                                          })
                                        }
                                        <div className="parameter_value">Example Request:</div>

                                        <td className="col parameters-col_description">
                                          <div className="body-param">
                                            <div className="highlight-code">
                                              <pre>
                                                <p dangerouslySetInnerHTML={{ __html: '{' }} />

                                                {
                                                  Object.entries(json[key].request).map((req, r) => {
                                                    return <p className="pl-3 m-0"> "{req[0]}" :
                                                      <span className="">
                                                        {
                                                          req[0] == 'api_id' ?
                                                            `"${user.user_id}"` + ','
                                                            : req[0] == 'api_key' ?
                                                              `"${user.api_key}"` + ',' :
                                                              isValidJSONString(req[1]) ?
                                                                `${JSON.stringify(req[1], null, 2)}` + ','
                                                                :
                                                                `"${req[1]}"` + 's,'
                                                        }

                                                      </span>
                                                    </p>
                                                  })
                                                }
                                                <p dangerouslySetInnerHTML={{ __html: '}' }} />

                                              </pre>
                                            </div>
                                            <div className="body-param-options">
                                              <label>Request Parameter content type
                                                <select className="form-control">
                                                  <option value="application/json">application/json</option>
                                                </select>
                                              </label>
                                            </div>
                                            <div className="parameter_value">Example Response:</div>
                                            <div className="body-param">
                                              <div className="highlight-code">
                                                <pre>{JSON.stringify(json[key].response, null, 2)}</pre>
                                              </div>
                                              <div className="body-param-options">
                                                <label>Response Parameter content type
                                                  <select className="form-control">
                                                    <option value="application/json">application/json</option>
                                                  </select>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        )
                      })
                    }

                  </Accordion>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserProfile;
