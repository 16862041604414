import React from "react";
import { withRouter } from "react-router-dom";

//i18n
import ResourcesSideBarContent from "./resources-sidebar-nav";

// import SidebarContent from "./profile-settings-superadmin";
const Sidebar = (props) => {
// console.log(props.props.role.id,'data here');

  return (
    <React.Fragment>
      <div className="vertical-menu" >
        <div data-simplebar className="h-100">
        
         <ResourcesSideBarContent /> 
        
          
         
        </div>
      </div>
    </React.Fragment>
  );
  
};

export default Sidebar;
