import React, { Component, Fragment, useState } from "react"
import { Link } from "react-router-dom";
import { ApiUrl } from "../../config";
import Context from './Context';
import swal from "sweetalert";
const Box1 = (props) => {
    const params = React.useContext(Context);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        fetch(`${ApiUrl}` + "update/builder/setting/" + params.user_id, {
            method: "POST",
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    params.goToBox(params.currentBox + 1);
                    params.getBuilderSetting();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }


    return (
        <div>
            <div className="box-center-align mt-3">
                <div className="SmallContaner my-4">
                <div className="d-flex align-items-center gap-2 StepHeading">
                        <div className="StepNumber">4 <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">Copy/Text</h4>
                    </div>
                    <form method="post" onSubmit={handleSubmit}
                        encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                        <div className="border-top border-bottom border-left border-right p-4 mt-3">
                            <div className="page-content-box">
                                <div className="TitleWizred d-flex align-items-center gap-2">
                                    <span className="fs-5"> a.</span> <h5>Provide answers to the following questions so your website can have unique and individual content.</h5>
                                </div>
                                <div className="WizredForm">
                                    
                                    <div className="form-group mt-4">
                                        <label>What is your Headline?
                                            <br />
                                            <small>Example: "I'll find you $50,000 in your business today without spending an additional dollar on marketing or advertising."</small>
                                        </label>
                                        <input required  name="headline" defaultValue={params.setting.headline ?? ''} className="form-control no-border px-0" type="text" placeholder="Type your answer here..."></input>
                                        {/* <note className="text-info">Max character limit: 70</note> */}
                                    </div>
                                    <div className="form-group mt-4">
                                        <label>What is your sub-headline?
                                            <br />
                                            <small>Ex. "Learn how I will triple your current number of leads, double your sales, and increase your annual revenue by at least $50,000 or more...in just a single 45 minute presentation, guaranteed!"

                                            </small>
                                        </label>
                                        <textarea rows={10} required name="sub_headline" defaultValue={params.setting.sub_headline ?? ''} className="form-control no-border px-0" type="text" placeholder="Type your answer here..."></textarea>
                                        {/* <note className="text-info">Max character limit: 350</note> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-lg btn-primary my-4"> Next &nbsp;<i className="fas fa-check"></i></button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Box1;