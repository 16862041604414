import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap";
import Helmet from "react-helmet";
import {
    bcrypt,
    dcrypt,
    uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";


import CampaignNav from "../nav";
import { Table } from "react-bootstrap";

export default class Drips extends Component {
    constructor(props) {
        super(props);
        this.state = {
            camapign_id: this.props.match.params.id
                ? dcrypt(this.props.match.params.id)
                : null,
            records: { group_ids: [] },
            page_name: "Broadcasts",
            tab: "7",
        };
    }



    componentDidMount() {
        this.getData(this.state.camapign_id, "");
    }


    getData = () => {
        fetch(`${ApiUrl}` + "get/edit/campaign", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: this.state.camapign_id }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    console.log(response, "debugging");
                    this.setState({
                        records: response.data.record,
                    });
                } else {
                     toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };



    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col sm={12}>
                        <div className="page-title-box">
                            <h4> {this.state.page_name} Lists</h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#">{this.state.page_name} Lists</Link>
                                </li>
                            </ol>
                        </div>
                    </Col>

                </Row>

                <CampaignNav activeTab={'4'} campaignId={bcrypt(this.state.camapign_id)} />
                <ToastContainer />
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody className="drips">
                                <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <td>Group Name</td>
                                            <td>Action</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.records.group_ids.map((row, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{row.title}</td>
                                                        <td>
                                                            <Link to={`/campaign/broadcasts/flow/${bcrypt(this.state.camapign_id)}/${bcrypt(row.group_id)}`} >
                                                                <i className="fas fa-plus" />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
