import React, { useEffect, useState } from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Label,
    Table,
    CardTitle,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    CardText,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    CardHeader,
    Container
} from "reactstrap"
import {
    AvForm,
    AvField,
    AvGroup,
    AvFeedback,
    AvInput,
} from "availity-reactstrap-validation";
import Select from "react-select";

import Header from "./header"
import ClientCreateUpdate from "../../Coaches/Clients/ClientCreateUpdate";
import CreateTask from "../../Tasks/create.js";

import { toast, ToastContainer, Flip } from "react-toastify";
import { ApiUrl } from "../../../config"
import { bcrypt, convertPhoneFormat, dcrypt, isObjectEmpty, uInfo, uToken } from "../../../useToken"

import { Dropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Phone from "../../InitiateCall/Phone";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CampaignDialer = props => {
    let query = useQuery();
    const getAlert = () => {
    }
    const [info, setInfo] = useState({});
    const [campaignInfo, setCampaignInfo] = useState({});
    const [DynamicModal, setDynamicModal] = useState(false);
    const [DynamicModalName, setDynamicModalName] = useState("");
    const [templates, setSmsTemplates] = useState([]);
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [emailSetting, setEmailSetting] = useState([]);
    const [tokens, setTokens] = useState([]);
    const [smsBody, setSmsBody] = useState("");
    const [token, setToken] = useState("");
    const [subject, setSubject] = useState("");
    const [html, setHtml] = useState("");
    const [EmailModal, setEmailModal] = useState(false);
    const [pipeline, setPipeline] = useState({});
    const [pipelineStatus, setPipelineStatus] = useState([]);
    const [masterPipelines, setMasterPipelines] = useState([]);
    const [masterPipelineId, setMasterPipelineId] = useState(0);
    const [selectedPipeline, setSelectedPipeline] = useState({});
    const [pipelineModal, setPipelineModal] = useState(false);
    const [coachPartners, setCoachPartners] = useState([]);
    const [type, setType] = useState("");
    const [page3Class, setpage3Class] = useState("");
    const [page4Class, setpage4Class] = useState("");
    const [page5Class, setpage5Class] = useState("");
    const [activity, setActivity] = useState([]);
    const [alltags, setAllTags] = useState([]);
    const [tag, setTag] = useState("");
    const [theArray, setTheArray] = useState([]);
    const [callToken, setCallToken] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [groupsName, setGroupName] = useState("");
    const [callStatus, setCallStatus] = useState([]);
    const [CallSid, setCallSid] = useState("");
    const [nextClientId, setNextClientId] = useState(0);
    const [prevClientId, setPrevClientId] = useState(0);
    const [notes, setNotes] = useState([]);
    const [sendNotes, setSendNote] = useState(false);
    const [noteType, setNoteType] = useState('manual');

    const [template_id, setTemplateId] = useState(0);


    const paramsters = {
        token: callToken,
        from_number: campaignInfo.from_number ? campaignInfo.from_number : uInfo().outbound_phone,
        phoneno: info.phone
            ? info.country_code + info.phone
            : info.country_code + info.mobile,

        phone: info.phone != '' ? info.country_code + '' + info.phone : '',
        mobile: info.mobile != '' ? info.country_code + '' + info.mobile : '',

        record: "false",
        client_id: info.client_id,
        coach_id: info.coach_id,
        campaign_id: campaignInfo.campaign_id
    };
    //console.log(paramsters, 'paramsters');

    const getCallSid = async (call_sid) => {
        setCallSid(call_sid);
    };

    const handleEmailSubmit = async (event, values) => {
        values.client_id = info.client_id;
        values.campaign_id = campaignInfo.campaign_id;
        values.type = DynamicModalName.toLowerCase();

        if (html != "") {
            let body = document.getElementById("custom-template").innerHTML;
            values.body = body;
        }

        fetch(`${ApiUrl}` + "company/email/activity", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                console.log(data, "Editing");
                if (data.status === true) {
                    setEmailModal(false);
                    setDynamicModalName("");
                    setSmsBody("");
                    setToken("");
                    setSubject("");
                    setHtml("");
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleSubmit = async (event, values) => {
        values.client_id = info.client_id;
        values.type = DynamicModalName.toLowerCase();
        values.campaign_id = campaignInfo.campaign_id;
        fetch(`${ApiUrl}` + "lead/activity", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                console.log(data, "Editing");
                if (data.status === true) {
                    setDynamicModal(false);
                    setDynamicModalName("");
                    setSmsBody("");
                    setToken("");
                    setSubject("");
                    setHtml("");
                    setTemplateId(0);
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    const getSmsTemplate = () => {
        fetch(`${ApiUrl}` + "get/sms/template", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    var templates = groupArrayOfObjects(response.data.templates, "type");
                    var templates_group = [
                        { label: "SMS", options: templates.SMS },
                        { label: "MMS", options: templates.MMS },
                    ];
                    setSmsTemplates(templates_group);

                    var tokens_group = [
                        {
                            label: "Default Field",
                            options: response.data.tokens.default_fields,
                        },
                        {
                            label: "Custom Field",
                            options: response.data.tokens.custom_fields,
                        },
                    ];

                    setTokens(tokens_group);
                    setPhoneNumbers(response.data.phoneNumbers);
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const getEmailTemplateHtml = (id) => {
        fetch(`${ApiUrl}` + "get/html/" + id + "/" + uInfo().user_id, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                setHtml(response.theme);
                // localStorage.setItem("theme", response.theme);
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const getEmailTemplate = () => {
        fetch(`${ApiUrl}` + "get/email/template", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    setSmsTemplates(response.data.templates);
                    var tokens_group = [
                        {
                            label: "Default Field",
                            options: response.data.tokens.default_fields,
                        },
                        {
                            label: "Custom Field",
                            options: response.data.tokens.custom_fields,
                        },
                    ];

                    setTokens(tokens_group);
                    setEmailSetting(response.data.phoneNumbers);
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    function groupArrayOfObjects(list, key) {
        return list.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    }

    const handleDealSubmit = async (pipeline_id, status_id, client_id, final_pipeline, campaign_id) => {
        fetch(`${ApiUrl}` + "assinged_pipeline", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({
                'pipeline_id': pipeline_id,
                'status_id': status_id,
                'client_id': client_id,
                'final_pipeline': final_pipeline,
                'campaign_id': campaign_id
            }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
                if (data.status === true) {
                    getClientPipeline(client_id, info.coach_id, masterPipelineId);
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                } else {
                    toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const getCompanyClients = (client_id, coach_id) => {
        fetch(`${ApiUrl}` + "get/client/information/" + client_id + "/" + coach_id, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    setCoachPartners(response.data.coachPartners);

                    var tokens_group = [
                        {
                            label: "Default Field",
                            options: response.data.tokens.default_fields,
                        },
                        {
                            label: "Custom Field",
                            options: response.data.tokens.custom_fields,
                        },
                    ];
                    setTokens(tokens_group);
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const getClientPipeline = (client_id, coach_id, master_id = 0) => {
        fetch(`${ApiUrl}` + "get/client/pipeline/" + client_id + "/" + coach_id, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ master_id: master_id }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    setMasterPipelineId(master_id);
                    setPipelineStatus(response.data.allPipelines);
                    setMasterPipelines(response.data.masterPipelines);
                    // if (response.data.allPipelines.length > 0) {
                    //   setPipeline(response.data.allPipelines[0]);
                    // }
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const getCompaignOutboundSetting = () => {

        if (query.get("runId") && query.get("dialer_type")) {
            let go = query.has("go") ? '?go=' + query.get("go") : '';
            let url = "get/camapign/outbound/setting/" + dcrypt(query.get("runId")) + '/' + query.get("dialer_type") + go;
            if (query.get("client")) {
                url = "get/camapign/outbound/setting/" + dcrypt(query.get("runId")) + '/' + query.get("dialer_type") + '?client_id=' + query.get("client");
            }
            fetch(`${ApiUrl}` + url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + uToken(),
                },
            })
                .then((response) => response.json())
                //Then with the data from the response in JSON...
                .then((response) => {
                    if (response.status === true) {
                        let records = response.data;
                        if (isObjectEmpty(records.client_rec)) {
                            if (query.get("dialer_type") == 'quick-dialer') {
                                if (query.get("from") == 'lead') {
                                    props.history.push({
                                        pathname: '/lead/list',
                                        state: { msg: records.msg, campaign_id: records.campaign_id }
                                    });
                                } else {
                                    props.history.push({
                                        pathname: '/lead/list',
                                        state: { msg: records.msg, campaign_id: records.campaign_id }
                                    });
                                }

                            } else {
                                props.history.push({
                                    pathname: '/campaign/list',
                                    state: { msg: records.msg, campaign_id: records.campaign_id }
                                });
                            }

                        }


                        setInfo(records.client_rec);
                        setLoaded(true);
                        setCallStatus(records.call_status);
                        setCampaignInfo(records.campaign);
                        setNextClientId(records.next_client_id);
                        setPrevClientId(records.prev_client_id);


                        if (!isObjectEmpty(records.client_rec)) {
                            setTheArray(records.client_rec.tag_id.split(',').map(function (strVale) { return Number(strVale) }));
                        }
                        setGroupName(records.group_name);
                        if (!isObjectEmpty(records.client_rec)) {
                            getCompanyClients(records.client_rec.client_id, records.client_rec.coach_id);
                            getClientPipeline(records.client_rec.client_id, records.client_rec.coach_id);

                        }

                        handleOutCall();

                    } else {
                        setLoaded(true);
                        toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                    }
                })
                //Then with the error genereted...
                .catch((error) => {
                    setLoaded(true);
                    console.error("Error:", error);
                });
        }
    };

    const handleOutCall = () => {
        fetch(`${ApiUrl}` + "voice/token", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    setCallToken(response.data.token);
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    useEffect(() => {
        if (query.get("runId")) {
            getTag();
            getCompaignOutboundSetting();
        }
    }, []);

    const cancelTask = () => {
        setpage4Class("slide-out2");
    };


    const getActivity = (index) => {
        let type = '';
        if (index == 5) {
            type = '&note_type=manual';
        }
        fetch(`${ApiUrl}` + "get/activity/" + info.client_id + "/" + index + '?campaign_id=' + campaignInfo.campaign_id + type, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    setActivity(response.data.records);
                    setpage3Class("slide-in");

                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const getTag = () => {
        fetch(`${ApiUrl}` + "get/group/tag", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },

            body: JSON.stringify({ manager_id: info.coach_id }),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                // console.log(response, "data");
                if (response.status === true) {
                    setAllTags(response.data.tags);
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };


    const AddTag = async (event) => {

        event.preventDefault();

        if (theArray.length > 0) {
            let tag_id = theArray.toString();

            fetch(`${ApiUrl}` + "add/tags/lead/" + info.client_id + "/" + info.coach_id, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + uToken(),
                },
                body: JSON.stringify({ 'tag_id': tag_id, 'dialer': 'campaign' }),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data, "Editing");
                    if (data.status === true) {
                        toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                    } else {
                        toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                    }
                })
                //Then with the error genereted...
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    };

    const AddCallStatus = async (call_status) => {
        if (call_status != '') {
            let values = {};
            values.client_id = info.client_id;
            values.call_status = call_status;
            values.campaign_id = campaignInfo.campaign_id;
            values.call_sid = CallSid;
            fetch(`${ApiUrl}` + "add/call/status", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ` + uToken(),
                },
                body: JSON.stringify(values),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data, "Editing");
                    if (data.status === true) {
                        toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
                        window.location.reload();
                    } else {
                        toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                    }
                })
                //Then with the error genereted...
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    };

    const handleChange = (selectedTags) => {
        let tags = [];
        selectedTags.map(tag => {
            tags.push(tag.group_id);
        })
        setTheArray(tags);
    }

    const getNotes = (data = {}, from = '') => {
        fetch(`${ApiUrl}` + "get/activity/" + info.client_id + "/5", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    setNotes(response.data.records)

                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    return (
        <React.Fragment>
            <div className="comapign-dialer" >

                <ToastContainer />
                <div className="top_bar bg-gray">
                    <Container fluid>
                        {
                            !isObjectEmpty(campaignInfo) && (
                                <Header prevClientId={prevClientId} nextClientId={nextClientId} type={query.get('dialer_type')} campaign={campaignInfo} />
                            )
                        }
                    </Container>
                </div>
                <div className="nav_tab">
                    <Container fluid>

                        <div className="row">
                            <div className="col-md-5">

                                <div className="voice_option_inner">
                                    {
                                        (info.phone != '' || info.mobile != '') && (
                                            <button
                                                onClick={() => {
                                                    setDynamicModal(true);
                                                    setDynamicModalName("sms");
                                                    getSmsTemplate();
                                                    setToken('');
                                                }}
                                                className="btn btn-sm m-2"
                                            >
                                                SMS
                                            </button>
                                        )}

                                    {
                                        info.email != '' && info.email != null && info.email_status == 1 && (
                                            <button
                                                className="btn btn-sm m-2"
                                                onClick={() => {
                                                    setEmailModal(true);
                                                    setDynamicModalName("email");
                                                    getEmailTemplate();
                                                    setToken('');
                                                    setHtml('');
                                                }}
                                            >
                                                Email
                                            </button>
                                        )
                                    }


                                    <button
                                        className="btn btn-sm m-2"
                                        onClick={() => {
                                            getNotes({ 'note_type': 'manual' }, 'notes');
                                            setDynamicModal(true);
                                            setDynamicModalName("Note");
                                        }}
                                    >
                                        Add/View notes
                                    </button>

                                </div>

                            </div>

                            <div className="col-md-7">

                                <div className="row">
                                    <div className="col-md-5 calling">

                                        <div className="voice_option_inner">

                                            <button
                                                className="btn btn-sm btn-white m-2"
                                                title="Deal Status"
                                                onClick={() => {
                                                    setpage5Class("slide-in");
                                                }}
                                            >
                                                <i className="fa fa-thumbs-up"></i>
                                            </button>

                                            {/* <button
                                                className="btn btn-sm btn-white m-2"
                                                title="Add Task"
                                                onClick={() => {
                                                    setpage4Class("slide-in");
                                                }}
                                            >
                                                <i className="fa fa-tasks"></i>
                                            </button> */}
                                        </div>

                                    </div>
                                    <div className="col-md-7">

                                    </div>
                                </div>
                            </div>
                        </div >
                    </Container>
                </div>

                <Container fluid>
                    <div className="section-dialer pt-3">
                        <div className="row">
                            <div className="col-md-3 pr-0" style={{ paddingLeft: '45px' }}>
                                <Card>
                                    <CardBody className="dailer-pad">
                                        <form noValidate="" action="#" method="get" className="needs-validation av-valid">
                                            <div className="call">

                                                <div className="col-md-12">
                                                    <div className="user_info">
                                                        <strong>Group(s) : </strong>
                                                        <span>{groupsName}</span>
                                                    </div>



                                                </div>

                                                {
                                                    !isObjectEmpty(info) && (

                                                        <div className="compant-action-btn">
                                                            {callToken ? (
                                                                <Phone paramsters={paramsters} getCallSid={getCallSid} />
                                                            ) : (
                                                                <p> Loading... </p>
                                                            )}

                                                        </div>
                                                    )
                                                }

                                            </div>
                                        </form>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-md-6">
                                <div className="client-form">
                                    {
                                        !isObjectEmpty(info) && (
                                            <>
                                                <ClientCreateUpdate
                                                    id={info.client_id}
                                                    coach_id={info.coach_id}
                                                    getData={getAlert}
                                                    from="companyDetails"
                                                />
                                            </>
                                        )
                                    }
                                </div>

                            </div>
                            <div className="col-md-3">
                                <Card>
                                    <CardBody>
                                        <h3>Assign Tag</h3>
                                        <form encType="multipart/form-data" className="form-horizontal" method="POST" onSubmit={AddTag}>

                                            {
                                                !isObjectEmpty(info) && (
                                                    <>
                                                        <div className="mb-3 mt-1">
                                                            <Select
                                                                getOptionLabel={(option) => option.title}
                                                                getOptionValue={(option) => option.group_id}
                                                                value={alltags.filter(obj => theArray.includes(obj.group_id))}
                                                                options={alltags}
                                                                isMulti={true}
                                                                classNamePrefix="select2-selection"
                                                                name="tags_id"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 offset-3">
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-info btn-sm width-100-percent"
                                                                >
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }

                                        </form>
                                    </CardBody>
                                </Card>

                                <Card>
                                    <CardBody>
                                        <h3>Call Status</h3>
                                        <select className="form-control" onChange={(e) => AddCallStatus(e.target.value)}>
                                            <option value={''} >-----Select Call Status-----</option>
                                            {
                                                callStatus.map((status, i) => {
                                                    return <option value={status.status_id}>{status.title}</option>
                                                })
                                            }
                                        </select>
                                    </CardBody>
                                </Card>
                            </div>

                        </div>
                    </div>
                    <div className="page-content dialer campaign-dialer">
                        {page5Class == "slide-in" && (
                            <div className={`page3 ${page5Class} custom-scroll deal-assign`}>
                                <div>
                                    <div className="modal-head top-heading">
                                        <h2> Pipeline Status </h2>
                                        <button
                                            onClick={() => {
                                                setpage5Class("slide-out2");
                                                setPipelineModal(false);
                                            }}
                                            className="btn btn-link text-white"
                                        >
                                            <i className="fas fa-times"> </i>
                                        </button>
                                    </div>
                                    <Card>
                                        <CardBody>
                                            <div className="MonthlyDropDown mb-1">
                                                <select defaultValue={masterPipelineId} onChange={(e) => { getClientPipeline(info.client_id, info.coach_id, e.target.value) }}
                                                    className="form-control w-100">
                                                    {masterPipelines.map((row, i) => {
                                                        return (
                                                            <option key={i} value={row.pipeline_id}>{row.label}</option>
                                                        )
                                                    }
                                                    )}
                                                </select>
                                            </div>
                                            {pipelineStatus.map((pstatus, i) => {
                                                return (
                                                    <div className="DealCards mb-2 border-1" key={i + '--' + pstatus.pipeline_id}>
                                                        <div key={i} className={`form-check form-check-right d-flex justify-content-between align-items-center ${pstatus.is_complete == 1 ? 'complete' : 'pending'}`}>
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="customCheck2"
                                                            >
                                                                {pstatus.name} {pstatus.final_pipeline == 1 && (`- ${pstatus.current_status}`)}
                                                            </label>
                                                            {
                                                                pstatus.final_pipeline == 0 && (
                                                                    <input
                                                                        key={i}
                                                                        type="checkbox"
                                                                        title="Mark as complete"
                                                                        className="form-check-input"
                                                                        // disabled={pstatus.is_complete == 1 ? true : false}
                                                                        defaultChecked={pstatus.is_complete == 1 ? true : false}
                                                                        onChange={(e) => (handleDealSubmit(pstatus.pipeline_id, 0, info.client_id, pstatus.final_pipeline, campaignInfo.campaign_id))}
                                                                    />
                                                                )
                                                            }

                                                        </div>
                                                        <ul className="mb-0 py-2">
                                                            {pstatus.pipeline_status.map((row, i) => {
                                                                return (
                                                                    <li
                                                                        disabled={pstatus.is_complete == 1 && pstatus.final_pipeline == 0 ? true : false}
                                                                        onClick={() => {
                                                                            handleDealSubmit(row.pipeline_id, row.status_id, info.client_id, pstatus.final_pipeline, campaignInfo.campaign_id);
                                                                        }}
                                                                        key={"p_status-" + i}
                                                                    >
                                                                        <div className=""
                                                                            style={{
                                                                                backgroundColor: pstatus.is_complete == 1 ? 'transparent' : row.color_code,
                                                                                padding: '3px',
                                                                                display: 'inline-block',
                                                                                width: '90%'
                                                                            }}>
                                                                            <p className={pstatus.is_complete != 1 && (row.color_code == '#fff' ? 'text-dark' : 'text-white')}>{row.title}</p>
                                                                        </div>
                                                                        {/* {row.is_complete == 1 && (<i className="fas fa-check code-green"></i>)} */}
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>
                                                );
                                            })}
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        )}

                        {page4Class == "slide-in" && (
                            <div className={`page3 ${page4Class} custom-scroll`}>
                                <div>
                                    <div className="modal-head top-heading">
                                        <h2> Add Task </h2>
                                        <button
                                            onClick={() => {
                                                setpage4Class("slide-out2");
                                            }}
                                            className="btn btn-link text-white"
                                        >
                                            <i className="fas fa-times"> </i>
                                        </button>
                                    </div>
                                    <CreateTask
                                        cancelSlider={cancelTask}
                                        compannyId={info.client_id}
                                        from="companyDetails"
                                    />
                                </div>
                            </div>
                        )}

                        {page3Class == "slide-in" && (
                            <div className={`page2 ${page3Class} custom-scroll invoice-details`}>
                                <div>
                                    <div className="modal-head top-heading">
                                        <h2> {type} Logs </h2>
                                        <button
                                            onClick={() => {
                                                setpage3Class("slide-out2");
                                            }}
                                            className="btn btn-link text-white"
                                        >
                                            <i className="fas fa-times"> </i>
                                        </button>
                                    </div>
                                    <Card>
                                        <CardBody>
                                            <div className="table-responsive">
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th> From </th>
                                                            <th> To </th>
                                                            <th> Direction </th>
                                                            <th> Type </th>
                                                            <th> Date </th>
                                                            <th> Details </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            activity.map((row, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td> {row.froms} </td>
                                                                        <td> {row.tos} </td>
                                                                        <td> {row.direction} </td>
                                                                        <td> {row.type} </td>
                                                                        <td> {row.created_at} </td>
                                                                        <td>

                                                                            <Link target={'_blank'} to={`/campaign/report/${type}/${bcrypt(campaignInfo.campaign_id)}?show=${bcrypt(row.report_id)}`}>
                                                                                Details
                                                                            </Link>
                                                                        </td>

                                                                    </tr>
                                                                )
                                                            })
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        )}
                    </div>
                    <Modal
                        isOpen={DynamicModal}
                        fade={true}
                        size="md"
                        toggle={() => {
                            setDynamicModal(false);
                            setSmsBody("");
                            setToken("");
                            setSubject("");
                            setHtml("");
                            setTemplateId(0);
                        }}
                    >
                        <AvForm
                            onValidSubmit={handleSubmit}
                            // model={this.state.record}
                            className="needs-validation"
                        >
                            {DynamicModalName == "Note" ? (
                                <div className="note">

                                    <div className="modal-head top-heading">
                                        <h2>  Add/View Notes  </h2>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setDynamicModal(false);
                                                setSmsBody("");
                                                setToken("");
                                                setSubject("");
                                                setHtml("");
                                            }}
                                            className="btn btn-link text-white"
                                        >
                                            <i className="fas fa-times"> </i>
                                        </button>
                                    </div>
                                    <ModalBody>
                                        <div className="d-flex justify-content-between">
                                            <div className="MonthlyDropDown mb-2" style={{ width: 'auto' }}>
                                                <select onChange={(e) => { setNotes([]); setNoteType(e.target.value); getNotes({ 'note_type': e.target.value }, 'notes') }} className="form-control">
                                                    <option value={'manual'}>Manual Notes</option>
                                                    <option value={'system'}>System Notes</option>
                                                    <option value="all">All Notes</option>
                                                </select>
                                            </div>
                                            {
                                                noteType == 'manual' && (
                                                    <div className="text-right mb-2">
                                                        <button onClick={() => setSendNote(true)} type="button" className="btn code-green" ><i className="fas fa-plus"></i></button>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        {
                                            sendNotes && (

                                                <div className="mb-3">
                                                    <div className="mb-2">
                                                        <AvField
                                                            className="form-control"
                                                            type="textarea"
                                                            name="activity"
                                                            label=""
                                                            placeholder="Add a note"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="btns">
                                                        <Button className="mr-3" color="info" type="submit">
                                                            Add Note
                                                        </Button>
                                                        <Button color="danger" onClick={() => { setSendNote(false) }}>
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </div>
                                            )
                                        }

                                        {notes.length > 0 ? (
                                            notes.map((row, i) => {
                                                return (
                                                    <div key={i} className="CompanyDetail ">
                                                        <div key={"note-" + i} className="note activity-info">
                                                            <div className="activity-body">
                                                                <div className="activity-bubble note">
                                                                    <div className="activity-content">
                                                                        <div className="activity_time">
                                                                            <div className="ActivityTitle" style={{ width: '65%' }}>
                                                                                <span> {row.type} </span>
                                                                            </div>
                                                                            {row.created_at}
                                                                        </div>
                                                                        <p> {row.activity} </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className="col-12 col-lg-12 col-xl-12 text-center">
                                                No Record Found
                                            </div>
                                        )}
                                        {/* <div className="mb-3">
                                            <AvField
                                                className="form-control"
                                                type="textarea"
                                                name="activity"
                                                label=""
                                                placeholder="Add a note"
                                                required
                                            /> */}
                                        {/* </div> */}
                                    </ModalBody>
                                    {/* <ModalFooter>
                                        <Button color="info" type="submit">
                                            Add Note
                                        </Button>
                                        <Button color="danger" onClick={() => {
                                            setDynamicModal(false);
                                            setSmsBody("");
                                            setToken("");
                                            setSubject("");
                                            setHtml("");
                                        }}>
                                            Cancel
                                        </Button>
                                    </ModalFooter> */}
                                </div>
                            ) : DynamicModalName == "sms" ? (
                                <div className="sms">
                                    <div className="modal-head top-heading">
                                        <h2> Send Sms </h2>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setDynamicModal(false);
                                                setSmsBody("");
                                                setToken("");
                                                setSubject("");
                                                setHtml("");
                                                setTemplateId(0);

                                            }}
                                            className="btn btn-link text-white"
                                        >
                                            <i className="fas fa-times"> </i>
                                        </button>
                                    </div>
                                    <ModalBody>
                                        <div className="mb-3">
                                            <Select
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                // value={options.filter(({ id }) => id === this.state.country_code)}
                                                isMulti={false}
                                                options={templates}
                                                classNamePrefix="select2-selection"
                                                name="country_id"
                                                placeholder={"Select sms template"}
                                                onChange={(e) => {
                                                    setSmsBody(e.body);
                                                    setTemplateId(e.id);

                                                }}
                                            />
                                            <AvField type="hidden" name="template_id" value={template_id} />
                                            <AvField type="hidden" name="tos" value={info.phone != '' ? info.phone : info.mobile} />
                                            <AvField type="hidden" name="activity" value={"sms send"} />
                                        </div>
                                        <div className="">
                                            <AvField readOnly type="hidden" name="froms" value={campaignInfo.from_number} />
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-8">
                                                <Select
                                                    getOptionLabel={(option) => option.label}
                                                    getOptionValue={(option) => option.key}
                                                    // value={options.filter(({ id }) => id === this.state.country_code)}
                                                    isMulti={false}
                                                    options={tokens}
                                                    classNamePrefix="select2-selection"
                                                    name="country_id"
                                                    placeholder={"Select token"}
                                                    onChange={(e) => {
                                                        setToken(`{${e.key}}`);
                                                    }}
                                                />
                                                {/* <AvField type="hidden" name="country_code" value={token} /> */}
                                            </div>
                                            <div className="col-md-4">
                                                <button
                                                    onClick={() => setSmsBody(`${smsBody} ${token}`)}
                                                    type="button"
                                                    className="btn btn-primary"
                                                >
                                                    Insert Token
                                                </button>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <AvField
                                                className="form-control"
                                                type="textarea"
                                                name="body"
                                                required
                                                value={smsBody}
                                                placeholder={"Enter body"}
                                                onChange={(e) => setSmsBody(e.target.value)}
                                            />
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="info" type="submit">
                                            Send
                                        </Button>
                                        <Button color="danger" onClick={() => {
                                            setDynamicModal(false);
                                            setSmsBody("");
                                            setToken("");
                                            setSubject("");
                                            setHtml("");
                                        }}>
                                            Cancel
                                        </Button>
                                    </ModalFooter>
                                </div>
                            ) : DynamicModalName == "rvm" ? (
                                <div className="rvm">
                                    <div className="modal-head top-heading">
                                        <h2> Send RVM </h2>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setDynamicModal(false);
                                                setSmsBody("");
                                                setToken("");
                                                setSubject("");
                                                setHtml("");
                                            }}
                                            className="btn btn-link text-white"
                                        >
                                            <i className="fas fa-times"> </i>
                                        </button>
                                    </div>
                                    <ModalBody>
                                        <div className="mb-3">
                                            <Select
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.id}
                                                // value={options.filter(({ id }) => id === this.state.country_code)}
                                                isMulti={false}
                                                options={templates}
                                                classNamePrefix="select2-selection"
                                                name="country_id"
                                                placeholder={"Select sms template"}
                                                onChange={(e) => {
                                                    setSmsBody(e.id);
                                                }}
                                            />
                                            <AvField
                                                type="hidden"
                                                name="tos"
                                                value={info.mobile ? info.mobile : info.phone}
                                            />
                                            <AvField type="hidden" name="activity" value={"RVM: send"} />
                                            <AvField type="hidden" name="template_id" value={smsBody} />
                                        </div>
                                        <div className="mb-3">
                                            <AvField
                                                className="select form-control"
                                                type="select"
                                                name="froms"
                                                required
                                            >
                                                <option value=""> Select Phone Number </option>
                                                {phoneNumbers.map((row, i) => {
                                                    return (
                                                        <option key={"num-" + i} value={row.phone_number}>
                                                            {convertPhoneFormat(row.phone_number)}
                                                        </option>
                                                    );
                                                })}
                                            </AvField>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="info" type="submit">
                                            Send
                                        </Button>
                                        <Button color="danger" onClick={() => {
                                            setDynamicModal(false);
                                            setSmsBody("");
                                            setToken("");
                                            setSubject("");
                                            setHtml("");
                                        }}>
                                            Cancel
                                        </Button>
                                    </ModalFooter>
                                </div>
                            ) : (
                                <div className=""> </div>
                            )}
                        </AvForm>
                    </Modal>

                    <Modal
                        isOpen={EmailModal}
                        fade={true}
                        size="lg"
                        toggle={() => {
                            setEmailModal(false);
                            setSmsBody("");
                            setToken("");
                            setSubject("");
                            setHtml("");
                        }}
                    >
                        <AvForm
                            onValidSubmit={handleEmailSubmit}
                            autoComplete="off"
                            className="needs-validation"
                        >
                            <div className="email">
                                <div className="modal-head top-heading">
                                    <h2> Send Email </h2>
                                    <button
                                        onClick={() => {
                                            setEmailModal(false);
                                            setSmsBody("");
                                            setToken("");
                                            setSubject("");
                                            setHtml("");
                                        }}
                                        type="button"
                                        className="btn btn-link text-white"
                                    >
                                        <i className="fas fa-times"> </i>
                                    </button>
                                </div>
                                <ModalBody>
                                    <div className="mb-3">
                                        <Select
                                            getOptionLabel={(option) => option.title}
                                            getOptionValue={(option) => option.id}
                                            // value={options.filter(({ id }) => id === this.state.country_code)}
                                            isMulti={false}
                                            options={templates}
                                            classNamePrefix="select2-selection"
                                            placeholder={"Select email template"}
                                            name="country_id"
                                            onChange={(e) => {
                                                setSubject(e.title);
                                                getEmailTemplateHtml(e.id);
                                            }}
                                        />
                                        <AvField type="hidden" name="tos" value={info.email} />
                                        <AvField type="hidden" name="activity" value={"Email: sent"} />
                                    </div>

                                    <AvField
                                        className="form-control"
                                        type="hidden"
                                        name="from_name"
                                        value={campaignInfo.from_name}
                                    />

                                    <AvField
                                        className="form-control"
                                        type="hidden"
                                        name="froms"
                                        value={campaignInfo.from_email}
                                    />
                                    <div className="row mb-3 d-none">
                                        <div className="col-md-12 mb-3">
                                            <Select
                                                getOptionLabel={(option) => option.label}
                                                getOptionValue={(option) => option.key}
                                                // value={options.filter(({ id }) => id === this.state.country_code)}
                                                isMulti={false}
                                                options={tokens}
                                                classNamePrefix="select2-selection"
                                                name="country_id"
                                                onChange={(e) => {
                                                    setToken(`{${e.key}}`);
                                                }}
                                            />
                                            {/* <AvField type="hidden" name="country_code" value={token} /> */}
                                        </div>
                                        {html == "" && (
                                            <div className="col-md-4">
                                                <button
                                                    onClick={() => setSmsBody(`${smsBody} ${token}`)}
                                                    type="button"
                                                    className="btn btn-primary"
                                                >
                                                    Insert Body
                                                </button>
                                            </div>
                                        )}
                                        <div className="col-md-4">
                                            <button
                                                onClick={() => setSubject(`${subject} ${token}`)}
                                                type="button"
                                                className="btn btn-primary"
                                            >
                                                Insert Subject
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            className="form-control"
                                            type="text"
                                            name="subject"
                                            placeholder="Subject"
                                            required
                                            value={subject}
                                            onChange={(e) => setSubject(e.target.value)}
                                        />
                                    </div>
                                    {html == "" ? (
                                        <div className="mb-3">
                                            <AvField
                                                className="form-control"
                                                type="textarea"
                                                name="body"
                                                placeholder="Enter body"
                                                required
                                                value={smsBody}
                                                onChange={(e) => setSmsBody(e.target.value)}
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <div
                                                id="custom-template"
                                                className="height-500 custom-scroll"
                                                dangerouslySetInnerHTML={{ __html: html }}
                                            ></div>
                                            {/* <AvField type="hidden" name="body" value={document.getElementById('custom-template')}/> */}
                                        </>
                                    )}
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="info" type="submit">
                                        Send
                                    </Button>
                                    <Button color="danger" onClick={() => {
                                        setEmailModal(false);
                                        setSmsBody("");
                                        setToken("");
                                        setSubject("");
                                        setHtml("");
                                    }}>
                                        Cancel
                                    </Button>
                                </ModalFooter>
                            </div>
                        </AvForm>
                    </Modal>

                    <Modal
                        isOpen={pipelineModal}
                        fade={true}
                        size="md"
                        className="hello"
                        toggle={() => {
                            setPipelineModal(false);
                            setSelectedPipeline({});
                        }}
                    >
                        <AvForm
                            onValidSubmit={handleDealSubmit}
                            // model={this.state.record}
                            className="needs-validation"
                        >
                            <div className="sms">
                                <div className="modal-head top-heading">
                                    <h2> Opportunity </h2>
                                    <button
                                        onClick={() => {
                                            setPipelineModal(false);
                                            setSelectedPipeline({});
                                        }}
                                        type="button"
                                        className="btn btn-link text-white"
                                    >
                                        <i className="fas fa-times"> </i>
                                    </button>
                                </div>
                                <ModalBody>
                                    <div className="mb-3">
                                        <AvField
                                            className="select form-control"
                                            type="select"
                                            name="pipeline_id"
                                            label="Pipeline"
                                            required
                                            disabled
                                            defaultValue={pipeline.pipeline_id}
                                        >
                                            <option value={pipeline.pipeline_id}>{pipeline.name}</option>
                                        </AvField>
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            className="select form-control"
                                            type="select"
                                            name="status_id"
                                            label="Deal Status"
                                            required
                                            disabled
                                            defaultValue={selectedPipeline.status_id}
                                        >
                                            <option value={selectedPipeline.status_id}>
                                                {selectedPipeline.title}
                                            </option>
                                        </AvField>
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            className="select form-control"
                                            type="select"
                                            name="assigned_to"
                                            label="Assign Member"
                                            required
                                        >
                                            <option value={""}> Select Member </option>
                                            {coachPartners.map((row, i) => {
                                                return (
                                                    <option key={"member-" + i} value={row.user_id}>
                                                        {row.name}
                                                    </option>
                                                );
                                            })}
                                        </AvField>
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            type="hidden"
                                            name="client_id"
                                            value={info.client_id}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            className="form-control"
                                            type="textarea"
                                            name="comments"
                                            required
                                            placeholder={"Enter comments"}
                                        />
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="info" type="submit">
                                        Assign
                                    </Button>
                                    <Button
                                        color="danger"
                                        onClick={() => {
                                            setPipelineModal(false);
                                            setSelectedPipeline({});
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </ModalFooter>
                            </div>
                        </AvForm>
                    </Modal>

                </Container >
                <div className="options-default">

                    <button
                        onClick={() => {
                            setpage3Class("slide-out2");
                            setType('SMS');
                            getActivity('8');
                        }}
                        className="btn btn-md m-2 sms-btn"
                    >
                        Sms Log
                    </button>

                    <button
                        className="btn btn-md m-2  email-btn"
                        onClick={() => {
                            setpage3Class("slide-out2");
                            setType('Email');
                            getActivity('7');
                        }}
                    >
                        Email Log
                    </button>

                    <button
                        className="btn btn-md m-2  call-btn"
                        onClick={() => {
                            setpage3Class("slide-out2");
                            setType('Call');
                            getActivity('9');
                        }}
                    >
                        Call Log
                    </button>

                    {/* <button
                        className="btn btn-md m-2  rvm-btn"
                        onClick={() => {
                            setpage3Class("slide-out2");
                            setType('Email');
                            getActivity('7');
                        }}
                    >
                        Rvm Log
                    </button> */}
                </div>
            </div >
        </React.Fragment >
    )
}

export default CampaignDialer