import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Button,
    Badge,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";

import { ApiUrl, ProductName } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import CampaignNav from "./nav";
import { dcrypt, bcrypt, uToken } from "../../useToken";
import { Modal, Tab, Tabs } from "react-bootstrap";
import swal from 'sweetalert';

export default class CampaignBatches extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id
                ? dcrypt(this.props.match.params.id)
                : null,
            batchId: this.props.match.params.batch_id
                ? dcrypt(this.props.match.params.batch_id)
                : null,
            batchType: this.props.match.params.batch_type,
            page_name: "Batches",
            filter: {},
            record: {},
            showModal: false,
            records: [],
            total_pages: 0,
            series: [],
            master_type: '0',
            tableLoader: true
        };
        this.columns = [
            {
                text: "Sr#",
                key: "sr",
            },
            {
                text: "Batch Id",
                key: "batch_id",
            },
            {
                text: "Master Type",
                key: "master_type",
            },
            {
                text: "Type",
                key: "type",
            },
            {
                text: "Followup Id",
                key: "series_id",
            },
            {
                text: "From",
                key: "from",
            },
            {
                text: "Total Clients",
                key: "clients",
                cell: (record, index) => {
                    if (record.status == 'instant') {
                        return (
                            <Fragment>
                                <button className="btn btn-link" onClick={() => this.props.history.push(`/campaign/batches/${bcrypt(record.campaign_id)}/${bcrypt(record.batch_id)}/${record.type}`)}><i className="fas fa-eye" /></button>
                            </Fragment>
                        )
                    } else {

                        return (
                            <Fragment>
                                <button className="btn btn-link" onClick={() => this.props.history.push(`/clients/batches/detail/${bcrypt(record.campaign_id)}/${bcrypt(record.batch_id)}`)}>{record.clients}</button>
                            </Fragment>
                        );
                    }
                }
            },
            // {
            //     text: "Processing Time",
            //     key: "status",
            // },
            {
                text: "Status",
                key: "status",
                cell: (record, index) => {
                    if (record.status == 'instant') {
                        return <Badge color="light" className="bg-light">{record.status}</Badge>
                    } else if (record.status == 'processed') {
                        return <Badge color="success" className="bg-success">{record.status}</Badge>
                    } else if (record.status == 'process') {
                        return <Badge color="info" className="bg-info">{record.status}</Badge>
                    } else if (record.status == 'stop') {
                        return <Badge color="danger" className="bg-danger">{record.status}</Badge>
                    } else if (record.status == 'pending') {
                        return <Badge color="warning" className="bg-warning">{record.status}</Badge>
                    }
                },
            },
            {
                text: "Processing Date",
                key: "processing_date_time",
            },
            {
                text: "Created Date",
                key: "created_at",
            },
            {
                key: "action",
                text: "Action",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            {(record.status != 'process' && record.status != 'instant') && (
                                <button
                                    className="btn btn-link btn-sm mb-1"
                                    onClick={this.deleteRecord.bind(this, record, index)}
                                >
                                    <i className="fas fa-trash" />
                                </button>
                            )}

                            {
                                record.status == 'instant' && (
                                    <button className="btn btn-link" onClick={() => this.props.history.push(`/campaign/batches/${bcrypt(record.campaign_id)}/${bcrypt(record.batch_id)}/${record.type}`)}> <i className="fas fa-eye" /></button>
                                )
                            }
                        </Fragment>
                    );
                }
            },
        ]
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: false,
                print: true,
                csv: true,
            },
        };
    }

    deleteRecord = (record, index) => {
        swal({
            title: "Are you sure?",
            text: "You want to remove this batch.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirmation) => {
                if (confirmation) {
                    fetch(`${ApiUrl}` + "delete/batch/" + record.batch_id, {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + uToken(),
                        },
                    })
                        .then((response) => response.json())
                        //Then with the data from the response in JSON...
                        .then((data) => {
                            if (data.status === true) {
                                this.getData(this.state.batchType);
                            } else {
                                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                            }
                        })
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                }
            });
    };


    componentDidUpdate(prevProps) {
        if (this.props.match.params.batch_id !== prevProps.match.params.batch_id) {
            this.getData(this.props.match.params.batch_type);
            // console.log(prevProps.match.params.batch_id ,'prevProps' , this.props.match.params.batch_id)
        }

        if (this.props.match.params.batch_type !== prevProps.match.params.batch_type) {
            this.getData(this.props.match.params.batch_type);
            // console.log(prevProps.match.params.batch_id ,'prevProps' , this.props.match.params.batch_id)
        }

    }


    componentDidMount() {
        this.getData(this.state.batchType);
    }

    getData = (type) => {
        this.setState({
            tableLoader: true,
        })
        fetch(`${ApiUrl}` + `campaign/batches/${this.state.id}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ "type": type, 'batch_id': this.props.match.params.batch_id ? dcrypt(this.props.match.params.batch_id) : '' }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        records: response.data.records,
                        batchType: type,
                        tableLoader: false,
                    });
                } else {
                    this.setState({
                        tableLoader: false,
                    });
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                this.setState({
                    tableLoader: false,
                });
                console.error("Error:", error);
            });
    };



    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.state.page_name}
                        Tables
                    </title>
                </Helmet>

                <Row>
                    <Col sm={5}>
                        <div className="page-title-box">
                            <h4>{this.state.page_name} Lists</h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#">{this.state.page_name} Lists</Link>
                                </li>
                            </ol>
                        </div>
                    </Col>
                </Row>

                <ToastContainer />
                <Row>
                    <Col className="col-12">
                        <Card className="mb-0">
                            <CampaignNav activeTab={'8'} campaignId={bcrypt(this.state.id)} />
                            {
                                this.props.match.params.batch_id == 'MA==' && (
                                    <Tabs defaultActiveKey={this.props.match.params.batch_type} onSelect={(key) => this.props.history.push(`/campaign/batches/${bcrypt(this.state.id)}/MA==/${key}`)}>
                                        <Tab eventKey={'email'} title="Email" />
                                        <Tab eventKey={'sms'} title="SMS" />
                                        <Tab eventKey={'rvm'} title="RVM" />
                                    </Tabs>
                                )
                            }

                        </Card>
                    </Col>
                </Row>


                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <ReactDatatable
                                    id={126}
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                    dynamic={false}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment >
        );
    }
}
