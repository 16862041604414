import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";

import { ApiUrl, ProductName } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import { dcrypt, bcrypt, uToken } from "../../../useToken";
import CampaignReportingNav from "./nav";
import { Modal } from "react-bootstrap";

export default class CampaignReportCall extends Component {
    constructor(props) {
        super(props);
        this.state = {
            campaign_id: 0,
            campaigns: [],
            page_name: "Call Report",
            filter: {},
            record: {},
            showModal: false,
            records: [],
            total_pages: 0,
            series: [],
            master_type: '0'
        };
        this.columns = [
            {
                text: "NAME",
                key: "name",
                cell: (record, index) => {
                  return (
                    <Fragment>
                      <button
                        key={index}
                        onClick={() => this.props.history.push(
                          "/lead/dialer/" +
                          bcrypt(record.client_id) +
                          "/" +
                          bcrypt(record.coach_id)
                        )
                        }
                        className="waves-effect border-0 text-left"
                      >
                        <i className="fas fa-newspaper pr-5"> </i>
                        {record.name}
                      </button>
                    </Fragment>
                  );
                },
            },
            {
                text: "From Number",
                key: "froms",
            },
            {
                text: "To Number",
                key: "tos",
            },
            {
                text: "Direction",
                key: "direction",
            },
            {
                text: "Area",
                key: "master_type",
            },
            {
                text: "Status",
                key: "status",
            },
            {
                text: "Created Date",
                key: "created_at",
            },
            {
                key: "action",
                text: "Action",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <button
                                className="btn btn-link btn-sm p-0"
                                onClick={this.viewStatus.bind(this, record, index)}
                            >
                                <i className="fas fa-eye" />
                            </button>
                            {/* )} */}
                        </Fragment>
                    );
                }
            },
        ]
        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50, 100],
            show_filter: true,
            show_pagination: true,
            button: {
                excel: false,
                print: true,
                csv: true,
            },
        };
    }


    componentDidMount() {
        this.getData();
        this.getCampaigns();
    }
    getCampaigns = () => {
        fetch(`${ApiUrl}` + `get/campaigns`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {
                    console.log(response, 'debuggingCampaigns');
                    this.setState({
                        campaigns: response.data.records,
                    });

                } else {
                              
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    getCampaignsState = (campaign_id) => {
        campaign_id && this.setState({ campaign_id: campaign_id });
        this.getSeries(0, 0);
    }

    viewStatus = (record, index) => {
        this.setState({
            record: record,
            showModal: true
        })
    };

    
    getData = (queryString = "", data) => {
        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        const report_id = params.get('show') ? dcrypt(params.get('show')) : '';

        fetch(`${ApiUrl}` + `aio/report` + '?report_type=call&report_id=' + report_id + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        total_pages: response.data.total,
                        records: response.data.records,
                    });
                } else {
                              
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    handleFilterSubmit = async (event, values) => {
        this.setState({
            filter: values,
        });
        this.getData("", values);
    };

    cancelFilter = async () => {
        this.form && this.form.reset();
        this.setState({ filter: {} });
        this.getData("", "");
    };

    tableChangeHandler = (data) => {
        let queryString = Object.keys(data)
            .map((key) => {
                if (key === "sort_order" && data[key]) {
                    return (
                        encodeURIComponent("sort_order") +
                        "=" +
                        encodeURIComponent(data[key].order) +
                        "&" +
                        encodeURIComponent("sort_column") +
                        "=" +
                        encodeURIComponent(data[key].column)
                    );
                } else {
                    return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
                }
            })
            .join("&");
        this.getData("&" + queryString, this.state.filter);
    };


    getSeries = (master_type, id) => {
        fetch(`${ApiUrl}` + `campaign/series/report/${id}/${master_type}/call`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {

                if (response.status === true) {
                    console.log(response, 'debugging');
                    this.setState({
                        series: response.data.records,
                        master_type: master_type,
                    });

                } else {
                              
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {this.state.page_name}
                        Tables
                    </title>
                </Helmet>

                <Row>
                    <Col sm={5}>
                        <div className="page-title-box">
                            <h4>{this.state.page_name}</h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#">{this.state.page_name}</Link>
                                </li>
                            </ol>
                        </div>
                    </Col>
                </Row>

                <ToastContainer />

                <Row>
                    <Col className="col-12">
                        <Card>
                            <CampaignReportingNav activeTab={'2'} campaignId={bcrypt(this.state.campaign_id)} />

                            <CardBody className="py-0">
                                <AvForm
                                    onValidSubmit={this.handleFilterSubmit}
                                    ref={(c) => (this.form = c)}
                                    className="needs-validation"
                                >
                                    <Row>
                                        <Col md="2">
                                            <div className="mb-0">
                                            <AvField
                                                    className="select form-control"
                                                    type="select"
                                                    name="campaign_id"
                                                    label="Report Type"
                                                    onChange={(e) =>
                                                        this.getCampaignsState(e.target.value)
                                                    }
                                                >
                                                   <optgroup key="all" label="All">
                                                        <option value=""> Select All</option>
                                                    </optgroup>
                                                    <optgroup key="manual" label="Non-Campaign">
                                                        <option value="-1"> Non Campaign Area</option>
                                                    </optgroup>
                                                    <optgroup key="campaigns" label="Campaigns">
                                                    {
                                                        this.state.campaigns.map((row, i) => {
                                                            return <option value={row.campaign_id}> {row.title} </option>
                                                        })
                                                    }
                                                    </optgroup>
                                                </AvField>
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-0">
                                                <AvField
                                                    className="select form-control"
                                                    type="select"
                                                    name="master_type"
                                                    label="Select Area"
                                                    onChange={(e) =>
                                                        this.getSeries(e.target.value, this.state.campaign_id)
                                                    }
                                                >
                                                    <option value="0"> All Time </option>
                                                    <option value="Scheduling">Broadcasts </option>
                                                    <optgroup label="Automation">
                                                        <option value="Autoresponder">Drips</option>
                                                        <option value="Eventreminder">Event Reminder</option>
                                                        <option value="Automation">Trigger</option>
                                                    </optgroup>
                                                    <option value="PipelineStatus">PipelineStatus</option>
                                                    <option value="Manual">Manual</option>
                                                </AvField>
                                            </div>
                                        </Col>
                                        {
                                            (this.state.master_type != 0 && this.state.master_type != 'Manual') && (
                                                <Col md="2">
                                                    <div className="mb-0">
                                                        <AvField
                                                            className="select form-control"
                                                            type="select"
                                                            name="series_id"
                                                            label={`Select ${this.state.master_type}`}

                                                        >
                                                            <option value=""> All </option>
                                                            {
                                                                this.state.series.map((row, i) => {
                                                                    return <option value={row.series_id}>{row.type} #{row.series_id}</option>
                                                                })
                                                            }


                                                        </AvField>
                                                    </div>
                                                </Col>
                                            )
                                        }
                                        <Col md="2">
                                            <div className="mb-0">
                                                <AvField
                                                    className="select form-control"
                                                    type="select"
                                                    name="flow"
                                                    label="Direction"
                                                >
                                                    <option value=""> All </option>
                                                    <option value="in">IN </option>
                                                    <option value="out">OUT</option>
                                                     <option value="system_out">SYSTEM OUT</option>
                                                </AvField>
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-0">
                                                <AvField
                                                    name="from_date"
                                                    label="From Date"
                                                    className="form-control"
                                                    placeholder="Enter Email Address"
                                                    type="date"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-0">
                                                <AvField
                                                    name="to_date"
                                                    label="To date"
                                                    className="form-control"
                                                    type="date"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-0">
                                                <label className="visibilty-hidden"> {"Action"}</label>
                                                <div className="button-items">

                                                    <Button className="btn-sm" color="primary" type="submit">
                                                        Filter
                                                    </Button>
                                                    <Button
                                                        onClick={() => this.cancelFilter()}
                                                        outline
                                                        color="secondary"
                                                        className="waves-effect btn-sm"
                                                        type="button"
                                                    >
                                                        Reset
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody className="pt-0">
                                {/* <CardTitle className="h4">Default Datatable </CardTitle> */}
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                    dynamic={true}
                                    total_record={this.state.total_pages}
                                    onChange={this.tableChangeHandler}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>


                <Modal
                    show={this.state.showModal}
                    onHide={() => this.setState({ showModal: false, record: {} })}
                    dialogClassName="modal-90w"
                    size="lg"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Log Details
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="campddd_log">
                            <div className="row">
                                <div className="col-md-6">
                                    <h6><strong>From: </strong> {this.state.record.froms}</h6>
                                    <h6><strong>To:</strong> {this.state.record.tos}</h6>
                                    <h6><strong>Direction:</strong> {this.state.record.direction}</h6>
                                    <h6><strong>Status:</strong> {this.state.record.status}</h6>
                                </div>
                                <div className="col-md-6">
                                    <h6><strong>Type:</strong> Call</h6>
                                    <h6><strong>Duration:</strong> {this.state.record.call_duration} sec</h6>

                                </div>

                                {
                                    this.state.record.body && (
                                        <div className="col-md-12">
                                            <audio controls>
                                                <source src={this.state.record.body} type="audio/ogg" />
                                                <source src={this.state.record.body} type="audio/mpeg" />
                                                Your browser does not support the audio element.
                                            </audio>
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                    </Modal.Body>
                </Modal>



            </React.Fragment >
        );
    }
}
