import React, { Component } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';
import { NumberWithCommas } from '../../useToken';

// const items = [
//   {
//     id: 1,
//     altText: 'Slide 1',
//     caption: 'Slide 1'
//   },
//   {
//     id: 2,
//     altText: 'Slide 2',
//     caption: 'Slide 2'
//   },
//   {
//     id: 3,
//     altText: 'Slide 3',
//     caption: 'Slide 3'
//   }
// ];

class OperationBreakdown extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    this.items = props.items;
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === this.items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }



  render() {
    const { activeIndex } = this.state;
    return (
      <div className=''>
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
          interval={null}
        >
          <CarouselIndicators items={this.items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
          {this.items.map((row, i) => {
            return <CarouselItem
              className="custom-tag"
              tag="div"
              key={i}
              onExiting={this.onExiting}
              onExited={this.onExited}
            >

              <div className="card card-body Breakdown" key={'submain-' + i}>
                <div className='cr-btns pull-right'>
                  <button className='btn btn-sm btn-link text-dark mr-1' onClick={this.previous}><i className='fas fa-angle-left'></i></button>
                  <button className='btn btn-sm btn-link text-dark' onClick={this.next}><i className='fas fa-angle-right'></i></button>
                </div>
                <div className="CalculatorArea">
                  <div className="ProfileImpact">
                    <div className="ProfitImpactChart">
                      <h3>Profit Impact</h3>
                      <h3>{row.length > 0 ? '$' + NumberWithCommas(row[0].profit_impac_net) : 0}</h3>
                    </div>

                    <div className="half-circle"></div>
                    {
                      row.map((sub_row, s) => {
                        return (
                          <div key={'sub-' + sub_row.report_id} className={`Impacts _${s}`}>
                            <div className="PointIcon"></div>
                            <div className={`OperationsImpact _${s}`}>
                              <div className="PercentCounter">
                                <div className="PercentNumber">
                                  {sub_row.input_amount}%
                                </div>
                              </div>
                              <div className="ShowTotalRev">
                                <div className="PackegeName">
                                  <i className="fa fa-arrow-circle-up"></i> <h6>{sub_row.cat_name}</h6>
                                </div>
                                <h3>${NumberWithCommas(sub_row.net_amount)}</h3>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>

            </CarouselItem>
          })
          }
          {/* <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} /> */}
        </Carousel>
      </div>
    );
  }
}

export default OperationBreakdown;