import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Form,
    Label,
    Button,
    Modal,
    ModalHeader,
    Input,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
    bcrypt,
    getPages,
    getPermission,
    getRoles,
    isAllowed,
    uInfo,
    uRole,
    uToken,
} from "../../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link, useHistory } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import ErrorBoundary from "../../../components/Common/ErrorBoundary";
import swal from 'sweetalert';

export default class TrackerCategories extends Component {

    constructor(props) {
        super(props);
        this.columns = [
            {
                text: "SR#",
                key: "sr",
                sortable: false,
            },
            {
                text: "Category",
                key: "cat_name",
                sortable: false,
            },
            {
                key: "action",
                text: "Action",
                cell: (record, index) => {
                    return (
                        <Fragment>
                            <div className="d-flex">
                                {isAllowed(uInfo(), ["can_edit"]) && (
                                    <>
                                        <button
                                            color="info"
                                            className="btn btn-primary btn-sm mr-5"
                                            onClick={() => this.props.history.push("/tracker/categories/edit/" + bcrypt(record.cat_id))}
                                        >
                                            <i className="mdi mdi-pencil"></i>
                                        </button>
                                    </>
                                )}
                            </div>
                        </Fragment>
                    );
                },
            },
        ];

        this.config = {
            key_column: "sr",
            page_size: 30,
            length_menu: [30, 50, 100, 200],
            show_filter: false,
            show_pagination: true,
            button: {
                excel: false,
                print: true,
                csv: true,
            },
        };
        this.state = {
            records: [],
            roles: [],
            total_pages: 0,
            modal: false,
            record: {},
            filter: {},
            page_name: "Progress Tracker Action Steps Categories",
            customDate: true,
            type_key: '',
            categoryfilter: '',
            selectedfilter: {},
        };
    }
    componentDidMount() {
        this.getData();
    }
    getData = (queryString = "", data) => {
        fetch(`${ApiUrl}` + "get/tracker/categoires" + queryString, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    //console.log(response, "DEBUGGING");
                    this.setState({
                        total_pages: response.data.total,
                        records: response.data.records,
                        roles: response.data.roles,
                    });
                } else {
                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    };
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Tracker Step Categories</title>
                </Helmet>
                <Row>
                    <Col sm={8}>
                        <div className="page-title-box">
                            <h4>Tracker Steps</h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#">Tracker Step Categories</Link>
                                </li>
                            </ol>
                        </div>
                    </Col>

                    <Col sm={4}>
                        <div className="page-title-box text-align-right">
                            <button
                                type="button"
                                color="secondary"
                                className="btn btn-info"
                                onClick={() => this.props.history.goBack()}
                            >
                                <i className="mdi mdi-keyboard-backspace "> </i>
                                Back
                            </button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody className="pt-0">
                                <ReactDatatable
                                    key={this.state.id}
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                    dynamic={true}
                                    total_record={this.state.total_pages}
                                    onChange={this.tableChangeHandler}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}