import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";

//Import Action to copy breadcrumb items from local state to redux state
import 'react-toastify/dist/ReactToastify.css';
import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName,ToastAutoClose } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import IntegrationSettings from "./Integration-sidebar";
export default class RvmSettings extends Component {
  constructor(props) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    super(props);
    this.state = {
      id: obj.user_id,
      record: {},
      page_name: this.props.match.params.id
        ? "Integrations"
        : "Integrations",
    };
  }
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/rvmsettings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            record: response.data.record,
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
       
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };



  handleSubmittwilio = async (event, values) => {
    if (this.state.id) {
        values.id = this.state.record.id ? this.state.record.id : -1
    }
    fetch(`${ApiUrl}` + 'edit/rvm_setting', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + uToken()
        },
        body: JSON.stringify(values),
    })
        .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((response) => {
            if (response.status === true) {
                if (this.state.id == null) {
                    this.form && this.form.reset();
                }
                // this.getData();
                toast.configure();
               toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });

            } else {
              toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

            }
        })
        //Then with the error genereted...
        .catch((error) => {
            console.error('Error:', error);
        });
}

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col lg="2" className="pl-0">
            <IntegrationSettings menuOpen={false} props={this.props} />
          </Col>
          <Col lg="10">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4>
                    
                    {this.state.page_name}
                  
                  </h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      
                      <Link to="#">
                        
                        {this.state.page_name}
                        
                      </Link>
                    </li>
                  </ol>
                </div>
              </Col>
            
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <AvForm
                    onValidSubmit={this.handleSubmittwilio}
                    ref={(c) => (this.form = c)}
                    className="needs-validation"
                  >
                    <ModalBody>
                      <div className="mb-3">
                        <h4>RVM(Slybroadcast) Credentials</h4>
                        <AvField
                          name="rvm_user_name"
                          label="Email Address"
                          value={this.state.record.rvm_user_name}
                          className="form-control"
                          placeholder="Slybroadcast Account Email Address"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="rvm_password"
                          label="Password"
                          type="password"
                          value={this.state.record.rvm_password}
                          className="form-control"
                          placeholder="Slybroadcast Account Password"
                          required
                        />
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        type="button"
                        color="secondary"
                        onClick={() => this.props.history.goBack()}
                      >
                        Cancel
                      </Button>
                      <button
                        className="btn btn-primary w-md waves-effect waves-light"
                        type="submit"
                      >
                        Submit
                      </button>
                    </ModalFooter>
                  </AvForm>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
