import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ReactDatatable from "@ashvin27/react-datatable";
import Badge from 'react-bootstrap/Badge';
//Import Action to copy breadcrumb items from local state to redux state

import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../../useToken";
import { ApiUrl, ProductName } from "../../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";

export default class UsersDatatableTables extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        text: "SR#",
        key: "sr",
        sortable: false,
      },
      {
        text: "Name",
        key: "name",
        sortable: true,
        cell: (record, index) => {
          return (
            <Fragment>
              {isAllowed(uInfo(), ["can_view"]) ? (
                <Link
                  to={"/user-profile/" + bcrypt(record.id)}
                  className="waves-effect btn btn-light"
                >
                  {record.name}
                </Link>
              ) : (
                record.name
              )}
            </Fragment>
          );
        },
      },
      {
        text: "Role",
        key: "role_name",
        sortable: true,
      },
      {
        text: "Email",
        key: "email",
        sortable: true,
      },
      {
        text: "Status",
        key: "status",
        sortable: true,
        cell: (record, index) => {
          return (
            <Fragment>
               {record.status=='Active' && (
              <Badge bg="success">{record.status}</Badge>
               )}
                {record.status=='Inactive' && (
              <Badge bg="danger">{record.status}</Badge>
               )}
            </Fragment>
          );
        },
      },
      {
        text: "Created At",
        key: "created_at",
      },
      {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          return (
            <Fragment>
              {isAllowed(uInfo(), ["can_edit"]) && (
                <Link
                  color="info"
                  className="btn btn-primary btn-sm mb-1 mr-5"
                  to={"admin/edit/" + bcrypt(record.id)}
                  // onClick={() => {
                  //   this.setState({
                  //     modal: !this.state.modal
                  //   })
                  // }}
                >
                  Edit
                </Link>
              )}
              {isAllowed(uInfo(), ["can_delete"]) && (
                <button
                  className="btn btn-danger btn-sm mb-1 mr-5"
                  onClick={this.deleteRecord.bind(this, record, index)}
                >
                  Delete
                </button>
              )}

              <Link
                to={"/user-profile/" + bcrypt(record.id)}
                className="btn btn-sm mb-1 btn-info"
              >
                View
              </Link>
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      key_column: "sr",
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: true,
      show_pagination: true,
      button: {
        excel: false,
        print: true,
        csv: true,
      },
    };
    this.state = {
      records: [],
      roles: [],
      total_pages: 0,
      modal: false,
      record: {},
      filter: {},
      page_name: "WL Admin",
      customDate: true,
    };
  }
  deleteRecord = (record, index) => {
    fetch(`${ApiUrl}` + "delete/reseller", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: record.id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          this.getData();
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        } else {
          toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  componentDidMount() {
    this.getData();
  }

  getData = (queryString = "", data) => {
    fetch(`${ApiUrl}` + "resellers" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        console.log(response, "data");
        if (response.status === true) {
          this.setState({
            total_pages: response.data.total,
            records: response.data.records,
            roles: response.data.roles,
          });
        } else {
                    
          
toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleFilterSubmit = async (event, values) => {
    this.setState({
      filter: values,
    });
    this.getData("", values);
  };

  cancelFilter = async () => {
    this.form && this.form.reset();
    this.setState({ filter: {}, customDate: true });
    this.getData("", "");
  };

  tableChangeHandler = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.getData("?" + queryString, this.state.filter);
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.page_name} </title>
        </Helmet>

        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4>{this.state.page_name} Lists</h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#">{this.state.page_name} Lists</Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              {isAllowed(uInfo(), ["can_create"]) && (
                <Link
                  type="button"
                  color="info"
                  className="btn btn-info"
                  to={"admin/add"}
                  // onClick={() => {
                  //   this.setState({
                  //     modal: !this.state.modal
                  //   })
                  // }}
                >
                  <i className="mdi mdi-plus"> </i>
                  Create
                </Link>
              )}
            </div>
          </Col>
        </Row>

        <ToastContainer />

        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <AvForm
                  onValidSubmit={this.handleFilterSubmit}
                  ref={(c) => (this.form = c)}
                  className="needs-validation"
                >
                  <Row>
                    <Col md="4">
                      <div className="mb-3">
                        <AvField
                          className="select form-control"
                          type="select"
                          name="date_range"
                          label="Date Filter"
                          onChange={(e) =>
                            e.target.value == "Custom Date"
                              ? this.setState({
                                  customDate: false,
                                })
                              : this.setState({
                                  customDate: true,
                                })
                          }
                        >
                          <option value="">All Time</option>
                          <option value="Current Week">Current Week</option>
                          <option value="Last Week">Last Week</option>
                          <option value="Current Month">Current Month</option>
                          <option value="Last Month">Last Month</option>
                          <option value="Last 3 Months">Last 3 Months</option>
                          <option value="Last 6 Months">Last 6 Months</option>
                          <option value="Current Year">Current Year</option>
                          <option value="Last Year">Last Year</option>
                          <option value="Custom Date">Custom Date</option>
                        </AvField>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mb-3">
                        <AvField
                          name="start_date"
                          label="Start date"
                          className="form-control"
                          placeholder="Enter Email Address"
                          type="date"
                          disabled={this.state.customDate}
                          required={!this.state.customDate}
                        />
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mb-3">
                        <AvField
                          name="end_date"
                          label="End date"
                          className="form-control"
                          placeholder="Enter Email Address"
                          type="date"
                          disabled={this.state.customDate}
                          required={!this.state.customDate}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <div className="mb-3">
                        <AvField
                          className="select form-control"
                          type="select"
                          id="s"
                          name="status"
                          label="Status"
                        >
                          <option value={""}>Select Status</option>
                          <option value={"Active"}>Active</option>
                          <option value={"Inactive"}>Inactive</option>
                        </AvField>
                      </div>
                    </Col>
                  </Row>
                  <div className="button-items">
                    <Button className="btn-sm" color="primary" type="submit">
                      Filter
                    </Button>
                    <Button
                      onClick={() => this.cancelFilter()}
                      outline
                      color="secondary"
                      className="waves-effect btn-sm"
                      type="button"
                    >
                      Reset
                    </Button>
                  </div>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                {/* <CardTitle className="h4">Default Datatable </CardTitle> */}
                <ReactDatatable
                  // key={this.state.id}
                  config={this.config}
                  records={this.state.records}
                  columns={this.columns}
                  dynamic={true}
                  total_record={this.state.total_pages}
                  onChange={this.tableChangeHandler}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
