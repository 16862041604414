import React from "react";
import list_view from "../../assets/images/resource-drive/list_view.jpg";
import info from "../../assets/images/resource-drive/info.png";
import "../../assets/css/resource-drive/DisplayContainer.css";

import ResourcesContext from "./ResourcesContext";
import { Link } from "react-router-dom";
import { bcrypt } from "../../useToken";

export default function DisplayUsers() {
  const params = React.useContext(ResourcesContext);

  return (
    <>
      <div className="users-list" id="displayCont">
        <div id="displayInfoNav">
          <p className="text-capitalize">{params.type} Lists</p>

          {/* <button>
            <img src={list_view} alt="Reload page" className="opacity" />
          </button>

          <button>
            <img src={info} alt="Reload page" className="opacity" />
          </button> */}
        </div>

        <div id="contentDisplayer">
          <div className="row m-0 w-100">
            {
              params.lists.map((user, i) => {
                return (
                  <div className="col-lg-3">
                    <div key={i} className="user-card displayCard text-center">
                      <Link to={`${params.type}/${bcrypt(user.user_id)}`}>
                        <div className="name">
                          <i className="fas fa-folder-open"></i>
                          <h5>{user.name}</h5>
                          {/* <h6>{user.email}</h6> */}
                        </div>
                      </Link>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </>
  );
}
