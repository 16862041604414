import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom";
import { ApiUrl } from "../../config";
import Context from './Context';
import swal from "sweetalert";
import { uInfo } from "../../useToken";
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
const Box9 = (props) => {
    const params = React.useContext(Context);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const submitData = new FormData();
        for (let [key, value] of formData.entries()) {
            submitData.append(key, value);
        }

        if (uInfo().user_id == 2) {
            submitData.append('edited_by', 0);
        } else {
            submitData.append('edited_by', 1);
        }

        fetch(`${ApiUrl}` + "update/builder/setting/" + params.user_id, {
            method: "POST",
            body: submitData,
        })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((response) => {
                if (response.status === true) {
                    params.goToBox(params.currentBox + 1);
                    params.getBuilderSetting();
                } else {
                    swal({
                        title: response.message,
                        icon: "warning",
                    });
                }
            })
            //Then with the error genereted...
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    return (
        <div>
            <div className="box-center-align mt-3">
                <div className="SmallContaner my-4">
                    <div className="d-flex align-items-center gap-2 StepHeading">
                        <div className="StepNumber">5 <i className="fas fa-arrow-right"></i></div> <h4 className="m-0">Section Information</h4>
                    </div>
                    <div className="page-content-box">

                        <form method="post" onSubmit={handleSubmit}
                            encType="multipart/form-data" className="form-horizontal" id="selection-myform">
                            <div className="WizredForm mt-3">
                                <div className="border-top border-bottom border-left border-right p-4">
                                    <div className="TitleWizred d-flex align-items-center gap-2">
                                        <span className="fs-5"> a.</span> <h5>About.</h5>
                                    </div>
                                    <div className="radio-box mt-3">
                                        <label>Do you have an "about" section already written?</label>
                                        <br />
                                        <div class="form-check form-check-inline">
                                            <input name="has_about" defaultChecked={params.setting.has_about == 'Yes' ? true : false} class="form-check-input" type="radio" id="inlineRadio1" value="Yes" />
                                            <label class="form-check-label" for="inlineRadio1">Yes</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input name="has_about" defaultChecked={params.setting.has_about == 'No' ? true : false} class="form-check-input" type="radio" id="inlineRadio2" value="No" />
                                            <label class="form-check-label" for="inlineRadio2">No</label>
                                        </div>
                                    </div>
                                    <div className="form-group mt-4">
                                        <label>Paste your "about" section here if you have it.</label>

                                        <SunEditor
                                        name="describe_about"
                                            setOptions={{
                                                height: 400,
                                                buttonList: [
                                                    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
                                                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                                    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                                                ]
                                            }}
                                            setContents={params.setting.describe_about ?? ''}
                                        />

                                        {/* <textarea rows={10} name="describe_about" defaultValue={params.setting.describe_about ?? ''}
                                            className="form-control no-border px-0" type="text" placeholder="Type your answer here..." /> */}

                                    </div>
                                </div>

                            </div>
                            <button type="submit" className="btn btn-lg btn-primary mt-2"> Next &nbsp;<i className="fas fa-check"></i></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Box9;