import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip } from "react-bootstrap";
import Helmet from "react-helmet";
import axios from "axios";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  dcrypt,
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import ErrorBoundary from "../../components/Common/ErrorBoundary";
import PackagesSidebar from "../Packages/packages-sidebar";
import swal from 'sweetalert';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export default class ProposalPages extends Component {
  constructor(props) {

    super(props);
    this.state = {
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      records: [],
      proposal_link: "",
      roles: [],
      total_pages: 0,
      modal: false,
      record: {},
      filter: {},
      page_name: "Proposal Pages",
      customDate: true,
    };
    this.columns = [
      {
        text: "ID#",
        key: "id",
        sortable: false,
      },
      {
        text: "Page Title",
        key: "title",
        sortable: true,
      },
      {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          return (
            <Fragment>
              {/* {isAllowed(uInfo(), ["can_edit"]) && (
                <Link
                  color="info"
                  className="btn-info mb-1  btn-sm"
                  onClick={() => this.props.history.push("/packages/proposal/content/edit/" + bcrypt(record.id) + "/" + this.props.match.params.id)}
                  title={"Edit this page"}
                >
                  <i className="mdi mdi-pencil"></i>
                </Link>
              )}
              {isAllowed(uInfo(), ["can_delete"]) && (
                <>
                  {" | "}
                  <Link
                    className="btn-danger mb-1  btn-sm"
                    onClick={this.deleteRecord.bind(this, record, index)}
                    title={"Delete this proposal page"}
                  >
                    <i className="mdi mdi-delete"></i>
                  </Link>
                </>
              )} */}
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      key_column: "sr",
      page_size: 10,
      length_menu: [10, 20, 50, 100],
      show_filter: false,
      show_pagination: false,
      button: {
        excel: false,
        print: false,
        csv: false,
      },
    };

  }


  componentDidMount() {
    this.getData();
  }
  deleteRecord = (record, index) => {
   
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "delete/proposalPage", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ id: record.id, proposal_id: dcrypt(this.props.match.params.id) }),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.status === true) {
                this.getData();
                //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });

        }
      });
  };
  getData = (queryString = this.state.id, data) => {
    fetch(`${ApiUrl}` + "proposal/pages/list/" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            total_pages: response.data.total,
            records: response.data.records,
          });
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = (event) => {
    fetch(`${ApiUrl}` + "update/proposalPages/order", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
     body: JSON.stringify({ proposal_id: this.state.id, items_arr: this.state.records }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {

          swal({
            text: "Updated successfully",
            icon: "success",
          })
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
  };
  onDragEnd = e => {
    if (!e.destination) {
      return;
    }
    const sorted = this.reorder(this.state.records, e.source.index, e.destination.index);
    this.setState({
      records: sorted,
    });
  };
  render() {
    return (
      <ErrorBoundary>
        <Helmet>
          <title>
            {this.state.page_name}
            Tables
          </title>
        </Helmet>

        <Row>
          <Col lg="2" className="pl-0">
            <PackagesSidebar menuOpen={false} props={this.props} />
          </Col>
          <Col lg="10">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4>
                    {this.state.page_name}


                  </h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#">
                        {this.state.page_name}

                      </Link>
                    </li>
                  </ol>
                </div>
              </Col>
              <Col sm={6}>
                <div className="page-title-box text-align-right">
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => this.props.history.push('/packages/proposal/list')}
                  >
                    Back
                  </Button>
                  <Button
                    className="ml-5"
                    type="button"
                    color="info"
                    onClick={() => this.props.history.push('/packages/proposal/content/add/' + bcrypt(this.state.id))}
                  >
                    Create
                  </Button>
                </div>
              </Col>
            </Row>

            <ToastContainer />

            <Row>
              <Col className="col-12">
                {/* <Card>
                  <CardBody>
                    <ReactDatatable
                      key={this.state.id}
                      config={this.config}
                      records={this.state.records}
                      columns={this.columns}
                      dynamic={false}
                      total_record={this.state.total_pages}
                    />
                  </CardBody>
                </Card> */}
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="Table">
                    {provided => (
                      <div className="table-responsive mb-0 col-md-12" data-pattern="priority-columns">
                        <table className="table table-striped table-bordered responsiveTable" {...provided.droppableProps} ref={provided.innerRef}>
                          <thead>
                            <tr>
                              <th>ID#</th>
                              <th>Name</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              this.state.records.map((row, i) => {

                                return (
                                  <Draggable
                                    key={row.id.toString()}
                                    draggableId={row.id.toString()}
                                    index={i}
                                  >
                                    {provided => (
                                      <tr
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <td>{i + 1}</td>
                                        <td>{row.title}</td>
                                        <td>
                                          {isAllowed(uInfo(), ["can_edit"]) && (
                                            <Link
                                              color="info"
                                              className="btn-info mb-1  btn-sm"
                                              onClick={() => this.props.history.push("/packages/proposal/content/edit/" + bcrypt(row.id) + "/" + this.props.match.params.id)}
                                              title={"Edit this page"}
                                            >
                                              <i className="mdi mdi-pencil"></i>
                                            </Link>
                                          )}
                                          {isAllowed(uInfo(), ["can_delete"]) && (
                                            <>
                                              {" | "}
                                              <Link
                                                className="btn-danger mb-1  btn-sm"
                                                onClick={this.deleteRecord.bind(this, row, i)}
                                                title={"Delete this proposal page"}
                                              >
                                                <i className="mdi mdi-delete"></i>
                                              </Link>
                                            </>
                                          )}
                                        </td>

                                      </tr>
                                    )}
                                  </Draggable>
                                );
                              })
                            }


                          </tbody>
                        </table>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {
                  this.state.records.length < 1 && (
                    <div className="text-center">No row found</div>
                  )
                }
                {
                  this.state.records.length > 0 && (
                    <button className="btn btn-primary btn-md" title="Update Page order" onClick={() => this.handleSubmit()}><i class="fas fa-sort"></i></button>
                  )
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </ErrorBoundary>
    );
  }
}
